  body{
    font-family: 'Plus Jakarta Sans', sans-serif;

  }
  .centered-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* max-width: 1200px; */
    margin: 0 auto;
    height: 100vh;
    width: 98%;
height: 701px;
border-radius: 81px;
background: rgba(209, 209, 209, 1);
  }

  .Assesscentered-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 90px auto;
    height: 100vh;
    width: 98%;
height: 701px;
border-radius: 81px;
background: rgba(209, 209, 209, 1);
  }
  
  .left-section {
    flex: 1;
    margin-left: 60px;
    height: 355px;
  }
  
  .right-section {
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }

  .TrainingPartContainerHeader{
    width: 542px;
    height: 87px;
    font-size: 70px;
    font-weight: 400;
    line-height: 86.8px;
    text-align: left;
  }

  .AssessPartContainerHeader{
    width: 780px;
    height: 87px;
    font-size: 70px;
    font-weight: 400;
    line-height: 86.8px;
    text-align: left;
    
  }

  .TrainingPartContainerPara{
    width: 517px;
/* height: 279px; */
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
  }

  .AssessPartContainerPara{
    width: 517px;
    /* height: 279px; */
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: right;
    margin-left: 150px;
  }


  
  .logo1 {
    max-width: 180px;
    height: auto;
    /* background-color: white; */
  /* padding: 10px;  */
  /* border-radius: 10px;  */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);  */
  }

  .Logo1bg{
    width: 200px;
    height: 200px;
    border-radius: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -200px;
    margin-top: 0px;
    position: relative;
  }

  .logo2 {
    max-width: 180px;
    height: auto;

  }

  .Logo2bg{
    width: 200px;
    height: 200px;
    border-radius: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 100px;
    margin-top: -50px;
    position: relative;
  }

  .logo3 {
    max-width: 180px;
    height: auto;

  }

  .Logo3bg{
    width: 200px;
    height: 200px;
    border-radius: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -300px;
    margin-top: -50px;
    position: relative;
  }

  .logo4 {
    max-width: 180px;
    height: auto;
  }

  .Logo4bg{
    width: 200px;
    height: 200px;
    border-radius: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 100px;
    margin-top: -100px;
    position: relative;
  }

  .TrainingPartContainerHeaderSm, .TrainingPartContainerParaSm, .TrainingPartContainerButtonSm{
    display: none; /* Hide by default */
  }

  .AssessPartContainerHeaderSm, .AssessPartContainerParaSm{
    display: none; /* Hide by default */
  }

  .TrainingPartContainerButton{
    width: 173px;
height: 60px;
border-radius: 37.5px;
background: rgba(7, 60, 165, 1);
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
text-align: center;
color: rgba(255, 255, 255, 1);
border: none;
margin-top: 15px;
  }

  .alogo1{
    height: 100px;
  }

  .alogo1bg{
    width: auto;
    height: 300px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -250px;
  }

  .alogo2{
    height: 100px;
  }

  .alogo2bg{
    width: 250px;
    height: 250px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 180px;
    margin-top: 350px;
  }
  @media screen and (max-width: 1027px){

    .right-section{
      margin-left: 100px;
    }

    .alogo1bg{
      margin-left: -200px;
    }

    .alogo2bg{
      margin-left: -100px;
    }

    .centered-container{
        width: 85%;
        height: 674px;
    }   
    
    .Assesscentered-container{
        margin-top: 40px;
        width: 85%;
        height: 674px;
    }

    .TrainingPartContainerHeaderSm{
        width: auto;
        height: auto;
        font-size: 70px;
        font-weight: 400;
        line-height: 86.8px;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 25px;
        display: block;
    }

    .AssessPartContainerHeaderSm{
        width: auto;
        height: auto;
        font-size: 70px;
        font-weight: 400;
        line-height: 86.8px;
        text-align: center;
        margin-top: 80px;
        margin-bottom: 15px;
        display: block;
    }

    .TrainingPartContainerParaSm{
        width: 75%;
        height: auto;
        font-size: 20px;
        font-weight: 400;
        line-height: 24.8px;
        text-align: center;
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    .AssessPartContainerParaSm{
        width: 75%;
        height: auto;
        font-size: 20px;
        font-weight: 400;
        line-height: 24.8px;
        text-align: center;
        margin-top: -20px;
        margin-bottom: 8em;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    .TrainingPartContainerButtonSm{
        display: block;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        width: 173px;
        height: 60px;
        border-radius: 37.5px;
        background: rgba(7, 60, 165, 1);
        color: rgba(255, 255, 255, 1);
        border: none;
        font-size: 20px;
        font-weight: 400;
        line-height: 24.8px;
        margin-top: 25px;
    }
    
    .left-section{
        display: none;
    }
  }

  @media screen and (max-width: 578px){
    .centered-container{
        height: 506px;
    }

    .Assesscentered-container{
        height: 506px;
    }

    .logo1
    {
        max-width: 120px;
    }

    .Logo1bg{
      width: 130px;
      height: 130px;

    }

    .logo2{
        max-width: 110px;
    }

    .Logo2bg{
      width: 130px;
      height: 130px;
      margin-left: 20px;
    }
    .logo3{
        max-width: 120px;
    }

    .Logo3bg{
      width: 130px;
      height: 130px;
      margin-left: -230px
    }

    .logo4{
        max-width: 120px;
    }

    .Logo4bg{
      width: 130px;
      height: 130px;
      margin-left: 30px;
      margin-top: -50px;
    }

    .alogo1{
        height: 60px;
    }

    .alogo1bg{
        height: 200px;
        margin-top: -180px;
        margin-left: -180px;
    }

    .alogo2{
      height: 60px;
    }

    .alogo2bg{
      height: 170px;
      width: 170px;
      margin-top: 200px;
      margin-left: 0px;
    }

    .TrainingPartContainerHeaderSm{
        width: auto;
        height: auto;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .TrainingPartContainerParaSm{
        width: 75%;
        height: auto;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.88px;
        text-align: center;
        margin-top: 30px;

    }

    .AssessPartContainerHeaderSm{
        width: auto;
        height: auto;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
        margin-top: 75px;
        margin-bottom: -10px;
    }

    .AssessPartContainerParaSm{
        width: 75%;
        height: auto;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.88px;
        text-align: center;
        margin-top: -60px;

    }



    .TrainingPartContainerButtonSm{
        width: 137px;
height: 42px;
border-radius: 37.5px;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: center;

    }

  }
  
 