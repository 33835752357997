.requestdemocontainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.RequestdemoBox{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    height: 200px;
}

.Reqheader{
    color: rgb(51,51,51);
    background-color: white;
    font-family: Arial, sans-serif;
    font-size: 10.5pt;
}

.Reqname{
    color: rgb(51,51,51);
    background-color: white;
    font-size: 10.5pt;
    font-family: Arial, sans-serif;
}

.Reqmainpara{
    color: rgb(51,51,51);
    background-color: white;
    font-size: 10.5pt;
    font-family: Arial, sans-serif;
}

.Requserinfo{
    color: rgb(51,51,51);
    background-color: white;
    font-size: 10.5pt;
    font-family: Arial, sans-serif;
}

.Reqendpara{
    color: rgb(51,51,51);
    background-color: white;
    font-size: 10.5pt;
    font-family: Arial, sans-serif;
}

.Reqmail{
    color: rgb(17,85,204);
    background-color: white;
    font-size: 10.5pt;
    font-family:  Arial, sans-serif; 
}

.Reqtq{
    color: rgb(51,51,51);
    background-color: white;
    font-size: 10.5pt;
    font-family: Arial, sans-serif;
}

/* style="color: rgb(51,51,51);background-color: white;font-size: 10.5pt;font-family: Arial, sans-serif;\" */