.mainlogorendering{
    display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.intro-video{
width: 100%;
}


/* .image {
    display: block;
    position: absolute;
  opacity: 0;
  transition: opacity 1.8s ease-in-out;
  }
  

  .image-0 {
    width: 298px;
    height: 273px;
  }
  
  .image-1 {
    width: 992px;
    height: 558px;
  }
  
  .image-2 {
    width: 298px;
    height: 273px;
  }

  .image-visible {
    opacity: 1;
  }
  
  @media (max-width: 1227px) {
    .image-0 {
      width: 242px;
      height: 221px;
    }
  
    .image-1 {
      width: 634px;
      height: 356px;
    }
  
    .image-2 {
      width: 242px;
      height: 221px;
    }
  }


  @media  (max-width: 576px){
    .image-1 {
        width: 366px;
        height: 206px;
      }
  }
   */