.termsemail {
    color: blue;
    padding: 2px 5px;
    /* Adjust padding as needed */
  }



@media (min-width: 1400px){

    .termssubtext{
        width: fit-content;
        height: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1em;
    }

    .termspara{
        width: 1030px;
    height: fit-content;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: justify;
    margin-top: 0%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5em;
    }


    .termscon{
        margin-top: 2%;
        margin-left: 4%;
    }
    
    .termsimg{
        width: 1198px;
    height: 462px;
    position: relative;
    }

    .termsimgmob{
        display: none;
    }
    
    .termsconimg{
        width: 1198px;
    height: 462px;
    background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
    position: absolute;
    }
    
    .termstext{
        width: 761px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-left: 20%;
    margin-top: -4%;
    }
    
    .termstext2{
        width: 1030px;
    height: 62px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 0%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5em;
    }
    
    .termstext3{
        width: 1030px;
    height: 1178px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    /* margin-top: 5%; */
    margin-left: 10%;
    /* margin-bottom: -20%; */
    }
    
    .termstartedbox2{
        margin-top: 50px;
        width: 1207px;
    height: 227px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 100px;
    margin-left: 4%;
    }
    
    .termsubbutton2{
        margin-left: 950px;
        margin-top: -245px;
        height: 50px;
        width: 175px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }
    
    .termstarthead2{
        width: 1000px;
    height: 87px;
    margin-left: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    
    }
    
    .termregis2{
        width: 90px;
    height: 19px;
        margin-top: 12px;
        margin-left: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    
    }
    
    .termstartpara2{
        width: 613px;
    height: 62px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 89px;
    margin-top: -1%;
    }

    .termspagemain{
        margin-top: -20px;
    }
    }

    @media (min-width:1227px) and (max-width:1399px){
       .termspagemain{
        max-width:1300px;
        transform: scale(0.83);
        transform-origin: top;
        margin-bottom: -300px;
       }

       .termssubtext{
        width: fit-content;
        height: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2em;
    }

       .termspara{
        width: 1030px;
    height: fit-content;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: justify;
    margin-top: 0%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5em;
    }
       
        .termscon{
            margin-top: 1%;
            margin-left: 1.4%;
        }
        
        .termsimg{
            width: 1198px;
        height: 462px;
        position: relative;
        }

        .termsimgmob{
            display: none;
        }
        
        .termsconimg{
            margin-left: -0.1%;
            margin-top: 0.1%;
            width: 1200px;
        height: 462px;
        background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
        position: absolute;
        }
        
        .termstext{
            width: 761px;
        height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: center;
        position: relative;
        margin-left: 20%;
        margin-top: -4%;
        }
        
        .termstext2{
            width: 1030px;
        height: 62px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 0%;
        margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;
        }
        
        .termstext3{
            width: 1030px;
        height: 1178px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 5%;
        margin-left: 10%;
        margin-bottom: -20%;
        }
        
        .termstartedbox2{
            margin-top: 50px;
            width: 1207px;
        height: 227px;
        background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
        border-radius: 25px;
        margin-bottom: 150px;
        margin-left: 1%;
        }
        
        .termsubbutton2{
            margin-left: 930px;
            margin-top: -280px;
            height: 50px;
            width: 175px;
            border-radius:30px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        background: rgb(255, 255, 255, 1);
        color: rgba(7, 60, 165, 1);
        border: solid rgba(255, 255, 255, 1);
        }
        
        .termstarthead2{
            width: 1000px;
        height: 87px;
        margin-left: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 50px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        justify-content: center;
        align-items: center;
        margin-top: 3%;
        
        }
        
        .termregis2{
            width: 90px;
        height: 19px;
            margin-top: 12px;
            margin-left: 40px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        
        }
        
        .termstartpara2{
            width: 613px;
        height: 62px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        margin-left: 55px;
        margin-top: -2.2%;
        margin-bottom: 120px;
        }
        }
    
        @media (min-width:1027px) and (max-width:1227px) {
            .termspagemain{
             max-width:1300px;
             transform: scale(0.83);
             transform-origin: top;
             margin-bottom: -350px;
            }
     
            .termssubtext{
             width: fit-content;
             height: 40px;
             font-family: 'Plus Jakarta Sans', sans-serif;
             font-size: 25px;
             font-weight: 400;
             line-height: 31px;
             text-align: center;
             margin-left: auto;
             margin-right: auto;
             margin-bottom: 2em;
         }
     
            .termspara{
             width: 1030px;
         height: fit-content;
         font-family: 'Plus Jakarta Sans', sans-serif;
         font-size: 25px;
         font-weight: 400;
         line-height: 31px;
         letter-spacing: 0em;
         text-align: justify;
         margin-top: 0%;
         margin-left: auto;
         margin-right: auto;
         margin-bottom: 1.5em;
         }
            
             .termscon{
                 margin-top: 1%;
                 margin-left: 1.4%;
             }
             
             .termsimg{
                 width: 100%;
             height: 462px;
             position: relative;
             }
     
             .termsimgmob{
                 display: none;
             }
             
             .termsconimg{
                 margin-left: -0.1%;
                 margin-top: 0.1%;
                 width: 100%;
             height: 462px;
             background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
             position: absolute;
             }
             
             .termstext{
                 width: 761px;
             height: 87px;
             font-family: 'Plus Jakarta Sans', sans-serif;
             font-size: 70px;
             font-weight: 400;
             line-height: 87px;
             letter-spacing: 0em;
             text-align: center;
             position: relative;
             margin-left: 20%;
             margin-top: -4%;
             }
             
             .termstext2{
                 width: 1030px;
             height: 62px;
             font-family: 'Plus Jakarta Sans', sans-serif;
             font-size: 25px;
             font-weight: 400;
             line-height: 31px;
             letter-spacing: 0em;
             text-align: center;
             margin-top: 0%;
             margin-left: auto;
         margin-right: auto;
         margin-bottom: 2em;
             }
             
             .termstext3{
                 width: 1030px;
             height: 1178px;
             font-family: 'Plus Jakarta Sans', sans-serif;
             font-size: 25px;
             font-weight: 400;
             line-height: 31px;
             letter-spacing: 0em;
             text-align: left;
             margin-top: 5%;
             margin-left: 10%;
             margin-bottom: -20%;
             }
             
             .termstartedbox2{
                 margin-top: 50px;
                 width: 1207px;
             height: 227px;
             background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
             border-radius: 25px;
             margin-bottom: 150px;
             margin-left: 1%;
             }
             
             .termsubbutton2{
                 margin-left: 930px;
                 margin-top: -280px;
                 height: 50px;
                 width: 175px;
                 border-radius:30px;
                 font-family: 'Plus Jakarta Sans', sans-serif;
             font-size: 20px;
             font-weight: 400;
             line-height: 19px;
             letter-spacing: 0em;
             background: rgb(255, 255, 255, 1);
             color: rgba(7, 60, 165, 1);
             border: solid rgba(255, 255, 255, 1);
             }
             
             .termstarthead2{
                 width: 1000px;
             height: 87px;
             margin-left: 40px;
             font-family: 'Plus Jakarta Sans', sans-serif;
             font-size: 50px;
             font-weight: 400;
             line-height: 87px;
             letter-spacing: 0em;
             text-align: left;
             color: rgba(255, 255, 255, 1);
             justify-content: center;
             align-items: center;
             margin-top: 3%;
             
             }
             
             .termregis2{
                 width: 90px;
             height: 19px;
                 margin-top: 12px;
                 margin-left: 40px;
                 font-family: 'Plus Jakarta Sans', sans-serif;
             font-size: 15px;
             font-weight: 400;
             line-height: 19px;
             letter-spacing: 0em;
             text-align: left;
             
             }
             
             .termstartpara2{
                 width: 613px;
             height: 62px;
             font-family: 'Plus Jakarta Sans', sans-serif;
             font-size: 25px;
             font-weight: 400;
             line-height: 31px;
             letter-spacing: 0em;
             text-align: left;
             color: rgba(255, 255, 255, 1);
             margin-left: 55px;
             margin-top: -2.2%;
             margin-bottom: 120px;
             }
             } 
    
    @media (max-width:1026px) {

        .termspara{
            width: 88%;
            height: fit-content;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: justify;
            margin-top: 0%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1.5em;
        }


        .termssubtext{
            width: fit-content;
            height: 40px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
            font-weight: 400;
            line-height: 31px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1em;
        }


        .termsimg{
            width: 95%;
        height: 462px;
        position: relative;
        margin-top: 5%;
        margin-left: 3%;
        }

        .termsimgmob{
            display: none;
        }
        
        .termsconimg{
            width: 100%;
        height: 462px;
        background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
        position: absolute;
        margin-top: 5%;
        /* margin-left: 2.7%; */
        }
        
        /* .termstext{
            width: auto;
        height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: center;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0%;
        } */
        
        .termstext2{
            width: 89%;
        height: 93px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 0%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2em;
        }
        
        .termstext3{
            width: 822px;
        height: 1550px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 5%;
        margin-left: 5%;
        }
        
        .termstartedbox2{
            width: 737px;
        height: 252px;
        background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
        border-radius: 25px;
        margin-bottom: 100px;
        margin-left: 10%;
        margin-top: 30px;
        }
        
        .termsubbutton2{
            margin-left: 300px;
            margin-top: -190px;
            height: 61px;
            width: 331px;
            border-radius:30px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        background: rgb(255, 255, 255, 1);
        color: rgba(7, 60, 165, 1);
        border: solid rgba(255, 255, 255, 1);
        }
        
        .termstarthead2{
            width: 506px;
        height: 40px;
        margin-left: 112px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        justify-content: center;
        align-items: center;
        margin-top: 3%;
        
        }
        
        .termregis2{
            width: 146px;
        height: 30px;
            margin-top: 12px;
            margin-left: 80px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        
        }
        
        .termstartpara2{
            width: 613px;
        height: 50px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        margin-left: 150px;
        margin-top: -15%;
        margin-bottom: 90px;
        }
    }


@media (min-width: 768px) and (max-width:1026px){

    .termstext{
        width: auto;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0%;
    }
}

    @media (min-width: 577px) and (max-width:767.5px){
        .termstext {
            width: 530px;
            height: 87px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 50px;
            font-weight: 400;
            line-height: 87px;
            letter-spacing: 0em;
            text-align: center;
            position: relative;
            margin-left: auto;
            margin-right: auto;
            margin-top: 0%;
        }
    }
    

    @media (max-width: 576px) {

        .termssubtext{
            width: fit-content;
            /* height: 40px; */
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: -6px;
            /* margin-bottom: 1em; */
        }

        .termspara{
            width: 90%;
        height: fit-content;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: justify;
        margin-top: 0%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1.5em;
        }
    
        .termsimg{
            display: none;
        }

        .termsimgmob{
            display: block;
            width: 90%;
            height: 293px;
            margin-top: 5%;
            margin-left: auto;
            margin-right: auto;
        }
        .termsconimg{
            width: 90%;
        height: 293px;
        background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
        position: absolute;
        margin-top: 5%;
        margin-left: 4.7%;
        }
    
        .termstext{
            width: 229px;
            height: 31px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: -8%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
        }
    
        .termstext2{
            width: 330px;
    height: 60px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

        }
    
        .termstextm3{
            width: 319px;
    height: 495px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    margin-left: 12%;
    text-align: justified;
        }
    
        .termstartedboxm{
            width: 335px;
        height: 199px;
        background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
        border-radius: 25px;
        margin-left: 8%;
        margin-top: 5%;
        }
    
        .termsubbuttonm{
            width: 137px;
            height: 42px;
            border-radius:37.5px;
            font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #073CA5;
    margin-top: -15%;
    margin-left: 32%;
    border: #FFFFFF;
        }
    
        .termstartheadm{
            width: 500px;
        height: 31px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        justify-content: center;
        align-items: center;
        /* margin-left: 20%; */
        margin-top: 10%;
        }
    
        .termregism{
            width: 90px;
        height: 19px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: 18%;
        margin-top: 8%;
        }    
    
        .termstartparam{
            width: 253px;
        height: 30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: 18%;
        margin-top: -20%;
        color: #FFFFFF;
        }
    }