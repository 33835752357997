body{
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.LogoMainheader{
    width: 350px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
justify-content: center;
align-items: center;
display: flex;
margin-left: auto;
margin-right: auto;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
}

.LogoSubheader{
    font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 20px;
}

.Logoheader{
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 20px;
margin-bottom: 30px;
}

/* .ScrollLogoContainer{white-space: nowrap;}

  
  .ScrollLogo {
    white-space: nowrap;
    display: inline-block;
    animation: scroll 20s linear infinite;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-100% - 10px));
    }
  }
  
  .ScrollLogo img {
    margin-top: 25px;
    margin-bottom: 25px;
    margin-right: 50px;
    height: 100px;
  }
   */



/* .logos:hover .logo-slidefre 
{
    animation-play-state: paused ;
} */

.ScrollLogoContainer{
    white-space: nowrap;
}

.ScrollLogo{
    display: inline-block;
    animation:120s scroll infinite linear;
}

.ScrollLogo img{
    margin: 25px 40px;
    height: 100px;
}

@keyframes scroll{
    from{ 
        transform: translateX(0);
    }
    to{
        transform: translateX(-100%);
    }
}

/* scroll part 2  */

.ScrollLogoContainer2{
    white-space: nowrap;
}

.ScrollLogo2{
    display: inline-block;
    animation:80s scroll2 infinite linear;
}

.ScrollLogo2 img{
    margin: 25px 40px;
    height: 100px;
}

@keyframes scroll2{
    from{ 
        transform: translateX(-100%);
    }
    to{
        transform: translateX(0);
    }
}


@media screen and (max-width: 578px){
    .LogoMainheader{
        width: 180px;
        height: 34px;
        border-radius: 37.5px;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.88px;
        text-align: center;
    }

    .LogoSubheader{
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
    }

    .Logoheader{
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
    }

    .ScrollLogo img{
        margin: 15px 30px;
        height: 50px;
    }

    .ScrollLogo2 img{
        margin: 15px 30px;
        height: 50px;
    }
}