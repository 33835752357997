.ppsubpara {
    margin-top: 10px;
    text-align: justify;
    line-height: 1.6; /* Adjust line height for readability */
    margin-bottom: 20px; /* Add margin at the bottom for separation */
  }

  .ppsubpara li {
    margin-bottom: 10px; /* Add margin between list items for clarity */
    list-style-type: lower-alpha; /* Use lowercase alphabets for list items */
  }

  
.privacycon {
    position: relative;
    width: 100%;
    height: 500px;
  }


@media (min-width: 1400px){

    .privacypagemain{
        margin-top: -50px;
    }

    .datatitlepp{
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 400;
    line-height: 80px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    left: 70px;
    }

    .datasubtitlepp{
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 38px;
    font-weight: 400;
    line-height: 80px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    left: 70px;
    }

    .pppara{
        width: 90%;
        height: fit-content;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: justify;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2em;
    }
    .ppparaaddr{
        width: 90%;
        height: fit-content;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: 0em;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2em;
    }

    .ppsubpara{
        text-align: justify;
    }


    .termscon1{
        margin-top: 5%;
        margin-left: 4%;
    }
    
    .termsimg1{
        width: 1198px;
    height: 462px;
    position: relative;
    }

    .termsimg1mob{
        display: none;
    }
    
    .termsconimg1{
        width: 1198px;
    height: 462px;
    background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
    position: absolute;
    }
    
    .termstexts{
        width: 761px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: -6%;
    position: relative;
    margin-left: 20%;
    margin-top: -4%;
    }
    
    .termstexts2{
        width: 1030px;
    height: 62px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 0%;
    margin-left: 9.5%;
    }
    
   
    
    .termstartedbox1{
        margin-top: 50px;
        width: 1207px;
    height: 227px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 100px;
    margin-left: 5%;
    }
    
    .termsubbutton1{
        margin-left: 950px;
        margin-top: -245px;
        height: 50px;
        width: 175px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }
    
    .termstarthead1{
        width: 1000px;
    height: 87px;
    margin-left: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    
    }
    
    .termregis1{
        width: 90px;
    height: 19px;
        margin-top: 12px;
        margin-left: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    
    }
    
    .termstartpara1{
        width: 613px;
    height: 62px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 94px;
    margin-top: 0%;
    }
    }

@media (min-width:1228px) and (max-width:1399px){
       .privacypagemain{
        max-width:1300px;
        transform: scale(0.83);
        transform-origin: top;
        margin-bottom: -110%;
       }

       .datatitlepp{
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 400;
    line-height: 80px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    left: 70px;
    }

    .datasubtitlepp{
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 38px;
    font-weight: 400;
    line-height: 80px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    left: 70px;
    }

    .pppara{
        width: 90%;
        height: fit-content;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: 0em;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2em;
        text-align: justify;
    }

    .ppparaaddr{
        width: 90%;
        height: fit-content;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: 0em;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2em;
        text-align: justify;
    }
       
        .termscon1{
            margin-top: 1.5%;
            margin-left: 1.5%;
        }
        
        .termsimg1{
            width: 1198px;
        height: 462px;
        position: relative;
        }
        
        .termsimg1mob{
            display: none;
        }

        .termsconimg1{
            width: 1199px;
        height: 462px;
        margin-top: 1%;
        background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
        position: absolute;
        }
        
        .termstexts{
            width: 761px;
        height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -6%;
        position: relative;
        margin-left: 20%;
        margin-top: -4%;
        }
        
        .termstexts2{
            width: 1030px;
        height: 62px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 0%;
        margin-left: 9.5%;
        }
        
       
        
        .termstartedbox1{
            margin-top: 50px;
            width: 1207px;
        height: 227px;
        background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
        border-radius: 25px;
        margin-bottom: 100px;
        margin-left: 0.5%;
        }
        
        .termsubbutton1{
            margin-left: 880px;
            margin-top: -250px;
            height: 50px;
            width: 175px;
            border-radius:30px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        background: rgb(255, 255, 255, 1);
        color: rgba(7, 60, 165, 1);
        border: solid rgba(255, 255, 255, 1);
        }
        
        .termstarthead1{
            width: 1000px;
        height: 87px;
        margin-left: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 50px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        justify-content: center;
        align-items: center;
        margin-top: 3%;
        
        }
        
        .termregis1{
            width: 90px;
        height: 19px;
            margin-top: 12px;
            margin-left: 40px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        
        }
        
        .termstartpara1{
            width: 613px;
        height: 62px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        margin-left: 52px;
        margin-top: 0%;
        }
        }

@media (min-width:1027px) and (max-width:1227px){
    .privacypagemain{
     max-width:1300px;
     transform: scale(0.83);
     transform-origin: top;
     margin-bottom: -130%;
    }

    .datatitlepp{
     font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 45px;
 font-weight: 400;
 line-height: 80px;
 letter-spacing: 0em;
 text-align: left;
 position: relative;
 left: 70px;
 }

 .datasubtitlepp{
     font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 38px;
 font-weight: 400;
 line-height: 80px;
 letter-spacing: 0em;
 text-align: left;
 position: relative;
 left: 70px;
 }

 .pppara{
     width: 90%;
     height: fit-content;
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 25px;
     font-weight: 400;
     line-height: 34px;
     letter-spacing: 0em;
     margin-left: auto;
     margin-right: auto;
     margin-bottom: 2em;
     text-align: justify;
 }

 .ppparaaddr{
     width: 90%;
     height: fit-content;
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 25px;
     font-weight: 400;
     line-height: 34px;
     letter-spacing: 0em;
     margin-left: auto;
     margin-right: auto;
     margin-bottom: 2em;
     text-align: justify;
 }
    
     .termscon1{
         margin-top: 1.5%;
         margin-left: 1.5%;
     }
     
     .termsimg1{
         width: 1198px;
     height: 462px;
     position: relative;
     }
     
     .termsimg1mob{
         display: none;
     }

     .termsconimg1{
         width: 1199px;
     height: 462px;
     margin-top: 1%;
     background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
     position: absolute;
     }
     
     .termstexts{
         width: 761px;
     height: 87px;
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 70px;
     font-weight: 400;
     line-height: 87px;
     letter-spacing: 0em;
     text-align: center;
     margin-top: -6%;
     position: relative;
     margin-left: 20%;
     margin-top: -4%;
     }
     
     .termstexts2{
         width: 1030px;
     height: 62px;
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 25px;
     font-weight: 400;
     line-height: 31px;
     letter-spacing: 0em;
     text-align: center;
     margin-top: 0%;
     margin-left: 9.5%;
     }
     
    
     
     .termstartedbox1{
         margin-top: 50px;
         width: 1207px;
     height: 227px;
     background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
     border-radius: 25px;
     margin-bottom: 100px;
     margin-left: 0.5%;
     }
     
     .termsubbutton1{
         margin-left: 880px;
         margin-top: -250px;
         height: 50px;
         width: 175px;
         border-radius:30px;
         font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 20px;
     font-weight: 400;
     line-height: 19px;
     letter-spacing: 0em;
     background: rgb(255, 255, 255, 1);
     color: rgba(7, 60, 165, 1);
     border: solid rgba(255, 255, 255, 1);
     }
     
     .termstarthead1{
         width: 1000px;
     height: 87px;
     margin-left: 40px;
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 50px;
     font-weight: 400;
     line-height: 87px;
     letter-spacing: 0em;
     text-align: left;
     color: rgba(255, 255, 255, 1);
     justify-content: center;
     align-items: center;
     margin-top: 3%;
     
     }
     
     .termregis1{
         width: 90px;
     height: 19px;
         margin-top: 12px;
         margin-left: 40px;
         font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 15px;
     font-weight: 400;
     line-height: 19px;
     letter-spacing: 0em;
     text-align: left;
     
     }
     
     .termstartpara1{
         width: 613px;
     height: 62px;
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 25px;
     font-weight: 400;
     line-height: 31px;
     letter-spacing: 0em;
     text-align: left;
     color: rgba(255, 255, 255, 1);
     margin-left: 52px;
     margin-top: 0%;
     }
     }

@media (max-width:1026px){



        .termstexts{
            width: auto;
            height: 87px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 45px;
            font-weight: 400;
            line-height: 87px;
            letter-spacing: 0em;
            text-align: center;
            margin-top: -11%;
            position: relative;
            margin-left: auto;
            margin-right: auto;
        }

        .termstexts2{
            width: auto;
        /* height: 93px; */
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -2.5%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        margin-bottom: 38px;
        }

        .pppara{
            width: 89%;
            height: auto;
            font-size: 23px;
            font-weight: 400;
            line-height: 31px;
            text-align: justify;
            margin-top: 0%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1.5em;
        }

        .datatitlepp{
font-size: 25px;
font-weight: 900;
line-height: 31px;
text-align: left;
font-family: 'Plus Jakarta Sans', sans-serif;
margin-left: 50px;
        }

        .datasubtitlepp{
            font-size: 25px;
font-weight: 900;
line-height: 31px;
text-align: left;
font-family: 'Plus Jakarta Sans', sans-serif;
margin-left: 50px;

        }

        /* .termsimg1{
            width: 95%;
            height: 462px;
            margin-left: auto;
            margin-right: auto;


        }

        .termsconimg1{
            width: 95%;
            height: 462px;
            background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
        } */

        .termsimg1{
            width: 95%;
        height: 462px;
        position: relative;
        margin-top: 5%;
        margin-left: 3%;
        }

        .termsimg1mob{
            display: none;
        }
        
        .termsconimg1{
            width: 95%;
        height: 462px;
        background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
        position: absolute;
        margin-top: 5%;
        margin-left: 2%;
        }
        

          }

          

    
    @media (max-width: 576px) {

        .privacycon{
            height: 284px;
        }

        .termstexts2{
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: -4%;
            margin-bottom: 0px;

        }


        .datatitlepp{
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        position: relative;
        /* left: 17px; */
        margin-left: 17px;
        }

        .datatitleppaddr{
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        }
    
        .datasubtitlepp{
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 20px;
        margin-bottom: 10px;
        }
    
        .pppara{
            width: 90%;
            height: fit-content;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: justify;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 2em;
            margin-top: 2%;
        }
        .ppparaaddr{
            width: 91%;
            height: fit-content;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 2em;
        }

        .ppsubpara {
            margin-top: 10px;
            text-align: left;
            line-height: 1.6; /* Adjust line height for readability */
            margin-bottom: 20px; /* Add margin at the bottom for separation */
          }
        
          .ppsubpara li {
            margin-bottom: 10px; /* Add margin between list items for clarity */
            list-style-type: lower-alpha; /* Use lowercase alphabets for list items */
          }


        .termsimg1{
            display: none;
        }

        .termsimg1mob{
            display: block;
            width: 365px;
        height: 293px;
        margin-top: 5%;
        margin-left: auto;
        margin-right: auto;
        /* margin-left: 5%; */
        }
        
        .termsconimg1{
            width: 365px;
        height: 293px;
        background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
        position: absolute;
        margin-top: 5%;
        margin-left: 4.7%;
        }
    
        .termstexts{
            width: 229px;
            height: 31px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-top: -7%;
    margin-left: auto;
    margin-right: auto;
    /* margin-left: 18%; */
        }
    }
    
    
    /* @media (min-width:831px) and (max-width:1026px) {
        .termsimg1{
            width: 886px;
        height: 462px;
        position: relative;
        margin-top: 5%;
        margin-left: 3%;
        }
        
        .termsconimg1{
            width: 886px;
        height: 462px;
        background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
        position: absolute;
        margin-top: 5%;
        margin-left: 2.8%;
        }
        
        .termstexts{
            width: 761px;
        height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 0%;
        position: relative;
        margin-left: 11%;
        }
        
        .termstexts2{
            width: 832px;
        height: 93px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 0%;
        margin-left: 6%;
        }
        
       
        
        .termstartedbox1{
            margin-top: 80px;
            width: 737px;
        height: 252px;
        background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
        border-radius: 25px;
        margin-bottom: 100px;
        margin-left: 10%;
        }
        
        .termsubbutton1{
            margin-left: 280px;
            margin-top: -190px;
            height: 61px;
            width: 331px;
            border-radius:30px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        background: rgb(255, 255, 255, 1);
        color: rgba(7, 60, 165, 1);
        border: solid rgba(255, 255, 255, 1);
        }
        
        .termstarthead1{
            width: 506px;
        height: 40px;
        margin-left: 112px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        justify-content: center;
        align-items: center;
        margin-top: 3%;
        
        }
        
        .termregis1{
            width: 146px;
        height: 30px;
            margin-top: 12px;
            margin-left: 80px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        
        }
        
        .termstartpara1{
            width: 613px;
        height: 50px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        margin-left: 140px;
        margin-top: -18%;
        }        
    } */
    