.FooterContainer{
    width: 100%;
    height: 680px;
    border-radius: 50px 50px 0px 0px;
    background: linear-gradient(180deg, #073CA5 0%, #09093F 100%);
    font-family: 'Plus Jakarta Sans', sans-serif;
    display: flex;
    justify-content: center;
}

.Footercontent{
    margin-left: -100px;
    margin-top: 110px;
}

.knowmoreclass{
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
color: rgba(255, 255, 255, 1);
}

.Emailcontactbox{
    margin-top: 23px;
}

.Entermailinput{
    width: 962px;
height: 60px;
border-radius: 37.5px;
border: 1px 0px 0px 0px;
background: rgba(4, 48, 135, 1);
border: 1px solid rgba(255, 255, 255, 1);
color: rgba(255, 255, 255, 1);
padding-left: 20px;
outline: none;
}

.Entermailinput::placeholder{
    color:rgba(255, 255, 255, 1);
}

.mailbutton{
    position: absolute;
    margin-left: -100px;
    width: 173px;
height: 60px;
border-radius: 37.5px;
background: rgba(255, 255, 255, 1);
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: center;
color: rgba(26, 26, 130, 1);
border: none;
}

.Logowhite{
    width: 222px;
    height: 40px;
    margin-top: 45px;
    margin-left: 10px;
}

.Footernavs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color:rgba(255, 255, 255, 1);
    margin-top: 30px;
    cursor: pointer;
}

.section {
    display: flex;
    /* justify-content: space-between; */
    width: 45%; /* Adjust width as needed */
}

.Footernavhead{
font-size: 20px;
font-weight: 400;
line-height: 30px;
margin-bottom: 10px;
color: white;
text-decoration: none;
}

.section1 {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.left-half,
.right-half {
    display: flex;
    flex-direction: column;
    width: 48%; /* Adjust width as needed */
}

.left-half{
    margin-left: 15px;
    width: 110px;
}

.right-half{
    margin-left: 265px;
    position: absolute;
    width: 145px;
}

.section2 {
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: 80px;
}

.Footercopy{
    width: 100%;
height: 94px;
background: rgba(4, 48, 135, 1);
display: flex;
justify-content: center;
align-items: center;
font-size: 20px;
font-weight: 400;
line-height: 30px;
color: rgba(255, 255, 255, 1);
margin-top: -2px;
}


@media (max-width: 1024px){
    .Footercontent{
        margin-left: 0px;
    }

    .knowmoreclass{
        text-align: center;
    }

    .Entermailinput{
        width: 684.09px;
        height: 60px;
        outline: none;
    }

    .mailbutton{
        margin-left: -150px;
    }

    .right-half{
        margin-left: 180px;
        
    }
}


@media (max-width: 830px) {
    .Footernavs {
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }
    .section {
        width: 100%;
        margin-bottom: 10px;
    }
    .section1{
        align-items: center;
        margin-top: -10px;
    }
    .section2 {
        align-items: start;
        position: relative;
        margin-top: 25px;
        left: 100px;
    }
    .FooterContainer{
        height: 790px;
    }

    .left-half{
        margin-left: auto;
        margin-right: auto;
    }

    .right-half{
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-top: 35px
    }

    .Logowhite{
        margin-left: auto; /* Move the logo to the right */
        margin-right: auto; /* Move the logo to the left */
        display: block;
    }

    .Entermailinput{
        width: 100%;
        outline: none;
    }
}

@media (max-width: 576px){
    .knowmoreclass{
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
    }

    .FooterContainer{
        height: 575px;
    }

    .Footercontent{
        margin-top: 80px;
    }

    .Entermailinput{
        width: 100%;
        height: 42px;
        margin-left: -40px;
        outline: none;
    }

    .mailbutton{
        width: 137px;
        height: 42px;
        font-size: 15px;
        font-weight: 400;
        line-height: 18.6px;
        text-align: center;
        margin-left: -50px;
    }

    .Logowhite{
        width: 168px;
        height: 30px;
    }

    .Footernavhead{

font-size: 12px;
font-weight: 400;
line-height: 18px;

    }
    .section1{
        margin-top: 10px;
    }
    .left-half{
        margin-left: 0px;
    }

    .right-half{
        margin-left: 160px;
        position: absolute;
        margin-top: 25px;
        width: 100px;
    }

    .section2{
        left: 2px;
        margin-top: 40px;
    }

    .Footercopy{
font-size: 12px;
font-weight: 400;
line-height: 18px;
text-align: center;
height: 61px;
    }
}

