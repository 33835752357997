

.Fmaincontent{
    width: 80%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-top: 80px;
margin-left: auto;
margin-right: auto;
margin-bottom: 3em;
}

@media (max-width:1024px){
    .Fmaincontent{
        margin-top: 50px;
    }
}


@media (max-width:576px){
    .Fmaincontent{
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: center;
margin-top: 30px;
margin-bottom: 2em;
    }
}



.chatboxtext1,.chatboxtext1tab,.chatboxtext21,.chatboxtext21tab,.chatboxtext331,.chatboxtext41,.chatboxtext41tab,.chatboxtext51,.chatboxtext51tab,.chatboxtext31{
    color: #000000;    
}

.chatbox1fre:hover ,
.chatbox2fre:hover,
.extratestimonial:hover,
.chatbox4fre:hover,
.chatbox5fre:hover
{
    transform: scale(1.05); /* Scale up the element on hover */
  }

  .chatbox1fre,
  .chatbox2fre,
  .extratestimonial,
  .chatbox4fre,
  .chatbox5fre
  {
    /* Add initial styles */
    transition: transform 0.3s ease; /* Add transition for smooth effect */
  }
 


@media (min-width: 1400px){


    .chatbox2fre{
        height: 100px;
    }

    .chatbox4fre{
        height: 0px;
    }
    .extratestimonial{
        height: 150px;
    }

    /* chat animation start */


    
    /* chat animation end */

/* box click element start */

    .clickihv1fre{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickihv2fre{
        width: 34px;
height: 34px;
position: relative;
top: -5px;
left: 90px;
    }

    .clickihv3fre{
        width: 34px;
height: 34px;
position: relative;
top: -35px;
left: 90px;
    }

    .clickhv1fre{
        width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
    }

    .clickhv2fre{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickhv3fre{
        width: 34px;
height: 34px;
position: relative;
top: -45px;
left: 90px;
    }

    .clichv1fre{
        width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
    }

    .clichv2fre{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clichv3fre{
        width: 34px;
height: 34px;
position: relative;
top: -45px;
left: 90px;
    }

    .clihv1fre{
        width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
    }

    .clihv2fre{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clihv3fre{
        width: 34px;
height: 34px;
position: relative;
top: -45px;
left: 90px;
    }

    /* box click elemet end */


    .assesheadtitfretab{
        display: none;
    }

    /* circle animation  */

    @keyframes moveUp {
        0% { transform: translateY(30px); }
        100% { transform: translateY(-10px); }
    }
    
    .org1circle, .vio1circle, .blue1circle {
        
        animation: none; /* Initially no animation */
    }
    
    .scrolling .org1circle {
        animation: moveUp 0.8s forwards; /* Play animation once and stop */
    }
    
    .scrolling .vio1circle {
        animation: moveUp 1.5s forwards; /* Slightly delay the animation */
    }
    
    .scrolling .blue1circle {
        animation: moveUp 2s forwards; /* Slightly delay the animation */
    }
    
    
  
    

    /* .parallax-circle{
        animation-name: dance;
        transition: 1s;
        transform: translateY(-10px);
  animation-duration: 1s;
  animation-iteration-count: infinite;
    }
    
    @keyframes dance {
        0% { transform: translateY(0); }
        50% { transform: translateY(-30px); }
        100% { transform: translateY(0); }
      } */

      .parallax-circle {
        /* width: 100px;
        height: 100px;
        background-color: blue;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer; */
        transition: transform 0.5s ease; /* Smooth transition for the dancing effect */
      }
      
      .parallax-circle.dancing {
        transform: translateY(-25px); /* Adjust the value as needed */
        animation: 60s ease-out alternate; /* Animation for the dancing effect */
      }


    
    /* FOR BOXES */
    /* .dancing-divv {
        opacity: 0;
        transform: translateY(-400px);
        transition: 1s ;
    }
      
      .dancing-divv.visible {
        opacity: 1;
        transform: translateY(-100%);
        animation:  0.5s ease-in-out forwards ;
      }
      
      @keyframes shake {
        0% { transform: translateX(0); }
        25% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
        75% { transform: translateX(-10px); }
        100% { transform: translateX(0); }
      }
      
     
      

 
    .dancing-div {
       
        transition: transform 0.5s ease;
      }
      
      .dancing-div.dancing {
        transform: translateY(-25px); 
        animation: 60s ease-out alternate; 
      }
      
      @keyframes bounce {
        from {
          transform: translateY(-25px);
        }
        to {
          transform: translateY(0);
        }
      } */

    .whitearrowfre{
        margin-left: 30%;
        margin-top: 20%;
    }

    .head13tab{
        display: none;
    }
    .bimg1xt{
        position: absolute;
        top: 1400px;
    }
    .firstsignup{
        margin-top: -5%;
    }
    body{
        overflow-x: hidden;
    }
    
    .register1tfre{

        width: auto;
        padding-top: 6.3%;
        margin-left: auto;
        margin-right: auto;
        color: rgba(255, 255, 255, 1);

    }

    .instlogofr{
        /* width: 100px; */
        height: 100px;
    }
    
    
    .contentparafre{
        display: flex;
        justify-content: center;
    margin-top: 2%;
        margin-left: 2%;
        width: 584px;
height: 191px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 48px;
font-weight: 300;
line-height: 59.52px;
text-align: left;
    }

    .giffre{
        position: absolute;
        top: -290px;
        left: 222px;
    }

    .clickincorefre1{
        width: 34px;
height: 34px;
position: relative;
left: 250px;
top: -53px;
    }
    
    .clickincorefre2{
        width: 34px;
height: 34px;
position: relative;
left: 885px;
top: -53px;
cursor: pointer;
    }

    .clickincorefre3{
        width: 34px;
height: 34px;
position: relative;
left: 810px;
top: -53px;
    }

    .clickincorefre4{
        width: 34px;
height: 34px;
position: relative;
left: 1110px;
top: -8px;
    }

    .brandhovercontainerexp{
        position: relative;
    }
    
    .accesshovercontainerexp{
        position: relative;
    }
    
    .autohovercontainerexp{
        position: relative;
    }
    
    .hirehovercontainerexp{
        position: relative;
    }
    
    
    .imagecontainerbrandexp{
        
    }

    .containerfrecore{
        position: absolute;
    }

    .mid3contentfresher img{
        cursor: pointer;
    }
    
    .imagecontaineraccessexp{
        position: relative;
        margin-top: -2.4%;
    }
    
    .imagecontainerautoexp{
        position: relative;
        margin-top: 0%;
    }
    
    .imagecontainerhireexp{
        position: relative;
        margin-top: -1.5%;
    }

    /* .brandhovercontentexp{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        transition: opacity 0.3s ease;
        position: relative;
        top: -0.7%;
      left: 5%;
      opacity: 0;
      pointer-events: none;
      z-index: 2;
    } */


    .brandhovercontentfr{
        position: relative;
        margin-top: -43%;
        left: 83px;
        background-color: #ffffff;
        width: 1140px;
        height: 470px;
        z-index: 1;
    }

    .autohovercontentfr{
        position: relative;
        margin-top: -44%;
        left: 83px;
        background-color: #ffffff;
        width: 1140px;
        height: 480px;
        z-index: 1;
    }

    .hirehovercontentfr{
        position: relative;
        margin-top: -42.5%;
        left: 83px;
        background-color: #ffffff;
        width: 1140px;
        height: 480px;
        z-index: 1;
    }



    .accesshovercontentfr{
        position: relative;
        margin-top: -40.5%;
        left: 83px;
        background-color: #ffffff;
        width: 1140px;
        height: 480px;
        z-index: 1;
    }
    
    /* .accesshovercontentfre{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        transition: opacity 0.3s ease;
        position: absolute;
        margin-top: -35.2%;
        top: -0.7%;
      left: 5%;
      opacity: 0;
      pointer-events: none;
      z-index: 2;
    } */
    
    /* .autohovercontentexp{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        transition: opacity 0.3s ease;
        position: absolute;
        margin-top: -35.9%;
        top: -0.7%;
      left: 5%;
      opacity: 0;
      pointer-events: none;
      z-index: 2;
    }
    
    .hirehovercontentexp{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        transition: opacity 0.3s ease;
        position: absolute;
        margin-top: -37%;
        top: -0.7%;
      left: 5%;
      opacity: 0;
      pointer-events: none;
      z-index: 2;
    } */
    
    
    
    /* .imagecontainerbrandexp:hover + .brandhovercontentexp {
        opacity: 1; 
      }
     */
      /* .imagecontaineraccessexp:hover + .accesshovercontentfre {
        opacity: 1; 
      } */
    
      /* .imagecontainerautoexp:hover + .autohovercontentexp {
        opacity: 1; 
      }
    
      .imagecontainerhireexp:hover + .hirehovercontentexp {
        opacity: 1; 
      } */
    
    
    
    .brandcontentexp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
    }
    
    .brandheaderexp{
        width: 660px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 5%;
    position: absolute;
    margin-top: 2.5%;
    }
    
    .brandparaexp{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .brandhoverimagesfre{
        margin-left: 85%;
        margin-top: -40.3%;
        position: absolute;
    }
    
    .hovfrimg1brand{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    position: absolute;
    }
    
    .brandhovfrimg1{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    position: relative;
    margin-bottom: -30px;
    }
    
    .hovfrimg2brand{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-top: 5%;
    position: absolute;
    }
    
    .brandhovfrimg2{
        width: 141px;
        height: 145px;
        border-radius: 20px;
        background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
        opacity: 45%;
        top: 7.5px;
        position: relative;
        margin-bottom: -30px;
    }
    
    .hovfrimg3brand{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-top: 10%;
    position: absolute;
    }
    
    .brandhovfrimg3{
        width: 141px;
        height: 145px;
        border-radius: 20px;
        background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
        opacity: 45%;
        top: 14px;
        position: relative;
    }
    
    .accesscontentexp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
    }
    
    .accessheaderexp{
        width: 900px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 2.5%;
    margin-left: 5%;
    }
    
    .accessparaexp{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .accesshoverimagesfre{
        margin-left: 85%;
        margin-top: -40.3%;
        position: absolute;
    }
    
    .hoverimg1accessexp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        position: absolute;
    }
    
    .accesshoverimg1bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    margin-bottom: -16%;
    }
    
    
    .hoverimg2accessexp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }
    
    .accesshoverimg2bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    margin-bottom: -16%;
    }

    .accesshoveimg2bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -16%;
    }

    .accesshovimg2bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -16%;
    }
    
    .hoverimg3accessexp{
        position: absolute;
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
    }
    
    .accesshoverimg3bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    opacity: 45%;
    margin-bottom: 2%;
    }

    .accesshoveimg3bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    opacity: 45%;
    margin-bottom: 2%;
    }

    .accesshovimg3bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    margin-bottom: 2%;
    }
   
    .hireheaderexp{
        width: 700px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 2.5%;
    margin-left: 5%;
    }
    
    .hireparaexp{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .hirecontentexp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
    
    }
    
    
    .autocontentexp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%);
    
    }
    
    
    
   
    
    .fresher1tag{
        /* align-items: center; */
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    display: flex;
    /* text-indent: 10px; */
    margin-left: 7%;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 234px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    margin-top: 5%;
    }
    .luck{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  display: inline-block;
                  font-weight: 900;
    
    }
    .ftext{
        /* margin-top: 10px; */
        top: 15px;
        position: absolute;
        left: 70px;
    }
    
    .image-container{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
        margin-left: 26%;
        
    
    }
    
    .img-fluid1{
        width: 375px;
height: 456.75px;
    margin-top: -1.7%;
    position: absolute;
    margin-left: 0%;
    }
    .orgvector{
        /* margin-left: 50%; */
        width: 313px;
    height: 430px;
    background-color: rgba(255, 91, 0, 1);
    border-top-right-radius: 90%;
    /* display: inline-block; */
    }
    
    .violetbox{
        background: rgba(130, 27, 97, 1);
        width: 203px;
    height: 203px;
    display: flex;
    position: relative;
    margin-left: 3%;
    margin-top: -32%;
    display: inline-block;
    text-align: start;
    }
    
    .vt1fre{
        width: 91px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    margin-top: 18%;
    margin-left: 15%;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    }
    .image-container4{
        position: relative;
        margin-left: 22%;
        margin-top: -10%;
    }
    
    .vt2fre{
        width: 83px;
    height: 19px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    margin-top: 80px;
    margin-left: -88px;
    }
    
    .yellowbox{
        background: rgba(247, 180, 0, 1);
        width: 118px;
    height: 118px;
    display: flex;
    margin-left: 12.5%;
    /* margin-right: 190px; */
    display: inline-block;
    position: relative;
    margin-top: -9%;
    text-align: start;
    }
    
    .yt1fre{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    }
    
    .yt2{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 10px;
    }

    .sechead{
        margin-top: 5%;
    }

    
    /* .sechead{
        margin-top: 10%;
    } */
    
    .siginslet{
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 7%;
    /* padding-top: 2%; */
    color: rgba(255, 255, 255, 1);
    
    
    }
    
    .signinss
    {
        width: 173px;
    height: 60px;
    position: relative;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: -10%;
    margin-left: 8%;
    background: rgba(7, 60, 165, 1);
    border: none;
    
    }
    
    .watchsss{
        width: 200px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-top: -2.7%;
    position: relative;
    margin-left: -400px;
    background-color: white;
    border: 1px solid rgba(7, 60, 165, 1);
    cursor: pointer !important;
    text-transform:capitalize;
    }
    .watchs{
        /* padding-top: 4.5%; */
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(7, 60, 165, 1);
    }
    
    .linetextttfre{
        width: 1100px;
    height: fit-content;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    margin-top: 7%;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center ;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5em;
    }

    .linetextttfre2{
        width: 1100px;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    /* margin-top: 25%; */
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center ;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5em;
    }
    
    .lineboxfre{
    
        width: 160px;
    height: 160px;
    display: flex;
    /* margin-right: 100px; */
    display: inline-block;
    /* top: 0px; */
    position: relative;
    margin-top: -73%;
    text-align: start;
    border: 2px solid rgba(0, 0, 0, 1);
    margin-left: 22%;
    
    }
    
    .hlinebox1fre{
        width: 102px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 16%;
    position: absolute;
    opacity: 20%;
    
    }
    .hlinebox2fre{
        width: 40px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 30%;
    opacity: 30%;
    position: absolute;
    
    }
    
    
    .rlinebox1fre{
        width: 159px;
    height: 102px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 10%;
    position: absolute;
    }
    
    .rlinebox2fre{
        width: 159px;
    height: 40px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 50%;
    }
    
    .lineboxtextfre{
        width: 64px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 21px;
    margin-left: 17px;
    color: rgba(0, 0, 0, 1);
    
    }
    .lineboxtext2fre{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    }

    .title1tagfre{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    /* text-indent: 10px; */
    /* margin-top: 6%; */
    /* margin-left: 40%; */
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 170px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }
    
    
    
    .title1taglogofre{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    margin-top: 4em;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 390px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }
    .imagecontainerbrand {
        position: relative;
    }

    .mid3contentfresher{
        margin-top: 2%;
    }
    
    .mid3contentfresher .img1{
        width: 312px;
    height: 462px;
    margin-left: 85px;
    margin-top: -3px;
    border-radius: 50px;
    }
    .mid3contentfresher .bimg1{
        width: 312px;
    height: 462px;
    position: absolute;
    margin-top: -0.2%;
    margin-left: -24%;
    border-radius: 50px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    }
    
    .mid3contentfresher .bimg1textfre{
        width: 200px;
    height: 84px;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    opacity: 100%;
    position: relative;
    /* display: block; */
    margin-top: -150px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-left: 120px;
    margin-bottom: 4.5%;
    
    }
    
    
    .imagecontaineraccess{
        position: relative;
    }
    
    .mid3contentfresher .img2{
        width: 513px;
    height: 231px;
    margin-top: -35.3%;
    margin-left: 32.5%;
    margin-bottom: 40%;
    border-radius: 50px;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    .mid3contentfresher .bimg2{
        width: 513px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-top: -35.3%;
    margin-left: -39.5%;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    
    }
    
    .bimg2textfre{
        width: 396px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -45%;
    margin-left: 480px;
    opacity: 100%;
    position: relative;
    display: block;
    }
    
    .imagecontainerauto{
        position: relative;
    }
    
    .title2taglogofre{
        /* width: 672px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 310px; */

    width: 1171px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    /* margin-left: 79px; */
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    }
    
    .title2tagexp2gfre{
        width: 1050px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: auto;
margin-right: auto;
    }

    .title2tagfre{
    width: 699px;
height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 86.8px;
    text-align: center;   
    margin-left: auto;
    margin-right: auto;
    }

    .title2tagfre2{

        width: 1050px;
height: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0.2em;
        }
    
    .mid3contentfresher .img3{
        width: 768px;
    height: 212px;
    border-radius: 50px;
    margin-top: -18%;
    margin-left: 32.5%;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    
    .mid3contentfresher .bimg3{
        width: 768px;
    height: 212px;
    margin-top: -16.1%;
    margin-left: -59.2%;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    }
    
    .mid3contentfresher .bimg3textfre{
        width: 345px;
    height: 84px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    margin-left: 36%;
    display: block;
    margin-top: -9%;
    color:rgba(255, 255, 255, 1);
    
    }
    
    .imagecontainerhire{
        position: relative;
    }
    
    
    .mid3contentfresher .img4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    margin-top: -58.2%;
    margin-left: 74%;
    /* opacity: 45%px; */
    
    
    }
    
    .mid3contentfresher .bimg4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-left: -17.8%;
    margin-top: -37%;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    }
    
    .bimg4textfre{
        width: 72px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    position: relative;
    display: block;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -31%;
    margin-left: 77%;
    }

    .phonetitle1tagfre{
        margin-top: 4em;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    margin-left: -10%;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 430px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }
    
    .phoneheadtitlefre{
        margin-top: -4%;
        margin-left: -10%;
        width: 630px;
        height: 174px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;    
    }
    
    .phonebodyfre{
        margin-top: 19%;
        width: 500px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: -42%;
    }
    
    .phones{
        width: 351px;
    height: 644px;
    margin-top: -510px;
    margin-left: 150px;
    position: relative;
    }
    
    .org1circle{
        width: 240.93px;
    height: 240.93px;
    margin-top: -170px;
    border-radius: 100%;
    margin-left: 120px;
    /* background: rgba(247, 91, 2, 1); */
    position: relative;
    /* opacity: 90%; */
    animation-name: dance;
    animation-delay: 0s;
    }
    
    .vio1circle{
        width: 240.93px;
    height: 240.93px;
    border-radius: 100%;
    /* background: rgba(130, 27, 97, 1); */
    margin-top: -120px;
    position: relative;
    opacity: 90%;
    animation-name: dance;
    animation-delay: 0.15s;
    }
    
    .vio1circle::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 12%;
      width: 240.93px;
    height: 240.93px;
      border-radius: 50%;
      opacity: 100%;
    }
    
    .org1circle::before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 240.93px;
    height: 240.93px;
      border-radius: 50%;
      opacity: 90%;
    }
    
    .org1circle::before {
        background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
      }
      
      .vio1circle::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }
    
    .blue1circle{
        width: 364.7px;
    height: 364.7px;
    border-radius: 100%;
    background: rgba(135, 189, 255, 1);
    margin-top: -400px;
    margin-left: 220px;
    position: absolute;

    animation-name: dance;
    animation-delay: 0.3s;
    }
    
    .arrowfre{
        width: 256.46px;
    height: 189.91px;
    margin-left: -130px;
    margin-top: -150px;
    }
    .image-phone-container{
        margin-left: 500px;
        margin-top: -400px;
        margin-bottom: -1em;
    }
    
    .phonetitles{
        margin-left: -130px;
    }
    

    
    .trainingtitles{
        margin-top: -590px;
    }
    
    .traintitle1tagfre{
        width: 225px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    }
    
    .trainheadtitletabfre{
        width: 542px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    top: 130px;
    }

    .traincirclefr{
        width: 500px;
    height: 500px;
    background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -250px;
    position: relative;
    top: -100px;
    }
    
    .head12fre{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    top: 130px;
    }
    
    .head13fre{
        margin-top: -80px;
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .registerfre{
        width: 280px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: -80px;
    background: rgba(7, 60, 165, 1);
    border: none;
    margin-bottom: -3%;
    }
    
    .assesscirclefre{
        width: 500px;
    height: 500px;
    background: rgba(9, 9, 63, 0.05);
    border-radius: 100%;
    margin-left: 1050px;
    margin-top: 5%
    }
    
    .assesstitle1tagfre{
        margin-top: -570px;
        margin-left: 990px;
        width: 225px;
        height: 64px;
        border-radius: 37.5px;
        background: rgba(232, 237, 252, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;    
    }
    
    .assesheadtitlefre{
        width: 693px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: right;
    margin-left: 590px;
    position: relative;
    top: -320px;
    }
    
    .assesshead1fre{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 210px;
    text-align: right;
    position: relative;
    top: -320px;
    margin-bottom: -180px;
    }
    
    .assesshead2fre{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 150px;
    text-align: right;
    margin-top: -50px;
    }
    
    .register2fre{
        width: 240px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 980px;
    margin-top: -25px;
    border: none;
    background: rgba(7, 60, 165, 1);
    }
    
    .chatfre{
        margin-top: -400px;
    }
    
    .chatitle1tagfre{
        width: 360px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    /* margin-top: 4em; */
    margin-top: 15%;
    }
    
    .ctext{
        margin-left: 30px;
        margin-top: 15px;
    }
    
    .chatheadtitlefre{
        width: 600px;
    height: 110px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .chatimg1fre{
        width: 87px;
    height: 87px;
    }
    .chatbox111fre{
        clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
        width: 84.16px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    margin-left: -116%;
    /* margin-top: -40.6%; */
position: relative;
    }
    
    .chatbox1s1fre{
        width: 378px;
    height: 615px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-left: 150px;
    margin-top: -80px;
    }
    
    .chatboxtext1{
        width: 370px;
    height: 529.92px;
    padding: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    .chatbox121fre{
        width: 81px;
    height: 64px;
    margin-top: -45.5%;
    margin-left: 62%;
    /* position: absolute; */
    position: relative;
    background: rgb(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    }

    .chatbox2{
        width: 660px;
        height: 300px;
    }


    
    
    .chatbox2s1{
        margin-left: -30px;
        width: 435px;
        height: 284px;
        border-radius: 50px 0px 50px 50px;
        background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext21{
        width: 365px;
    height: 150px;
        padding: 30px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg2fre{
        width: 87px;
    height: 87px;
    margin-left: 470px;
    margin-top: -10%;
    }
    
    .chatbox1331fre{
        width: 81px;
    height: 64px;
    background: rgb(207, 240, 230, 1);
    /* position: absolute; */
    position: relative;
    margin-top: -32.2%;
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    margin-left: 63%;
    }
    
    .chatbox331{
        padding: 10px;
        margin-top: 10%;
        width: 358px;
       margin-left: 10%;
    height: 201px;
    border-radius: 50px 0px 50px 50px;
    background: rgba(207, 240, 230, 1);
    /* background: #000000; */
    }
    
    .chatboxtext331{
        padding: 20px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg33fre{
        margin-top: -8%;
        margin-left: 74%;
    }
    
    .chatimg4fre{
        width: 87px;
    height: 87px;
    margin-top: -480px;
    margin-left: -40px;
    }
    
    .chatbox141fre{
        width: 81px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    /* position: absolute; */
    position: relative;
    margin-top: -2.3%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: -100%;
    }
    
    .chatbox4s1{
        padding: 10px;
        width: 475px;
    height: 220px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: -300px;
    margin-left: 100px;
    }
    .chatboxtext41{
        width: 388px;
    height: 90px;
        padding: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    
    }
    
    
    .chatimg5fre{
        width: 87px;
    height: 87px;
        margin-top: -130px;
        margin-left: -10px;
    }
    
    .chatbox5s1fre{
        padding: 10px;
        width: 455px;
    height: 200px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: -130px;
    margin-left: 130px;
    }
    
    .chatbox151fre{
        width: 81px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    position: relative;
    margin-top: -2.2%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: -110%;
    }
    
    .chatboxtext51{
        padding: 20px;
        width: 399px;
    height: 194px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .startedboxfre{
        margin-top: 5em;
        width: 1207px;
    height: 227px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 30%;
    margin-left: 4%;    
    }
    
    .subbuttonfre{
        margin-left: 960px;
        margin-top: -450px;
        position: absolute;
        height: 50px;
        width: 175px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }
    
    .startheadfre{
        width: 1000px;
    height: 87px;
    margin-left: 60px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    
    }
    
    .regisfre{
        width: 90px;
    height: 19px;
        margin-top: 12px;
        margin-left: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    
    }

    .strengthvalues4m{
        display: none;
    }
    
    .startparalapscreen{
        width: 613px;
    height: 62px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 105px;
    margin-top: -40%;
    margin-bottom: 3em;
    /* margin-bottom: 13%; */
    }

    .phonebodymobscreen{
        display: none;
    }

    .startparamobfre{
        display: none;
    }
    
    .bluec{
        width: 54px;
    height: 54px;
    background: rgba(7, 60, 165, 1);
    margin-left: 380px;
    margin-top: 42%;
    border-radius: 100%;
    }
    
    .whitea{
        width: 30px;
    height: 60px;
    }
    
    .namesm{
        width: 250px;
    height: 30px;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .desgsm{
        width: 134px;
    height: 30px;
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-top: -30px;
    margin-left: 28%;
    }
    
    .logos{
        /* padding: 20px 0 0 0; */
        /* padding-right: 0%; */
        /* margin-left: 0%; */
        white-space: nowrap;
    
    }
    
    .logos:hover .logo-slidefre 
    {
        /* animation-play-state: paused ; */
    }
    
    .logo-slidefre{
        display: inline-block;
        animation:120s slide infinite linear;
    }
    
    
    
    .logo-slidefre img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logoss{
        /* overflow: hidden; */
        /* padding: 20px 0; */
        white-space: nowrap;
    
    }
    
    .logoss:hover .logos-slidefre 
    {
        /* animation-play-state: paused ; */
    }
    
    .logos-slidefre{
        display: inline-block;
        animation: 80s slides infinite linear;
    }
    
    .logos-slidefre img{
        margin: 0 40px;
    }
    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }

    .traincirclepad{
        display: none;
    }

    .head12tabfre{
        display: none;
    }
    .assesheadtitletab{
        display: none;
    }

    .assesshead1tabfre{
        display: none;
    }

    .assesshead2tab{
        display: none;
    }

    .register2tab{
        display: none;
    }

    .chatheadtitletab{
        display: none;
    }

    .chatboxtext1tab{
        display: none;
    }
    .chatbox111tab{
        display: none;
    }.chatboxtext21tab{
        display: none;
    }
    .chatbox121tab{
        display: none;
    }
    .chatimg2tab{
        display: none;
    }
    .chatimg4tabfre{
        display: none;
    }.chatboxtext41tab{
        display: none;
    }
    .chatbox141tab{
        display: none;
    }.chatimg5tabfre{
        display: none;
    }
    .chatboxtext51tab{
        display: none;
    }.chatbox151tabfre{
        display: none;
    }

    .startedtab{
        display: none;
    }
    .registertabfre{
        display: none;
    }

    .chatbox151mobfre{
        display: none;
    }

    .image-container2{
        margin-top: -10%;
        margin-left: 15%;
    }

}



@media (min-width:1227px) and (max-width:1399px){
    .fresherspagemain{
        max-width:1300px;
        transform: scale(0.83);
        transform-origin: top;
        margin-bottom: -800px;
      }

      /* box click element start */

    .clickihv1fre{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickihv2fre{
        width: 34px;
height: 34px;
position: relative;
top: -5px;
left: 90px;
    }

    .clickihv3fre{
        width: 34px;
height: 34px;
position: relative;
top: -35px;
left: 90px;
    }

    .clickhv1fre{
        width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
    }

    .clickhv2fre{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickhv3fre{
        width: 34px;
height: 34px;
position: relative;
top: -45px;
left: 90px;
    }

    .clichv1fre{
        width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
    }

    .clichv2fre{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clichv3fre{
        width: 34px;
height: 34px;
position: relative;
top: -45px;
left: 90px;
    }

    .clihv1fre{
        width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
    }

    .clihv2fre{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clihv3fre{
        width: 34px;
height: 34px;
position: relative;
top: -45px;
left: 90px;
    }

    /* box click elemet end */

      .assesheadtitfretab{
        display: none;
    }

      .chatbox1 {
        opacity: 0;
        transition: opacity 2s ease-in; 
      }
      
      .chatbox1.show {
        opacity: 1;
      }

      .chatbox2 {
        opacity: 0;
        transition: opacity 2s ease-in; /* Define transition */
      }
      
      .chatbox2.show {
        opacity: 1;
      }

      /* .extratestimonial {
        opacity: 0;
        transition: opacity 2s ease-in; 
      }
      
      .extratestimonial.show {
        opacity: 1;
      } */

      .chatbox4 {
        opacity: 0;
        transition: opacity 2s ease-in; /* Define transition */
      }
      
      .chatbox4.show {
        opacity: 1;
      }

      .giffre{
        position: absolute;
        top: -290px;
        left: 222px;
    }
    .whitearrowfre{
        margin-left: 30%;
        margin-top: 20%;
    }

    .head13tab{
        display: none;
    }
    .bimg1xt{
        position: absolute;
        top: 1400px;
    }
    .firstsignup{
        margin-top: -8%;
    }
    body{
        overflow-x: hidden;
    }
    
    .register1tfre{

        width: auto;
        padding-top: 6.3%;
        margin-left: auto;
        margin-right: auto;
        color: rgba(255, 255, 255, 1);

    }

    /* .instlogofr{
        width: 100px;
        height: 100px;
    } */
    
    
    .contentparafre{
        display: flex;
        justify-content: center;
    margin-top: 2%;
        margin-left: 2%;

        width: 584px;
height: 191px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 48px;
font-weight: 300;
line-height: 59.52px;
text-align: left;
    }

    .clickincorefre1{
        width: 34px;
height: 34px;
position: relative;
left: 250px;
top: -50px;
    }

    .clickincorefre2{
        width: 34px;
height: 34px;
position: relative;
left: 855px;
top: -48px;
    }

    .clickincorefre3{
        width: 34px;
height: 34px;
position: relative;
left: 800px;
top: -55px;
    }

    .clickincorefre4{
        width: 34px;
height: 34px;
position: relative;
left: 1080px;
top: -8px;
    }

    .brandhovercontainerexp{
        position: relative;
    }
    
    .accesshovercontainerexp{
        position: relative;
    }
    
    .autohovercontainerexp{
        position: relative;
    }
    
    .hirehovercontainerexp{
        position: relative;
    }
    
    
    .imagecontainerbrandexp{
        
    }

    .containerfrecore{
        position: absolute;
    }

    .mid3contentfresher img{
        cursor: pointer;
    }
    
    .imagecontaineraccessexp{
        position: relative;
        margin-top: -2.4%;
    }
    
    .imagecontainerautoexp{
        position: relative;
        margin-top: 0%;
    }
    
    .imagecontainerhireexp{
        position: relative;
        margin-top: -1.5%;
    }

    /* .brandhovercontentexp{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        transition: opacity 0.3s ease;
        position: relative;
        top: -0.7%;
      left: 5%;
      opacity: 0;
      pointer-events: none;
      z-index: 2;
    } */


    .brandhovercontentfr{
        position: relative;
        margin-top: -43%;
        left: 70px;
        background-color: #ffffff;
        width: 1140px;
        height: 470px;
        z-index: 1;
    }

    .autohovercontentfr{
        position: relative;
        margin-top: -44.5%;
        left: 70px;
        background-color: #ffffff;
        width: 1140px;
        height: 480px;
        z-index: 1;
    }

    .hirehovercontentfr{
        position: relative;
        margin-top: -43%;
        left: 70px;
        background-color: #ffffff;
        width: 1140px;
        height: 480px;
        z-index: 1;
    }



    .accesshovercontentfr{
        position: relative;
        margin-top: -40.5%;
        left: 70px;
        background-color: #ffffff;
        width: 1140px;
        height: 480px;
        z-index: 1;
    }
    
    /* .accesshovercontentfre{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        transition: opacity 0.3s ease;
        position: absolute;
        margin-top: -35.2%;
        top: -0.7%;
      left: 5%;
      opacity: 0;
      pointer-events: none;
      z-index: 2;
    } */
    
    /* .autohovercontentexp{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        transition: opacity 0.3s ease;
        position: absolute;
        margin-top: -35.9%;
        top: -0.7%;
      left: 5%;
      opacity: 0;
      pointer-events: none;
      z-index: 2;
    }
    
    .hirehovercontentexp{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        transition: opacity 0.3s ease;
        position: absolute;
        margin-top: -37%;
        top: -0.7%;
      left: 5%;
      opacity: 0;
      pointer-events: none;
      z-index: 2;
    } */
    
    
    
    /* .imagecontainerbrandexp:hover + .brandhovercontentexp {
        opacity: 1; 
      }
     */
      /* .imagecontaineraccessexp:hover + .accesshovercontentfre {
        opacity: 1; 
      } */
    
      /* .imagecontainerautoexp:hover + .autohovercontentexp {
        opacity: 1; 
      }
    
      .imagecontainerhireexp:hover + .hirehovercontentexp {
        opacity: 1; 
      } */
    
    
    
    .brandcontentexp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
    }
    
    .brandheaderexp{
        width: 660px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 5%;
    position: absolute;
    margin-top: 2.5%;
    }
    
    .brandparaexp{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .brandhoverimagesfre{
        margin-left: 85%;
        margin-top: -40.3%;
        position: absolute;
    }
    
    .hovfrimg1brand{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    position: absolute;
    }
    
    .brandhovfrimg1{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    position: relative;
    margin-bottom: -30px;
    }
    
    .hovfrimg2brand{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-top: 5%;
    position: absolute;
    }
    
    .brandhovfrimg2{
        width: 141px;
        height: 145px;
        border-radius: 20px;
        background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
        opacity: 45%;
        top: 7.5px;
        position: relative;
        margin-bottom: -30px;
    }
    
    .hovfrimg3brand{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-top: 10%;
    position: absolute;
    }
    
    .brandhovfrimg3{
        width: 141px;
        height: 145px;
        border-radius: 20px;
        background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
        opacity: 45%;
        top: 14px;
        position: relative;
    }
    
    
    
    
    .accesscontentexp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
    }
    
    .accessheaderexp{
        width: 900px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 2.5%;
    margin-left: 5%;
    }
    
    .accessparaexp{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .accesshoverimagesfre{
        margin-left: 85%;
        margin-top: -40.3%;
        position: absolute;
    }
    
    .hoverimg1accessexp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        position: absolute;
    }
    
    .accesshoverimg1bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    margin-bottom: -16%;
    }
    
    
    .hoverimg2accessexp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }
    
    .accesshoverimg2bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    margin-bottom: -16%;
    }

    .accesshoveimg2bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -16%;
    }

    .accesshovimg2bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -16%;
    }
    
    .hoverimg3accessexp{
        position: absolute;
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
    }
    
    .accesshoverimg3bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    opacity: 45%;
    margin-bottom: 2%;
    }

    .accesshoveimg3bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    opacity: 45%;
    margin-bottom: 2%;
    }

    .accesshovimg3bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    margin-bottom: 2%;
    }
    
    .hireheaderexp{
        width: 700px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 2.5%;
    margin-left: 5%;
    }
    
    .hireparaexp{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .hirecontentexp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
    
    }
    
    
    .autocontentexp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%);
    
    }
    
    
    
    .watchs{
        padding-top: 4.5%;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(7, 60, 165, 1);

    }
    
    .fresher1tag{
        /* align-items: center; */
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    display: flex;
    margin-top: 3%;
    /* text-indent: 10px; */
    /* margin-top: -20%; */
    margin-left: 7%;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 234px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    
    }
    .luck{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  display: inline-block;
                  font-weight: 900;
    
    }
    .ftext{
        /* margin-top: 10px; */
        top: 15px;
        position: absolute;
        left: 70px;
    }
    
    .image-container{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
        margin-left: 26%;
        
    
    }


    
    .img-fluid1{
        width: 375px;
height: 456.75px;
    margin-top: -1.7%;
    position: absolute;
    margin-left: 0%;
    }
    .orgvector{
        /* margin-left: 50%; */
        width: 313px;
    height: 430px;
    background-color: rgba(255, 91, 0, 1);
    border-top-right-radius: 90%;
    /* display: inline-block; */
    }
    
    .violetbox{
        background: rgba(130, 27, 97, 1);
        width: 203px;
    height: 203px;
    display: flex;
    position: relative;
    margin-left: 3%;
    margin-top: -32%;
    display: inline-block;
    text-align: start;
    }
    
    .vt1fre{
        width: 91px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    margin-top: 18%;
    margin-left: 15%;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    }
    .image-container4{
        position: relative;
        margin-left: 22%;
        margin-top: -10%;
    }
    
    .vt2fre{
        width: 83px;
    height: 19px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    margin-top: 80px;
    margin-left: -88px;
    }
    
    .yellowbox{
        background: rgba(247, 180, 0, 1);
        width: 118px;
    height: 118px;
    display: flex;
    margin-left: 11.5%;
    /* margin-right: 190px; */
    display: inline-block;
    position: relative;
    margin-top: -9%;
    text-align: start;
    }
    
    .yt1fre{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    }
    
    .yt2{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 10px;
    
    }
    
    .sechead{
        margin-top: 5%;
    }
    
    .siginslet{
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 7%;
    /* padding-top: 2%; */
    color: rgba(255, 255, 255, 1);
    
    
    }
    
    .signinss
    {
        width: 173px;
    height: 60px;
    position: relative;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: -10%;
    margin-left: 8%;
    background: rgba(7, 60, 165, 1);
    border: none;
    
    }
    
    .watchsss{
        width: 200px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: -10%;
    margin-left: -380px;
    background-color: white;
    border: 1px solid rgba(7, 60, 165, 1);

    }
    
    .linetextttfre{
        width: 1100px;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    margin-top: 25%;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center ;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5em;
    }

    .linetextttfre2{
        width: 1100px;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    /* margin-top: 25%; */
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center ;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5em;
    }
    
    .lineboxfre{
    
        width: 160px;
    height: 160px;
    display: flex;
    /* margin-right: 100px; */
    display: inline-block;
    /* top: 0px; */
    position: relative;
    margin-top: -78%;
    text-align: start;
    border: 2px solid rgba(0, 0, 0, 1);
    margin-left: 21.5%;
    
    }
    
    .hlinebox1fre{
        width: 102px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 16%;
    position: absolute;
    opacity: 20%;
    
    }
    .hlinebox2fre{
        width: 40px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 30%;
    opacity: 30%;
    position: absolute;
    
    }
    
    
    .rlinebox1fre{
        width: 159px;
    height: 102px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 10%;
    position: absolute;
    }
    
    .rlinebox2fre{
        width: 159px;
    height: 40px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 50%;
    }
    
    .lineboxtextfre{
        width: 64px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 21px;
    margin-left: 17px;
    color: rgba(0, 0, 0, 1);
    
    }
    .lineboxtext2fre{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    }

    .title1tagfre{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 170px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }
    
    .title1taglogofre{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    margin-top: 4em;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 390px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }

    .title2tagexp2gfre{
        width: 1050px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: auto;
margin-right: auto;
    }

    .imagecontainerbrand {
        position: relative;
    }

    .mid3contentfresher{
        margin-top: 0%;
        /* margin-left: -3%; */
    }
    
    .mid3contentfresher .img1{
        width: 312px;
    height: 462px;
    margin-left: 90px;
    margin-top: -5px;
    border-radius: 50px;
    }
    .mid3contentfresher .bimg1{
        width: 312px;
    height: 462px;
    position: absolute;
    margin-top: -0.3%;
    margin-left: -25.25%;
    border-radius: 50px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    }
    
    .mid3contentfresher .bimg1textfre{
        width: 200px;
    height: 84px;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    opacity: 100%;
    position: relative;
    /* display: block; */
    margin-top: -150px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-left: 120px;
    margin-bottom: 4.5%;
    
    }
    
    
    .imagecontaineraccess{
        position: relative;
    }
    
    .mid3contentfresher .img2{
        width: 513px;
    height: 231px;
    margin-top: -37%;
    margin-left: 34%;
    margin-bottom: 40%;
    border-radius: 50px;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    .mid3contentfresher .bimg2{
        width: 513px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-top: -37%;
    margin-left: -41.6%;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    
    }
    
    .bimg2textfre{
        width: 396px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -45%;
    margin-left: 450px;
    opacity: 100%;
    position: relative;
    display: block;
    }
    
    .imagecontainerauto{
        position: relative;
    }
    
    .title2taglogofre{
        /* width: 672px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 310px; */

    width: 1171px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    /* margin-left: 79px; */
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    }
    
    
      
    
    .title2tagfre{
    width: 699px;
height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 86.8px;
    text-align: center;   
    margin-left: auto;
    margin-right: auto;
    }

    .title2tagfre2{

        width: 1050px;
height: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0.2em;
        }
    
    .mid3contentfresher .img3{
        width: 768px;
    height: 212px;
    border-radius: 50px;
    margin-top: -18.5%;
    margin-left: 34%;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    
    .mid3contentfresher .bimg3{
        width: 768px;
    height: 212px;
    margin-top: -16.8%;
    margin-left: -62.3%;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    }
    
    .mid3contentfresher .bimg3textfre{
        width: 345px;
    height: 84px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    margin-left: 37%;
    display: block;
    margin-top: -9%;
    color:rgba(255, 255, 255, 1);
    
    }
    
    .imagecontainerhire{
        position: relative;
    }
    
    
    .mid3contentfresher .img4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    margin-top: -62.3%;
    margin-left: 77%;
    /* opacity: 45%px; */
    
    
    }
    
    .mid3contentfresher .bimg4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-left: -18.8%;
    margin-top: -39.3%;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    }
    
    .bimg4textfre{
        width: 72px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    position: relative;
    display: block;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -34%;
    margin-left: 79%;
    
    }
    
    
    
/*     
    .phonebox{
        margin: 14%;
    } */
    
    .phonetitle1tagfre{
        margin-top: 4em;
        /* margin-top: 100px; */
        /* align-items: center; */
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    /* position: relative; */
    /* text-indent: 10px; */
    margin-left: -10%;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 430px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }
    
    .phoneheadtitlefre{
        margin-top: -4%;
        margin-left: -10%;
        width: 630px;
        height: 174px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;    
    }
    
    .phonebodyfre{
        margin-top: 19%;
        width: 500px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: -42%;
    }
    
    .phones{
        width: 351px;
    height: 644px;
    margin-top: -510px;
    margin-left: 150px;
    position: relative;
    }
    
    .org1circle{
        width: 240.93px;
    height: 240.93px;
    margin-top: -170px;
    border-radius: 100%;
    margin-left: 120px;
    /* background: rgba(247, 91, 2, 1); */
    position: relative;
    /* opacity: 90%; */
    }
    
    .vio1circle{
        width: 240.93px;
    height: 240.93px;
    border-radius: 100%;
    /* background: rgba(130, 27, 97, 1); */
    margin-top: -120px;
    position: relative;
    opacity: 90%;
    }
    
    .vio1circle::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 12%;
      width: 240.93px;
    height: 240.93px;
      border-radius: 50%;
      opacity: 100%;
    }
    
    .org1circle::before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 240.93px;
    height: 240.93px;
      border-radius: 50%;
      opacity: 90%;
    }
    
    .org1circle::before {
        background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
      }
      
      .vio1circle::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }
    
    .blue1circle{
        width: 364.7px;
    height: 364.7px;
    border-radius: 100%;
    background: rgba(135, 189, 255, 1);
    margin-top: -400px;
    margin-left: 220px;
    position: absolute;
    }
    
    .arrowfre{
        width: 256.46px;
    height: 189.91px;
    margin-left: -130px;
    margin-top: -150px;
    }
    .image-phone-container{
        margin-left: 500px;
        margin-top: -400px;
        margin-bottom: -1em;
    }
    
    .phonetitles{
        margin-left: -130px;
    }
    

    
    .trainingtitles{
        margin-top: -590px;
    }
    
    .traintitle1tagfre{
        width: 225px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    }
    
    .trainheadtitletabfre{
        width: 542px;
        height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: left;
        position: relative;
        top: 130px;
    }

    .traincirclefr{
        width: 500px;
        height: 500px;
        background: rgba(247, 91, 2, 0.1);
        border-radius: 100%;
        margin-left: -250px;
        position: relative;
        top: -100px;
    }
    
    .head12fre{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    top: 130px;
    }
    
    .head13fre{
        margin-top: -80px;
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .registerfre{
        width: 280px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: -80px;
    background: rgba(7, 60, 165, 1);
    border: none;
    margin-bottom: -3%;
    
    
    }
    
    .assesscirclefre{
        width: 500px;
        height: 500px;
        background: rgba(9, 9, 63, 0.05);
        border-radius: 100%;
        margin-left: 1050px;
        margin-top: 5%
    }
    
    .assesstitle1tagfre{
        margin-top: -570px;
        margin-left: 990px;
        width: 225px;
        height: 64px;
        border-radius: 37.5px;
        background: rgba(232, 237, 252, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;    
    }
    
    .assesheadtitlefre{
        width: 693px;
        height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: right;
        margin-left: 540px;
        position: relative;
        top: -320px;
    }
    
    .assesshead1fre{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 160px;
    text-align: right;
    position: relative;
    top: -320px;
    margin-bottom: -180px;
    }
    
    .assesshead2fre{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 150px;
    text-align: right;
    margin-top: -50px;
    }
    
    .register2fre{
        width: 240px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 980px;
    margin-top: -25px;
    border: none;
    background: rgba(7, 60, 165, 1);
    }
    
    .chatfre{
        margin-top: -350px;
    }
    
    .chatitle1tagfre{
        width: 360px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    /* margin-top: 4em; */
    margin-top: 10%;
    }
    
    .ctext{
        margin-left: 30px;
        margin-top: 15px;
    }
    
    .chatheadtitlefre{
        width: 600px;
    height: 110px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .chatimg1fre{
        width: 87px;
    height: 87px;
    }
    .chatbox111fre{
        clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
        width: 84.16px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    margin-left: -116%;
    /* margin-top: -40.6%; */
position: relative;
    }
    
    .chatbox1s1fre{
        width: 378px;
    height: 615px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-left: 150px;
    margin-top: -80px;
    }
    
    .chatboxtext1{
        width: 370px;
    height: 529.92px;
    padding: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    .chatbox121fre{
        width: 81px;
    height: 64px;
    margin-top: -48%;
    margin-left: 62%;
    /* position: absolute; */
    position: relative;
    background: rgb(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    }
    
    
    .chatbox2s1{
        margin-left: -30px;
        width: 435px;
        height: 284px;
        border-radius: 50px 0px 50px 50px;
        background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext21{
        width: 365px;
    height: 150px;
        padding: 30px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg2fre{
        width: 87px;
    height: 87px;
    margin-left: 470px;
    margin-top: -10%;
    }
    
    .chatbox1331fre{
        width: 81px;
    height: 64px;
    background: rgb(207, 240, 230, 1);
    /* position: absolute; */
    position: relative;
    margin-top: -34%;
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    margin-left: 65%;
    }
    
    .chatbox331{
        padding: 10px;
        margin-top: 10%;
        width: 358px;
       margin-left: 10%;
    height: 201px;
    border-radius: 50px 0px 50px 50px;
    background: rgba(207, 240, 230, 1);
    /* background: #000000; */
    }
    
    .chatboxtext331{
        padding: 20px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg33fre{
        margin-top: -8%;
        margin-left: 74%;
    }
    
    .chatimg4fre{
        width: 87px;
    height: 87px;
    margin-top: -480px;
    margin-left: -40px;
    }
    
    .chatbox141fre{
        width: 81px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    /* position: absolute; */
    position: relative;
    margin-top: -2.3%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: -100%;
    }
    
    .chatbox4s1{
        padding: 10px;
        width: 475px;
    height: 220px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: -300px;
    margin-left: 100px;
    }
    .chatboxtext41{
        width: 388px;
    height: 90px;
        padding: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    
    }
    
    
    .chatimg5fre{
        width: 87px;
    height: 87px;
        margin-top: -130px;
        margin-left: -10px;
    }
    
    .chatbox5s1fre{
        padding: 10px;
        width: 455px;
    height: 200px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: -130px;
    margin-left: 130px;
    }
    
    .chatbox151fre{
        width: 81px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    position: relative;
    margin-top: -2%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: -110%;
    }
    
    .chatboxtext51{
        padding: 20px;
        width: 399px;
    height: 194px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .startedfre{
        margin-bottom: 4em;
    }

    .startedboxfre{
        margin-top: 8em;
        width: 1207px;
    height: 227px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 30%;
    margin-left: 4%;    
    }
    
    .subbuttonfre{
        margin-left: 960px;
        margin-top: -450px;
        position: absolute;
        height: 50px;
        width: 175px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }
    
    .startheadfre{
        width: 1000px;
    height: 87px;
    margin-left: 60px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    }
    
    .regisfre{
        width: 90px;
    height: 19px;
        margin-top: 12px;
        margin-left: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    
    }

    .strengthvalues4m{
        display: none;
    }
    
    .startparalapscreen{
        width: 613px;
    height: 62px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 105px;
    margin-top: -40%;
    margin-bottom: 3em;
    /* margin-bottom: 13%; */
    }

    .phonebodymobscreen{
        display: none;
    }

    .startparamobfre{
        display: none;
    }
    
    .bluec{
        width: 54px;
    height: 54px;
    background: rgba(7, 60, 165, 1);
    margin-left: 380px;
    margin-top: 42%;
    border-radius: 100%;
    }
    
    .whitea{
        width: 30px;
    height: 60px;
    }
    
    .namesm{
        width: 250px;
    height: 30px;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .desgsm{
        width: 134px;
    height: 30px;
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-top: -30px;
    margin-left: 28%;
    }
    
    .logos{
        /* padding: 20px 0 0 0; */
        /* padding-right: 0%; */
        /* margin-left: 0%; */
        white-space: nowrap;
    
    }
    
    .logos:hover .logo-slidefre 
    {
        /* animation-play-state: paused ; */
    }
    
    .logo-slidefre{
        display: inline-block;
        animation: 120s slide infinite linear;
    }
    
    
    
    .logo-slidefre img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logoss{
        /* overflow: hidden; */
        /* padding: 20px 0; */
        white-space: nowrap;
    
    }
    
    .logoss:hover .logos-slidefre 
    {
        /* animation-play-state: paused ; */
    }
    
    .logos-slidefre{
        display: inline-block;
        animation: 80s slides infinite linear;
    }
    
    .logos-slidefre img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }

    .traincirclepad{
        display: none;
    }

    .head12tabfre{
        display: none;
    }
    .assesheadtitletab{
        display: none;
    }

    .assesshead1tabfre{
        display: none;
    }

    .assesshead2tab{
        display: none;
    }

    .register2tab{
        display: none;
    }

    .chatheadtitletab{
        display: none;
    }

    .chatboxtext1tab{
        display: none;
    }
    .chatbox111tab{
        display: none;
    }.chatboxtext21tab{
        display: none;
    }
    .chatbox121tab{
        display: none;
    }
    .chatimg2tab{
        display: none;
    }
    .chatimg4tabfre{
        display: none;
    }.chatboxtext41tab{
        display: none;
    }
    .chatbox141tab{
        display: none;
    }.chatimg5tabfre{
        display: none;
    }
    .chatboxtext51tab{
        display: none;
    }.chatbox151tabfre{
        display: none;
    }

    .startedtab{
        display: none;
    }
    .registertabfre{
        display: none;
    }

    .chatbox151mobfre{
        display: none;
    }

    .image-container2{
        margin-top: -10%;
        margin-left: 15%;
    }

}
/* need one more media query 1028 - 1227 */

@media (min-width:1028px) and (max-width:1226px){

    .fresherspagemain{
        max-width:1300px;
        transform: scale(0.83);
        transform-origin: top;
        margin-bottom: -900px;
        
      }

      
      /* box click element start */

    .clickihv1fre{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickihv2fre{
        width: 34px;
height: 34px;
position: relative;
top: -5px;
left: 90px;
    }

    .clickihv3fre{
        width: 34px;
height: 34px;
position: relative;
top: -35px;
left: 90px;
    }

    .clickhv1fre{
        width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
    }

    .clickhv2fre{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickhv3fre{
        width: 34px;
height: 34px;
position: relative;
top: -45px;
left: 90px;
    }

    .clichv1fre{
        width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
    }

    .clichv2fre{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clichv3fre{
        width: 34px;
height: 34px;
position: relative;
top: -45px;
left: 90px;
    }

    .clihv1fre{
        width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
    }

    .clihv2fre{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clihv3fre{
        width: 34px;
height: 34px;
position: relative;
top: -45px;
left: 90px;
    }

    /* box click elemet end */

    .assesheadtitfretab{
        display: none;
    }

    /* circle animation  */

    @keyframes moveUp {
        0% { transform: translateY(30px); }
        100% { transform: translateY(-10px); }
    }
    
    .org1circle, .vio1circle, .blue1circle {
        
        animation: none; /* Initially no animation */
    }
    
    .scrolling .org1circle {
        animation: moveUp 0.8s forwards; /* Play animation once and stop */
    }
    
    .scrolling .vio1circle {
        animation: moveUp 1.5s forwards; /* Slightly delay the animation */
    }
    
    .scrolling .blue1circle {
        animation: moveUp 2s forwards; /* Slightly delay the animation */
    }
    
    
  
    

    /* .parallax-circle{
        animation-name: dance;
        transition: 1s;
        transform: translateY(-10px);
  animation-duration: 1s;
  animation-iteration-count: infinite;
    }
    
    @keyframes dance {
        0% { transform: translateY(0); }
        50% { transform: translateY(-30px); }
        100% { transform: translateY(0); }
      } */

      .parallax-circle {
        /* width: 100px;
        height: 100px;
        background-color: blue;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer; */
        transition: transform 0.5s ease; /* Smooth transition for the dancing effect */
      }
      
      .parallax-circle.dancing {
        transform: translateY(-25px); /* Adjust the value as needed */
        animation: 60s ease-out alternate; /* Animation for the dancing effect */
      }


    
    /* FOR BOXES */
    /* .dancing-divv {
        opacity: 0;
        transform: translateY(-400px);
        transition: 1s ;
    }
      
      .dancing-divv.visible {
        opacity: 1;
        transform: translateY(-100%);
        animation:  0.5s ease-in-out forwards ;
      }
      
      @keyframes shake {
        0% { transform: translateX(0); }
        25% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
        75% { transform: translateX(-10px); }
        100% { transform: translateX(0); }
      }
      
    
      

 
    .dancing-div {
        transition: transform 0.5s ease; 
      }
      
      .dancing-div.dancing {
        transform: translateY(-25px); 
        animation: 60s ease-out alternate; 
      }
      
      @keyframes bounce {
        from {
          transform: translateY(-25px);
        }
        to {
          transform: translateY(0);
        }
      } */

    .whitearrowfre{
        margin-left: 30%;
        margin-top: 20%;
    }

    .head13tab{
        display: none;
    }
    .bimg1xt{
        position: absolute;
        top: 1400px;
    }
    .firstsignup{
        margin-top: -5%;
    }
    body{
        overflow-x: hidden;
    }
    
    .register1tfre{

        width: auto;
        padding-top: 6.3%;
        margin-left: auto;
        margin-right: auto;
        color: rgba(255, 255, 255, 1);

    }

    .instlogofr{
        /* width: 100px; */
        height: 100px;
    }
    
    
    .contentparafre{
        display: flex;
        justify-content: center;
    margin-top: 2%;
        margin-left: 2%;
        width: 584px;
height: 191px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 48px;
font-weight: 300;
line-height: 59.52px;
text-align: left;
    }

    .giffre{
        position: absolute;
        top: -290px;
        left: 222px;
    }

    .clickincorefre1{
        width: 34px;
height: 34px;
position: relative;
left: 185px;
top: -45px;
    }
    
    .clickincorefre2{
        width: 34px;
height: 34px;
position: relative;
left: 780px;
top: -50px;
cursor: pointer;
    }

    .clickincorefre3{
        width: 34px;
height: 34px;
position: relative;
left: 730px;
top: -50px;
    }

    .clickincorefre4{
        width: 34px;
height: 34px;
position: relative;
left: 1015px;
top: -7px;
    }

    .brandhovercontainerexp{
        position: relative;
    }
    
    .accesshovercontainerexp{
        position: relative;
    }
    
    .autohovercontainerexp{
        position: relative;
    }
    
    .hirehovercontainerexp{
        position: relative;
    }
    
    
    .imagecontainerbrandexp{
        
    }

    .containerfrecore{
        position: absolute;
    }

    .mid3contentfresher img{
        cursor: pointer;
    }
    
    .imagecontaineraccessexp{
        position: relative;
        margin-top: -2.4%;
    }
    
    .imagecontainerautoexp{
        position: relative;
        margin-top: 0%;
    }
    
    .imagecontainerhireexp{
        position: relative;
        margin-top: -1.5%;
    }

    /* .brandhovercontentexp{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        transition: opacity 0.3s ease;
        position: relative;
        top: -0.7%;
      left: 5%;
      opacity: 0;
      pointer-events: none;
      z-index: 2;
    } */


    .brandhovercontentfr{
        position: relative;
        margin-top: -42.78%;
        left: 20px;
        background-color: #ffffff;
        width: 1140px;
        height: 470px;
        z-index: 1;
    }

    .autohovercontentfr{
        position: relative;
        margin-top: -44%;
        left: 15px;
        background-color: #ffffff;
        width: 1140px;
        height: 480px;
        z-index: 1;
    }

    .hirehovercontentfr{
        position: relative;
        margin-top: -42.5%;
        left: 15px;
        background-color: #ffffff;
        width: 1140px;
        height: 480px;
        z-index: 1;
    }



    .accesshovercontentfr{
        position: relative;
        margin-top: -40.5%;
        left: 15px;
        background-color: #ffffff;
        width: 1140px;
        height: 480px;
        z-index: 1;
    }
    
    /* .accesshovercontentfre{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        transition: opacity 0.3s ease;
        position: absolute;
        margin-top: -35.2%;
        top: -0.7%;
      left: 5%;
      opacity: 0;
      pointer-events: none;
      z-index: 2;
    } */
    
    /* .autohovercontentexp{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        transition: opacity 0.3s ease;
        position: absolute;
        margin-top: -35.9%;
        top: -0.7%;
      left: 5%;
      opacity: 0;
      pointer-events: none;
      z-index: 2;
    }
    
    .hirehovercontentexp{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        transition: opacity 0.3s ease;
        position: absolute;
        margin-top: -37%;
        top: -0.7%;
      left: 5%;
      opacity: 0;
      pointer-events: none;
      z-index: 2;
    } */
    
    
    
    /* .imagecontainerbrandexp:hover + .brandhovercontentexp {
        opacity: 1; 
      }
     */
      /* .imagecontaineraccessexp:hover + .accesshovercontentfre {
        opacity: 1; 
      } */
    
      /* .imagecontainerautoexp:hover + .autohovercontentexp {
        opacity: 1; 
      }
    
      .imagecontainerhireexp:hover + .hirehovercontentexp {
        opacity: 1; 
      } */
    
    
    
    .brandcontentexp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
    }
    
    .brandheaderexp{
        width: 660px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 5%;
    position: absolute;
    margin-top: 2.5%;
    }
    
    .brandparaexp{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .brandhoverimagesfre{
        margin-left: 85%;
        margin-top: -40.3%;
        position: absolute;
    }
    
    .hovfrimg1brand{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    position: absolute;
    }
    
    .brandhovfrimg1{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    position: relative;
    margin-bottom: -30px;
    }
    
    .hovfrimg2brand{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-top: 5%;
    position: absolute;
    }
    
    .brandhovfrimg2{
        width: 141px;
        height: 145px;
        border-radius: 20px;
        background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
        opacity: 45%;
        top: 7.5px;
        position: relative;
        margin-bottom: -30px;
    }
    
    .hovfrimg3brand{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-top: 10%;
    position: absolute;
    }
    
    .brandhovfrimg3{
        width: 141px;
        height: 145px;
        border-radius: 20px;
        background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
        opacity: 45%;
        top: 14px;
        position: relative;
    }
    
    .accesscontentexp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
    }
    
    .accessheaderexp{
        width: 900px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 2.5%;
    margin-left: 5%;
    }
    
    .accessparaexp{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .accesshoverimagesfre{
        margin-left: 85%;
        margin-top: -40.3%;
        position: absolute;
    }
    
    .hoverimg1accessexp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        position: absolute;
    }
    
    .accesshoverimg1bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    margin-bottom: -16%;
    }
    
    
    .hoverimg2accessexp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }
    
    .accesshoverimg2bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    margin-bottom: -16%;
    }

    .accesshoveimg2bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -16%;
    }

    .accesshovimg2bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -16%;
    }
    
    .hoverimg3accessexp{
        position: absolute;
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
    }
    
    .accesshoverimg3bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    opacity: 45%;
    margin-bottom: 2%;
    }

    .accesshoveimg3bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    opacity: 45%;
    margin-bottom: 2%;
    }

    .accesshovimg3bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    margin-bottom: 2%;
    }
    
    .hireheaderexp{
        width: 700px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 2.5%;
    margin-left: 5%;
    }
    
    .hireparaexp{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .hirecontentexp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
    
    }
    
    
    .autocontentexp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%);
    
    }
    
    
    
   
    
    .fresher1tag{
        /* align-items: center; */
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    display: flex;
    /* text-indent: 10px; */
    margin-left: 7%;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 234px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    margin-top: 5%;
    }
    .luck{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  display: inline-block;
                  font-weight: 900;
    
    }
    .ftext{
        /* margin-top: 10px; */
        top: 15px;
        position: absolute;
        left: 70px;
    }
    
    .image-container{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
        margin-left: 26%;
        
    
    }
    
    .img-fluid1{
        width: 375px;
height: 456.75px;
    margin-top: -1.7%;
    position: absolute;
    margin-left: 0%;
    }
    .orgvector{
        /* margin-left: 50%; */
        width: 313px;
    height: 430px;
    background-color: rgba(255, 91, 0, 1);
    border-top-right-radius: 90%;
    /* display: inline-block; */
    }
    
    .violetbox{
        background: rgba(130, 27, 97, 1);
        width: 203px;
    height: 203px;
    display: flex;
    position: relative;
    margin-left: 3%;
    margin-top: -32%;
    display: inline-block;
    text-align: start;
    }
    
    .vt1fre{
        width: 91px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    margin-top: 18%;
    margin-left: 15%;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    }
    .image-container4{
        position: relative;
        margin-left: 22%;
        margin-top: -10%;
    }
    
    .vt2fre{
        width: 83px;
    height: 19px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    margin-top: 80px;
    margin-left: -88px;
    }
    
    .yellowbox{
        background: rgba(247, 180, 0, 1);
        width: 118px;
    height: 118px;
    display: flex;
    margin-left: 10%;
    /* margin-right: 190px; */
    display: inline-block;
    position: relative;
    margin-top: -9%;
    text-align: start;
    }
    
    .yt1fre{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    }
    
    .yt2{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 10px;
    
    }
    
    .sechead{
        margin-top: 5%;
    }
    
    .siginslet{
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 7%;
    /* padding-top: 2%; */
    color: rgba(255, 255, 255, 1);
    
    
    }
    
    .signinss
    {
        width: 173px;
    height: 60px;
    position: relative;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: -10%;
    margin-left: 8%;
    background: rgba(7, 60, 165, 1);
    border: none;
    
    }
    
    .watchsss{
        width: 200px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-top: -3%;
    position: relative;
    margin-left: -350px;
    background-color: white;
    border: 1px solid rgba(7, 60, 165, 1);
    cursor: pointer !important;
    text-transform:capitalize;
    }
    .watchs{
        /* padding-top: 4.5%; */
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(7, 60, 165, 1);
    }
    
    .linetextttfre{
        width: 1100px;
    height: fit-content;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    margin-top: 7%;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center ;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5em;
    margin-top: 300px;
    }

    .linetextttfre2{
        width: 1100px;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    /* margin-top: 25%; */
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center ;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5em;
    }
    
    .lineboxfre{
    
        width: 160px;
    height: 160px;
    display: flex;
    /* margin-right: 100px; */
    display: inline-block;
    /* top: 0px; */
    position: relative;
    margin-top: -83%;
    text-align: start;
    border: 2px solid rgba(0, 0, 0, 1);
    margin-left: 19.8%;
    
    }
    
    .hlinebox1fre{
        width: 102px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 16%;
    position: absolute;
    opacity: 20%;
    
    }
    .hlinebox2fre{
        width: 40px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 30%;
    opacity: 30%;
    position: absolute;
    
    }
    
    
    .rlinebox1fre{
        width: 159px;
    height: 102px;
    /* padding: 0px, 0px, 0px, 0px; */
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 10%;
    position: absolute;
    }
    
    .rlinebox2fre{
        width: 159px;
    height: 40px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 50%;
    }
    
    .lineboxtextfre{
        width: 64px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 21px;
    margin-left: 17px;
    color: rgba(0, 0, 0, 1);
    
    }
    .lineboxtext2fre{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    }

    .title1tagfre{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    /* text-indent: 10px; */
    /* margin-top: 6%; */
    /* margin-left: 40%; */
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 170px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }
    
    
    
    .title1taglogofre{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    margin-top: 4em;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 390px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }
    .imagecontainerbrand {
        position: relative;
    }

    .mid3contentfresher{
        margin-top: 2%;
    }
    
    .mid3contentfresher .img1{
        width: 312px;
    height: 462px;
    margin-left: 20px;
    margin-top: -25px;
    border-radius: 50px;
    }

    .mid3contentfresher .bimg1{
        width: 312px;
    height: 462px;
    position: absolute;
    margin-top: -2.25%;
    margin-left: -27.9%;
    border-radius: 50px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    }
    
    .mid3contentfresher .bimg1textfre{
        width: 200px;
    height: 84px;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    opacity: 100%;
    position: relative;
    /* display: block; */
    margin-top: -150px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-left: 50px;
    margin-bottom: 4.5%;
    
    }
    
    
    .imagecontaineraccess{
        position: relative;
    }
    
    .mid3contentfresher .img2{
        width: 513px;
    height: 231px;
    margin-top: -41%;
    margin-left: 31%;
    margin-bottom: 40%;
    border-radius: 50px;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    .mid3contentfresher .bimg2{
        width: 513px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-top: -41%;
    margin-left: -45.85%;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    
    }
    
    .bimg2textfre{
        width: 396px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -46%;
    margin-left: 370px;
    opacity: 100%;
    position: relative;
    display: block;
    }
    
    .imagecontainerauto{
        position: relative;
    }
    
    .title2taglogofre{
        /* width: 672px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 310px; */

    width: 1171px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    /* margin-left: 79px; */
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    }
    
    .title2tagexp2gfre{
        width: 1050px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: auto;
margin-right: auto;
    }

    .title2tagfre{
    width: 699px;
height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 86.8px;
    text-align: center;   
    margin-left: auto;
    margin-right: auto;
    }

    .title2tagfre2{

        width: 1050px;
height: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0.2em;
        }
    
    .mid3contentfresher .img3{
        width: 768px;
    height: 212px;
    border-radius: 50px;
    margin-top: -21%;
    margin-left: 31%;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    
    .mid3contentfresher .bimg3{
        width: 768px;
    height: 212px;
    margin-top: -18.7%;
    margin-left: -68.6%;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    }
    
    .mid3contentfresher .bimg3textfre{
        width: 345px;
    height: 84px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    margin-left: 34%;
    display: block;
    margin-top: -11%;
    color:rgba(255, 255, 255, 1);
    
    }
    
    .imagecontainerhire{
        position: relative;
    }
    
    
    .mid3contentfresher .img4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    margin-top: -67%;
    margin-left: 78.5%;
    /* opacity: 45%px; */
    
    
    }
    
    .mid3contentfresher .bimg4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-left: -20.5%;
    margin-top: -42.5%;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    }
    
    .bimg4textfre{
        width: 72px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    position: relative;
    display: block;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -37%;
    margin-left: 80%;
    }

    .phonetitle1tagfre{
        margin-top: 4em;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    margin-left: -10%;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 430px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }
    
    .phoneheadtitlefre{
        margin-top: -4%;
        margin-left: -10%;
        width: 630px;
        height: 174px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;    
    }
    
    .phonebodyfre{
        margin-top: 19%;
        width: 500px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: -42%;
    }
    
    .phones{
        width: 351px;
    height: 644px;
    margin-top: -510px;
    margin-left: 150px;
    position: relative;
    }
    
    .org1circle{
        width: 240.93px;
    height: 240.93px;
    margin-top: -170px;
    border-radius: 100%;
    margin-left: 120px;
    /* background: rgba(247, 91, 2, 1); */
    position: relative;
    /* opacity: 90%; */
    animation-name: dance;
    animation-delay: 0s;
    }
    
    .vio1circle{
        width: 240.93px;
    height: 240.93px;
    border-radius: 100%;
    /* background: rgba(130, 27, 97, 1); */
    margin-top: -120px;
    position: relative;
    opacity: 90%;
    animation-name: dance;
    animation-delay: 0.15s;
    }
    
    .vio1circle::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 12%;
      width: 240.93px;
    height: 240.93px;
      border-radius: 50%;
      opacity: 100%;
    }
    
    .org1circle::before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 240.93px;
    height: 240.93px;
      border-radius: 50%;
      opacity: 90%;
    }
    
    .org1circle::before {
        background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
      }
      
      .vio1circle::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }
    
    .blue1circle{
        width: 364.7px;
    height: 364.7px;
    border-radius: 100%;
    background: rgba(135, 189, 255, 1);
    margin-top: -400px;
    margin-left: 220px;
    position: absolute;

    animation-name: dance;
    animation-delay: 0.3s;
    }
    
    .arrowfre{
        width: 256.46px;
    height: 189.91px;
    margin-left: -130px;
    margin-top: -150px;
    }
    .image-phone-container{
        margin-left: 500px;
        margin-top: -400px;
        margin-bottom: -1em;
    }
    
    .phonetitles{
        margin-left: -130px;
    }
    

    
    .trainingtitles{
        margin-top: -590px;
    }
    
    .traintitle1tagfre{
        width: 225px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    }
    
    .trainheadtitletabfre{
        width: 542px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    top: 130px;
    }

    .traincirclefr{
        width: 500px;
    height: 500px;
    background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -250px;
    position: relative;
    top: -100px;
    }
    
    .head12fre{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    top: 130px;
    }
    
    .head13fre{
        margin-top: -80px;
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .registerfre{
        width: 280px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: -80px;
    background: rgba(7, 60, 165, 1);
    border: none;
    margin-bottom: -3%;
    }
    
    .assesscirclefre{
        width: 500px;
    height: 500px;
    background: rgba(9, 9, 63, 0.05);
    border-radius: 100%;
    margin-left: 1050px;
    margin-top: 5%
    }
    
    .assesstitle1tagfre{
        margin-top: -570px;
        margin-left: 990px;
        width: 225px;
        height: 64px;
        border-radius: 37.5px;
        background: rgba(232, 237, 252, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;    
    }
    
    .assesheadtitlefre{
        width: 693px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: right;
    margin-left: 590px;
    position: relative;
    top: -320px;
    }
    
    .assesshead1fre{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 210px;
    text-align: right;
    position: relative;
    top: -320px;
    }
    
    .assesshead2fre{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 150px;
    text-align: right;
    margin-top: -50px;
    }
    
    .register2fre{
        width: 240px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 980px;
    margin-top: -25px;
    border: none;
    background: rgba(7, 60, 165, 1);
    }
    
    .chatfre{
        margin-top: -400px;
    }
    
    .chatitle1tagfre{
        width: 360px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    /* margin-top: 4em; */
    margin-top: 15%;
    }
    
    .ctext{
        margin-left: 30px;
        margin-top: 15px;
    }
    
    .chatheadtitlefre{
        width: 600px;
    height: 110px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .chatimg1fre{
        width: 87px;
    height: 87px;
    }
    .chatbox111fre{
        clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
        width: 84.16px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    margin-left: -116%;
    /* margin-top: -40.6%; */
position: relative;
    }
    
    .chatbox1s1fre{
        width: 378px;
    height: 615px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-left: 150px;
    margin-top: -80px;
    }
    
    .chatboxtext1{
        width: 370px;
    height: 529.92px;
    padding: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    .chatbox121fre{
        width: 81px;
    height: 64px;
    margin-top: -44.7%;
    margin-left: 62%;
    /* position: absolute; */
    position: relative;
    background: rgb(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    }

    .chatbox2{
        width: 660px;
        height: 300px;
    }


    
    
    .chatbox2s1{
        margin-left: -30px;
        width: 435px;
        height: 284px;
        border-radius: 50px 0px 50px 50px;
        background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext21{
        width: 365px;
    height: 150px;
        padding: 30px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg2fre{
        width: 87px;
    height: 87px;
    margin-left: 470px;
    margin-top: -10%;
    }
    
    .chatbox1331fre{
        width: 81px;
    height: 64px;
    background: rgb(207, 240, 230, 1);
    /* position: absolute; */
    position: relative;
    margin-top: -31.7%;
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    margin-left: 63%;
    }
    
    .chatbox331{
        padding: 10px;
        margin-top: 10%;
        width: 358px;
       margin-left: 10%;
    height: 201px;
    border-radius: 50px 0px 50px 50px;
    background: rgba(207, 240, 230, 1);
    /* background: #000000; */
    }
    
    .chatboxtext331{
        padding: 20px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg33fre{
        margin-top: -8%;
        margin-left: 74%;
    }
    
    .chatimg4fre{
        width: 87px;
    height: 87px;
    margin-top: -480px;
    margin-left: -40px;
    }
    
    .chatbox141fre{
        width: 81px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    /* position: absolute; */
    position: relative;
    margin-top: -2.3%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: -100%;
    }
    
    .chatbox4s1{
        padding: 10px;
        width: 475px;
    height: 220px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: -300px;
    margin-left: 100px;
    }
    .chatboxtext41{
        width: 388px;
    height: 90px;
        padding: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    
    }
    
    
    .chatimg5fre{
        width: 87px;
    height: 87px;
        margin-top: -130px;
        margin-left: -10px;
    }
    
    .chatbox5s1fre{
        padding: 10px;
        width: 455px;
    height: 200px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: -130px;
    margin-left: 130px;
    }
    
    .chatbox151fre{
        width: 81px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    position: relative;
    margin-top: -2%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: -110%;
    }
    
    .chatboxtext51{
        padding: 20px;
        width: 399px;
    height: 194px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .startedboxfre{
        margin-top: 5em;
        width: 1207px;
    height: 227px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 30%;
    margin-left: 4%;    
    }
    
    .subbuttonfre{
        margin-left: 960px;
        margin-top: -450px;
        position: absolute;
        height: 50px;
        width: 175px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }
    
    .startheadfre{
        width: 1000px;
    height: 87px;
    margin-left: 60px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    
    }
    
    .regisfre{
        width: 90px;
    height: 19px;
        margin-top: 12px;
        margin-left: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    
    }

    .strengthvalues4m{
        display: none;
    }
    
    .startparalapscreen{
        width: 613px;
    height: 62px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 105px;
    margin-top: -40%;
    margin-bottom: 3em;
    /* margin-bottom: 13%; */
    }

    .phonebodymobscreen{
        display: none;
    }

    .startparamobfre{
        display: none;
    }
    
    .bluec{
        width: 54px;
    height: 54px;
    background: rgba(7, 60, 165, 1);
    margin-left: 380px;
    margin-top: 42%;
    border-radius: 100%;
    }
    
    .whitea{
        width: 30px;
    height: 60px;
    }
    
    .namesm{
        width: 250px;
    height: 30px;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .desgsm{
        width: 134px;
    height: 30px;
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-top: -30px;
    margin-left: 28%;
    }
    
    .logos{
        /* padding: 20px 0 0 0; */
        /* padding-right: 0%; */
        /* margin-left: 0%; */
        white-space: nowrap;
    
    }
    
    .logos:hover .logo-slidefre 
    {
        /* animation-play-state: paused ; */
    }
    
    .logo-slidefre{
        display: inline-block;
        animation:120s slide infinite linear;
    }
    
    
    
    .logo-slidefre img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logoss{
        /* overflow: hidden; */
        /* padding: 20px 0; */
        white-space: nowrap;
    
    }
    
    .logoss:hover .logos-slidefre 
    {
        /* animation-play-state: paused ; */
    }
    
    .logos-slidefre{
        display: inline-block;
        animation: 80s slides infinite linear;
    }
    
    .logos-slidefre img{
        margin: 0 40px;
    }
    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }

    .traincirclepad{
        display: none;
    }

    .head12tabfre{
        display: none;
    }
    .assesheadtitletab{
        display: none;
    }

    .assesshead1tabfre{
        display: none;
    }

    .assesshead2tab{
        display: none;
    }

    .register2tab{
        display: none;
    }

    .chatheadtitletab{
        display: none;
    }

    .chatboxtext1tab{
        display: none;
    }
    .chatbox111tab{
        display: none;
    }.chatboxtext21tab{
        display: none;
    }
    .chatbox121tab{
        display: none;
    }
    .chatimg2tab{
        display: none;
    }
    .chatimg4tabfre{
        display: none;
    }.chatboxtext41tab{
        display: none;
    }
    .chatbox141tab{
        display: none;
    }.chatimg5tabfre{
        display: none;
    }
    .chatboxtext51tab{
        display: none;
    }.chatbox151tabfre{
        display: none;
    }

    .startedtab{
        display: none;
    }
    .registertabfre{
        display: none;
    }

    .chatbox151mobfre{
        display: none;
    }

    .image-container2{
        margin-top: -10%;
        margin-left: 15%;
    }

}


@media (min-width:1024.5px) and (max-width:1028px){
    .strengthvalues4m{
        display: none !important;
    }

    .title2tagfre2{
        width: 1050px;
height: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0.2em;
        }

        .title2tagfre{
            width: 699px;
        height: 87px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 70px;
            font-weight: 400;
            line-height: 86.8px;
            text-align: center;   
            margin-left: auto;
            margin-right: auto;
            }
}


@media (min-width:831px) and (max-width:991.5px){
    .brandhfre{
        position: relative;
        top: 12%;
        margin-left: -1%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 30px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    width: 474px;
height: 42px;
    }

    .hirehfre{
        width: 476px;
height: 42px;
top: 16.5%;
position: relative;
        margin-left: 5%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 30px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;
    }

    .digitalhfre{
        top: 16%;
position: relative;
        margin-left: 5%;
        width: 640px;
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 30px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;
    }

    .trainhfre{
        top: 12%;
position: relative;
        margin-left: -1.5%;
width: 475px;
height: 24px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 30px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    }
}


@media (min-width:992px) and (max-width:1024px){
    .brandhfre{
        position: relative;
        top: 15%;
        margin-left: 4.5%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    width: 474px;
height: 42px;
    }

    .hirehfre{
        width: 476px;
height: 42px;
top: 19.5%;
position: relative;
        margin-left: 5%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;
    }

    .digitalhfre{
        top: 19%;
position: relative;
        margin-left: 5%;
        width: 640px;
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;
    }

    .trainhfre{
        top: 15%;
position: relative;
        margin-left: 4%;
width: 475px;
height: 24px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    }
}

@media (min-width:831px) and (max-width:1024px) {

    .chatbox1fre:hover ,
.chatbox2fre:hover,
.extratestimonial:hover,
.chatbox4fre:hover,
.chatbox5fre:hover
{
    transform: none; /* Scale up the element on hover */
  }



      /* circle animation  */

      @keyframes moveUp {
        0% { transform: translateY(30px); }
        100% { transform: translateY(-10px); }
    }
    
    .org1circle, .vio1circle, .blue1circle {
        
        animation: none; /* Initially no animation */
    }
    
    .scrolling .org1circle {
        animation: moveUp 0.8s forwards; /* Play animation once and stop */
    }
    
    .scrolling .vio1circle {
        animation: moveUp 1.5s forwards; /* Slightly delay the animation */
    }
    
    .scrolling .blue1circle {
        animation: moveUp 2s forwards; /* Slightly delay the animation */
    }
    
    
  
    

    /* .parallax-circle{
        animation-name: dance;
        transition: 1s;
        transform: translateY(-10px);
  animation-duration: 1s;
  animation-iteration-count: infinite;
    }
    
    @keyframes dance {
        0% { transform: translateY(0); }
        50% { transform: translateY(-30px); }
        100% { transform: translateY(0); }
      } */

      .parallax-circle {
        /* width: 100px;
        height: 100px;
        background-color: blue;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer; */
        transition: transform 0.5s ease; /* Smooth transition for the dancing effect */
      }
      
      .parallax-circle.dancing {
        transform: translateY(-25px); /* Adjust the value as needed */
        animation: 60s ease-out alternate; /* Animation for the dancing effect */
      }


    
    /* FOR BOXES */
    /* .dancing-divv {
        opacity: 0;
        transform: translateY(-400px);
        transition: 1s ;
    }
      
      .dancing-divv.visible {
        opacity: 1;
        transform: translateY(0%);
        animation:  0.5s ease-in-out forwards ;
      }
      
      @keyframes shake {
        0% { transform: translateX(0); }
        25% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
        75% { transform: translateX(-10px); }
        100% { transform: translateX(0); }
      }
    
 
    .dancing-div {
       
        transition: transform 0.5s ease; 
      }
      
      .dancing-div.dancing {
        transform: translateY(-25px); 
        animation: 60s ease-out alternate; 
      }
      
      @keyframes bounce {
        from {
          transform: translateY(-25px);
        }
        to {
          transform: translateY(0);
        }
      } */
    .giffre{
        height: 100px;
        position: absolute;
        top: -10px;
        left: 430px;
    }

    .logos{
        /* padding: 20px 0 0 0; */
        /* padding-right: 0%; */
        /* margin-left: 0%; */
        white-space: nowrap;
    
    }
    
    .logos:hover .logo-slidefre 
    {
        /* animation-play-state: paused ; */
    }
    
    .logo-slidefre{
        display: inline-block;
        animation: 80s slide infinite linear;
    }
    
    
    
    /* .logo-slidefre img{
        margin: 0 40px;
    } */
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logoss{
        /* overflow: hidden; */
        /* padding: 20px 0; */
        white-space: nowrap;
    
    }
    
    .logoss:hover .logos-slidefre 
    {
        /* animation-play-state: paused ; */
    }
    
    .logos-slidefre{
        display: inline-block;
        animation: 80s slides infinite linear;
    }
    
    .logos-slidefre img{
        margin: 0 40px;
    }
    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }
    

    .luck{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  display: inline-block;
                  font-weight: 900;
    
    }
    .mid3contentfresher{
        display: none;
    }

    .fresher1tag{
        display: none;
    }

    .logo-slidefre img{
        margin: 0 40px;
        height: 100px;

    }


    .logos-slidefre img{
        margin: 0 40px;
    }

    html,body{
        overflow-x: hidden;
    }

    .namesm{
        width: 250px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 22px;
font-weight: 600;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
    }

    .desgsm{
        width: 134px;
height: 30px;
font-size: 22px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-style: italic;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
margin-top: -30px;
margin-left: 30%;
    }

    .brandppfre{
       margin-top: 8%;
       margin-left: auto;
        margin-right: auto;
       width: 90%;
       height: 175px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);
    }

    .hireppfre{  
margin-top: 10%;
       margin-left: auto;
        margin-right: auto;
       width: 90%;
       height: 175px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);

    }

    .digitalppfre{

margin-top: 10%;
       margin-left: auto;
        margin-right: auto;
       width: 90%;
       height: 175px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);
    }

    .trainppfre{
margin-top: 10%;
       margin-left: auto;
        margin-right: auto;
       width: 90%;
       height: 175px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);

    }

    /* .brandhfre{
        position: relative;
        top: 15%;
        margin-left: 4.5%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    width: 474px;
height: 42px;
    }

    .hirehfre{
        width: 476px;
height: 42px;
top: 19.5%;
position: relative;
        margin-left: 5%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;
    }

    .digitalhfre{
        top: 19%;
position: relative;
        margin-left: 5%;
        width: 640px;
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;
    }

    .trainhfre{
        top: 15%;
position: relative;
        margin-left: 4%;
width: 475px;
height: 24px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    } */



    .brandfre{
        width: 84.2%;
        height: 306px;
        background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
    border-radius: 50px;
    margin-top: 0%;
    margin-bottom: 20px;
    /* margin-left: 8.5%; */
    margin-left: auto;
    margin-right: auto;
    }
    
    .hirefre{
        width: 84.2%;
height: 307px;
border-radius: 50px;
background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);

    border-radius: 50px;
    margin-bottom: 20px;
    /* margin-left: 8.5%; */
    margin-left: auto;
    margin-right: auto;
    }
    
    .digitalfre{
        width: 84.2%;
height: 307px;
        border-radius: 50px;
        background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%); font-size: 20px;
        margin-bottom: 20px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        /* margin-left: 8.5%; */
        margin-left: auto;
    margin-right: auto;
    }
    
    .trainfre{
        width: 84.2%;
height: 307px;
        border-radius: 50px;
        background: linear-gradient(3600deg, #000000 16.94%, #F7B400 75.01%);
        margin-bottom: 70px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: auto;
    margin-right: auto;
    }

    .registab{
        font-family: 'Plus Jakarta Sans', sans-serif;
        color: rgba(7, 60, 165, 1);
        width: 145.25px;
height: 30px;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;

margin-top: 3%;
margin-left: 25%;

    }

    .bluec{
        width: 54px;
height: 54px;
margin-top: -107%;
margin-left: 29%;
position: relative;
background: rgba(7, 60, 165, 1);
border-radius: 100%;
    }

    .whitearrowfre{
        width: 24px;
height: 30px;
margin-top: 20%;
margin-left: 26%;

    }

    .whitea{
        width: 100px;
        height: 100px;
    }

    .startparamobtab{
        width: 612.49px;
height: 50px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
top: -180px;
/* margin-top: -20%; */
left: 22%;
position: relative;


    }

    .startheadtab{
        width: 505px;
height: 40px;
margin-left: 116px;
margin-top: 15px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 32px;
font-weight: 400;
line-height: 40px;
letter-spacing: 0em;
text-align: center;
color: rgb(255, 255, 255, 1);
    }

    .subbuttontab{
        /* top: 40px; */
        /* margin-top: -10px; */
        top: -32%;
        position: relative;
        margin-left: 40%;
        width: 331px;
height: 61px;
border-radius: 37.5px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    margin-bottom: 4em;
    }

    .startedfre{
        display: none;
    }

    .startedboxtab{
        margin-top: 140px;
        margin-left: 13%;
        width: 737px;
height: 252px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 0%;
    
    }

    .chatboxtext51tab{
        width: 608px;
height: 374px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
padding: 9%;


    }


    .chatboxtext51{
display: none;
    }

    .chatbox5s1fre{
        width: 529px;
height: 469px;
border-radius: 20px 20px 0px 20px;
margin-top: 50px;
background: rgba(207, 240, 230, 1);
margin-left: 30%;
    }

    .chatimg5tabfre{
        width: 83px;
        height: 83px;
        margin-left: 74%;
        margin-top: 42%;
        position: absolute;
    }

    .chatimg5fre{
display: none;
    }

    .chatboxtext41tab{
        width: 508px;
height: 225px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
padding: 10%;
    }

    .chatboxtext41{
display: none;
    }

    .startparalapscreen{
        display: none;
    }

    .chatbox4s1{
        width: 529px;
height: 329px;
    border-radius: 0px 20px 20px 20px;
    background: rgba(231, 242, 255, 1);
    margin-top: 10%;
    margin-left: 45%;
   
    }

    .chatimg4fre{
        display: none;
    }

    .chatimg4tabfre{
        width: 83px;
height: 83px;
        margin-left: 20px;
        margin-top: 4%; 
       position: absolute;
    }

    .chatboxtext21{
        display: none;
    }

    .chatboxtext21tab{
        padding: 10%;
        width: 508px;
height: 299px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;



    }

    .chatbox2s1{
        background: rgba(207, 240, 230, 1);
        width: 529px;
height: 392px;
border-radius: 20px 20px 0px 20px;
margin-top: 120%;
margin-left: -80%;
position: relative;
    }

    .chatimg2fre{
        width: 83.46px;
height: 83.46px;
margin-top: 10%;
margin-left: 0%;
position: relative;
display: none;
    }

    .chatimg2tab{
        width: 83.46px;
height: 83.46px;
margin-top: -35%;
margin-left: 60%;
position: relative;
    }

    .chatboxtext1{
        display: none;
    }

    .chatboxtext1tab{
        padding: 30px;
        width: 450px;
height: 510.11px;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;


    font-family: 'Plus Jakarta Sans', sans-serif;
    }

    .chatbox1s1fre{
        width: 529px;
height: 602px;  
border-radius: 0px 20px 20px 20px;
margin-top: -20%;
background: rgba(231, 242, 255, 1);
margin-left: 55%;
position: relative;

    }
    
    .chatimg1fre{
        width: 90px;
        height: 90px;
        margin-top: -7%;
        position: absolute;
        margin-left: 7%;
    }

    .chatfre{
        margin-top: 50px;
    }

    .chatheadtitletab{
        width: 749px;
height: 174px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
margin-top: -5%;
margin-left: 10%;
margin-bottom: 10%;
position: relative;

    }


    .chatheadtitlefre{
        display: none;

    }

    .chatitle1tagfre{
        width: 400px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 29%;
margin-top: 90px;
    }

    .ctext{
        margin-top: 15px;
        width: 400px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;


    }
    

    /* .register2fre{
        width: 137px;
height: 42px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
position: relative;
margin-left: 140px;
background: rgba(7, 60, 165, 1);
top: -60px;
    } */

    .register2tab{
        width: 220px;
height: 60px;
border-radius: 37.5px;
background: rgba(7, 60, 165, 1);
border: none;
margin-left: 36%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
margin-top: -8%;
position: absolute;


    }

    .assesshead1tabfre{
        width: 890px;
        height: 210px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-left: 6%;
margin-bottom: 100px;

    }

    .assesshead2tab{
        margin-top: -15%;
        width: 676px;
        height: 210px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-left: 28%;
    }

    .assesshead2fre{
        margin-top: -15%;
        width: 676px;
        height: 210px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-left: 28%;
    }

    .assesshead1fre{
        /* width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 11px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px; */
display: none;
    }
    .assesshead2fre{
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;
display: none;
    }
    .assesheadtitlefre{
display: none;
    }

.assesstitle1tagfre{
    margin-top: -75%;
    margin-left: 66%;
    /* margin-left: auto;
    margin-right: auto; */
    width: 234px;
height: 64px;
border-radius: 32px;
    position: relative;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
}

/* .assesscircle{
    display: none;
} */

    

    .assesscircle{
        /* width: 478px;
height: 478px;
margin-left: 2%;
background: rgba(9, 9, 63, 0.05);
border-radius: 100%;
margin-left: 10%; */
display: none;

    }

    

    .registertabfre{
        width: 300px;
height: 60px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
margin-left: 74%;
background: rgba(7, 60, 165, 1);
border: none;
/* top: -50px; */
margin-top: 0%;
margin-bottom: 20%;
/* margin-top: -700%; */
position: relative;
    }

    .registerfre{
/* display: none; */
    }
    .register2fre{
        display: none;
            }

    .register1tfre{
        /* padding-top: 8%;
        margin-left: auto;
        margin-right: auto; */
        display: none;
    }
    .register1ttabfre{
        margin-top: 4%;
    }

    .register1ttab{
        margin-top: 7%;
    }

    .head12fre{
        display: none;
    }

    .head13fre{
        display: none;
    }

    .head12tabfre{
        width: 676px;
height: 210px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-left: 31%;

    }

    .head13tab{
        margin-top: -100px;
        width: 676px;
height: 210px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-left: 30%;

    }

    /* .traintitle1tagfre{
        display: none;
    } */

    .traintitle1tagfre{
        width: 234px;
height: 64px;
border-radius: 32px;
margin-top: -102%;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
text-align: center;
margin-left: 80%;
    }

    .trainheadtitletabfre{
        width: 564px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
margin-left: 45%;
margin-top: -350px;
    }

    

    .traincirclefr{
        width: 478px;
height: 478px;
        
    background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -120%;
    display: none;
    }

    .traincirclepad{
        width: 478px;
height: 478px;
        
    background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -40%;
    }

    .phonetitles{
        margin-left: 10px;
        margin-top: 100px;

    }
    
    
    .org1circle{
        width: 121.5px;
height: 121.5px;
position: relative;
margin-top: 0%;
margin-left: 0%;
    }

    .org1circle::before{
        content: '';
        position: absolute;
        width: 231.87px;
height: 231.87px;
        margin-left: 270%;
        margin-top: 150%;
        border-radius: 50%;
        opacity: 90%;
      }
      
      .org1circle::before {
          background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
        }
    .vio1circle{
        /* margin-top: 10%; */
        position: relative;
        width: 231.87px;
height: 231.87px;
opacity: 90%;
    }

    .vio1circle::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }

    .vio1circle::before {
        content: '';
        position: absolute;
        margin-top: 85%;
        margin-left: 90%;
        width: 231.87px;
height: 231.87px;
        border-radius: 50%;
        opacity: 100%;
      }

    .blue1circle{
        width: 350px;
height: 350.98px;
margin-top: -15%;
margin-left: 40%;
position: absolute;
background: rgba(135, 189, 255, 1);
border-radius: 100%;

    }

    .phones{
        width: 314px;
height: 576px;
/* top: 20%; */
    margin-top: -56%;
    margin-left: 73%;
    position: absolute;
    /* order: 2; */
    
    }



    .phonebodylapscreen{
        display: none;
    }

    .phonetitles{
        order: 1;
    }
    .phonetitle1tagfre{
        margin-top: 8%;
        width: 440px;
height: 64px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align:  center;
display: block;
justify-content: center;
 align-items: center;
 position: absolute;
 left: 50%;
 transform: translate(-50%, -50%);
background: rgba(232, 237, 252, 1);

    }

    .firstsignup{
        margin-top: -400px;
        text-align: center;
    }

    
    .firstsignup1{
        margin-top: -240px;
    }

    /* .phonetitles h1{
        width: 710px;
    } */

    .phoneheadtitlefre{
        width: 710px;
height: 90px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
position: absolute;
 left: 50%;
 transform: translate(-50%, -50%);
color: rgba(0, 0, 0, 1);
margin-top: 15%;
    }


.image-phone-container{
    margin-top:45%;
    order: 1;
}

.assesheadtitfretab{
    width: 749px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
margin-left: 22%;
margin-top: -320px;
}

.assesscircletab{
    width: 478px;
height: 478px;
margin-left: 2%;
background: rgba(9, 9, 63, 0.05);
border-radius: 100%;
margin-left: 120%;
}

.assesscirclefre{
    width: 478px;
height: 478px;
background: rgba(9, 9, 63, 0.05);
border-radius: 100%;
margin-left: 120%;
position: relative;
 top: 50px;
    }

.phonebodymob{
    order: 3;
    width: 676px;
height: 75px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
margin-left: -75%;
margin-top: 195%;
margin-bottom: 30%;

}

    .adobe{
        width: 89.62px;
height: 23.58px;
    }

    .adidas{
        margin-left: 30px;
        width: 45.28px;
height: 46.23px;
    }
    .burger{
        width: 43.4px;
height: 46.23px;
    }
    .kfc{
        margin-left: 20px;
        width: 43.4px;
height: 46.23px;
    }
    .fedex{
        width: 60.38px;
height: 25.47px;
    }
    .uptime{
        margin-left: 20px;
        width: 66.04px;
height: 23.58px;

    }

    .title2taglogofre{
        /* width: 950px; */
height: 100px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 20%; */
margin-left: auto;
margin-right: auto;
margin-bottom: 6.5%;
margin-top: 3%;

    }

    .title2tagfre2{
        width: 750px;
        height: 100px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4%;
        margin-top: -6%;
    }


    .title2tagfre{
        /* width: 950px; */
height: 100px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 50px;
font-weight: 400;
line-height: 60px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 20%; */
margin-left: auto;
margin-right: auto;
margin-bottom: 3%;
margin-top: 15%;

    }

    .titlee1tag{
        margin-top: 30px;
        width: 110px;
        margin-left: 35.5%;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1);
    }

    .arrowfre{
        display: none;
    }

    .extratestimonial{
        display: none;
    }

    .title1tagfre{
        margin-top: 14%;
        /* margin-left: 36.5%; */
        margin-left: auto;
        margin-right: auto;
width: 300px;
height: 64px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;

text-align: center;
background: rgba(232, 237, 252, 1);

color: rgba(0, 0, 0, 1);
    }


    .title1taglogofre{
        margin-top: 10%;
        margin-left: auto;
        margin-right: auto;
width: 400px;
height: 64px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1);
    }

    .title2tagexp2gfre{
        height: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        margin-bottom: 0em;
    }

    .contentparafre p{
        width: 601px;
height: 259.3px;
    margin-top: 15%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    /* margin-left: 34%; */
      order: 2;
      font-family:'Plus Jakarta Sans', sans-serif;
font-size: 55px;
font-weight: 300;
line-height: 68px;
letter-spacing: 0em;
text-align: center;
      margin-bottom: 50px;
      

    }

    .titlee1tag{
        width: 234px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;



        /* margin-top: 30px;
        width: 110px;
        margin-left: 35.5%;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1); */


    }

    .violetbox{
        background: rgba(130, 27, 97, 1);
        width: 203px;
        height: 203px;
        margin-top: -39%;
        margin-bottom: 50%;
        margin-left: -77%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;     

    }

    .vt1fre{
        width: 91px;
height: 45px;
font-family: 'Plus Jakarta Sans', sans-serif;  
position: relative;
font-size: 36px;
font-weight: 400;
line-height: 45px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);
/* left: 0px; */
margin-left: 10%;
margin-top: 10%;    }

    .vt2fre{
        width: 83px;
height: 19px;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-left: -42%;
margin-top: 35%;
position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif; 
    }

    .yellowbox{
        background: rgba(247, 180, 0, 1);
        width: 118px;
        height: 118px;
        margin-top: -170%;
        position: relative;
        margin-left: -76%;
        font-family: 'Plus Jakarta Sans', sans-serif;           
    }
    .yt1fre{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
letter-spacing: 0em;
text-align: left;
    margin-top: 20px;
    margin-left: 10%;
    }

    .lineboxfre{
        width: 164px;
height: 164px;
border: 1px;
opacity: 0.1px;
margin-left: -61%;
 position: relative;
margin-top: -150%;
margin-bottom: 160%;
border: 1px solid rgba(0, 0, 0, 1);

    }

    .hlinebox1fre{
        width: 100px;;
    height: 163px;
    border: 1px solid rgba(0, 0, 0, 1);
    opacity: 15%;
    left: 34px;
    position: absolute;
}

.hlinebox2fre{
    width: 40px;
height: 163px;
/* opacity: 30%; */
border: 1px solid rgba(0, 0, 0, 1);
margin-left: 30%;
}

.rlinebox1fre{
    margin-top: 10px;
    width: 163px;
height: 100px;
opacity: 30%;
border: 1px solid rgba(0, 0, 0, 1);

top: 20px;
position: absolute;
}
.rlinebox2fre{
    /* margin-top: 20px; */
    width: 163px;
height: 40px;
opacity: 30%;
top: 27px;
border: 1px solid rgba(0, 0, 0, 1);

position: absolute;
}

.lineboxtextfre{
    width: 55px;
height: 35px;
margin-top: 20px;
margin-left: 10%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 36px;
font-weight: 400;
line-height: 45px;
letter-spacing: 0em;
text-align: left;
position: absolute;

}

.lineboxtext2fre{
    width: 82px;
height: 19px;
    margin-top: 2px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 18.6px;
    text-align: left;
    
}

.siginslet{
    margin-top: 8.5%;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    color: rgba(255, 255, 255, 1);
}

    .signinss{
        width: 173px;
height: 60px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
text-align: right;
display: flex;
justify-content: center;
margin-top: 15%;
margin-left: 245px;
text-align: center;
align-content: center;
background: rgba(7, 60, 165, 1);
border: none;
/* text-align: center; */

    }

    .instlogo1{
        width: 50px;
        height: 50px;
    }

    .watchs{
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 17px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
text-align: left;
    }

    .watchsss{
        width: 200px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
display: flex;
justify-content: center;
margin-left: 15px;
margin-top: 14.5%;
text-align: center;
border-radius: 37.5px;
background-color: white;
    border: 1px solid rgba(7, 60, 165, 1);
        color:rgba(7, 60, 165, 1);
    }

    .linetextttfre{
        width: 91%;
height: 130px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 29.76px;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 7%;
/* display: none; */
    }
    .linetextttfre2{
        width: 800px;
height: fit-content;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 29.76px;
text-align: center;
margin-left: auto;
margin-right: auto;
/* margin-top: 7%; */
/* display: none; */
margin-bottom: 5em;
    }

    .image-container2{
        margin-left: 20px;
    }

  
    .image-container {
        margin-left: 60px;
      order: 1;
    }

    .chatbox121tab{
        width: 127.72px;
height: 97.12px;
    margin-top: -21.3%;
    margin-left: 26%;
    position: relative;
    background: rgba(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100.3%); 
    }

    .chatbox121fre{
        /* width: 61.38px;
height: 46.67px;
    margin-top: -10.8%;
    margin-left: 64%;
    position: absolute;
    background: rgba(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100.3%);    */
    display: none;
 }

 .chatbox141fre{
    display: none;
 }

 .chatbox141tab{
    width: 128px;
height: 97px;
background: rgba(231, 242, 255, 1);
position: relative;
margin-top: -0%;
clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
margin-left: -120%;
}

.chatbox151fre{
    display: none;
}

.chatbox151tabfre{
    width: 127px;
height: 97px;
background: rgba(207, 240, 230, 1);
position: relative;
margin-top: -21%;
clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100%);
margin-left:140%;
}

.chatbox111fre{
    display: none;
}

.chatbox111tab{
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    width: 127.72px;
height: 97.12px;
background: rgba(231, 242, 255, 1);
margin-left: -105%;
/* margin-top: -59.7%; */
position: relative;
}

.orgvector{
    width: 313px;
    height: 428.07px;
background: rgba(255, 91, 0, 1);
border-top-right-radius: 90%;
margin-left: -55%;
position: relative;
margin-top: 110%;
}
.img-fluid1{
    width: 375px;
height: 456.75px;
position: absolute;
margin-top: 40.5%;
margin-left: -25.5%;
}

.sechead{
    margin-top: -15%;
}

.josephlogo{
    width: 100px;
    height: 100px;
}

.instlogofr{
    /* width: 100px; */
    height: 100px;
}

    body{
        overflow-x: hidden;
    }

    .startedtab{
        margin-bottom: -14%;

    }
   
}


@media (min-width:577px) and (max-width:767.5px) {
    .brandhfre{
        font-size: 30px;
        font-weight: 900;
        position: relative;
        top: 30px;
        left: 20px;
    }

    .hirehfre{
        font-size: 30px;
        font-weight: 900;
        position: relative;
        top: 40px;
        left: 20px;
    }

    .digitalhfre{
        font-size: 30px;
        font-weight: 900;
        position: relative;
        line-height: 35px;
        top: 40px;
        left: 20px;
    }

    .digitalppfre{
        margin-top: 13%;
        margin-left: auto;
         margin-right: auto;
        width: 90%;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
    }

    .trainhfre{
        font-size: 30px;
        font-weight: 900;
        position: relative;
        top: 40px;
        left: 20px;
    }
}

@media (min-width:768px) and (max-width:830px) {
    .brandhfre{
        position: relative;
        top: 15%;
        margin-left: 4.5%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    width: 474px;
height: 42px;
    }

    .hirehfre{
        width: 476px;
height: 42px;
top: 19.5%;
position: relative;
        margin-left: 5%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;
    }

    .digitalhfre{
        top: 19%;
position: relative;
        margin-left: 5%;
        width: fit-content;
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;
    }

    .digitalppfre{

        margin-top: 18%;
               margin-left: auto;
                margin-right: auto;
               width: 90%;
               height: 175px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        opacity: 80%;
        color: rgba(255, 255, 255, 1);
            }

            .trainhfre{
                top: 15%;
        position: relative;
                margin-left: 4%;
        width: 475px;
        height: 24px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
                font-weight: 900;
                line-height: 42px;
                letter-spacing: 0em;
                text-align: center;
            }
}


@media (min-width:577px) and (max-width:830px) {

    .chatbox1fre:hover ,
.chatbox2fre:hover,
.extratestimonial:hover,
.chatbox4fre:hover,
.chatbox5fre:hover
{
    transform: none; /* Scale up the element on hover */
  }



      /* circle animation  */

      @keyframes moveUp {
        0% { transform: translateY(30px); }
        100% { transform: translateY(-10px); }
    }
    
    .org1circle, .vio1circle, .blue1circle {
        
        animation: none; /* Initially no animation */
    }
    
    .scrolling .org1circle {
        animation: moveUp 0.8s forwards; /* Play animation once and stop */
    }
    
    .scrolling .vio1circle {
        animation: moveUp 1.5s forwards; /* Slightly delay the animation */
    }
    
    .scrolling .blue1circle {
        animation: moveUp 2s forwards; /* Slightly delay the animation */
    }
    
    
  
    

    /* .parallax-circle{
        animation-name: dance;
        transition: 1s;
        transform: translateY(-10px);
  animation-duration: 1s;
  animation-iteration-count: infinite;
    }
    
    @keyframes dance {
        0% { transform: translateY(0); }
        50% { transform: translateY(-30px); }
        100% { transform: translateY(0); }
      } */

      .parallax-circle {
        /* width: 100px;
        height: 100px;
        background-color: blue;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer; */
        transition: transform 0.5s ease; /* Smooth transition for the dancing effect */
      }
      
      .parallax-circle.dancing {
        transform: translateY(-25px); /* Adjust the value as needed */
        animation: 60s ease-out alternate; /* Animation for the dancing effect */
      }


    
    /* FOR BOXES */
    /* .dancing-divv {
        opacity: 0;
        transform: translateY(-400px);
        transition: 1s ;
    }
      
      .dancing-divv.visible {
        opacity: 1;
        transform: translateY(0%);
        animation:  0.5s ease-in-out forwards ;
      }
      
      @keyframes shake {
        0% { transform: translateX(0); }
        25% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
        75% { transform: translateX(-10px); }
        100% { transform: translateX(0); }
      }
      
    
      

 
    .dancing-div {
     
        transition: transform 0.5s ease; 
      }
      
      .dancing-div.dancing {
        transform: translateY(-25px); 
        animation: 60s ease-out alternate; 
      }
      
      @keyframes bounce {
        from {
          transform: translateY(-25px);
        }
        to {
          transform: translateY(0);
        }
      } */
    .giffre{
        height: 100px;
        position: absolute;
        top: -50px;
        left: 455px;
    }

    .logos{
        /* padding: 20px 0 0 0; */
        /* padding-right: 0%; */
        /* margin-left: 0%; */
        white-space: nowrap;
    
    }
    
    .logos:hover .logo-slidefre 
    {
        /* animation-play-state: paused ; */
    }
    
    .logo-slidefre{
        display: inline-block;
        animation: 80s slide infinite linear;
    }
    
    
    
    /* .logo-slidefre img{
        margin: 0 40px;
    } */
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logoss{
        /* overflow: hidden; */
        /* padding: 20px 0; */
        white-space: nowrap;
    
    }
    
    .logoss:hover .logos-slidefre 
    {
        /* animation-play-state: paused ; */
    }
    
    .logos-slidefre{
        display: inline-block;
        animation: 80s slides infinite linear;
    }
    
    .logos-slidefre img{
        margin: 0 40px;
    }
    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }
    

    .luck{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  display: inline-block;
                  font-weight: 900;
    
    }
    .mid3contentfresher{
        display: none;
    }

    .fresher1tag{
        display: none;
    }

    .logo-slidefre img{
        margin: 0 40px;
        height: 100px;

    }


    .logos-slidefre img{
        margin: 0 40px;
    }

    html,body{
        overflow-x: hidden;
    }

    .namesm{
        width: 250px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 22px;
font-weight: 600;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
    }

    .desgsm{
        width: 134px;
height: 30px;
font-size: 22px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-style: italic;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
margin-top: -30px;
margin-left: 30%;
    }

    .brandppfre{
       margin-top: 8%;
       margin-left: auto;
        margin-right: auto;
       width: 90%;
       height: 175px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);
    }

    .hireppfre{  
margin-top: 11%;
       margin-left: auto;
        margin-right: auto;
       width: 90%;
       height: 175px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);

    }

    /* .digitalppfre{

margin-top: 18%;
       margin-left: auto;
        margin-right: auto;
       width: 90%;
       height: 175px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);
    } */

    .trainppfre{
margin-top: 12%;
       margin-left: auto;
        margin-right: auto;
       width: 90%;
       height: 175px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);

    }

    /* .brandhfre{
        position: relative;
        top: 15%;
        margin-left: 4.5%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    width: 474px;
height: 42px;
    }

    .hirehfre{
        width: 476px;
height: 42px;
top: 19.5%;
position: relative;
        margin-left: 5%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;
    }

    .digitalhfre{
        top: 19%;
position: relative;
        margin-left: 5%;
        width: fit-content;
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;
    }

    .trainhfre{
        top: 15%;
position: relative;
        margin-left: 4%;
width: 475px;
height: 24px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    } */



    .brandfre{
        width: 84.2%;
        height: 306px;
        background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
    border-radius: 50px;
    margin-top: 0%;
    margin-bottom: 20px;
    /* margin-left: 8.5%; */
    margin-left: auto;
    margin-right: auto;
    }
    
    .hirefre{
        width: 84.2%;
height: 307px;
border-radius: 50px;
background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);

    border-radius: 50px;
    margin-bottom: 20px;
    /* margin-left: 8.5%; */
    margin-left: auto;
    margin-right: auto;
    }
    
    .digitalfre{
        width: 84.2%;
height: 307px;
        border-radius: 50px;
        background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%); font-size: 20px;
        margin-bottom: 20px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        /* margin-left: 8.5%; */
        margin-left: auto;
    margin-right: auto;
    }
    
    .trainfre{
        width: 84.2%;
height: 307px;
        border-radius: 50px;
        background: linear-gradient(3600deg, #000000 16.94%, #F7B400 75.01%);
        margin-bottom: 70px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: auto;
    margin-right: auto;
    }

    .registab{
        font-family: 'Plus Jakarta Sans', sans-serif;
        color: rgba(7, 60, 165, 1);
        width: 145.25px;
height: 30px;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;

margin-top: 3%;
margin-left: 25%;

    }

    .bluec{
        width: 54px;
height: 54px;
margin-top: -148%;
margin-left: 55%;
position: relative;
background: rgba(7, 60, 165, 1);
border-radius: 100%;
    }

    .whitearrowfre{
        width: 24px;
height: 30px;
margin-top: 20%;
margin-left: 26%;

    }

    .whitea{
        width: 100px;
        height: 100px;
    }

    .startparamobtab{
        width: 612.49px;
height: 50px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
top: -175px;
/* margin-top: -20%; */
left: 8%;
position: relative;


    }

    .startheadtab{
        width: 505px;
height: 40px;
margin-left: 116px;
margin-top: 20px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 32px;
font-weight: 400;
line-height: 40px;
letter-spacing: 0em;
text-align: center;
color: rgb(255, 255, 255, 1);
    }

    .subbuttontab{
        /* top: 40px; */
        /* margin-top: -10px; */
        top: -29%;
        position: relative;
        margin-left: 30%;
        width: 331px;
height: 61px;
border-radius: 37.5px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    margin-bottom: 4em;
    }

    .startedfre{
        display: none;
    }

    .startedboxtab{
        margin-top: 140px;
        margin-left: -3%;
        width: 737px;
height: 252px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 0%;
    
    }

    .chatboxtext51tab{
        width: 608px;
height: 374px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
padding: 9%;


    }


    .chatboxtext51{
display: none;
    }

    .chatbox5s1fre{
        width: 529px;
height: 469px;
border-radius: 20px 20px 0px 20px;
margin-top: 50px;
background: rgba(207, 240, 230, 1);
margin-left: 30%;
    }

    .chatimg5tabfre{
        width: 83px;
        height: 83px;
        margin-left: 74%;
        margin-top: 42%;
        position: absolute;
    }

    .chatimg5fre{
display: none;
    }

    .chatboxtext41tab{
        width: 508px;
height: 225px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
padding: 10%;
    }

    .chatboxtext41{
display: none;
    }

    .startparalapscreen{
        display: none;
    }

    .chatbox4s1{
        width: 529px;
height: 329px;
    border-radius: 0px 20px 20px 20px;
    background: rgba(231, 242, 255, 1);
    margin-top: 10%;
    margin-left: 45%;
   
    }

    .chatimg4fre{
        display: none;
    }

    .chatimg4tabfre{
        width: 83px;
height: 83px;
        margin-left: 20px;
        margin-top: 4%; 
       position: absolute;
    }

    .chatboxtext21{
        display: none;
    }

    .chatboxtext21tab{
        padding: 10%;
        width: 508px;
height: 299px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;



    }

    .chatbox2s1{
        background: rgba(207, 240, 230, 1);
        width: 529px;
height: 392px;
border-radius: 20px 20px 0px 20px;
margin-top: 120%;
margin-left: -80%;
position: relative;
    }

    .chatimg2fre{
        width: 83.46px;
height: 83.46px;
margin-top: 10%;
margin-left: 0%;
position: relative;
display: none;
    }

    .chatimg2tab{
        width: 83.46px;
height: 83.46px;
margin-top: -35%;
margin-left: 60%;
position: relative;
    }

    .chatboxtext1{
        display: none;
    }

    .chatboxtext1tab{
        padding: 30px;
        width: 450px;
height: 510.11px;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;


    font-family: 'Plus Jakarta Sans', sans-serif;
    }

    .chatbox1s1fre{
        width: 529px;
height: 602px;  
border-radius: 0px 20px 20px 20px;
margin-top: -20%;
background: rgba(231, 242, 255, 1);
margin-left: 55%;
position: relative;

    }
    
    .chatimg1fre{
        width: 90px;
        height: 90px;
        margin-top: -7%;
        position: absolute;
        margin-left: 7%;
    }

    .chatfre{
        margin-top: 50px;
    }

    .chatheadtitletab{
        width: 86%;
height: 174px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
margin-top: -5%;
margin-left: auto;
margin-right: auto;
margin-bottom: 10%;
position: relative;

    }


    .chatheadtitlefre{
        display: none;

    }

    .chatitle1tagfre{
        width: 400px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 90px;
    }

    .ctext{
        margin-top: 15px;
        width: 400px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;


    }
    

    /* .register2fre{
        width: 137px;
height: 42px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
position: relative;
margin-left: 140px;
background: rgba(7, 60, 165, 1);
top: -60px;
    } */

    .register2tab{
        width: 220px;
height: 60px;
border-radius: 37.5px;
background: rgba(7, 60, 165, 1);
border: none;
margin-left: 36%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
margin-top: -8%;
position: absolute;


    }

    .assesshead1tabfre{
        width: 175%;
        height: 210px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-top: 10px;
margin-bottom: 100px;
/* margin-left: 6%; */

    }

    .assesshead2tab{
        margin-top: -15%;
        width: 676px;
        height: 210px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-left: 28%;
    }

    .assesshead2fre{
        margin-top: -15%;
        width: 676px;
        height: 210px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-left: 28%;
    }

    .assesshead1fre{
        /* width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 11px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px; */
display: none;
    }
    .assesshead2fre{
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;
display: none;
    }
    .assesheadtitlefre{
display: none;
    }

.assesstitle1tagfre{
    margin-top: -75%;
    margin-left: 66%;
    /* margin-left: auto;
    margin-right: auto; */
    width: 234px;
height: 64px;
border-radius: 32px;
    position: relative;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
}

/* .assesscircle{
    display: none;
} */

    

    .assesscircle{
        /* width: 478px;
height: 478px;
margin-left: 2%;
background: rgba(9, 9, 63, 0.05);
border-radius: 100%;
margin-left: 10%; */
display: none;

    }

    

    .registertabfre{
        width: 300px;
height: 60px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
margin-left: 74%;
background: rgba(7, 60, 165, 1);
border: none;
/* top: -50px; */
margin-top: 0%;
margin-bottom: 20%;
/* margin-top: -700%; */
position: relative;
    }

    .registerfre{
/* display: none; */
    }
    .register2fre{
        display: none;
            }

    .register1tfre{
        /* padding-top: 8%;
        margin-left: auto;
        margin-right: auto; */
        display: none;
    }
    .register1ttabfre{
        margin-top: 4%;
    }

    .register1ttab{
        margin-top: 7%;
    }

    .head12fre{
        display: none;
    }

    .head13fre{
        display: none;
    }

    .head12tabfre{
        width: 212%;
height: 210px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 37%; */
margin-top: -260px;
    }

    .head13tab{
        margin-top: -100px;
        width: 676px;
height: 210px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-left: 30%;

    }

    /* .traintitle1tagfre{
        display: none;
    } */

    .traintitle1tagfre{
        width: 234px;
height: 64px;
border-radius: 32px;
margin-top: -102%;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
text-align: center;
margin-left: 80%;
    }

    .trainheadtitletabfre{
        width: max-content;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 50%; */
position: absolute;
left: 50%;
transform: translate(-50%, -50%);
margin-top: -310px;
    }

    

    .traincirclefr{
        width: 478px;
height: 478px;
        
    background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -120%;
    display: none;
    }

    .traincirclepad{
        width: 478px;
        height: 478px;   
        background: rgba(247, 91, 2, 0.1);
        border-radius: 100%;
        margin-left: -60%;
    }

    .phonetitles{
        margin-left: 10px;
        margin-top: 100px;

    }
    
    
    .org1circle{
        width: 121.5px;
height: 121.5px;
position: relative;
margin-top: 0%;
margin-left: 0%;
    }

    .org1circle::before{
        content: '';
        position: absolute;
        width: 231.87px;
height: 231.87px;
        margin-left: 270%;
        margin-top: 150%;
        border-radius: 50%;
        opacity: 90%;
      }
      
      .org1circle::before {
          background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
        }
    .vio1circle{
        /* margin-top: 10%; */
        position: relative;
        width: 231.87px;
height: 231.87px;
opacity: 90%;
    }

    .vio1circle::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }

    .vio1circle::before {
        content: '';
        position: absolute;
        margin-top: 85%;
        margin-left: 90%;
        width: 231.87px;
height: 231.87px;
        border-radius: 50%;
        opacity: 100%;
      }

    .blue1circle{
        width: 350px;
height: 350.98px;
margin-top: -18%;
margin-left: 50%;
position: absolute;
background: rgba(135, 189, 255, 1);
border-radius: 100%;

    }

    .phones{
        width: 314px;
height: 576px;
/* top: 20%; */
    margin-top: -56%;
    margin-left: 73%;
    position: absolute;
    /* order: 2; */
    
    }



    .phonebodylapscreen{
        display: none;
    }

    .phonetitles{
        order: 1;
    }
    .phonetitle1tagfre{
        margin-top: 8%;
        width: 440px;
height: 64px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align:  center;
display: block;
justify-content: center;
 align-items: center;
 position: absolute;
 left: 50%;
 transform: translate(-50%, -50%);
background: rgba(232, 237, 252, 1);

    }

    .firstsignup{
        margin-top: -400px;
        text-align: center;
    }

    
    .firstsignup1{
        margin-top: -240px;
    }

    /* .phonetitles h1{
        width: 710px;
    } */

    .phoneheadtitlefre{
        width: 93%;
height: 90px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
position: absolute;
 left: 50%;
 transform: translate(-50%, -50%);
color: rgba(0, 0, 0, 1);
margin-top: 17%;
    }


.image-phone-container{
    margin-top:60%;
    margin-left: -44%;
    order: 1;
}

.assesheadtitfretab{
    width: 693px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
margin-left: 0%;
margin-top: -320px;
}

.assesscircletab{
    width: 478px;
height: 478px;
margin-left: 2%;
background: rgba(9, 9, 63, 0.05);
border-radius: 100%;
margin-left: 120%;
}

.assesscirclefre{
    width: 478px;
height: 478px;
background: rgba(9, 9, 63, 0.05);
border-radius: 100%;
margin-left: 110%;
position: relative;
 top: 50px;
    }

.phonebodymob{
    order: 3;
    width: 676px;
height: 75px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
margin-left: -101%;
margin-top: 265%;
margin-bottom: 60%;

}

    .adobe{
        width: 89.62px;
height: 23.58px;
    }

    .adidas{
        margin-left: 30px;
        width: 45.28px;
height: 46.23px;
    }
    .burger{
        width: 43.4px;
height: 46.23px;
    }
    .kfc{
        margin-left: 20px;
        width: 43.4px;
height: 46.23px;
    }
    .fedex{
        width: 60.38px;
height: 25.47px;
    }
    .uptime{
        margin-left: 20px;
        width: 66.04px;
height: 23.58px;

    }

    .title2taglogofre{
        /* width: 950px; */
height: 100px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 20%; */
margin-left: auto;
margin-right: auto;
margin-bottom: 6.5%;
margin-top: 3%;

    }

    .title2tagfre2{
        /* width: 750px; */
        height: 100px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4%;
        margin-top: -6%;
    }


    .title2tagfre{
        /* width: 950px; */
height: 100px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 50px;
font-weight: 400;
line-height: 60px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 20%; */
margin-left: auto;
margin-right: auto;
margin-bottom: 3%;
margin-top: 13%;

    }

    .titlee1tag{
        margin-top: 30px;
        width: 110px;
        margin-left: 35.5%;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1);
    }

    .arrowfre{
        display: none;
    }

    .extratestimonial{
        display: none;
    }

    .title1tagfre{
        margin-top: 14%;
        /* margin-left: 36.5%; */
        margin-left: auto;
        margin-right: auto;
width: 300px;
height: 64px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;

text-align: center;
background: rgba(232, 237, 252, 1);

color: rgba(0, 0, 0, 1);
    }


    .title1taglogofre{
        margin-top: 10%;
        margin-left: auto;
        margin-right: auto;
width: 400px;
height: 64px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1);
    }

    .title2tagexp2gfre{
        height: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        margin-bottom: 0em;
    }

    .contentparafre p{
        width: 601px;
height: 259.3px;
    margin-top: 15%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    /* margin-left: 34%; */
      order: 2;
      font-family:'Plus Jakarta Sans', sans-serif;
font-size: 55px;
font-weight: 300;
line-height: 68px;
letter-spacing: 0em;
text-align: center;
      margin-bottom: 50px;
      

    }

    .titlee1tag{
        width: 234px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;



        /* margin-top: 30px;
        width: 110px;
        margin-left: 35.5%;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1); */


    }

    .violetbox{
        background: rgba(130, 27, 97, 1);
        width: 203px;
        height: 203px;
        margin-top: -39%;
        margin-bottom: 50%;
        margin-left: -77%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;     

    }

    .vt1fre{
        width: 91px;
height: 45px;
font-family: 'Plus Jakarta Sans', sans-serif;  
position: relative;
font-size: 36px;
font-weight: 400;
line-height: 45px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);
/* left: 0px; */
margin-left: 10%;
margin-top: 10%;    }

    .vt2fre{
        width: 83px;
height: 19px;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-left: -42%;
margin-top: 35%;
position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif; 
    }

    .yellowbox{
        background: rgba(247, 180, 0, 1);
        width: 118px;
        height: 118px;
        margin-top: -240%;
        position: relative;
        margin-left: -76%;
        font-family: 'Plus Jakarta Sans', sans-serif;           
    }
    .yt1fre{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
letter-spacing: 0em;
text-align: left;
    margin-top: 20px;
    margin-left: 10%;
    }

    .lineboxfre{
        width: 164px;
height: 164px;
border: 1px;
opacity: 0.1px;
margin-left: -61%;
 position: relative;
margin-top: -200%;
margin-bottom: 219%;
border: 1px solid rgba(0, 0, 0, 1);

    }

    .hlinebox1fre{
        width: 100px;;
    height: 163px;
    border: 1px solid rgba(0, 0, 0, 1);
    opacity: 15%;
    left: 34px;
    position: absolute;
}

.hlinebox2fre{
    width: 40px;
height: 163px;
/* opacity: 30%; */
border: 1px solid rgba(0, 0, 0, 1);
margin-left: 30%;
}

.rlinebox1fre{
    margin-top: 10px;
    width: 163px;
height: 100px;
opacity: 30%;
border: 1px solid rgba(0, 0, 0, 1);

top: 20px;
position: absolute;
}
.rlinebox2fre{
    /* margin-top: 20px; */
    width: 163px;
height: 40px;
opacity: 30%;
top: 27px;
border: 1px solid rgba(0, 0, 0, 1);

position: absolute;
}

.lineboxtextfre{
    width: 55px;
height: 35px;
margin-top: 20px;
margin-left: 10%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 36px;
font-weight: 400;
line-height: 45px;
letter-spacing: 0em;
text-align: left;
position: absolute;

}

.lineboxtext2fre{
    width: 82px;
height: 19px;
    margin-top: 2px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 18.6px;
    text-align: left;
    
}

.siginslet{
    margin-top: 8.5%;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    color: rgba(255, 255, 255, 1);
}

    .signinss{
        width: 173px;
height: 60px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
text-align: right;
display: flex;
justify-content: center;
margin-top: 15%;
margin-left: 110px;
text-align: center;
align-content: center;
background: rgba(7, 60, 165, 1);
border: none;
/* text-align: center; */

    }

    .instlogo1{
        width: 50px;
        height: 50px;
    }

    .watchs{
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 17px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
text-align: left;
    }

    .watchsss{
        width: 200px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
display: flex;
justify-content: center;
margin-left: 40px;
margin-top: 15%;
text-align: center;
border-radius: 37.5px;
background-color: white;
    border: 1px solid rgba(7, 60, 165, 1);
        color:rgba(7, 60, 165, 1);
    }

    .linetextttfre{
        width: 96%;
height: 130px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 29.76px;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 5%;
/* display: none; */
    }
    .linetextttfre2{
        /* width: 800px; */
height: fit-content;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 29.76px;
text-align: center;
margin-left: auto;
margin-right: auto;
/* margin-top: 7%; */
/* display: none; */
margin-bottom: 5em;
    }

    .image-container2{
        margin-left: 20px;
    }

  
    .image-container {
        margin-left: 60px;
      order: 1;
    }

    .chatbox121tab{
        width: 127.72px;
height: 97.12px;
    margin-top: -21.3%;
    margin-left: 26%;
    position: relative;
    background: rgba(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100.3%); 
    }

    .chatbox121fre{
        /* width: 61.38px;
height: 46.67px;
    margin-top: -10.8%;
    margin-left: 64%;
    position: absolute;
    background: rgba(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100.3%);    */
    display: none;
 }

 .chatbox141fre{
    display: none;
 }

 .chatbox141tab{
    width: 128px;
height: 97px;
background: rgba(231, 242, 255, 1);
position: relative;
margin-top: -0%;
clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
margin-left: -120%;
}

.chatbox151fre{
    display: none;
}

.chatbox151tabfre{
    width: 127px;
height: 97px;
background: rgba(207, 240, 230, 1);
position: relative;
margin-top: -21%;
clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100%);
margin-left:140%;
}

.chatbox111fre{
    display: none;
}

.chatbox111tab{
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    width: 127.72px;
height: 97.12px;
background: rgba(231, 242, 255, 1);
margin-left: -105%;
/* margin-top: -59.7%; */
position: relative;
}

.orgvector{
    width: 313px;
    height: 428.07px;
background: rgba(255, 91, 0, 1);
border-top-right-radius: 90%;
margin-left: -55%;
position: relative;
margin-top: 110%;
}
.img-fluid1{
    width: 375px;
height: 456.75px;
position: absolute;
margin-top: 34.3%;
margin-left: -25.5%;
}

.sechead{
    margin-top: -15%;
}

.josephlogo{
    width: 100px;
    height: 100px;
}

.instlogofr{
    /* width: 100px; */
    height: 100px;
}

    body{
        overflow-x: hidden;
    }

    .startedtab{
        margin-bottom: -14%;

    }

    .freimgpart{
        margin-top: 7%;
    }

    .lapbreak{
        display: none;
    }

   
}



@media (max-width: 576px) {

    .freimgpart{
        position: relative;
        margin-top: 120px;
        left: 50%;
        transform: translate(-50%, -50%);
    }






    .chatbox1fre:hover ,
    .chatbox2fre:hover,
    .extratestimonial:hover,
    .chatbox4fre:hover,
    .chatbox5fre:hover
    {
        transform: none; /* Scale up the element on hover */
      }
    
      /* circle animation  */

      @keyframes moveUp {
        0% { transform: translateY(30px); }
        100% { transform: translateY(-10px); }
    }
    
    .org1circle, .vio1circle, .blue1circle {
        
        animation: none; /* Initially no animation */
    }
    
    .scrolling .org1circle {
        animation: moveUp 0.8s forwards; /* Play animation once and stop */
    }
    
    .scrolling .vio1circle {
        animation: moveUp 1.5s forwards; /* Slightly delay the animation */
    }
    
    .scrolling .blue1circle {
        animation: moveUp 2s forwards; /* Slightly delay the animation */
    }
    
    
  
    

    /* .parallax-circle{
        animation-name: dance;
        transition: 1s;
        transform: translateY(-10px);
  animation-duration: 1s;
  animation-iteration-count: infinite;
    }
    
    @keyframes dance {
        0% { transform: translateY(0); }
        50% { transform: translateY(-30px); }
        100% { transform: translateY(0); }
      } */

      .parallax-circle {
        /* width: 100px;
        height: 100px;
        background-color: blue;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer; */
        transition: transform 0.5s ease; /* Smooth transition for the dancing effect */
      }
      
      .parallax-circle.dancing {
        transform: translateY(-25px); /* Adjust the value as needed */
        animation: 60s ease-out alternate; /* Animation for the dancing effect */
      }


    
    /* FOR BOXES */
    /* .dancing-divv {
        opacity: 0;
        transform: translateY(-400px);
        transition: 1s ;
    }
      
      .dancing-divv.visible {
        opacity: 1;
        transform: translateY(0%);
        animation:  0.5s ease-in-out forwards ;
      }
      
      @keyframes shake {
        0% { transform: translateX(0); }
        25% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
        75% { transform: translateX(-10px); }
        100% { transform: translateX(0); }
      }
      
  
      

 
    .dancing-div {
       
        transition: transform 0.5s ease;
      }
      
      .dancing-div.dancing {
        transform: translateY(-25px); 
        animation: 60s ease-out alternate; 
      }
      
      @keyframes bounce {
        from {
          transform: translateY(-25px);
        }
        to {
          transform: translateY(0);
        }
      } */

    .title2tagfre2{
        
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: center;
width: 88%;
margin-left: auto;
margin-right: auto;

    }

    .giffre{
        height: 50px;
        position: absolute;
        top: -150px;
        left: 165px;
    }

    .registertabfre{
        display: none;
    }

    .startedtab{
        display: none;
    }

    .chatheadtitletab{
        display: none;
    }

    .chatboxtext1tab{
        display: none;
    }
    .chatbox111tab{
        display: none;
    }.chatboxtext21tab{
        display: none;
    }
    .chatbox121tab{
        display: none;
    }
    .chatimg2tab{
        display: none;
    }
    .chatimg4tabfre{
        display: none;
    }.chatboxtext41tab{
        display: none;
    }
    .chatbox141tab{
        display: none;
    }.chatimg5tabfre{
        display: none;
    }
    .chatboxtext51tab{
        display: none;
    }.chatbox151tabfre{
        display: none;
    }

    .assesheadtitletab{
        display: none;
    }

    .assesshead1tabfre{
        display: none;
    }

    .assesshead2tab{
        display: none;
    }

    .register2tab{
        display: none;
    }

    .head13tab{
        display: none;
    }
    .head12tabfre{
        display: none;
    }
    .traincirclepad{
        display: none;
    }
    .luck{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  display: inline-block;
    
    }
    .mid3contentfresher{
        display: none;
    }

    .fresher1tag{
        display: none;
    }

    .logos{
        /* padding: 20px 0 0 0; */
        /* padding-right: 0%; */
        /* margin-left: 0%; */
        white-space: nowrap;
    
    }
    
    .logos:hover .logo-slidefre 
    {
        /* animation-play-state: paused ; */
    }
    
    .logo-slidefre{
        display: inline-block;
        animation:80s slide infinite linear;
    }
    
    
    
    .logo-slidefre img{
        margin: 0 40px;
        height: 50px;
    }
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logoss{
        /* overflow: hidden; */
        /* padding: 20px 0; */
        white-space: nowrap;
    
    }
    
    .logoss:hover .logos-slidefre 
    {
        /* animation-play-state: paused ; */
    }
    
    .logos-slidefre{
        display: inline-block;
        animation: 80s slides infinite linear;
    }
    
    .logos-slidefre img{
        margin: 0 40px;
        height: 50px;
    }
    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }




    html,body{

        overflow-x: hidden;
    }

    .namesm{
        width: 250px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 600;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
    }

    .desgsm{
        width: 134px;
height: 30px;
font-size: 15px;
font-family: 'Plus  Jakarta Sans', sans-serif;
font-style: italic;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
margin-top: -30px;
margin-left: 19%;
    }

    .brandppfre{
        position: absolute;
       /* margin-top: 18.5%; */
       
       margin-left: 35px;
width: 260px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);

    }

    .hireppfre{
        /* width: 365px;
height: 175px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
padding: 10px; */

/* margin-top: 18.5%; */
position: absolute;
margin-left: 35px;
       /* margin-left: 17.5%; */
width: 260px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);

    }

    .digitalppfre{
        /* width: 365px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
padding-left: 10px;
padding: 10px; */
position: absolute;
/* margin-top: 25.5%; */
margin-left: 35px;
       /* margin-left: 17.5%; */
width: 260px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);
    }

    .trainppfre{
        /* padding-top: 9%;
        width: 365px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
padding-left: 10px; */
position: absolute;
/* margin-top: 26%; */
margin-left: 35px;
       /* margin-left: 17.5%; */
width: 260px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);

    }

    .brandhfre{
        position: relative;
        /* left: 50%;
    transform: translate(-50%, -50%); */
        top: 18%;
        /* margin-left: 17.2%; */
        width: 250px;
        height: 24px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 35px;
    margin-bottom: 4em;
    }

    .hirehfre{
        width: 250px;
        height: 24px;
        position: relative;
top: 18%;
text-align: left;
    margin-left: 35px;
        /* margin-left: 17%; */
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;
    margin-bottom: 4em;
    }

    .digitalhfre{
        top: 20%;
position: relative;
margin-left: 35px;  
        /* margin-left: 17.5%; */
        width: 258px;
height: 20px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 900;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
margin-bottom: 4.5em;
    }

    .trainhfre{
        top: 18%;
position: relative;
margin-left: 35px;
        /* margin-left: 17.5%; */
width: 259px;
height: 15px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 900;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
margin-bottom: 4.5em;
    }



    .brandfre{
        width: 334px;
    height: 300px;
        background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
        margin-top: 0%;
    /* text-align: center; */
    border-radius: 50px;
    margin-bottom: 18px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    text-align: center;
    /* margin-left: 2.5%; */
    }
    
    .hirefre{
        width: 334px;
    height: 300px;
        background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
        /* font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em; */
    /* text-align: center; */
    border-radius: 50px;
    margin-bottom: 18px;
    /* margin-left: 2.5%; */
    margin-left: auto;
    margin-right: auto;
    position: relative;
    text-align: center;
    }
    
    .digitalfre{
        width: 334px;
    height: 300px;
        border-radius: 50px;
        background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%); font-size: 20px;
        /* font-weight: 900;
        line-height: 24px;
        letter-spacing: 0em; */
        /* text-align: center; */
        /* border-radius: 50px; */
        margin-bottom: 18px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        /* margin-left: 2.5%; */
        margin-left: auto;
        margin-right: auto;
        position: relative;
        text-align: center;
    }
    
    .trainfre{
        width: 334px;
    height: 300px;
        border-radius: 50px;
        background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
        margin-bottom: 120px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        text-align: center;
    }

    .regisfre{
        font-family: 'Plus Jakarta Sans', sans-serif;
        color: rgba(7, 60, 165, 1);
        width: 100px;
height: 19px;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
margin-top: 6%;
margin-left: 13%;

    }

    .bluec{
        width: 32.91px;
height: 32.91px;
margin-top: 21%;
margin-left: 77%;
background: rgba(7, 60, 165, 1);
border-radius: 100%;
position: relative;
    }

    .whitearrowfre{
        width: 14.63px;
height: 18.28px;
margin-left: 27%;
/* margin-left: -50%; */

    }

    .whitea{
        width: 100px;
        height: 100px;
    }

    .startparalapscreen{
        width: 400px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-top: -52%;
/* margin-left: 10%; */
margin-left: auto;
margin-right: auto;
position: relative;


    }

    .startheadfre{
        width: 500px;
height: 31px;
margin-left: 0px;
margin-top: 15px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
color: rgb(255, 255, 255, 1);
    }

    .subbuttonfre{
        /* top: 40px; */
        margin-top: -160px;
        position: absolute;
        margin-left: 130px;
        height: 42px;
        width: 140px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }

    .startedboxfre{
        margin-top: 50px;
        width: 98.43%;
    height: 170px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
    
    }

    .chatboxtext51{
        padding: 10%;
        width: 240px;
height: 180px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;



    }

    .chatbox5s1fre{
        width: 254px;
height: 170px;
border-radius: 20px 20px 0px 20px;
margin-top: 27px;
background: rgba(207, 240, 230, 1);
margin-left: 13%;

    }


    .chatimg5fre{
        width: 40.11px;
height: 40.11px;
margin-left: 340px;
margin-top: 145px;
position: absolute;
    }
    

    .chatboxtext41{
        width: 230px;
height: 108px;
padding: 20px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
    }

    .startparalapscreen{
        /* display: none; */
    }

    .chatbox4s1{
        width: 250px;
    height: 178px;
    border-radius: 0px 20px 20px 20px;
    background: rgba(231, 242, 255, 1);
    margin-top: 0px;
    margin-left: 30%;
   
    }

    .chatimg4fre{
        width: 40.11px;
        height: 40.11px;
        margin-left: 45px;
        margin-top: 15px; 
       position: absolute;
    }

    .chatboxtext21{
        padding: 30px;
        width: 290px;
height: 144px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;


    }

    .chatbox2s1{
        background: rgba(207, 240, 230, 1);
        width: 290px;
height: 200px;
border-radius: 20px 20px 0px 20px;
margin-top: 25px;
margin-left: 20px;
    }

    .chatimg2fre{
        width: 40px;
height: 40px;
margin-top: -100px;
margin-left: 340px;

    }


    .chatboxtext1{
        padding: 23px;
        width: 250px;
height: 245.15px;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;

    font-family: 'Plus Jakarta Sans', sans-serif;
    }

    .chatbox1s1fre{
        width: 254px;
height: 325px;
border-radius: 0px 20px 20px 20px;
margin-top: -65px;
background: rgba(231, 242, 255, 1);
margin-left: 27%;

    }
    
    .chatimg1fre{
        width: 40px;
        height: 40px;
        margin-left: 25px;
        position: relative;
        top: -10px;
    }

    .chatfre{
        margin-top: 50px;
    }

    .chatheadtitlefre{
        width: 244px;
height: 62px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: 82px;
margin-bottom: -40px;
    }

    .chatitle1tagfre{
        width: 180px;
height: 34px;
border-radius: 37.5px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 40px;
    }

    .ctext{
        margin-left: 20px;
        margin-top: 10px;
    }
    

    .register2fre{
        width: 200px;
height: 42px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
position: relative;
/* margin-top: -200px; */
/* margin-left: 140px; */
margin-left: auto;
margin-right: auto;
border: none;
background: rgba(7, 60, 165, 1);
top: -22px;
margin-bottom: 10%;
    }

    .lapbreak{
        display: none;
    }


    .assesshead1fre{
        width: 92.5%;
height: 90px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 30px; */
margin-left: auto;
margin-right: auto;
margin-bottom: 100px;
    }
    .assesshead2fre{
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-top: -10%;
margin-left: 30px;
    }

    .assesheadtitlefre{
        width: 248px;
        height: 31px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: -185px;
    }

.assesstitle1tagfre{
    margin-top: -240px;
    /* margin-left: 40%; */
    margin-left: auto;
    margin-right: auto;
    width: 105px;
    height: 34px;
    border-radius: 37.5px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
}
    

    .assesscirclefre{
        overflow-x: hidden;
        width: 260px;
height: 260px;
background: rgba(9, 9, 63, 0.05);
margin-left: 259px;
border-radius: 100%;
    }

    .registerfre{
        width: 200px;
height: 42px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 35%; */
margin-left: auto;
margin-right: auto;
top: -50px;
position: relative;
background: rgba(7, 60, 165, 1);
border: none;
left: 0px;
    }
    .register1tfre{
        padding-top: 5%;
        margin-left: auto;
        margin-right: auto;
        color: rgba(255, 255, 255, 1);

    }

    .head12fre{
        width: 92.5%;
height: 90px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 30px; */
margin-left: auto;
margin-right: auto;

    }

    .head13fre{
        margin-top: -10%;
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 30px; */
margin-left: auto;
margin-right: auto;

    }



    .traintitle1tagfre{
        width: 105px;
height: 34px;
border-radius: 37.5px;
margin-top: -60%;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 38%; */
margin-left: auto;
margin-right: auto;

    }

    .trainheadtitletabfre{
        width: 194px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: -230px;


    }

    .traincirclefr{
        width: 260px;
        height: 260px;
        position: relative;
    background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -120px;
    top: -50px;
    }

    .phonetitles{
        margin-left: 10px;
        margin-top: 100px;

    }
    
    .phonebody{
        /* margin-left: 10px; */
    }
    .org1circle{
        width: 121.5px;
height: 121.5px;
position: relative;
margin-top: 0%;
margin-left: 0%;
    }

    .org1circle::before{
        content: '';
        position: absolute;
        width: 121.5px;
        margin-left: 74%;
height: 121.5px;
        border-radius: 50%;
        opacity: 90%;
      }
      
      .org1circle::before {
          background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
        }
    .vio1circle{
        margin-top: -10%;
        position: relative;
        width: 121.5px;
height: 121.5px;
opacity: 90%;
    }

    .vio1circle::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }

    .vio1circle::before {
        content: '';
        position: absolute;
        margin-top: -10%;
        margin-left: 35%;
        width: 121.5px;
height: 121.5px;
        border-radius: 50%;
        opacity: 100%;
      }

    .blue1circle{
        width: 183.91px;
height: 183.91px;
margin-top: -50%;
margin-left: 38%;
position: absolute;
background: rgba(135, 189, 255, 1);
border-radius: 100%;

    }

    .phones{
        width: 177px;
    height: 324.75px;
    margin-top: -70%;
    margin-left: 26%;
    position: relative;
    /* order: 2; */
    
    }

    .phonebodylapscreen{
        display: none;
    }

    .phonetitles{
        order: 1;
    }

    .phonetitle1tagfre{
        margin-top: 10%;
        width: 220px;
        height: 34px;
        border-radius: 37.5px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
position: relative;
margin-left: auto;
margin-right: auto;
background: rgba(232, 237, 252, 1);

    }

    .firstsignup{
        margin-top: -400px;
        text-align: center;
    }

    
    .firstsignup1{
        margin-top: -240px;
    }

    .phoneheadtitlefre{
        width: 270px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 23%; */
margin-left: auto;
margin-right: auto;
position: relative;
color: rgba(0, 0, 0, 1);
    }

.image-phone-container{
    margin-top: 100px;
    margin-left: 10px;
    order: 1;
}

.phonebodymob{
    order: 3;
    width: 358px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 35px; */
margin-top: 25px;
margin-left: auto;
margin-right: auto;
margin-bottom: 30%;
}

    .adobe{
        width: 89.62px;
height: 23.58px;
    }

    .adidas{
        margin-left: 30px;
        width: 45.28px;
height: 46.23px;
    }
    .burger{
        width: 43.4px;
height: 46.23px;
    }
    .kfc{
        margin-left: 20px;
        width: 43.4px;
height: 46.23px;
    }
    .fedex{
        width: 60.38px;
height: 25.47px;
    }
    .uptime{
        margin-left: 20px;
        width: 66.04px;
height: 23.58px;

    }

    .title2taglogofre{
        /* width: 400px;
height: 31px; */
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 100px; */
margin-left: auto;
        margin-right: auto;
/* margin-top: -10%; */
/* margin-bottom: 12%; */

    }
    .title2tagfre{
        width: 100%;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
        margin-right: auto;
margin-top: 110px;
margin-bottom: 2%;

    }

    .titlee1tag{
        margin-top: 30px;
        width: 110px;
        margin-left: 35.5%;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1);
    }

    .arrowfre{
        display: none;
    }

    .extratestimonial{
        display: none;
    }

    .title1tagfre{
        margin-top: -9%;
        width: 105px;
        /* margin-left: 36.5%; */
        margin-left: auto;
        margin-right: auto;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);

color: rgba(0, 0, 0, 1);
    }

    .title1taglogofre{
        width: 180px;
        margin-left: auto;
        margin-right: auto;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1);
    }

    .title2tagexp2gfre{
        width: 250px;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
        top: -10px;
        margin-bottom: -1em;
        position: relative;
    }

    .contentparafre{
        width: 300px;
height: 93px;
        display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;
      order: 2;
      font-family: 'Plus Jakarta Sans', sans-serif;
      font-size: 25px;
      /* font-weight: 300; */
      line-height: 31px;
      letter-spacing: 0em;
      text-align: center;
      margin-bottom: 50px;
      

    }

    .titlee1tag{
        width: 234px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;



        /* margin-top: 30px;
        width: 110px;
        margin-left: 35.5%;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1); */


    }

    .violetbox{
        background: rgba(130, 27, 97, 1);
        width: 123px;
        height: 124px;
        margin-top: -27%;
        /* margin-bottom: 80%; */
        margin-left: 10%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;     
font-size: 10px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;

    }

    .vt1fre{
        width: 55px;
height: 35px;
font-family: 'Plus Jakarta Sans', sans-serif;  
position: relative;
left: 15px;
top: 15px;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);


    }

    .vt2fre{
        margin-top: 35px;
        margin-left: -40px;
        font-family: 'Plus Jakarta Sans', sans-serif; 
        color: rgba(255, 255, 255, 1);
font-size: 10px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
    }

    .yellowbox{
        background: rgba(247, 180, 0, 1);
        width: 72px;
        height: 72px;
        margin-top: -9%;
        margin-left: 7%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;           
    }
    .yt1fre{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
letter-spacing: 0em;
text-align: left;
margin-left: 5px;
    margin-top: 10px;
    }

    .lineboxfre{
        width: 100px;
height: 100px;
border: 1px;
opacity: 0.1px;
/* margin-top: 38%; */
margin-left: 15%;
 position: relative;
margin-top: -71%;
/* margin-bottom: 158%; */
border: 1px solid rgba(0, 0, 0, 1);

    }

    .hlinebox1fre{
        width: 63px;;
    height: 99px;
    opacity: 15%;
    left: 1px;
    margin-left: 17%;
    border: 1px solid rgba(0, 0, 0, 1);
    position: absolute;
}

.hlinebox2fre{
    width: 23px;
height: 99px;
opacity: 30%;
border: 1px solid rgba(0, 0, 0, 1);
/* left: 10px; */
margin-left: 30%;
}

.rlinebox1fre{
    margin-top: 10px;
    width: 99px;
height: 63px;
opacity: 20%;
top: 8px;
border: 1px solid rgba(0, 0, 0, 1);
position: absolute;
}
.rlinebox2fre{
    /* margin-top: 20px; */
    width: 99px;
height: 23px;
border: 1px solid rgba(0, 0, 0, 1);
opacity: 30%;
/* border: 1px solid rgba(0, 0, 0, 1); */
top: 20px;
position: absolute;
}

.lineboxtextfre{
    width: 55px;
height: 35px;
margin-top: -60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;
top: 80px;
margin-left: 10%;
position: absolute;

}

.lineboxtext2fre{
    margin-top: 2px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 10px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;
}

.siginslet{
    margin-top: 8%;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);


}

    .signinss{
        width: 137px;
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
display: flex;
justify-content: center;
margin-top: 300px;
margin-left: 50px;
text-align: center;
align-content: center;
background: rgba(7, 60, 165, 1);
border-radius: 37.5px;
border: none;
/* text-align: center; */

    }

    .instlogo1{
        width: 50px;
        height: 50px;
    }


    .watchs{
        padding-top: 8%;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;

    }

    .watchsss{
        width: 150px;
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
display: flex;
justify-content: center;
align-items: center;
margin-left: -8px;
margin-top: 300px;
/* text-align: center; */
border-radius: 30px;
background-color: white;
    border: 1px solid rgba(7, 60, 165, 1);
        color:rgba(7, 60, 165, 1);
    }

    .linetextttfre{
        width: 88%;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 13px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 118%;
    }

    .linetextttfre2{
        width: 88%;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 13px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
/* margin-left: auto;
margin-right: auto; */
margin-top: 10%;
position: absolute;
margin-bottom: 4em;
left: 50%;
    transform: translate(-50%, -50%);
    }

    .image-container2{
        margin-left: 20px;
    }

  
    .image-container {
        margin-left: 90px;
        /* margin-top: 12%; */
      order: 1;
    }

    .chatbox121fre{
        width: 61.38px;
height: 46.67px;
    margin-top: -12.2%;
    margin-left: 77%;
    position: relative;
    background: rgba(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100.3%);   
 }

 .chatbox141fre{
    width: 61px;
height: 46px;
background: rgba(231, 242, 255, 1);
position: relative;
/* margin-top: -41.3%; */
clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
margin-left: -110%;
}

.chatbox151fre{
    /* width: 61px;
height: 46px;
background: rgb(9, 9, 9);
position: relative;
clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100%);
margin-left: 0%; */
display: none;
}

.chatbox151mobfre{
    width: 61px;
height: 46px;
background: rgb(207, 240, 230, 1);
position: relative;
margin-top: -12.1%;
clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100%);
margin-left: 77%;
/* display: block; */
}

.chatbox111fre{
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    width: 61px;
height: 46px;
background: rgba(231, 242, 255, 1);
margin-left: -120%;
/* margin-top: -75.5%; */
position: relative;
}

.orgvector{
    
width: 191px;
height: 261px;
background: rgba(255, 91, 0, 1);
border-top-right-radius: 90%;
margin-left: 12%;
margin-top: 10%;
position: absolute;
}
.img-fluid1{
    width: 228.54px;
height: 278.36px;
margin-top: 8.5%;
margin-left: 11%;
position: relative;
}

.sechead{
    margin-top: -15%;
}

.josephlogo{
    width: 100px;
    height: 100px;
}

.assesheadtitfretab{
    display: none;
}


    body{
        overflow-x: hidden;
    }
    
  }