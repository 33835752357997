@media (min-width:1400px){
    .breakhideinlap{
        display: none;
    }

    .prtwidth1{
        width: 500px;
    }

    .prtwidth2{
        width: 650px;
    }

    .prtwidth3{
        width: 650px;
    }

    /* box click element start */

    .clickihv1prt{
        width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
    }

    .clickihv2prt{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickihv3prt{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clickhv1prt{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clickhv2prt{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickhv3prt{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clichv1prt{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clichv2prt{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clichv3prt{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clihv1prt{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clihv2prt{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clihv3prt{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    /* box click elemet end */

    .partnerpagemain{
        margin-top: -2.4%;
    }

    .patmarimg {
        height: 110px; /* Adjust height as needed */
        background-size: 120%;
        background-color: white;
        border-radius: 100%;
      }


    .imgscrolluppat {
        /* animation: scroll-up 20s linear infinite; */
        display: flex;
        flex-direction: column;
        margin-left: 70%;
      }
      
      @keyframes scroll-up {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-100%);
        }
      }

    /* top image animation  */

    .danceprt-divv {
        opacity: 0;
        transform: translateY(-400px);
        transition: 1s ;
    }
      
      .danceprt-divv.visible {
        opacity: 1;
        transform: translateY(-100%);
        animation:  0.5s ease-in-out forwards ;
      }
      
      @keyframes shake {
        0% { transform: translateX(0); }
        25% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
        75% { transform: translateX(-10px); }
        100% { transform: translateX(0); }
      }
      
    .danceprt-div {
        transition: transform 0.5s ease; 
      }
      
      .danceprt-div.dancing {
        transform: translateY(-25px); 
        animation: 60s ease-out alternate; 
      }
      
      @keyframes bounce {
        from {
          transform: translateY(-25px);
        }
        to {
          transform: translateY(0);
        }
      }

      /* box click event */

      .clickinsprt{
        width: 34px;
        height: 34px;
        position: relative;
        left: 320px;
        top: -45px;
        cursor: pointer;
    }

    .clickinsprt2{
        width: 34px;
height: 34px;
position: relative;
left: 750px;
top: -45px;
cursor: pointer;
    }

    .clickinsprt3{
        width: 34px;
        height: 34px;
        position: relative;
        left: 620px;
        top: -50px;
        cursor: pointer;
    }

    .clickinsprt4{
        width: 34px;
        height: 34px;
        position: relative;
        left: 1075px;
        top: -8px;
        cursor: pointer;
    }

    /* Start of brand */

    .brandhovercontainerpat{
        position: relative;
    }
    
    .accesshovercontainerpat{
        position: relative;
    }
    
    .autohovercontainerpat{
        position: relative;
    }
    
    .hirehovercontainerpat{
        position: relative;
    }
    
    
    .imagecontainerbrandpat{
        position: relative;
        /* transition: opacity 0.3s ease;
        cursor: pointer;
        z-index: 1; */
    }
    
    .imagecontainacespat{
        position: relative;
        top: -35px;
        /* transition: opacity 0.3s ease;
        cursor: pointer;
        z-index: 1; */
    }
    
    .imagecontainerautopat{
        position: relative;
        top: -30px;
        /* transition: opacity 0.3s ease;
        cursor: pointer;
        z-index: 1; */
    }
    
    .imageconthirepat{
        position: relative;
        top: -50px;
        /* transition: opacity 0.3s ease;
        cursor: pointer;
        z-index: 1; */
    }

    .brandhovercontentpat{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        position: absolute;
        top: -0.85%;
      left: 7%;
      z-index: 2;
    
    }
    
    .accesshovercontentpat{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        position: absolute;
        margin-top: -37.7%;
      left: 7%;
      z-index: 2;
    }
    
    .autohovercontentpat{
        background-color: #ffffff;
        width: 1300px;
        height: 471px;
        position: absolute;
        margin-top: -41%;
      left: 7%;
      z-index: 2;
    }
    
    .hirehovercontentpat{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        position: absolute;
        margin-top: -41.2%;
      left: 7%;
      z-index: 2;
    }
    
    
    
    /* .imagecontainerbrandpat:hover + .brandhovercontentpat {
        opacity: 1; 
      } */
    
      /* .imagecontaineraccesspat:hover + .accesshovercontentpat {
        opacity: 1; 
      } */
    
      /* .imagecontainerautopat:hover + .autohovercontentpat {
        opacity: 1; 
      } */
    
      /* .imagecontainerhirepat:hover + .hirehovercontentpat {
        opacity: 1; 
      } */
    
    
    
    .brandcontentpat{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
    }
    
    .brandheaderpat{
        width: 660px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 5%;
    position: absolute;
    margin-top: 2.5%;
    }
    
    .brandparapat{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .brandhoverimages{
        margin-left: 74.5%;
        margin-top: -35%;
    }
    
    .hoverimg1brandpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    position: absolute;
    }
    
    .brandhoverimg1bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -7%;
    }
    
    .hoverimg2brandpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    /* margin-bottom: 2%; */
    position: absolute;
    }
    
    .brandprtimg2bg{
        width: 141px;
        height: 145px;
        border-radius: 20px;
        margin-bottom: -7%;
        background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
        opacity: 45%;
    }
    
    .hoverimg3brandpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-bottom: 2%;
    position: absolute;
    }
    
    .brandprtimg3bg{
        width: 141px;
        height: 145px;
        border-radius: 20px;
        margin-bottom: 2%;
        background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
        opacity: 45%;
    }
    
    .accesscontentpat{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
    }
    
    .accessheaderpat{
        width: 900px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 2.5%;
    margin-left: 5%;
    }
    
    .accessparapat{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 5%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .accesshoverimagespat{
        margin-left: 74.5%;
        margin-top: -35%;
    }
    
    .hoverimg1accesspat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        /* margin-bottom: 2%; */
        position: absolute;
    }
    
    .aceshovimg1bgpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    margin-bottom: -7%;
    }

    .aceshoveimg1bgpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    margin-bottom: -7%;
    }

    .aceshoverimg1bgpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    margin-bottom: -7%;
    }
    
    
    .hoverimg2accesspat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        position: absolute;
    }
    
    .aceshovimg2bgpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    margin-bottom: -7%;
    }

    .aceshoveimg2bgpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -7%;
    }

    .aceshoverimg2bgpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -7%;
    }
    
    .hoverimg3accesspat{
        position: absolute;
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
    }
    
    .aceshovimg3bgpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    opacity: 45%;
    margin-bottom: 2%;
    }
    
    .aceshoveimg3bgpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    opacity: 45%;
    margin-bottom: 2%;
    }

    .aceshoverimg3bgpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    margin-bottom: 2%;
    }
    
    .hireheaderpat{
        width: 700px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 2.5%;
    margin-left: 5%;
    }
    
    .hireparapat{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .hirecontentpat{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
    
    }
    
    
    .autocontentpat{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%);
    
    }
    

    /* end of brand */
    .bimg1xt{
        position: absolute;
        top: 1400px;
    }

    .partsbox{
        display: none;
    }
    
    body{
        overflow-x: hidden;
    }
    
    .register1t{
        padding-top: 10%;
    }
    

    
    .logosprt{
        white-space: nowrap;
    }
    
    .logo-slidetp{
        display: inline-block;
        animation: 120s slide infinite linear;
    }
    
    .logo-slidetp img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logossprt{
        white-space: nowrap;
        margin-bottom: 150px;
    }

    .logos-slidept{
        display: inline-block;
        animation: 80s slides infinite linear;
    }
    
    .logos-slidept img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }

    .corevalues{
        display: none;
    }
    
    .partimgmov{
        position: relative;
        left: 20px;
    }
    
    .contentt3{
        display: flex;
        justify-content: center;
    font-size: 40px;
    font-weight: 300;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
        margin-left: 20px;
        margin-top: 140px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        width: 700px;
    }
    
    .contentt3 br {
        content: '';
        display: block;
        /* margin-bottom: 10px;  */
    }
    
    .watchs3{
        padding-top: 6%;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    text-transform:capitalize;
    }
    
    .fresher1tag{
        /* align-items: center; */
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    /* text-indent: 10px; */
    margin-left: 7%;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 234px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    
    }
    .luck3{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  font-weight: 900;
    
    }
    .ftext{
        /* margin-top: 10px; */
        top: 15px;
        position: absolute;
        left: 70px;
    }
    
    .ima-container1 {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
        margin-left: 10%;
    }
    
    .background-container3 {
        position: absolute;
        bottom: 0;
        left: 60%;
        transform: translateX(-50%);
        width: 320px;
        height: 451px;
        border-top-left-radius: 100%;
        background-color: #CC0530;
    }
    
    .orgvector3 {
        /* z-index: 1;  */
        position: relative;
        margin-left: 15%;
    }
    
    .orgvector3 img {
        display: block;
    }
    
    .violetbox3{
        background: #821B61;
        width: 203px;
    height: 203px;
    display: flex;
    position: relative;
    margin-left: 22.5%;
    margin-top: -27.5%;
    display: inline-block;
    text-align: start;
    }
    
    .vt1pat{
        width: 91px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    }

    .ima-container4{
        position: relative;
        margin-left: 23%;
        margin-top: -4%;
        /* z-index: 1; */
    }
    
    .vt2pat{
        width: 83px;
    height: 19px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    margin-top: 65px;
    margin-left: -85px;
    }
    
    .yellowbox3pat{
        background:#F7B400;
        width: 118px;
    height: 118px;
    display: flex;
    margin-left: 14.5%;
    /* margin-right: 190px; */
    position: relative;
    margin-top: -9.2%;
    /* text-align: start; */
    }
    
    .yt1pat{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    }
    
    .yt2pat{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 43px;
    margin-left: -66px;
    }
    
    .ima-container2, .ima-container3, .ima-container5 {
        position: relative;
        /* z-index: 2;  */
    }
    
    .sechead3{
        margin-top: 5%;
    }
    
    .firstsignup3{
        position: relative;
        margin-top: -15%;
        left: -70px;
    }

    .siginspat{
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 2%;
    color: rgba(255, 255, 255, 1);
    
    
    }
    
    .signinpatcol
    {
        width: 173px;
    height: 60px;
    position: relative;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 12%;
    margin-left: 14%;
    background: rgba(7, 60, 165, 1);
    border: none;
    
    }
    
    .watchpat{
        width: 200px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 12%;
    margin-left: -380px;
    }
    
    .linetextprt{
        width: 1100px;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center ;
    margin-left: auto;
    margin-right: auto;
    top: 40px;
    position: relative;
    }

    .linetxtprt{
        width: 1100px;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center ;
    margin-left: auto;
    margin-right: auto;
    top: -10px;
    margin-bottom: 0em;
    position: relative;
    }
    
    .linebox3{
    
        width: 160px;
    height: 160px;
    display: flex;
    /* margin-right: 100px; */
    display: inline-block;
    /* top: 0px; */
    position: relative;
    margin-top: -72%;
    text-align: start;
    border: 2px solid rgba(0, 0, 0, 1);
    margin-left: 24%;
    
    }
    
    .hlinebox1pat{
        width: 102px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 16%;
    position: absolute;
    opacity: 30%;
    
    }
    .hlinebox2pat{
        width: 40px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 30%;
    opacity: 30%;
    position: absolute;
    
    }
    
    
    .rlinebox1pat{
        width: 159px;
    height: 102px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 10%;
    position: absolute;
    }
    
    .rlinebox2pat{
        width: 159px;
    height: 40px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 50%;
    }
    
    .lineboxtext{
        width: 64px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    color: rgba(0, 0, 0, 1);
    
    }
    .lineboxtext2{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .title1tagpat{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    margin-top: 0%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 170px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }

    .title1tagpatn{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    margin-top: 0%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 390px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }

    .title2tagpat2g{
        width: 1050px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: auto;
margin-right: auto;
    }

    .imagecontainerbrand{
        position: relative;
    }
    
    .mid3contentpat .img1{
        width: 312px;
    height: 462px;
    margin-left: 100px;
    margin-top: -3px;
    border-radius: 50px;
    }
    .mid3contentpat .bimg1{
        width: 312px;
    height: 462px;
    position: absolute;
    margin-top: -0.2%;
    margin-left: -24%;
    border-radius: 50px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    }
    
    .mid3contentpat .bimg1text{
        width: 146px;
    height: 84px;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    opacity: 100%;
    position: relative;
    /* display: block; */
    margin-top: -100px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-left: 120px;
    
    }
    
    
    .imagecontaineraccess{
        position: relative;
    }
    
    .mid3contentpat .img2{
        width: 513px;
    height: 231px;
    margin-top: -35%;
    margin-left: 34%;
    margin-bottom: 40%;
    border-radius: 50px;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    .mid3contentpat .bimg2{
        width: 513px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-top: -35%;
    margin-left: -39.5%;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    
    }
    
    .bimg2text{
        width: 300px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -45%;
    margin-left: 480px;
    opacity: 100%;
    position: relative;
    display: block;
    }
    
    .imagecontainerauto{
        position: relative;
    }
    
    .title2tagpat{
        width: 699px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    }

    .title2tagpat2{
        width: 1050px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: auto;
margin-right: auto;
    }
    
    .mid3contentpat .img3{
        width: 768px;
    height: 212px;
    border-radius: 50px;
    margin-top: -18%;
    margin-left: 34%;    
    }
    
    .mid3contentpat .bimg3{
        width: 768px;
    height: 212px;
    margin-top: -16%;
    margin-left: -59.2%;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    }
    
    .mid3contentpat .bimg3text{
        width: 430px;
    height: 84px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    margin-left: 36%;
    display: block;
    margin-top: -9%;
    color:rgba(255, 255, 255, 1);
    
    }
    
    .imagecontainerhire{
        position: relative;
    }
    
    
    .mid3contentpat .img4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    margin-top: -58%;
    margin-left: 75%;
    /* opacity: 45%px; */
    
    
    }
    
    .mid3contentpat .bimg4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-left: -17.7%;
    margin-top: -36.9%;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    }
    
    .bimg4textpat{
        width: 72px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    position: relative;
    display: block;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -31%;
    margin-left: 77%;
    
    }
    
    
    
    
    .phonebox{
        margin: 14%;
    }
    
    .phonetitle1tag{
        margin-top: 100px;
        /* align-items: center; */
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    /* position: relative; */
    /* text-indent: 10px; */
    /* margin-left: 17%; */
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 234px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }
    
    .phoneheadtitle{
        /* margin-left: 120px; */
        width: 381px;
        height: 174px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;    
    }
    
    .phonebody{
        width: 405px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .phone{
        width: 485px;
    height: 391px;
    margin-top: -290px;
    margin-left: 30px;
    position: relative;
    }
    
    .orgcircle{
        width: 240.93px;
    height: 240.93px;
    margin-top: -200px;
    border-radius: 100%;
    margin-left: 100px;
    /* background: rgba(247, 91, 2, 1); */
    position: relative;
    /* opacity: 90%; */
    }
    
    .viocircle{
        width: 240.93px;
    height: 240.93px;
    border-radius: 100%;
    /* background: rgba(130, 27, 97, 1); */
    margin-top: -120px;
    position: relative;
    opacity: 90%;
    }
    
    .viocircle::before {
      content: '';
      position: absolute;
      margin-top: 27%;
      margin-left: -20%;
      width: 240.93px;
    height: 240.93px;
      border-radius: 50%;
      opacity: 100%;
    }
    
    .orgcircle::before{
      content: '';
      position: absolute;
      margin-top: 35%;
      margin-left: -7%;
      width: 240.93px;
      height: 240.93px;
      border-radius: 50%;
      opacity: 90%;
    }
    
    .orgcircle::before {
        background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
      }
      
      .viocircle::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }
    
    .bluecircle{
        width: 364.7px;
    height: 364.7px;
    border-radius: 100%;
    background: rgba(135, 189, 255, 1);
    margin-top: -382px;
    margin-left: 170px;
    }
    
    .yellowcircle{
        width: 171.62px;
        height: 171.62px;
        border-radius: 100%;
        background: #F7B400; 
        margin-top: -170px;
        margin-left: 120px;
    }
    .arrow{
        width: 256.46px;
    height: 189.91px;
    margin-left: -250px;
    margin-top: -150px;
    }
    .image-phone-container{
        margin-left: 500px;
        margin-top: -400px;
    }
    
    .phonetitles{
        margin-left: -130px;
    }
    
    .phonebody{
        margin-left: -130px;
    }
    
    .trainingtitles{
        margin-top: -590px;
    }
    
    .traintitle1tag{
        width: 225px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    }
    
    .trainheadtitle{
        width: 542px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    }
    .traincircle{
        width: 673px;
    height: 673px;
    background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -250px;
    
    }
    
    .head12{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .head13{
        margin-top: -80px;
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .register{
        width: 173px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: -100px;
    background: rgba(7, 60, 165, 1);
    border: none;
    
    
    }
    
    .assesscircle{
        width: 673px;
    height: 673px;
    background: rgba(9, 9, 63, 0.05);
    border-radius: 100%;
    margin-left: 900px;
    
    }
    
    .assesstitle1tag{
        margin-top: -570px;
        margin-left: 990px;
        width: 225px;
        height: 64px;
        border-radius: 37.5px;
        background: rgba(232, 237, 252, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;    
    }
    
    .assesheadtitle{
        width: 693px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: right;
    margin-left: 530px;
    }
    
    .assesshead1{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 150px;
    text-align: right;
    }
    
    .assesshead2{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 150px;
    text-align: right;
    margin-top: -50px;
    }
    
    .register2{
        width: 173px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 1050px;
    margin-top: -100px;
    border: none;
    background: rgba(7, 60, 165, 1);
    }
    
    .chat{
        margin-top: 200px;
    }
    
    .chatitle1tagtp{
        width: 200px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    
    }
    
    .ctext{
        margin-left: 30px;
        margin-top: 15px;
    }
    
    .chatheadtitle{
        width: 551px;
    height: 174px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .chatimg1{
        width: 87px;
    height: 87px;
    }
    .chatbox111pat{
        clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
        width: 84.16px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    margin-left: 6%;
    margin-top: -40.6%;
    position: absolute;
    }
    
    .chatbox11pat{
        width: 378px;
    height: 615px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-left: 150px;
    margin-top: -80px;
    }
    
    .chatboxtext1pat{
        width: 370px;
    height: 529.92px;
    padding: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    .chatbox121pat{
        width: 81px;
    height: 64px;
    margin-top: -18.75%;
    margin-left: 25%;
    position: absolute;
    background: rgba(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    }
    
    
    .chatbox21pat{
        margin-left: -30px;
        width: 435px;
        height: 284px;
        border-radius: 50px 0px 50px 50px;
        background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext21pat{
        width: 365px;
    height: 150px;
        padding: 30px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg2pat{
        width: 87px;
    height: 87px;
    margin-left: 450px;
    margin-top: -540px;
    }
    
    .chatbox131{
        width: 81px;
    height: 64px;
    background: rgba(207, 240, 230, 1);
    position: absolute;
    margin-top: -13.28%;
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    margin-left: 18%;
    }
    
    .chatbox31{
        padding: 10px;
        margin-top: 10%;
        width: 358px;
       margin-left: -10%;
    height: 201px;
    border-radius: 50px 0px 50px 50px;
    background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext31{
        padding: 20px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg3{
        
        margin-top: -330px;
        margin-left: 370px;
    }
    
    .chatimg4pat{
        width: 87px;
    height: 87px;
    margin-top: -480px;
    margin-left: -40px;
    }
    
    .chatbox141pat{
        width: 81px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    position: absolute;
    margin-top: -14.5%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: 3%;
    }
    
    .chatbox41pat{
        padding: 10px;
        width: 475px;
    height: 220px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: -300px;
    margin-left: 100px;
    }
    .chatboxtext41pat{
        width: 388px;
    height: 90px;
        padding: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    
    }
    
    
    .chatimg5pat{
        width: 87px;
    height: 87px;
        margin-top: -450px;
        margin-left: -40px;
    }
    
    .chatbox51pat{
        padding: 10px;
        width: 455px;
    height: 200px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: -290px;
    margin-left: 95px;
    }
    
    .chatbox151pat{
        width: 81px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    position: absolute;
    margin-top: -13.2%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: 3%;
    }
    
    .chatboxtext51pat{
        padding: 20px;
        width: 399px;
    height: 194px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .mainbox1{
        width: 1263px;
    height: 701px;
    border-radius: 81px;
    opacity:50%;
    background: linear-gradient(180deg, #F7F7F7 0%, rgba(157, 160, 193, 0.5) 100%);
    position: relative;
    margin-top: 10%;
    z-index: 1;
    }
    
    .maintitle1{
        margin-top: -48%;
    margin-left: 5%;
    }
    
    .maintitletag1{
        width: 225px;
    height: 64px;
    border-radius: 32px;
    background: #E8EDFC;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    z-index: 2;
    }
    
    .mainheadtitle1{
        width: 542px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    z-index: 2;
    }
    
    .para1{
        width: 517px;
    height: 140px;
    font-family: 'Plus Jakarta Sans', sans-serif;;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    z-index: 2;
    /* margin-bottom: 10%; */
    }
    
    .para2{
        width: 517px;
        height: 140px;
        font-family: 'Plus Jakarta Sans', sans-serif;;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        position: relative;
        z-index: 2;
    }
    
    .register3{
        width: 173px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    background: rgba(7, 60, 165, 1);
    border: none;
    position: relative;
    z-index: 2;
    }
    
    .mainimg1 {
        position: absolute;
        margin-top: -38%;
        margin-left: 40%;
        z-index: 2;
    }
    
    .msfedex1{
        width: 168px;
        height: 168px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: -110px;
        margin-left: 80px;
        z-index: 1;
    }
    .mfedex1{
        width: 133px;
    height: 56px;
    margin-top: -80px;
    margin-left: 100px;
    position: relative;
    z-index: 2;
    }
    
    .mskfc1{
        width: 112px;
    height: 112px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: 2px;
    margin-left: 396px;
    z-index: 1;
    }
    
    .mkfc1{
        width: 106px;
    height: 111px;
    margin-left: 400px;
    position: relative;
    z-index: 2;
    }
    
    .msadidas1{
        width: 232px;
    height: 232px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: -20px;
    margin-left: 157px;
    z-index: 1;
    }
    
    .madidas1{
        width: 220px;
    height: 223px;
    margin-left: 160px;
    margin-top: -30px;
    position: relative;
    z-index: 2;
    }
    
    .msburger1{
        width: 168px;
    height: 168px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: 0px;
    margin-left: 445px;
    z-index: 1;
    }
    
    .mburger1{
        width: 159px;
    height: 172px;
    margin-left: 450px;
    position: relative;
    z-index: 2;
    }
    
    .msfedex2{
        width: 112px;
    height: 112px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: -150px;
    margin-left: 162px;
    z-index: 1;
    }
    
    .mfedex2{
        width: 79px;
    height: 34px;
    margin-top: -230px;
    margin-left: 180px;
    position: relative;
    z-index: 2;
    }
    
    .mskfc2{
        width: 112px;
    height: 112px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: -19px;
    margin-left: 296px;
    z-index: 1;
    }
    
    .mkfc2{
        width: 106px;
    height: 111px;
    margin-top: -20px;
    margin-left: 300px;
    position: relative;
    z-index: 2;
    }
    
    .parttitletag{
        width: 105px;
    height: 34px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    background: #E8EDFC;
    margin-left: 35%;
    margin-top: 8%;
    
    }
    
    .partheadtitle{
        width: 194px;
    height: 31px;
    font-family: 'Plus Jakarta Sans', sans-serif;;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-left: 22%;
    }
    
    .partbox{
        width: 356px;
    height: 556px;
    border-radius: 81px;
    opacity: 0.5px;
    background: linear-gradient(270deg, #F7F7F7 0%, rgba(187, 192, 238, 0.5) 100%);
    position: relative;
    margin-left: 5%;
    }
    
    .par1{
        width: 358px;
    height: 57px;
    font-family: 'Plus Jakarta Sans', sans-serif;;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-left: 5%;
    margin-top: 3%;
    }
    
    .par2{
        width: 358px;
    height: 57px;
    font-family: 'Plus Jakarta Sans', sans-serif;;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-left: 5%;
    }
    
    .reg{
        width: 137px;
    height: 42px;
    border-radius: 37.5px;
    background: #073CA5;
    margin-left: 33%;
    }
    
    .startedpat{
        padding: 0 50px 0 50px;
        margin-top: 10%;
        margin-bottom: 7em;
    }


    
    .startedboxprt{
        margin-top: 50px;
        width: 1207px;
    height: 227px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 100px;
    }
    
    .subbuttonpat{
        margin-left: 900px;
        margin-top: -230px;
        height: 50px;
        width: 175px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }
    
    .startheadprt{
        width: 1000px;
    height: 87px;
    margin-left: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    
    }
    
    .regispt{
        width: 90px;
    height: 19px;
        margin-top: 12px;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    
    }
    
    .startparapattab{
        width: 613px;
    height: 62px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 40px;
    margin-top: -2%;
    }
    
    .bluec3{
        width: 54px;
    height: 54px;
    background: rgba(7, 60, 165, 1);
    margin-left: 380px;
    margin-top: 35%;
    border-radius: 100%;
    }
    
    .whiteatp{
        width: 24px;
    height: 30px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    }
    
    .namesm{
        width: 250px;
    height: 30px;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .desgsm{
        width: 134px;
    height: 30px;
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans',  sans-serif;
    margin-top: -30px;
    margin-left: 28%;
    }

    .chat{
        display: none;
    }

    .partnerbox1{
        margin-bottom: 5em;
    }

}

@media (min-width:1228px) and (max-width:1399px)
{

    .prtwidth1{
        width: 500px;
    }

    .prtwidth2{
        width: 650px;
    }

    .prtwidth3{
        width: 650px;
    }

     /* box click element start */

     .clickihv1prt{
        width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
    }

    .clickihv2prt{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickihv3prt{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clickhv1prt{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clickhv2prt{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickhv3prt{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clichv1prt{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clichv2prt{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clichv3prt{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clihv1prt{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clihv2prt{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clihv3prt{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    /* box click elemet end */

    .patmarimg {
        /* width: 110px; */
        height: 110px; /* Adjust height as needed */
        background-size: 120%;
        background-color: white;
        border-radius: 100%;
      }


    .imgscrolluppat {
        /* animation: scroll-up 20s linear infinite; */
        display: flex;
        flex-direction: column;
        margin-left: 70%;
      }
      
      @keyframes scroll-up {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-100%);
        }
      }
    .partnerpagemain{
        max-width:1300px;
        transform: scale(0.83);
        transform-origin: top;
        margin-bottom: -450px;
    }

    /* top image animation  */

    .danceprt-divv {
        opacity: 0;
        transform: translateY(-400px);
        transition: 1s ;
    }
      
      .danceprt-divv.visible {
        opacity: 1;
        transform: translateY(-100%);
        animation:  0.5s ease-in-out forwards ;
      }
      
      @keyframes shake {
        0% { transform: translateX(0); }
        25% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
        75% { transform: translateX(-10px); }
        100% { transform: translateX(0); }
      }
      
    .danceprt-div {
        transition: transform 0.5s ease; 
      }
      
      .danceprt-div.dancing {
        transform: translateY(-25px); 
        animation: 60s ease-out alternate; 
      }
      
      @keyframes bounce {
        from {
          transform: translateY(-25px);
        }
        to {
          transform: translateY(0);
        }
      }

    .partimgmov{
        position: relative;
        left: 30px;
        top: -15px;
    }

    /* box click event */

    .clickinsprt{
        width: 34px;
        height: 34px;
        position: relative;
        left: 320px;
        top: -45px;
        cursor: pointer;
    }

    .clickinsprt2{
        width: 34px;
height: 34px;
position: relative;
left: 750px;
top: -45px;
cursor: pointer;
    }

    .clickinsprt3{
        width: 34px;
        height: 34px;
        position: relative;
        left: 595px;
        top: -55px;
        cursor: pointer;
    }

    .clickinsprt4{
        width: 34px;
        height: 34px;
        position: relative;
        left: 1050px;
        top: -8px;
        cursor: pointer;
    }

    /* Start of brand */

    .brandhovercontainerpat{
        position: relative;
    }
    
    .accesshovercontainerpat{
        position: relative;
    }
    
    .autohovercontainerpat{
        position: relative;
    }
    
    .hirehovercontainerpat{
        position: relative;
    }
    
    
    .imagecontainerbrandpat{
        position: relative;
        /* transition: opacity 0.3s ease;
        cursor: pointer;
        z-index: 1; */
    }
    
    .imagecontainacespat{
        position: relative;
        top: -35px;
        /* transition: opacity 0.3s ease;
        cursor: pointer;
        z-index: 1; */
    }
    
    .imagecontainerautopat{
        position: relative;
        top: -30px;
        /* transition: opacity 0.3s ease;
        cursor: pointer;
        z-index: 1; */
    }
    
    .imageconthirepat{
        position: relative;
        top: -60px;
        /* transition: opacity 0.3s ease;
        cursor: pointer;
        z-index: 1; */
    }

    .brandhovercontentpat{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        position: absolute;
        top: -1.5%;
      left: 7%;
      z-index: 2;
    
    }
    
    .accesshovercontentpat{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        position: absolute;
        margin-top: -40%;
      left: 7%;
      z-index: 2;
    }
    
    .autohovercontentpat{
        background-color: #ffffff;
        width: 1300px;
        position: absolute;
        margin-top: -44%;
      left: 7%;
      z-index: 2;
    }
    
    .hirehovercontentpat{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        position: absolute;
        margin-top: -44%;
      left: 7%;
      z-index: 2;
    }
    
    
    
    /* .imagecontainerbrandpat:hover + .brandhovercontentpat {
        opacity: 1; 
      } */
    
      /* .imagecontaineraccesspat:hover + .accesshovercontentpat {
        opacity: 1; 
      } */
    
      /* .imagecontainerautopat:hover + .autohovercontentpat {
        opacity: 1; 
      } */
    
      /* .imagecontainerhirepat:hover + .hirehovercontentpat {
        opacity: 1;
      } */
    
    
    
    .brandcontentpat{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
    }
    
    .brandheaderpat{
        width: 660px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 5%;
    position: absolute;
    margin-top: 2.5%;
    }
    
    .brandparapat{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .brandhoverimages{
        margin-left: 74.5%;
        margin-top: -35%;
    }
    
    .hoverimg1brandpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-bottom: 2%;
    position: absolute;
    }
    
    .brandhoverimg1bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -7%;
    }
    
    .hoverimg2brandpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-bottom: 2%;
    position: absolute;
    }
    
    .brandprtimg2bg{
        width: 141px;
        height: 145px;
        border-radius: 20px;
        margin-bottom: -7%;
        background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
        opacity: 45%;
    }
    
    .hoverimg3brandpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-bottom: 2%;
    position: absolute;
    }
    
    .brandprtimg3bg{
        width: 141px;
        height: 145px;
        border-radius: 20px;
        margin-bottom: 2%;
        background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
        opacity: 45%;
    }
    
    .accesscontentpat{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
    }
    
    .accessheaderpat{
        width: 900px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 2.5%;
    margin-left: 5%;
    }
    
    .accessparapat{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .accesshoverimagespat{
        margin-left: 74.5%;
        margin-top: -35%;
    }
    
    .hoverimg1accesspat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        position: absolute;
    }
    
    .aceshovimg1bgpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    margin-bottom: -7%;
    }

    .aceshoveimg1bgpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    margin-bottom: -7%;
    }

    .aceshoverimg1bgpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    margin-bottom: -7%;
    }
    
    .hoverimg2accesspat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        position: absolute;
    }
    
    .aceshovimg2bgpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    margin-bottom: -7%;
    }

    .aceshoveimg2bgpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -7%;
    }
    
    .aceshoverimg2bgpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -7%;
    }

    .hoverimg3accesspat{
        position: absolute;
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
    }
    
    .aceshovimg3bgpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    opacity: 45%;
    margin-bottom: 2%;
    }
    
    .aceshoveimg3bgpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    opacity: 45%;
    margin-bottom: 2%;
    }

    .aceshoverimg3bgpat{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    margin-bottom: 2%;
    }
    
    .hireheaderpat{
        width: 700px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 2.5%;
    margin-left: 5%;
    }
    
    .hireparapat{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .hirecontentpat{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
    
    }
    
    
    .autocontentpat{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%);
    
    }
    

    /* end of brand */
    .bimg1xt{
        position: absolute;
        top: 1400px;
    }

    .partsbox{
        display: none;
    }
    
    body{
        overflow-x: hidden;
    }
    
    .register1t{
        padding-top: 10%;
    }
    

    
    .logosprt{
        white-space: nowrap;
    }

    .logo-slidetp{
        display: inline-block;
        animation: 120s slide infinite linear;
    }
    
    .logo-slidetp img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logossprt{
        white-space: nowrap;
        margin-bottom: 150px;
    }

    .logos-slidept{
        display: inline-block;
        animation: 80s slides infinite linear;
    }
    
    .logos-slidept img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }

    .corevalues{
        display: none;
    }
    
    
    
    .contentt3{
        display: flex;
        justify-content: center;
    font-size: 40px;
    font-weight: 300;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
        margin-left: 20px;
        margin-top: 110px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        width: 700px;
    }
    
    .contentt3 br {
        content: '';
        display: block;
        /* margin-bottom: 10px;  */
    }
    
    .watchs3{
        padding-top: 6%;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    text-transform:capitalize;
    }
    
    .fresher1tag{
        /* align-items: center; */
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    /* text-indent: 10px; */
    margin-left: 7%;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 234px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    
    }
    .luck3{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  font-weight: 900;
    
    }
    .ftext{
        /* margin-top: 10px; */
        top: 15px;
        position: absolute;
        left: 70px;
    }
    
    .ima-container1 {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
        margin-left: 10%;
    }
    
    .background-container3 {
        position: absolute;
        bottom: 0;
        left: 60%;
        transform: translateX(-50%);
        width: 320px;
        height: 451px;
        border-top-left-radius: 100%;
        background-color: #CC0530;
    }
    
    .orgvector3 {
        /* z-index: 1;  */
        position: relative;
        margin-left: 15%;
    }
    
    .orgvector3 img {
        display: block;
    }
    
    .violetbox3{
        background: #821B61;
        width: 203px;
    height: 203px;
    display: flex;
    position: relative;
    margin-left: 22.5%;
    margin-top: -29%;
    display: inline-block;
    text-align: start;
    }
    
    .vt1pat{
        width: 91px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    }

    .ima-container4{
        position: relative;
        margin-left: 23%;
        margin-top: -4%;
        /* z-index: 1; */
    }
    
    .vt2pat{
        width: 83px;
    height: 19px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    margin-top: 65px;
    margin-left: -85px;
    }
    
    .yellowbox3pat{
        background:#F7B400;
        width: 118px;
    height: 118px;
    display: flex;
    margin-left: 14.5%;
    /* margin-right: 190px; */
    display: inline-block;
    position: relative;
    margin-top: -9.2%;
    text-align: start;
    }
    
    .yt1pat{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    }
    
    .yt2pat{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: -35px;
    margin-left: 10px;
    }
    
    .ima-container2, .ima-container3, .ima-container5 {
        position: relative;
        /* z-index: 2;  */
    }
    
    .sechead3{
        margin-top: 5%;
    }
    
    .firstsignup3{
        position: relative;
        margin-top: -18%;
        left: -12px;
    }

    .siginspat{
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 2%;
    color: rgba(255, 255, 255, 1);
    
    
    }
    
    .signinpatcol
    {
        width: 173px;
    height: 60px;
    position: relative;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 10%;
    margin-left: 6%;
    background: rgba(7, 60, 165, 1);
    border: none;
    
    }
    
    .watchpat{
        width: 200px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 10%;
    margin-left: -380px;
    }
    
    .linetextprt{
        width: 1000px;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center ;
    margin-left: auto;
    margin-right: auto;
    top: 20px;
    position: relative;
    }

    .linetxtprt{
        width: 1100px;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center ;
    margin-left: auto;
    margin-right: auto;
    top: -30px;
    position: relative;
    margin-bottom: -1em;
    }
    
    .linebox3{
    
        width: 160px;
    height: 160px;
    display: flex;
    display: inline-block;
    position: relative;
    margin-top: -79%;
    text-align: start;
    border: 2px solid rgba(0, 0, 0, 1);
    margin-left: 24%;
    
    }
    
    .hlinebox1pat{
        width: 102px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 16%;
    position: absolute;
    opacity: 30%;
    
    }
    .hlinebox2pat{
        width: 40px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 30%;
    opacity: 30%;
    position: absolute;
    
    }
    
    
    .rlinebox1pat{
        width: 159px;
    height: 102px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 10%;
    position: absolute;
    }
    
    .rlinebox2pat{
        width: 159px;
    height: 40px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 50%;
    }
    
    .lineboxtext{
        width: 64px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    color: rgba(0, 0, 0, 1);
    
    }
    .lineboxtext2{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .title1tagpat{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    margin-top: 0%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 170px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }

    .title1tagpatn{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    margin-top: 0%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 390px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }

    .title2tagpat2g{
        width: 1050px;
        height: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .imagecontainerbrand{
        position: relative;
    }
    
    .mid3contentpat .img1{
        width: 312px;
    height: 462px;
    margin-left: 90px;
    margin-top: -3px;
    border-radius: 50px;
    }
    .mid3contentpat .bimg1{
        width: 312px;
    height: 462px;
    position: absolute;
    margin-top: -0.2%;
    margin-left: -25.2%;
    border-radius: 50px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    }
    
    .mid3contentpat .bimg1text{
        width: 146px;
    height: 84px;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    opacity: 100%;
    position: relative;
    /* display: block; */
    margin-top: -100px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-left: 120px;
    
    }
    
    
    .imagecontaineraccess{
        position: relative;
    }
    
    .mid3contentpat .img2{
        width: 513px;
    height: 231px;
    margin-top: -36.8%;
    margin-left: 34%;
    margin-bottom: 40%;
    border-radius: 50px;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    .mid3contentpat .bimg2{
        width: 513px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-top: -36.8%;
    margin-left: -41.5%;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    
    }
    
    .bimg2text{
        width: 300px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -45%;
    margin-left: 480px;
    opacity: 100%;
    position: relative;
    display: block;
    }
    
    .imagecontainerauto{
        position: relative;
    }
    
    .title2tagpat{
        width: 699px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    }

    .title2tagpat2{
        width: 1050px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: auto;
margin-right: auto;
    }
    
    .mid3contentpat .img3{
        width: 768px;
    height: 212px;
    border-radius: 50px;
    margin-top: -19.4%;
    margin-left: 34%;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    
    .mid3contentpat .bimg3{
        width: 768px;
    height: 212px;
    margin-top: -17.1%;
    margin-left: -62.3%;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    }
    
    .mid3contentpat .bimg3text{
        width: 430px;
    height: 84px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    margin-left: 36%;
    display: block;
    margin-top: -9%;
    color:rgba(255, 255, 255, 1);
    
    }
    
    .imagecontainerhire{
        position: relative;
    }
    
    
    .mid3contentpat .img4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    margin-top: -61%;
    margin-left: 77.5%;
    /* opacity: 45%px; */
    
    
    }
    
    .mid3contentpat .bimg4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-left: -18.8%;
    margin-top: -38.7%;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    }
    
    .bimg4textpat{
        width: 72px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    position: relative;
    display: block;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -33%;
    margin-left: 79%;
    
    }
    
    
    
    
    .phonebox{
        margin: 14%;
    }
    
    .phonetitle1tag{
        margin-top: 100px;
        /* align-items: center; */
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    /* position: relative; */
    /* text-indent: 10px; */
    /* margin-left: 17%; */
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 234px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }
    
    .phoneheadtitle{
        /* margin-left: 120px; */
        width: 381px;
        height: 174px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;    
    }
    
    .phonebody{
        width: 405px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .phone{
        width: 485px;
    height: 391px;
    margin-top: -290px;
    margin-left: 30px;
    position: relative;
    }
    
    .orgcircle{
        width: 240.93px;
    height: 240.93px;
    margin-top: -200px;
    border-radius: 100%;
    margin-left: 100px;
    /* background: rgba(247, 91, 2, 1); */
    position: relative;
    /* opacity: 90%; */
    }
    
    .viocircle{
        width: 240.93px;
    height: 240.93px;
    border-radius: 100%;
    /* background: rgba(130, 27, 97, 1); */
    margin-top: -120px;
    position: relative;
    opacity: 90%;
    }
    
    .viocircle::before {
      content: '';
      position: absolute;
      margin-top: 27%;
      margin-left: -20%;
      width: 240.93px;
    height: 240.93px;
      border-radius: 50%;
      opacity: 100%;
    }
    
    .orgcircle::before{
      content: '';
      position: absolute;
      margin-top: 35%;
      margin-left: -7%;
      width: 240.93px;
      height: 240.93px;
      border-radius: 50%;
      opacity: 90%;
    }
    
    .orgcircle::before {
        background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
      }
      
      .viocircle::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }
    
    .bluecircle{
        width: 364.7px;
    height: 364.7px;
    border-radius: 100%;
    background: rgba(135, 189, 255, 1);
    margin-top: -382px;
    margin-left: 170px;
    }
    
    .yellowcircle{
        width: 171.62px;
        height: 171.62px;
        border-radius: 100%;
        background: #F7B400; 
        margin-top: -170px;
        margin-left: 120px;
    }
    .arrow{
        width: 256.46px;
    height: 189.91px;
    margin-left: -250px;
    margin-top: -150px;
    }
    .image-phone-container{
        margin-left: 500px;
        margin-top: -400px;
    }
    
    .phonetitles{
        margin-left: -130px;
    }
    
    .phonebody{
        margin-left: -130px;
    }
    
    .trainingtitles{
        margin-top: -590px;
    }
    
    .traintitle1tag{
        width: 225px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    }
    
    .trainheadtitle{
        width: 542px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    }
    .traincircle{
        width: 673px;
    height: 673px;
    background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -250px;
    
    }
    
    .head12{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .head13{
        margin-top: -80px;
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .register{
        width: 173px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: -100px;
    background: rgba(7, 60, 165, 1);
    border: none;
    
    
    }
    
    .assesscircle{
        width: 673px;
    height: 673px;
    background: rgba(9, 9, 63, 0.05);
    border-radius: 100%;
    margin-left: 900px;
    
    }
    
    .assesstitle1tag{
        margin-top: -570px;
        margin-left: 990px;
        width: 225px;
        height: 64px;
        border-radius: 37.5px;
        background: rgba(232, 237, 252, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;    
    }
    
    .assesheadtitle{
        width: 693px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: right;
    margin-left: 530px;
    }
    
    .assesshead1{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 150px;
    text-align: right;
    }
    
    .assesshead2{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 150px;
    text-align: right;
    margin-top: -50px;
    }
    
    .register2{
        width: 173px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 1050px;
    margin-top: -100px;
    border: none;
    background: rgba(7, 60, 165, 1);
    }
    
    .chat{
        margin-top: 200px;
    }
    
    .chatitle1tagtp{
        width: 200px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    
    }
    
    .ctext{
        margin-left: 30px;
        margin-top: 15px;
    }
    
    .chatheadtitle{
        width: 551px;
    height: 174px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .chatimg1{
        width: 87px;
    height: 87px;
    }
    .chatbox111pat{
        clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
        width: 84.16px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    margin-left: 6%;
    margin-top: -40.6%;
    position: absolute;
    }
    
    .chatbox11pat{
        width: 378px;
    height: 615px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-left: 150px;
    margin-top: -80px;
    }
    
    .chatboxtext1pat{
        width: 370px;
    height: 529.92px;
    padding: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    .chatbox121pat{
        width: 81px;
    height: 64px;
    margin-top: -18.75%;
    margin-left: 25%;
    position: absolute;
    background: rgba(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    }
    
    
    .chatbox21pat{
        margin-left: -30px;
        width: 435px;
        height: 284px;
        border-radius: 50px 0px 50px 50px;
        background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext21pat{
        width: 365px;
    height: 150px;
        padding: 30px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg2pat{
        width: 87px;
    height: 87px;
    margin-left: 450px;
    margin-top: -540px;
    }
    
    .chatbox131{
        width: 81px;
    height: 64px;
    background: rgba(207, 240, 230, 1);
    position: absolute;
    margin-top: -13.28%;
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    margin-left: 18%;
    }
    
    .chatbox31{
        padding: 10px;
        margin-top: 10%;
        width: 358px;
       margin-left: -10%;
    height: 201px;
    border-radius: 50px 0px 50px 50px;
    background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext31{
        padding: 20px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg3{
        
        margin-top: -330px;
        margin-left: 370px;
    }
    
    .chatimg4pat{
        width: 87px;
    height: 87px;
    margin-top: -480px;
    margin-left: -40px;
    }
    
    .chatbox141pat{
        width: 81px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    position: absolute;
    margin-top: -14.5%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: 3%;
    }
    
    .chatbox41pat{
        padding: 10px;
        width: 475px;
    height: 220px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: -300px;
    margin-left: 100px;
    }
    .chatboxtext41pat{
        width: 388px;
    height: 90px;
        padding: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    
    }
    
    
    .chatimg5pat{
        width: 87px;
    height: 87px;
        margin-top: -450px;
        margin-left: -40px;
    }
    
    .chatbox51pat{
        padding: 10px;
        width: 455px;
    height: 200px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: -290px;
    margin-left: 95px;
    }
    
    .chatbox151pat{
        width: 81px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    position: absolute;
    margin-top: -13.2%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: 3%;
    }
    
    .chatboxtext51pat{
        padding: 20px;
        width: 399px;
    height: 194px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .mainbox1{
        width: 1263px;
    height: 701px;
    border-radius: 81px;
    opacity:50%;
    background: linear-gradient(180deg, #F7F7F7 0%, rgba(157, 160, 193, 0.5) 100%);
    position: relative;
    margin-top: 10%;
    z-index: 1;
    }
    
    .maintitle1{
        margin-top: -48%;
    margin-left: 5%;
    }
    
    .maintitletag1{
        width: 225px;
    height: 64px;
    border-radius: 32px;
    background: #E8EDFC;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    z-index: 2;
    }
    
    .mainheadtitle1{
        width: 542px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    z-index: 2;
    }
    
    .para1{
        width: 517px;
    height: 140px;
    font-family: 'Plus Jakarta Sans', sans-serif;;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    z-index: 2;
    /* margin-bottom: 10%; */
    }
    
    .para2{
        width: 517px;
        height: 140px;
        font-family: 'Plus Jakarta Sans', sans-serif;;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        position: relative;
        z-index: 2;
    }
    
    .register3{
        width: 173px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    background: rgba(7, 60, 165, 1);
    border: none;
    position: relative;
    z-index: 2;
    }
    
    .mainimg1 {
        position: absolute;
        margin-top: -46%;
        margin-left: 45%;
        z-index: 2;
    }
    
    .msfedex1{
        width: 168px;
        height: 168px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: -110px;
        margin-left: 80px;
        z-index: 1;
    }
    .mfedex1{
        width: 133px;
    height: 56px;
    margin-top: -80px;
    margin-left: 100px;
    position: relative;
    z-index: 2;
    }
    
    .mskfc1{
        width: 112px;
    height: 112px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: 2px;
    margin-left: 396px;
    z-index: 1;
    }
    
    .mkfc1{
        width: 106px;
    height: 111px;
    margin-left: 400px;
    position: relative;
    z-index: 2;
    }
    
    .msadidas1{
        width: 232px;
    height: 232px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: -20px;
    margin-left: 157px;
    z-index: 1;
    }
    
    .madidas1{
        width: 220px;
    height: 223px;
    margin-left: 160px;
    margin-top: -30px;
    position: relative;
    z-index: 2;
    }
    
    .msburger1{
        width: 168px;
    height: 168px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: 0px;
    margin-left: 445px;
    z-index: 1;
    }
    
    .mburger1{
        width: 159px;
    height: 172px;
    margin-left: 450px;
    position: relative;
    z-index: 2;
    }
    
    .msfedex2{
        width: 112px;
    height: 112px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: -150px;
    margin-left: 162px;
    z-index: 1;
    }
    
    .mfedex2{
        width: 79px;
    height: 34px;
    margin-top: -230px;
    margin-left: 180px;
    position: relative;
    z-index: 2;
    }
    
    .mskfc2{
        width: 112px;
    height: 112px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: -19px;
    margin-left: 296px;
    z-index: 1;
    }
    
    .mkfc2{
        width: 106px;
    height: 111px;
    margin-top: -20px;
    margin-left: 300px;
    position: relative;
    z-index: 2;
    }
    
    .parttitletag{
        width: 105px;
    height: 34px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    background: #E8EDFC;
    margin-left: 35%;
    margin-top: 8%;
    
    }
    
    .partheadtitle{
        width: 194px;
    height: 31px;
    font-family: 'Plus Jakarta Sans', sans-serif;;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-left: 22%;
    }
    
    .partbox{
        width: 356px;
    height: 556px;
    border-radius: 81px;
    opacity: 0.5px;
    background: linear-gradient(270deg, #F7F7F7 0%, rgba(187, 192, 238, 0.5) 100%);
    position: relative;
    margin-left: 5%;
    }
    
    .par1{
        width: 358px;
    height: 57px;
    font-family: 'Plus Jakarta Sans', sans-serif;;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-left: 5%;
    margin-top: 3%;
    }
    
    .par2{
        width: 358px;
    height: 57px;
    font-family: 'Plus Jakarta Sans', sans-serif;;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-left: 5%;
    }
    
    .reg{
        width: 137px;
    height: 42px;
    border-radius: 37.5px;
    background: #073CA5;
    margin-left: 33%;
    }
    
    .startedpat{
        padding: 0 50px 0 50px;
        margin-top: 10%;
        margin-bottom: 34%;
    }


    
    .startedboxprt{
        margin-top: 50px;
        width: 1207px;
    height: 227px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 100px;
    
    }
    
    .subbuttonpat{
        margin-left: 900px;
        margin-top: -230px;
        height: 50px;
        width: 175px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }
    
    .startheadprt{
        width: 1000px;
    height: 87px;
    margin-left: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    
    }
    
    .regispt{
        width: 90px;
    height: 19px;
        margin-top: 12px;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    
    }
    
    .startparapattab{
        width: 613px;
    height: 62px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 40px;
    margin-top: -2%;
    }
    
    .bluec3{
        width: 54px;
    height: 54px;
    background: rgba(7, 60, 165, 1);
    margin-left: 380px;
    margin-top: 38%;
    border-radius: 100%;
    }
    
    .whiteatp{
        width: 24px;
    height: 30px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    }
    
    .namesm{
        width: 250px;
    height: 30px;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .desgsm{
        width: 134px;
    height: 30px;
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans',  sans-serif;
    margin-top: -30px;
    margin-left: 28%;
    }

    .partnerbox1{
        margin-bottom: 4em;
    }

    .chat{
        display: none;
    }
}

@media (min-width:1027px) and (max-width:1227px)
{

    .prtwidth1{
        width: 500px;
    }

    .prtwidth2{
        width: 650px;
    }

    .prtwidth3{
        width: 650px;
    }

    /* box click element start */

    .clickihv1prt{
       width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
   }

   .clickihv2prt{
       width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
   }

   .clickihv3prt{
       width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
   }

   .clickhv1prt{
       width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
   }

   .clickhv2prt{
       width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
   }

   .clickhv3prt{
       width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
   }

   .clichv1prt{
       width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
   }

   .clichv2prt{
       width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
   }

   .clichv3prt{
       width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
   }

   .clihv1prt{
       width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
   }

   .clihv2prt{
       width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
   }

   .clihv3prt{
       width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
   }

   /* box click elemet end */

   .patmarimg {
       /* width: 110px; */
       height: 110px; /* Adjust height as needed */
       background-size: 120%;
       background-color: white;
       border-radius: 100%;
     }


   .imgscrolluppat {
       /* animation: scroll-up 20s linear infinite; */
       display: flex;
       flex-direction: column;
       margin-left: 70%;
     }
     
     @keyframes scroll-up {
       0% {
         transform: translateY(0);
       }
       100% {
         transform: translateY(-100%);
       }
     }
   .partnerpagemain{
       max-width:1300px;
       transform: scale(0.83);
       transform-origin: top;
       margin-bottom: -600px;
   }

   /* top image animation  */

   .danceprt-divv {
       opacity: 0;
       transform: translateY(-400px);
       transition: 1s ;
   }
     
     .danceprt-divv.visible {
       opacity: 1;
       transform: translateY(-100%);
       animation:  0.5s ease-in-out forwards ;
     }
     
     @keyframes shake {
       0% { transform: translateX(0); }
       25% { transform: translateX(-10px); }
       50% { transform: translateX(10px); }
       75% { transform: translateX(-10px); }
       100% { transform: translateX(0); }
     }
     
   .danceprt-div {
       transition: transform 0.5s ease; 
     }
     
     .danceprt-div.dancing {
       transform: translateY(-25px); 
       animation: 60s ease-out alternate; 
     }
     
     @keyframes bounce {
       from {
         transform: translateY(-25px);
       }
       to {
         transform: translateY(0);
       }
     }

   .partimgmov{
       position: relative;
       left: 30px;
       top: -15px;
   }

   /* box click event */

   .clickinsprt{
       width: 34px;
       height: 34px;
       position: relative;
       left: 200px;
       top: -82px;
       cursor: pointer;
   }

   .clickinsprt2{
       width: 34px;
height: 34px;
position: relative;
left: 680px;
top: -45px;
cursor: pointer;
   }

   .clickinsprt3{
       width: 34px;
       height: 34px;
       position: relative;
       left: 555px;
       top: -55px;
       cursor: pointer;
   }

   .clickinsprt4{
       width: 34px;
       height: 34px;
       position: relative;
       left: 1000px;
       top: -8px;
       cursor: pointer;
   }

   /* Start of brand */

   .brandhovercontainerpat{
       position: relative;
   }
   
   .accesshovercontainerpat{
       position: relative;
   }
   
   .autohovercontainerpat{
       position: relative;
   }
   
   .hirehovercontainerpat{
       position: relative;
   }
   
   
   .imagecontainerbrandpat{
       position: relative;
       /* transition: opacity 0.3s ease;
       cursor: pointer;
       z-index: 1; */
   }
   
   .imagecontainacespat{
       position: relative;
       top: -35px;
       /* transition: opacity 0.3s ease;
       cursor: pointer;
       z-index: 1; */
   }
   
   .imagecontainerautopat{
       position: relative;
       top: -30px;
       /* transition: opacity 0.3s ease;
       cursor: pointer;
       z-index: 1; */
   }
   
   .imageconthirepat{
       position: relative;
       top: -60px;
       /* transition: opacity 0.3s ease;
       cursor: pointer;
       z-index: 1; */
   }

   .brandhovercontentpat{
       background-color: #ffffff;
       width: 1300px;
       height: 470px;
       position: absolute;
       top: -1.5%;
     left: 3%;
     z-index: 2;
   
   }
   
   .accesshovercontentpat{
       background-color: #ffffff;
       width: 1300px;
       height: 470px;
       position: absolute;
       margin-top: -43%;
     left: 3%;
     z-index: 2;
   }
   
   .autohovercontentpat{
       background-color: #ffffff;
       width: 1300px;
       position: absolute;
       margin-top: -47%;
     left: 3%;
     z-index: 2;
   }
   
   .hirehovercontentpat{
       background-color: #ffffff;
       width: 1300px;
       height: 470px;
       position: absolute;
       margin-top: -47%;
     left: 3%;
     z-index: 2;
   }
   
   
   
   /* .imagecontainerbrandpat:hover + .brandhovercontentpat {
       opacity: 1; 
     } */
   
     /* .imagecontaineraccesspat:hover + .accesshovercontentpat {
       opacity: 1; 
     } */
   
     /* .imagecontainerautopat:hover + .autohovercontentpat {
       opacity: 1; 
     } */
   
     /* .imagecontainerhirepat:hover + .hirehovercontentpat {
       opacity: 1;
     } */
   
   
   
   .brandcontentpat{
       width: 948px;
   height: 462px;
   border-radius: 50px;
   background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
   }
   
   .brandheaderpat{
       width: 660px;
   height: 54px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 45px;
   font-weight: 900;
   line-height: 54.46px;
   text-align: left;
   color: rgba(255, 255, 255, 1);
   margin-left: 5%;
   position: absolute;
   margin-top: 2.5%;
   }
   
   .brandparapat{
       width: 854px;
   height: 217px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   text-align: left;
   color: rgba(255, 255, 255, 1);
   position: absolute;
   margin-top: 6%;
   margin-left: 2%;
   padding: 3%;
   }
   
   .brandhoverimages{
       margin-left: 74.5%;
       margin-top: -35%;
   }
   
   .hoverimg1brandpat{
       width: 141px;
   height: 145px;
   border-radius: 20px;
   margin-bottom: 2%;
   position: absolute;
   }
   
   .brandhoverimg1bg{
       width: 141px;
   height: 145px;
   border-radius: 20px;
   background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
   opacity: 45%;
   margin-bottom: -7%;
   }
   
   .hoverimg2brandpat{
       width: 141px;
   height: 145px;
   border-radius: 20px;
   margin-bottom: 2%;
   position: absolute;
   }
   
   .brandprtimg2bg{
       width: 141px;
       height: 145px;
       border-radius: 20px;
       margin-bottom: -7%;
       background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
       opacity: 45%;
   }
   
   .hoverimg3brandpat{
       width: 141px;
   height: 145px;
   border-radius: 20px;
   margin-bottom: 2%;
   position: absolute;
   }
   
   .brandprtimg3bg{
       width: 141px;
       height: 145px;
       border-radius: 20px;
       margin-bottom: 2%;
       background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
       opacity: 45%;
   }
   
   .accesscontentpat{
       width: 948px;
   height: 462px;
   border-radius: 50px;
   background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
   }
   
   .accessheaderpat{
       width: 900px;
   height: 54px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 45px;
   font-weight: 900;
   line-height: 54.46px;
   text-align: left;
   color: rgba(255, 255, 255, 1);
   position: absolute;
   margin-top: 2.5%;
   margin-left: 5%;
   }
   
   .accessparapat{
       width: 854px;
   height: 217px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   text-align: left;
   color: rgba(255, 255, 255, 1);
   position: absolute;
   margin-top: 6%;
   margin-left: 2%;
   padding: 3%;
   }
   
   .accesshoverimagespat{
       margin-left: 74.5%;
       margin-top: -35%;
   }
   
   .hoverimg1accesspat{
       width: 141px;
   height: 145px;
   border-radius: 20px;
       margin-top: 0%;
       position: absolute;
   }
   
   .aceshovimg1bgpat{
       width: 141px;
   height: 145px;
   border-radius: 20px;
   background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
   opacity: 45%;
   margin-bottom: -7%;
   }

   .aceshoveimg1bgpat{
       width: 141px;
   height: 145px;
   border-radius: 20px;
   background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
   opacity: 45%;
   margin-bottom: -7%;
   }

   .aceshoverimg1bgpat{
       width: 141px;
   height: 145px;
   border-radius: 20px;
   background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
   opacity: 45%;
   margin-bottom: -7%;
   }
   
   .hoverimg2accesspat{
       width: 141px;
   height: 145px;
   border-radius: 20px;
       margin-top: 0%;
       position: absolute;
   }
   
   .aceshovimg2bgpat{
       width: 141px;
   height: 145px;
   border-radius: 20px;
   background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
   opacity: 45%;
   margin-bottom: -7%;
   }

   .aceshoveimg2bgpat{
       width: 141px;
   height: 145px;
   border-radius: 20px;
   background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
   opacity: 45%;
   margin-bottom: -7%;
   }
   
   .aceshoverimg2bgpat{
       width: 141px;
   height: 145px;
   border-radius: 20px;
   background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
   opacity: 45%;
   margin-bottom: -7%;
   }

   .hoverimg3accesspat{
       position: absolute;
       width: 141px;
   height: 145px;
   border-radius: 20px;
       margin-top: 0%;
       margin-bottom: 2%;
   }
   
   .aceshovimg3bgpat{
       width: 141px;
   height: 145px;
   border-radius: 20px;
   background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
   opacity: 45%;
   margin-bottom: 2%;
   }
   
   .aceshoveimg3bgpat{
       width: 141px;
   height: 145px;
   border-radius: 20px;
   background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
   opacity: 45%;
   margin-bottom: 2%;
   }

   .aceshoverimg3bgpat{
       width: 141px;
   height: 145px;
   border-radius: 20px;
   background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
   opacity: 45%;
   margin-bottom: 2%;
   }
   
   .hireheaderpat{
       width: 700px;
   height: 54px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 45px;
   font-weight: 900;
   line-height: 54.46px;
   text-align: left;
   color: rgba(255, 255, 255, 1);
   position: absolute;
   margin-top: 2.5%;
   margin-left: 5%;
   }
   
   .hireparapat{
       width: 854px;
   height: 217px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   text-align: left;
   color: rgba(255, 255, 255, 1);
   position: absolute;
   margin-top: 6%;
   margin-left: 2%;
   padding: 3%;
   }
   
   .hirecontentpat{
       width: 948px;
   height: 462px;
   border-radius: 50px;
   background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
   
   }
   
   
   .autocontentpat{
       width: 948px;
   height: 462px;
   border-radius: 50px;
   background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%);
   
   }
   

   /* end of brand */
   .bimg1xt{
       position: absolute;
       top: 1400px;
   }

   .partsbox{
       display: none;
   }
   
   body{
       overflow-x: hidden;
   }
   
   .register1t{
       padding-top: 10%;
   }
   

   
   .logosprt{
       white-space: nowrap;
   }

   .logo-slidetp{
       display: inline-block;
       animation: 120s slide infinite linear;
   }
   
   .logo-slidetp img{
       margin: 0 40px;
       height: 100px;
   }
   
   @keyframes slide{
       from{ 
           transform: translateX(0);
       }
       to{
           transform: translateX(-100%);
       }
   }
   
   .logossprt{
       white-space: nowrap;
       margin-bottom: 150px;
   }

   .logos-slidept{
       display: inline-block;
       animation: 80s slides infinite linear;
   }
   
   .logos-slidept img{
       margin: 0 40px;
       height: 100px;
   }
   
   @keyframes slides{
       to{ 
           transform: translateX(0);
       }
       from{
           transform: translateX(-100%);
       }
   }

   .corevalues{
       display: none;
   }
   
   
   
   .contentt3{
       display: flex;
       justify-content: center;
   font-size: 40px;
   font-weight: 300;
   line-height: 48px;
   letter-spacing: 0em;
   text-align: left;
       margin-left: 20px;
       margin-top: 110px;
       font-family: 'Plus Jakarta Sans', sans-serif;
       width: 700px;
   }
   
   .contentt3 br {
       content: '';
       display: block;
       /* margin-bottom: 10px;  */
   }
   
   .watchs3{
       padding-top: 6%;
       font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 20px;
   font-weight: 400;
   line-height: 25px;
   letter-spacing: 0em;
   text-align: center;
   text-transform:capitalize;
   }
   
   .fresher1tag{
       /* align-items: center; */
       font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   position: relative;
   /* text-indent: 10px; */
   margin-left: 7%;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: center;
   width: 234px;
   height: 64px;
   border-radius: 32px;
   background-color: rgba(232, 237, 252, 1);
   
   }
   .luck3{
       font-family: 'Plus Jakarta Sans', sans-serif;
       background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                 color: transparent; 
                 -webkit-background-clip: text; 
                 background-clip: text;
                 font-weight: 900;
   
   }
   .ftext{
       /* margin-top: 10px; */
       top: 15px;
       position: absolute;
       left: 70px;
   }
   
   .ima-container1 {
       position: relative;
       display: flex;
       justify-content: center;
       align-items: center;
       margin-top: 10%;
       margin-left: 10%;
   }
   
   .background-container3 {
       position: absolute;
       bottom: 0;
       left: 60%;
       transform: translateX(-50%);
       width: 320px;
       height: 451px;
       border-top-left-radius: 100%;
       background-color: #CC0530;
   }
   
   .orgvector3 {
       /* z-index: 1;  */
       position: relative;
       margin-left: 15%;
   }
   
   .orgvector3 img {
       display: block;
   }
   
   .violetbox3{
       background: #821B61;
       width: 203px;
   height: 203px;
   display: flex;
   position: relative;
   margin-left: 22.5%;
   margin-top: -29%;
   display: inline-block;
   text-align: start;
   }
   
   .vt1pat{
       width: 91px;
   height: 45px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 36px;
   font-weight: 400;
   line-height: 45px;
   letter-spacing: 0em;
   text-align: left;
   display: flex;
   justify-content: center;
   color: rgba(255, 255, 255, 1);
   }

   .ima-container4{
       position: relative;
       margin-left: 23%;
       margin-top: -4%;
       /* z-index: 1; */
   }
   
   .vt2pat{
       width: 83px;
   height: 19px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 19px;
   letter-spacing: 0em;
   text-align: left;
   display: flex;
   justify-content: center;
   color: rgba(255, 255, 255, 1);
   margin-top: 65px;
   margin-left: -85px;
   }
   
   .yellowbox3pat{
       background:#F7B400;
       width: 118px;
   height: 118px;
   display: flex;
   margin-left: 14.5%;
   /* margin-right: 190px; */
   display: inline-block;
   position: relative;
   margin-top: -9.2%;
   text-align: start;
   }
   
   .yt1pat{
       width: 66px;
   height: 44px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 20px;
   font-weight: 400;
   line-height: 25px;
   letter-spacing: 0em;
   text-align: left;
   margin-top: 20px;
   margin-left: 10px;
   }
   
   .yt2pat{
       width: 66px;
   height: 44px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 19px;
   letter-spacing: 0em;
   text-align: left;
   margin-top: -35px;
   margin-left: 10px;
   }
   
   .ima-container2, .ima-container3, .ima-container5 {
       position: relative;
       /* z-index: 2;  */
   }
   
   .sechead3{
       margin-top: 5%;
   }
   
   .firstsignup3{
       position: relative;
       margin-top: -18%;
       left: -12px;
   }

   .siginspat{
       font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 20px;
   font-weight: 400;
   line-height: 25px;
   letter-spacing: 0em;
   text-align: center;
   padding-top: 2%;
   color: rgba(255, 255, 255, 1);
   
   
   }
   
   .signinpatcol
   {
       width: 173px;
   height: 60px;
   position: relative;
   border-radius: 37.5px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 20px;
   font-weight: 400;
   line-height: 25px;
   letter-spacing: 0em;
   margin-top: 10%;
   margin-left: 6%;
   background: rgba(7, 60, 165, 1);
   border: none;
   
   }
   
   .watchpat{
       width: 200px;
   height: 60px;
   border-radius: 37.5px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 20px;
   font-weight: 400;
   line-height: 25px;
   letter-spacing: 0em;
   margin-top: 10%;
   margin-left: -380px;
   }
   
   .linetextprt{
       width: 1000px;
   height: 93px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: center;
   display: flex;
   justify-content: center;
   align-content: center ;
   margin-left: auto;
   margin-right: auto;
   top: 20px;
   position: relative;
   }

   .linetxtprt{
       width: 1100px;
   height: 93px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: center;
   display: flex;
   justify-content: center;
   align-content: center ;
   margin-left: auto;
   margin-right: auto;
   top: -30px;
   position: relative;
   margin-bottom: -1em;
   }
   
   .linebox3{
   
       width: 160px;
   height: 160px;
   display: flex;
   display: inline-block;
   position: relative;
   margin-top: -79%;
   text-align: start;
   border: 2px solid rgba(0, 0, 0, 1);
   margin-left: 24%;
   
   }
   
   .hlinebox1pat{
       width: 102px;
   height: 159px;
   padding: 0px, 0px, 0px, 0px;
   border: 1px;
   border: 1px solid rgba(0, 0, 0, 1);
   margin-left: 16%;
   position: absolute;
   opacity: 30%;
   
   }
   .hlinebox2pat{
       width: 40px;
   height: 159px;
   padding: 0px, 0px, 0px, 0px;
   border: 1px;
   border: 1px solid rgba(0, 0, 0, 1);
   margin-left: 30%;
   opacity: 30%;
   position: absolute;
   
   }
   
   
   .rlinebox1pat{
       width: 159px;
   height: 102px;
   padding: 0px, 0px, 0px, 0px;
   border: 1px solid rgba(0, 0, 0, 1);
   margin-top: 30px;
   opacity: 10%;
   position: absolute;
   }
   
   .rlinebox2pat{
       width: 159px;
   height: 40px;
   padding: 0px, 0px, 0px, 0px;
   border: 1px solid rgba(0, 0, 0, 1);
   margin-top: 30px;
   opacity: 50%;
   }
   
   .lineboxtext{
       width: 64px;
   height: 45px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 36px;
   font-weight: 400;
   line-height: 45px;
   letter-spacing: 0em;
   text-align: left;
   margin-top: 20px;
   margin-left: 10px;
   color: rgba(0, 0, 0, 1);
   
   }
   .lineboxtext2{
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 12px;
   letter-spacing: 0em;
   text-align: left;
   }
   
   .title1tagpat{
       align-items: center;
       font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   position: relative;
   margin-top: 0%;
   margin-left: auto;
   margin-right: auto;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: center;
   width: 170px;
   height: 64px;
   border-radius: 32px;
   background-color: rgba(232, 237, 252, 1);
   }

   .title1tagpatn{
       align-items: center;
       font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   position: relative;
   margin-top: 0%;
   margin-left: auto;
   margin-right: auto;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: center;
   width: 390px;
   height: 64px;
   border-radius: 32px;
   background-color: rgba(232, 237, 252, 1);
   }

   .title2tagpat2g{
       width: 1050px;
       height: 40px;
       font-family: 'Plus Jakarta Sans', sans-serif;
       font-size: 25px;
       font-weight: 400;
       line-height: 31px;
       text-align: center;
       margin-left: auto;
       margin-right: auto;
   }

   .imagecontainerbrand{
       position: relative;
   }
   
   .mid3contentpat .img1{
       width: 312px;
   height: 462px;
   margin-left: 40px;
   margin-top: -3px;
   border-radius: 50px;
   }
   .mid3contentpat .bimg1{
       width: 312px;
   height: 462px;
   position: absolute;
   margin-top: -0.2%;
   margin-left: -27.9%;
   border-radius: 50px;
   background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
   opacity: 45%;
   }
   
   .mid3contentpat .bimg1text{
       width: 146px;
   height: 84px;
   font-size: 30px;
   font-weight: 900;
   line-height: 42px;
   letter-spacing: 0em;
   text-align: left;
   color:rgba(255, 255, 255, 1);
   opacity: 100%;
   position: relative;
   /* display: block; */
   margin-top: -110px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   margin-left: 60px;
   
   }
   
   
   .imagecontaineraccess{
       position: relative;
   }
   
   .mid3contentpat .img2{
       width: 513px;
   height: 231px;
   margin-top: -39.6%;
   margin-left: 33%;
   margin-bottom: 40%;
   border-radius: 50px;
   /* opacity: 0.45px; */
   /* position: absolute; */
   
   }
   .mid3contentpat .bimg2{
       width: 513px;
   height: 231px;
   border-radius: 50px;
   opacity: 45%;
   position: absolute;
   margin-top: -39.6%;
   margin-left: -45.8%;
   background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
   
   }
   
   .bimg2text{
       width: 300px;
   height: 42px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 30px;
   font-weight: 900;
   line-height: 42px;
   letter-spacing: 0em;
   text-align: left;
   color:rgba(255, 255, 255, 1);
   margin-top: -45%;
   margin-left: 400px;
   opacity: 100%;
   position: relative;
   display: block;
   }
   
   .imagecontainerauto{
       position: relative;
   }
   
   .title2tagpat{
       width: 699px;
   height: 87px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 70px;
   font-weight: 400;
   line-height: 87px;
   letter-spacing: 0em;
   text-align: center;
   margin-left: auto;
   margin-right: auto;
   }

   .title2tagpat2{
       width: 1050px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: auto;
margin-right: auto;
   }
   
   .mid3contentpat .img3{
       width: 768px;
   height: 212px;
   border-radius: 50px;
   margin-top: -19.4%;
   margin-left: 33%;
   /* opacity: 0.45px; */
   /* position: absolute; */
   
   }
   
   .mid3contentpat .bimg3{
       width: 768px;
   height: 212px;
   margin-top: -20.1%;
   margin-left: 32.9%;
   border-radius: 50px;
   opacity: 45%;
   position: absolute;
   background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
   }
   
   .mid3contentpat .bimg3text{
       width: 430px;
   height: 84px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 30px;
   font-weight: 900;
   line-height: 42px;
   letter-spacing: 0em;
   text-align: left;
   position: relative;
   margin-left: 35%;
   display: block;
   margin-top: -10%;
   color:rgba(255, 255, 255, 1);
   
   }
   
   .imagecontainerhire{
       position: relative;
   }
   
   
   .mid3contentpat .img4{
       width: 231px;
   height: 231px;
   border-radius: 50px;
   margin-top: -65%;
   margin-left: 80.5%;
   /* opacity: 45%px; */
   
   
   }
   
   .mid3contentpat .bimg4{
       width: 231px;
   height: 231px;
   border-radius: 50px;
   opacity: 45%;
   position: absolute;
   margin-left: 80.6%;
   margin-top: -43.7%;
   background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
   }
   
   .bimg4textpat{
       width: 72px;
   height: 42px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 30px;
   font-weight: 900;
   line-height: 42px;
   letter-spacing: 0em;
   position: relative;
   display: block;
   text-align: left;
   color:rgba(255, 255, 255, 1);
   margin-top: -35%;
   margin-left: 82%;
   
   }
   
   
   
   
   .phonebox{
       margin: 14%;
   }
   
   .phonetitle1tag{
       margin-top: 100px;
       /* align-items: center; */
       font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   /* position: relative; */
   /* text-indent: 10px; */
   /* margin-left: 17%; */
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: center;
   width: 234px;
   height: 64px;
   border-radius: 32px;
   background-color: rgba(232, 237, 252, 1);
   }
   
   .phoneheadtitle{
       /* margin-left: 120px; */
       width: 381px;
       height: 174px;
       font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 70px;
   font-weight: 400;
   line-height: 87px;
   letter-spacing: 0em;
   text-align: left;    
   }
   
   .phonebody{
       width: 405px;
   height: 155px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: left;
   }
   
   .phone{
       width: 485px;
   height: 391px;
   margin-top: -290px;
   margin-left: 30px;
   position: relative;
   }
   
   .orgcircle{
       width: 240.93px;
   height: 240.93px;
   margin-top: -200px;
   border-radius: 100%;
   margin-left: 100px;
   /* background: rgba(247, 91, 2, 1); */
   position: relative;
   /* opacity: 90%; */
   }
   
   .viocircle{
       width: 240.93px;
   height: 240.93px;
   border-radius: 100%;
   /* background: rgba(130, 27, 97, 1); */
   margin-top: -120px;
   position: relative;
   opacity: 90%;
   }
   
   .viocircle::before {
     content: '';
     position: absolute;
     margin-top: 27%;
     margin-left: -20%;
     width: 240.93px;
   height: 240.93px;
     border-radius: 50%;
     opacity: 100%;
   }
   
   .orgcircle::before{
     content: '';
     position: absolute;
     margin-top: 35%;
     margin-left: -7%;
     width: 240.93px;
     height: 240.93px;
     border-radius: 50%;
     opacity: 90%;
   }
   
   .orgcircle::before {
       background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
     }
     
     .viocircle::before {
       background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
     }
   
   .bluecircle{
       width: 364.7px;
   height: 364.7px;
   border-radius: 100%;
   background: rgba(135, 189, 255, 1);
   margin-top: -382px;
   margin-left: 170px;
   }
   
   .yellowcircle{
       width: 171.62px;
       height: 171.62px;
       border-radius: 100%;
       background: #F7B400; 
       margin-top: -170px;
       margin-left: 120px;
   }
   .arrow{
       width: 256.46px;
   height: 189.91px;
   margin-left: -250px;
   margin-top: -150px;
   }
   .image-phone-container{
       margin-left: 500px;
       margin-top: -400px;
   }
   
   .phonetitles{
       margin-left: -130px;
   }
   
   .phonebody{
       margin-left: -130px;
   }
   
   .trainingtitles{
       margin-top: -590px;
   }
   
   .traintitle1tag{
       width: 225px;
   height: 64px;
   border-radius: 32px;
   background: rgba(232, 237, 252, 1);
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: center;
   }
   
   .trainheadtitle{
       width: 542px;
   height: 87px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 70px;
   font-weight: 400;
   line-height: 87px;
   letter-spacing: 0em;
   text-align: left;
   }
   .traincircle{
       width: 673px;
   height: 673px;
   background: rgba(247, 91, 2, 0.1);
   border-radius: 100%;
   margin-left: -250px;
   
   }
   
   .head12{
       width: 1075px;
   height: 155px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: left;
   }
   
   .head13{
       margin-top: -80px;
       width: 1075px;
   height: 155px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: left;
   }
   
   .register{
       width: 173px;
   height: 60px;
   border-radius: 37.5px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 20px;
   font-weight: 400;
   line-height: 25px;
   letter-spacing: 0em;
   text-align: center;
   margin-top: -100px;
   background: rgba(7, 60, 165, 1);
   border: none;
   
   
   }
   
   .assesscircle{
       width: 673px;
   height: 673px;
   background: rgba(9, 9, 63, 0.05);
   border-radius: 100%;
   margin-left: 900px;
   
   }
   
   .assesstitle1tag{
       margin-top: -570px;
       margin-left: 990px;
       width: 225px;
       height: 64px;
       border-radius: 37.5px;
       background: rgba(232, 237, 252, 1);
       font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: center;    
   }
   
   .assesheadtitle{
       width: 693px;
   height: 87px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 70px;
   font-weight: 400;
   line-height: 87px;
   letter-spacing: 0em;
   text-align: right;
   margin-left: 530px;
   }
   
   .assesshead1{
       width: 1075px;
   height: 155px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   margin-left: 150px;
   text-align: right;
   }
   
   .assesshead2{
       width: 1075px;
   height: 155px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   margin-left: 150px;
   text-align: right;
   margin-top: -50px;
   }
   
   .register2{
       width: 173px;
   height: 60px;
   border-radius: 37.5px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 20px;
   font-weight: 400;
   line-height: 25px;
   letter-spacing: 0em;
   text-align: center;
   margin-left: 1050px;
   margin-top: -100px;
   border: none;
   background: rgba(7, 60, 165, 1);
   }
   
   .chat{
       margin-top: 200px;
   }
   
   .chatitle1tagtp{
       width: 200px;
   height: 64px;
   border-radius: 32px;
   background: rgba(232, 237, 252, 1);
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: center;
   
   }
   
   .ctext{
       margin-left: 30px;
       margin-top: 15px;
   }
   
   .chatheadtitle{
       width: 551px;
   height: 174px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 70px;
   font-weight: 400;
   line-height: 87px;
   letter-spacing: 0em;
   text-align: left;
   }
   
   .chatimg1{
       width: 87px;
   height: 87px;
   }
   .chatbox111pat{
       clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
       width: 84.16px;
   height: 64px;
   background: rgba(231, 242, 255, 1);
   margin-left: 6%;
   margin-top: -40.6%;
   position: absolute;
   }
   
   .chatbox11pat{
       width: 378px;
   height: 615px;
   border-radius: 0px 50px 50px 50px;
   background: rgba(231, 242, 255, 1);
   margin-left: 150px;
   margin-top: -80px;
   }
   
   .chatboxtext1pat{
       width: 370px;
   height: 529.92px;
   padding: 40px;
   font-size: 20px;
   font-weight: 400;
   line-height: 30px;
   letter-spacing: 0em;
   text-align: left;
   font-family: 'Plus Jakarta Sans', sans-serif;
   }
   .chatbox121pat{
       width: 81px;
   height: 64px;
   margin-top: -18.75%;
   margin-left: 25%;
   position: absolute;
   background: rgba(207, 240, 230, 1);
   clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
   }
   
   
   .chatbox21pat{
       margin-left: -30px;
       width: 435px;
       height: 284px;
       border-radius: 50px 0px 50px 50px;
       background: rgba(207, 240, 230, 1);
   }
   
   .chatboxtext21pat{
       width: 365px;
   height: 150px;
       padding: 30px;
       font-size: 20px;
   font-weight: 400;
   line-height: 30px;
   letter-spacing: 0em;
   text-align: left;
   font-family: 'Plus Jakarta Sans', sans-serif;
   }
   
   .chatimg2pat{
       width: 87px;
   height: 87px;
   margin-left: 450px;
   margin-top: -540px;
   }
   
   .chatbox131{
       width: 81px;
   height: 64px;
   background: rgba(207, 240, 230, 1);
   position: absolute;
   margin-top: -13.28%;
   clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
   margin-left: 18%;
   }
   
   .chatbox31{
       padding: 10px;
       margin-top: 10%;
       width: 358px;
      margin-left: -10%;
   height: 201px;
   border-radius: 50px 0px 50px 50px;
   background: rgba(207, 240, 230, 1);
   }
   
   .chatboxtext31{
       padding: 20px;
       font-size: 20px;
   font-weight: 400;
   line-height: 30px;
   letter-spacing: 0em;
   text-align: left;
   font-family: 'Plus Jakarta Sans', sans-serif;
   }
   
   .chatimg3{
       
       margin-top: -330px;
       margin-left: 370px;
   }
   
   .chatimg4pat{
       width: 87px;
   height: 87px;
   margin-top: -480px;
   margin-left: -40px;
   }
   
   .chatbox141pat{
       width: 81px;
   height: 64px;
   background: rgba(231, 242, 255, 1);
   position: absolute;
   margin-top: -14.5%;
   clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
   margin-left: 3%;
   }
   
   .chatbox41pat{
       padding: 10px;
       width: 475px;
   height: 220px;
   border-radius: 0px 50px 50px 50px;
   background: rgba(231, 242, 255, 1);
   margin-top: -300px;
   margin-left: 100px;
   }
   .chatboxtext41pat{
       width: 388px;
   height: 90px;
       padding: 20px;
   font-size: 20px;
   font-weight: 400;
   line-height: 30px;
   letter-spacing: 0em;
   text-align: left;
   font-family: 'Plus Jakarta Sans', sans-serif;
   
   }
   
   
   .chatimg5pat{
       width: 87px;
   height: 87px;
       margin-top: -450px;
       margin-left: -40px;
   }
   
   .chatbox51pat{
       padding: 10px;
       width: 455px;
   height: 200px;
   border-radius: 0px 50px 50px 50px;
   background: rgba(231, 242, 255, 1);
   margin-top: -290px;
   margin-left: 95px;
   }
   
   .chatbox151pat{
       width: 81px;
   height: 64px;
   background: rgba(231, 242, 255, 1);
   position: absolute;
   margin-top: -13.2%;
   clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
   margin-left: 3%;
   }
   
   .chatboxtext51pat{
       padding: 20px;
       width: 399px;
   height: 194px;
   font-size: 20px;
   font-weight: 400;
   line-height: 30px;
   letter-spacing: 0em;
   text-align: left;
   font-family: 'Plus Jakarta Sans', sans-serif;
   }
   
   .mainbox1{
       width: 1263px;
   height: 701px;
   border-radius: 81px;
   opacity:50%;
   background: linear-gradient(180deg, #F7F7F7 0%, rgba(157, 160, 193, 0.5) 100%);
   position: relative;
   margin-top: 10%;
   z-index: 1;
   }
   
   .maintitle1{
       margin-top: -48%;
   margin-left: 5%;
   }
   
   .maintitletag1{
       width: 225px;
   height: 64px;
   border-radius: 32px;
   background: #E8EDFC;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: center;
   position: relative;
   z-index: 2;
   }
   
   .mainheadtitle1{
       width: 542px;
   height: 87px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 70px;
   font-weight: 400;
   line-height: 87px;
   letter-spacing: 0em;
   text-align: left;
   position: relative;
   z-index: 2;
   }
   
   .para1{
       width: 517px;
   height: 140px;
   font-family: 'Plus Jakarta Sans', sans-serif;;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: left;
   position: relative;
   z-index: 2;
   /* margin-bottom: 10%; */
   }
   
   .para2{
       width: 517px;
       height: 140px;
       font-family: 'Plus Jakarta Sans', sans-serif;;
       font-size: 25px;
       font-weight: 400;
       line-height: 31px;
       letter-spacing: 0em;
       text-align: left;
       position: relative;
       z-index: 2;
   }
   
   .register3{
       width: 173px;
   height: 60px;
   border-radius: 37.5px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 20px;
   font-weight: 400;
   line-height: 25px;
   letter-spacing: 0em;
   text-align: center;
   background: rgba(7, 60, 165, 1);
   border: none;
   position: relative;
   z-index: 2;
   }
   
   .mainimg1 {
       position: absolute;
       margin-top: -46%;
       margin-left: 45%;
       z-index: 2;
   }
   
   .msfedex1{
       width: 168px;
       height: 168px;
       background: #FFFFFF;
       position: absolute;
       border-radius: 100%;
       margin-top: -110px;
       margin-left: 80px;
       z-index: 1;
   }
   .mfedex1{
       width: 133px;
   height: 56px;
   margin-top: -80px;
   margin-left: 100px;
   position: relative;
   z-index: 2;
   }
   
   .mskfc1{
       width: 112px;
   height: 112px;
   background: #FFFFFF;
   position: absolute;
   border-radius: 100%;
   margin-top: 2px;
   margin-left: 396px;
   z-index: 1;
   }
   
   .mkfc1{
       width: 106px;
   height: 111px;
   margin-left: 400px;
   position: relative;
   z-index: 2;
   }
   
   .msadidas1{
       width: 232px;
   height: 232px;
   background: #FFFFFF;
   position: absolute;
   border-radius: 100%;
   margin-top: -20px;
   margin-left: 157px;
   z-index: 1;
   }
   
   .madidas1{
       width: 220px;
   height: 223px;
   margin-left: 160px;
   margin-top: -30px;
   position: relative;
   z-index: 2;
   }
   
   .msburger1{
       width: 168px;
   height: 168px;
   background: #FFFFFF;
   position: absolute;
   border-radius: 100%;
   margin-top: 0px;
   margin-left: 445px;
   z-index: 1;
   }
   
   .mburger1{
       width: 159px;
   height: 172px;
   margin-left: 450px;
   position: relative;
   z-index: 2;
   }
   
   .msfedex2{
       width: 112px;
   height: 112px;
   background: #FFFFFF;
   position: absolute;
   border-radius: 100%;
   margin-top: -150px;
   margin-left: 162px;
   z-index: 1;
   }
   
   .mfedex2{
       width: 79px;
   height: 34px;
   margin-top: -230px;
   margin-left: 180px;
   position: relative;
   z-index: 2;
   }
   
   .mskfc2{
       width: 112px;
   height: 112px;
   background: #FFFFFF;
   position: absolute;
   border-radius: 100%;
   margin-top: -19px;
   margin-left: 296px;
   z-index: 1;
   }
   
   .mkfc2{
       width: 106px;
   height: 111px;
   margin-top: -20px;
   margin-left: 300px;
   position: relative;
   z-index: 2;
   }
   
   .parttitletag{
       width: 105px;
   height: 34px;
   border-radius: 37.5px;
   font-family: 'Plus Jakarta Sans', sans-serif;;
   font-size: 12px;
   font-weight: 400;
   line-height: 15px;
   letter-spacing: 0em;
   text-align: center;
   position: relative;
   background: #E8EDFC;
   margin-left: 35%;
   margin-top: 8%;
   
   }
   
   .partheadtitle{
       width: 194px;
   height: 31px;
   font-family: 'Plus Jakarta Sans', sans-serif;;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: center;
   position: relative;
   margin-left: 22%;
   }
   
   .partbox{
       width: 356px;
   height: 556px;
   border-radius: 81px;
   opacity: 0.5px;
   background: linear-gradient(270deg, #F7F7F7 0%, rgba(187, 192, 238, 0.5) 100%);
   position: relative;
   margin-left: 5%;
   }
   
   .par1{
       width: 358px;
   height: 57px;
   font-family: 'Plus Jakarta Sans', sans-serif;;
   font-size: 12px;
   font-weight: 400;
   line-height: 15px;
   letter-spacing: 0em;
   text-align: center;
   position: relative;
   margin-left: 5%;
   margin-top: 3%;
   }
   
   .par2{
       width: 358px;
   height: 57px;
   font-family: 'Plus Jakarta Sans', sans-serif;;
   font-size: 12px;
   font-weight: 400;
   line-height: 15px;
   letter-spacing: 0em;
   text-align: center;
   position: relative;
   margin-left: 5%;
   }
   
   .reg{
       width: 137px;
   height: 42px;
   border-radius: 37.5px;
   background: #073CA5;
   margin-left: 33%;
   }
   
   .startedpat{
       padding: 0 50px 0 50px;
       margin-top: 10%;
       margin-bottom: 34%;
   }


   
   .startedboxprt{
       margin-top: 50px;
       width: 1207px;
   height: 227px;
   background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
   border-radius: 25px;
   margin-bottom: 100px;
   
   }
   
   .subbuttonpat{
       margin-left: 900px;
       margin-top: -230px;
       height: 50px;
       width: 175px;
       border-radius:30px;
       font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 20px;
   font-weight: 400;
   line-height: 19px;
   letter-spacing: 0em;
   background: rgb(255, 255, 255, 1);
   color: rgba(7, 60, 165, 1);
   border: solid rgba(255, 255, 255, 1);
   }
   
   .startheadprt{
       width: 1000px;
   height: 87px;
   margin-left: 40px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 50px;
   font-weight: 400;
   line-height: 87px;
   letter-spacing: 0em;
   text-align: left;
   color: rgba(255, 255, 255, 1);
   justify-content: center;
   align-items: center;
   margin-top: 3%;
   
   }
   
   .regispt{
       width: 90px;
   height: 19px;
       margin-top: 12px;
       margin-left: auto;
       margin-right: auto;
       font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 19px;
   letter-spacing: 0em;
   text-align: left;
   
   }
   
   .startparapattab{
       width: 613px;
   height: 62px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: left;
   color: rgba(255, 255, 255, 1);
   margin-left: 40px;
   margin-top: -2%;
   }
   
   .bluec3{
       width: 54px;
   height: 54px;
   background: rgba(7, 60, 165, 1);
   margin-left: 380px;
   margin-top: 38%;
   border-radius: 100%;
   }
   
   .whiteatp{
       width: 24px;
   height: 30px;
   position: relative;
   margin-left: auto;
   margin-right: auto;
   margin-top: auto;
   margin-bottom: auto;
   }
   
   .namesm{
       width: 250px;
   height: 30px;
   font-size: 22px;
   font-weight: 600;
   line-height: 30px;
   letter-spacing: 0em;
   text-align: left;
   font-family: 'Plus Jakarta Sans', sans-serif;
   }
   
   .desgsm{
       width: 134px;
   height: 30px;
   font-size: 22px;
   font-style: italic;
   font-weight: 400;
   line-height: 30px;
   letter-spacing: 0em;
   text-align: left;
   font-family: 'Plus Jakarta Sans',  sans-serif;
   margin-top: -30px;
   margin-left: 28%;
   }

   .partnerbox1{
       margin-bottom: 4em;
   }

   .chat{
       display: none;
   }
}


@media (min-width:1024.5px) and (max-width:1028px){
    .corevalues{
        display: none !important;
    }

    .title2tagpat{
        width: 699px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    }
 
    .title2tagpat2{
        width: 1050px;
 height: 40px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 25px;
 font-weight: 400;
 line-height: 31px;
 text-align: center;
 margin-left: auto;
 margin-right: auto;
    }
}


@media (min-width:831px) and (max-width:991.5px){
    .brandpppat{
        margin-top: 10%;
        margin-left: auto;
        margin-right: auto;
        width: 530px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
     }

     .hirepppat{
        margin-top: 10%;
               left: 30px; 
               width: 500px;
               height: 175px;
               position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        opacity: 80%;
        color: rgba(255, 255, 255, 1);
            }

            .digitalhpat{
                top: 15%;
        position: relative;
                margin-left: 0%;
                width: 588px;
        height: 42px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
                font-weight: 900;
                line-height: 42px;
                letter-spacing: 0em;
                text-align: center;
            }

            .digitalpppat{
                margin-top: 10%;
                       margin-left: auto;
                       margin-right: auto;
                       width: 550px;
                       height: 175px;
                font-family: 'Plus Jakarta Sans', sans-serif;
                font-size: 20px;
                font-weight: 400;
                line-height: 25px;
                letter-spacing: 0em;
                text-align: left;
                opacity: 80%;
                color: rgba(255, 255, 255, 1);
                    }

                    .trainpppat{
                        margin-top: 12%;
                               margin-left: auto;
                               margin-right: auto;
                               width: 530px;
                               height: 175px;
                        font-family: 'Plus Jakarta Sans', sans-serif;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 25px;
                        letter-spacing: 0em;
                        text-align: left;
                        opacity: 80%;
                        color: rgba(255, 255, 255, 1);
                            }
}


@media (min-width:992px) and (max-width:1024px){
    .brandpppat{
        margin-top: 8%;
        margin-left: auto;
        margin-right: auto;
        width: 706px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
     }

     .hirepppat{
        margin-top: 8%;
               margin-left: auto;
               margin-right: auto; 
               width: 706px;
               height: 175px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        opacity: 80%;
        color: rgba(255, 255, 255, 1);
            }

            .digitalhpat{
                top: 15%;
        position: relative;
                margin-left: 3.5%;
                width: 588px;
        height: 42px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
                font-weight: 900;
                line-height: 42px;
                letter-spacing: 0em;
                text-align: center;
            }

            .digitalpppat{
                margin-top: 8%;
                       margin-left: auto;
                       margin-right: auto;
                       width: 706px;
                       height: 175px;
                font-family: 'Plus Jakarta Sans', sans-serif;
                font-size: 20px;
                font-weight: 400;
                line-height: 25px;
                letter-spacing: 0em;
                text-align: left;
                opacity: 80%;
                color: rgba(255, 255, 255, 1);
                    }

                    .trainpppat{
                        margin-top: 10%;
                               margin-left: auto;
                               margin-right: auto;
                               width: 706px;
                               height: 175px;
                        font-family: 'Plus Jakarta Sans', sans-serif;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 25px;
                        letter-spacing: 0em;
                        text-align: left;
                        opacity: 80%;
                        color: rgba(255, 255, 255, 1);
                            }
}


@media (min-width:831px) and (max-width:1026px){
    .breakhideinlap{
        display: none;
    }
     
      /* box click element start */

      .clickihv1prt{
        width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
    }

    .clickihv2prt{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickihv3prt{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clickhv1prt{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clickhv2prt{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickhv3prt{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clichv1prt{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clichv2prt{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clichv3prt{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clihv1prt{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clihv2prt{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clihv3prt{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    /* box click elemet end */

     /* top image animation  */

     .danceprt-divv {
        opacity: 0;
        transform: translateY(-400px);
        transition: 1s ;
    }
      
      .danceprt-divv.visible {
        opacity: 1;
        transform: translateY(0%);
        animation:  0.5s ease-in-out forwards ;
      }
      
      @keyframes shake {
        0% { transform: translateX(0); }
        25% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
        75% { transform: translateX(-10px); }
        100% { transform: translateX(0); }
      }
      
    .danceprt-div {
        transition: transform 0.5s ease; 
      }
      
      .danceprt-div.dancing {
        transform: translateY(-25px); 
        animation: 60s ease-out alternate; 
      }
      
      @keyframes bounce {
        from {
          transform: translateY(-25px);
        }
        to {
          transform: translateY(0);
        }
      }
    .patmarimg {
        /* width: 110px; */
         /* Adjust height as needed */
        background-size: 120%;
        background-color: white;
        border-radius: 100%;
      }
    .chatheadtitle{
        width: 749px;
height: 174px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: center;
margin-left: auto;
margin-right: auto;

    }

    .startedboxprt{
        width: 737px;
height: 252px;
border-radius: 25px;
background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
margin-left: 13%;
margin-top: 10%;
    }

    .startedpat{
        margin-top: 7%;
        margin-bottom: 10%;
    }

    .yt2{

    }

    .regispt{
        width: 145.25px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
color: rgba(7, 60, 165, 1);
margin-left: 26%;
margin-top: 3%;


    }

    .chatbox141pat{
        width: 128px;
height: 97px;
    background: rgba(231, 242, 255, 1);
    position: absolute;
    margin-top: -32.65%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: 20%;
    }

    .subbuttonpat{
        margin-left: 300px;
        margin-top: -110px;
        width: 331px;
height: 61px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
position: absolute;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }

   

    .parttitletag{
        width: 234px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 25%;


    }

    .partnerbox1{
        display: none;
    }

    .bluec3{
        width: 59px;
    height: 59px;
    background: rgba(7, 60, 165, 1);
    margin-left: 130px;
    position: relative;
    margin-top: -29%;
    border-radius: 100%;
    }

    .whiteatp{
        width: 26.53px;
height: 33.17px;
position: relative;
top: 3px;
    }

    .title1tagpat{
        position: relative;
        width: 154px;
height: 64px;
border-radius: 32px;
        margin-left: auto;
        margin-right: auto;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
color: rgba(0, 0, 0, 1);
background: rgba(232, 237, 252, 1);
    }

    .title1tagpatn{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    margin-top: -15%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 390px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }

    .title2tagpat2g{
        height: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: -10px;
        margin-bottom: 0em;
    }

    .title2tagpat{
        width: 740px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    }

    .title2tagpat2{
        width: 740px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-bottom: 0em;
position: relative;
top: -10px;
    }

    .partimgmov{
        margin-top: 80px;
    }

    .contentt3{
        width: 705px;
        height: 204px;
        display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    margin-left: 30%;
      order: 2;
      font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 55px;
font-weight: 300;
line-height: 68px;
letter-spacing: 0em;
text-align: center;
      margin-bottom: 50px;
    }

    .luck3{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  /* display: inline-block; */
                  font-weight: 900;
    
    }

    .orgvector3 img{
        width: 332.75px;
height: 521.79px;
        margin-top: 90%;
        margin-left: -100%;
        position: relative;
    }

    .background-container3{
        width: 353.76px;
height: 498.57px;
        border-top-left-radius: 100%;
        margin-left: -18%;
        margin-top: 32%;
        position: absolute;
        background-color: #CC0530;
    }

    .violetbox3{
        background: rgba(130, 27, 97, 1);
        width: 224.41px;
height: 224.41px;
        margin-top: -40%;
        margin-bottom: 100%;
        margin-left: -60%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;     
font-size: 10px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;

    }

    .vt1pat{
        width: 55px;
height: 35px;
font-family: 'Plus Jakarta Sans', sans-serif;  
/* margin-left: 100px; */
position: relative;
left: 20px;
top: 10px;
font-size: 36px;
font-weight: 400;
line-height: 45px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);

    }

    .vt2pat{
        margin-top: 83px;
        margin-left: -35px;
        font-family: 'Plus Jakarta Sans', sans-serif; 
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);


    }

    .yellowbox3pat{
        background: rgba(247, 180, 0, 1);
        width: 130.45px;
height: 130.45px;
display: flex;
        margin-top: -116%;
        margin-left: -71%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif; 
    }

    .yt1pat{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 36px;
font-weight: 400;
line-height: 24.8px;
letter-spacing: 0em;
text-align: left;
margin-left: 8%;
    margin-top: 30px;
    }

    .yt2pat{
        font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
position: relative;
top: 62px;
left: -62px;
    }

    .linebox3{
        width: 181.3px;
height: 181.3px;
border: 1px;
opacity: 0.1px;
margin-left: -58%;
 position: relative;
margin-top: -225%;
margin-bottom: 100%;
border: 1px solid rgba(0, 0, 0, 1);

    }

    .hlinebox1pat{
        width: 110px;;
    height: 181.3px;
    opacity: 15%;
    left: 20%;
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 1);
}

.hlinebox2pat{
    width: 40px;
height: 181.3px;
opacity: 100%;
margin-left: 30%;
position: absolute;
border: 1px solid rgba(0, 0, 0, 1);
}

.rlinebox1pat{
    margin-top: 27px;
    width: 181.3px;
height: 100px;
opacity: 30%;
top: 10px;
position: absolute;
border: 1px solid rgba(0, 0, 0, 1);
}
.rlinebox2pat{
    /* margin-top: 20px; */
    width: 181.3px;
height: 40px;
opacity: 30%;
top: 30px;
position: absolute;
border: 1px solid rgba(0, 0, 0, 1);
}

.lineboxtext{
    width: 70.75px;
height: 49.75px;
margin-top: -50px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 36px;
font-weight: 400;
line-height: 45px;
letter-spacing: 0em;
text-align: left;
/* top: 80px; */
margin-left: 20px;
position: absolute;

}

.lineboxtext2{
    width: 90.65px;
height: 21px;
    margin-top: -3px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

.signinpatcol
    {
        width: 173px;
    height: 60px;
    position: relative;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    /* margin-top: -250%; */
    margin-left: 50%;
    background: rgba(7, 60, 165, 1);
    border: none;
    color: rgba(255, 255, 255, 1);
    }

    .watchpat{
        width: 200px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 0%;
    margin-left: -10px;
    }

    .watchs3{
        padding-top: 2.5%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        text-transform:capitalize;
    }

    .linetextprt{
        width: 78%;
height: 150px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
    }

    .linetxtprt{
        width: 800px;
height: 150px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
position: relative;
top: -130px;
margin-bottom: -6em;
    }

    .mid3contentpat{
        display: none;
    }

    /* .brandpppat{
        margin-top: 8%;
        margin-left: auto;
        margin-right: auto;
        width: 706px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
 
     } */
 
     /* .hirepppat{
 margin-top: 8%;
        margin-left: auto;
        margin-right: auto; 
        width: 706px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
     } */
 
     /* .digitalpppat{
 margin-top: 8%;
        margin-left: auto;
        margin-right: auto;
        width: 706px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
     } */
 
     /* .trainpppat{
 margin-top: 10%;
        margin-left: auto;
        margin-right: auto;
        width: 706px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
     } */
 
     .brandhpat{
         position: relative;
         top: 15%;
         margin-left: 5%;
         font-family: 'Plus Jakarta Sans', sans-serif;
         font-size: 35px;
         font-weight: 900;
         line-height: 42px;
         letter-spacing: 0em;
         text-align: center;
     width: 366px;
 height: 42px;
     }
 
     .hirehpat{
         width: 320px;
 height: 42px;
 top: 15%;
 position: relative;
         margin-left: 5%;
         font-family: 'Plus Jakarta Sans', sans-serif;
         font-size: 35px;
         font-weight: 900;
         line-height: 42px;
         letter-spacing: 0em;
         text-align: center;
     }
 
     /* .digitalhpat{
         top: 15%;
 position: relative;
         margin-left: 3.5%;
         width: 588px;
 height: 42px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 35px;
         font-weight: 900;
         line-height: 42px;
         letter-spacing: 0em;
         text-align: center;
     } */
 
     .trainhpat{
         top: 15%;
 position: relative;
         margin-left: 4%;
 width: 320px;
 height: 24px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 35px;
         font-weight: 900;
         line-height: 42px;
         letter-spacing: 0em;
         text-align: center;
     }
 
 
 
     .brandpat{
        width: 84.2%;
         height: 306px;
         background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
     border-radius: 50px;
     margin-top: 0%;
     margin-bottom: 20px;
     /* margin-left: 8.5%; */
     margin-left: auto;
     margin-right: auto;
     }
     
     .hirepat{
        width: 84.2%;
 height: 307px;
 border-radius: 50px;
         background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
     border-radius: 50px;
     margin-bottom: 20px;
     /* margin-left: 8.5%; */
     margin-left: auto;
     margin-right: auto;
     }
     
     .digitalpat{
        width: 84.2%;
 height: 307px;
         border-radius: 50px;
         background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%); font-size: 20px;
         margin-bottom: 20px;
         font-family: 'Plus Jakarta Sans', sans-serif;
         /* margin-left: 8.5%; */
         margin-left: auto;
         margin-right: auto;
     }
     
     .trainpat{
        width: 84.2%;
 height: 307px;
         border-radius: 50px;
         background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
         margin-bottom: 80px;
         font-family: 'Plus Jakarta Sans', sans-serif;
         margin-left: auto;
         margin-right: auto;
     }

     .partheadtitle{
        width: fit-content;
height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: left;
margin-top: 18%;
    position: relative;
margin-left: auto;
margin-right: auto;
    }

    .partbox{
        width: 674px;
height: 799px;
    border-radius: 81px;
    opacity: 0.5px;
    background: linear-gradient(270deg, #F7F7F7 0%, rgba(187, 192, 238, 0.5) 100%);
    position: relative;
    margin-left: 15%;
    }

    .par1{
        width: 700px;
height: 150px;
    font-family: 'Plus Jakarta Sans', sans-serif;;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    }
    
    .par2{
        width: 700px;
height: 150px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
    position: relative;
    margin-top: -6%;
    margin-left: 13%;
    }

    .reg{
        width: 173px;
        height: 60px;
    border-radius: 37.5px;
    background: #073CA5;
    /* margin-left: 40%; */
    position: relative;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 0%;
    border: none;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    
    }


    .chatboxtext51pat{
        padding: 10%;
        width: 508px;
height: 374px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
    }

    .chatbox51pat{
        width: 529px;
        height: 469px;
border-radius: 20px 20px 0px 20px;
margin-top: 50px;
background: rgba(207, 240, 230, 1);
margin-left: 13%;

    }


    .chatimg5pat{
        width: 83px;
height: 83px;
margin-left: 69%;
margin-top: 43%;
position: absolute;
    }
    

    .chatboxtext41pat{
        width: 508px;
        height: 225px;
padding: 20px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
padding: 10%;

    }

    .chatbox41pat{
        width: 529px;
height: 329px;
    border-radius: 0px 20px 20px 20px;
    background: rgba(231, 242, 255, 1);
    margin-top: 20px;
    margin-left: 70%;
   
    }

    .chatimg4pat{
        width: 83.46px;
height: 83.46px;
        margin-left: 14%;
        margin-top: 3%; 
       position: absolute;
    }

    .chatboxtext21pat{
        padding: 30px;
        width: 408px;
height: 299px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;



    }

    .chatbox21pat{
        background: rgba(207, 240, 230, 1);
        width: 529px;
height: 392px;
border-radius: 20px 0px 20px 20px;
margin-top: 150%;
margin-left: -70%;
    }

    .chatitle1tagtp{
        width: 370px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 8%;
    }

    .ctexttp{
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
    }

    .chatbox3{
        display: none;
    }

    .chatimg2pat{
        width: 83.46px;
height: 83.46px;
margin-top: -158%;
margin-left: 300px;

    }


    .chatboxtext1pat{
        padding: 50px;
        width: 509px;
height: 510.11px;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;


    font-family: 'Plus Jakarta Sans', sans-serif;
    }

    .chatbox11pat{
        width: 529px;
height: 602px;
border-radius: 0px 20px 20px 20px;
margin-top: 24px;
margin-left: 55%;
background: rgba(231, 242, 255, 1);


    }

    .chatbox111pat{
        clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
        width: 127.72px;
        height: 97.12px;
    
    background: rgba(231, 242, 255, 1);
    margin-left: 13%;
    margin-top: -59.75%;
    position: absolute;
    }
    
    .chatimg1{
        width: 83.46px;
height: 83.46px;
position: absolute;
margin-top: 3%;
margin-left: 7%;
    }

    .chatbox121pat{
        width: 127.72px;
        height: 97.12px;
        margin-top: -38.92%;
    margin-left: 19%;
    position: absolute;
    background: rgba(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    }

    .chatbox151pat{
        width: 127px;
height: 97px;
    background: rgba(207, 240, 230, 1);
    position: absolute;
    margin-top: -9.65%;
    clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100%);
    margin-left: 58%;
    }

    .startparapattab{
        width: 612.49px;
height: 50px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
position: relative;
margin-top: -23%;
margin-left: 21%;
margin-bottom: 10%;
    }

    .startheadprt{
        width: 510px;
height: 40px;
margin-left: auto;
margin-right: auto;
margin-top: 20px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 32px;
font-weight: 400;
line-height: 40px;
letter-spacing: 0em;
text-align: center;
color: rgb(255, 255, 255, 1);
    }

    .logosprt{
        white-space: nowrap;
    }
    
    .logo-slidetp{
        display: inline-block;
        animation: 80s slide infinite linear;
    }
    
    .logo-slidetp img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logossprt{
        white-space: nowrap;
        margin-bottom: 8em;
    }
    
    .logos-slidept{
        display: inline-block;
        animation: 80s slides infinite linear;
    }
    
    .logos-slidept img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }
    

}


@media (min-width:577px) and (max-width:767.5px) {
    .brandhpat{
        position: relative;
        top: 9%;
        margin-left: 0%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 30px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    width: 366px;
 height: 42px;
    }

    .hirehpat{
        width: 320px;
 height: 42px;
 top: 10%;
 position: relative;
        margin-left: 0%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 30px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    }

    .digitalhpat{
        top: 10%;
 position: relative;
        margin-left: 5%;
        width: 97%;
 height: 42px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 30px;
        font-weight: 900;
        line-height: 35px;
        letter-spacing: 0em;
        text-align: left;
    }

    .trainhpat{
        top: 8%;
 position: relative;
        margin-left: 0%;
 width: 320px;
 height: 24px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 30px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    }
}


@media (min-width:768px) and (max-width:830px) {
       .brandhpat{
       position: relative;
       top: 15%;
       margin-left: 5%;
       font-family: 'Plus Jakarta Sans', sans-serif;
       font-size: 35px;
       font-weight: 900;
       line-height: 42px;
       letter-spacing: 0em;
       text-align: center;
   width: 366px;
height: 42px;
   }

   .hirehpat{
    width: 320px;
height: 42px;
top: 15%;
position: relative;
    margin-left: 5%;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 35px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: center;
}

.digitalhpat{
    top: 15%;
position: relative;
    margin-left: 5%;
    width: 97%;
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
}

.trainhpat{
    top: 15%;
position: relative;
    margin-left: 4%;
width: 320px;
height: 24px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: center;
}
}


@media (min-width:577px) and (max-width:830px)
{
    .breakhideinlap{
        display: none;
    }
     
    /* box click element start */

    .clickihv1prt{
      width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
  }

  .clickihv2prt{
      width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
  }

  .clickihv3prt{
      width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
  }

  .clickhv1prt{
      width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
  }

  .clickhv2prt{
      width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
  }

  .clickhv3prt{
      width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
  }

  .clichv1prt{
      width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
  }

  .clichv2prt{
      width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
  }

  .clichv3prt{
      width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
  }

  .clihv1prt{
      width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
  }

  .clihv2prt{
      width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
  }

  .clihv3prt{
      width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
  }

  /* box click elemet end */

   /* top image animation  */

   .danceprt-divv {
      opacity: 0;
      transform: translateY(-400px);
      transition: 1s ;
  }
    
    .danceprt-divv.visible {
      opacity: 1;
      transform: translateY(0%);
      animation:  0.5s ease-in-out forwards ;
    }
    
    @keyframes shake {
      0% { transform: translateX(0); }
      25% { transform: translateX(-10px); }
      50% { transform: translateX(10px); }
      75% { transform: translateX(-10px); }
      100% { transform: translateX(0); }
    }
    
  .danceprt-div {
      transition: transform 0.5s ease; 
    }
    
    .danceprt-div.dancing {
      transform: translateY(-25px); 
      animation: 60s ease-out alternate; 
    }
    
    @keyframes bounce {
      from {
        transform: translateY(-25px);
      }
      to {
        transform: translateY(0);
      }
    }
  .patmarimg {
      /* width: 110px; */
       /* Adjust height as needed */
      background-size: 120%;
      background-color: white;
      border-radius: 100%;
    }
  .chatheadtitle{
      width: 749px;
height: 174px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: center;
margin-left: auto;
margin-right: auto;

  }

  .startedboxprt{
      width: 120%;
height: 252px;
border-radius: 25px;
background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
/* margin-left: 13%; */
margin-top: 10%;
  }

  .startedpat{
      margin-top: 7%;
      margin-bottom: 10%;
  }

  .yt2{

  }

  .regispt{
      width: 145.25px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
color: rgba(7, 60, 165, 1);
margin-left: 26%;
margin-top: 3%;


  }

  .chatbox141pat{
      width: 128px;
height: 97px;
  background: rgba(231, 242, 255, 1);
  position: absolute;
  margin-top: -32.65%;
  clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
  margin-left: 20%;
  }

  .subbuttonpat{
      margin-left: 173px;
      margin-top: -100px;
      width: 331px;
height: 61px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
position: absolute;
  background: rgb(255, 255, 255, 1);
  color: rgba(7, 60, 165, 1);
  border: solid rgba(255, 255, 255, 1);
  }

 

  .parttitletag{
      width: 234px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 25%;


  }

  .partnerbox1{
      display: none;
  }

  .bluec3{
      width: 59px;
  height: 59px;
  background: rgba(7, 60, 165, 1);
  margin-left: 170px;
  position: relative;
  margin-top: -45%;
  border-radius: 100%;
  }

  .whiteatp{
      width: 26.53px;
height: 33.17px;
position: relative;
top: 3px;
  }

  .title1tagpat{
      position: relative;
      width: 154px;
height: 64px;
border-radius: 32px;
      margin-left: auto;
      margin-right: auto;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
color: rgba(0, 0, 0, 1);
background: rgba(232, 237, 252, 1);
  }

  .title1tagpatn{
      align-items: center;
      font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 25px;
  position: relative;
  margin-top: -15%;
  margin-left: auto;
  margin-right: auto;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  width: 390px;
  height: 64px;
  border-radius: 32px;
  background-color: rgba(232, 237, 252, 1);
  }

  .title2tagpat2g{
      height: 40px;
      font-family: 'Plus Jakarta Sans', sans-serif;
      font-size: 25px;
      font-weight: 400;
      line-height: 31px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      top: -10px;
      margin-bottom: 0em;
  }

  .title2tagpat{
      /* width: 740px; */
  height: 87px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 50px;
  font-weight: 400;
  line-height: 87px;
  letter-spacing: 0em;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  }

  .title2tagpat2{
      /* width: 740px; */
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-bottom: 0em;
position: relative;
top: -1px;
  }

  .partimgmov{
      margin-top: 80px;
  }

  .contentt3{
      width: 705px;
      height: 204px;
      display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 75px;
  /* margin-left: 30%; */
    order: 2;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 55px;
font-weight: 300;
line-height: 68px;
letter-spacing: 0em;
text-align: center;
    margin-bottom: 50px;
  }

  .luck3{
      font-family: 'Plus Jakarta Sans', sans-serif;
      background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                color: transparent; 
                -webkit-background-clip: text; 
                background-clip: text;
                /* display: inline-block; */
                font-weight: 900;
  
  }

  .orgvector3 img{
      width: 332.75px;
height: 521.79px;
      margin-top: 90%;
      margin-left: -80%;
      position: relative;
  }

  .background-container3{
      width: 353.76px;
height: 498.57px;
      border-top-left-radius: 100%;
      margin-left: -18%;
      margin-top: 40%;
      position: absolute;
      background-color: #CC0530;
  }

  .violetbox3{
      background: rgba(130, 27, 97, 1);
      width: 224.41px;
height: 224.41px;
      margin-top: -40%;
      margin-bottom: 100%;
      margin-left: -82%;
      position: relative;
      font-family: 'Plus Jakarta Sans', sans-serif;     
font-size: 10px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;

  }

  .vt1pat{
      width: 55px;
height: 35px;
font-family: 'Plus Jakarta Sans', sans-serif;  
/* margin-left: 100px; */
position: relative;
left: 20px;
top: 10px;
font-size: 36px;
font-weight: 400;
line-height: 45px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);

  }

  .vt2pat{
      margin-top: 83px;
      margin-left: -35px;
      font-family: 'Plus Jakarta Sans', sans-serif; 
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);


  }

  .yellowbox3pat{
      background: rgba(247, 180, 0, 1);
      width: 130.45px;
height: 130.45px;
display: flex;
      margin-top: -140%;
      margin-left: -100%;
      position: relative;
      font-family: 'Plus Jakarta Sans', sans-serif; 
  }

  .yt1pat{
      width: 66px;
  height: 44px;
  font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 36px;
font-weight: 400;
line-height: 24.8px;
letter-spacing: 0em;
text-align: left;
margin-left: 8%;
  margin-top: 30px;
  }

  .yt2pat{
      font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
position: relative;
top: 62px;
left: -62px;
  }

  .linebox3{
      width: 181.3px;
height: 181.3px;
border: 1px;
opacity: 0.1px;
margin-left: -85%;
position: relative;
margin-top: -265%;
margin-bottom: 120%;
border: 1px solid rgba(0, 0, 0, 1);

  }

  .hlinebox1pat{
      width: 110px;;
  height: 181.3px;
  opacity: 15%;
  left: 20%;
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 1);
}

.hlinebox2pat{
  width: 40px;
height: 181.3px;
opacity: 100%;
margin-left: 30%;
position: absolute;
border: 1px solid rgba(0, 0, 0, 1);
}

.rlinebox1pat{
  margin-top: 27px;
  width: 181.3px;
height: 100px;
opacity: 30%;
top: 10px;
position: absolute;
border: 1px solid rgba(0, 0, 0, 1);
}
.rlinebox2pat{
  /* margin-top: 20px; */
  width: 181.3px;
height: 40px;
opacity: 30%;
top: 30px;
position: absolute;
border: 1px solid rgba(0, 0, 0, 1);
}

.lineboxtext{
  width: 70.75px;
height: 49.75px;
margin-top: -50px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 36px;
font-weight: 400;
line-height: 45px;
letter-spacing: 0em;
text-align: left;
/* top: 80px; */
margin-left: 20px;
position: absolute;

}

.lineboxtext2{
  width: 90.65px;
height: 21px;
  margin-top: -3px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.signinpatcol
  {
      width: 173px;
  height: 60px;
  position: relative;
  border-radius: 37.5px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  /* margin-top: -250%; */
  margin-left: 35%;
  background: rgba(7, 60, 165, 1);
  border: none;
  color: rgba(255, 255, 255, 1);
  }

  .watchpat{
      width: 200px;
  height: 60px;
  border-radius: 37.5px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  margin-top: 0%;
  margin-left: 0px;
  }

  .watchs3{
      padding-top: 2.5%;
      font-family: 'Plus Jakarta Sans', sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 31px;
      letter-spacing: 0em;
      text-align: center;
      text-transform:capitalize;
  }

  .linetextprt{
      width: 78%;
height: 150px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
  }

  .linetxtprt{
      width: 82%;
height: 150px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
position: relative;
top: -100px;
margin-bottom: -6em;
  }

  .mid3contentpat{
      display: none;
  }

  .brandpppat{
      margin-top: 8%;
      /* margin-left: 10%; */
      margin-left: auto;
      margin-right: auto;
      width: 90%;
      height: 175px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);

   }

   .hirepppat{
   
margin-top: 8%;
      /* margin-left: 10%; */
      
      margin-left: auto;
      margin-right: auto; 
      width: 90%;
      height: 175px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);

   }

   .digitalpppat{

margin-top: 16%;
      /* margin-left: 10%; */
      margin-left: auto;
      margin-right: auto;
      width: 90%;
      height: 175px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);
   }

   .trainpppat{
       /* padding-top: 9%;
       width: 365px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
padding-left: 10px; */

margin-top: 12%;
      /* margin-left: 10%; */
      margin-left: auto;
      margin-right: auto;
      width: 90%;
      height: 175px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);

   }

   /* .brandhpat{
       position: relative;
       top: 15%;
       margin-left: 5%;
       font-family: 'Plus Jakarta Sans', sans-serif;
       font-size: 35px;
       font-weight: 900;
       line-height: 42px;
       letter-spacing: 0em;
       text-align: center;
   width: 366px;
height: 42px;
   } */

   /* .hirehpat{
       width: 320px;
height: 42px;
top: 15%;
position: relative;
       margin-left: 5%;
       font-family: 'Plus Jakarta Sans', sans-serif;
       font-size: 35px;
       font-weight: 900;
       line-height: 42px;
       letter-spacing: 0em;
       text-align: center;
   } */

   /* .digitalhpat{
       top: 15%;
position: relative;
       margin-left: 5%;
       width: 97%;
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
       font-weight: 900;
       line-height: 42px;
       letter-spacing: 0em;
       text-align: left;
   } */

   /* .trainhpat{
       top: 15%;
position: relative;
       margin-left: 4%;
width: 320px;
height: 24px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
       font-weight: 900;
       line-height: 42px;
       letter-spacing: 0em;
       text-align: center;
   } */



   .brandpat{
      width: 84.2%;
       height: 306px;
       background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
   border-radius: 50px;
   margin-top: 3%;
   margin-bottom: 20px;
   /* margin-left: 8.5%; */
   margin-left: auto;
   margin-right: auto;
   }
   
   .hirepat{
      width: 84.2%;
height: 307px;
border-radius: 50px;
       background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
   border-radius: 50px;
   margin-bottom: 20px;
   /* margin-left: 8.5%; */
   margin-left: auto;
   margin-right: auto;
   }
   
   .digitalpat{
      width: 84.2%;
height: 307px;
       border-radius: 50px;
       background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%); font-size: 20px;
       margin-bottom: 20px;
       font-family: 'Plus Jakarta Sans', sans-serif;
       /* margin-left: 8.5%; */
       margin-left: auto;
       margin-right: auto;
   }
   
   .trainpat{
      width: 84.2%;
height: 307px;
       border-radius: 50px;
       background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
       margin-bottom: 80px;
       font-family: 'Plus Jakarta Sans', sans-serif;
       margin-left: auto;
       margin-right: auto;
   }

   .partheadtitle{
      width: fit-content;
height: 87px;
  font-family: 'Plus Jakarta Sans', sans-serif;;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: left;
margin-top: 18%;
  position: relative;
margin-left: auto;
margin-right: auto;
  }

  .partbox{
      width: 674px;
height: 799px;
  border-radius: 81px;
  opacity: 0.5px;
  background: linear-gradient(270deg, #F7F7F7 0%, rgba(187, 192, 238, 0.5) 100%);
  position: relative;
  margin-left: 15%;
  }

  .par1{
      width: 700px;
height: 150px;
  font-family: 'Plus Jakarta Sans', sans-serif;;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;
  }
  
  .par2{
      width: 700px;
height: 150px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
  position: relative;
  margin-top: -6%;
  margin-left: 13%;
  }

  .reg{
      width: 173px;
      height: 60px;
  border-radius: 37.5px;
  background: #073CA5;
  /* margin-left: 40%; */
  position: relative;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0%;
  border: none;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  
  }


  .chatboxtext51pat{
      padding: 10%;
      width: 508px;
height: 374px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
  }

  .chatbox51pat{
      width: 529px;
      height: 469px;
border-radius: 20px 20px 0px 20px;
margin-top: 50px;
background: rgba(207, 240, 230, 1);
margin-left: 13%;

  }


  .chatimg5pat{
      width: 83px;
height: 83px;
margin-left: 69%;
margin-top: 43%;
position: absolute;
  }
  

  .chatboxtext41pat{
      width: 508px;
      height: 225px;
padding: 20px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
padding: 10%;

  }

  .chatbox41pat{
      width: 529px;
height: 329px;
  border-radius: 0px 20px 20px 20px;
  background: rgba(231, 242, 255, 1);
  margin-top: 20px;
  margin-left: 70%;
 
  }

  .chatimg4pat{
      width: 83.46px;
height: 83.46px;
      margin-left: 14%;
      margin-top: 3%; 
     position: absolute;
  }

  .chatboxtext21pat{
      padding: 30px;
      width: 408px;
height: 299px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;



  }

  .chatbox21pat{
      background: rgba(207, 240, 230, 1);
      width: 529px;
height: 392px;
border-radius: 20px 0px 20px 20px;
margin-top: 150%;
margin-left: -70%;
  }

  .chatitle1tagtp{
      width: 370px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 8%;
  }

  .ctexttp{
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
  }

  .chatbox3{
      display: none;
  }

  .chatimg2pat{
      width: 83.46px;
height: 83.46px;
margin-top: -158%;
margin-left: 300px;

  }


  .chatboxtext1pat{
      padding: 50px;
      width: 509px;
height: 510.11px;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;


  font-family: 'Plus Jakarta Sans', sans-serif;
  }

  .chatbox11pat{
      width: 529px;
height: 602px;
border-radius: 0px 20px 20px 20px;
margin-top: 24px;
margin-left: 55%;
background: rgba(231, 242, 255, 1);


  }

  .chatbox111pat{
      clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
      width: 127.72px;
      height: 97.12px;
  
  background: rgba(231, 242, 255, 1);
  margin-left: 13%;
  margin-top: -59.75%;
  position: absolute;
  }
  
  .chatimg1{
      width: 83.46px;
height: 83.46px;
position: absolute;
margin-top: 3%;
margin-left: 7%;
  }

  .chatbox121pat{
      width: 127.72px;
      height: 97.12px;
      margin-top: -38.92%;
  margin-left: 19%;
  position: absolute;
  background: rgba(207, 240, 230, 1);
  clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
  }

  .chatbox151pat{
      width: 127px;
height: 97px;
  background: rgba(207, 240, 230, 1);
  position: absolute;
  margin-top: -9.65%;
  clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100%);
  margin-left: 58%;
  }

  .startparapattab{
      width: 612.49px;
height: 50px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
position: relative;
margin-top: -30%;
margin-left: 6%;
margin-bottom: 10%;
  }

  .startheadprt{
      width: 510px;
height: 40px;
margin-left: auto;
margin-right: auto;
margin-top: 20px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 32px;
font-weight: 400;
line-height: 40px;
letter-spacing: 0em;
text-align: center;
color: rgb(255, 255, 255, 1);
  }

  .logosprt{
      white-space: nowrap;
  }
  
  .logo-slidetp{
      display: inline-block;
      animation: 80s slide infinite linear;
  }
  
  .logo-slidetp img{
      margin: 0 40px;
      height: 100px;
  }
  
  @keyframes slide{
      from{ 
          transform: translateX(0);
      }
      to{
          transform: translateX(-100%);
      }
  }
  
  .logossprt{
      white-space: nowrap;
      margin-bottom: 8em;
  }
  
  .logos-slidept{
      display: inline-block;
      animation: 80s slides infinite linear;
  }
  
  .logos-slidept img{
      margin: 0 40px;
      height: 100px;
  }
  
  @keyframes slides{
      to{ 
          transform: translateX(0);
      }
      from{
          transform: translateX(-100%);
      }
  }
  

}


@media (max-width: 576px) {

    .breakhideinlap{
        display: none;
    }

     /* top image animation  */

     .danceprt-divv {
        opacity: 0;
        transform: translateY(-400px);
        transition: 1s ;
    }
      
      .danceprt-divv.visible {
        opacity: 1;
        transform: translateY(0%);
        animation:  0.5s ease-in-out forwards ;
      }
      
      @keyframes shake {
        0% { transform: translateX(0); }
        25% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
        75% { transform: translateX(-10px); }
        100% { transform: translateX(0); }
      }
      
    .danceprt-div {
        transition: transform 0.5s ease; 
      }
      
      .danceprt-div.dancing {
        transform: translateY(-25px); 
        animation: 60s ease-out alternate; 
      }
      
      @keyframes bounce {
        from {
          transform: translateY(-25px);
        }
        to {
          transform: translateY(0);
        }
      }
    .patmarimg {
        /* width: 110px; */
         /* Adjust height as needed */
        background-size: 120%;
        background-color: white;
        border-radius: 100%;
      }


      .mainkfc1{
        margin-left: 10%;
      }

      .mainadidas1{
        margin-left: -20%;
      }

      .mainburger1{
        margin-left: 30%;
      }

      .mainkfc2{
        margin-left: -5%;
      }



    .startparapattab{
        display: none;
    }

    .chatbox3{
        display: none;
    }

    .partnerbox1{
        display: none;
    }

    .reg{
        width: 137px;
    height: 42px;
    border-radius: 37.5px;
    background: #073CA5;
    margin-left: 33%;
    border: none;
    }
    

    .par1{
        width: 358px;
    height: 57px;
    font-family: 'Plus Jakarta Sans', sans-serif;;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-left: 5%;
    margin-top: 3%;
    }
    
    .par2{
        width: 358px;
    height: 57px;
    font-family: 'Plus Jakarta Sans', sans-serif;;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-left: 5%;
    }

    .partbox{
        width: 356px;
    height: 556px;
    border-radius: 81px;
    opacity: 0.5px;
    background: linear-gradient(270deg, #F7F7F7 0%, rgba(187, 192, 238, 0.5) 100%);
    position: relative;
    margin-left: 5%;
    }

    .partheadtitle{
        width: 194px;
    height: 31px;
    font-family: 'Plus Jakarta Sans', sans-serif;;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 90px;
    }

    .parttitletag{
        width: 105px;
height: 34px;
border-radius: 37.5px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 34%;
margin-top: 20%;


    }

    .luck3{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  /* display: inline-block; */
                  font-weight: 900;
    }

    html,body{

        overflow-x: hidden;
    }

    .namesm{
        width: 250px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 600;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
    }

    .desgsm{
        width: 134px;
height: 30px;
font-size: 15px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-style: italic;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
margin-top: -30px;
margin-left: 19%;
    }

    .brandpppat{
margin-top: 20%;
       /* margin-left: 17.5%; */
       margin-left: auto;
       margin-right: auto;
width: 260px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);
    }

    .hirepppat{
margin-top: 20%;
       margin-left: auto;
       margin-right: auto;
width: 260px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);

    }

    .digitalpppat{
margin-top: 18%;
margin-left: auto;
margin-right: auto;
width: 260px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);
    }

    .trainpppat{
margin-top: 20%;
margin-left: auto;
margin-right: auto;
width: 260px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);

    }

    .brandhpat{
    position: relative;
        top: 18%;
        margin-left: 11.2%;
        width: 250px;
        height: 24px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;

    }

    .hirehpat{
    width: 190px;
    height: 24px;
    top: 20%;
    position: relative;
            margin-left: 11.2%;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 900;
        line-height: 24px;
        letter-spacing: 0em;
    }

    .digitalhpat{
top: 16%;
position: relative;
        margin-left: 11.5%;
        width: 290px;
height: 48px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 900;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
    }

    .trainhpat{
top: 19%;
position: relative;
        margin-left: 11.5%;
width: 259px;
height: 24px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 900;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
    }

    .mid3contentpat
    {
        display: none;
    }



    .brandpat{
        width: 334px;
    height: 300px;
        background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
    border-radius: 50px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -15%;
    }
    
    .hirepat{
        width: 334px;
    height: 300px;
        background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
    border-radius: 50px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    }
    
    .digitalpat{
        width: 334px;
    height: 300px;
        border-radius: 50px;
        background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%); font-size: 20px;
        margin-bottom: 10px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: auto;
    margin-right: auto;
    }
    
    .trainpat{
        width: 334px;
    height: 300px;
        border-radius: 50px;
        background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
        margin-bottom: 50px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: auto;
    margin-right: auto;
    }

    .regispt{
        font-family: 'Plus Jakarta Sans', sans-serif;
        color: rgba(7, 60, 165, 1);
        width: 90px;
height: 19px;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
margin-top: 6%;
margin-left: auto;
margin-right: auto;

    }

    .bluec3{
        width: 33px;
height: 33px;
margin-top: -590px;
margin-left: 290px;
position: relative;
background: rgba(7, 60, 165, 1);
border-radius: 100%;
    }

    .whiteatp{
        width: 14.63px;
height: 18.28px;
position: relative;
left: -7px;
top: -2px;
    }

    .startparapat{
        width: 330px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-top: -60%;
margin-left: 8%;
position: relative;


    }

    .startheadprt{
        width: 405px;
height: 31px;
/* margin-left: 97px; */
margin-left: auto;
margin-right: auto;
margin-top: 15px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
color: rgb(255, 255, 255, 1);
    }

    .subbuttonpat{
        /* top: 40px; */
        /* margin-top: -10px; */
        top: -9px;
        position: relative;
        margin-left: 125px;
        height: 42px;
        width: 137px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }

    .startedboxprt{
        margin-top: 50px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    height: 170px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 140px;
    
    }

    .chatboxtext51pat{
        width: 240px;
height: 180px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
padding: 10%;



    }

    .chatbox51pat{
        width: 254px;
height: 170px;
border-radius: 20px 20px 0px 20px;
margin-top: 50px;
background: rgba(207, 240, 230, 1);
margin-left: 13%;

    }


    .chatimg5pat{
        width: 40.11px;
height: 40.11px;
margin-left: 340px;
margin-top: 175px;
position: absolute;
    }
    

    .chatboxtext41pat{
        width: 230px;
height: 108px;
padding: 20px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
    }

    .chatbox41pat{
        width: 250px;
    height: 178px;
    border-radius: 0px 20px 20px 20px;
    background: rgba(231, 242, 255, 1);
    margin-top: 0px;
    margin-left: 30%;
   
    }

    .chatimg4pat{
        width: 40.11px;
        height: 40.11px;
        margin-left: 35px;
        margin-top: 1%; 
       position: absolute;
    }

    .chatboxtext21pat{
        padding: 30px;
        width: 290px;
height: 144px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;


    }

    .chatbox21pat{
        background: rgba(207, 240, 230, 1);
        width: 290px;
height: 200px;
border-radius: 20px 20px 0px 20px;
margin-top: 10px;
margin-left: 10px;
    }

    .chatimg2pat{
        width: 40px;
height: 40px;
margin-top: -90px;
margin-left: 320px;

    }


    .chatboxtext1pat{
        padding: 23px;
        width: 250px;
height: 245.15px;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;

    font-family: 'Plus Jakarta Sans', sans-serif;
    }

    .chatbox11pat{
        width: 254px;
height: 325px;
border-radius: 0px 20px 20px 20px;
margin-top: -30px;
margin-left: 35%;
background: rgba(231, 242, 255, 1);


    }
    
    .chatimg1{
        width: 50px;
height: 50px;
position: relative;
top: 20px;
margin-left: 55px;

    }

    .chat{
        margin-top: 50px;
    }


    .chatheadtitle{
        width: 244px;
height: 62px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: 90px;
margin-bottom: -40px;

    }

    .chatitle1tagtp{
        width: 190px;
height: 34px;
border-radius: 37.5px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
    }

    .ctexttp{
        /* margin-left: 20px; */
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
    }
    

    .register2{
        width: 137px;
height: 42px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
position: relative;
/* margin-top: -200px; */
margin-left: 140px;
top: -60px;
    }


    .assesshead1{
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;
    }
    .assesshead2{
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;
    }
    .assesheadtitle{
        width: 248px;
        height: 31px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: 80px;
margin-top: -10px;
    }

.assesstitle1tag{
    margin-top: -240px;
    margin-left: 40%;
    width: 105px;
    height: 34px;
    border-radius: 37.5px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
}
    

    .assesscircle{
        overflow-x: hidden;
        width: 260px;
height: 260px;
margin-left: 259px;
    }

    .register{
        width: 137px;
height: 42px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
margin-left: 35%;
top: -60px;
position: relative;
left: 0px;
    }
    .register1t{
        padding-top: 8%;
    }

    .head12{
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;

    }

    .head13{
        margin-top: -60px;
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;

    }



    .traintitle1tag{
        width: 105px;
height: 34px;
border-radius: 37.5px;
margin-top: 350px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 40%;

    }

    .trainheadtitle{
        width: 194px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: 28%;
margin-top: -20px;


    }

    .traincircle{
        width: 260px;
        height: 260px;
        
    background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -120px;
    
    }

    .phonetitles{
        margin-left: 10px;
        margin-top: 100px;

    }
    
    .phonebody{
        /* margin-left: 10px; */
    }
    .orgcircle{
        width: 121.5px;
height: 121.5px;
margin-top: 100px;
margin-left: 40px;
    }

    .orgcircle::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 121.5px;
height: 121.5px;
        border-radius: 50%;
        opacity: 90%;
      }
      
      .orgcircle::before {
          background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
        }
    .viocircle{
        margin-top: -50px;
        width: 121.5px;
height: 121.5px;
opacity: 90%;
    }

    .viocircle::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }

    .viocircle::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        margin-left: -15%;
        width: 121.5px;
height: 121.5px;
        border-radius: 50%;
        opacity: 100%;
      }

    .bluecircle{
        width: 183.91px;
height: 183.91px;
margin-top: -250px;
margin-left: 80px;

    }

    .phone{
        width: 177px;
    height: 324.75px;
    margin-top: -250px;
    margin-left: 50px;
    /* order: 2; */
    
    }

    .phonetitles{
        order: 1;
    }
    .phonetitle1tag{
        margin-top: 10%;
        width: 105px;
        height: 34px;
        border-radius: 37.5px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
margin-left: 30%;
    }

    .sechead3{
        margin-top: -150px;
    }

    .firstsignup3{
        margin-top: -480px;
        text-align: center;
    }

    
    .firstsignup13{
        margin-top: -430px;
    }

    .phoneheadtitle{
        width: 201px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;
color: rgba(0, 0, 0, 1);
    }

.image-phone-container{
    margin-top: 100px;
    margin-left: 10px;
    order: 1;
}

.phonebody{
    order: 3;
    width: 358px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: -40px;
margin-top: 80px;

}

    .adobe{
        width: 89.62px;
height: 23.58px;
    }

    .adidas{
        margin-left: 30px;
        width: 45.28px;
height: 46.23px;
    }
    .burger{
        width: 43.4px;
height: 46.23px;
    }
    .kfc{
        margin-left: 20px;
        width: 43.4px;
height: 46.23px;
    }
    .fedex{
        width: 60.38px;
height: 25.47px;
    }
    .uptime{
        margin-left: 20px;
        width: 66.04px;
height: 23.58px;
    }

    .title2tagpat{
        width: 255px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
position: relative;
margin-top: 200px;
    }

    .title2tagpat2{
        width: 95%;
        margin-left: auto;
        margin-right: auto;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0em;
text-align: center;
top: -10px;
position: relative;
margin-bottom: 4em;
    }

    .titlee1tag{
        margin-top: 30px;
        width: 110px;
        margin-left: 35.5%;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1);
    }


    .title1tagpat{
        margin-top: -25%;
        width: 75px;
        margin-left: auto;
        margin-right: auto;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background-color: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1);
    }

    .title1tagpatn{
        width: 190px;
        margin-left: auto;
        margin-right: auto;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background-color: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1);
    }

    .title2tagpat2g{
        width: 250px;
        margin-left: auto;
        margin-right: auto;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
top: -10px;
margin-bottom: 0em;
position: relative;
    }

    .contentt3{
        width: 100%;
        height: 93px;
        display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
      order: 2;
      font-family: 'Plus Jakarta Sans', sans-serif;
      font-size: 25px;
      line-height: 31px;
      letter-spacing: 0em;
      text-align: center;
      margin-bottom: 50px;
    }
.registert{
    margin-top: 5%;
}
    .ttextpat{
        width: 155px;
height: 15px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;


    }

    .titlee1tag{
        width: 234px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
    }

    .violetbox3{
        background: rgba(130, 27, 97, 1);
        width: 123px;
        height: 124px;
        margin-top: -27%;
        margin-bottom: 100%;
        margin-left: 6%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;     
font-size: 10px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;

    }

    .vt1pat{
        width: 55px;
height: 35px;
font-family: 'Plus Jakarta Sans', sans-serif;  
/* margin-left: 100px; */
position: relative;
left: 0px;
top: -10px;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);
    }

    .vt2pat{
        margin-top: 35px;
        margin-left: -55px;
        font-family: 'Plus Jakarta Sans', sans-serif; 
        width: 55px;
        height: 35px;
font-size: 10px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);
    }

    .yellowbox3pat{
        background: rgba(247, 180, 0, 1);
        width: 72px;
        height: 72px;
        margin-top: -190%;
        margin-left: 5.6%;
        display: flex;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif; 



          
    }
    .yt1pat{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
margin-left: 5%;
    margin-top: 10px;
    }

    .yt2pat{
        font-size: 10px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
position: relative;
top: 30px;
left: -45px;
    }

    .linebox3{
        width: 100px;
height: 100px;
border: 1px;
opacity: 0.1px;
margin-left: 14%;
 position: relative;
margin-top: -165%;
margin-bottom: 180%;
border: 1px solid rgba(0, 0, 0, 1);
    }

    .hlinebox1pat{
        width: 63px;;
    height: 99px;
    opacity: 15%;
    margin-left: 18%;
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 1);
}

.hlinebox2pat{
    width: 23px;
height: 99px;
opacity: 30%;
margin-left: 30%;
border: 1px solid rgba(0, 0, 0, 1);
}

.rlinebox1pat{
    margin-top: 10px;
    width: 99px;
height: 63px;
opacity: 10%;
top: 9px;
position: absolute;
border: 1px solid rgba(0, 0, 0, 1);
}
.rlinebox2pat{
    /* margin-top: 20px; */
    width: 99px;
height: 23px;
opacity: 20%;
top: 16px;
position: absolute;
border: 1px solid rgba(0, 0, 0, 1);
}

.lineboxtext{
    width: 55px;
height: 35px;
/* margin-top: -60px; */
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;
left: 10px;
/* top: 80px; */
position: absolute;

}

.lineboxtext2{
    margin-top: 2px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 10px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;
}

.siginspat{
    padding-top: 1%;
    padding-left: 0%;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;

}

    .signinpatcol{
        width: 137px;
        
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
display: flex;
justify-content: center;
margin-top: 30px;
margin-left: 50px;
text-align: center;
align-content: center;
background: rgba(7, 60, 165, 1);
border: none;
color: white;
border-radius: 37.5px;
/* text-align: center; */

    }


    .watchs3{
        padding-top: 1%;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
text-transform:capitalize;

    }

    .watchpat{
        width: 150px;
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
display: flex;
border-radius: 37.5px;
justify-content: center;
margin-left: -8px;
margin-top: 30px;
text-align: center;

/* text-align: left; */
    }

    .linetextprt{
        width: 358px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 13px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
    }

    .linetxtprt{
        width: 358px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 13px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
top: -70px;
position: relative;
/* margin-bottom: 2em; */
    }

    .ima-container2{
        margin-left: 20px;
    }

  
    .ima-container1 {
      order: 1;
    }

    .background-container3{
        width: 190px;
        height: 267px;
        border-top-left-radius: 100%;
        margin-left: 27%;
        position: absolute;
        background-color: #CC0530;
    }



    .orgvector3 img{
        width: 189px;
        height: 297px;
        margin-top: -16%;
        margin-left: 120%;
        position: relative;
    }

    .chatbox121pat{
        width: 61.38px;
height: 46.67px;
    margin-top: -10.8%;
    margin-left: 64%;
    position: absolute;
    background: rgba(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100.3%);   
 }

 .chatbox141pat{
    width: 61px;
height: 46px;
background: rgba(231, 242, 255, 1);
position: absolute;
margin-top: -41.4%;
clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
margin-left: 15%;
}

.chatbox151pat{
    width: 61px;
height: 46px;
background: rgba(207, 240, 230, 1);
position: absolute;
margin-top: -10.8%;
clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100%);
margin-left: 67%;
}

.chatbox111pat{
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    width: 61px;
height: 46px;
background: rgba(231, 242, 255, 1);
margin-left: 24%;
margin-top: -75.7%;
position: absolute;
}


.logosprt{
    white-space: nowrap;
}

.logo-slidetp{
    display: inline-block;
    animation: 80s slide infinite linear;
}

.logo-slidetp img{
    margin: 0 40px;
    height: 50px;
}

@keyframes slide{
    from{ 
        transform: translateX(0);
    }
    to{
        transform: translateX(-100%);
    }
}

.logossprt{
    white-space: nowrap;
    margin-bottom: 5em;
}

.logos-slidept{
    display: inline-block;
    animation: 80s slides infinite linear;
}

.logos-slidept img{
    margin: 0 40px;
    height: 50px;
}

@keyframes slides{
    to{ 
        transform: translateX(0);
    }
    from{
        transform: translateX(-100%);
    }
}

    body{
        overflow-x: hidden;
    }
    
  }