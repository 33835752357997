.headingContainer{
    width: 100%;
height: 243px;
background: rgba(236, 245, 255, 1);
margin-top: 3%;
}

.shape1{
    width: 123px;
height: 120px;
background-color: rgb(26, 26, 130, 1);
border: 3px solid rgba(26, 26, 130, 1);
opacity: 40%;
position: absolute;
margin-top: 2%;
margin-left: -0.5%;
clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
}

.shape1box{
    margin-top: 7%;
    margin-left: 1.5%;
    position: relative;
    width: 109px;
    height: 105px;
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    background-color: rgba(236, 245, 255, 1);
}

.shape2{
    width: 80px;
height: 130px;
background-color:rgba(7, 60, 165, 1) ;
border: 3px solid rgba(7, 60, 165, 1);
opacity: 40%;
position: absolute;
margin-top: 3.5%;
margin-left: 6%;
clip-path: polygon(100% 50%, 100% 100%, 0% 100%, 0% 50%, 55% 0%, 100% 0%);
}

.shape2box{
    margin-top: 1%;
    margin-left: 0.5%;
    width: 75px;
height: 124px;
position: relative;
clip-path: polygon(100% 50%, 100% 100%, 0% 100%, 0% 50%, 55% 0%, 100% 0%);
background-color: rgba(236, 245, 255, 1);
}

.shape3{
    width: 140px;
height: 80px;
margin-left: 10%;
margin-top: 1%;
border: 3px solid rgba(137, 26, 97, 1);
background-color:rgba(137, 26, 97, 1);
opacity: 40%;
position: absolute;
clip-path: polygon(0 0, 41% 0, 100% 100%, 0% 100%);
}

.shape3box{
    margin-left: 0.5%;
    width: 129px;
height: 75px;
clip-path: polygon(0 0, 41% 0, 100% 100%, 0% 100%);
position: relative;
background-color: rgba(236, 245, 255, 1);
}

.shape4{
    width: 75px;
height: 100px;
position: absolute;
background-color: rgba(204, 5, 48, 1);
border-radius: 0 100% 0 0;
margin-left: 17%;
margin-top: 2%;
opacity: 40%;
}

.shape4box{
    margin-top: 7%;
    margin-left:5.5%;
    width: 65px;
    height: 90px;
    border-radius: 0 100% 0 0;
    position: relative;
    background-color: rgba(236, 245, 255, 1);
}

.shape5{
    width: 100px;
    height: 70px;
    position: absolute;
    background-color: rgba(255, 188, 0, 1);
    margin-left: 20.5%;
    margin-top: 1%;
    opacity: 40%;
}

.shape5box{
    margin-top: 5%;
    margin-left:5.5%;
    width: 90px;
    height: 60px;
    position: relative;
    background-color: rgba(236, 245, 255, 1);
}

.shape6{
    width: 75px;
height: 100px;
position: absolute;
background-color: rgba(255, 91, 0, 1);
border-radius: 0 0% 100% 0;
margin-left: 26%;
margin-top: 4%;
opacity: 40%;
}

.shape6box{
    margin-top: 6.5%;
    margin-left:5.5%;
    width: 65px;
    height: 90px;
    border-radius: 0 0 100% 0;
    position: relative;
    background-color: rgba(236, 245, 255, 1);
}

.shape7{
    width: 70px;
height: 70px;
position: absolute;
background-color: rgba(12, 96, 196, 1);
margin-left: 29.5%;
margin-top: 6.5%;
opacity: 40%;
}

.shape7box{
    margin-top: 5.5%;
    margin-left:4.5%;
    width: 64px;
    height: 63px;
    position: relative;
    background-color: rgba(236, 245, 255, 1);
}

.headtext{
    margin-left: 37%;
    margin-top: 5%;
    width: 350px;
height: 87px;
position: absolute;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
}



.shape12{
    width: 123px;
height: 120px;
background-color: rgb(26, 26, 130, 1);
border: 3px solid rgba(26, 26, 130, 1);
opacity: 40%;
position: absolute;
margin-top: 2%;
margin-left: 92%;
clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}

.shape12box{
    margin-top: 7.5%;
    margin-left: 5.5%;
    position: relative;
    width: 109px;
    height: 105px;
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    background-color: rgba(236, 245, 255, 1);
}

.shape22{
    width: 80px;
height: 130px;
background-color:rgba(7, 60, 165, 1) ;
border: 3px solid rgba(7, 60, 165, 1);
opacity: 40%;
position: absolute;
margin-top: 3.5%;
margin-left: 88%;
clip-path: polygon(50% 0%, 100% 44%, 100% 100%, 50% 100%, 0 100%, 0 0);
}

.shape22box{
    margin-top: 1%;
    margin-left: 0.5%;
    width: 74px;
height: 124px;
position: relative;
clip-path: polygon(50% 0%, 100% 44%, 100% 100%, 50% 100%, 0 100%, 0 0);
background-color: rgba(236, 245, 255, 1);
}

.shape32{
    width: 140px;
height: 80px;
margin-left: 79.5%;
margin-top: 1%;
border: 3px solid rgba(137, 26, 97, 1);
background-color:rgba(137, 26, 97, 1);
opacity: 40%;
position: absolute;
clip-path: polygon(60% 0, 100% 0, 100% 100%, 0% 100%);
}

.shape32box{
    margin-left: 3.8%;
    width: 129px;
height: 75px;
clip-path: polygon(60% 0, 100% 0, 100% 100%, 0% 100%);
position: relative;
background-color: rgba(236, 245, 255, 1);
}

.shape42{
    width: 75px;
height: 100px;
position: absolute;
background-color: rgba(204, 5, 48, 1);
border-radius: 100% 0 0 0;
margin-left: 76%;
margin-top: 2%;
opacity: 40%;
}

.shape42box{
    margin-top: 7.5%;
    margin-left:8%;
    width: 65px;
    height: 90px;
    border-radius:  100% 0 0 0;
    position: relative;
    background-color: rgba(236, 245, 255, 1);
}

.shape52{
    width: 100px;
    height: 70px;
    position: absolute;
    background-color: rgba(255, 188, 0, 1);
    margin-left: 70.8%;
    margin-top: 1%;
    opacity: 40%;
}

.shape52box{
    margin-top: 5%;
    margin-left:5.5%;
    width: 90px;
    height: 60px;
    position: relative;
    background-color: rgba(236, 245, 255, 1);
}

.shape62{
    width: 75px;
height: 100px;
position: absolute;
background-color: rgba(255, 91, 0, 1);
border-radius: 0 0% 0 100%;
margin-left: 67%;
margin-top: 4%;
opacity: 40%;
}

.shape62box{
    margin-top: 5.5%;
    margin-left:7%;
    width: 65px;
    height: 90px;
    border-radius: 0 0 0 100% ;
    position: relative;
    background-color: rgba(236, 245, 255, 1);
}

.shape72{
    width: 70px;
height: 70px;
position: absolute;
background-color: rgba(12, 96, 196, 1);
margin-left: 64%;
margin-top: 6.5%;
opacity: 40%;
}

.shape72box{
    margin-top: 5.5%;
    margin-left:4.5%;
    width: 64px;
    height: 63px;
    position: relative;
    background-color: rgba(236, 245, 255, 1);
}

.search{
    width: 355px;
height: 67px;
border-radius: 15px;
border: 2px;
background: rgba(255, 255, 255, 1);
border: 2px solid rgba(226, 226, 226, 1);
margin-top: -10%;
margin-left: 27%;
  font-size: 16px;
  clip-path: polygon(87% 0%, 0% 0%, 0% 100%, 87% 100%);
  /* background-image: url('../Joblisting/images/search.png'); */
  background-position: 15px 24px; 
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
}

.search::placeholder{

    width: 51px;
height: 16px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: left;
color: rgba(13, 13, 13, 1);
}

.displaybox{
    margin-top: -4.4%;
    margin-left: 47%;
    width: 340px;
    position: relative;
height: 67.1px;
border-radius: 0 15px 15px 0;
border: 2px;
background: rgba(255, 255, 255, 1);
border: 2px solid rgba(226, 226, 226, 1);
}

.displayboxcontent{
    margin-left: 6%;
    margin-top: 6.5%;
    width: 118px;
    height: 16px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}


.Container1{
    width: 839px;
height: 140px;
padding: 25px, 15px, 25px, 15px;
border-radius: 10px;
border: 1px;
background: rgba(255, 255, 255, 1);
border: 1px solid rgba(230, 231, 234, 1);
margin-left: 35%;
}

.infosys{
    position: relative;
    margin-top: 3%;
    margin-left: 2%;
}

.CompanyName{
    width: 186px;
height: 18px;
position: relative;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 14px;
font-weight: 500;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
color: rgba(153, 159, 172, 1);
margin-left: 7.75%;
margin-top: -5%;
}
.JobName{
    width: 202px;
height: 24px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 18px;
font-weight: 700;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: rgba(52, 62, 89, 1);
position: relative;
margin-left: 7.5%;
margin-top: -2%;
}

@media (min-width:1228px) and (max-width:1399px){
        
}

@media (min-width:831px) and (max-width:1026px){
    .headtext{
        position: absolute;
        margin-top: 10.5%;
        margin-left: 33%;
        /* margin-bottom: -8%; */
    }
}


@media (max-width: 576px) {
    .headingContainer{
        height: 200px;
    }
    .shape1{
        display: none;
    }
    .shape2{
        display: none;
    }
    .shape3{
        display: none;
    }
    .shape4{
        display: none;
    }

    .shape5{
        margin-left: -13%;
        margin-top: 4%;
    }

    .shape6{
        margin-left: 6%;
        margin-top: 9%;
    }
    .shape7{
        margin-left: 17%;
        margin-top: 18%;
    }

    .shape12{
        display: none;
    }
    .shape22{
        display: none;
    }
    .shape32{
        display: none;
    }
    .shape42{
        display: none;
    }
    .shape52{
        margin-left: 90%;
        margin-top: 4%;
    }
    .shape62{
        margin-left: 77.5%;
        margin-top: 9%;
    }
    .shape72{
        margin-left: 68%;
        margin-top: 18%;
    }

    .headtext{
        width: 125px;
        height: 31px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;

    }
    html, body {
        overflow-x: hidden !important;
        margin: 0; /* Remove default margin */
        padding: 0; /* Remove default padding */
      }
}
