@media (min-width: 1400px){
    .expbluformbox{
        width: 1233px;
    height: 846px;
    border-radius: 40px;
    background: #1A1A82;
    margin-top: 10%;
    margin-bottom: 10%;
    }
    
    .expgreyformbox{
        width: 762px;
    height: 846px;
    position: relative;
    margin-left: auto;
    border-radius: 40px;   
    border: 1px solid #073CA5;
    background: linear-gradient(180deg, #FFFFFF 0%, #C5C5C5 100%);
    }

    .experformbac{
        width: 236px;
height: 358px;
background: rgba(255, 188, 0, 1);
margin-top: -64%;
margin-left: 7%;
position: relative;
    }

    .experformimg{
        width: 293px;
height: 438px;
margin-top: -36.5%;
margin-left: 6.4%;
position: relative;
    }

    .expformtit{
        width: 241px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    margin-left: 4%;
    margin-top: 1%;
    color: #FFFFFF;
    position: relative;
    }
    
    .expeformpara{
        width: 370px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 4%;
    margin-top: 2%;
    position: relative;
    margin-bottom: 17.3%;
    }

.explogcontent {
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin-top: -56%;
    margin-left: 40%; 
  }    
  .explogsbutton {
    display: flex;
    margin-left: 45%;
    margin-top: 40px; 
  }

  :where(.css-dev-only-do-not-override-1drr2mu).ant-form-item .ant-form-item-label >label {
    font-size: 20px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
  }

  /* .titfrmexp{
    align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 170px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    position: relative;
    left: 530px;
    top: -920px;
  } */

}


@media (min-width:1228px) and (max-width:1399px){
    .expbluformbox{
        width: 1233px;
    height: 846px;
    border-radius: 40px;
    background: #1A1A82;
    margin-top: 10%;
    /* margin-bottom: -100%; */
    }
    
    .expgreyformbox{
        width: 762px;
    height: 846px;
    position: relative;
    margin-left: auto;
    border-radius: 40px;   
    border: 1px solid #073CA5;
    background: linear-gradient(180deg, #FFFFFF 0%, #C5C5C5 100%);
    }

    .experformbac{
        width: 236px;
height: 358px;
background: rgba(255, 188, 0, 1);
margin-top: -62%;
margin-left: 7%;
position: absolute;
    }

    .experformimg{
        width: 293px;
height: 438px;
margin-top: -101.5%;
margin-left: 6.4%;
position: relative;
    }

    .expformtit{
        width: 241px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    margin-left: 4%;
    margin-top: 1%;
    color: #FFFFFF;
    position: relative;
    }
    
    .expeformpara{
        width: 337px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 4%;
    margin-top: 2%;
    position: relative;
    /* margin-bottom: 17.3%; */
    }

  .formexpext{
    position: relative;
    top: -20px;
    left: -20px;
    width: fit-content;
    /* margin-bottom: -50%; */
}

.expregisform{
    position: relative;
    top: -20px;
    left: -50px;
    margin-bottom: -30%;
}

/* .titfrmexp{
    align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 170px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    position: relative;
    left: 510px;
    top: -670px;
  } */

}


@media (min-width:1028px) and (max-width:1226px){
    .expbluformbox{
        width: 1233px;
    height: 846px;
    border-radius: 40px;
    background: #1A1A82;
    margin-top: 10%;
    /* margin-bottom: -100%; */
    }
    
    .expgreyformbox{
        width: 762px;
    height: 846px;
    position: relative;
    margin-left: auto;
    border-radius: 40px;   
    border: 1px solid #073CA5;
    background: linear-gradient(180deg, #FFFFFF 0%, #C5C5C5 100%);
    }

    .experformbac{
        width: 236px;
height: 358px;
background: rgba(255, 188, 0, 1);
margin-top: -62%;
margin-left: 7%;
position: absolute;
    }

    .experformimg{
        width: 293px;
height: 438px;
margin-top: -101.5%;
margin-left: 6.4%;
position: relative;
    }

    .expformtit{
        width: 241px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    margin-left: 4%;
    margin-top: 1%;
    color: #FFFFFF;
    position: relative;
    }
    
    .expeformpara{
        width: 337px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 4%;
    margin-top: 2%;
    position: relative;
    /* margin-bottom: 17.3%; */
    }

  .formexpext{
    position: relative;
    top: -20px;
    left: -20px;
    width: fit-content;
    /* margin-bottom: -50%; */
}

.expregisform{
    position: relative;
    top: -20px;
    left: -50px;
    margin-bottom: -30%;
}

/* .titfrmexp{
    align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 170px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    position: relative;
    left: 510px;
    top: -670px;
  } */

}

@media (min-width:1001px) and (max-width:1027px){

    .experformbac{
        width: 236px;
height: 358px;
background: rgba(255, 188, 0, 1);
margin-top: -74%;
margin-left: 7%;
position: absolute;
    }

    .experformimg{
        width: 293px;
height: 438px;
margin-top: -97%;
margin-left: 6.4%;
position: relative;
    }

    .expformtit{
        width: 241px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    margin-left: 4%;
    margin-top: 1%;
    color: #FFFFFF;
    position: relative;
    }
    
    .expeformpara{
        width: 366px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 4%;
    margin-top: 2%;
    position: relative;
    margin-bottom: -35%;
    }

  .formexpext{
    position: relative;
    width: 500px;
    top: -170px;
    left: -150px;
}

.expregisform{
    position: relative;
    width: 500px;
    top: -460px;
    left: -140px;
    margin-bottom: -30%;
}

}

@media (min-width:831px) and (max-width:1000px){
    /* .expbluformbox{
        width: 1233px;
    height: 846px;
    border-radius: 40px;
    background: #1A1A82;
    margin-top: 10%;
    }
    
    .expgreyformbox{
        width: 762px;
    height: 846px;
    position: relative;
    margin-left: auto;
    border-radius: 40px;   
    border: 1px solid #073CA5;
    background: linear-gradient(180deg, #FFFFFF 0%, #C5C5C5 100%);
    } */

    .experformbac{
        width: 236px;
height: 358px;
background: rgba(255, 188, 0, 1);
margin-top: -74%;
margin-left: 7%;
position: absolute;
    }

    .experformimg{
        width: 293px;
height: 438px;
margin-top: -97%;
margin-left: 6.4%;
position: relative;
    }

    .expformtit{
        width: 241px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    margin-left: 4%;
    margin-top: 1%;
    color: #FFFFFF;
    position: relative;
    }
    
    .expeformpara{
        width: 366px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 4%;
    margin-top: 2%;
    position: relative;
    margin-bottom: -35%;
    }

  .formexpext{
    position: relative;
    width: 500px;
    top: -240px;
    left: -200px;
}

.expregisform{
    position: relative;
    width: 500px;
    top: -450px;
    left: -200px;
    margin-bottom: -30%;
}

/* .titfrmexp{
    align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 170px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    position: relative;
    left: 510px;
    top: -670px;
  } */

}

@media (min-width:577px) and (max-width:830px){
    .expregisform{
        position: absolute;
        top: 90px;
        left: 145px;
        transform: scale(1.4);
    transform-origin: top;
    width: 200px;
    height: 200px;
    }

    .experformimg{
        width: 164px;
        height: 245px;
        top: -163px;
        left: -5px;
        position: relative;
    }

    .experformbac{
        width: 133px;
height: 201px;
background: #FFBC00;
position: relative;
top: 80px;
    }

    .expformtit{
        width: 151px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
color: #FFFFFF
    }

    .expeformpara{
        width: 282px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
color: #FFFFFF;
    }

    .formexpext{
        position: absolute;
        top: 495px;
        left: 112px;
        margin-bottom: -140%;
    }

    .titfrmexp{
        display: none !important;
    }

}

@media (min-width:501px) and (max-width:576px){ 
    .expregisform{
        position: relative;
        top: -1490px;
        left: 30px;
    }

    .experformimg{
        width: 164px;
        height: 245px;
        top: -163px;
        left: -5px;
        position: relative;
    }

    .experformbac{
        width: 133px;
height: 201px;
background: #FFBC00;
position: relative;
top: 80px;
    }

    .expformtit{
        width: 151px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
color: #FFFFFF
    }

    .expeformpara{
        width: 282px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
color: #FFFFFF;
    }

    .formexpext{
        position: relative;
        top: -1630px;
        left: 30px;
        margin-bottom: -130%; 
    }

    .titfrmexp{
        display: none !important;
    }

}

@media (max-width:500px){
    .expregisform{
        position: relative;
        top: -1310px;
        left: 30px;
    }

    .experformimg{
        width: 164px;
        height: 245px;
        top: -163px;
        left: -5px;
        position: relative;
    }

    .experformbac{
        width: 133px;
height: 201px;
background: #FFBC00;
position: relative;
top: 80px;
    }

    .expformtit{
        width: 151px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
color: #FFFFFF
    }

    .expeformpara{
        width: 282px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
color: #FFFFFF;
    }

    .formexpext{
        position: relative;
        top: -1460px;
        left: 30px;
        margin-bottom: -140%; 
    }

    .titfrmexp{
        display: none !important;
    }

}

