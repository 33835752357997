/* .testimonial-container5.testimonials-inline5 {
  width: 550px; 
  margin-left: 30px;
  margin-top: 10px;
}

.testimonial-container2.testimonials-inline2 {
  margin-top: 30px !important; 
} */


/* animation start */

.testimonials-hidden {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.testimonials-visible {
  opacity: 1;
  transform: translateY(0);
}


/* animation end  */

.testimonial-container1 {
  display: flex;
  /* align-items: center; */
  margin-top: 40px;
}

.testimonial-container2 {
  display: flex;
  margin-top: -200px;
  margin-left: auto;
}

.testimonial-container3 {
  display: flex;
  margin-top: 15px;
  margin-left: 35px;
  /* margin-left: auto; */
}

.testimonial-container4 {
  display: flex;
  margin-top: -570px;
  /* margin-left: auto; */
}

.testimonial-container5 {
  display: flex;
  margin-top: -330px;
  margin-left: auto;
}


.testimonial-container1 img {
  width: 87px;
  height: 87px;
  border-radius: 100%;
  margin-right: 20px;
  position: relative;
  left: -20px;
  top: 20px;
}

.testimonial-container2 img {
  width: 87px;
  height: 87px;
  border-radius: 100%;
  margin-left: 40px;
  margin-top: 20px;
}

.testimonial-container3 img {
  width: 87px;
  height: 87px;
  border-radius: 100%;
  margin-left: 40px;
  margin-top: 20px;
}

.testimonial-container4 img {
  width: 87px;
  height: 87px;
  border-radius: 100%;
  margin-left: 0px;
  margin-top: 20px;
  margin-right: 20px;
}

.testimonial-container5 img {
  width: 87px;
  height: 87px;
  border-radius: 100%;
  margin-left: 40px;
  margin-top: 20px;
}

.testimonial-box1 {
  width: 378px;
  /* height: 400px; */
  background: rgba(231, 242, 255, 1);
  padding-top: 20px;
  border-radius: 0 50px 50px 50px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.testimonial-box1::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50px;
  width: 0px;
  height: 0px;
  border-bottom: 60px solid transparent; /* Adjust size as needed */
  border-right: 60px solid rgba(231, 242, 255, 1); /* Match the background color of the testimonial box */
}

.testimonial-box2 {
  width: 550px;
  /* height: 400px; */
  /* height: auto; */
  background: rgba(207, 240, 230, 1);
  padding: 20px;
  border-radius: 50px 0px 50px 50px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.testimonial-box2::before {
  content: "";
  position: absolute;
  top: 0;
  right: -50px; 
  width: 0px;
  height: 0px;
  border-bottom: 60px solid transparent;
  border-left: 60px solid rgba(207, 240, 230, 1); 
}

.testimonial-box3 {
  width: 500px;
  /* height: 400px; */
  /* height: auto; */
  background: rgba(207, 240, 230, 1);
  padding: 20px;
  border-radius: 50px 0px 50px 50px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.testimonial-box3::before {
  content: "";
  position: absolute;
  top: 0;
  right: -50px; 
  width: 0px;
  height: 0px;
  border-bottom: 60px solid transparent;
  border-left: 60px solid rgba(207, 240, 230, 1); 
}

.testimonial-box4 {
  width: 348px;
  /* height: 400px; */
  background: rgba(231, 242, 255, 1);
  padding-top: 20px;
  border-radius: 0 50px 50px 50px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.testimonial-box4::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50px;
  width: 0px;
  height: 0px;
  border-bottom: 60px solid transparent; /* Adjust size as needed */
  border-right: 60px solid rgba(231, 242, 255, 1); /* Match the background color of the testimonial box */
}

.testimonial-box5 {
  width: 550px;
  /* height: 400px; */
  /* height: auto; */
  background: rgba(207, 240, 230, 1);
  padding: 20px;
  border-radius: 50px 0px 50px 50px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.testimonial-box5::before {
  content: "";
  position: absolute;
  top: 0;
  right: -50px; 
  width: 0px;
  height: 0px;
  border-bottom: 60px solid transparent;
  border-left: 60px solid rgba(207, 240, 230, 1); 
}

.testcontent {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  font-family: "Plus Jakarta Sans", sans-serif;
  padding: 30px;
}
.testname {
  padding-left: 30px;
  font-weight: 900;
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  margin-bottom: 40px;
}

.testdesignation {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 22px;
  font-style: italic;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}

.testionialsContainer {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  align-items: center;
  gap: 20px;
}

.testionialsContainer .testimonialsbox1 {
  flex: 0 0 100%;
}

.testmainheaderfre {
  width: 370px;
  height: 64px;
  border-radius: 32px;
  background: rgba(232, 237, 252, 1);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 25px;
  font-weight: 400;
  line-height: 31px;
  text-align: center;
}

.testmainsubheaderfre {
  width: 880px;
  height: 174px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 70px;
  font-weight: 400;
  line-height: 86.8px;
  text-align: left;
  margin-top: 5px;
}

.testmainsubheader1fre {
  width: 700px;
  height: 174px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 70px;
  font-weight: 400;
  line-height: 86.8px;
  text-align: left;
}

@media (min-width:1027px) and (max-width:1399px){
  .testimonial-container2 {
    display: flex;
    /* margin-top: -1030px; */
    margin-left: auto;
    margin-right: -30px;
  }

  .testimonial-container4{
    margin-top: -585px;
  }

  .testimonial-container5{
      margin-top: -360px;
  }
}

@media screen and (max-width: 1025px) {
  .testmainheaderfre {
    width: 370px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .testmainsubheaderfre {
    width:94%;
    height: 174px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 86.8px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  .testmainsubheader1fre {
    width:94%;
    height: 174px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 86.8px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  .testimonial-container1{
    margin-left: 50px;
  }

  .testimonial-container2{
    margin-left: auto;
    margin-right: 50px;
    margin-top: 40px;
  }

  .testimonial-container3{
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .testimonial-container4{
    margin-top: 30px;
    margin-left: 50px;
  }

  .testimonial-container5{
    margin-top: 30px;
    margin-left: auto;
    margin-right: 50px;
  }

  .testimonial-box1{
    width: 530px;
  }

  .testimonial-box2{
    width: 520px;
  }

  .testimonial-box4{
    width: 520px;
  }

  .testimonial-box3{
    border-radius: 50px 50px 0px 50px;

  }


  .testimonial-box3::before {
    content: "";
    position: absolute;
    top: initial;
    bottom: 0px;
    right: -50px; 
    width: 0px;
    height: 0px;
    border-bottom: initial;
    border-top: 60px solid transparent;
    border-left: 60px solid rgba(207, 240, 230, 1); 
  }

  .testimonial-container3 img {

    border-radius: 100%;
    margin-right: 20px;
    position: relative; 
    bottom: 40px; 
    left: -10px; 
    margin-top: auto; 
  }


}

@media screen and (max-width: 769px) {
  .testionialsContainer .testimonialsbox1 {
    flex-basis: 100%; /* Each box takes up 100% of the width on smaller screens */
    max-width: 100%;
  }

  .testimonial-box1 {
    width: fit-content;
    background: rgba(231, 242, 255, 1);
    padding: 20px;
    border-radius: 0 20px 20px 20px;
    position: relative;
  }

  .testimonial-box2 {
    width: fit-content;
    background: rgba(207, 240, 230, 1);
    padding: 20px;
    border-radius: 20px 0px 20px 20px;
    position: relative;
  }

  .testimonial-container1 img {
    width: 83px;
    height: 83px;
    border-radius: 100%;
    margin-right: 20px;
    position: relative;
    left: -20px;
    top: 20px;
  }

  .testimonial-container2 img {
    width: 83px;
    height: 83px;
    border-radius: 100%;
    margin-left: 40px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 578px) {
  .testmainheaderfre {
    width: 180px;
    height: 34px;
    border-radius: 37px;
    background: rgba(232, 237, 252, 1);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.88px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .testmainsubheaderfre {
    width: 99%;
    height: 62px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .testmainsubheader1fre {
    width: 99%;
    height: 62px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .testimonial-container1{
    margin-top: 30px;
    margin-left: 0px;
  }

  .testimonial-container2{
    margin-top: 10px;
    margin-left: 30px;
  }

  .testimonial-container3{
    margin-top: 10px;
    margin-left: 30px;
  }

  

  .testimonial-container4{
    margin-top: 10px;
  }

  .testimonial-container5{
    margin-top: 10px;
  }


  .testimonial-container1 img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 20px;
    position: relative;
    left: -20px;
    top: 20px;
  }

  .testimonial-container2 img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-left: 40px;
    margin-top: 20px;
  }

  .testimonial-container3 img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 20px;
    position: relative; /* Keep the image's position relative */
    bottom: 40px; /* Position the image at the bottom */
    left: -10px; /* Adjust the horizontal position as needed */
    margin-top: auto; /* Ensure no top margin */
  }

  .testimonial-container4 img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 20px;
    position: relative;
    left: -20px;
    top: 0px;
  }

  .testimonial-container5 img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 20px;
    position: relative;
    left: 0px;
    top: 0px;
  }
  .testimonial-box1 {
    width: 255px;
    background: rgba(231, 242, 255, 1);
    /* padding: 20px; */
    border-radius: 0 20px 20px 20px;
    position: relative;
  }

  .testimonial-box2 {
    width: 255px;
    background: rgba(207, 240, 230, 1);
    /* padding: 20px; */
    border-radius: 20px 0px 20px 20px;
    position: relative;
  }

  .testimonial-box3{
    width: 255px;
    /* padding: 20px; */
    border-radius: 20px 20px 0px 20px;
    position: relative;
  }

  .testimonial-box3::before {
    content: "";
    position: absolute;
    top: initial;
    bottom: 0px;
    right: -50px; 
    width: 0px;
    height: 0px;
    border-bottom: initial;
    border-top: 60px solid transparent;
    border-left: 60px solid rgba(207, 240, 230, 1); 
  }

  
  .testimonial-box4{
    width: 255px;
        padding: 20px;
        border-radius: 0 20px 20px 20px;
        position: relative;
  }

  

  .testimonial-box5{
    width: 255px;
    /* padding: 20px; */
    border-radius: 20px 0px 20px 20px;
    position: relative;
  }

  .testimonial-container5{
    margin-left: 70px;
  }

  .testcontent {
    margin: 0;
    width: 210px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    font-family: "Plus Jakarta Sans", sans-serif;
    padding: 0px;
  }
  .testname {
    padding-left: 0px;
    font-weight: 900;
    margin: 0;
    font-family: "Plus Jakarta Sans", sans-serif;
font-size: 15px;
line-height: 21px;
text-align: left;
margin-top: 5px;
  }

  .testdesignation {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 15px;
    font-style: italic;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }

  .testionialsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}

/* @media (max-width:578px){



    .testimonial-box1 {
        width: 250px;
        background-color: #d83333;
        padding: 20px;
        border-radius: 0 20px 20px 20px; 
        position: relative;
    }

    .testimonial-box2 {
        width: 250px;
        background-color: #d83333;
        padding: 20px;
        border-radius: 20px 0px 20px 20px; 
        position: relative;
    }
}
   */
