@media (min-width: 1400px){

    .datatitlepas{
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 400;
    line-height: 80px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
        left: 70px;
    }

    .dataparapas{
        width: 90%;
        height: fit-content;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: justify;
        position: relative;
        /* left: 70px; */
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2em;
    }

    .privsecn{
        margin-top: 5%;
        margin-left: 4%;
    }
    
    .privecimg{
        width: 1198px;
    height: 462px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    }

    .privecimgmob{
        display: none;
    }
    
    .prisecimg{
        width: 1199px;
    height: 462px;
    background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
    position: absolute;
    margin-left: 48px;
    }
    
    .prisectext1{
        width: 761px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: -4%;
    margin-left: auto;
    margin-right: auto;
    /* margin-left: 20%; */
    position: relative;
    }
    
    .prisectext2{
        width: 1030px;
    height: fit-content;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 0%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4em;
    /* margin-left: 9.5%; */
    }
    
    .prisectitle1{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 370px;
    height: 64px;
    border-radius: 32px;
    margin-top: 5em;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(232, 237, 252, 1);
    margin-bottom: 1em;
    }
    
    .prisectitle2{
        width: 761px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -2%;
    margin-bottom: 0.5em;
    }
    
    .prisectitle3{
        width: 1030px;
    height: 62px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -2%;
    }
    
    .valcirc{
        width: 92px;
    height: 92px;
    background: #D9D9D9;
    border-radius: 100%;
    margin-left: 10%;
    margin-top: 5%;
    }
    
    .prisecvalu1{
        width: fit-content;
    height: 31px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 20%;
    margin-top: -6.5%;
    }
    
    .prisecvalue1{
        width: 1030px;
    height: fit-content;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    }
    
    .valucirc{
        width: 92px;
    height: 92px;
    background: #D9D9D9;
    border-radius: 100%;
    margin-left: 7%;
    margin-top: 2%;
    }
    
    .prisecvalu2{
        width: 103px;
    height: 31px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 15%;
    margin-top: -6.5%;
    }
    
    .prisecvalue2{
        width: 1030px;
    height: 124px;
    font-family: Plus Jakarta Sans;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 7%;
    margin-top: 2%;
    }
    
    .valuecirc{
        width: 92px;
    height: 92px;
    background: #D9D9D9;
    border-radius: 100%;
    margin-left: 7%;
    margin-top: 2%;
    }
    
    .prisecvalu3{
        width: 103px;
    height: 31px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 15%;
    margin-top: -6.5%;
    }
    
    .prisecvalue3{
        width: 1030px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 7%;
    margin-top: 2%;
    }
    
    
    
    .termstartedbox{
        margin-top: 80px;
        width: 1207px;
    height: 227px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 100px;
    margin-left: 4%;
    }
    
    .termsubbutton{
        margin-left: 950px;
        margin-top: -230px;
        height: 50px;
        width: 175px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }
    
    .termstarthead{
        width: 1000px;
    height: 87px;
    margin-left: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    
    }
    
    .termregis{
        width: 90px;
    height: 19px;
        margin-top: 12px;
        margin-left: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    
    }
    
    .termstartpara{
        width: 613px;
    height: 62px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 85px;
    margin-top: -2%;
    }
    
    }
   

    @media (min-width:1228px) and (max-width:1399px){
        .privacysecuritypagemain{
            max-width:1300px;
        transform: scale(0.83);
        transform-origin: top;
        margin-bottom: -600px;
        }

        .datatitlepas{
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 45px;
        font-weight: 400;
        line-height: 80px;
        letter-spacing: 0em;
        text-align: left;
    position: relative;
        left: 70px;
        }
    
        .dataparapas{
            width: 90%;
            height: fit-content;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: justify;
    position: relative;
        left: 70px;
        }
       
        .privsecn{
            margin-top: 5%;
            margin-left: 4%;
        }

        .privecimgmob{
            display: none;
        }
        
        .privecimg{
            width: 1198px;
        height: 462px;
        position: relative;
        margin-left: 1.5%;
        }
        
        .prisecimg{
            width: 1199px;
        height: 462px;
        background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
        position: absolute;
        margin-left: 1.45%;
        margin-top: 1px;
        }
        
        .prisectext1{
            width: 761px;
        height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -4%;
        margin-left: auto;
        margin-right: auto;
        /* margin-left: 20%; */
        position: relative;
        }
        
        .prisectext2{
            width: 1030px;
        height: 62px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 0%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 8em;
        /* margin-left: 9.5%; */
        }
        
        .prisectitle1{
            align-items: center;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        position: relative;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        width: 370px;
        height: 64px;
        border-radius: 32px;
        margin-top: 5em;
        margin-left: auto;
        margin-right: auto;
        background-color: rgba(232, 237, 252, 1);
        margin-bottom: 1em;
        }
        
        .prisectitle2{
            width: 761px;
        height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: -2%;
        margin-bottom: 0.5em;
        }
        
        .prisectitle3{
            width: 1030px;
        height: 62px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: -2%;
        }
        
        .valcirc{
            width: 92px;
        height: 92px;
        background: #D9D9D9;
        border-radius: 100%;
        margin-left: 10%;
        margin-top: 5%;
        }
        
        .prisecvalu1{
            width: fit-content;
        height: 31px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 20%;
        margin-top: -6.5%;
        }
        
        .prisecvalue1{
            width: 1030px;
        height: fit-content;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: justify;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2%;
        }
        
        .valucirc{
            width: 92px;
        height: 92px;
        background: #D9D9D9;
        border-radius: 100%;
        margin-left: 7%;
        margin-top: 2%;
        }
        
        .prisecvalu2{
            width: 103px;
        height: 31px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 15%;
        margin-top: -6.5%;
        }
        
        .prisecvalue2{
            width: 1030px;
        height: 124px;
        font-family: Plus Jakarta Sans;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 7%;
        margin-top: 2%;
        }
        
        .valuecirc{
            width: 92px;
        height: 92px;
        background: #D9D9D9;
        border-radius: 100%;
        margin-left: 7%;
        margin-top: 2%;
        }
        
        .prisecvalu3{
            width: 103px;
        height: 31px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 15%;
        margin-top: -6.5%;
        }
        
        .prisecvalue3{
            width: 1030px;
        height: 124px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 7%;
        margin-top: 2%;
        }
        
        
        
        .termstartedbox{
            margin-top: 80px;
            width: 1207px;
        height: 227px;
        background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
        border-radius: 25px;
        margin-bottom: 100px;
        margin-left: 4%;
        }
        
        .termsubbutton{
            margin-left: 950px;
            margin-top: -230px;
            height: 50px;
            width: 185px;
            border-radius:30px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        background: rgb(255, 255, 255, 1);
        color: rgba(7, 60, 165, 1);
        border: solid rgba(255, 255, 255, 1);
        }
        
        .termstarthead{
            width: 1000px;
        height: 87px;
        margin-left: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 50px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        justify-content: center;
        align-items: center;
        margin-top: 3%;
        
        }
        
        .termregis{
            width: 90px;
        height: 19px;
            margin-top: 12px;
            margin-left: 50px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        
        }
        
        .termstartpara{
            width: 613px;
        height: 62px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        margin-left: 85px;
        margin-top: -2%;
        }
        
        }

     @media (min-width:1027px) and (max-width:1227.5px){
            .privacysecuritypagemain{
                max-width:1300px;
            transform: scale(0.83);
            transform-origin: top;
            margin-bottom: -600px;
            }
    
            .datatitlepas{
                font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 45px;
            font-weight: 400;
            line-height: 80px;
            letter-spacing: 0em;
            text-align: left;
        position: relative;
            left: 70px;
            }
        
            .dataparapas{
                width: 90%;
                height: fit-content;
                font-family: 'Plus Jakarta Sans', sans-serif;
                font-size: 25px;
                font-weight: 400;
                line-height: 31px;
                letter-spacing: 0em;
                text-align: justify;
        position: relative;
            left: 70px;
            }
           
            .privsecn{
                margin-top: 5%;
                margin-left: 4%;
            }
    
            .privecimgmob{
                display: none;
            }
            
            .privecimg{
                width: 100%;
            height: 462px;
            position: relative;
            }
            
            .prisecimg{
                width: 100%;
            height: 462px;
            background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
            position: absolute;
            margin-top: 1px;
            }
            
            .prisectext1{
                width: 761px;
            height: 87px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 70px;
            font-weight: 400;
            line-height: 87px;
            letter-spacing: 0em;
            text-align: center;
            margin-top: -4%;
            margin-left: auto;
            margin-right: auto;
            /* margin-left: 20%; */
            position: relative;
            }
            
            .prisectext2{
                width: 1030px;
            height: 62px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: center;
            margin-top: 0%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 8em;
            /* margin-left: 9.5%; */
            }
            
            .prisectitle1{
                align-items: center;
                font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
            position: relative;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: center;
            width: 370px;
            height: 64px;
            border-radius: 32px;
            margin-top: 5em;
            margin-left: auto;
            margin-right: auto;
            background-color: rgba(232, 237, 252, 1);
            margin-bottom: 1em;
            }
            
            .prisectitle2{
                width: 761px;
            height: 87px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 70px;
            font-weight: 400;
            line-height: 87px;
            letter-spacing: 0em;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: -2%;
            margin-bottom: 0.5em;
            }
            
            .prisectitle3{
                width: 1030px;
            height: 62px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: -2%;
            }
            
            .valcirc{
                width: 92px;
            height: 92px;
            background: #D9D9D9;
            border-radius: 100%;
            margin-left: 10%;
            margin-top: 5%;
            }
            
            .prisecvalu1{
                width: fit-content;
            height: 31px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: left;
            margin-left: 20%;
            margin-top: -6.5%;
            }
            
            .prisecvalue1{
                width: 1030px;
            height: fit-content;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: justify;
            margin-left: auto;
            margin-right: auto;
            margin-top: 2%;
            }
            
            .valucirc{
                width: 92px;
            height: 92px;
            background: #D9D9D9;
            border-radius: 100%;
            margin-left: 7%;
            margin-top: 2%;
            }
            
            .prisecvalu2{
                width: 103px;
            height: 31px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: left;
            margin-left: 15%;
            margin-top: -6.5%;
            }
            
            .prisecvalue2{
                width: 1030px;
            height: 124px;
            font-family: Plus Jakarta Sans;
            font-size: 25px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: left;
            margin-left: 7%;
            margin-top: 2%;
            }
            
            .valuecirc{
                width: 92px;
            height: 92px;
            background: #D9D9D9;
            border-radius: 100%;
            margin-left: 7%;
            margin-top: 2%;
            }
            
            .prisecvalu3{
                width: 103px;
            height: 31px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: left;
            margin-left: 15%;
            margin-top: -6.5%;
            }
            
            .prisecvalue3{
                width: 1030px;
            height: 124px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: left;
            margin-left: 7%;
            margin-top: 2%;
            }
            
            
            
            .termstartedbox{
                margin-top: 80px;
                width: 1207px;
            height: 227px;
            background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
            border-radius: 25px;
            margin-bottom: 100px;
            margin-left: 4%;
            }
            
            .termsubbutton{
                margin-left: 950px;
                margin-top: -230px;
                height: 50px;
                width: 185px;
                border-radius:30px;
                font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            background: rgb(255, 255, 255, 1);
            color: rgba(7, 60, 165, 1);
            border: solid rgba(255, 255, 255, 1);
            }
            
            .termstarthead{
                width: 1000px;
            height: 87px;
            margin-left: 40px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 50px;
            font-weight: 400;
            line-height: 87px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(255, 255, 255, 1);
            justify-content: center;
            align-items: center;
            margin-top: 3%;
            
            }
            
            .termregis{
                width: 90px;
            height: 19px;
                margin-top: 12px;
                margin-left: 50px;
                font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 15px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            
            }
            
            .termstartpara{
                width: 613px;
            height: 62px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(255, 255, 255, 1);
            margin-left: 85px;
            margin-top: -2%;
            }
        }


@media (min-width:831px) and (max-width:991.5px) {
    .prisec{
        margin-left: -16%;
    }

    .prisecimg{
        width: 889px;
    height: 462px;
    background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
    position: absolute;
    margin-top: 5%;
    margin-left: 0%;
    }

    .prisectext1{
        width: 761px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
margin-top: -1%;
margin-left: -4%;
    }

    .prisectext2{
        width: 85%;
height: 93px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 2%;
margin-bottom: 9em;
    }

    .valcirc{
        width: 92px;
        height: 92px;
        background: #D9D9D9;
        border-radius: 100%;
        margin-top: 8%;
        margin-left: 7%;
    }

    .prisecvalu1{
        width: fit-content;
        height: 31px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 22%;
        margin-top: -12%;
    }

    .prisectitle2{
        width: 761px;
        height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: -30px;
        margin-top: -2%;
    }

    .prisectitle3{
        width: 722px;
height: 93px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: 0%;
margin-top: -2%;
    }
}


@media (min-width:992px) and (max-width:1026px) {
    .prisec{
        margin-left: 0%;
    }

    .prisecimg{
        width: 889px;
    height: 462px;
    background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
    position: absolute;
    margin-top: 5%;
    margin-left: 2.4%;
    }

    .prisectext1{
        width: 761px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
margin-top: -1%;
margin-left: 10%;
    }

    .prisectext2{
        width: 85%;
height: 93px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 2%;
margin-bottom: 7em;
    }

    .valcirc{
        width: 92px;
        height: 92px;
        background: #D9D9D9;
        border-radius: 100%;
        margin-top: 3%;
        margin-left: 9%;
    }

    .prisecvalu1{
        width: fit-content;
        height: 31px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 22%;
        margin-top: -10%;
    }

    .prisectitle2{
        width: 761px;
        height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: -2%;
    }

    .prisectitle3{
        width: 722px;
height: 93px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: 12%;
margin-top: -2%;
    }
}

    
    @media (min-width:831px) and (max-width:1026px) {

        .datatitlepas{
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 45px;
        font-weight: 400;
        line-height: 80px;
        letter-spacing: 0em;
        text-align: center;
        }
    
        .dataparapas{
            width: 80%;
            height: fit-content;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: justify;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 2em;
        }

        .privecimg{
            width: 888px;
        height: 462px;
        margin-top: 5%;
        margin-left: auto;
        margin-right: auto;
        /* margin-left: 5%; */
        }

        .privecimgmob{
            display: none;
        }
        
        /* .prisecimg{
            width: 889px;
        height: 462px;
        background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
        position: absolute;
        margin-top: 5%;
        margin-left: 2.4%;
        } */
    
        /* .prisectext1{
            width: 761px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: -1%;
    margin-left: 10%;
        } */
    
        /* .prisectext2{
            width: 85%;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 7em;
        } */
    
        .prisectitle1{
            align-items: center;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        position: relative;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        width: 340px;
        height: 64px;
        border-radius: 32px;
        background-color: rgba(232, 237, 252, 1);
        margin-left: auto;
        margin-right: auto;
        margin-top: 15%;
        }
    
        /* .prisectitle2{
            width: 761px;
            height: 87px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 70px;
            font-weight: 400;
            line-height: 87px;
            letter-spacing: 0em;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: -2%;
        } */
    
        /* .prisectitle3{
            width: 722px;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 12%;
    margin-top: -2%;
        } */
    
        /* .valcirc{
            width: 92px;
            height: 92px;
            background: #D9D9D9;
            border-radius: 100%;
            margin-top: 3%;
            margin-left: 9%;
        } */
    
        /* .prisecvalu1{
            width: fit-content;
            height: 31px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: left;
            margin-left: 22%;
            margin-top: -10%;
        } */
    
        .prisecvalue1{
            width: 762px;
    height: fit-content;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 2em;
        }

        /* .prisec{
            margin-left: 0%;
        } */
    
        .valucirc{
            width: 92px;
    height: 92px;
    background: #D9D9D9;
    border-radius: 100%;
    margin-top: 3%;
    margin-left: 7%;
        }
    
        .prisecvalu2{
            width: 103px;
            height: 31px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: left;
            margin-left: 19%;
            margin-top: -10%;
        }
    
        .prisecvalue2{
            width: 762px;
    height: 186px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 8%;
    margin-top: 3%;
        }
    
        .valuecirc{
            width: 92px;
    height: 92px;
    background: #D9D9D9;
    border-radius: 100%;
    margin-top: 3%;
    margin-left: 7%;
        }
    
        .prisecvalu3{
            width: 103px;
            height: 31px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: left;
            margin-left: 19%;
            margin-top: -10%;
        }
    
        .prisecvalue3{
            width: 762px;
    height: 186px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 8%;
    margin-top: 3%;
        }
    
        .termstartedbox{
            margin-top: 130px;
            width: 737px;
        height: 252px;
        background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
        border-radius: 25px;
        margin-bottom: 15%;
        position: absolute;
        left: 50%;
        transform: translate(-50%,-50%);
        /* margin-left: 10%; */
        }
        
        .termsubbutton{
            margin-left: 300px;
            margin-top: 160px;
            position: relative;
            height: 61px;
            width: 331px;
            border-radius:30px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        background: rgb(255, 255, 255, 1);
        color: rgba(7, 60, 165, 1);
        border: solid rgba(255, 255, 255, 1);
        }
        
        .termstarthead{
            width: 473px;
        height: 40px;
        margin-left: 140px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        justify-content: center;
        align-items: center;
        margin-top: 3%;
        
        }
        
        .termregis{
            width: 146px;
        height: 30px;
            margin-top: 12px;
            margin-left: 80px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        
        }
        
        .termstartpara{
            width: 613px;
        height: 50px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        margin-left: 150px;
        margin-top: -15%;
        margin-bottom: 5em;
        position: relative;
        }
    }


@media (min-width:577px) and (max-width:767.5px) {
    .prisectext1{
        width: 761px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 55px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
position: relative;
margin-top: -10%;
margin-left: -24%;
    }

    .prisectext2{
        width: 85%;
height: 93px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 21px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 1%;
margin-bottom: 13em;
    }

    .prisectitle2{
        width: 761px;
        height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 55px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: -120px;
        margin-top: -2%;
    }

    .prisectitle3{
        width: 545px;
height: 93px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 21px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: -2%;
margin-top: -6%;
    }

    .valcirc{
        width: 92px;
        height: 92px;
        background: #D9D9D9;
        border-radius: 100%;
        margin-top: 10%;
        margin-left: 9%;
    }

    .prisecvalue1{
        width: 500px;
height: fit-content;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 21px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: justify;
margin-left: auto;
margin-right: auto;
margin-top: 3%;
margin-bottom: 2em;
    }

    .prisecvalu1{
        width: fit-content;
        height: 31px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 30%;
        margin-top: -16%;
    }

    .dataparapas{
        width: 80%;
        height: fit-content;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 21px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: justify;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2em;
        margin-top: 25px;
    }
  }


@media (min-width:768px) and (max-width:830px) {
    .prisectext1{
        width: 761px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
position: relative;
margin-top: -10%;
margin-left: -4%;
    }

    .prisectext2{
        width: 85%;
height: 93px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 1%;
margin-bottom: 10em;
    }

    .prisectitle2{
        width: 761px;
        height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: -50px;
        margin-top: -2%;
    }

    .prisectitle3{
        width: 593px;
height: 93px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: 5%;
margin-top: -2%;
    }

    .valcirc{
        width: 92px;
        height: 92px;
        background: #D9D9D9;
        border-radius: 100%;
        margin-top: 10%;
        margin-left: 9%;
    }

    .prisecvalue1{
        width: 600px;
height: fit-content;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: justify;
margin-left: auto;
margin-right: auto;
margin-top: 3%;
margin-bottom: 2em;
    }

    .prisecvalu1{
        width: fit-content;
        height: 31px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 25%;
        margin-top: -12%;
    }

    .dataparapas{
        width: 80%;
        height: fit-content;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: justify;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2em;
        margin-top: 25px;
    }
  }


    @media (min-width:577px) and (max-width:830px) {

        .datatitlepas{
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 45px;
        font-weight: 400;
        /* line-height: 80px; */
        letter-spacing: 0em;
        text-align: center;
        width: 90%;
        margin-right: auto;
        margin-left: auto;
        }
    
        /* .dataparapas{
            width: 80%;
            height: fit-content;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: justify;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 2em;
            margin-top: 25px;
        } */

        .privecimg{
            width: 90%;
        height: 430px;
        margin-top: 5%;
        margin-left: auto;
        margin-right: auto;
        /* margin-left: 5%; */
        }

        .privecimgmob{
            display: none;
        }
        
        .prisecimg{
            width: 90%;
        height: 430px;
        background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
        position: absolute;
        margin-top: 5%;
        margin-left: 2.4%;
        /* margin-left: auto;
        margin-right: auto; */
        }
    
        /* .prisectext1{
            width: 761px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-top: -10%;
    margin-left: -4%;
        } */
    
        /* .prisectext2{
            width: 85%;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    margin-bottom: 10em;
        } */
    
        .prisectitle1{
            align-items: center;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        position: relative;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        width: 340px;
        height: 64px;
        border-radius: 32px;
        background-color: rgba(232, 237, 252, 1);
        margin-left: auto;
        margin-right: auto;
        margin-top: 15%;
        }
    
        /* .prisectitle2{
            width: 761px;
            height: 87px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 70px;
            font-weight: 400;
            line-height: 87px;
            letter-spacing: 0em;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: -2%;
        } */
    
        /* .prisectitle3{
            width: 722px;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 12%;
    margin-top: -2%;
        } */
    
        /* .valcirc{
            width: 92px;
            height: 92px;
            background: #D9D9D9;
            border-radius: 100%;
            margin-top: 3%;
            margin-left: 9%;
        } */
    
        /* .prisecvalu1{
            width: fit-content;
            height: 31px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: left;
            margin-left: 22%;
            margin-top: -10%;
        } */
    
        /* .prisecvalue1{
            width: 762px;
    height: fit-content;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 2em;
        } */

        .prisec{
            margin-left: 0%;
        }
    
        .valucirc{
            width: 92px;
    height: 92px;
    background: #D9D9D9;
    border-radius: 100%;
    margin-top: 3%;
    margin-left: 7%;
        }
    
        .prisecvalu2{
            width: 103px;
            height: 31px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: left;
            margin-left: 19%;
            margin-top: -10%;
        }
    
        .prisecvalue2{
            width: 762px;
    height: 186px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 8%;
    margin-top: 3%;
        }
    
        .valuecirc{
            width: 92px;
    height: 92px;
    background: #D9D9D9;
    border-radius: 100%;
    margin-top: 3%;
    margin-left: 7%;
        }
    
        .prisecvalu3{
            width: 103px;
            height: 31px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: left;
            margin-left: 19%;
            margin-top: -10%;
        }
    
        .prisecvalue3{
            width: 762px;
    height: 186px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 8%;
    margin-top: 3%;
        }
    
        .termstartedbox{
            margin-top: 130px;
            width: 737px;
        height: 252px;
        background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
        border-radius: 25px;
        margin-bottom: 15%;
        position: absolute;
        left: 50%;
        transform: translate(-50%,-50%);
        /* margin-left: 10%; */
        }
        
        .termsubbutton{
            margin-left: 300px;
            margin-top: 160px;
            position: relative;
            height: 61px;
            width: 331px;
            border-radius:30px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        background: rgb(255, 255, 255, 1);
        color: rgba(7, 60, 165, 1);
        border: solid rgba(255, 255, 255, 1);
        }
        
        .termstarthead{
            width: 473px;
        height: 40px;
        margin-left: 140px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        justify-content: center;
        align-items: center;
        margin-top: 3%;
        
        }
        
        .termregis{
            width: 146px;
        height: 30px;
            margin-top: 12px;
            margin-left: 80px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        
        }
        
        .termstartpara{
            width: 613px;
        height: 50px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        margin-left: 150px;
        margin-top: -15%;
        margin-bottom: 5em;
        position: relative;
        }
    }
    
    @media (max-width: 576px) {

        .datatitlepas{
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        }
    
        .dataparapas{
            width: 80%;
            height: fit-content;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: justify;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 2em;
        }
    

        .prisecstartedm{
            margin-bottom: 10%;
        }
        .privecimg{
            display: none;
        }

        .privecimgmob{
           
            width: 90%;
            height: 293px;
            margin-top: 5%;
            margin-left: auto;
            margin-right: auto;
            /* margin-left: 5%; */
        }
        
        .prisecimg{
            width: 90%;
        height: 293px;
        background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
        position: absolute;
        margin-top: 5%;
        /* margin-left: 4.7%; */
        }
    
        .prisectext1{
            width: 211px;
    height: 31px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-top: -7%;
    margin-left: 23%;
        }
    
        .prisectext2{
            /* width: 370px; */
            width: 91%;
    height: 60px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8em;
        }
    
        .prisectitle1{
            align-items: center;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 12px;
        position: relative;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
        width: 165px;
        height: 34px;
        border-radius: 32px;
        background-color: rgba(232, 237, 252, 1);
        margin-left: auto;
        margin-right: auto;
        /* margin-left: 35%; */
        margin-top: 13%;
        }
    
        .prisectitle2{
            width: 191px;
            height: 31px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 25px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            /* margin-left: 25%; */
            margin-top: 0%;
            margin-bottom: 1em;
        }
    
        .prisectitle3{
            width: 358px;
    height: 60px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 5%;
    margin-top: -9%;
    margin-bottom: 20px;
        }
    
        .valcirc{
            width: 50px;
            height: 50px;
            background: #D9D9D9;
            border-radius: 100%;
            margin-left: 9%;
        }
    
        .prisecvalu1{
            width: fit-content;
            height: 19px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 15px;
            font-weight: 400;
            line-height: 18.6px;
            letter-spacing: 0em;
            text-align: left;
            margin-left: 25%;
            margin-top: -14%;
        }
    
        .prisecvalue1{
            width: 310px;
    height: fit-content;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0%;
    margin-bottom: 3em;
        }
    
        .valucirc{
            width: 50px;
    height: 50px;
    background: #D9D9D9;
    border-radius: 100%;
    margin-left: 7%;
        }
    
        .prisecvalu2{
            width: 62px;
            height: 19px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 15px;
            font-weight: 400;
            line-height: 18.6px;
            letter-spacing: 0em;
            text-align: left;
            margin-left: 22%;
            margin-top: -14%;
        }
    
        .prisecvaluem2{
            width: 310px;
    height: 60px;
    font-family: Plus Jakarta Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 7%;
    margin-top: 0%;
        }
    
        .valuecirc{
            width: 50px;
    height: 50px;
    background: #D9D9D9;
    border-radius: 100%;
    margin-left: 7%;
        }
    
        .prisecvalu3{
            width: 62px;
            height: 19px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 15px;
            font-weight: 400;
            line-height: 18.6px;
            letter-spacing: 0em;
            text-align: left;
            margin-left: 22%;
            margin-top: -14%;
        }
    
        .prisecvaluem3{
            width: 310px;
    height: 60px;
    font-family: Plus Jakarta Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 7%;
    margin-top: -18%;
        }
    
        .prisecstartedboxm{
            width: 335px;
        height: 199px;
        background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
        border-radius: 25px;
        margin-left: 8%;
        margin-top: 5%;
        margin-bottom: 10%;
        }
    
        .prisecsubbuttonm{
            width: 155px;
            height: 42px;
            border-radius:37.5px;
            font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #073CA5;
    margin-top: -25%;
    margin-left: 30%;
    border: #FFFFFF;
        }
    
        .prisecstartheadm{
            width: max-content;
        height: 31px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        justify-content: center;
        align-items: center;
        /* margin-left: 20%; */
        margin-top: 8%;
        }
    
        .prisecregism{
            width: 86px;
        height: 19px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 5%;
        }    
    
        .prisecstartparam{
            width: 253px;
        height: 30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: 18%;
        margin-top: -22%;
        color: #FFFFFF;
        margin-bottom: -10%;
        }
    }