.blogc1{
    width: 69px;
height: 69px;
background: rgb(255,255,255,1);
border-radius: 100%;
position: relative;
margin-top: 28%;
margin-left: -91%;
}
.prisecblanding{
    margin-left: 11%;
}

.bloglhead{
    margin-left: 7%;
}

.blogc1text{
    width: 156px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 700;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
position: relative;
margin-top: 29.5%;
margin-left: 2%;
}

.bloglhead1{
    width: 1035px;
height: 124px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 50px;
font-weight: 400;
line-height: 62px;
letter-spacing: 0em;
text-align: left;
position: relative;
margin-left: 9.5%;
margin-top: 1%;
}

.bloglheadpara1{
    width: 1035px;
height: 186px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: left;
position: relative;
margin-left: 9.5%;
margin-top: 2%;
}

.readmorebutton{
    width: 173px;
height: 60px;
border-radius: 37.5px;
border: none;
background-color: rgba(7, 60, 165, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
position: relative;
margin-left: 10%;
margin-top: 2%;
}

.showmorebutton{
    width: 173px;
height: 60px;
border-radius: 37.5px;
border: none;
background-color: rgba(7, 60, 165, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
position: relative;
margin-left: 43%;
margin-top: 2%;
}

.hr1blogl{
    width: 1206px;
position: relative;
border: 1px;
margin-top: 5%;
margin-left: 2.5%;
border: 1px solid rgba(0, 0, 0, 1);
}

.hr2blogl{
    width: 330px;
position: relative;
border: 1px;
/* margin-top: 5%;
margin-left: 2.5%; */
border: 1px solid rgba(0, 0, 0, 1);
}

.cardbldimensions{
    margin-left: 30%;
    width: 343px;
    height: 600px;
    margin-top: 10%;
    border: none;
}

.bltitle{
    width: 343px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 500;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
position: relative;
color: rgba(17, 17, 17, 1);
}

.bltitlepara{
    width: 343px;
height: 76px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
position: relative;
/* color: rgba(17, 17, 17, 1); */
}

.bloglc{
    width: 41px;
height: 41px;
background: rgba(217, 217, 217, 1);
border-radius: 100%;
/* position: relative; */
margin-top: -5%;
margin-left: 0%;
}

.bloglctext{
    width: 105px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 700;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
margin-left: 18%;
margin-top: -10%;
}

.Blogstartedbox{
    width: 1207px;
height: 227px;
background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
border-radius: 25px;
position: relative;
margin-left: 13%;
margin-top: 12%;
}

.Blogstartedhead{
    width: 644px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);
position: relative;
margin-top: 2.5%;
margin-left: 5%;
}

.Blogstartedpara{
    width: 613px;
height: 62px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);
position: relative;
margin-top: 10%;
margin-left: -53%;

}

.Blogstartedbutton{
    width: 173px;
height: 60px;
border-radius: 37.5px;
border: none;
background-color: rgba(255,255,255,1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
color: rgba(7, 60, 165, 1);
position: relative;
margin-left: 17%;
margin-top: 7%;

}

@media (max-width: 768px){

    .Blogstartedbutton{
        width: 137px;
height: 42px;
border-radius: 37.5px;
background-color:  rgba(255,255,255,1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
color: rgba(7, 60, 165, 1);
position: relative;
margin-top: 35%;
margin-left: -60%;

    }

    .Blogstartedpara{
        width: 253px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
position: relative;
margin-top: 20%;
margin-left: -80%;
    }

    .Blogstartedhead{
        width: 295px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-left: 5%;
margin-top: 8%;
position: relative;
    }

    .Blogstartedbox{
        width: 335px;
height: 199px;
background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
border-radius: 25px;
/* position: relative; */

    }

    .bloglc{
        width: 43px;
height: 43px;
background: rgba(217, 217, 217, 1);
border-radius: 100%;
margin-top: -165%;
margin-left: 0%;

    }

    .bloglctext{
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 700;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
margin-top: -12%;
margin-left: 20%;

    }



    .bltitlepara{
        width: 309px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: left;
margin-left: -5%;
    }

    .bltitle{
        width: 257px;
height: 50px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 500;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
position: relative;
color: rgba(17, 17, 17, 1);
margin-top: -33%;
margin-bottom: 13%;


    }

    .cardbldimensions{
        margin-left: 14%;
        width: 309px;
        height: 368px;
        margin-top: 12%;
        border: none;
    }

    .hr1blogl{
        width: 344px;
border: 1px;
position: relative;
border: 1px solid rgba(0, 0, 0, 1);
margin-left: 5%;
margin-top: 15%;
    }

    .showmorebutton{
        width: 137px;
height: 42px;
border-radius: 37.5px;
background-color:  rgba(7, 60, 165, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
position: relative;
margin-top: 0%;
margin-left: 34%;
    }


    .readmorebutton{
        width: 137px;
height: 42px;
border-radius: 37.5px;
background-color:  rgba(7, 60, 165, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
position: relative;
margin-top: 0%;
    }

    .bloglheadpara1{
        width: 358px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: left;
position: relative;

    }

    .bloglhead1{
        width: 335px;
height: 50px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 500;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
position: relative;
margin-top: -3%;



    }
    .blogc1{
        width: 43px;
height: 43px;
background: rgba(217, 217, 217, 1);
margin-top: 50%;
    }

    .blogc1text{
        width: 105px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 700;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
margin-top: 52.5%;
    }

    .prisecblanding{
        border: none;
    }
}