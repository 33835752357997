.locsize{
  width: 295px;
height: 60px;
border-radius: 5px;
}

@media (min-width:577px) and (max-width:830px){
  .locsize{
    width: 603px;
height: 60px;
  border-radius: 5px;
}
}

@media (max-width:576px){
  .locsize{
      width: 285px;
    height: 44px;
    border-radius: 5px;
  }
}