@media (min-width: 1400px){
    .partbluformbox{
        width: 1233px;
    height: 846px;
    border-radius: 40px;
    background: #1A1A82;
    margin-top: 10%;
    margin-bottom: 10%;
    }
    
    .partgreyformbox{
        width: 762px;
    height: 846px;
    position: relative;
    margin-left: auto;
    border-radius: 40px;   
    border: 1px solid #073CA5;
    background: linear-gradient(180deg, #FFFFFF 0%, #C5C5C5 100%);
    }

    .parterformbac{
        width: 257px;
height: 347px;
background: #CC0530;
border-top-left-radius: 100%;
margin-top: -63%;
margin-left: 9%;
position: relative;
    }

    .parterformimg{
        width: 270px;
height: 424px;
margin-top: -35.5%;
margin-left: 8%;
position: relative;
    }

    .parformtit{
        width: 310px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    margin-left: 5%;
    margin-top: 1%;
    color: #FFFFFF;
    position: relative;
    }
    
    .partformpara{
        width: 337px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 5%;
    margin-top: 2%;
    position: relative;
    margin-bottom: 16.5%;
    }

.partlogcontent {
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin-top: -56%;
    margin-left: 40%; 
  }    
  .partlogsbutton {
    display: flex;
    margin-left: 45%;
    margin-top: 40px; 
  }

  :where(.css-dev-only-do-not-override-1drr2mu).ant-form-item .ant-form-item-label >label {
    font-size: 20px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
  }

  /* .titfrmtrp{
    align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 250px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    position: relative;
    left: 530px;
    top: -915px;
  } */

}

@media (min-width:1228px) and (max-width:1399px){
    .parterformbac{
        width: 257px;
height: 347px;
background: #CC0530;
border-top-left-radius: 100%;
margin-top: -63%;
margin-left: 9%;
position: absolute;
    }

    .parterformimg{
        width: 270px;
height: 424px;
margin-top: -104.2%;
margin-left: 8%;
position: relative;
    }

    .parformtit{
        width: 310px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    margin-left: 5%;
    margin-top: 1%;
    color: #FFFFFF;
    position: relative;
    }
    
    .partformpara{
        width: 337px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 5%;
    margin-top: 2%;
    position: relative;
    /* margin-bottom: 16.5%; */
    }

  .formpartext{
    position: relative;
    top: -20px;
    left: -20px;
    width: fit-content;
    /* margin-bottom: -50%; */
}

.partregisform{
    position: relative;
    top: 0px;
    left: -50px;
    margin-bottom: -30%;
}

/* .titfrmtrp{
    align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 250px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    position: relative;
    left: 530px;
    top: -675px;
  } */

}



@media (min-width:1028px) and (max-width:1226px){
    .parterformbac{
        width: 257px;
height: 347px;
background: #CC0530;
border-top-left-radius: 100%;
margin-top: -63%;
margin-left: 9%;
position: absolute;
    }

    .parterformimg{
        width: 270px;
height: 424px;
margin-top: -104.2%;
margin-left: 8%;
position: relative;
    }

    .parformtit{
        width: 310px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    margin-left: 5%;
    margin-top: 1%;
    color: #FFFFFF;
    position: relative;
    }
    
    .partformpara{
        width: 337px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 5%;
    margin-top: 2%;
    position: relative;
    /* margin-bottom: 16.5%; */
    }

  .formpartext{
    position: relative;
    top: -20px;
    left: -50px;
    width: fit-content;
    /* margin-bottom: -50%; */
}

.partregisform{
    position: relative;
    top: 0px;
    left: -50px;
    margin-bottom: -30%;
}

/* .titfrmtrp{
    align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 250px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    position: relative;
    left: 530px;
    top: -675px;
  } */

}

@media (min-width:1001px) and (max-width:1027px){
    .parterformbac{
        width: 257px;
height: 347px;
background: #CC0530;
border-top-left-radius: 100%;
margin-top: -63%;
margin-left: 9%;
position: absolute;
    }

    .parterformimg{
        width: 270px;
height: 424px;
margin-top: -78.5%;
margin-left: 8%;
position: relative;
    }

    .parformtit{
        width: 310px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    margin-left: 5%;
    margin-top: 1%;
    color: #FFFFFF;
    position: relative;
    }
    
    .partformpara{
        width: 337px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 5%;
    margin-top: 2%;
    position: relative;
    margin-bottom: -35%;
    }

  .formpartext{
    position: relative;
    width: 500px;
    top: -180px;
    left: -150px;
}

.partregisform{
    position: relative;
    width: 500px;
    top: -500px;
    left: -140px;
    margin-bottom: -30%;
}

}

@media (min-width:831px) and (max-width:1000px){
    .parterformbac{
        width: 257px;
height: 347px;
background: #CC0530;
border-top-left-radius: 100%;
margin-top: -63%;
margin-left: 9%;
position: absolute;
    }

    .parterformimg{
        width: 270px;
height: 424px;
margin-top: -78.5%;
margin-left: 8%;
position: relative;
    }

    .parformtit{
        width: 310px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    margin-left: 5%;
    margin-top: 1%;
    color: #FFFFFF;
    position: relative;
    }
    
    .partformpara{
        width: 337px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 5%;
    margin-top: 2%;
    position: relative;
    margin-bottom: -35%;
    }

  .formpartext{
    position: relative;
    width: 500px;
    top: -240px;
    left: -200px;
}

.partregisform{
    position: relative;
    width: 500px;
    top: -480px;
    left: -200px;
    margin-bottom: -30%;
}

/* .titfrmtrp{
    align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 250px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    position: relative;
    left: 530px;
    top: -675px;
  } */

}

@media (min-width:577px) and (max-width:830px){
    .partregisform{
        position: absolute;
        top: 110px;
        left: 180px;
        transform: scale(1.4);
    transform-origin: top;
    width: 200px;
    height: 200px;
    }

    .parterformimg{
        width: 159px;
        height: 250px;
        top: -175px;
        left: 0px;
        position: relative;
    }

    .parterformbac{
        width: 152px;
height: 205px;
background: #CC0530;
border-top-left-radius: 100%;
position: relative;
top: 75px;
    }

    .parformtit{
        width: 194px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
color: #FFFFFF
    }

    .partformpara{
        width: 400px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
color: #FFFFFF;
    }

    .formpartext{
        position: absolute;
        top: 510px;
        left: 140px;
        margin-bottom: -140%;
    }

    .titfrmtrp{
        display: none !important;
    }

}

@media (min-width:501px) and (max-width:576px){ 
    .partregisform{
        position: relative;
        top: -1470px;
        left: 40px;
    }

    .parterformimg{
        width: 159px;
        height: 250px;
        top: -175px;
        left: 0px;
        position: relative;
    }

    .parterformbac{
        width: 152px;
height: 205px;
background: #CC0530;
border-top-left-radius: 100%;
position: relative;
top: 75px;
    }

    .parformtit{
        width: 194px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
color: #FFFFFF
    }

    .partformpara{
        width: 250px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
color: #FFFFFF;
    }

    .formpartext{
        position: relative;
        top: -1630px;
        left: 30px;
        margin-bottom: -130%;
    }

    .titfrmtrp{
        display: none !important;
    }
}

@media (max-width:500px){
    .partregisform{
        position: relative;
        top: -1310px;
        left: 40px;
    }

    .parterformimg{
        width: 159px;
        height: 250px;
        top: -175px;
        left: 0px;
        position: relative;
    }

    .parterformbac{
        width: 152px;
height: 205px;
background: #CC0530;
border-top-left-radius: 100%;
position: relative;
top: 75px;
    }

    .parformtit{
        width: 194px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
color: #FFFFFF
    }

    .partformpara{
        width: 250px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
color: #FFFFFF;
    }

    .formpartext{
        position: relative;
        top: -1470px;
        left: 30px;
        margin-bottom: -140%;
    }

    .titfrmtrp{
        display: none !important;
    }

}
