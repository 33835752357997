.UnlockContainer {
    display: grid;
    grid-template-areas: 
      "feature1 feature2 feature4"
      "feature3 feature3 feature3";
    grid-gap: 18px;
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .Feature1 {
    grid-area: feature1;
    cursor: pointer;
  }
  
  .Feature2 {
    grid-area: feature2;
    cursor: pointer;
    height: 231px;
  }
  
  .Feature3 {
    grid-area: feature3;
    display: flex;
    flex-direction: column;
    top: -230px;
    position: relative;
    left: 330px;
    width: 768px;
    cursor: pointer;
  }
  
  .Feature4 {
    grid-area: feature4;
    cursor: pointer;
    height: 231px;
  }


  .Feature1img{
    width: 312px;
    height: 462px;
    border-radius: 50px;
  }

  .Feature1imgbg{
    width: 312px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    position: absolute;
    display: block;
    opacity: 45%;
    margin-top: -462px;
  }



  .Feature2img{
    width: 513px;
    height: 231px;
    border-radius: 50px;
  }

  .Feature2imgbg{
    width: 513px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    display: block;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    margin-top: -231px;
  }

  .Feature3img{
    width: 768px;
    height: 212px;
    border-radius: 50px;
  }

  .Feature3imgbg{
    width: 768px;
    height: 212px;
    border-radius: 50px;
    opacity: 45%;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    position: absolute;
    display: block;
  }

  .Feature4img{
    width: 231px;
    height: 231px;
    border-radius: 50px;
  }

  .Feature4imgbg{
    width: 231px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    position: absolute;
    display: block;
    margin-top: -231px;
  }

  .Feature1Clickimg{
    /* position: absolute; */
    /* display: inline-block;  */
    
    margin-left: 10px;

  }



  .Feature1header{
    position: absolute;
    display: flex;
    width: 256px;
    height: 84px;
    font-size: 35px;
    font-weight: 900;
    line-height: 42.36px;
    color: rgba(255, 255, 255, 1);
    margin-top: -140px;
    margin-left: 30px;
    align-items: center;
  }

  .Feature2header{
    position: absolute;
    display: flex;
    margin-top: -75px;
    margin-left: 20px;
    width: 470px;
    height: 42px;
    font-size: 35px;
    font-weight: 900;
    line-height: 42.36px;
    color: rgba(255, 255, 255, 1);
    align-items: center;
  }

  .Feature3header{
    position: absolute;
    display: flex;
    width: 388px;
    height: 84px;
    font-size: 35px;
    font-weight: 900;
    line-height: 42.36px;
    color: rgba(255, 255, 255, 1);
    margin-top: 120px;
    margin-left: 30px;
    align-items: center;
  }

  .Feature4header{
    position: absolute;
    display: flex;
    width: 200px;
    height: 42px;
    font-size: 35px;
    font-weight: 900;
    line-height: 42.36px;
    color: rgba(255, 255, 255, 1);
    margin-top: -105px;
    margin-left: 9px;
    align-items: center;
  }


.ColoredBox {
    width: 100px;
    height: 100px;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 1;
}


.Feature1Box {
    width: 1100px;
    height: 462px;
    background-color: white;
    display: flex;
    flex-direction: row;
    position: absolute;
    margin-top: -462px;
    z-index: 2;
}

.Feature2Box {
    width: 1100px;
    height: 462px;
    background-color: white;
    display: flex;
    flex-direction: row;
    position: absolute;
    margin-top: -231px;
    margin-left: -330px;
    z-index: 2;
}

.Feature3Box {
    width: 1100px;
    height: 462px;
    background-color: white;
    display: flex;
    flex-direction: row;
    position: absolute;
    margin-top: -250px;
    margin-left: -330px;
    z-index: 2;
}

.Feature4Box {
    width: 1100px;
    height: 462px;
    background-color: white;
    display: flex;
    flex-direction: row;
    position: absolute;
    margin-top: -231px;
    margin-left: -862px;
    z-index: 2;
}

.Feature1BoxHS{
    margin-top: 45px;
    margin-left: 45px;
}

.Feature1Boxheader{
font-size: 45px;
font-weight: 900;
line-height: 54.46px;
color: rgba(255, 255, 255, 1);
}

.Feature1BoxSubheader{
width: 90%;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: justify;
color: rgba(255, 255, 255, 1);
margin-top: 20px;
}

.F1Section1 {
    width: 83%;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
border-radius: 50px;
}
.F2Section1 {
    width: 83%;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
border-radius: 50px;
}
.F3Section1 {
    width: 83%;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
border-radius: 50px;
}
.F4Section1 {
    width: 83%;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
border-radius: 50px;
}

.F1Section2 {
    width: 18%; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}


.F1SubSection1img{
    width: 141px;
height: 145px;
border-radius: 20px;
}

.F1SubSection1imgbg{
    width: 141px;
height: 145px;
background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
position: absolute;
opacity: 45%;
border-radius: 20px;
margin-left: -141px;
}

.F2SubSection1imgbg{
    width: 141px;
    height: 145px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    position: absolute;
    opacity: 45%;
    border-radius: 20px;
    margin-left: -141px;
}

.F2SubSection2imgbg{
    width: 141px;
    height: 145px;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    position: absolute;
    opacity: 45%;
    border-radius: 20px;
    margin-left: -141px;
}

.F2SubSection3imgbg{
    width: 141px;
    height: 145px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    position: absolute;
    opacity: 45%;
    border-radius: 20px;
    margin-left: -141px;
}

.F3SubSection1imgbg{
    width: 141px;
    height: 145px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    position: absolute;
    opacity: 45%;
    border-radius: 20px;
    margin-left: -141px;
}

.F3SubSection2imgbg{
    width: 141px;
    height: 145px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    position: absolute;
    opacity: 45%;
    border-radius: 20px;
    margin-left: -141px;
}

.F3SubSection3imgbg{
    width: 141px;
    height: 145px;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    position: absolute;
    opacity: 45%;
    border-radius: 20px;
    margin-left: -141px;
}


.F4SubSection1imgbg{
    width: 141px;
    height: 145px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    position: absolute;
    opacity: 45%;
    border-radius: 20px;
    margin-left: -141px;
}

.F4SubSection2imgbg{
    width: 141px;
    height: 145px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    position: absolute;
    opacity: 45%;
    border-radius: 20px;
    margin-left: -141px;
}

.F4SubSection3imgbg{
    width: 141px;
    height: 145px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    position: absolute;
    opacity: 45%;
    border-radius: 20px;
    margin-left: -141px;
}


.F1SubSection1Click{
    position: absolute;
    margin-left: -50px;
    margin-top: 90px;
}

.F1SubSection2imgbg{
    width: 141px;
height: 145px;
background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
position: absolute;
opacity: 45%;
border-radius: 20px;
margin-left: -141px;
}

.F1SubSection3imgbg{
    width: 141px;
height: 145px;
background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
position: absolute;
opacity: 45%;
border-radius: 20px;
margin-left: -141px;
}


@media (max-width:1025px){
    .UnlockContainer{
        display: none !important;
    }
}
  