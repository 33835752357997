.blogbuttonaboutus{
    color: white;
    text-decoration: none;
}

.aboutimage a {
    text-decoration: none;
  }

.Aboutuscorevaluessection{
    margin-top: 45px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
    flex-direction: column;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-bottom: 30px;
}
.Aboutuscorevalueheader{
    width: 350px;
height: 64px; 
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
}

.Aboutuscorevaluesubheader{
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: center;
}

.Aboutuscorevaluepara{
    width: 90%;
    height: 62px;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;

}
.auscon {
    position: relative;
    width: 100%;
    height: 702px;
  }
  
  .auimg, .auconimg,.auimgmob {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .auimg {
    width: 1198px;
    height: 462px;
    margin-top: 20px;
  }
  
  .auconimg {
    background: linear-gradient(360deg, #ffffff 6.1%, rgba(255, 255, 255, 0) 88.1%);
    width: 1200px;
    height: 462px;
    margin-top: 20px;
  }
  
  .auimgmob {
    display: none;
  }
  
  .AboutUsTitleHeader {
    position: relative;
    margin-top: -300px; 
    text-align: center; 
    /* justify-content: center; */
    align-items: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
  }
  
  .Aboutusheader{
    font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: center;
font-family: 'Plus Jakarta Sans', sans-serif;

  }


  .Aboutushedcontent{
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
width: 80%;
margin-top: 10px;
  }

  @media (max-width:1024px){
    .auimg{
        width: 80%;
        height: 462px;
    }
    .auconimg{
        width: 80.5%;
        height: 464px;
    }

    .AboutUsTitleHeader{
        margin-top: -300px;
    }

  } 
  @media (max-width: 576px){


  }



@media(min-width:1400px){

    .aboutusstartedbox{
        width: 1207px;
height: 227px;
border-radius: 25px;
background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
margin-bottom: 8%;  
/* position: relative; */
/* left: 50%;
    transform: translate(-50%, -50%);  */
margin-left: 2%;
margin-top: 10%;
}



    .Aboutustartedhead{
        width: 1000px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 50px;
font-weight: 400;
line-height: 86.8px;
text-align: left;
color: rgba(255, 255, 255, 1);
margin-left: 6%;
margin-top: 2%;
    }

    .Aboutustartedpara{
        width: 613px;
height: 62px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
color: rgba(255, 255, 255, 1);
margin-top: 10%;
margin-left: -82.5%;

    }

    .Aboutustartedbutton{
        width: 173px;
height: 60px;
border-radius: 37.5px;
background: rgba(255, 255, 255, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: center;
border: none;
color: rgba(7, 60, 165, 1);
margin-left: 20%;
margin-top: 7%;

    }


    .thisisformob{
        display: none;
    }
    .about2circleheadpara{
        width: 1130px;
height: 62px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
margin-top: 2%;
margin-left: 6%;

    }

    .aboutustitleheadermob{
        display: none;
    }

    .aboutcoreheaderparamob{
        display: none !important;
    }

    .about2circlehead{
        width: 490px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
margin-top: -55%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: 6%;
    }

    .about2circleheader{
        width: 761px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: left;
margin-left: -15%;
margin-top: -50%;
    }
    /* .aboutuscon{
        margin-top: 1%;
        margin-left: 4%;
    } */
    
    .aboutusimg{
        width: 1198px;
    height: 462px;
    position: relative;
    left: 25px;
    }
    
    .aboutusimgmob{
        display: none;
    }
    .aboutusconimg{
        width: 1198px;
    height: 462px;
    left: 51px;
    background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
    position: absolute;
    }
    
    .aboutustitlehead{
        width: 761px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 86.8px;
    text-align: center;
    margin-left: 0%;
    margin-top: 60%;
    }

    .aboutustitleheader{
        width: 1030px;
height: 62px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-top: 80%;
/* margin-left: -137%; */
    }

    .aboutacoreheader{
        width: 350px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
margin-left: 73%;
margin-top: 5%;
    }

    .aboutcoreheadertitle{
        width: 761px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: center;
margin-top: 2%;
margin-left: 38%;



    }

    .aboutcoreheaderpara{
        width: 1030px;
height: 62px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: 19%;
margin-top: 4%;

    }

    .aboutus2corecircle{
        width: 92px;
height: 92px;
background: rgba(217, 217, 217, 1);
border-radius: 100%;
margin-left: 10%;
margin-top: 12%;

    }

    .aboutus2corecirclepara{
        width: fit-content;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
color: rgba(17, 17, 17, 1);
margin-left: 38%;
margin-top: -15%;

    }

    .about2imagebox1{
        width: 327px;
height: 448px;
border-radius: 25px;
opacity: 0.9px;
margin-top: 10%;
margin-left: 15%;
    }

    
    .about2imagebox2{
        width: 327px;
height: 448px;
border-radius: 25px;
opacity: 0.9px;
margin-top: 10%;
margin-left: 8%;
    }

    
    .about2imagebox3{
        width: 327px;
height: 448px;
border-radius: 25px;
opacity: 0.9px;
margin-top: 10%;
margin-left: 0%;
    }



    .insideabout2image1{
        width: 295px;
height: 88px;
border-radius: 15px;
opacity: 80%;
background: rgb(255,255,255,1);
margin-top: -30%;
margin-left:18%;
    }

    .linkediniconabout21{
        width: 50px;
height: 50px;
margin-top: 6.5%;
    }

    .himanshuabout21{
        width: 100%;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 700;
line-height: 31px;
text-align: left;
margin-left: 16%;
margin-top: -17%;
    }

    .designationabout21{
        width: 100%;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: left;
margin-left: 16.2%;
margin-top: -2%;
    }

    .signinabout21{
        width: 60px;
        height: 60px;
        position: relative;
        margin-top: -50%;
        margin-left: 65%;
    }


    .insideabout2image2{
        width: 295px;
height: 88px;
border-radius: 15px;
opacity: 80%;
background: rgb(255,255,255,1);
margin-top: -29.5%;
margin-left: 12%;
    }

    .linkediniconabout22{
        width: 50px;
height: 50px;
margin-top: 6.5%;
    }

    .harshaabout22{
        width: 160px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 700;
line-height: 31px;
text-align: left;
margin-left: 18%;
margin-top: -17%;
    }

    .designationabout22{
        width: 160px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: left;
margin-left: 18.2%;
margin-top: -2%;
    }

    .signinabout22{
        width: 60px;
        height: 60px;
        position: relative;
        margin-top: -50%;
        margin-left: 60%;


    }

    .insideabout2image3{
        width: 295px;
height: 88px;
border-radius: 15px;
opacity: 80%;
background: rgb(255,255,255,1);
margin-top: -29.5%;
margin-left: 4%;
    }

    .linkediniconabout23{
        width: 50px;
height: 50px;
margin-top: 6.5%;
    }

    .prakashabout23{
        width: 248px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 700;
line-height: 31px;
text-align: left;
margin-left: 15%;
margin-top: -17%;
    }

    .designationabout23{
        width: 160px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: left;
margin-left: 15.2%;
margin-top: -2%;
    }

    .signinabout23{
        width: 60px;
        height: 60px;
        position: relative;
        margin-top: -50%;
        margin-left: 53%;


    }

    .Aboutusblogcardlap{
        margin-left: -6%;
    }




    .about2pinkcircle{
        width: 673px;
height: 673px;
background: rgba(247, 91, 2, 0.1);
border-radius: 100%;
margin-left: -30%;
margin-top: 10%;
    }

    
/* //hjdcbjh */

.aboutstite1{
    align-items: center;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
position: relative;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
width: 234px;
height: 64px;
border-radius: 32px;
background: #E8EDFC;
align-items: left;
margin-left: 20%;
margin-top: 15%;
}

.aboutparas1{
    width: 761px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: left;
margin-left: -11%;
margin-top: 0%;
}

.aboutingblog{
    width: 536px;
height: 371px;
border-radius: 25;   
}

.aboutlcblog{
    width: 69px;
height: 69px;
border-radius: 100%;
background: #FFFFFF;
margin-top: -130px;
position: absolute;
}

.aboutauthblog{
    width: 136px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 700;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
margin-top: -110px;
margin-left: 80px;
}

.aboutlat{
    width: 173px;
height: 60px;
border-radius: 0 0 37px 37px;
background: #252525;
margin-top: -395px;
margin-left: 300px;
position: absolute;
}

.aboutlate{
    width: 110px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
color: #FFFFFF;
margin-left: -5%;
margin-top: -2%;
}

.aboutmaiblog{
    margin-left: 13%;
    margin-top: 14%;
}

.aboutbuilblog{
    width: 484px;
height: 90px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 36px;
font-weight: 500;
line-height: 45px;
letter-spacing: 0em;
text-align: left;
margin-left: -28%;
margin-top: 20%;
}

.aboutbuildblog{
    width: 484px;
height: 155px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: left;
margin-left: -28%;
margin-top: -2%;
}

.aboutreadmore{
    width: 173px;
    height: 60px;
    border-radius: 37.5px;
    border: none;
    background-color: rgba(7, 60, 165, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    margin-top: 8px;
    margin-left: -140px;
}


/* shcnakjinca */

.abthr2blogl{
    width: 300px;
border: 1px solid #000000;
margin-left: -23px;
}

.abtcardbldimensions{
    width: 343px;
    height: 600px;
    border: none;
    margin-left: 10%;
}

.abtbltitle{
    width: 343px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 500;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
position: relative;
color: rgba(17, 17, 17, 1);
margin-left: -20px;
}

.abtbltitlepara{
    width: 343px;
height: 76px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
position: relative;
margin-left: -20px;
/* color: rgba(17, 17, 17, 1); */
}

.abtbloglc{
    width: 41px;
height: 41px;
background: rgba(217, 217, 217, 1);
border-radius: 100%;
/* position: relative; */
margin-top: -5%;
margin-left: -20px;
}

.abtbloglctext{
    width: 105px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 700;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
margin-left: 30px;
margin-top: -11%;
}


.hideinlap{
    display: none;
}

.aboutusheaderforphone{
    display: none;
}

}

@media (min-width:1228px) and (max-width:1399px){
    .Aboutuspagemain{
        max-width:1300px;
        transform: scale(0.83);
        transform-origin: top;
        margin-bottom: -300px;
    }

    .aboutusstartedbox{
        width: 1207px;
height: 227px;
border-radius: 25px;
background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
margin-bottom: 58%;   
margin-left: 3%;
margin-top: 10%;
}

    .Aboutustartedhead{
        width: 1000px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 50px;
font-weight: 400;
line-height: 86.8px;
text-align: left;
color: rgba(255, 255, 255, 1);
margin-left: 6%;
margin-top: 2%;
    }

    .Aboutustartedpara{
        width: 613px;
height: 62px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
color: rgba(255, 255, 255, 1);
margin-top: 10%;
margin-left: -82.5%;

    }

    .Aboutustartedbutton{
        width: 173px;
height: 60px;
border-radius: 37.5px;
background: rgba(255, 255, 255, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: center;
border: none;
color: rgba(7, 60, 165, 1);
margin-left: 19%;
margin-top: 7.5%;

    }


    .thisisformob{
        display: none;
    }
    .about2circleheadpara{
        width: 1130px;
height: 62px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
margin-top: 2%;
margin-left: 6%;

    }

    .aboutustitleheadermob{
        display: none;
    }

    .aboutcoreheaderparamob{
        display: none !important;
    }

    .about2circlehead{
        width: 490px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
margin-top: -55%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: 6%;
    }

    .about2circleheader{
        width: 761px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: left;
margin-left: -15%;
margin-top: -50%;
    }
    .aboutuscon{
        margin-top: 1%;
        margin-left: 4%;
    }
    
    .aboutusimg{
        width: 1198px;
    height: 462px;
    position: relative;
    left: -6px;
    }

    .aboutusimgmob{
        display: none;
    }
    
    .aboutusconimg{
        width: 1199px;
    height: 462px;
    left: 18.7px;
    top: 10px;
    background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
    position: absolute;
    }
    
    .aboutustitlehead{
        width: 761px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 86.8px;
    text-align: center;
    margin-left: -65%;
    margin-top: 60%;
    }

    .aboutustitleheader{
        width: 1030px;
height: 62px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-top: 80%;
margin-left: -143%;
    }

    .aboutacoreheader{
        width: 350px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
margin-left: 70%;
margin-top: 20%;
    }

    .aboutcoreheadertitle{
        width: 761px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: center;
margin-top: 2%;
margin-left: 38%;



    }

    .aboutcoreheaderpara{
        width: 1030px;
height: 62px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: 19%;
margin-top: 4%;

    }

    .aboutus2corecircle{
        width: 92px;
height: 92px;
background: rgba(217, 217, 217, 1);
border-radius: 100%;
margin-left: 10%;
margin-top: 12%;

    }

    .aboutus2corecirclepara{
        width: 180px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
color: rgba(17, 17, 17, 1);
margin-left: 38%;
margin-top: -15%;

    }

    .about2imagebox1{
        width: 327px;
height: 448px;
border-radius: 25px;
opacity: 0.9px;
margin-top: 10%;
margin-left: 15%;
    }

    
    .about2imagebox2{
        width: 327px;
height: 448px;
border-radius: 25px;
opacity: 0.9px;
margin-top: 10%;
margin-left: 8%;
    }

    
    .about2imagebox3{
        width: 327px;
height: 448px;
border-radius: 25px;
opacity: 0.9px;
margin-top: 10%;
margin-left: 0%;
    }



    .insideabout2image1{
        width: 295px;
height: 88px;
border-radius: 15px;
opacity: 80%;
background: rgb(255,255,255,1);
margin-top: -30%;
margin-left:18%;
    }

    .linkediniconabout21{
        width: 50px;
height: 50px;
margin-top: 6.5%;
    }

    .himanshuabout21{
        width: 100%;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 700;
line-height: 31px;
text-align: left;
margin-left: 17%;
margin-top: -17%;
    }

    .designationabout21{
        width: 100%;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: left;
margin-left: 18.2%;
margin-top: -2%;
    }

    .signinabout21{
        width: 60px;
        height: 60px;
        position: relative;
        margin-top: -53%;
        margin-left: 65%;
    }


    .insideabout2image2{
        width: 295px;
height: 88px;
border-radius: 15px;
opacity: 80%;
background: rgb(255,255,255,1);
margin-top: -29.5%;
margin-left: 12%;
    }

    .linkediniconabout22{
        width: 50px;
height: 50px;
margin-top: 6.5%;
    }

    .harshaabout22{
        width: 160px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 700;
line-height: 31px;
text-align: left;
margin-left: 18%;
margin-top: -17%;
    }

    .designationabout22{
        width: 160px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: left;
margin-left: 18.2%;
margin-top: -2%;
    }

    .signinabout22{
        width: 60px;
        height: 60px;
        position: relative;
        margin-top: -53%;
        margin-left: 60%;


    }

    .insideabout2image3{
        width: 295px;
height: 88px;
border-radius: 15px;
opacity: 80%;
background: rgb(255,255,255,1);
margin-top: -29.5%;
margin-left: 4%;
    }

    .linkediniconabout23{
        width: 50px;
height: 50px;
margin-top: 6.5%;
    }

    .prakashabout23{
        width: 248px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 700;
line-height: 31px;
text-align: left;
margin-left: 15%;
margin-top: -17%;
    }

    .designationabout23{
        width: 160px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: left;
margin-left: 15.2%;
margin-top: -2%;
    }

    .signinabout23{
        width: 60px;
        height: 60px;
        position: relative;
        margin-top: -53%;
        margin-left: 53%;


    }

    .Aboutusblogcardlap{
        margin-left: -6%;
    }




    .about2pinkcircle{
        width: 673px;
height: 673px;
background: rgba(247, 91, 2, 0.1);
border-radius: 100%;
margin-left: -30%;
margin-top: 10%;
    }

    
/* //hjdcbjh */

.aboutstite1{
    align-items: center;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
position: relative;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
width: 234px;
height: 64px;
border-radius: 32px;
background: #E8EDFC;
align-items: left;
margin-left: 20%;
margin-top: 15%;
}

.aboutparas1{
    width: 761px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: left;
margin-left: -11%;
margin-top: 0%;
}

.aboutingblog{
    width: 536px;
height: 371px;
border-radius: 25;   
}

.aboutlcblog{
    width: 69px;
height: 69px;
border-radius: 100%;
background: #FFFFFF;
margin-top: -130px;
position: absolute;
}

.aboutauthblog{
    width: 136px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 700;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
margin-top: -110px;
margin-left: 80px;
}

.aboutlat{
    width: 173px;
height: 60px;
border-radius: 0 0 37px 37px;
background: #252525;
margin-top: -395px;
margin-left: 300px;
position: absolute;
}

.aboutlate{
    width: 110px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
color: #FFFFFF;
margin-left: -5%;
margin-top: -2%;
}

.aboutmaiblog{
    margin-left: 13%;
    margin-top: 14%;
}

.aboutbuilblog{
    width: 484px;
height: 90px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 36px;
font-weight: 500;
line-height: 45px;
letter-spacing: 0em;
text-align: left;
margin-left: -28%;
margin-top: 20%;
}

.aboutbuildblog{
    width: 484px;
height: 155px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: left;
margin-left: -28%;
margin-top: -2%;
}

.aboutreadmore{
    width: 173px;
    height: 60px;
    border-radius: 37.5px;
    border: none;
    background-color: rgba(7, 60, 165, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    margin-top: 8px;
    margin-left: -140px;
}


/* shcnakjinca */

.abthr2blogl{
    width: 300px;
border: 1px solid #000000;
margin-left: -23px;
}

.abtcardbldimensions{
    width: 343px;
    height: 600px;
    border: none;
    margin-left: 10%;
}

.abtbltitle{
    width: 343px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 500;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
position: relative;
color: rgba(17, 17, 17, 1);
margin-left: -20px;
}

.abtbltitlepara{
    width: 343px;
height: 76px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
position: relative;
margin-left: -20px;
/* color: rgba(17, 17, 17, 1); */
}

.abtbloglc{
    width: 41px;
height: 41px;
background: rgba(217, 217, 217, 1);
border-radius: 100%;
/* position: relative; */
margin-top: -5%;
margin-left: -20px;
}

.abtbloglctext{
    width: 105px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 700;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
margin-left: 30px;
margin-top: -11%;
}


.hideinlap{
    display: none;
}

.aboutusheaderforphone{
    display: none;
}

}

@media (min-width:1027px) and (max-width:1227px){
    .Aboutuspagemain{
        max-width:1300px;
        transform: scale(0.83);
        transform-origin: top;
        margin-bottom: -500px;
    }

    .aboutusstartedbox{
        width: 1207px;
height: 227px;
border-radius: 25px;
background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
margin-bottom: 58%;   
margin-left: 3%;
margin-top: 10%;
}

    .Aboutustartedhead{
        width: 1000px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 50px;
font-weight: 400;
line-height: 86.8px;
text-align: left;
color: rgba(255, 255, 255, 1);
margin-left: 6%;
margin-top: 2%;
    }

    .Aboutustartedpara{
        width: 613px;
height: 62px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
color: rgba(255, 255, 255, 1);
margin-top: 10%;
margin-left: -82.5%;

    }

    .Aboutustartedbutton{
        width: 173px;
height: 60px;
border-radius: 37.5px;
background: rgba(255, 255, 255, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: center;
border: none;
color: rgba(7, 60, 165, 1);
margin-left: 19%;
margin-top: 7.5%;

    }


    .thisisformob{
        display: none;
    }
    .about2circleheadpara{
        width: 1130px;
height: 62px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
margin-top: 2%;
margin-left: 6%;

    }

    .aboutustitleheadermob{
        display: none;
    }

    .aboutcoreheaderparamob{
        display: none !important;
    }

    .about2circlehead{
        width: 490px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
margin-top: -55%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: 6%;
    }

    .about2circleheader{
        width: 761px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: left;
margin-left: -15%;
margin-top: -50%;
    }
    .aboutuscon{
        margin-top: 1%;
        margin-left: 4%;
    }
    
    .aboutusimg{
        width: 1198px;
    height: 462px;
    position: relative;
    left: -6px;
    }

    .aboutusimgmob{
        display: none;
    }
    
    .aboutusconimg{
        width: 1199px;
    height: 462px;
    left: 18.7px;
    top: 10px;
    background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
    position: absolute;
    }
    
    .aboutustitlehead{
        width: 761px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 86.8px;
    text-align: center;
    margin-left: -65%;
    margin-top: 60%;
    }

    .aboutustitleheader{
        width: 1030px;
height: 62px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-top: 80%;
margin-left: -143%;
    }

    .aboutacoreheader{
        width: 350px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
margin-left: 70%;
margin-top: 20%;
    }

    .aboutcoreheadertitle{
        width: 761px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: center;
margin-top: 2%;
margin-left: 38%;



    }

    .aboutcoreheaderpara{
        width: 1030px;
height: 62px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: 19%;
margin-top: 4%;

    }

    .aboutus2corecircle{
        width: 92px;
height: 92px;
background: rgba(217, 217, 217, 1);
border-radius: 100%;
margin-left: 10%;
margin-top: 12%;

    }

    .aboutus2corecirclepara{
        width: 180px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
color: rgba(17, 17, 17, 1);
margin-left: 38%;
margin-top: -15%;

    }

    .about2imagebox1{
        width: 327px;
height: 448px;
border-radius: 25px;
opacity: 0.9px;
margin-top: 10%;
margin-left: 15%;
    }

    
    .about2imagebox2{
        width: 327px;
height: 448px;
border-radius: 25px;
opacity: 0.9px;
margin-top: 10%;
margin-left: 8%;
    }

    
    .about2imagebox3{
        width: 327px;
height: 448px;
border-radius: 25px;
opacity: 0.9px;
margin-top: 10%;
margin-left: 0%;
    }



    .insideabout2image1{
        width: 295px;
height: 88px;
border-radius: 15px;
opacity: 80%;
background: rgb(255,255,255,1);
margin-top: -20%;
margin-left:18%;
    }

    .linkediniconabout21{
        width: 50px;
height: 50px;
margin-top: 6.5%;
    }

    .himanshuabout21{
        width: 100%;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 700;
line-height: 31px;
text-align: left;
margin-left: 18%;
margin-top: -17%;
    }

    .designationabout21{
        width: 100%;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: left;
margin-left: 18.2%;
margin-top: -2%;
    }

    .signinabout21{
        width: 60px;
        height: 60px;
        position: relative;
        margin-top: -40%;
        margin-left: 55%;
    }


    .insideabout2image2{
        width: 295px;
height: 88px;
border-radius: 15px;
opacity: 80%;
background: rgb(255,255,255,1);
margin-top: -29.5%;
margin-left: 12%;
    }

    .linkediniconabout22{
        width: 50px;
height: 50px;
margin-top: 6.5%;
    }

    .harshaabout22{
        width: 160px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 700;
line-height: 31px;
text-align: left;
margin-left: 18%;
margin-top: -17%;
    }

    .designationabout22{
        width: 160px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: left;
margin-left: 18.2%;
margin-top: -2%;
    }

    .signinabout22{
        width: 60px;
        height: 60px;
        position: relative;
        margin-top: -60%;
        margin-left: 70%;


    }

    .insideabout2image3{
        width: 295px;
height: 88px;
border-radius: 15px;
opacity: 80%;
background: rgb(255,255,255,1);
margin-top: -40%;
margin-left: 5%;
    }

    .aboutimage3{
        /* margin-left: 80px; */

    }

    .linkediniconabout23{
        width: 50px;
height: 50px;
margin-top: 6.5%;
    }

    .prakashabout23{
        width: 248px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 700;
line-height: 31px;
text-align: left;
margin-left: 15%;
margin-top: -17%;
    }

    .designationabout23{
        width: 160px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: left;
margin-left: 15.2%;
margin-top: -2%;
    }

    .signinabout23{
        width: 60px;
        height: 60px;
        position: relative;
        margin-top: -80%;
        margin-left: 80%;


    }

    .Aboutusblogcardlap{
        margin-left: -6%;
    }




    .about2pinkcircle{
        width: 673px;
height: 673px;
background: rgba(247, 91, 2, 0.1);
border-radius: 100%;
margin-left: -30%;
margin-top: 10%;
    }

    
/* //hjdcbjh */

.aboutstite1{
    align-items: center;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
position: relative;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
width: 234px;
height: 64px;
border-radius: 32px;
background: #E8EDFC;
align-items: left;
margin-left: 20%;
margin-top: 15%;
}

.aboutparas1{
    width: 761px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: left;
margin-left: -11%;
margin-top: 0%;
}

.aboutingblog{
    width: 536px;
height: 371px;
border-radius: 25;   
}

.aboutlcblog{
    width: 69px;
height: 69px;
border-radius: 100%;
background: #FFFFFF;
margin-top: -130px;
position: absolute;
}

.aboutauthblog{
    width: 136px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 700;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
margin-top: -110px;
margin-left: 80px;
}

.aboutlat{
    width: 173px;
height: 60px;
border-radius: 0 0 37px 37px;
background: #252525;
margin-top: -395px;
margin-left: 300px;
position: absolute;
}

.aboutlate{
    width: 110px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
color: #FFFFFF;
margin-left: -5%;
margin-top: -2%;
}

.aboutmaiblog{
    margin-left: 13%;
    margin-top: 14%;
}

.aboutbuilblog{
    width: 484px;
height: 90px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 36px;
font-weight: 500;
line-height: 45px;
letter-spacing: 0em;
text-align: left;
margin-left: -28%;
margin-top: 20%;
}

.aboutbuildblog{
    width: 484px;
height: 155px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: left;
margin-left: -28%;
margin-top: -2%;
}

.aboutreadmore{
    width: 173px;
    height: 60px;
    border-radius: 37.5px;
    border: none;
    background-color: rgba(7, 60, 165, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    margin-top: 8px;
    margin-left: -140px;
}


/* shcnakjinca */

.abthr2blogl{
    width: 300px;
border: 1px solid #000000;
margin-left: -23px;
}

.abtcardbldimensions{
    width: 343px;
    height: 600px;
    border: none;
    margin-left: 10%;
}

.abtbltitle{
    width: 343px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 500;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
position: relative;
color: rgba(17, 17, 17, 1);
margin-left: -20px;
}

.abtbltitlepara{
    width: 343px;
height: 76px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
position: relative;
margin-left: -20px;
/* color: rgba(17, 17, 17, 1); */
}

.abtbloglc{
    width: 41px;
height: 41px;
background: rgba(217, 217, 217, 1);
border-radius: 100%;
/* position: relative; */
margin-top: -5%;
margin-left: -20px;
}

.abtbloglctext{
    width: 105px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 700;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
margin-left: 30px;
margin-top: -11%;
}


.hideinlap{
    display: none;
}

.aboutusheaderforphone{
    display: none;
}

.aboutimage2{
    margin-top: 20px;

}

}

@media (min-width:831px) and (max-width:1026px){

    .aboutusstartedbox{
        width: 737px;
height: 252px;
border-radius: 25px;
background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
margin-bottom: 8%;   
margin-left: 14%;
margin-top: 74px;
}

    .Aboutustartedhead{
        width: 505px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 32px;
font-weight: 400;
line-height: 39.68px;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-left: 16%;
margin-top: 2%;
    }

    .Aboutustartedpara{
        width: 612.49px;
        height: 50px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-top: 10%;
margin-left: -75%;

    }

    .Aboutustartedbutton{
        width: 331px;
height: 61px;
border-radius: 37.5px;
background: rgba(255, 255, 255, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 29.76px;
text-align: center;
border: none;
color: rgba(7, 60, 165, 1);
margin-left: -65%;
margin-top: 20%;

    }




    .thisisformob{
        display: none;
    }

    .Aboutusblogcardlap{
        display: none;
    }

    .hideintab{
        display: none;
    }

    .imagebacktabdimen{
        width: 761px;
height: 229px;
border-radius: 25px;

    }

    .abtcardbldimensions{
        /* width: 900px; */
border-radius: 25px ;
/* opacity: 0px; */
/* background-color: aquamarine; */
margin-top: 10%;
margin-bottom: 20%;
    }

    .abtbloglctext{
        width: 123px;
height: 25px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 700;
line-height: 24.8px;
text-align: left;
margin-top: -4%;
margin-left: 8%;

    }

    .abthr2blogl{
        display: none;
    }

    .abtbloglc{
        width: 43px;
height: 43px;
background: rgba(217, 217, 217, 1);
border-radius: 100%;
margin-top: -58%;
    }

    .abtbltitle{
        width: 438px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 500;
line-height: 29.76px;
text-align: left;
color: rgba(17, 17, 17, 1);
margin-top: -13%;
    }

    .abtbltitlepara{
        width: 759px;
height: 90px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 29.76px;
text-align: left;
margin-top: 6%;
margin-left: -2%;

    }

    .aboutblogheader{
        width: 234px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
margin-top: 10%;
margin-left: 5%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
    }

    .aboutblogheader2{
        width: 761px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: left;
margin-left: 5%;
margin-top: 1%;
    }

    .aboutblogheader3{
        width: 761px;
height: 90px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 29.76px;
text-align: left;
margin-left: 6%;
    }

    .insideabout2image1{
        width: 328.21px;
height: 88px;
border-radius: 15px;
opacity: 80%;
background: rgb(255,255,255,1);
margin-top: -28%;
margin-left: 20%;
    }

    /* .about2imagebox1{
        width: 363.81px;
height: 448px;
border-radius: 25px;
opacity: 0.9px;
margin-top: 5%;
margin-left: 16%;
    } */

    .linkediniconabout21{
        width: 50px;
height: 50px;
margin-top: 6.5%;
    }

    .himanshuabout21{
        width: 100%;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 700;
line-height: 31px;
text-align: left;
margin-left: 18%;
margin-top: -16%;
    }

    .designationabout21{
        width: 100%;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: left;
margin-left: 18.2%;
margin-top: -2%;
    }

    /* .signinabout21{
        width: 60px;
        height: 60px;
        position: relative;
        margin-top: -45%;
        margin-left: 70%;
    } */



    .insideabout2image2{
        width: 328.21px;
height: 88px;
border-radius: 15px;
opacity: 80%;
background: rgb(255,255,255,1);
margin-top: -41%;
margin-left: 22%;
    }

    .about2imagebox2{
        width: 363.81px;
height: 448px;
border-radius: 25px;
opacity: 0.9px;
margin-top: 7%;
margin-left: 16%;
    }

    .linkediniconabout22{
        width: 50px;
height: 50px;
margin-top: 6.5%;
    }

    .harshaabout22{
        width: 160px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 700;
line-height: 31px;
text-align: left;
margin-left: 18%;
margin-top: -16%;
    }

    .designationabout22{
        width: 155px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: left;
margin-left: 18.2%;
margin-top: -2%;
    }

    .signinabout22{
        width: 60px;
        height: 60px;
        position: relative;
        margin-top: -70%;
        margin-left: 95%;


    }



    .insideabout2image3{
        width: 328.21px;
height: 88px;
border-radius: 15px;
opacity: 80%;
background: rgb(255,255,255,1);
margin-top: -41%;
margin-left: 30%;
    }

    .about2imagebox3{
        width: 363.81px;
height: 448px;
border-radius: 25px;
opacity: 0.9px;
margin-top: 10%;
margin-left: 24%;
    }

    .linkediniconabout23{
        width: 50px;
height: 50px;
margin-top: 6.5%;
    }

    .prakashabout23{
        width: 250px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 700;
line-height: 31px;
text-align: left;
margin-left: 18%;
margin-top: -16%;
    }

    .designationabout23{
        width: 155px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: left;
margin-left: 18.2%;
margin-top: -2%;
    }

    .signinabout23{
        width: 60px;
        height: 60px;
        position: relative;
        margin-top: -70%;
        margin-left: 106%;


    }



    .about2circlehead{
        width: 450px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
margin-top: -83%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: 8%;
    }

    .about2circleheader{
        width: 761px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: left;
margin-left: -20%;
margin-top: -76%;
    }

    .about2pinkcircle{
        width: 673px;
height: 673px;
background: rgba(247, 91, 2, 0.1);
border-radius: 100%;
margin-top: 10%;
margin-left: -30%;

    }

    .about2circleheadpara{
        width: 742px;
height: 93px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
margin-left: 10%;
margin-top: 2%;

    }

    .aboutustitleheadermob{
        display: none;
    }

    .aboutcoreheaderparamob{
        display: none !important;
    }

    .aboutus2corecircle{
        width: 92px;
height: 92px;
background: rgba(217, 217, 217, 1);
border-radius: 100%;
margin-left: 20%;
margin-top: 10%;
    }

    .aboutus2corecirclepara{
        width: 180px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
margin-left: 54%;
margin-top: -20%;

    }

    .aboutcoreheaderpara{
        width: 762px;
height: 93px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: 17%;
margin-top: 3%;

    }

    .aboutacoreheader{
        width: 300px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: 66%;
margin-top: 20%;
    }

    .aboutcoreheadertitle{
        width: 761px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: center;
margin-top: 2%;
margin-left: 19%;


    }

    .aboutustitleheader{
        width: 762px;
height: 93px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-top: 105%; 
margin-left: -165%; 
    }

    .aboutuscon{
        margin-top: 3%;
        margin-left: 6%;
    }
    .aboutusimg{

        width: 886px;
        height: 462px;
    position: relative;
    }

    .aboutusimgmob{
        display: none;
    }

    .aboutusconimg{
        width: 888px;
        height: 462px;
        margin-top: 2%;
        margin-left: -0.1%;
    background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
    position: absolute;
    }

    .aboutustitlehead{
        width: 761px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 86.8px;
    text-align: center;
    margin-left: -83%;
    margin-top: 80%;
    position: relative;
    }
}


@media (min-width:577px) and (max-width:767.5px) {
    .Aboutushedcontent{
        width: 510px;
    }

    .Aboutuscorevaluesubheader{
        width: 535px;
        font-size: 55px;
    }

    .Aboutuscorevaluepara{
        width: 550px;
    }

    .corevaluesaboutus{
        position: relative;
        margin-bottom: 3em;
        left: -50px;
        top: 30px;
    }

    .signinabout21{
        width: 60px;
        height: 60px;
        position: relative;
        margin-top: -42%;
        margin-left: 62%;
    }
}


@media (min-width:768px) and (max-width:830px) {
    .signinabout21{
        width: 60px;
        height: 60px;
        position: relative;
        margin-top: -31%;
        margin-left: 62%;


    }
}


@media (min-width:831px) and (max-width:991.5px){
    .about2imagebox1{
        width: 363.81px;
height: 448px;
border-radius: 25px;
opacity: 0.9px;
margin-top: 5%;
margin-left: 16%;
margin-bottom: 10%;
    }

    .signinabout21{
        width: 60px;
        height: 60px;
        position: relative;
        margin-top: -32%;
        margin-left: 55%;
    }
}


@media (min-width:992px) and (max-width:1026px){
    .about2imagebox1{
        width: 363.81px;
height: 448px;
border-radius: 25px;
opacity: 0.9px;
margin-top: 5%;
margin-left: 16%;
    }

    .signinabout21{
        width: 60px;
        height: 60px;
        position: relative;
        margin-top: -45%;
        margin-left: 70%;
    }
}


@media (min-width:577px) and (max-width:830px){

    .aboutusstartedbox{
        width: 737px;
height: 252px;
border-radius: 25px;
background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
margin-bottom: 8%;   
margin-left: 14%;

}

    .Aboutustartedhead{
        width: 472.07px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 32px;
font-weight: 400;
line-height: 39.68px;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-left: 16%;
margin-top: 2%;
    }

    .Aboutustartedpara{
        width: 612.49px;
        height: 50px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-top: 10%;
margin-left: -72%;

    }

    .Aboutustartedbutton{
        width: 331px;
height: 61px;
border-radius: 37.5px;
background: rgba(255, 255, 255, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 29.76px;
text-align: center;
border: none;
color: rgba(7, 60, 165, 1);
margin-left: -65%;
margin-top: 20%;

    }




    .thisisformob{
        display: none;
    }

    .Aboutusblogcardlap{
        display: none;
    }

    .hideintab{
        display: none;
    }

    .imagebacktabdimen{
        width: 761px;
height: 229px;
border-radius: 25px;

    }

    .abtcardbldimensions{
        /* width: 900px; */
border-radius: 25px ;
/* opacity: 0px; */
/* background-color: aquamarine; */
margin-top: 10%;
margin-bottom: 20%;
    }

    .abtbloglctext{
        width: 123px;
height: 25px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 700;
line-height: 24.8px;
text-align: left;
margin-top: -4%;
margin-left: 8%;

    }

    .abthr2blogl{
        display: none;
    }

    .abtbloglc{
        width: 43px;
height: 43px;
background: rgba(217, 217, 217, 1);
border-radius: 100%;
margin-top: -58%;
    }

    .abtbltitle{
        width: 438px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 500;
line-height: 29.76px;
text-align: left;
color: rgba(17, 17, 17, 1);
margin-top: -13%;
    }

    .abtbltitlepara{
        width: 759px;
height: 90px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 29.76px;
text-align: left;
margin-top: 6%;
margin-left: -2%;

    }

    .aboutblogheader{
        width: 234px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
margin-top: 10%;
margin-left: 5%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
    }

    .aboutblogheader2{
        width: 761px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: left;
margin-left: 5%;
margin-top: 1%;
    }

    .aboutblogheader3{
        width: 761px;
height: 90px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 29.76px;
text-align: left;
margin-left: 6%;
    }

    .insideabout2image1{
        width: 328.21px;
height: 88px;
border-radius: 15px;
opacity: 80%;
background: rgb(255,255,255,1);
margin-top: -28%;
/* margin-left: 20%; */
margin-left: auto;
margin-right: auto;
    }

    .about2imagebox1{
        width: 363.81px;
height: 448px;
border-radius: 25px;
margin-top: 5%;
/* margin-left: 16%; */
margin-left: auto;
margin-right: auto;
position: relative;
display: flex;
margin-bottom: 60px;
    }

    .linkediniconabout21{
        width: 50px;
height: 50px;
margin-top: 6.5%;
    }

    .himanshuabout21{
        width: 100%;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 700;
line-height: 31px;
text-align: left;
margin-left: 18%;
margin-top: -16%;
    }

    .designationabout21{
        width: 100%;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: left;
margin-left: 18.2%;
margin-top: -2%;
    }

    /* .signinabout21{
        width: 60px;
        height: 60px;
        position: relative;
        margin-top: -31%;
        margin-left: 62%;


    } */



    .insideabout2image2{
        width: 328.21px;
height: 88px;
border-radius: 15px;
opacity: 80%;
background: rgb(255,255,255,1);
margin-top: -41%;
margin-left: 22%;
    }

    .about2imagebox2{
        width: 363.81px;
height: 448px;
border-radius: 25px;
opacity: 0.9px;
margin-top: 7%;
margin-left: 16%;
    }

    .linkediniconabout22{
        width: 50px;
height: 50px;
margin-top: 6.5%;
    }

    .harshaabout22{
        width: 160px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 700;
line-height: 31px;
text-align: left;
margin-left: 18%;
margin-top: -16%;
    }

    .designationabout22{
        width: 155px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: left;
margin-left: 18.2%;
margin-top: -2%;
    }

    .signinabout22{
        width: 60px;
        height: 60px;
        position: relative;
        margin-top: -70%;
        margin-left: 95%;


    }



    .insideabout2image3{
        width: 328.21px;
height: 88px;
border-radius: 15px;
opacity: 80%;
background: rgb(255,255,255,1);
margin-top: -41%;
margin-left: 30%;
    }

    .about2imagebox3{
        width: 363.81px;
height: 448px;
border-radius: 25px;
opacity: 0.9px;
margin-top: 10%;
margin-left: 24%;
    }

    .linkediniconabout23{
        width: 50px;
height: 50px;
margin-top: 6.5%;
    }

    .prakashabout23{
        width: 250px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 700;
line-height: 31px;
text-align: left;
margin-left: 18%;
margin-top: -16%;
    }

    .designationabout23{
        width: 155px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: left;
margin-left: 18.2%;
margin-top: -2%;
    }

    .signinabout23{
        width: 60px;
        height: 60px;
        position: relative;
        margin-top: -70%;
        margin-left: 106%;


    }



    .about2circlehead{
        width: 450px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
margin-top: -83%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: 8%;
    }

    .about2circleheader{
        width: 761px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: left;
margin-left: -165px;
margin-top: 180px;
    }

    .about2pinkcircle{
        width: 673px;
height: 673px;
background: rgba(247, 91, 2, 0.1);
border-radius: 100%;
margin-top: 10%;
margin-left: -30%;
position: absolute;
    }

    .about2circleheadpara{
        width: 90%;
height: 93px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
margin-left: 10%;
margin-top: 25px;
margin-bottom: 20px;

    }

    .aboutustitleheadermob{
        display: none;
    }

    .aboutcoreheaderparamob{
        display: none !important;
    }

    .aboutus2corecircle{
        width: 92px;
height: 92px;
background: rgba(217, 217, 217, 1);
border-radius: 100%;
margin-left: 20%;
margin-top: 10%;
    }

    .aboutus2corecirclepara{
        width: 180px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
margin-left: 54%;
margin-top: -20%;

    }

    .aboutcoreheaderpara{
        width: 762px;
height: 93px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: 17%;
margin-top: 3%;

    }

    .aboutacoreheader{
        width: 300px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: 66%;
margin-top: 20%;
    }

    .aboutcoreheadertitle{
        width: 761px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: center;
margin-top: 2%;
margin-left: 19%;


    }

    .aboutustitleheader{
        width: 762px;
height: 93px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-top: 105%; 
margin-left: -165%; 
    }

    .aboutuscon{
        margin-top: 3%;
        margin-left: 6%;
    }
    .aboutusimg{
        width: 886px;
        height: 462px;
    position: relative;
    }

    .aboutusimgmob{
        display: none;
    }

    .aboutusconimg{
        width: 888px;
        height: 462px;
        margin-top: 2%;
        margin-left: -0.1%;
    background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
    position: absolute;
    }

    .aboutustitlehead{
        width: 761px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 86.8px;
    text-align: center;
    margin-left: -83%;
    margin-top: 80%;
    position: relative;
    }
}


@media (max-width: 576px){



    .Aboutuscorevalueheader{
        width: 180px;
        height: 34px;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: center;
    }

    .Aboutuscorevaluesubheader{
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;

    }

    .Aboutuscorevaluepara{
width: 90%;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: center;

    }

    .auscon {
        height: 282px;
      }

    .auimgmob{
        display: block;
        width: 80.5%;
        height: 293px;
    }

    .auimg{
        display: none;
    }

    .auconimg{
        width: 80.5%;
        height: 293px;
    }

    .AboutUsTitleHeader{
        margin-top: 0px;
        margin-bottom: 30px;
    }

    .Aboutusheader{
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;

    }

    .Aboutushedcontent{
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: center;

    }

    .iconimg{
        width: 30px;
        height: 30px;
    }

    .aboutusstartedbox{
        width: 335px;
height: 199px;
border-radius: 25px;
background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
margin-bottom: 8%;   
margin-left: 11%;
margin-top: 10%;
}

    .Aboutustartedhead{
        width: 300px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-left: 5%;
margin-top: 10%;
    }

    .Aboutustartedpara{
        width: 257px;
        height: 30px;        
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-top: 29%;
margin-left: -83%;

    }

    .Aboutustartedbutton{
        width: 137px;
height: 42px;
border-radius: 37.5px;
background: rgba(255, 255, 255, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: center;
border: none;
color: rgba(7, 60, 165, 1);
margin-left: -58%;
margin-top: 41%;

    }

    .aboutblogheader{
        width: 105px;
height: 34px;
border-radius: 37.5px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: center;
margin-top: 10%;
margin-left: 10%;
    }

    .aboutblogheader2{
        width: 105px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
margin-top: 20%;
margin-left: -25%;

    }
    .aboutblogheader3{
        width: 309px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
margin-left: 10%;

    }

    .abtcardbldimensions{
        margin-top: 14%;
        margin-left: -5%;
        border: none;
        margin-bottom: 40%;
    }

    .abtbloglctext{
        width: 90px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 700;
line-height: 18.6px;
text-align: left;
margin-left: 18%;
margin-top: -10%;
    }

    .abthr2blogl{
        display: none;
    }

    .abtbltitle{
        width: 257px;
height: 50px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 500;
line-height: 24.8px;
text-align: left;
margin-top: -30%;
    }

    .abtbltitlepara{
        width: 306px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
margin-top: 10%;


    }

    .abtbloglc{
        width: 43px;
height: 43px;
background: rgba(217, 217, 217, 1);
border-radius: 100%;
margin-top: -145%;
    }

    .hideinlap{
        display: none;
    }

    .Aboutusblogcardlap{
        display: none;
    }

    .hideintab{
        display: none;
    }

    .about2imagebox1{
        width: 60%;
height: 350px;
border-radius: 15px;
display: flex;
margin-left: auto;
margin-right: auto;
margin-top: 8%;
position: relative;
    }

    .insideabout2image1{
        width: 50%;
height: 46px;
border-radius: 15px;
opacity: 80%;
background: rgba(255, 255, 255, 1);
margin-top: -80px;
margin-left: auto;
margin-right: auto;
    }

    .linkediniconabout21{
        width: 36px;
height: 36px;
margin-top: 5px;
position: absolute;
    }

    .himanshuabout21{
        width: 100%;
height: 15px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 700;
line-height: 18.6px;
text-align: left;
margin-top: 8px;
margin-left: 37px;
position: absolute;
    }

    .designationabout21{
        width: 100%;
height: 12px;
font-size: 10px;
font-weight: 400;
line-height: 12.4px;
text-align: left;
font-family: 'Plus Jakarta Sans', sans-serif;
position: absolute;
margin-top: 28px;
margin-left: 38px;
    }

    .signinabout21{
        width: 28px;
height: 28px;
margin-top: -60px;
margin-left: 260px;
position: absolute;
display: flex;
    }




    .about2imagebox2{
        width: 155.19px;
height: 212.61px;
border-radius: 15px;
margin-left: 10%;
margin-top: 8%;
    }

    .insideabout2image2{
        width: 140px;
height: 46px;
border-radius: 15px;
opacity: 80%;
background: rgba(255, 255, 255, 1);
margin-top: -30%;
margin-left: 14%;
    }

    .linkediniconabout22{
        width: 36px;
height: 36px;
margin-top: 4%;
    }

    .harshaabout22{
        width: 86px;
height: 15px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 10px;
font-weight: 700;
line-height: 18.6px;
text-align: left;
margin-top: -23%;
margin-left: 24%;
    }

    .designationabout22{
        width: 112px;
height: 12px;
font-size: 10px;
font-weight: 400;
line-height: 12.4px;
text-align: left;
font-family: 'Plus Jakarta Sans', sans-serif;
margin-left: 24%;
margin-top: -4%;
    }

    .signinabout22{
        width: 28px;
height: 28px;
margin-top: -60%;
margin-left: 65%;
position: relative;
    }




    .about2imagebox3{
        width: 155.19px;
height: 212.61px;
border-radius: 15px;
margin-left: 20%;
margin-top: 2%;
    }

    .insideabout2image3{
        width: 140px;
height: 46px;
border-radius: 15px;
opacity: 80%;
background: rgba(255, 255, 255, 1);
margin-top: -30%;
margin-left: 24%;
    }

    .linkediniconabout23{
        width: 36px;
height: 36px;
margin-top: 4%;
    }

    .prakashabout23{
        width: 101px;
height: 15px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 10px;
font-weight: 700;
line-height: 18.6px;
text-align: left;
margin-top: -23%;
margin-left: 24%;
    }

    .designationabout23{
        width: 111px;
height: 12px;
font-size: 10px;
font-weight: 400;
line-height: 12.4px;
text-align: left;
font-family: 'Plus Jakarta Sans', sans-serif;
margin-left: 24%;
margin-top: -4%;
    }

    .signinabout23{
        width: 28px;
height: 28px;
margin-top: -60%;
margin-left: 74%;
position: relative;
    }

    .about2circleheadpara{
        width: 75%;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
margin-left: 13%;
margin-top: 2%;

    }

    .about2circlehead{
        width: 230px;
height: 34px;
border-radius: 37.5px;
background: rgba(232, 237, 252, 1);
margin-top: 27%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: center;
margin-left: 10%;
    }

    .about2circleheader{
        width: 120px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: 10%;
margin-top: 30%;
    }

    .about2pinkcircle{
        display: none;
    }

    .aboutustitleheader{
        display: none;
    }

    .aboutcoreheaderpara{
        display: none !important;
    }

    .aboutus2corecirclepara{
        width: 92px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: left;
margin-top: -3%;
margin-left: 42%;
position: absolute;
    }

    .aboutus2corecircle{
        width: 50px;
height: 50px;
background: rgba(217, 217, 217, 1);
border-radius: 100%;
margin-top: 23%;
margin-bottom: -15%;
margin-left: 11%;
    }

    .aboutcoreheaderparamob{
        width: 358px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: center;
margin-left: 13%;
margin-top: 5%;
    }

    .aboutacoreheader{
        width: 160px;
        height: 34px;
        border-radius: 37.5px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: center;
margin-left: 60%;
margin-top: -220px;
    }

    .corevaluesaboutus{
        position: relative;
        margin-top: -90px;
        left: -10px;
    }

    .aboutcoreheadertitle{
        width: 191px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-top: 2%;
margin-left: 55%;


    }

    .aboutustitleheadermob{
        width: 358px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: center;
margin-top: 146%;
margin-left: -139%;
    }

    .aboutustitlehead{
        width: 201px;
height: 31px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
position: relative;
margin-top:126%;
    margin-left: -48%;
    }
    .aboutuscon{
        margin-top: 3%;
        margin-left: 5%;
    }

    .aboutusimg{
        display: none;
    }

    .aboutusimgmob{
        width: 365px;
        height: 293px;  
    position: relative;
    margin-left: 5%;
    }

    .aboutusconimg{
        width: 367px;
height: 293px;
        margin-top: 2%;
        margin-left: 5%;
    background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
    position: absolute;
    }

    .aboutimage{
        margin-bottom: 25px;
    }

    .Aboutuscorevaluessection{
        margin-bottom: 60px;
    }
}

@media (max-width:391px){
    .signinabout21{
        margin-left: 210px;
    }
}