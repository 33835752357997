.profilepick{
  width: 603px !important;
  height: 60px;
  border-radius: 5px;
  /* line-height: 55px; */
}



@media (max-width:576px){
    .profilepick{
        width: 285px !important;
      height: 44px;
      border-radius: 5px;
    }
}