.chatboxtext1col,.chatboxtext21col,.chatboxtext31col,.chatboxtext41col,.chatboxtext51col{
    color: #000000;    
}

.chatbox1col1:hover ,
.chatbox2col:hover,
.chatbox3col:hover,
.chatbox4col:hover,z
.chatbox5col:hover
{
    transform: scale(1.05); /* Scale up the element on hover */
  }

  .chatbox1col1,
  .chatbox2col,
  .chatbox3col,
  .chatbox4col,
  .chatbox5col
  {
    /* Add initial styles */
    transition: transform 0.3s ease; /* Add transition for smooth effect */
  }
 


@media (min-width:1400px){

    .colwidth1{
        width: 710px;
    }

    .colwidth2{
        width: 700px;
    }

    .colwidth3{
        width: 650px;
    }

    .contentt2{
        /* display: flex;
        justify-content: center;
    font-size: 55px;
    font-weight: 300;
    line-height: 68px;
    letter-spacing: 0em;
    font-family: 'Plus Jakarta Sans', sans-serif;
    text-align: left; */

    width: 640px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 300;
    font-size: 38px;
    /* letter-spacing: 0em; */
  /* max-width: 600px;  */
  /* line-height: 1.2;  */
  line-height: 47.52px;
  /* margin: 0 auto;  */
  text-align: left;
        margin-left: 80px;
        margin-top: 25%;
    }

    /* .colpara1{
        width: 570px;
height: 40px;
font-size: 38px;
font-weight: 300;
text-align: left;
font-family: 'Plus Jakarta Sans', sans-serif;
    }

    .colpara2{
        width: 640px;
height: 66px;
font-size: 38px;
font-weight: 300;
line-height: 40px;
text-align: left;
font-family: 'Plus Jakarta Sans', sans-serif;
    }

    .colpara3{
        width: 600px;
font-size: 38px;
font-weight: 300;
line-height: 45px;
text-align: left;
font-family: 'Plus Jakarta Sans', sans-serif;
    } */


    .head12tabcol{
        display: none;
    }

    .row-reveal {
        opacity: 0;
        transition: opacity 2s ease; /* Adjust the duration as needed */
      }
      
      .row-reveal.active {
        opacity: 1;
      }
      

    .testimonial-card {
        margin-bottom: 20px;
        border: none; /* Remove the border */
        background: transparent ;
        box-shadow: none;
      }  
      

    .testimonial-box {
        width: 400px;
        height: 300px;
        position: relative;
        
      }

      
      .curved-side {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #f0f0f0;
        border-radius: 50px;
      }
      
      .curved-side-1 {
        top: 0;
        left: 0;
      }
      
      .curved-side-2 {
        top: 0;
        right: 0;
      }
      
      .curved-side-3 {
        bottom: 0;
        left: 0;
      }
      
      .pointed-side {
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        left: -50px;
        border-left: 100px solid transparent;
        border-top: 100px solid #f0f0f0;
      }

      .testimonial-text {
        position: absolute;
        top: -70px;
        width: 340px;
        color: #000000;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
      }

      .namecol{
        position: absolute;
        margin-top: 10px;
      }
      

    /* box click element start */

    .clickihv1col{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clickihv2col{
        width: 34px;
height: 34px;
position: relative;
top: -15px;
left: 90px;
    }

    .clickihv3col{
        width: 34px;
height: 34px;
position: relative;
top: -45px;
left: 90px;
    }

    .clickhv1col{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clickhv2col{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickhv3col{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clichv1col{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clichv2col{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clichv3col{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clihv1col{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clihv2col{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clihv3col{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    /* box click elemet end */

    .collegepagemain{
        margin-top: -2%;
    }

    .gifcol{
        position: absolute;
        top: -200px;
        left: 180px;
    }

    /* circle animation  */

    @keyframes moveUp {
        0% { transform: translateY(30px); }
        100% { transform: translateY(-10px); }
    }
    
    .orgcircle3, .viocircle3, .bluecircle3, .yellowcircle3 {
        
        animation: none; 
    }
    
    .scrolling .orgcircle3 {
        animation: moveUp 1s forwards; 
    }
    
    .scrolling .viocircle3 {
        animation: moveUp 1.5s forwards; 
    }
    
    .scrolling .bluecircle3 {
        animation: moveUp 2s forwards; 
    }

    .scrolling .yellowcircle3 {
        animation: moveUp 3s forwards; 
    }


    /* circle animation end  */

    .clickinscol{
        width: 34px;
        height: 34px;
        position: relative;
        left: 320px;
        top: -45px;
        cursor: pointer;
    }

    .clickinscol2{
        width: 34px;
height: 34px;
position: relative;
left: 850px;
top: -45px;
cursor: pointer;
    }

    .clickinscol3{
        width: 34px;
height: 34px;
position: relative;
left: 940px;
top: -55px;
cursor: pointer;
    }

    .clickinscol4{
        width: 34px;
height: 34px;
position: relative;
left: 1150px;
top: -8px;
cursor: pointer;
    }

    .brandhovercontainercol{
        position: relative;
    }
    
    .accesshovercontainercol{
        position: relative;
    }
    
    .autohovercontainercol{
        position: relative;
    }
    
    .hirehovercontainercol{
        position: relative;
    }
    
    
    .imagecontainerbrandcol{
        position: relative;
        /* transition: opacity 0.3s ease;
        cursor: pointer;
        z-index: 1; */
    }
    
    .imagecontaineraccesscol{
        position: relative;
        top: -30px;
        /* transition: opacity 0.3s ease;
        cursor: pointer; */
        /* z-index: 1; */
    }
    
    .imagecontainerautocol{
        position: relative;
        top: -25px;
        /* transition: opacity 0.3s ease;
        cursor: pointer;
        z-index: 1; */
    }
    
    .imagecontainerhirecol{
        position: relative;
        top: -45px;
        /* transition: opacity 0.3s ease;
        cursor: pointer;
        z-index: 1; */
    }

    .brandhovercontentcol{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        position: absolute;
        top: -0.85%;
      left: 6.5%;
      z-index: 2;
    
    }
    
    .accesshovercontentcol{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        position: absolute;
        margin-top: -37.7%;
        left: 7%;
        z-index: 2;
    }
    
    .autohovercontentcol{
        background-color: #ffffff;
        width: 1300px;
        height: 471px;
        position: absolute;
        margin-top: -41%;
      left: 7%;
      z-index: 2;
    }
    
    .hirehovercontentcol{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        position: absolute;
        margin-top: -41%;
      left: 7%;
      z-index: 2;
    }
    
    /* .imagecontainerbrandcol:hover + .brandhovercontentcol {
        opacity: 1; 
      } */
    
      /* .imagecontaineraccesscol:hover + .accesshovercontentcol {
        opacity: 1; 
      } */
    
      /* .imagecontainerautocol:hover + .autohovercontentcol {
        opacity: 1; 
      } */
    
      /* .imagecontainerhirecol:hover + .hirehovercontentcol {
        opacity: 1;
      } */
    
    
    
    .brandcontentcol{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
    }
    
    .brandheadercol{
        width: 660px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 5%;
    position: absolute;
    margin-top: 2.5%;
    }
    
    .brandparacol{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .brandimagescol{
        position: absolute;
        margin-left: 75%;
        margin-top: -35%;
    }
    
    .hoverimg1brandcol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-bottom: 2%;
    top: -6px;
    position: absolute;
    }
    
    .brandcolgimg1bg{
        position: relative;
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -20%;
    top: -6px;
    }
    
    .hoverimg2brandcol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-bottom: 2%;
    position: absolute;
    }
    
    .brandcolgimg2bg{
        width: 141px;
        height: 145px;
        border-radius: 20px;
        margin-bottom: -20%;
        top: 0px;
        background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
        opacity: 45%;
    }
    
    .hoverimg3brandcol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-bottom: 2%;
    position: absolute;
    margin-top: 5%;
    }
    
    .brandcolgimg3bg{
        width: 141px;
        height: 145px;
        border-radius: 20px;
        margin-bottom: 2%;
        top: 7px;
        background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
        opacity: 45%;
        position: relative;
    }
    
    .accesscontentcol{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
    }
    
    .accessheadercol{
        width: 900px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 2.5%;
    margin-left: 5%;
    }
    
    .accessparacol{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 5%;
    margin-left: 2%;
    padding: 3%;
    }

    .accessparacol2{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 9%;
    margin-left: 2%;
    padding: 3%;
    }

    .accesscolgimage{
        margin-left: 75%;
        margin-top: -35%;
    }
    
    .accessclgimages{
        margin-left: 75%;
        margin-top: -35%;
    }

    .acescgimages{
        margin-left: 75%;
        margin-top: -35%;
    }

    .acesclgimages{
        margin-left: 75%;
        margin-top: -35%;
    }
    
    .hoverimg1acescol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: -0.5%;
        margin-bottom: 2%;
        position: absolute;
    }

    .hoverimg1accesscol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }

    .hoverimg1acescl{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }
    
    .accesshoverimg1bgcol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    margin-bottom: -7%;
    }
    
    .aceshoverimg1bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    margin-bottom: -9%;
    top: -5px;
    position: relative;
    }

    .aceshoveimg1bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    margin-bottom: -7%;
    }
    
    .hoverimg2accesscol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }

    .hoverimg2acescol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }

    .hoveimg2acescol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }
    
    .accesshoverimg2bgcol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -7%;
    }

    .aceshoverimg2bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    margin-bottom: -7%;
    }

    .aceshoveimg2bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -7%;
    }
    
    .hoverimg3accesscol{
        position: absolute;
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
    }

    .hoverimg3acescol{
        position: absolute;
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
    }

    .hoveimg3aces{
        position: absolute;
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
    }
    
    .accesshoverimg3bgcol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    opacity: 45%;
    margin-bottom: 2%;
    }

    .aceshoverimg3bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    opacity: 45%;
    margin-bottom: 2%;
    }
    
    .aceshoveimg3bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    margin-bottom: 2%;
    }
    
    .hireheadercol{
        width: 700px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 2.5%;
    margin-left: 5%;
    }
    
    .hireparacol{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .hirecontentcol{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
    
    }
    
    
    .autocontentcol{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%);
    
    }
    


    /* kcsjnskj */
    .bimg1xt{
        position: absolute;
        top: 1400px;
    }
    
    body{
        overflow-x: hidden;
    }
    
    .regiter1t{
        padding-top: 5%;
    }
    
    .logosclg{
        white-space: nowrap;    
    }
    
    .siginscol{
        color: white;
    }

    .logocl-slide{
        display: inline-block;
        animation: 120s slide infinite linear;
    }
    
    .logocl-slide img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logossclg{
        /* overflow: hidden; */
        /* padding: 20px 0; */
        white-space: nowrap;
    
    }
    
    .logoscl-slide{
        display: inline-block;
        animation: 80s slides infinite linear;
    }
    
    .logoscl-slide img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }
    
    
    

    
    /* .contentt2 br {
        content: '';
        display: block;
         margin-bottom: 10px;  
    } */
    
    .watchs2 {
        padding-top: 6%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        text-transform:capitalize;
    }

    .fresher1tag{
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    /* text-indent: 10px; */
    margin-left: 7%;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 234px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }
    
    .luck2{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  font-weight: 900;
    }

    .ftext{
        top: 15px;
        position: absolute;
        left: 70px;
    }

    .dancecol-divv {
        opacity: 0;
        transform: translateY(-400px);
        transition: 1s ;
    }
      
      .dancecol-divv.visible {
        opacity: 1;
        transform: translateY(-100%);
        animation:  0.5s ease-in-out forwards ;
      }
      
      @keyframes shake {
        0% { transform: translateX(0); }
        25% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
        75% { transform: translateX(-10px); }
        100% { transform: translateX(0); }
      }
      
    .dancecol-div {
        transition: transform 0.5s ease; 
      }
      
      .dancecol-div.dancing {
        transform: translateY(-25px); 
        animation: 60s ease-out alternate; 
      }
      
      @keyframes bounce {
        from {
          transform: translateY(-25px);
        }
        to {
          transform: translateY(0);
        }
      }
    
    .im-container1 {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
        margin-left: 10%;
    }
    
    .background-container2 {
        position: absolute;
        bottom: 0;
        left: 60%;
        transform: translateX(-50%);
        width: 249px;
        height: 445px;
        clip-path: polygon(0 0, 100% 60%, 100% 100%, 0% 100%);
        background-color:#891A61;
    }
    
    .orgvector2 {
        /* z-index: 1;  */
        margin-left: 25%;
        position: relative;
    }
    
    .violetbox2{
        background: #F75B02;
        width: 203px;
    height: 203px;
    display: flex;
    position: relative;
    margin-left: 21%;
    margin-top: -28%;
    display: inline-block;
    text-align: start;
    }
    
    .vt1col{
        width: 91px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    margin-top: 9%;
    margin-left: 5%;
    }
    .im-container4{
        position: relative;
        margin-left: 19%;
        margin-top: -4%;
        /* z-index: 1; */
    }
    
    .vt2col{
        width: 83px;
    height: 19px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    margin-top: 62px;
    margin-left: -85px;
    }
    
    .yellowbox2{
        background:#F7B400;
        width: 118px;
    height: 118px;
    display: flex;
    margin-left: 19.5%;
    /* margin-right: 190px; */
    display: inline-block;
    position: relative;
    margin-top: -9%;
    text-align: start;
    }
    
    .yt1col{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    }
    
    .yt2{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 10px;
    }
    
    .im-container2, .im-container3, .im-container5 {
        position: relative;
        /* z-index: 2;  */
    }
    
    .sechead2{
        margin-top: 5%;
    }
    
    .firstsignup2{
        margin-top: -13%;
    }
    .sigins{
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 2%;
    color: rgba(255, 255, 255, 1);
    
    
    }
    
    .signincol
    {
        width: 173px;
    height: 60px;
    position: relative;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 11%;
    margin-left: 14%;
    background: rgba(7, 60, 165, 1);
    /* text-align: center; */
    border: none;
    
    }
    
    .watchcol{
        width: 200px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 11%;
    margin-left: -380px;
    }
    
    .linetextcol{
        width: 1110px;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center ;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6%;
    }

    .linetxtcol{
        width: 1110px;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center ;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: -20px;
    margin-bottom: -1em;
    }
    
    .linebox2{
    
        width: 160px;
    height: 160px;
    display: flex;
    /* margin-right: 100px; */
    display: inline-block;
    /* top: 0px; */
    position: relative;
    margin-top: -72%;
    text-align: start;
    border: 2px solid rgba(0, 0, 0, 1);
    margin-left: 29.3%;
    
    }
    
    .hlinebox1col{
        width: 102px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 16%;
    position: absolute;
    opacity: 30%;
    
    }
    .hlinebox2col{
        width: 40px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 30%;
    opacity: 30%;
    position: absolute;
    
    }
    
    
    .rlinebox1col{
        width: 159px;
    height: 102px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 10%;
    position: absolute;
    }
    
    .rlinebox2{
        width: 159px;
    height: 40px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 50%;
    }
    
    .lineboxtextcol{
        width: 64px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    color: rgba(0, 0, 0, 1);
    
    }
    .lineboxtext2col{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .title1tagcol{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    margin-top: 0%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 170px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    }

    .title1tagcolg{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 390px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    }

    .title2tagcol2g{
        width: 1050px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: auto;
margin-right: auto;
    }

    .imagecontainerbrand{
        position: relative;
    }
    
    .mid3contentcol .img1{
        width: 312px;
    height: 462px;
    margin-left: 100px;
    margin-top: -3px;
    border-radius: 50px;
    }
    .mid3contentcol .bimg1{
        width: 312px;
    height: 462px;
    position: absolute;
    margin-top: -0.2%;
    margin-left: -24%;
    border-radius: 50px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    }
    
    .mid3contentcol .bimg1text{
        width: 146px;
    height: 84px;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    opacity: 100%;
    position: relative;
    /* display: block; */
    margin-top: -100px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-left: 120px;
    
    }
    
    
    .imagecontaineraccess{
        position: relative;
    }
    
    .mid3contentcol .img2{
        width: 513px;
    height: 231px;
    margin-top: -35%;
    margin-left: 34%;
    margin-bottom: 40%;
    border-radius: 50px;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    .mid3contentcol .bimg2{
        width: 513px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-top: -35%;
    margin-left: -39.5%;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    
    }
    
    .bimg2textcol{
        width: 400px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -45%;
    margin-left: 480px;
    opacity: 100%;
    position: relative;
    display: block;
    }
    
    .imagecontainerauto{
        position: relative;
    }
    
    
    
      
    
    .title2tagcol{
        width: 1050px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    }

    .title2tagcol2{
        width: 1050px;
        height: 40px;
                font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
                margin-left: auto;
                margin-right: auto;
                margin-top: 0.2em;
    }
    
    .mid3contentcol .img3{
        width: 768px;
    height: 212px;
    border-radius: 50px;
    margin-top: -18%;
    margin-left: 34%;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    
    .mid3contentcol .bimg3{
        width: 768px;
    height: 212px;
    margin-top: -16%;
    margin-left: -59.2%;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    }
    
    .mid3contentcol .bimg3text{
        width: 520px;
    height: 84px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    margin-left: 36%;
    display: block;
    margin-top: -9%;
    color:rgba(255, 255, 255, 1);
    
    }
    
    .imagecontainerhire{
        position: relative;
    }
    
    
    .mid3contentcol .img4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    margin-top: -58%;
    margin-left: 75%;
    /* opacity: 45%px; */
    
    
    }
    
    .mid3contentcol .bimg4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-left: -17.7%;
    margin-top: -36.9%;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    }
    
    .bimg4textcol{
        width: 90px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    position: relative;
    display: block;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -32%;
    margin-left: 75.8%;
    
    }
    
    .logmovcl{
        position: relative;
        margin-bottom: -4rem;
    }
    
    .phonebox3{
        margin: 14%;
    }
    
    .phonetitle1tag3{
        margin-top: 100px;
        left: -10px;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 600px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }
    
    .phoneheadtitle3{
        width: 630px;
        height: 174px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;    
    }
    
    .phonebody3lap{
        width: 455px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: -150px;
    top: 90px;
    position: relative;
    }

    .phonebody3mob{
        display: none;
    }
    
    .phone3{
        width: 485px;
    height: 391px;
    margin-top: -290px;
    margin-left: 30px;
    position: relative;
    }
    
    .orgcircle3{
        width: 240.93px;
    height: 240.93px;
    margin-top: -200px;
    border-radius: 100%;
    margin-left: 110px;
    position: relative;
    }
    
    .viocircle3{
        width: 240.93px;
    height: 240.93px;
    border-radius: 100%;
    /* background: rgba(130, 27, 97, 1); */
    margin-top: -120px;
    position: relative;
    opacity: 90%;
    }
    
    .viocircle3::before {
      content: '';
      position: absolute;
      margin-top: 27%;
      margin-left: -20%;
      width: 240.93px;
    height: 240.93px;
      border-radius: 50%;
      opacity: 100%;
    }
    
    .orgcircle3::before{
      content: '';
      position: absolute;
      margin-top: 35%;
      margin-left: -7%;
      width: 240.93px;
      height: 240.93px;
      border-radius: 50%;
      opacity: 90%;
    }
    
    .orgcircle3::before {
        background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
      }
      
      .viocircle3::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }
    
    .bluecircle3{
        width: 364.7px;
    height: 364.7px;
    border-radius: 100%;
    background: rgba(135, 189, 255, 1);
    margin-top: -272px;
    margin-left: 170px;
    position: absolute;
    }
    
    .yellowcircle3{
        width: 171.62px;
        height: 171.62px;
        border-radius: 100%;
        background: #F7B400; 
        margin-top: -70px;
        margin-left: 120px;
        position: absolute;
    }

    .arrow3{
        width: 256.46px;
    height: 189.91px;
    margin-left: -100px;
    margin-top: -150px;
    }
    .image-phone-container3{
        margin-left: 600px;
        margin-top: -400px;
    }
    
    .phonetitles3{
        margin-left: -150px;
    }
    
    
    
    .trainingtitles{
        margin-top: -590px;
    }
    
    .traintitle1tagcol{
        width: 225px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    }
    
    .trainheadtitlecol{
        width: 542px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 200px;
    }

    .colgciradful{
        position: relative;
        top: 5em;
    }

    .traincirclecol{
        width: 500px;
    height: 500px;
    background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -250px;
    
    }
    
    .head12col{
        width: 1075px;
    height: 105px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .head13{
        margin-top: -80px;
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .regitercol{
        width: 300px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    top: 20px;
    background: rgba(7, 60, 165, 1);
    border: none;
    position: relative;
    }

    .asescirful{
        position: relative;
        top: -180px;
    }
    
    .assesscirclecol{
        width: 500px;
    height: 500px;
    background: rgba(9, 9, 63, 0.05);
    border-radius: 100%;
    margin-left: 1100px;
    margin-top: 38%;
    
    }
    
    .assesstitle1tagcol{
        margin-top: -570px;
        margin-left: 990px;
        width: 225px;
        height: 64px;
        border-radius: 37.5px;
        background: rgba(232, 237, 252, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;    
    }
    
    .assesheadtitlecol{
        width: 693px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: right;
    margin-left: 570px;
    margin-top: -45%;
    }
    
    .assesshead1col{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 185px;
    text-align: right;
    }
    
    .assesshead2col{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 150px;
    text-align: right;
    margin-top: -50px;
    }
    
    .regiter2col{
        width: 300px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 930px;
    margin-top: -100px;
    border: none;
    background: rgba(7, 60, 165, 1);
    margin-bottom: 10%;
    }


    
    .chatcol{
        margin-top: 0px;
        margin-bottom: -2%;
    }
    
    .chatitle1tagcol{
        width: 370px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    
    }
    
    .ctextcol{
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
    }
    
    .chatheadtitlecol{
        width: 600px;
    height: 174px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .chatimg1col{
        width: 87px;
    height: 87px;
    margin-left: -2%;
    }
    
    .chatbox111col{
        clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
        width: 84.16px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    margin-left: 11%;
    margin-top: -98.6%;
    position: relative;
    }
    
    .chatbox11col{
        width: 378px;
    height: 615px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-left: 150px;
    margin-top: -80px;
    }
    
    .chatboxtext1col{
        width: 370px;
    height: 529.92px;
    padding: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: #000000;
    }
    
    .chatbox2col{
        margin-top: 0.5%;
    }
    
    .chatbox121col{
        width: 81px;
    height: 64px;
    margin-top: -45.5%;
    margin-left: 63.5%;
    position: relative;
    background: rgba(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    }
    
    
    .chatbox21col{
        margin-left: -30px;
        width: 435px;
        height: 284px;
        border-radius: 50px 0px 50px 50px;
        background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext21col{
        width: 365px;
    height: 150px;
        padding: 30px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg2col{
        width: 87px;
    height: 87px;
    margin-left: 470px;
    margin-top: -60px;
    }
    
    .chatbox3col{
        margin-top: 15%;
        margin-bottom: 10%;
    }
    
    .chatbox131col{
        width: 81px;
    height: 64px;
    background: rgba(207, 240, 230, 1);
    position: relative;
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    margin-top: -32.2%;
    margin-left: 60%;
    }
    
    .chatbox31col{
        padding: 10px;
        margin-top: 60%;
        width: 358px;
       margin-left: 4%;
    height: 201px;
    border-radius: 50px 0px 50px 50px;
    background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext31col{
        padding: 20px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    color: #000000;

    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg3col{
        margin-top: -60px;
        margin-left: 440px;
    }
    
    .chatbox4col{
        margin-top: 16%;
        height: 100px;
    }
    
    .chatimg4col{
        width: 87px;
    height: 87px;
    margin-top: 50px;
    margin-left: -45px;
    position: absolute;
    }
    
    .chatbox141col{
        width: 81px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    position: relative;
    margin-top: -35.3%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: 3%;
    }
    
    .chatbox41col{
        padding: 10px;
        width: 475px;
    height: 220px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: 40px;
    margin-left: 99px;
    }
    .chatboxtext41col{
        width: 388px;
    height: 90px;
        padding: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    color: #000000;

    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    
    }
    
    .chatbox5col{
        margin-top: -9%;
        margin-bottom: 18%;
    }
    
    .chatimg5col{
        width: 87px;
    height: 87px;
        margin-top: -120px;
        margin-left: -35px;
        position: absolute;
    }
    
    .chatbox51col{
        padding: 10px;
        width: 455px;
    height: 200px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: -130px;
    margin-left: 110px;
    }
    
    .chatbox151col{
        width: 81px;
    height: 64px;
    background: #E7F2FF;
    position: relative;
    margin-top: -32.15%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: 4.8%;
    }
    
    .chatboxtext51col{
        padding: 20px;
        width: 399px;
    height: 194px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;

    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .started{
        padding: 0 50px 0 50px;
    }
    .startedboxcol{
        margin-top: 50px;
        width: 1207px;
    height: 227px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 100px;
    margin-left: 3%;
    
    }
    
    .subbuttoncol{
        margin-left: 940px;
        margin-top: -230px;
        height: 50px;
        width: 175px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }
    
    .startheadcol{
        width: 1000px;
    height: 87px;
    margin-left: 60px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    
    }
    
    .regicol{
        width: 90px;
    height: 19px;
        margin-top: 12px;
        margin-right: auto;
        margin-left: auto;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .startparacol{
        width: 613px;
    height: 62px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 95px;
    margin-top: -2%;
    margin-bottom: 14%;
    }
    
    .bluec2{
        width: 54px;
    height: 54px;
    background: rgba(7, 60, 165, 1);
    margin-left: 73%;
    margin-top: 33%;
    border-radius: 100%;
    }
    
    .whiteacol{
        width: 24px;
height: 30px;
margin-top: 20%;
margin-left: 28%;

    }
    
    .namesmcol{
        width: 250px;
    height: 30px;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .desgsmcol{
        width: 134px;
    height: 30px;
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-top: -30px;
    margin-left: 28%;
    }

    .corevaluescon{
        display: none;
    }

    .assesshead1mob{
        display: none;
    }

    .assesshead2mob{
        display: none;
    }

    .traintitle1tagtabtext{
        display: none;
    }

    .trainheadtitletab{
        display: none;
    }

    .assesstitle1tagcoltab{
        display: none;
    }

}

@media (min-width:1228px) and (max-width:1399px)
{   
    .collegepagemain{
        max-width:1300px;
        transform: scale(0.83);
        transform-origin: top;
        margin-bottom: -500px;
    }

    .colwidth1{
        width: 710px;
    }

    .colwidth2{
        width: 700px;
    }

    .colwidth3{
        width: 650px;
    }

    /* chat animation start  */
    .row-reveal {
        opacity: 0;
        transition: opacity 2s ease; /* Adjust the duration as needed */
      }

      /* .row-reveal2 {
        opacity: 0;
        transition: opacity 3s ease;
      }
      .row-reveal2.active {
        opacity: 1;
      } */
      
      .row-reveal.active {
        opacity: 1;
      }
    /* chat animation end  */

     /* box click element start */

     .clickihv1col{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clickihv2col{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickihv3col{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clickhv1col{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clickhv2col{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickhv3col{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clichv1col{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clichv2col{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clichv3col{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clihv1col{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clihv2col{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clihv3col{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    /* box click elemet end */

    .accessparacol2{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 9%;
    margin-left: 2%;
    padding: 3%;
    }

    .gifcol{
        position: absolute;
        top: -200px;
        left: 180px;
    }

     /* circle animation  */

     @keyframes moveUp {
        0% { transform: translateY(30px); }
        100% { transform: translateY(-10px); }
    }
    
    .orgcircle3, .viocircle3, .bluecircle3, .yellowcircle3 {
        
        animation: none; 
    }
    
    .scrolling .orgcircle3 {
        animation: moveUp 1s forwards; 
    }
    
    .scrolling .viocircle3 {
        animation: moveUp 1.5s forwards; 
    }
    
    .scrolling .bluecircle3 {
        animation: moveUp 2s forwards; 
    }

    .scrolling .yellowcircle3 {
        animation: moveUp 3s forwards; 
    }

    .brandhovercontainercol{
        position: relative;
    }
    
    .accesshovercontainercol{
        position: relative;
    }
    
    .autohovercontainercol{
        position: relative;
    }
    
    .hirehovercontainercol{
        position: relative;
    }
    
    .clickinscol{
        width: 34px;
        height: 34px;
        position: relative;
        left: 320px;
        top: -45px;
        cursor: pointer;
    }

    .clickinscol2{
        width: 34px;
height: 34px;
position: relative;
left: 850px;
top: -45px;
cursor: pointer;
    }

    .clickinscol3{
        width: 34px;
height: 34px;
position: relative;
left: 920px;
top: -52px;
cursor: pointer;
    }

    .clickinscol4{
        width: 34px;
height: 34px;
position: relative;
left: 1140px;
top: -8px;
cursor: pointer;
    }

    .imagecontainerbrandcol{
        position: relative;
        left: 5px;
        /* transition: opacity 0.3s ease;
        cursor: pointer;
        z-index: 1; */
    }
    
    .imagecontaineraccesscol{
        position: relative;
        top: -35px;
        /* transition: opacity 0.3s ease;
        cursor: pointer;
        z-index: 1; */
    }
    
    .imagecontainerautocol{
        position: relative;
        top: -26px;
        /* transition: opacity 0.3s ease;
        cursor: pointer;
        z-index: 1; */
    }
    
    .imagecontainerhirecol{
        position: relative;
        top: -55px;
        /* transition: opacity 0.3s ease;
        cursor: pointer;
        z-index: 1; */
    }

    .brandhovercontentcol{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        position: absolute;
        top: -0.85%;
      left: 5%;
      z-index: 2;
    
    }
    
    .accesshovercontentcol{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        position: absolute;
        margin-top: -39.8%;
      left: 5%;
      z-index: 2;
    }
    
    .autohovercontentcol{
        background-color: #ffffff;
        width: 1300px;
        height: 471px;
        position: absolute;
        margin-top: -43.8%;
      left: 5%;
      z-index: 2;
    }
    
    .hirehovercontentcol{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        position: absolute;
        margin-top: -43.5%;
      left: 5%;
      z-index: 2;
    }
    
    .brandcontentcol{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
    }
    
    .brandheadercol{
        width: 660px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 5%;
    position: absolute;
    margin-top: 2.5%;
    }
    
    .brandparacol{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .brandimagescol{
        margin-left: 75%;
        margin-top: -35%;
    }
    
    .hoverimg1brandcol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-bottom: 2%;
    position: absolute;
    }
    
    .brandcolgimg1bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -7%;
    }
    
    .hoverimg2brandcol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-bottom: 2%;
    position: absolute;
    }
    
    .brandcolgimg2bg{
        width: 141px;
        height: 145px;
        border-radius: 20px;
        margin-bottom: -7%;
        background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
        opacity: 45%;
    }
    
    .hoverimg3brandcol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-bottom: 2%;
    position: absolute;
    }
    
    .brandcolgimg3bg{
        width: 141px;
        height: 145px;
        border-radius: 20px;
        margin-bottom: 2%;
        background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
        opacity: 45%;
    }
    
    
    
    
    .accesscontentcol{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
    }
    
    .accessheadercol{
        width: 900px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 2.5%;
    margin-left: 5%;
    }
    
    .accessparacol{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .acesclgimages{
        margin-left: 75%;
        margin-top: -35%;
    }

    .accessclgimages{
        margin-left: 75%;
        margin-top: -35%;
    }

    .acescgimages{
        margin-left: 75%;
        margin-top: -35%;
    }
    
    .hoverimg1acescol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }

    .hoverimg1accesscol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }

    .hoverimg1acescl{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }

    .accesshoverimg1bgcol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    margin-bottom: -7%;
    }

    .aceshoverimg1bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    margin-bottom: -7%;
    }

    .aceshoveimg1bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    margin-bottom: -7%;
    }

    .hoverimg2accesscol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }

    .hoverimg2acescol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }

    .hoveimg2acescol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }
    
    .accesshoverimg2bgcol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -7%;
    }

    .aceshoverimg2bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    margin-bottom: -7%;
    }

    .aceshoveimg2bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -7%;
    }
    
    .hoverimg3accesscol{
        position: absolute;
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
    }

    .hoverimg3acescol{
        position: absolute;
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
    }

    .hoveimg3aces{
        position: absolute;
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
    }
    
    .accesshoverimg3bgcol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    opacity: 45%;
    margin-bottom: 2%;
    }
    
    .aceshoverimg3bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    opacity: 45%;
    margin-bottom: 2%;
    }

    .aceshoveimg3bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    margin-bottom: 2%;
    }
    
    .hireheadercol{
        width: 700px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 2.5%;
    margin-left: 5%;
    }
    
    .hireparacol{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .hirecontentcol{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
    }
    
    
    .autocontentcol{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%);
    
    }

    .dancecol-divv {
        opacity: 0;
        transform: translateY(-400px);
        transition: 1s ;
    }
      
      .dancecol-divv.visible {
        opacity: 1;
        transform: translateY(-100%);
        animation:  0.5s ease-in-out forwards ;
      }
      
      @keyframes shake {
        0% { transform: translateX(0); }
        25% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
        75% { transform: translateX(-10px); }
        100% { transform: translateX(0); }
      }
      
    .dancecol-div {
        transition: transform 0.5s ease; 
      }
      
      .dancecol-div.dancing {
        transform: translateY(-25px); 
        animation: 60s ease-out alternate; 
      }
      
      @keyframes bounce {
        from {
          transform: translateY(-25px);
        }
        to {
          transform: translateY(0);
        }
      }
    


    /* kcsjnskj */
    .bimg1xt{
        position: absolute;
        top: 1400px;
    }
    
    body{
        overflow-x: hidden;
    }
    
    .regiter1t{
        padding-top:5%;
    }
    .siginscol{
        color: white;
    }
    
    .logosclg{
        white-space: nowrap;
    }
    
    .logocl-slide{
        display: inline-block;
        animation: 120s slide infinite linear;
    }
    
    .logocl-slide img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logossclg{
        white-space: nowrap;
    }

    .logoscl-slide{
        display: inline-block;
        animation: 80s slides infinite linear;
    }
    
    .logoscl-slide img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }
    
    .contentt2{
        /* display: flex;
        justify-content: center;
    font-size: 55px;
    font-weight: 300;
    line-height: 68px;
    letter-spacing: 0em;
    font-family: 'Plus Jakarta Sans', sans-serif;
    text-align: left;
    width: 600px;
        margin-left: 40px;
        margin-top: 12%;
        position: relative; */


        width: 640px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 300;
    font-size: 38px;
    /* letter-spacing: 0em; */
  /* max-width: 600px;  */
  /* line-height: 1.2;  */
  line-height: 47.52px;
  /* margin: 0 auto;  */
  text-align: left;
        margin-left: 40px;
        margin-top: 25%;
    }
    
    /* .contentt2 br {
        content: '';
        display: block;
        margin-bottom: 10px; 
    } */
    
    .watchs2{
        padding-top: 6%;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    text-transform:capitalize;
    }
    
    .fresher1tag{
        /* align-items: center; */
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    /* text-indent: 10px; */
    margin-left: 7%;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 234px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }

    .luck2{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  font-weight: 900;
    }

    .ftext{
        top: 15px;
        position: absolute;
        left: 70px;
    }
    
    .im-container1 {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
        margin-left: 10%;
    }
    
    .background-container2 {
        position: absolute;
        bottom: 0;
        left: 60%;
        transform: translateX(-50%);
        width: 249px;
        height: 445px;
        clip-path: polygon(0 0, 100% 60%, 100% 100%, 0% 100%);
        background-color:#891A61;
    }
    
    .orgvector2 {
        /* z-index: 1;  */
        position: relative;
        margin-left: 25%;
    }
    
    .orgvector2 img {
        display: block;
    }
    
    .violetbox2{
        background: #F75B02;
        width: 203px;
    height: 203px;
    display: flex;
    position: relative;
    margin-left: 21%;
    margin-top: -28%;
    display: inline-block;
    text-align: start;
    }
    
    .vt1col{
        width: 91px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    margin-top: 9%;
    margin-left: 5%;
    }
    .im-container4{
        position: relative;
        margin-left: 19%;
        margin-top: -4%;
        /* z-index: 1; */
    }
    
    .vt2col{
        width: 83px;
    height: 19px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    margin-top: 62px;
    margin-left: -85px;
    }
    
    .yellowbox2{
        background:#F7B400;
        width: 118px;
    height: 118px;
    display: flex;
    margin-left: 19.5%;
    /* margin-right: 190px; */
    display: inline-block;
    position: relative;
    margin-top: -9%;
    text-align: start;
    }
    
    .yt1col{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    }
    
    .yt2{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 10px;
    }
    
    .im-container2, .im-container3, .im-container5 {
        position: relative;
        /* z-index: 2;  */
    }
    
    .sechead2{
        margin-top: 5%;
    }
    
    .firstsignup2{
        margin-top: -13%;
    }
    .sigins{
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 2%;
    color: rgba(255, 255, 255, 1);
    
    
    }
    
    .signincol
    {
        width: 173px;
    height: 60px;
    position: relative;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 7%;
    margin-left: 6%;
    background: rgba(7, 60, 165, 1);
    border: none;
    
    }
    
    .watchcol{
        width: 200px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 7%;
    margin-left: -380px;
    }
    
    .linetextcol{
        width: 1110px;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center ;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6%;
    }

    .linetxtcol{
        width: 1110px;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center ;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: -20px;
    margin-bottom: -1em;
    }
    
    .linebox2{
    
        width: 160px;
    height: 160px;
    display: flex;
    display: inline-block;
    position: relative;
    margin-top: -80%;
    text-align: start;
    border: 2px solid rgba(0, 0, 0, 1);
    margin-left: 28%;
    
    }
    
    .hlinebox1col{
        width: 102px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 16%;
    position: absolute;
    opacity: 30%;
    
    }
    .hlinebox2col{
        width: 40px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 30%;
    opacity: 30%;
    position: absolute;
    
    }
    
    
    .rlinebox1col{
        width: 159px;
    height: 102px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 10%;
    position: absolute;
    }
    
    .rlinebox2{
        width: 159px;
    height: 40px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 50%;
    }
    
    .lineboxtextcol{
        width: 64px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    color: rgba(0, 0, 0, 1);
    
    }
    .lineboxtext2col{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .title1tagcol{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    margin-top: 0%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 170px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    }

    .title1tagcolg{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 390px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    }

    .title2tagcol2g{
        width: 1050px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: auto;
margin-right: auto;
    }

    .imagecontainerbrand{
        position: relative;
    }
    
    .mid3contentcol .img1{
        width: 312px;
    height: 462px;
    margin-left: 80px;
    margin-top: -3px;
    border-radius: 50px;
    }
    .mid3contentcol .bimg1{
        width: 312px;
    height: 462px;
    position: absolute;
    margin-top: -0.2%;
    margin-left: -25.3%;
    border-radius: 50px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    }
    
    .mid3contentcol .bimg1text{
        width: 146px;
    height: 84px;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    opacity: 100%;
    position: relative;
    /* display: block; */
    margin-top: -100px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-left: 120px;
    
    }
    
    
    .imagecontaineraccess{
        position: relative;
    }
    
    .mid3contentcol .img2{
        width: 513px;
    height: 231px;
    margin-top: -36.7%;
    margin-left: 34%;
    margin-bottom: 40%;
    border-radius: 50px;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    .mid3contentcol .bimg2{
        width: 513px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-top: -36.7%;
    margin-left: -41.6%;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    
    }
    
    .bimg2textcol{
        width: 400px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -45%;
    margin-left: 480px;
    opacity: 100%;
    position: relative;
    display: block;
    }
    
    .imagecontainerauto{
        position: relative;
    }
    
    .title2tagcol2{
        width: 1050px;
        height: 40px;
                font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
                margin-left: auto;
                margin-right: auto;
                margin-top: 0.2em;
    }  
    
    .title2tagcol{
        width: 1050px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    }
    
    .mid3contentcol .img3{
        width: 768px;
    height: 212px;
    border-radius: 50px;
    margin-top: -19%;
    margin-left: 34%;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    
    .mid3contentcol .bimg3{
        width: 768px;
    height: 212px;
    margin-top: -17%;
    margin-left: -62.3%;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    }
    
    .mid3contentcol .bimg3text{
        width: 520px;
    height: 84px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    margin-left: 36%;
    display: block;
    margin-top: -9%;
    color:rgba(255, 255, 255, 1);
    
    }
    
    .imagecontainerhire{
        position: relative;
    }
    
    
    .mid3contentcol .img4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    margin-top: -61%;
    margin-left: 77%;
    /* opacity: 45%px; */

    }
    
    .mid3contentcol .bimg4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-left: -18.7%;
    margin-top: -38.7%;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    }
    
    .bimg4textcol{
        width: 90px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    position: relative;
    display: block;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -34%;
    margin-left: 78%;
    }
    
    .logmovcl{
        position: relative;
        margin-bottom: -4rem;
    }
    
    .phonebox3{
        margin: 14%;
    }
    
    .phonetitle1tag3{
        margin-top: 100px;
        left: -10px;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 600px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }
    
    .phoneheadtitle3{
        width: 630px;
        height: 174px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;    
    }
    
    .phonebody3lap{
        width: 455px;
        height: 155px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: -200px;
        top: 90px;
        position: relative;
    }

    .phonebody3mob{
        display: none;
    }
    
    .phone3{
        width: 485px;
    height: 391px;
    margin-top: -290px;
    margin-left: 30px;
    position: relative;
    }
    
    .orgcircle3{
        width: 240.93px;
    height: 240.93px;
    margin-top: -200px;
    border-radius: 100%;
    margin-left: 110px;
    position: relative;
    }
    
    .viocircle3{
        width: 240.93px;
    height: 240.93px;
    border-radius: 100%;
    margin-top: -120px;
    position: relative;
    opacity: 90%;
    }
    
    .viocircle3::before {
      content: '';
      position: absolute;
      margin-top: 27%;
      margin-left: -20%;
      width: 240.93px;
    height: 240.93px;
      border-radius: 50%;
      opacity: 100%;
    }
    
    .orgcircle3::before{
      content: '';
      position: absolute;
      margin-top: 35%;
      margin-left: -7%;
      width: 240.93px;
      height: 240.93px;
      border-radius: 50%;
      opacity: 90%;
    }
    
    .orgcircle3::before {
        background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
      }
      
      .viocircle3::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }
    
    .bluecircle3{
        width: 364.7px;
    height: 364.7px;
    border-radius: 100%;
    background: rgba(135, 189, 255, 1);
    margin-top: -272px;
    margin-left: 170px;
    position: absolute;
    }
    
    .yellowcircle3{
        width: 171.62px;
        height: 171.62px;
        border-radius: 100%;
        background: #F7B400; 
        margin-top: -70px;
        margin-left: 120px;
        position: absolute;
    }

    .arrow3{
        width: 256.46px;
    height: 189.91px;
    margin-left: -140px;
    margin-top: -150px;
    }
    .image-phone-container3{
        margin-left: 550px;
        margin-top: -400px;
    }
    
    .phonetitles3{
        margin-left: -200px;
    }
    
    
    
    .trainingtitles{
        margin-top: -590px;
    }
    
    .traintitle1tagcol{
        width: 225px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    }
    
    .trainheadtitlecol{
        width: 542px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 32%;
    }

    .colgciradful{
        position: relative;
        top: 5em;
    }

    .traincirclecol{
        width: 500px;
    height: 500px;
    background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -250px;
    
    }
    
    .head12col{
        width: 1075px;
    height: 105px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .head13{
        margin-top: -80px;
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .regitercol{
        width: 300px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    background: rgba(7, 60, 165, 1);
    border: none;
    position: relative;
    top: 20px;
    }

    .asescirful{
        position: relative;
        top: 10px;
    }
    
    .assesscirclecol{
        width: 500px;
    height: 500px;
    background: rgba(9, 9, 63, 0.05);
    border-radius: 100%;
    margin-left: 900px;
    
    }
    
    .assesstitle1tagcol{
        margin-top: -570px;
        margin-left: 990px;
        width: 225px;
        height: 64px;
        border-radius: 37.5px;
        background: rgba(232, 237, 252, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;    
    }
    
    .assesheadtitlecol{
        width: 693px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: right;
    margin-left: 530px;
    margin-top: -50%;
    }
    
    .assesshead1col{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 150px;
    text-align: right;
    margin-bottom: 170px;
    }
    
    .assesshead2col{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 150px;
    text-align: right;
    margin-top: -50px;
    }
    
    .regiter2col{
        width: 300px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 930px;
    margin-top: -100px;
    border: none;
    background: rgba(7, 60, 165, 1);
    }
    
    .chatcol{
        margin-top: 260px;
        margin-bottom: -2%;
    }

    .chat{
        margin-top: 200px;
    }
    
    .chatitle1tagcol{
        width: 370px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    
    }
    
    .ctextcol{
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
    }
    
    .chatheadtitlecol{
        width: 600px;
    height: 174px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .chatimg1col{
        width: 87px;
    height: 87px;
    margin-left: -2%;
    }
    
    .chatbox111col{
        clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
        width: 84.16px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    margin-left: 11.5%;
    margin-top: -104%;
    position: relative;
    }
    
    .chatbox11col{
        width: 378px;
    height: 615px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-left: 150px;
    margin-top: -80px;
    }
    
    .chatboxtext1col{
        width: 370px;
    height: 529.92px;
    padding: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatbox2col{
        margin-top: 0.5%;
    }
    
    .chatbox121col{
        width: 81px;
    height: 64px;
    margin-top: -48%;
    margin-left: 63.5%;
    position: relative;
    background: rgba(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    }
    
    
    .chatbox21col{
        margin-left: -30px;
        width: 435px;
        height: 284px;
        border-radius: 50px 0px 50px 50px;
        background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext21col{
        width: 365px;
    height: 150px;
        padding: 30px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg2col{
        width: 87px;
    height: 87px;
    margin-left: 470px;
    margin-top: -60px;
    }
    
    .chatbox3col{
        margin-top: 18%;
        margin-bottom: 10%;
    }
    
    .chatbox131col{
        width: 81px;
    height: 64px;
    background: rgba(207, 240, 230, 1);
    position: relative;
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    margin-top: -34%;
    margin-left: 60%;
    }
    
    .chatbox31col{
        padding: 10px;
        margin-top: 60%;
        width: 358px;
       margin-left: 4%;
    height: 201px;
    border-radius: 50px 0px 50px 50px;
    background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext31col{
        padding: 20px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg3col{
        margin-top: -60px;
        margin-left: 440px;
    }
    
    .chatbox4col{
        margin-top: 16%;
        height: 100px;
    }
    
    .chatimg4col{
        width: 87px;
    height: 87px;
    margin-top: 50px;
    margin-left: -45px;
    position: absolute;
    }
    
    .chatbox141col{
        width: 81px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    position: relative;
    margin-top: -37.2%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: 3.5%;
    }
    
    .chatbox41col{
        padding: 10px;
        width: 475px;
    height: 220px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: 40px;
    margin-left: 99px;
    }
    .chatboxtext41col{
        width: 388px;
    height: 90px;
        padding: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    
    }
    
    .chatbox5col{
        margin-top: -9%;
        margin-bottom: 18%;
    }
    
    .chatimg5col{
        width: 87px;
    height: 87px;
        margin-top: -120px;
        margin-left: -35px;
        position: absolute;
    }
    
    .chatbox51col{
        padding: 10px;
        width: 455px;
    height: 200px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: -130px;
    margin-left: 110px;
    }
    
    .chatbox151col{
        width: 81px;
    height: 64px;
    background: #E7F2FF;
    position: relative;
    margin-top: -33.8%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: 5.4%;
    }
    
    .chatboxtext51col{
        padding: 20px;
        width: 399px;
    height: 194px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    color: #000000;

    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .startedcol{
        padding: 0 50px 0 50px;
        margin-bottom: 5%;
    }

    .startedboxcol{
        margin-top: 50px;
        width: 1207px;
    height: 227px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 100px;
    
    }
    
    .subbuttoncol{
        margin-left: 910px;
        margin-top: -240px;
        height: 50px;
        width: 175px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }
    
    .startheadcol{
        width: 1000px;
    height: 87px;
    margin-left: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    
    }
    
    .regicol{
        width: 90px;
    height: 19px;
        margin-top: 12px;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    
    }
    
    .startparacol{
        width: 613px;
    height: 62px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 40px;
    margin-top: -1.5%;
    }
    
    .bluec2{
        width: 54px;
    height: 54px;
    background: rgba(7, 60, 165, 1);
    margin-left: 73%;
    margin-top: 34%;
    border-radius: 100%;
    }
    
    .whiteacol{
        position: relative;
        width: 24px;
    height: 30px;
    top: 10px;
    left: 15px;
    }
    
    .namesmcol{
        width: 250px;
    height: 30px;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .desgsmcol{
        width: 134px;
    height: 30px;
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-top: -30px;
    margin-left: 28%;
    }

    .corevaluescon{
        display: none;
    }

    .assesshead1mob{
        display: none;
    }

    .assesshead2mob{
        display: none;
    }

    .traintitle1tagtabtext{
        display: none;
    }

    .trainheadtitletab{
        display: none;
    }

    .assesstitle1tagcoltab{
        display: none;
    }

    .head12tabcol{
        display: none;
    }

}


@media (min-width:1028px) and (max-width:1227px) {   
    .collegepagemain{
        max-width:1300px;
        transform: scale(0.83);
        transform-origin: top;
        margin-bottom: -600px;
    }

    .colwidth1{
        width: 710px;
    }

    .colwidth2{
        width: 700px;
    }

    .colwidth3{
        width: 650px;
    }

    /* chat animation start  */
    .row-reveal {
        opacity: 0;
        transition: opacity 2s ease; /* Adjust the duration as needed */
      }

      /* .row-reveal2 {
        opacity: 0;
        transition: opacity 3s ease;
      }
      .row-reveal2.active {
        opacity: 1;
      } */
      
      .row-reveal.active {
        opacity: 1;
      }
    /* chat animation end  */

     /* box click element start */

     .clickihv1col{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clickihv2col{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickihv3col{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clickhv1col{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clickhv2col{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickhv3col{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clichv1col{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clichv2col{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clichv3col{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clihv1col{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clihv2col{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clihv3col{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    /* box click elemet end */

    .accessparacol2{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 9%;
    margin-left: 2%;
    padding: 3%;
    }

    .gifcol{
        position: absolute;
        top: -200px;
        left: 180px;
    }

     /* circle animation  */

     @keyframes moveUp {
        0% { transform: translateY(30px); }
        100% { transform: translateY(-10px); }
    }
    
    .orgcircle3, .viocircle3, .bluecircle3, .yellowcircle3 {
        
        animation: none; 
    }
    
    .scrolling .orgcircle3 {
        animation: moveUp 1s forwards; 
    }
    
    .scrolling .viocircle3 {
        animation: moveUp 1.5s forwards; 
    }
    
    .scrolling .bluecircle3 {
        animation: moveUp 2s forwards; 
    }

    .scrolling .yellowcircle3 {
        animation: moveUp 3s forwards; 
    }

    .brandhovercontainercol{
        position: relative;
    }
    
    .accesshovercontainercol{
        position: relative;
    }
    
    .autohovercontainercol{
        position: relative;
    }
    
    .hirehovercontainercol{
        position: relative;
    }
    
    .clickinscol{
        width: 34px;
        height: 34px;
        position: relative;
        left: 180px;
        top: -80px;
        cursor: pointer;
    }

    .clickinscol2{
        width: 34px;
height: 34px;
position: relative;
left: 750px;
top: -45px;
cursor: pointer;
    }

    .clickinscol3{
        width: 34px;
height: 34px;
position: relative;
left: 850px;
top: -52px;
cursor: pointer;
    }

    .clickinscol4{
        width: 34px;
height: 34px;
position: relative;
left: 960px;
top: -8px;
cursor: pointer;
    }

    .imagecontainerbrandcol{
        position: relative;
        left: 5px;
        /* transition: opacity 0.3s ease;
        cursor: pointer;
        z-index: 1; */
    }
    
    .imagecontaineraccesscol{
        position: relative;
        top: -35px;
        /* transition: opacity 0.3s ease;
        cursor: pointer;
        z-index: 1; */
    }
    
    .imagecontainerautocol{
        position: relative;
        top: -26px;
        /* transition: opacity 0.3s ease;
        cursor: pointer;
        z-index: 1; */
    }
    
    .imagecontainerhirecol{
        position: relative;
        top: -55px;
        /* transition: opacity 0.3s ease;
        cursor: pointer;
        z-index: 1; */
    }

    .brandhovercontentcol{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        position: absolute;
        top: -1.2%;
      left: 0%;
      z-index: 2;
    
    }
    
    .accesshovercontentcol{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        position: absolute;
        margin-top: -42.8%;
      left: 0%;
      z-index: 2;
    }
    
    .autohovercontentcol{
        background-color: #ffffff;
        width: 1300px;
        height: 471px;
        position: absolute;
        margin-top: -46%;
      left: 0%;
      z-index: 2;
    }
    
    .hirehovercontentcol{
        background-color: #ffffff;
        width: 1300px;
        height: 470px;
        position: absolute;
        margin-top: -46%;
      left: 0%;
      z-index: 2;
    }
    
    .brandcontentcol{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
    }
    
    .brandheadercol{
        width: 660px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 5%;
    position: absolute;
    margin-top: 2.5%;
    }
    
    .brandparacol{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .brandimagescol{
        margin-left: 75%;
        margin-top: -35%;
    }
    
    .hoverimg1brandcol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-bottom: 2%;
    position: absolute;
    }
    
    .brandcolgimg1bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -7%;
    }
    
    .hoverimg2brandcol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-bottom: 2%;
    position: absolute;
    }
    
    .brandcolgimg2bg{
        width: 141px;
        height: 145px;
        border-radius: 20px;
        margin-bottom: -7%;
        background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
        opacity: 45%;
    }
    
    .hoverimg3brandcol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-bottom: 2%;
    position: absolute;
    }
    
    .brandcolgimg3bg{
        width: 141px;
        height: 145px;
        border-radius: 20px;
        margin-bottom: 2%;
        background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
        opacity: 45%;
    }
    
    
    
    
    .accesscontentcol{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
    }
    
    .accessheadercol{
        width: 900px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 2.5%;
    margin-left: 5%;
    }
    
    .accessparacol{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .acesclgimages{
        margin-left: 75%;
        margin-top: -35%;
    }

    .accessclgimages{
        margin-left: 75%;
        margin-top: -35%;
    }

    .acescgimages{
        margin-left: 75%;
        margin-top: -35%;
    }
    
    .hoverimg1acescol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }

    .hoverimg1accesscol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }

    .hoverimg1acescl{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }

    .accesshoverimg1bgcol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    margin-bottom: -7%;
    }

    .aceshoverimg1bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    margin-bottom: -7%;
    }

    .aceshoveimg1bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    margin-bottom: -7%;
    }

    .hoverimg2accesscol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }

    .hoverimg2acescol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }

    .hoveimg2acescol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }
    
    .accesshoverimg2bgcol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -7%;
    }

    .aceshoverimg2bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    margin-bottom: -7%;
    }

    .aceshoveimg2bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -7%;
    }
    
    .hoverimg3accesscol{
        position: absolute;
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
    }

    .hoverimg3acescol{
        position: absolute;
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
    }

    .hoveimg3aces{
        position: absolute;
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
    }
    
    .accesshoverimg3bgcol{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    opacity: 45%;
    margin-bottom: 2%;
    }
    
    .aceshoverimg3bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    opacity: 45%;
    margin-bottom: 2%;
    }

    .aceshoveimg3bg{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    margin-bottom: 2%;
    }
    
    .hireheadercol{
        width: 700px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 2.5%;
    margin-left: 5%;
    }
    
    .hireparacol{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .hirecontentcol{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
    }
    
    
    .autocontentcol{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%);
    
    }

    .dancecol-divv {
        opacity: 0;
        transform: translateY(-400px);
        transition: 1s ;
    }
      
      .dancecol-divv.visible {
        opacity: 1;
        transform: translateY(-100%);
        animation:  0.5s ease-in-out forwards ;
      }
      
      @keyframes shake {
        0% { transform: translateX(0); }
        25% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
        75% { transform: translateX(-10px); }
        100% { transform: translateX(0); }
      }
      
    .dancecol-div {
        transition: transform 0.5s ease; 
      }
      
      .dancecol-div.dancing {
        transform: translateY(-25px); 
        animation: 60s ease-out alternate; 
      }
      
      @keyframes bounce {
        from {
          transform: translateY(-25px);
        }
        to {
          transform: translateY(0);
        }
      }
    


    /* kcsjnskj */
    .bimg1xt{
        position: absolute;
        top: 1400px;
    }
    
    body{
        overflow-x: hidden;
    }
    
    .regiter1t{
        padding-top:5%;
    }
    .siginscol{
        color: white;
    }
    
    .logosclg{
        white-space: nowrap;
    }
    
    .logocl-slide{
        display: inline-block;
        animation: 120s slide infinite linear;
    }
    
    .logocl-slide img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logossclg{
        white-space: nowrap;
    }

    .logoscl-slide{
        display: inline-block;
        animation: 80s slides infinite linear;
    }
    
    .logoscl-slide img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }
    
    .contentt2{
        /* display: flex;
        justify-content: center;
    font-size: 55px;
    font-weight: 300;
    line-height: 68px;
    letter-spacing: 0em;
    font-family: 'Plus Jakarta Sans', sans-serif;
    text-align: left;
    width: 600px;
        margin-left: 40px;
        margin-top: 12%;
        position: relative; */


        width: 640px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 300;
    font-size: 38px;
    /* letter-spacing: 0em; */
  /* max-width: 600px;  */
  /* line-height: 1.2;  */
  line-height: 47.52px;
  /* margin: 0 auto;  */
  text-align: left;
        margin-left: 40px;
        margin-top: 25%;
    }
    
    /* .contentt2 br {
        content: '';
        display: block;
        margin-bottom: 10px; 
    } */
    
    .watchs2{
        padding-top: 6%;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    text-transform:capitalize;
    }
    
    .fresher1tag{
        /* align-items: center; */
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    /* text-indent: 10px; */
    margin-left: 7%;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 234px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }

    .luck2{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  font-weight: 900;
    }

    .ftext{
        top: 15px;
        position: absolute;
        left: 70px;
    }
    
    .im-container1 {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
        margin-left: 10%;
    }
    
    .background-container2 {
        position: absolute;
        bottom: 0;
        left: 60%;
        transform: translateX(-50%);
        width: 249px;
        height: 445px;
        clip-path: polygon(0 0, 100% 60%, 100% 100%, 0% 100%);
        background-color:#891A61;
    }
    
    .orgvector2 {
        /* z-index: 1;  */
        position: relative;
        margin-left: 25%;
    }
    
    .orgvector2 img {
        display: block;
    }
    
    .violetbox2{
        background: #F75B02;
        width: 203px;
    height: 203px;
    display: flex;
    position: relative;
    margin-left: 21%;
    margin-top: -28%;
    display: inline-block;
    text-align: start;
    }
    
    .vt1col{
        width: 91px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    margin-top: 9%;
    margin-left: 5%;
    }
    .im-container4{
        position: relative;
        margin-left: 19%;
        margin-top: -4%;
        /* z-index: 1; */
    }
    
    .vt2col{
        width: 83px;
    height: 19px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    margin-top: 62px;
    margin-left: -85px;
    }
    
    .yellowbox2{
        background:#F7B400;
        width: 118px;
    height: 118px;
    display: flex;
    margin-left: 19.5%;
    /* margin-right: 190px; */
    display: inline-block;
    position: relative;
    margin-top: -9%;
    text-align: start;
    }
    
    .yt1col{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    }
    
    .yt2{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 10px;
    }
    
    .im-container2, .im-container3, .im-container5 {
        position: relative;
        /* z-index: 2;  */
    }
    
    .sechead2{
        margin-top: 5%;
    }
    
    .firstsignup2{
        margin-top: -13%;
    }
    .sigins{
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 2%;
    color: rgba(255, 255, 255, 1);
    
    
    }
    
    .signincol
    {
        width: 173px;
    height: 60px;
    position: relative;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 7%;
    margin-left: 6%;
    background: rgba(7, 60, 165, 1);
    border: none;
    
    }
    
    .watchcol{
        width: 200px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 7%;
    margin-left: -380px;
    }
    
    .linetextcol{
        width: 1110px;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center ;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6%;
    }

    .linetxtcol{
        width: 1110px;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center ;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: -20px;
    margin-bottom: -1em;
    }
    
    .linebox2{
    
        width: 160px;
    height: 160px;
    display: flex;
    display: inline-block;
    position: relative;
    margin-top: -80%;
    text-align: start;
    border: 2px solid rgba(0, 0, 0, 1);
    margin-left: 28%;
    
    }
    
    .hlinebox1col{
        width: 102px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 16%;
    position: absolute;
    opacity: 30%;
    
    }
    .hlinebox2col{
        width: 40px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 30%;
    opacity: 30%;
    position: absolute;
    
    }
    
    
    .rlinebox1col{
        width: 159px;
    height: 102px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 10%;
    position: absolute;
    }
    
    .rlinebox2{
        width: 159px;
    height: 40px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 50%;
    }
    
    .lineboxtextcol{
        width: 64px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    color: rgba(0, 0, 0, 1);
    
    }
    .lineboxtext2col{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .title1tagcol{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    margin-top: 0%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 170px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    }

    .title1tagcolg{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 390px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    }

    .title2tagcol2g{
        width: 1050px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: auto;
margin-right: auto;
    }

    .imagecontainerbrand{
        position: relative;
    }
    
    .mid3contentcol .img1{
        width: 312px;
    height: 462px;
    margin-left: 10px;
    margin-top: -3px;
    border-radius: 50px;
    }
    .mid3contentcol .bimg1{
        width: 312px;
    height: 462px;
    position: absolute;
    margin-top: -0.2%;
    margin-left: -27.8%;
    border-radius: 50px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    }
    
    .mid3contentcol .bimg1text{
        width: 146px;
    height: 84px;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    opacity: 100%;
    position: relative;
    /* display: block; */
    margin-top: -110px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-left: 40px;
    
    }
    
    
    .imagecontaineraccess{
        position: relative;
    }
    
    .mid3contentcol .img2{
        width: 513px;
    height: 231px;
    margin-top: -39.7%;
    margin-left: 31%;
    margin-bottom: 40%;
    border-radius: 50px;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    .mid3contentcol .bimg2{
        width: 513px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-top: -39.7%;
    margin-left: -45.8%;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    
    }
    
    .bimg2textcol{
        width: 400px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -45%;
    margin-left: 370px;
    opacity: 100%;
    position: relative;
    display: block;
    }
    
    .imagecontainerauto{
        position: relative;
    }
    
    .title2tagcol2{
        width: 1050px;
        height: 40px;
                font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
                margin-left: auto;
                margin-right: auto;
                margin-top: 0.2em;
    }  
    
    .title2tagcol{
        width: 1050px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    }
    
    .mid3contentcol .img3{
        width: 768px;
    height: 212px;
    border-radius: 50px;
    margin-top: -20%;
    margin-left: 31%;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    
    .mid3contentcol .bimg3{
        width: 768px;
    height: 212px;
    margin-top: -18.2%;
    margin-left: -68.5%;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    }
    
    .mid3contentcol .bimg3text{
        width: 520px;
    height: 84px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    margin-left: 33%;
    display: block;
    margin-top: -11%;
    color:rgba(255, 255, 255, 1);
    
    }
    
    .imagecontainerhire{
        position: relative;
    }
    
    
    .mid3contentcol .img4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    margin-top: -64%;
    margin-left: 78.5%;
    /* opacity: 45%px; */

    }
    
    .mid3contentcol .bimg4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-left: -20.5%;
    margin-top: -41%;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    }
    
    .bimg4textcol{
        width: 90px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    position: relative;
    display: block;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -35%;
    margin-left: 80%;
    }
    
    .logmovcl{
        position: relative;
        margin-bottom: -4rem;
    }
    
    .phonebox3{
        margin: 14%;
    }
    
    .phonetitle1tag3{
        margin-top: 100px;
        left: -10px;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 600px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }
    
    .phoneheadtitle3{
        width: 630px;
        height: 174px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;    
    }
    
    .phonebody3lap{
        width: 455px;
        height: 155px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: -200px;
        top: 90px;
        position: relative;
    }

    .phonebody3mob{
        display: none;
    }
    
    .phone3{
        width: 485px;
    height: 391px;
    margin-top: -290px;
    margin-left: 30px;
    position: relative;
    }
    
    .orgcircle3{
        width: 240.93px;
    height: 240.93px;
    margin-top: -200px;
    border-radius: 100%;
    margin-left: 110px;
    position: relative;
    }
    
    .viocircle3{
        width: 240.93px;
    height: 240.93px;
    border-radius: 100%;
    margin-top: -120px;
    position: relative;
    opacity: 90%;
    }
    
    .viocircle3::before {
      content: '';
      position: absolute;
      margin-top: 27%;
      margin-left: -20%;
      width: 240.93px;
    height: 240.93px;
      border-radius: 50%;
      opacity: 100%;
    }
    
    .orgcircle3::before{
      content: '';
      position: absolute;
      margin-top: 35%;
      margin-left: -7%;
      width: 240.93px;
      height: 240.93px;
      border-radius: 50%;
      opacity: 90%;
    }
    
    .orgcircle3::before {
        background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
      }
      
      .viocircle3::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }
    
    .bluecircle3{
        width: 364.7px;
    height: 364.7px;
    border-radius: 100%;
    background: rgba(135, 189, 255, 1);
    margin-top: -272px;
    margin-left: 170px;
    position: absolute;
    }
    
    .yellowcircle3{
        width: 171.62px;
        height: 171.62px;
        border-radius: 100%;
        background: #F7B400; 
        margin-top: -70px;
        margin-left: 120px;
        position: absolute;
    }

    .arrow3{
        width: 256.46px;
    height: 189.91px;
    margin-left: -140px;
    margin-top: -150px;
    }
    .image-phone-container3{
        margin-left: 550px;
        margin-top: -400px;
    }
    
    .phonetitles3{
        margin-left: -200px;
    }
    
    
    
    .trainingtitles{
        margin-top: -590px;
    }
    
    .traintitle1tagcol{
        width: 225px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    }
    
    .trainheadtitlecol{
        width: 542px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 32%;
    }

    .colgciradful{
        position: relative;
        top: 5em;
    }

    .traincirclecol{
        width: 500px;
    height: 500px;
    background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -250px;
    
    }
    
    .head12col{
        width: 1075px;
    height: 105px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .head13{
        margin-top: -80px;
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .regitercol{
        width: 300px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    background: rgba(7, 60, 165, 1);
    border: none;
    position: relative;
    top: 20px;
    }

    .asescirful{
        position: relative;
        top: 10px;
    }
    
    .assesscirclecol{
        width: 500px;
    height: 500px;
    background: rgba(9, 9, 63, 0.05);
    border-radius: 100%;
    margin-left: 900px;
    
    }
    
    .assesstitle1tagcol{
        margin-top: -570px;
        margin-left: 990px;
        width: 225px;
        height: 64px;
        border-radius: 37.5px;
        background: rgba(232, 237, 252, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;    
    }
    
    .assesheadtitlecol{
        width: 693px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: right;
    margin-left: 530px;
    margin-top: -50%;
    }
    
    .assesshead1col{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 150px;
    text-align: right;
    margin-bottom: 170px;
    }
    
    .assesshead2col{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 150px;
    text-align: right;
    margin-top: -50px;
    }
    
    .regiter2col{
        width: 300px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 930px;
    margin-top: -100px;
    border: none;
    background: rgba(7, 60, 165, 1);
    }
    
    .chatcol{
        margin-top: 260px;
        margin-bottom: -2%;
    }

    .chat{
        margin-top: 200px;
    }
    
    .chatitle1tagcol{
        width: 370px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    
    }
    
    .ctextcol{
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
    }
    
    .chatheadtitlecol{
        width: 600px;
    height: 174px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .chatimg1col{
        width: 87px;
    height: 87px;
    margin-left: -2%;
    }
    
    .chatbox111col{
        clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
        width: 84.16px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    margin-left: 11.5%;
    margin-top: -104%;
    position: relative;
    }
    
    .chatbox11col{
        width: 378px;
    height: 615px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-left: 150px;
    margin-top: -80px;
    }
    
    .chatboxtext1col{
        width: 370px;
    height: 529.92px;
    padding: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatbox2col{
        margin-top: 0.5%;
    }
    
    .chatbox121col{
        width: 81px;
    height: 64px;
    margin-top: -48%;
    margin-left: 63.5%;
    position: relative;
    background: rgba(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    }
    
    
    .chatbox21col{
        margin-left: -30px;
        width: 435px;
        height: 284px;
        border-radius: 50px 0px 50px 50px;
        background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext21col{
        width: 365px;
    height: 150px;
        padding: 30px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg2col{
        width: 87px;
    height: 87px;
    margin-left: 470px;
    margin-top: -60px;
    }
    
    .chatbox3col{
        margin-top: 18%;
        margin-bottom: 10%;
    }
    
    .chatbox131col{
        width: 81px;
    height: 64px;
    background: rgba(207, 240, 230, 1);
    position: relative;
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    margin-top: -34%;
    margin-left: 60%;
    }
    
    .chatbox31col{
        padding: 10px;
        margin-top: 60%;
        width: 358px;
       margin-left: 4%;
    height: 201px;
    border-radius: 50px 0px 50px 50px;
    background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext31col{
        padding: 20px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg3col{
        margin-top: -60px;
        margin-left: 440px;
    }
    
    .chatbox4col{
        margin-top: 16%;
        height: 100px;
    }
    
    .chatimg4col{
        width: 87px;
    height: 87px;
    margin-top: 50px;
    margin-left: -45px;
    position: absolute;
    }
    
    .chatbox141col{
        width: 81px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    position: relative;
    margin-top: -37.2%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: 3.5%;
    }
    
    .chatbox41col{
        padding: 10px;
        width: 475px;
    height: 220px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: 40px;
    margin-left: 99px;
    }
    .chatboxtext41col{
        width: 388px;
    height: 90px;
        padding: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    
    }
    
    .chatbox5col{
        margin-top: -9%;
        margin-bottom: 18%;
    }
    
    .chatimg5col{
        width: 87px;
    height: 87px;
        margin-top: -120px;
        margin-left: -35px;
        position: absolute;
    }
    
    .chatbox51col{
        padding: 10px;
        width: 455px;
    height: 200px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: -130px;
    margin-left: 110px;
    }
    
    .chatbox151col{
        width: 81px;
    height: 64px;
    background: #E7F2FF;
    position: relative;
    margin-top: -33.8%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: 5.4%;
    }
    
    .chatboxtext51col{
        padding: 20px;
        width: 399px;
    height: 194px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    color: #000000;

    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .startedcol{
        padding: 0 50px 0 50px;
        margin-bottom: 5%;
    }

    .startedboxcol{
        margin-top: 50px;
        width: 1207px;
    height: 227px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 100px;
    
    }
    
    .subbuttoncol{
        margin-left: 910px;
        margin-top: -240px;
        height: 50px;
        width: 175px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }
    
    .startheadcol{
        width: 1000px;
    height: 87px;
    margin-left: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    
    }
    
    .regicol{
        width: 90px;
    height: 19px;
        margin-top: 12px;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    
    }
    
    .startparacol{
        width: 613px;
    height: 62px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 40px;
    margin-top: -1.5%;
    }
    
    .bluec2{
        width: 54px;
    height: 54px;
    background: rgba(7, 60, 165, 1);
    margin-left: 73%;
    margin-top: 34%;
    border-radius: 100%;
    }
    
    .whiteacol{
        position: relative;
        width: 24px;
    height: 30px;
    top: 10px;
    left: 15px;
    }
    
    .namesmcol{
        width: 250px;
    height: 30px;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .desgsmcol{
        width: 134px;
    height: 30px;
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-top: -30px;
    margin-left: 28%;
    }

    .corevaluescon{
        display: none;
    }

    .assesshead1mob{
        display: none;
    }

    .assesshead2mob{
        display: none;
    }

    .traintitle1tagtabtext{
        display: none;
    }

    .trainheadtitletab{
        display: none;
    }

    .assesstitle1tagcoltab{
        display: none;
    }

    .head12tabcol{
        display: none;
    }

}


@media (min-width:1024.5px) and (max-width:1028px){
    .corevaluescon{
        display: none !important;
    }

    .title2tagcol{
        width: 1050px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    }

    .title2tagcol2{
        width: 1050px;
        height: 40px;
                font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
                margin-left: auto;
                margin-right: auto;
                margin-top: 0.2em;
    } 

    .sechead2{
        margin-top: -20px;
    }
}


@media (min-width:831px) and (max-width:991.5px){
    .title2tagcol{
        position: relative;
        font-size: 50px;
        font-weight: 400;
        width: 740px;
        text-align: center;
        left: -20px;
        top: 50px;
    }

    .title2tagcol2{
        position: relative;
        font-size: 25px;
        font-weight: 400;
        text-align: center;
        top: 50px;
    }

    .brandppcol{
        position: relative;
        top: 40px;
        margin-left: auto;
        margin-right: auto;
        width: 530px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
    }

    .hireppcol{
        position: relative;
        top: 50px;
        margin-left: auto;
        margin-right: auto;
        width: 535px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
    }

    .digitalppcol{
        position: relative;
        top: 80px;
        margin-left: auto;
        margin-right: auto;
        width: 545px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
    }

    .digitalhcol{
        position: relative;
        top: 40px;
position: relative;
        margin-left: 0%;
        width: 588px;
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    }

    .trainppcol{
        position: relative;
        top: 60px;
        margin-left: auto;
        margin-right: auto;
        width: 525px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
    }

    .sechead2{
        margin-top: 0px;
    }
}


@media (min-width:992px) and (max-width:1024px){
    .title2tagcol{
        height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 50px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 3%;
            }

            .title2tagcol2{
                width: 740px;
        height: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: -1em;
            }

            .brandppcol{
                margin-top: 8%;
                margin-left: auto;
                margin-right: auto;
                width: 706px;
                height: 175px;
         font-family: 'Plus Jakarta Sans', sans-serif;
         font-size: 20px;
         font-weight: 400;
         line-height: 25px;
         letter-spacing: 0em;
         text-align: left;
         opacity: 80%;
         color: rgba(255, 255, 255, 1);
            }

            .hireppcol{
                margin-top: 9%;
                margin-left: auto;
                margin-right: auto;
                width: 706px;
                height: 175px;
         font-family: 'Plus Jakarta Sans', sans-serif;
         font-size: 20px;
         font-weight: 400;
         line-height: 25px;
         letter-spacing: 0em;
         text-align: left;
         opacity: 80%;
         color: rgba(255, 255, 255, 1);
            }

            .digitalppcol{
                margin-top: 12%;
                margin-left: auto;
                margin-right: auto;
                width: 706px;
                height: 175px;
         font-family: 'Plus Jakarta Sans', sans-serif;
         font-size: 20px;
         font-weight: 400;
         line-height: 25px;
         letter-spacing: 0em;
         text-align: left;
         opacity: 80%;
         color: rgba(255, 255, 255, 1);
            }

            .digitalhcol{
                top: 15%;
        position: relative;
                margin-left: 2%;
                width: 588px;
        height: 42px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
                font-weight: 900;
                line-height: 42px;
                letter-spacing: 0em;
                text-align: center;
            }

            .trainppcol{
                margin-top: 10%;
                margin-left: auto;
                margin-right: auto;
                width: 706px;
                height: 175px;
         font-family: 'Plus Jakarta Sans', sans-serif;
         font-size: 20px;
         font-weight: 400;
         line-height: 25px;
         letter-spacing: 0em;
         text-align: left;
         opacity: 80%;
         color: rgba(255, 255, 255, 1);
            }

            .sechead2{
                margin-top: 0px;
            }
}


@media (min-width:831px) and (max-width:1027px){

    .chatbox1col1:hover ,
    .chatbox2col:hover,
    .chatbox3col:hover,
    .chatbox4col:hover,
    .chatbox5col:hover
    {
        transform: none; /* Scale up the element on hover */
      }

     /* box click element start */

     .clickihv1col{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clickihv2col{
        width: 34px;
height: 34px;
position: relative;
top: -15px;
left: 90px;
    }

    .clickihv3col{
        width: 34px;
height: 34px;
position: relative;
top: -45px;
left: 90px;
    }

    .clickhv1col{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clickhv2col{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickhv3col{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clichv1col{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clichv2col{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clichv3col{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clihv1col{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clihv2col{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clihv3col{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    /* box click elemet end */

     /* circle animation  */

     @keyframes moveUp {
        0% { transform: translateY(30px); }
        100% { transform: translateY(-10px); }
    }
    
    .orgcircle3, .viocircle3, .bluecircle3, .yellowcircle3 {
        
        animation: none; 
    }
    
    .scrolling .orgcircle3 {
        animation: moveUp 1s forwards; 
    }
    
    .scrolling .viocircle3 {
        animation: moveUp 1.5s forwards; 
    }
    
    .scrolling .bluecircle3 {
        animation: moveUp 2s forwards; 
    }

    .scrolling .yellowcircle3 {
        animation: moveUp 3s forwards; 
    }


    /* circle animation end  */

    .chatcol{
        margin-top: 24%;
    }
    

    /* box animation: ; */
    
    .dancecol-divv {
        opacity: 0;
        transform: translateY(-400px);
        transition: 1s ;
    }
      
      .dancecol-divv.visible {
        opacity: 1;
        transform: translateY(0%);
        animation:  0.5s ease-in-out forwards ;
      }
      
      @keyframes shake {
        0% { transform: translateX(0); }
        25% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
        75% { transform: translateX(-10px); }
        100% { transform: translateX(0); }
      }
      
    .dancecol-div {
        transition: transform 0.5s ease; 
      }
      
      .dancecol-div.dancing {
        transform: translateY(-25px); 
        animation: 60s ease-out alternate; 
      }
      
      @keyframes bounce {
        from {
          transform: translateY(-25px);
        }
        to {
          transform: translateY(0);
        }
      }
    .gifcol{
        position: absolute;
        top: -150px;
        left: 360px;
    }
    .logosclg{
        white-space: nowrap;
    }

    .colimgmovup{
        margin-left: auto;
        margin-right: auto;
    }
    
    .logocl-slide{
        display: inline-block;
        animation: 120s slide infinite linear;
    }
    
    .logocl-slide img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logossclg{
        white-space: nowrap;
    }
    
    .logoscl-slide{
        display: inline-block;
        animation: 80s slides infinite linear;
    }
    
    .logoscl-slide img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }

    .mid3contentcol{
        display: none;
    }

    .luck2{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  /* display: inline-block; */
                  font-weight: 900;
    
    }

    html,body{

        overflow-x: hidden;
    }

    .namesmcol{
        width: 250px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 22px;
font-weight: 600;
line-height: 30px;
text-align: left;

    }

    .desgsmcol{
        width: 134px;
        height: 30px;
        font-size: 22px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-style: italic;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
margin-top: -30px;
margin-left: 30%;
    }

    /* .brandppcol{
        margin-top: 8%;
        margin-left: auto;
        margin-right: auto;
        width: 706px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
    } */

    /* .hireppcol{
        margin-top: 9%;
        margin-left: auto;
        margin-right: auto;
        width: 706px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);

    } */

    /* .digitalppcol{
        margin-top: 12%;
        margin-left: auto;
        margin-right: auto;
        width: 706px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
    } */

    /* .trainppcol{
        margin-top: 10%;
        margin-left: auto;
        margin-right: auto;
        width: 706px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);

    } */

    .brandhcol{
        position: relative;
        top: 15%;
        margin-left: 4.5%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    width: 380px;
height: 42px;
    }

    

    .hirehcol{
        width: 526px;
height: 42px;
top: 18%;
position: relative;
        margin-left: 5%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;
    }

    /* .digitalhcol{
        top: 15%;
position: relative;
        margin-left: 2%;
        width: 588px;
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    } */

    .trainhcol{
        top: 15%;
position: relative;
        margin-left: 4%;
width: 434px;
height: 24px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    }

    /* .instlogo{
     width: 100px;
     height: 100px;   
    } */

    .arrow3{
        display: none;
    }



    /* .brandh{
        position: relative;
        top: 18%;
        margin-left: 15%;
        width: 250px;
        height: 24px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;

    }

    .hireh{
        width: 42px;
height: 24px;
top: 22%;
position: relative;
        margin-left: 17%;

        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;
    }

    .digitalh{
        top: 18%;
position: relative;
        margin-left: 16%;
        width: 290px;
height: 48px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 900;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
    }

    .trainh{
        top: 22%;
position: relative;
        margin-left: 17%;
width: 259px;
height: 24px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 900;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
    } */




    .brandcol{
        width: 84.2%;
        height: 306px;
        background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
    border-radius: 50px;
    margin-top: 10%;
    margin-bottom: 20px;
    /* margin-left: 8.5%; */
    margin-left: auto;
    margin-right: auto;
    }
    
    .hirecol{
        width: 84.2%;
height: 307px;
border-radius: 50px;
        background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
    border-radius: 50px;
    margin-bottom: 20px;
    /* margin-left: 8.5%; */
    margin-left: auto;
    margin-right: auto;
    }
    
    .digitalcol{
        width: 84.2%;
height: 307px;
        border-radius: 50px;
        background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%); font-size: 20px;
        margin-bottom: 20px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        /* margin-left: 8.5%; */
        margin-left: auto;
    margin-right: auto;
    }
    
    .traincol{
        width: 84.2%;
height: 307px;
        border-radius: 50px;
        background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
        margin-bottom: 90px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        /* margin-left: 8.5%; */
        margin-left: auto;
    margin-right: auto;
    }


    .regicol{
        font-family: 'Plus Jakarta Sans', sans-serif;
        color: rgba(7, 60, 165, 1);
        width: 145.25px;
height: 30px;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-top: 4%;
margin-left: 26%;

    }

    .bluec2{
        width: 60.18px;
height: 60.18px;
margin-top: -540px;
position: relative;
margin-left: 20%;
background: rgba(7, 60, 165, 1);
border-radius: 100%;
    }

    .whiteacol{
        width: 26.75px;
height: 33.43px;
margin-left: 30%;
margin-top: 20%;

    }

    /* .sechead2{
        margin-top: 0px;
    } */

    .startparacol{
        width: 612.49px;
height: 50px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
/* top: 215px;
left: 20px; */
margin-left: 22%;
margin-top: -43%;
margin-bottom: 10%;
position: relative;


    }

    .startheadcol{
        width: 510px;
height: 40px;
/* margin-left: 18%; */
margin-left: auto;
margin-right: auto;
margin-top: 20px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 32px;
font-weight: 400;
line-height: 40px;
letter-spacing: 0em;
text-align: center;
color: rgb(255, 255, 255, 1);
    }

    .subbuttoncol{
        /* top: 40px; */
        /* margin-top: -10px; */
        top: -200px;
        position: relative;
        margin-left: 300px;
        width: 331px;
height: 61px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }

    .startedboxcol{
        margin-top: 50px;
        width: 737px;
height: 252px;
        margin-left: 14%;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 100px;
    position: relative;
    
    }

    .chatboxtext51col{
        width: 508px;
height: 374px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
color: #000000;

letter-spacing: 0em;
text-align: left;
padding: 10%;



    }

    .chatbox51col{
        width: 529px;
height: 469px;
border-radius: 20px 20px 0px 20px;
margin-top: 50px;
background: rgba(207, 240, 230, 1);
margin-left: -70%;

    }


    .chatimg5col{
        width: 83px;
height: 83px;
margin-left: 300px;
margin-top: 38%;
position: absolute;
    }
    

    .chatboxtext41col{
        width: 508px;
height: 225px;
padding: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;


    }

    .chatbox41col{
        width: 529px;
height: 329px;
    border-radius: 0px 20px 20px 20px;
    background: rgba(231, 242, 255, 1);
    margin-top: 10%;
   margin-left: -40%;
    }

    .chatimg4col{
        width: 83px;
height: 83px;
        margin-left: -34%;
        margin-top: 8%; 
       position: absolute;
    }

    .chatboxtext21col{
        padding: 10%;
        width: 508px;
height: 299px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;



    }

    .chatbox21col{
        background: rgba(207, 240, 230, 1);
        width: 529px;
height: 392px;
border-radius: 20px 20px 0px 20px;
margin-top: 160%;
margin-left: -80%;
position: relative;
    }

    .chatimg2col{
        width: 83.46px;
height: 83.46px;
margin-top: -35%;
margin-left: 55%;

    }

    .extrachat{
        display: none;
    }

    .chatbox331col{
        display: none;
    }

    .chatbox1331col{
        display: none;
    }

    .chatimg3col{
        display: none;
    }




    .extrachatbox{
        display: none;
    }


    .chatboxtext1col{
        padding: 27px;
        margin-left: 8%;
        width: 438.2px;
height: 510.11px;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }

    .chatbox11col{
        width: 529px;
height: 602px;
border-radius: 0px 20px 20px 20px;
margin-top: 10%;
margin-left: 55%;
background: rgba(231, 242, 255, 1);
position: relative;

    }
    
    .chatimg1col{
        width: 83.46px;
height: 83.46px;
margin-left: 9%;
position: absolute;
margin-top: 7%;

    }

    .chat{
        margin-top: 50px;
    }


    .chatheadtitlecol{
        width: 749px;
height: 174px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: center;
margin-left: 90px;
margin-bottom: -40px;

    }

    .startedcol{
        margin-top: 7%;
        margin-bottom: 10%;
    }

    .chatitle1tagcol{
        width: 370px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 50%;
    }

    .ctextcol{
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
    }
    

    .regiter2col{
        width: 300px;
height: 60px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
position: relative;
margin-left: 63%;
top: -15px;
background: rgba(7, 60, 165, 1);
border: none;
    }


    .assesshead1col{
        width: 130%;
height: 180px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-left: 24%;
position: relative;
margin-top: 65%;
margin-bottom: 2em;
    }
    .assesshead2col{
        width: 676px;
height: 160px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-top: -13%;
margin-left: 27%;
    }
    .assesheadtitlecol{
        width: 749px;
height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: center;
        position: absolute;
/* margin-left: 120px; */
margin-top: 280px;
left: 50%;
        transform: translate(-50%, -50%);
    }

.assesstitle1tagcol{
    margin-top: 18%;
    margin-left: 68%;
    width: 234px;
height: 64px;
border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
position: relative;
/* display: none; */
}


    

    .assesscirclecol{
        overflow-x: hidden;
        width: 478px;
height: 478px;
margin-left: 60%;
margin-top: 10%;
border-radius: 100%;
position: absolute;
background: rgba(9, 9, 63, 0.05);
    }

    .regitercol{
        width: 300px;
        height: 60px;
        border-radius: 37.5px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
margin-left: 75%;
top: 20px;
position: relative;
left: 0px;
background: rgba(7, 60, 165, 1);
border: none;
    }

    .regiter1t{
        padding-top: 5%;
    }

    .head12tabcol{
        width: 713px;
height: 110px;
margin-top: 110%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-left: 25%;
position: relative; 

    }

    .head13tab{
        margin-top: 0%;
        width: 676px;
        height: 90px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-left: 30%;

    }

    .traintitle1tagcol{
        /* display: none; */
        margin-top: 24%;
        margin-left: 80%;

        width: 234px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;


    }

    .traintitle1tagcoltext{
        /* display: none; */
    }



    .traintitle1tagtab{
        width: 105px;
height: 34px;
border-radius: 37.5px;
margin-top: 300%;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 40%;
/* margin-bottom: 30%; */
position: relative;

    }

    .trainheadtitlecol{
display: none;
    }

    .head12col{
        display: none;
    }

    .head13{
        display: none;
    }

   

    .trainheadtitletab{
        width: 564px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 43%; */
margin-top: 45%;
position: absolute;
left: 50%;
transform: translate(-50%, -50%);
    }

    .traincircletab{
        width: 478px;
        height: 478px;
        background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -20%;
    margin-top: 30%;
    position: absolute;
    }

    .phonetitles3{
        margin-left: 10px;
        margin-top: 100px;

    }

    .yellowcircle3{
        width: 180.56px;
height: 180.56px;
background: rgba(247, 180, 0, 1);
border-radius: 100%;
margin-top: -37%;
       margin-left: 85%;
    }

    .orgcircle3{
        width: 121.5px;
height: 121.5px;
margin-top: 0px;
position: relative;
margin-left: 80px;
    }

    .orgcircle3::before{
        content: '';
        position: absolute;
        margin-top: -30%;
        margin-left: 175%;
        width: 253.48px;
height: 253.48px;
        border-radius: 50%;
        opacity: 90%;
      }
      
      .orgcircle3::before {
          background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
        }
    .viocircle3{
        margin-top: -50px;
        width: 121.5px;
height: 121.5px;
opacity: 90%;
    }

    .viocircle3::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }

    .viocircle3::before {
        content: '';
        position: absolute;
        margin-top: 0px;
        left: 140px;
        /* margin-left: 20%; */
        width: 253.48px;
height: 253.48px;
        border-radius: 50%;
        opacity: 100%;
      }

    .bluecircle3{
        width: 383.69px;
height: 383.69px;
margin-top: -22.5%;
margin-left: 37%;
border-radius: 100%;
background: rgba(135, 189, 255, 1);
position: absolute;

    }

    .phone3{
        width: 485px;
height: 391px;
    margin-top: -55%;
    margin-left: 50%;
    position: relative;
    }

    .phonetitles3{
        order: 1;
    }

    .phonetitle1tag3{
        margin-top: 25%;
        width: 590px;
height: 64px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
/* margin-left: 30%; */
position: absolute;
left: 50%;
transform: translate(-50%, -50%);
background: rgba(232, 237, 252, 1);

    }

    .firstsignup2{
        margin-top: -480px;
        text-align: center;
    }

    
    .firstsignup12{
        margin-top: -430px;
    }

    .phoneheadtitle3{
        width: 71%;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 75px;
letter-spacing: 0em;
text-align: center;
margin-top: 33%;
position: absolute;
left: 50%;
        transform: translate(-50%, -50%);
/* margin-left: 20%; */
color: rgba(0, 0, 0, 1);
margin-bottom: 2em;
    }

    .phonebody3lap{
        display: none;
    }

.image-phone-container3{
    margin-top: 560px;
    margin-left: 10px;
    order: 1;
}

.phonebody3mob{
    order: 3;
    width: 720px;
height: 75px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
/* margin-left: -78%; */
position: absolute;
left: 50%;
        transform: translate(-50%, -50%);
margin-top: 100%;

}

    .adobe{
        width: 89.62px;
height: 23.58px;
    }

    .adidas{
        margin-left: 30px;
        width: 45.28px;
height: 46.23px;
    }
    .burger{
        width: 43.4px;
height: 46.23px;
    }
    .kfc{
        margin-left: 20px;
        width: 43.4px;
height: 46.23px;
    }
    .fedex{
        width: 60.38px;
height: 25.47px;
    }
    .uptime{
        margin-left: 20px;
        width: 66.04px;
height: 23.58px;
    }

    /* .title2tagcol{
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 50px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 3%;
    } */

    /* .title2tagcol2{
        width: 740px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-bottom: -1em;
    } */

    .titlee1tag{
        margin-top: 30px;
        width: 110px;
        margin-left: 35.5%;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1);
    }


    .title1tagcol{
        margin-top: 5%;
        position: relative;
        margin-bottom: 30%;
        width: 154px;
height: 64px;
border-radius: 32px;
        margin-left: auto;
        margin-right: auto;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
color: rgba(0, 0, 0, 1);
background: rgba(232, 237, 252, 1);
    }

    .title1tagcolg{
        margin-top: 5%;
        position: relative;
        /* margin-bottom: 30%; */
        width: 380px;
height: 64px;
border-radius: 32px;
        margin-left: auto;
        margin-right: auto;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
color: rgba(0, 0, 0, 1);
background: rgba(232, 237, 252, 1);
    }

    .title2tagcol2g{
        height: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 15px;
        margin-bottom: 0em;
    }

    .mid3contentcol{
        display: none;
    }

    .contentt2{
        width: 87%;
height: 204px;
position: absolute;
left: 50%;
        transform: translate(-50%, -50%);
    margin-top: 148px;
    /* margin-left: 32%; */
      order: 2;
      font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 55px;
font-weight: 300;
line-height: 68px;
letter-spacing: 0em;
text-align: center;
      margin-bottom: 50px;
    }


    .ttextcol{
        width: 320px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
    }

    .titlee1tag{
        width: 234px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;



        /* margin-top: 30px;
        width: 110px;
        margin-left: 35.5%;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1); */


    }

    .violetbox2{
        background: #F75B02;
        width: 226.24px;
height: 226.24px;
        margin-top: -40%;
        margin-bottom: 100%;
        margin-left: -70%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;     
font-size: 10px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;

    }

    .vt1col{
        width: 101.42px;
        height: 50.15px;
font-family: 'Plus Jakarta Sans', sans-serif;  
position: relative;
font-size: 36px;
font-weight: 400;
line-height: 45px;
letter-spacing: 0em;
text-align: left;
margin-top:10%;
margin-left: 10%;
color: rgba(255, 255, 255, 1);

    }

    .vt2col{
        margin-top: 63px;
        margin-left: -44%;
        font-family: 'Plus Jakarta Sans', sans-serif; 
        width: 92.5px;
height: 21.18px;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);

    }

    .yellowbox2{
        background: rgba(247, 180, 0, 1);
        width: 131.51px;
height: 131.51px;
        margin-top: -197.5%;
        margin-left: -70%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;           
    }
    .yt1col{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
letter-spacing: 0em;
text-align: left;
margin-left: 10%;
    margin-top: 20px;
    }

    .linebox2{
        width: 182.78px;
height: 182.78px;
border: 1px;
opacity: 0.1px;
margin-left: -57%;
 position: relative;
margin-top: -216%;
margin-bottom: 180%;
border: 1px solid rgba(0, 0, 0, 1);
    }

    .hlinebox1col{
        width: 110px;
    height: 182.78px;
    opacity: 15%;
    border: 1px solid rgba(0, 0, 0, 1);
margin-left: 21%;
    /* left: 1px; */
    position: absolute;
}

.hlinebox2col{
    width: 40px;
    border: 1px solid rgba(0, 0, 0, 1);
height: 182.78px;
opacity: 90%;
margin-left: 30%;
}

.rlinebox1col{
    margin-top: 10px;
    width: 182.78px;
    border: 1px solid rgba(0, 0, 0, 1);
height: 110px;
opacity: 30%;
top: 23px;
position: absolute;
}
.rlinebox2col{
    /* margin-top: 20px; */
    width: 182.78px;
    border: 1px solid rgba(0, 0, 0, 1);
height: 40px;
opacity: 30%;
top: 30px;
position: absolute;
}

.lineboxtextcol{
    width: 71.33px;
height: 50.15px;
margin-top: 20px;
margin-left: 10%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 36px;
font-weight: 400;
line-height: 45px;
letter-spacing: 0em;
text-align: left;
position: absolute;

}

.lineboxtext2col{
    width: 91.39px;
height: 21.18px;
    margin-top: 2px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    
}

.siginscol{
    padding-top: 5%;
    padding-left: 0%;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    
color: white;

}

    .signincol{
        width: 173px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
display: flex;
justify-content: center;
margin-top: 19%;
margin-left: 50%;
text-align: center;
align-content: center;
/* text-align: center; */
background: rgba(7, 60, 165, 1);
border-radius: 37.5px;
border: none;
position: relative;
    }


    .watchs2{
        padding-top: 5%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 19px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
width:200px;
height: 100px;        
text-transform:capitalize;
    }

    .watchcol{
        width: 200px;
height: 60px;
border-radius: 37.5px;

font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;

display: flex;
justify-content: center;
margin-left: 20px;
margin-top: 19%;
text-align: center;
    }

    .linetxtcol{
        width: 676px;
height: 75px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
position: relative;
top: -20px;
margin-bottom: 7em;
    }

    .linetextcol{
        width: 76%;
height: 75px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 15%;
    }

    .im-container2{
        margin-left: 20px;
    }

  
    .im-container1 {
      order: 1;
    }

    .background-container2{
        width: 277.51px;
        height: 495.93px;
    
        background: rgba(137, 26, 97, 1);
position: absolute;
        clip-path: polygon(0 0, 100% 60%, 100% 100%, 0% 100%);
        margin-left: -15%;
        margin-top: 34%;
    }

    .orgvector2 img{
        width: 298.68px;
height: 512.24px;
        margin-top: 109.5%;
        margin-left: -90%;
        position: relative;
    }    

    .chatbox121col{
        width: 127.72px;
height: 97.12px;
    margin-top: -9.6%;
    margin-left: 16%;
    position: absolute;
    background: rgba(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100.3%);   
 }

 .chatbox141col{
    width: 128px;
height: 97px;
background: rgba(231, 242, 255, 1);
position: absolute;
margin-top: -33%;
clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
margin-left: -30%;
}

.chatbox151col{
    width: 127px;
height: 97px;
background: rgba(207, 240, 230, 1);
position: absolute;
margin-top: -9.5%;
clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100%);
margin-left: 20%;
}

.chatbox111col{
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    width: 127.72px;
height: 97.12px;
background: rgba(231, 242, 255, 1);
margin-left: 15%;
margin-top: -59.5%;
position: absolute;
}

.chatboxtext31col{
    display: none;
}



    body{
        overflow-x: hidden;
    }
    
    .colgciradful{
        position: relative;
        top: 0px;
    }
    
    
}


@media (min-width:577px) and (max-width:767.5px) {
    .title2tagcol{
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 33px;
font-weight: 400;
line-height: 38px;
letter-spacing: 0em;
text-align: center;
position: relative;
left: 10px;
margin-bottom: 30px;
    }

    .title2tagcol2{
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        position: relative;
        text-align: center;
        margin-bottom: -3em;
        left: 10px;
        top: -20px;
            }

            .brandppcol{
                position: relative;
                top: 35px;
                left: 5px;
                margin-left: auto;
                margin-right: auto;
                width: 90%;
                height: 175px;
         font-family: 'Plus Jakarta Sans', sans-serif;
         font-size: 20px;
         font-weight: 400;
         line-height: 25px;
         letter-spacing: 0em;
         text-align: left;
         opacity: 80%;
         color: rgba(255, 255, 255, 1);
            }

            .brandhcol{
                position: relative;
                top: 15%;
                margin-left: -7%;
                font-family: 'Plus Jakarta Sans', sans-serif;
                font-size: 25px;
                font-weight: 900;
                line-height: 42px;
                letter-spacing: 0em;
                text-align: center;
            width: 380px;
        height: 42px;
            }

            .hireppcol{
                position: relative;
                top: 25px;
                margin-left: auto;
                margin-right: auto;
                width: 90%;
                height: 175px;
         font-family: 'Plus Jakarta Sans', sans-serif;
         font-size: 20px;
         font-weight: 400;
         line-height: 25px;
         letter-spacing: 0em;
         text-align: left;
         opacity: 80%;
         color: rgba(255, 255, 255, 1);
            }

            .hirehcol{
                width: 526px;
        height: 42px;
        top: 12%;
        position: relative;
                margin-left: 5%;
                font-family: 'Plus Jakarta Sans', sans-serif;
                font-size: 25px;
                font-weight: 900;
                line-height: 42px;
                letter-spacing: 0em;
                text-align: left;
            }

            .digitalhcol{
                top: 13%;
        position: relative;
                margin-left: 0.7%;
                width: 425px;
        height: 42px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
                font-weight: 900;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: center;
            }

            .trainhcol{
                top: 10%;
        position: relative;
                margin-left: 4%;
        width: 310px;
        height: 24px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
                font-weight: 900;
                line-height: 42px;
                letter-spacing: 0em;
                text-align: center;
            }
}

@media (min-width:768px) and (max-width:830px) {
    .title2tagcol{
        width: 80%;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 50px;
font-weight: 400;
line-height: 45px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 3%;
margin-bottom: 30px;
    }

    .title2tagcol2{
        height: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: -1em;
        margin-top: 20px;
            }

            .brandppcol{
                margin-top: 8%;
                margin-left: auto;
                margin-right: auto;
                width: 90%;
                height: 175px;
         font-family: 'Plus Jakarta Sans', sans-serif;
         font-size: 20px;
         font-weight: 400;
         line-height: 25px;
         letter-spacing: 0em;
         text-align: left;
         opacity: 80%;
         color: rgba(255, 255, 255, 1);
            }

            .brandhcol{
                position: relative;
                top: 15%;
                margin-left: 4.5%;
                font-family: 'Plus Jakarta Sans', sans-serif;
                font-size: 35px;
                font-weight: 900;
                line-height: 42px;
                letter-spacing: 0em;
                text-align: center;
            width: 380px;
        height: 42px;
            }

            .hireppcol{
                margin-top: 10%;
                margin-left: auto;
                margin-right: auto;
                width: 90%;
                height: 175px;
         font-family: 'Plus Jakarta Sans', sans-serif;
         font-size: 20px;
         font-weight: 400;
         line-height: 25px;
         letter-spacing: 0em;
         text-align: left;
         opacity: 80%;
         color: rgba(255, 255, 255, 1);
            }

            .hirehcol{
                width: 526px;
        height: 42px;
        top: 18%;
        position: relative;
                margin-left: 5%;
                font-family: 'Plus Jakarta Sans', sans-serif;
                font-size: 35px;
                font-weight: 900;
                line-height: 42px;
                letter-spacing: 0em;
                text-align: left;
            }

            .digitalhcol{
                top: 15%;
        position: relative;
                margin-left: 0.7%;
                width: 588px;
        height: 42px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
                font-weight: 900;
                line-height: 42px;
                letter-spacing: 0em;
                text-align: center;
            }

            .trainhcol{
                top: 15%;
        position: relative;
                margin-left: 4%;
        width: 434px;
        height: 24px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
                font-weight: 900;
                line-height: 42px;
                letter-spacing: 0em;
                text-align: center;
            }
}

@media (min-width:577px) and (max-width:830px){

    .chatbox1col1:hover ,
    .chatbox2col:hover,
    .chatbox3col:hover,
    .chatbox4col:hover,
    .chatbox5col:hover
    {
        transform: none; /* Scale up the element on hover */
      }

     /* box click element start */

     .clickihv1col{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clickihv2col{
        width: 34px;
height: 34px;
position: relative;
top: -15px;
left: 90px;
    }

    .clickihv3col{
        width: 34px;
height: 34px;
position: relative;
top: -45px;
left: 90px;
    }

    .clickhv1col{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clickhv2col{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickhv3col{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clichv1col{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clichv2col{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clichv3col{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clihv1col{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clihv2col{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clihv3col{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    /* box click elemet end */

     /* circle animation  */

     @keyframes moveUp {
        0% { transform: translateY(30px); }
        100% { transform: translateY(-10px); }
    }
    
    .orgcircle3, .viocircle3, .bluecircle3, .yellowcircle3 {
        
        animation: none; 
    }
    
    .scrolling .orgcircle3 {
        animation: moveUp 1s forwards; 
    }
    
    .scrolling .viocircle3 {
        animation: moveUp 1.5s forwards; 
    }
    
    .scrolling .bluecircle3 {
        animation: moveUp 2s forwards; 
    }

    .scrolling .yellowcircle3 {
        animation: moveUp 3s forwards; 
    }


    /* circle animation end  */

    .chatcol{
        margin-top: 24%;
    }
    

    /* box animation: ; */
    
    .dancecol-divv {
        opacity: 0;
        transform: translateY(-400px);
        transition: 1s ;
    }
      
      .dancecol-divv.visible {
        opacity: 1;
        transform: translateY(0%);
        animation:  0.5s ease-in-out forwards ;
      }
      
      @keyframes shake {
        0% { transform: translateX(0); }
        25% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
        75% { transform: translateX(-10px); }
        100% { transform: translateX(0); }
      }
      
    .dancecol-div {
        transition: transform 0.5s ease; 
      }
      
      .dancecol-div.dancing {
        transform: translateY(-25px); 
        animation: 60s ease-out alternate; 
      }
      
      @keyframes bounce {
        from {
          transform: translateY(-25px);
        }
        to {
          transform: translateY(0);
        }
      }
    .gifcol{
        position: absolute;
        top: 0px;
        left: 70px;
    }
    .logosclg{
        white-space: nowrap;
    }

    .colimgmovup{
        margin-left: auto;
        margin-right: auto;
    }
    
    .logocl-slide{
        display: inline-block;
        animation: 120s slide infinite linear;
    }
    
    .logocl-slide img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logossclg{
        white-space: nowrap;
    }
    
    .logoscl-slide{
        display: inline-block;
        animation: 80s slides infinite linear;
    }
    
    .logoscl-slide img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }

    .mid3contentcol{
        display: none;
    }

    .luck2{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  /* display: inline-block; */
                  font-weight: 900;
    
    }

    html,body{

        overflow-x: hidden;
    }

    .namesmcol{
        width: 250px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 22px;
font-weight: 600;
line-height: 30px;
text-align: left;

    }

    .desgsmcol{
        width: 134px;
        height: 30px;
        font-size: 22px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-style: italic;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
margin-top: -30px;
margin-left: 30%;
    }

    /* .brandppcol{
        margin-top: 8%;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
    } */

    /* .hireppcol{
        margin-top: 10%;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);

    } */

    .digitalppcol{
        margin-top: 16%;
        /* margin-left: 8%; */
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
    }

    .trainppcol{
        margin-top: 12%;
        /* margin-left: 8%; */
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);

    }

    /* .brandhcol{
        position: relative;
        top: 15%;
        margin-left: 4.5%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    width: 380px;
height: 42px;
    } */

    

    /* .hirehcol{
        width: 526px;
height: 42px;
top: 18%;
position: relative;
        margin-left: 5%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;
    } */

    /* .digitalhcol{
        top: 15%;
position: relative;
        margin-left: 0.7%;
        width: 588px;
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    } */

    /* .trainhcol{
        top: 15%;
position: relative;
        margin-left: 4%;
width: 434px;
height: 24px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    } */

    /* .instlogo{
     width: 100px;
     height: 100px;   
    } */

    .arrow3{
        display: none;
    }



    /* .brandh{
        position: relative;
        top: 18%;
        margin-left: 15%;
        width: 250px;
        height: 24px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;

    }

    .hireh{
        width: 42px;
height: 24px;
top: 22%;
position: relative;
        margin-left: 17%;

        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;
    }

    .digitalh{
        top: 18%;
position: relative;
        margin-left: 16%;
        width: 290px;
height: 48px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 900;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
    }

    .trainh{
        top: 22%;
position: relative;
        margin-left: 17%;
width: 259px;
height: 24px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 900;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
    } */




    .brandcol{
        width: 84.2%;
        height: 306px;
        background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
    border-radius: 50px;
    margin-top: 10%;
    margin-bottom: 20px;
    /* margin-left: 8.5%; */
    margin-left: auto;
    margin-right: auto;
    }
    
    .hirecol{
        width: 84.2%;
height: 307px;
border-radius: 50px;
        background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
    border-radius: 50px;
    margin-bottom: 20px;
    /* margin-left: 8.5%; */
    margin-left: auto;
    margin-right: auto;
    }
    
    .digitalcol{
        width: 84.2%;
height: 307px;
        border-radius: 50px;
        background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%); font-size: 20px;
        margin-bottom: 20px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        /* margin-left: 8.5%; */
        margin-left: auto;
    margin-right: auto;
    }
    
    .traincol{
        width: 84.2%;
height: 307px;
        border-radius: 50px;
        background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
        margin-bottom: 90px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        /* margin-left: 8.5%; */
        margin-left: auto;
    margin-right: auto;
    }


    .regicol{
        font-family: 'Plus Jakarta Sans', sans-serif;
        color: rgba(7, 60, 165, 1);
        width: 145.25px;
height: 30px;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-top: 4%;
margin-left: 26%;

    }

    .bluec2{
        width: 60.18px;
height: 60.18px;
margin-top: -595px;
position: relative;
margin-left: 35%;
background: rgba(7, 60, 165, 1);
border-radius: 100%;
    }

    .whiteacol{
        width: 26.75px;
height: 33.43px;
margin-left: 30%;
margin-top: 20%;

    }

    .sechead2{
        margin-top: 0px;
    }

    .startparacol{
        width: 612.49px;
height: 50px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
/* top: 215px;
left: 20px; */
margin-left: 7%;
margin-top: -58%;
margin-bottom: 10%;
position: relative;


    }

    .startheadcol{
        width: 510px;
height: 40px;
/* margin-left: 18%; */
margin-left: auto;
margin-right: auto;
margin-top: 20px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 32px;
font-weight: 400;
line-height: 40px;
letter-spacing: 0em;
text-align: center;
color: rgb(255, 255, 255, 1);
    }

    .subbuttoncol{
        /* top: 40px; */
        /* margin-top: -10px; */
        top: -200px;
        position: relative;
        margin-left: 175px;
        width: 331px;
height: 61px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }

    .startedboxcol{
        margin-top: 50px;
        width: 120%;
height: 252px;
        /* margin-left: 14%; */
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 100px;
    position: relative;
    
    }

    .chatboxtext51col{
        width: 508px;
height: 374px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
color: #000000;

letter-spacing: 0em;
text-align: left;
padding: 10%;



    }

    .chatbox51col{
        width: 529px;
height: 469px;
border-radius: 20px 20px 0px 20px;
margin-top: 50px;
background: rgba(207, 240, 230, 1);
margin-left: -70%;

    }


    .chatimg5col{
        width: 83px;
height: 83px;
margin-left: 300px;
margin-top: 38%;
position: absolute;
    }
    

    .chatboxtext41col{
        width: 508px;
height: 225px;
padding: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;


    }

    .chatbox41col{
        width: 529px;
height: 329px;
    border-radius: 0px 20px 20px 20px;
    background: rgba(231, 242, 255, 1);
    margin-top: 10%;
   margin-left: -40%;
    }

    .chatimg4col{
        width: 83px;
height: 83px;
        margin-left: -34%;
        margin-top: 8%; 
       position: absolute;
    }

    .chatboxtext21col{
        padding: 10%;
        width: 508px;
height: 299px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;



    }

    .chatbox21col{
        background: rgba(207, 240, 230, 1);
        width: 529px;
height: 392px;
border-radius: 20px 20px 0px 20px;
margin-top: 160%;
margin-left: -80%;
position: relative;
    }

    .chatimg2col{
        width: 83.46px;
height: 83.46px;
margin-top: -35%;
margin-left: 55%;

    }

    .extrachat{
        display: none;
    }

    .chatbox331col{
        display: none;
    }

    .chatbox1331col{
        display: none;
    }

    .chatimg3col{
        display: none;
    }




    .extrachatbox{
        display: none;
    }


    .chatboxtext1col{
        padding: 27px;
        margin-left: 8%;
        width: 438.2px;
height: 510.11px;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }

    .chatbox11col{
        width: 529px;
height: 602px;
border-radius: 0px 20px 20px 20px;
margin-top: 10%;
margin-left: 55%;
background: rgba(231, 242, 255, 1);
position: relative;

    }
    
    .chatimg1col{
        width: 83.46px;
height: 83.46px;
margin-left: 9%;
position: absolute;
margin-top: 7%;

    }

    .chat{
        margin-top: 50px;
    }


    .chatheadtitlecol{
        width: 86%;
height: 174px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-bottom: -40px;

    }

    .startedcol{
        margin-top: 7%;
        margin-bottom: 10%;
    }

    .chatitle1tagcol{
        width: 370px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 65%;
    }

    .ctextcol{
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
    }
    

    .regiter2col{
        width: 300px;
height: 60px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
position: relative;
margin-left: 63%;
top: -15px;
background: rgba(7, 60, 165, 1);
border: none;
    }

    .lapbreak{
        display: none;
    }


    .assesshead1col{
        width: 140%;
height: 180px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-left: 20%;
position: relative;
margin-top: 85%;
    }
    .assesshead2col{
        width: 676px;
height: 160px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-top: -13%;
margin-left: 27%;
    }
    .assesheadtitlecol{
        width: max-content;
height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: center;
        position: absolute;
/* margin-left: 120px; */
margin-top: 280px;
left: 50%;
        transform: translate(-50%, -50%);
    }

.assesstitle1tagcol{
    margin-top: 18%;
    margin-left: 68%;
    width: 234px;
height: 64px;
border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
position: relative;
/* display: none; */
}


    

    .assesscirclecol{
        overflow-x: hidden;
        width: 478px;
height: 478px;
margin-left: 60%;
margin-top: 10%;
border-radius: 100%;
position: absolute;
background: rgba(9, 9, 63, 0.05);
    }

    .regitercol{
        width: 300px;
        height: 60px;
        border-radius: 37.5px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
margin-left: 75%;
top: 20px;
position: relative;
left: 0px;
background: rgba(7, 60, 165, 1);
border: none;
    }

    .regiter1t{
        padding-top: 5%;
    }

    .head12tabcol{
        width: 158%;
height: 110px;
margin-top: 60%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-left: 25%;
position: relative; 

    }

    .head13tab{
        margin-top: 0%;
        width: 676px;
        height: 90px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-left: 30%;

    }

    .traintitle1tagcol{
        /* display: none; */
        margin-top: 24%;
        margin-left: 80%;

        width: 234px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;


    }

    .traintitle1tagcoltext{
        /* display: none; */
    }



    .traintitle1tagtab{
        width: 105px;
height: 34px;
border-radius: 37.5px;
margin-top: 300%;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 40%;
/* margin-bottom: 30%; */
position: relative;

    }

    .trainheadtitlecol{
display: none;
    }

    .head12col{
        display: none;
    }

    .head13{
        display: none;
    }

   

    .trainheadtitletab{
        width: 564px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 43%; */
margin-top: 20%;
position: absolute;
left: 50%;
transform: translate(-50%, -50%);
    }

    .traincircletab{
        width: 478px;
        height: 478px;
        background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -20%;
    margin-top: 0%;
    position: absolute;
    }

    .phonetitles3{
        margin-left: 10px;
        margin-top: 100px;

    }

    .yellowcircle3{
        width: 180.56px;
height: 180.56px;
background: rgba(247, 180, 0, 1);
border-radius: 100%;
position: absolute;
margin-top: -7%;
       margin-left: 33%;
    }

    .orgcircle3{
        width: 121.5px;
height: 121.5px;
margin-top: 0px;
position: relative;
/* margin-left: 80px; */
    }

    .orgcircle3::before{
        content: '';
        position: absolute;
        margin-top: 215%;
        margin-left: 15%;
        width: 253.48px;
height: 253.48px;
        border-radius: 50%;
        opacity: 90%;
      }
      
      .orgcircle3::before {
          background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
        }
    .viocircle3{
        margin-top: 150px;
        margin-left: 0%;
        width: 121.5px;
height: 121.5px;
opacity: 90%;
    }

    .viocircle3::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }

    .viocircle3::before {
        content: '';
        position: absolute;
        margin-top: 130px;
        left: -160px;
        /* margin-left: 20%; */
        width: 253.48px;
height: 253.48px;
        border-radius: 50%;
        opacity: 100%;
      }

    .bluecircle3{
        width: 383.69px;
height: 383.69px;
margin-top: -37%;
margin-left: 38%;
border-radius: 100%;
background: rgba(135, 189, 255, 1);
position: absolute;

    }

    .phone3{
        width: 485px;
height: 391px;
    margin-top: -25%;
    margin-left: -21%;
    position: relative;
    }

    .phonetitles3{
        order: 1;
    }

    .phonetitle1tag3{
        margin-top: 25%;
        width: 590px;
height: 64px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
/* margin-left: 30%; */
position: absolute;
left: 50%;
transform: translate(-50%, -50%);
background: rgba(232, 237, 252, 1);

    }

    .firstsignup2{
        margin-top: -480px;
        text-align: center;
    }

    
    .firstsignup12{
        margin-top: -430px;
    }

    .phoneheadtitle3{
        width: 89%;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 75px;
letter-spacing: 0em;
text-align: center;
margin-top: 33%;
position: absolute;
left: 50%;
        transform: translate(-50%, -50%);
/* margin-left: 20%; */
color: rgba(0, 0, 0, 1);
margin-bottom: 2em;
    }

    .phonebody3lap{
        display: none;
    }

.image-phone-container3{
    margin-top: 560px;
    /* margin-left: 10px; */
    left: 50%;
        transform: translate(-50%, -50%);
    order: 1;
}

.phonebody3mob{
    order: 3;
    width: 90%;
height: 75px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
/* margin-left: -78%; */
position: absolute;
left: 50%;
        transform: translate(-50%, -50%);
margin-top: -30%;

}

    .adobe{
        width: 89.62px;
height: 23.58px;
    }

    .adidas{
        margin-left: 30px;
        width: 45.28px;
height: 46.23px;
    }
    .burger{
        width: 43.4px;
height: 46.23px;
    }
    .kfc{
        margin-left: 20px;
        width: 43.4px;
height: 46.23px;
    }
    .fedex{
        width: 60.38px;
height: 25.47px;
    }
    .uptime{
        margin-left: 20px;
        width: 66.04px;
height: 23.58px;
    }

    /* .title2tagcol{
        width: 80%;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 50px;
font-weight: 400;
line-height: 45px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 3%;
margin-bottom: 30px;
    } */

    /* .title2tagcol2{
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-bottom: -1em;
margin-top: 20px;
    } */

    .titlee1tag{
        margin-top: 30px;
        width: 110px;
        margin-left: 35.5%;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1);
    }


    .title1tagcol{
        margin-top: 5%;
        position: relative;
        margin-bottom: 30%;
        width: 154px;
height: 64px;
border-radius: 32px;
        margin-left: auto;
        margin-right: auto;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
color: rgba(0, 0, 0, 1);
background: rgba(232, 237, 252, 1);
    }

    .title1tagcolg{
        margin-top: 5%;
        position: relative;
        /* margin-bottom: 30%; */
        width: 380px;
height: 64px;
border-radius: 32px;
        margin-left: auto;
        margin-right: auto;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
color: rgba(0, 0, 0, 1);
background: rgba(232, 237, 252, 1);
    }

    .title2tagcol2g{
        height: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 15px;
        margin-bottom: 0em;
    }

    .mid3contentcol{
        display: none;
    }

    .contentt2{
        width: 87%;
height: 204px;
position: absolute;
left: 50%;
        transform: translate(-50%, -50%);
    margin-top: 148px;
    /* margin-left: 32%; */
      order: 2;
      font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 38px;
font-weight: 300;
line-height: 48px;
letter-spacing: 0em;
text-align: center;
      margin-bottom: 50px;
    }


    .ttextcol{
        width: 320px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
    }

    .titlee1tag{
        width: 234px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;



        /* margin-top: 30px;
        width: 110px;
        margin-left: 35.5%;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1); */


    }

    .violetbox2{
        background: #F75B02;
        width: 226.24px;
height: 226.24px;
        margin-top: -40%;
        margin-bottom: 100%;
        margin-left: -95%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;     
font-size: 10px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;

    }

    .vt1col{
        width: 101.42px;
        height: 50.15px;
font-family: 'Plus Jakarta Sans', sans-serif;  
position: relative;
font-size: 36px;
font-weight: 400;
line-height: 45px;
letter-spacing: 0em;
text-align: left;
margin-top:10%;
margin-left: 10%;
color: rgba(255, 255, 255, 1);

    }

    .vt2col{
        margin-top: 63px;
        margin-left: -44%;
        font-family: 'Plus Jakarta Sans', sans-serif; 
        width: 92.5px;
height: 21.18px;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);

    }

    .yellowbox2{
        background: rgba(247, 180, 0, 1);
        width: 131.51px;
height: 131.51px;
        margin-top: -280.5%;
        margin-left: -88%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;           
    }
    .yt1col{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
letter-spacing: 0em;
text-align: left;
margin-left: 10%;
    margin-top: 20px;
    }

    .linebox2{
        width: 182.78px;
height: 182.78px;
border: 1px;
opacity: 0.1px;
margin-left: -70%;
 position: relative;
margin-top: -272%;
margin-bottom: 260%;
border: 1px solid rgba(0, 0, 0, 1);
    }

    .hlinebox1col{
        width: 110px;
    height: 182.78px;
    opacity: 15%;
    border: 1px solid rgba(0, 0, 0, 1);
margin-left: 21%;
    /* left: 1px; */
    position: absolute;
}

.hlinebox2col{
    width: 40px;
    border: 1px solid rgba(0, 0, 0, 1);
height: 182.78px;
opacity: 90%;
margin-left: 30%;
}

.rlinebox1col{
    margin-top: 10px;
    width: 182.78px;
    border: 1px solid rgba(0, 0, 0, 1);
height: 110px;
opacity: 30%;
top: 23px;
position: absolute;
}
.rlinebox2col{
    /* margin-top: 20px; */
    width: 182.78px;
    border: 1px solid rgba(0, 0, 0, 1);
height: 40px;
opacity: 30%;
top: 30px;
position: absolute;
}

.lineboxtextcol{
    width: 71.33px;
height: 50.15px;
margin-top: 20px;
margin-left: 10%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 36px;
font-weight: 400;
line-height: 45px;
letter-spacing: 0em;
text-align: left;
position: absolute;

}

.lineboxtext2col{
    width: 91.39px;
height: 21.18px;
    margin-top: 2px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    
}

.siginscol{
    padding-top: 5%;
    padding-left: 0%;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    
color: white;

}

    .signincol{
        width: 173px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
display: flex;
justify-content: center;
margin-top: 19%;
margin-left: 35%;
text-align: center;
align-content: center;
/* text-align: center; */
background: rgba(7, 60, 165, 1);
border-radius: 37.5px;
border: none;
position: relative;
    }


    .watchs2{
        padding-top: 5%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 19px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
width:200px;
height: 100px;        
text-transform:capitalize;
    }

    .watchcol{
        width: 200px;
height: 60px;
border-radius: 37.5px;

font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;

display: flex;
justify-content: center;
margin-left: 20px;
margin-top: 19%;
text-align: center;
    }

    .linetxtcol{
        width: 676px;
height: 75px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
position: relative;
top: -20px;
margin-bottom: 7em;
    }

    .linetextcol{
        width: 85%;
height: 75px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 15%;
    }

    .im-container2{
        margin-left: 20px;
    }

  
    .im-container1 {
      order: 1;
    }

    .background-container2{
        width: 277.51px;
        height: 495.93px;
    
        background: rgba(137, 26, 97, 1);
position: absolute;
        clip-path: polygon(0 0, 100% 60%, 100% 100%, 0% 100%);
        margin-left: -15%;
        margin-top: 34%;
    }

    .orgvector2 img{
        width: 298.68px;
height: 512.24px;
        margin-top: 86.5%;
        margin-left: -90%;
        position: relative;
    }    

    .chatbox121col{
        width: 127.72px;
height: 97.12px;
    margin-top: -9.6%;
    margin-left: 16%;
    position: absolute;
    background: rgba(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100.3%);   
 }

 .chatbox141col{
    width: 128px;
height: 97px;
background: rgba(231, 242, 255, 1);
position: absolute;
margin-top: -33%;
clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
margin-left: -30%;
}

.chatbox151col{
    width: 127px;
height: 97px;
background: rgba(207, 240, 230, 1);
position: absolute;
margin-top: -9.5%;
clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100%);
margin-left: 20%;
}

.chatbox111col{
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    width: 127.72px;
height: 97.12px;
background: rgba(231, 242, 255, 1);
margin-left: 15%;
margin-top: -59.5%;
position: absolute;
}

.chatboxtext31col{
    display: none;
}



    body{
        overflow-x: hidden;
    }
    
    .colgciradful{
        position: relative;
        top: 0px;
    }
    
    
}




@media (max-width: 576px) {

    .chatbox1col1:hover ,
    .chatbox2col:hover,
    .chatbox3col:hover,
    .chatbox4col:hover,
    .chatbox5col:hover
    {
        transform: none; /* Scale up the element on hover */
      }
    

    .row-reveal {
        opacity: 0;
        transition: opacity 2s ease; /* Adjust the duration as needed */
      }
      
      .row-reveal.active {
        opacity: 1;
      }

    .testimonial-box {
        width: 300px;
        height: 200px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
      }

      .head12tabcol{
        display: none;
    }
      
      .curved-side {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #f0f0f0;
        border-radius: 50px;
      }
      
      .curved-side-1 {
        top: 0;
        left: 0;
      }
      
      .curved-side-2 {
        top: 0;
        right: 0;
      }
      
      .curved-side-3 {
        bottom: 0;
        left: 0;
      }
      
      .pointed-side {
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        left: -50px;
        border-left: 100px solid transparent;
        border-top: 100px solid #f0f0f0;
      }

      .testimonial-text {
        position: absolute;
        top: -70px;
        width: 200px;
        color: #000000;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
      }


     /* circle animation  */

     @keyframes moveUp {
        0% { transform: translateY(30px); }
        100% { transform: translateY(-10px); }
    }
    
    .orgcircle3, .viocircle3, .bluecircle3, .yellowcircle3 {
        
        animation: none; 
    }
    
    .scrolling .orgcircle3 {
        animation: moveUp 1s forwards; 
    }
    
    .scrolling .viocircle3 {
        animation: moveUp 1.5s forwards; 
    }
    
    .scrolling .bluecircle3 {
        animation: moveUp 2s forwards; 
    }

    .scrolling .yellowcircle3 {
        animation: moveUp 3s forwards; 
    }


    /* circle animation end  */

    .chatimg3col{
        display: none;
    }
    .dancecol-divv {
        opacity: 0;
        transform: translateY(-400px);
        transition: 1s ;
    }
      
      .dancecol-divv.visible {
        opacity: 1;
        transform: translateY(0%);
        animation:  0.5s ease-in-out forwards ;
      }
      
      @keyframes shake {
        0% { transform: translateX(0); }
        25% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
        75% { transform: translateX(-10px); }
        100% { transform: translateX(0); }
      }
      
    .dancecol-div {
        transition: transform 0.5s ease; 
      }
      
      .dancecol-div.dancing {
        transform: translateY(-25px); 
        animation: 60s ease-out alternate; 
      }
      
      @keyframes bounce {
        from {
          transform: translateY(-25px);
        }
        to {
          transform: translateY(0);
        }
      }

    .gifcol{
        position: absolute;
        top: -300px;
        left: 120px;
        height: 140px;
    }

    .logosclg{
        white-space: nowrap;
    }
    
    .logocl-slide{
        display: inline-block;
        animation: 120s slide infinite linear;
    }
    
    .logocl-slide img{
        margin: 0 40px;
        height: 50px;
    }
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logossclg{
        white-space: nowrap;
    }

    .logoscl-slide{
        display: inline-block;
        animation: 80s slides infinite linear;
    }
    
    .logoscl-slide img{
        margin: 0 40px;
        height: 50px;
    }
    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }

    .chatbox331col{
        display: none;
    }

    .chatbox1331col{
        display: none;
    }

    .chatimg33col{
        display: none;
    }

    .chatboxtext31col{
        display: none;
    }

    .trainheadtitletab{
        display: none;
    }

    .extrachatbox{
        display: none;
    }

    .assesstitle1tagcoltab{
        display: none;
    }

    .traintitle1tagtabtext{
        display: none;
    }

    .mid3contentcol{
        display: none;
    }

    .luck2{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  /* display: inline-block; */
                  font-weight: 900;
    
    }

    .colimgmovup{
        position: relative;
        left: 30px;
    }

    html,body{

        overflow-x: hidden;
    }

    .namesmcol{
        width: 250px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 600;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
    }

    .desgsmcol{
        width: 134px;
height: 30px;
font-size: 15px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-style: italic;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
margin-top: -30px;
margin-left: 19%;
    }

    .brandppcol{
    margin-top: 19%;
       margin-left: auto;
       margin-right: auto;
width: 260px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);
    }
    

    .hireppcol{
margin-top: 28%;
       /* margin-left: 17.5%; */
       margin-left: auto;
       margin-right: auto;
width: 260px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);

    }

    .digitalppcol{
        margin-top: 25%;
       margin-left: auto;
       margin-right: auto;
width: 260px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);
    }

    .trainppcol{
        margin-top: 21%;
       margin-left: auto;
       margin-right: auto;
width: 260px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);

    }

    /* .brandh{
        padding: 2%;
        width: 187px;
height: 24px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;

    }

    .hireh{
        width: 42px;
height: 24px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;
    }

    .digitalh{
        padding: 1%;
        width: 290px;
height: 48px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 900;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
    }

    .trainh{
width: 259px;
height: 24px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 900;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
    } */

    .brandhcol{
        position: relative;
        top: 19%;
        margin-left: 35px;
        width: 250px;
        height: 24px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;

    }

    .hirehcol{
        width: 170px;
height: 24px;
top: 22%;
position: relative;
        margin-left: 35px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;
    }

    .digitalhcol{
        top: 18%;
position: relative;
        margin-left: 35px;
        width: 288px;
height: 48px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 900;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
    }

    .trainhcol{
        top: 22%;
position: relative;
        margin-left: 35px;
width: 259px;
height: 24px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 900;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
    }




    .brandcol{
        width: 334px;
    height: 300px;
        background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
    border-radius: 50px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -25%;
    }
    
    .hirecol{
        width: 334px;
        height: 300px;
        background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
    border-radius: 50px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    }
    
    .digitalcol{
        width: 334px;
        height: 300px;
        border-radius: 50px;
        background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%); font-size: 20px;
        margin-left: auto;
    margin-right: auto;
        margin-bottom: 10px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .traincol{
        width: 334px;
        height: 300px;
        border-radius: 50px;
        background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
        margin-bottom: 50px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: auto;
    margin-right: auto;
    }

    .regicol{
        font-family: 'Plus Jakarta Sans', sans-serif;
        color: rgba(7, 60, 165, 1);
        width: 90px;
height: 19px;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
margin-top: 6%;
margin-left: auto;
margin-right: auto;
    }

    .bluec2{
        width: 32.91px;
height: 32.91px;
margin-top: -590px;
margin-left: 270px;
background: rgba(7, 60, 165, 1);
position: relative;
border-radius: 100%;
    }

    .whiteacol{
        position: relative;
        width: 14.63px;
height: 18.28px;
margin-left: 28%;
top: 2px;
    }

    .sechead2{
        margin-top: 75px;
    }

    .startparacol{
        display: none;
    }

    .startpara{
        width: 320px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-top: -50%;
margin-left: auto;
margin-right: auto;
position: relative;


    }

    .startheadcol{
        width: 405px;
height: 31px;
margin-top: 15px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
color: rgb(255, 255, 255, 1);
    }

    .subbuttoncol{
        margin-top: -8px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        height: 42px;
        width: 137px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }

    .startedboxcol{
        margin-top: 50px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    height: 170px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 100px;
    
    }

    .chatboxtext51col{
        padding: 10%;
        color: #000000;
        width: 240px;
height: 180px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;



    }

    .chatbox51col{
        width: 254px;
height: 170px;
border-radius: 20px 20px 0px 20px;
margin-top: 50px;
background: rgba(207, 240, 230, 1);
margin-left: 13%;

    }


    .chatimg5col{
        width: 40.11px;
height: 40.11px;
margin-left: 340px;
margin-top: 175px;
position: absolute;
    }
    

    .chatboxtext41col{
        width: 230px;
height: 108px;
padding: 20px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
    }

    .chatbox41col{
        width: 250px;
    height: 178px;
    border-radius: 0px 20px 20px 20px;
    background: rgba(231, 242, 255, 1);
    margin-top: 0px;
   margin-left: 30%;
    }

    .chatimg4col{
        width: 40.11px;
        height: 40.11px;
        margin-left: 35px;
        margin-top: 1%; 
       position: absolute;
    }

    .chatboxtext21col{
        padding: 30px;
        width: 290px;
height: 144px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;


    }

    .chatbox21col{
        background: rgba(207, 240, 230, 1);
        width: 290px;
height: 200px;
border-radius: 20px 20px 0px 20px;
margin-top: 10px;
margin-left: 10px;
    }

    .chatimg2col{
        width: 40px;
height: 40px;
margin-top: -90px;
margin-left: 320px;

    }


    .chatboxtext1col{
        padding: 23px;
        width: 250px;
height: 245.15px;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;

    font-family: 'Plus Jakarta Sans', sans-serif;
    }

    .chatbox11col{
        width: 254px;
height: 325px;
border-radius: 0px 20px 20px 20px;
margin-top: -30px;
margin-left: 35%;
background: rgba(231, 242, 255, 1);

    }
    
    .chatimg1col{
        width: 40.11px;
        height: 40.11px;        
margin-left: 70px;
top: 20px;
position: relative;

    }

    .chat{
        margin-top: 50px;
    }


    .chatheadtitlecol{
        width: 244px;
height: 62px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: 90px;
margin-bottom: -40px;

    }

    .chatitle1tagcol{
        width: 180px;
height: 34px;
border-radius: 37.5px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-bottom: -1em;
    }

    .ctextcol{
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
    }
    

    .regiter2col{
        width: 230px;
height: 42px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
position: relative;
left: 100px;
top: -30px;
background: rgba(7, 60, 165, 1);
border: none;
    }


    .assesshead1col{
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;
margin-bottom: 12%;
    }
    .assesshead2col{
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-top: -13%;
margin-left: 30px;
    }
    .assesheadtitlecol{
        width: 248px;
        height: 31px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: 80px;
margin-top: -190px;
    }

.assesstitle1tagcol{
    margin-top: -240px;
    margin-left: 40%;
    width: 105px;
    height: 34px;
    border-radius: 37.5px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
}
    

    .assesscirclecol{
        overflow-x: hidden;
        width: 260px;
height: 260px;
margin-left: 259px;
background: rgba(9, 9, 63, 0.05);
border-radius: 100%;
    }

    .regitercol{
        width: 230px;
height: 42px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
margin-left: 34%;
top: -60px;
position: relative;
left: -45px;
background: rgba(7, 60, 165, 1);
border: none;
    }
    
    .regiter1t{
        padding-top: 5%;
    }

    .head12col{
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;
margin-top: -40%;
    }

    .head13{
        margin-top: -60px;
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;

    }



    .traintitle1tagcol{
        width: 105px;
height: 34px;
border-radius: 37.5px;
margin-top: -55%;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
/* margin-left: 40%; */

    }

    .trainheadtitlecol{
        width: 194px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: 28%;
margin-top: -55%;
    }

    .traincirclecol{
        width: 260px;
        height: 260px;
    background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -120px;
    
    }

    .phonetitles3{
        margin-left: 10px;
        margin-top: 100px;

    }
    
    .yellowcircle3{
       margin-left: 20px;
    }

    .orgcircle3{
        width: 131.48px;
height: 131.48px;
top: -65px;
position: relative;
margin-left: 68px;
    }

    .orgcircle3::before{
        content: '';
        position: absolute;
        margin-top: -55%;
        margin-left: 14%;
        width: 131.48px;
height: 131.48px;
        border-radius: 50%;
        opacity: 90%;
      }
      
      .orgcircle3::before {
          background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
        }
    .viocircle3{
        margin-top: -50px;
        width: 131.48px;
height: 131.48px;
opacity: 90%;
    }

    .viocircle3::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }

    .viocircle3::before {
        content: '';
        position: absolute;
        margin-top: -160px;
        left: 0px;
        width: 131.48px;
height: 131.48px;
        border-radius: 50%;
        opacity: 100%;
      }

    .bluecircle3{
        width: 199.02px;
height: 199.02px;
margin-top: -81%;
margin-left: 31%;
border-radius: 100%;
background: rgba(135, 189, 255, 1);
position: absolute;
    }

    .phone3{
        width: 282px;
    height: 228px;
    margin-top: -480px;
    margin-left: 50px;
    position: relative;
    }

    .phonetitles3{
        order: 1;
    }
    .phonetitle1tag3{
        margin-top: 0%;
        width: 290px;
        height: 34px;
        border-radius: 37.5px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
margin-left: auto;
margin-right: auto;
background: rgba(232, 237, 252, 1);

    }

    .firstsignup2{
        margin-top: -480px;
        text-align: center;
    }

    
    .firstsignup12{
        margin-top: -430px;
    }

    .phoneheadtitle3{
        width: 270px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 28px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
color: rgba(0, 0, 0, 1);
top: -10px;
position: relative;
    }

    .phonebody3lap{
        display: none;
    }

.image-phone-container3{
    margin-top: 180px;
    margin-left: 20px;
    order: 1;
}

.phonebody3mob{
    order: 3;
    width: 358px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: -30%;
margin-bottom: -3em;
}

    .adobe{
        width: 89.62px;
height: 23.58px;
    }

    .adidas{
        margin-left: 30px;
        width: 45.28px;
height: 46.23px;
    }
    .burger{
        width: 43.4px;
height: 46.23px;
    }
    .kfc{
        margin-left: 20px;
        width: 43.4px;
height: 46.23px;
    }
    .fedex{
        width: 60.38px;
height: 25.47px;
    }
    .uptime{
        margin-left: 20px;
        width: 66.04px;
height: 23.58px;
    }

    .title2tagcol{
        width: 100%;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
margin-left: auto;
margin-right: auto;
top: 0px;
position: relative;
text-align: center;
margin-bottom: 10px;
    }

    .title2tagcol2{
        width: 95%;
/* height: 31px; */
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
margin-left: auto;
margin-right: auto;
text-align: center;
top: 13px;
position: relative;
margin-bottom: 9em;
    }

    .titlee1tag{
        margin-top: 30px;
        width: 110px;
        margin-left: 35.5%;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1);
    }

    .title1tagcol{
        width: 80px;
        margin-left: auto;
        margin-right: auto;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
color: rgba(0, 0, 0, 1);
background: rgba(232, 237, 252, 1);
    }

    .title1tagcolg{
        width: 190px;
        margin-left: auto;
        margin-right: auto;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
color: rgba(0, 0, 0, 1);
background: rgba(232, 237, 252, 1);
    }

    .title2tagcol2g{
        width: 250px;
        margin-left: auto;
        margin-right: auto;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
top: -10px;
margin-bottom: -1em;
position: relative;
    }

    .contentt2{
        width: 98%;
height: 93px;
        display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
    /* margin-left: 16%; */
    margin-left: auto;
    margin-right: auto;
      order: 2;
      font-family: 'Plus Jakarta Sans', sans-serif;
      font-size: 25px;
      line-height: 31px;
      letter-spacing: 0em;
      text-align: center;
      margin-bottom: 85px;
    }

    .ttextcol{
        width: 155px;
height: 15px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;


    }

    .titlee1tag{
        width: 234px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;



        /* margin-top: 30px;
        width: 110px;
        margin-left: 35.5%;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1); */


    }

    .violetbox2{
        background: #F75B02;
        width: 123px;
        height: 124px;
        margin-top: -27%;
        margin-bottom: 100%;
        margin-left: 6%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;     
font-size: 10px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;

    }

    .vt1col{
        width: 55px;
height: 35px;
font-family: 'Plus Jakarta Sans', sans-serif;  
/* margin-left: 100px; */
position: relative;
left: 15px;
top: 17px;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);



    }

    .vt2col{
        margin-top: 38px;
        margin-left: -40px;
        font-family: 'Plus Jakarta Sans', sans-serif; 
font-size: 10px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);
    }

    .yellowbox2{
        background: rgba(247, 180, 0, 1);
        width: 72px;
        height: 72px;
        margin-top: -189%;
        margin-left: -2.5%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;           
    }
    .yt1col{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
letter-spacing: 0em;
text-align: left;
margin-left: 8%;
    margin-top: 10px;
    }

    .linebox2{
        width: 100px;
height: 100px;
border: 1px;
opacity: 0.1px;
/* margin-top: 38%; */
margin-left: 6%;
 position: relative;
margin-top: -165%;
margin-bottom: 180%;
border: 1px solid rgba(0, 0, 0, 1);

    }

    .hlinebox1col{
        width: 63px;;
    height: 99px;
    opacity: 15%;
    margin-left: 17%;
    border: 1px solid rgba(0, 0, 0, 1);
    position: absolute;
}

.hlinebox2col{
    width: 23px;
height: 99px;
opacity: 30%;
margin-left: 31%;
border: 1px solid rgba(0, 0, 0, 1);
}

.rlinebox1col{
    margin-top: 10px;
    width: 99px;
height: 63px;
opacity: 10%;
top: 8px;
position: absolute;
border: 1px solid rgba(0, 0, 0, 1);

}
.rlinebox2col{
    /* margin-top: 20px; */
    width: 99px;
height: 23px;
opacity: 30%;
top: 20px;
border: 1px solid rgba(0, 0, 0, 1);

position: absolute;
}

.lineboxtextcol{
    width: 55px;
height: 35px;
margin-top: 20px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
margin-left: 8%;
text-align: left;
position: absolute;

}

.lineboxtext2col{
    margin-top: 2px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 10px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;
}

.siginscol{
    padding-top: 1.5%;
    padding-left: 0%;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
color: white;

}

    .signincol{
        width: 137px;
        
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
display: flex;
justify-content: center;
margin-top: -10%;
margin-left: 50px;
text-align: center;
align-content: center;
/* text-align: center; */
background: rgba(7, 60, 165, 1);
border-radius: 37.5px;
border: none;
    }


    .watchs2{
        padding-top: 1%;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
text-transform:capitalize;
    }

    .watchcol{
        border-radius: 30px;
        width: 150px;
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
display: flex;
justify-content: center;
margin-left: -8px;
margin-top: -10%;
text-align: center;

/* text-align: left; */
    }

    .linetextcol{
        width: 377px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 13px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 6%; */
margin-left: auto;
margin-right: auto;
margin-top: 3%;
    }

    .linetxtcol{
        width: 358px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 13px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
top: -30px;
position: relative;
    }

    .im-container2{
        margin-left: 20px;
    }

  
    .im-container1 {
      order: 1;
    }

    .background-container2{
        width:153px;
        height: 274px;
        background: rgba(137, 26, 97, 1);
position: absolute;
        clip-path: polygon(0 0, 100% 60%, 100% 100%, 0% 100%);
        margin-left: 30%;
    }

    .orgvector2 img{
        width: 159px;
        height: 320px;
        margin-top: -29%;
        margin-left: 170%;
        position: relative;
    }    

    .chatbox121col{
        width: 61.38px;
height: 46.67px;
    margin-top: -10.8%;
    margin-left: 64%;
    position: absolute;
    background: rgba(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100.3%);   
 }

 .chatbox141col{
    width: 61px;
height: 46px;
background: rgba(231, 242, 255, 1);
position: absolute;
margin-top: -41.4%;
clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
margin-left: 15%;
}

.chatbox151col{
    width: 61px;
height: 46px;
background: rgba(207, 240, 230, 1);
position: absolute;
margin-top: -10.7%;
clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100%);
margin-left: 67%;
}

.chatbox111col{
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    width: 61px;
height: 46px;
background: rgba(231, 242, 255, 1);
margin-left: 22%;
margin-top: -75.5%;
position: absolute;
}

.yt2{

}

.colgciradful{
    position: relative;
    top: 0px;
}


    body{
        overflow-x: hidden;
    }
    
  
}