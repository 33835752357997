 /*  BOXES Animation start*/
    .dancing-divv {
        opacity: 0;
        transform: translateY(-400px);
        transition: 1s ;
    }
      
      .dancing-divv.visible {
        opacity: 1;
        transform: translateY(-100%);
        animation:  0.5s ease-in-out forwards ;
      }
      
      @keyframes shake {
        0% { transform: translateX(0); }
        25% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
        75% { transform: translateX(-10px); }
        100% { transform: translateX(0); }
      }
      
     
      

 
    .dancing-div {
        transition: transform 0.5s ease;
      }
      
      .dancing-div.dancing {
        transform: translateY(-25px); 
        animation: 60s ease-out alternate; 
      }
      
      @keyframes bounce {
        from {
          transform: translateY(-25px);
        }
        to {
          transform: translateY(0);
        }
      }

      /* boxes animation end */

      .mobcolwidthc{
        display: none;
      }
      
      .mobpatwidthc{
        display: none;
      }
      



.containerm {
    display: flex;
    flex-wrap: wrap;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-top: 90px;
  }




  .Sec2box3.college-bg{
    background: rgba(247, 91, 2, 1);
    margin-left: -85px;
  }
  .Sec2box1.college-bg1{
    margin-left: -60px;
  }
  .Sec2box2.college-bg2{
    margin-left: -115px;
  }
  
  .partner-margin-top{
    position: relative;
    left: 60px;
    margin-top: 40px;
  }

  .college-margin-top{
    position: relative;
    left: 40px;
    margin-top: 40px;
  }

  .collgmaincnt{
    position: relative;
    left: 20px;
    margin-top: 30px;
  }

  .college-arrow{
    margin-left: -20px;
  }
  
  .Section1, .Section2, .Section3 {
    width: 100%;
  }

  .Section1{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Section2{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Section3{
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .Sec1para{
    width: 520px;
/* height: 205px; */
font-size: 47px;
font-weight: 300;
line-height: 54px;
text-align: left;
  }

  .colprolef{
    position: relative;
    left: -30px;
    top: -20px;
  }

  .partprolef{
    position: relative;
    left: -20px;
    top: -30px;
  }

  .corprolef{
    position: relative;
    left: -20px;
    top: -40px;
  }

  .Csignupbutton{
    width: 173px;
    height: 60px;
    border-radius: 37.5px;
    background: rgba(7, 60, 165, 1);
    color: white;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.8px;
    text-align: center;
    border: none;
  }

  .Cjoblistingbutton{
    width: 173px;
height: 60px;
border-radius: 37.5px;
border: 1px;
background: rgba(255, 255, 255, 1);
border: 1px solid rgba(7, 60, 165, 1);
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
text-align: center;
color: rgba(7, 60, 165, 1);
  }

  .Sec1button {
    display: flex;
    gap: 40px;
    justify-content: center;
    margin-top: -50px;
    align-items: center;
    margin-left: 70px;
    position: relative;
  }

  .fresher .Sec1button{
    top: -20px;
  }

  .experienced .Sec1button{
    top: -40px;
    left: -10px;
  }

  .college .Sec1button{
    top: -50px;
    left: -40px;
  }

  .partner .Sec1button{
    top: -60px;
    left: -30px;
  }

  .corporate .Sec1button{
    top: -60px;
    left: -30px;
  }

  .small-font-size {
    font-size: 40px;
  }

  .Sec2fullpart{
    margin-left: 100px;
  }

  .Crequestbutton{
    width: 200px;
height: 60px;
border-radius: 37.5px;
border: 1px;
background: rgba(255, 255, 255, 1);
border: 1px solid rgba(7, 60, 165, 1);
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
text-align: center;
color: rgba(7, 60, 165, 1);



  }

  .bgpartimgfre{
    width: 375px;
    height: 456.75px;
    margin-top: -485px;
    margin-left: -30px;
  }

  
  .bgpartimgexp{
    width: 354px;
height: 529px;
margin-top: -555px;
    margin-left: 20px;
}

.bgpartimgcol{
  width: 268px;
  height: 459.62px;
margin-top: -488px;
  margin-left: 55px;
  position: relative;
}

.bgpartimgpat{
  width: 301px;
  height: 472px;
  margin-top: -500px;
  margin-left: 30px;
}

.bgpartimgcorp{
  width: 381.75px;
height: 509px;
margin-top: -536px;
    margin-left: -45px;
    position: relative;
}



 .bgpart1{
    width: 313px;
    height: 428.07px;
    border-top-right-radius: 90%;
    background: rgba(255, 91, 0, 1);
    display: flex;
 }

 .bgpart2{
    width: 284px;
    height: 432px;
    background: rgba(255, 188, 0, 1);
    display: flex;
    margin-left: 21px;
 }

 .bgpart3{
  width: 249px;
height: 444.98px;
  background: rgba(137, 26, 97, 1);
  clip-path: polygon(0 0, 100% 60%, 100% 100%, 0% 100%);
  display: flex;
  margin-left: 51px;
  position: relative; 
}

 .bgpart4{
  width: 313px;
  height: 428.07px;
  border-top-left-radius: 90%;
  background: rgba(204, 5, 48, 1);
  display: flex;
}

 .bgpart5{
  width: 282px;
height: 467.91px;
background: rgba(7, 60, 165, 1);
clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 38%);
  display: flex;
  margin-left: 30px;
}

 .Sec2boxes {
    position: relative; 
    display: flex;
    flex-direction: column; 

  }

  .Sec2box1 {
    width: 164px;
    height: 164px;
    border: 1px solid rgba(0, 0, 0, 1);
    position: absolute;
    margin-left: -120px;
    margin-top: -10px;
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 columns */
    grid-template-rows: repeat(5, 1fr); /* 5 rows */
  }
  
  .Sec2box1 .Sline {
    border: 1px solid rgba(0, 0, 0, 0.12); 
  }

  .background{
    position: relative;
    z-index: -1;
  }
  

.Sec2box2{
    width: 118px;
height: 118px;
background: rgba(247, 180, 0, 1);
position: absolute;
margin-left: -172px;
margin-top: 88px;


}

.Sec2box3{
    width: 203px;
height: 203px;
background: rgba(130, 27, 97, 1);
position: absolute;
margin-top: 250px;
margin-left: -135px;

}

.experienced-bg {
    background: rgba(247, 91, 2, 1);
  }

.Sec2universities {
    position: absolute;
    margin-top: 23px;
    margin-left: 20px;
  }

  .Sec2Count{
font-size: 36px;
font-weight: 400;
line-height: 44.64px;
  }

  .Sec2text{
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
position: absolute;
margin-top: -5px;
  }

  .Sec2Count2{
font-size: 20px;
font-weight: 400;
/* line-height: 24.8px; */


  }

  .Sec3box3text{
    margin-top: 25px;
    margin-left: 25px;
    color: rgba(255, 255, 255, 1);
  }

  .Sec3box2text{
    margin-top: 15px;
    margin-left: 15px;
  }

  .coverdiv{
    width: 54px;
    height: 54px;
    background: rgba(7, 60, 165, 1);
    display: flex;
    position: absolute;
    border-radius: 100%;
    margin-left: 285px;
    margin-top: -55px;
    justify-content: center;
    align-items: center;
  }

  @media (min-width:1227px) and (max-width:1399px){
    .fresprolef{
      position: relative;
      left: -20px;
      top: -60px;
    }

    .expprolef{
      position: relative;
      left: -20px;
      top: -40px;
    }

    .colprolef{
      position: relative;
      left: -20px;
      top: -70px;
    }

    .partprolef{
      position: relative;
      left: -30px;
      top: -90px;
    }

    .corprolef{
      position: relative;
      left: -10px;
      top: -90px;
    }

    .fresher .Sec1button{
      top: -90px;
      left: -40px;
    }

    .experienced .Sec1button{
      top: -110px;
      left: -20px;
    }

    .college .Sec1button{
      top: -80px;
      left: -40px;
    }

    .partner .Sec1button{
      top: -80px;
      left: -50px;
    }

    .corporate .Sec1button{
      top: -120px;
    }

    .college-margin-top{
      position: relative;
      margin-top: -20px;
      left: 60px;
    }

    .partner-margin-top{
      position: relative;
      left: 60px;
      margin-top: 0px;
    }

    .collgmaincnt{
      position: relative;
      left: 50px;
    }

  }
  
  @media (min-width: 1025px) {

   

    .Section1, .Section3 {
      width: 50%;
    }
  
    .Section2 {
      width: 50%;
    }
  
    .Section1 {
      order: 1;
    }
  
    .Section2 {
      order: 2;
    }
  
    .Section3 {
      order: 3;
    }



  
  }

  @media (min-width: 1025px) and (max-width:1399px){
    .experienced-margin-top{
      margin-top: 70px;
    }
  }


  

  @media (max-width: 1024px) {

    .fresprolef{
      width: 515px !important;
    }

    .expprolef{
      width: 515px !important;
    }

    .corprolef{
      /* position: relative; */
      left: 0px;
      top: -15px;
    }

    .partner .Sec1button{
      top: 0px;
      left: 0px;
      margin-top: 50px;
    }

    .corporate .Sec1button{
      top: 0px;
      left: 0px;
      margin-top: 50px;
    }

    .partprolef{
      /* position: relative; */
      left: 0px;
      top: 0px;
    }

    .college-margin-top{
      left: 0px;
    }

    .colprolef{
      /* position: relative; */
      left: 0px;
      top: 0px;
    }

    .fresher .Sec1button{
      margin-top: 80px;
    }
    
    .corpwidth1{
      display: none;
    }

    .corpwidth2{
      display: none;
    }

    .corpwidth3{
      display: none;
    }

    .college .Sec1button{
      top: 0px;
      left: 0px;
      margin-top: 40px;
    }

    .Section1{
        margin-top: 50px;
    }

    .Section2{
        margin-top: 100px;
        margin-left: 50px;
    }

    .Section3{
        margin-top: 0px;
    }
    .experienced-margin-top {
        margin-top: 160px;
        margin-left: 80px;
      }

      .partner-margin-top{
        margin-top: 180px;
      }

      

    .bgpart2{
        width: 284px;
        height: 432px;
    }

    .bgpartimgfre{
        width: 375px;
height: 456.75px;
    }


    .Sec2fullpart{
        margin-left: 0px;
      }
    .Sec1button {
      align-items: center;
      margin-left: 0;
      width: 100%; /* Ensures the button container is centered */
    }

    .Csignupbutton, .Cjoblistingbutton {
      margin-bottom: 20px; 
      width: 175px; 
    }

    .collgmaincnt{
      position: relative;
      left: 30px;
      margin-top: 70px;
    }

    .Sec1para{
        text-align: center;
        width: 600px;
        height: 204px;
        /* font-size: 55px;
        font-weight: 300;
        line-height: 57px; */
        text-align: center;
    }

    .containerm{
        margin-top: 40px;
    }

    .experienced .Sec1button{
      top: 0px;
      left: 0px;
      margin-top: 100px;
    }

    .mobcolwidthc{
      display: block;
      left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 90%;
    margin-top: 110px;
    }

    .mobpatwidthc{
      display: block;
      left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 105%;
    margin-top: 110px;
    }
    

   .colwidth1{
    display: none;
   }
   .colwidth2{
    display: none;
   }

   .colwidth3{
    display: none;
   }

   .prtwidth1{
      display: none;
   }
   .prtwidth2{
    display: none;
 }
 .prtwidth3{
  display: none;
}


  }

  @media (max-width: 576px){
    .experienced .Sec1button{
      margin-top: 30px;
    }

    .mobcolwidthc{
        margin-top: 75px;
        width: 105%;
    }

    .mobpatwidthc{
      margin-top: 85px;
      width: 118%;
  }

  .collgmaincnt{
    position: relative;
    left: 0px;
    margin-top: 100px;
  }

    .Sec1para{
        width: 273px !important;
        height: 93px;
        font-size: 25px;
        font-weight: 300;
        line-height: 31px;
        text-align: center;
    }

    .Sec2box1.college-bg1{
      margin-left: -82px;
    }

    .Csignupbutton, .Cjoblistingbutton {
        width: 137px;
        height: 42px;
        font-size: 15px;
        font-weight: 400;
        line-height: 18.6px;
        text-align: center;
    }

    .bgpartimgfre{
        width: 228.54px;
        height: 278.36px;
        margin-top: -305px;
        margin-left: -20px;
    }

    .bgpartimgpat{
      width: 189px;
      height: 297px;  
      margin-top: -323px;
      margin-left: 10px;
  }

    .experienced-margin-top {
        margin-top: 90px;
        margin-left: 0px;
      }

      .partner-margin-top{
        margin-top: 140px;
        left: 0px;
      }


    .bgpart1{
      width: 190px;
      height: 267px;      
    }

    .bgpart2{
        width: 170px;
        height: 263px;
    }

    .bgpart3{
      width: 153px;
      height: 274px;
      margin-left: -20px;
  }



    .bgpart4{
      width: 191px;
      height: 261px;
  }

    .Sec2box1{
        width: 100px;
        height: 100px;
        margin-left: -65px;
        margin-top: -10px;
    }

    .bgpartimgexp{
        width: 198px;
        height: 297px;
        margin-top: -325px;
        margin-left: 30px;
    }

    .bgpartimgcol{
      width: 158.96px;
      height: 272.62px;
      margin-top: -300px;
      margin-left: -20px;
    }

    .Sec2universities{
        margin-top: 14px;
        margin-left: 8px;
    }

    .college-arrow{
      margin-left: -58px;
    }

    .Sec2Count{
font-size: 20px;
font-weight: 400;
line-height: 24.8px;

    }

    .Sec2text{
font-size: 10px;
font-weight: 400;
line-height: 12.4px;
margin-top: -2px;
    }

    .Sec2box2{
        width: 72px;
height: 72px;
margin-left: -90px;
margin-top: 50px;

    }

    .Sec2box3{
        width: 123px;
height: 124px;
margin-top: 155px;
margin-left: -80px;

    }

    .coverdiv{
        width: 32.91px;
        height: 32.91px;
        margin-left: 170px;
        margin-top: -40px;

    }

    .arrowimg{
        width: 14.63px;
height: 18.28px;


    }

    .Sec2fullpart{
        margin-left: 40px;
    }

    .Section1{
        margin-top: 0px;
    }

    /* .Section2{
        margin-top: 80px;
    } */

    .Section3{
        margin-top: 0px;
    }

    .Sec3box3text{
        margin-top: 20px;
        margin-left: 15px;
    }

    .Sec3box2text{
        margin-top: 10px;
        margin-left: 6px;
    }

    .Sec2Count2{
font-size: 20px;
font-weight: 400;
line-height: 24.8px;

    }

    .small-font-size {
      font-size: 25px;
font-weight: 300;
line-height: 29px;
text-align: center;
width: 90%;
margin-top: 0px;
margin-bottom: 15px;
    }

    .bgpartimgcorp{
      width: 222.75px;
      height: 297px;
      margin-top: -325px;
      margin-left: -15px;
    }

    .bgpart5{
      width: 164px;
      height: 273px;
    }

    .Crequestbutton{
      width: 137px;
height: 42px;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
margin-top: -50px;
    }

    .partner .Sec1button{
      top: 0px;
      left: 0px;
      margin-top: 50px;
    }

    
  }