@media (min-width: 1400px){
    .Landingpgbox{
        width: 225px; 
        /* or 15% */
    }

}

@media (max-width: 1399px) {
    .Landingpgbox {
      width: 215px;
      /* or 17% */
    }
  }


  .Pluginlivelogo{
        width: 170px;
        height: 100px;
  }

  .LandingpgHeaderline{
    border: 1px solid rgba(56, 56, 71, 1);
    width: 83%;
    margin-left: auto;
    margin-right: auto;
  }

  .LandingpgHeaders{
    margin-top: 28px;
  }

  .LandingpgHeadersmain{
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 36px;
    font-weight: 300;
    line-height: 44.64px;
  }

  .LandingpgHeaderspara{
    width: 80%;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.8px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }

  .PickProfile{
    width: 229px;
    height: 60px;
    border-radius: 37.5px;
    border: 1px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(7, 60, 165, 1);
    font-size: 20px;
    font-weight: 400;
    line-height: 24.8px;
    text-align: center;
    color: rgba(7, 60, 165, 1);
    margin-top: 30px;
  }

.LandingpgContainer {
    text-align: center;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }
  
  .LandingpgHeader{
    font-size: 2em;
    margin: 20px 0;
    align-items: center;
    margin-bottom: 2.8em;
  }
  
  .Landingpgboxes {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .Landingpgbox {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid #000; */
    /* width: 17%; */
    height: 400px;
    margin: 10px;
  }

  .FresherBgSection{
    margin-left: 30px;
    cursor: pointer;
  }

  .ExperiencedBgSection{
    margin-left: 30px;
    cursor: pointer;
  }

  .CollegeBgSection{
    margin-left: 30px;
    cursor: pointer;
  }

  .PartnerBgSection{
    margin-left: 30px;
    cursor: pointer;
  }

  .CorporateBgSection{
    margin-left: 30px;
    cursor: pointer;
  }

  .FLandingBg{
    width: 199px;
    height: 269px;
    background: rgba(255, 91, 0, 1);
    border-top-right-radius: 90%;
    display: flex;
  }

  .ELandingBg{
    width: 195px;
    height: 269px;
    background: rgba(255, 188, 0, 1);
    display: flex;
  }

  .CLandingBg{
    width: 206px;
    height: 350px;
    background: rgba(137, 26, 97, 1);
    clip-path: polygon(0 0, 100% 54%, 100% 100%, 0 100%, 0% 38%);
    display: flex;
  }

  .PLandingBg{
    width: 217px;
    height: 294px;
    background: rgba(204, 5, 48, 1);
    border-top-right-radius: 100%;
    display: flex;
  }

  .CoLandingBg{
    width: 200px;
    height: 308px;
    background: rgba(7, 60, 165, 1);
    clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 38%);
    display: flex;
  }

  .FLandingimg{
    width: 266px;
    height: 323.99px;
    margin-top: -352px;
    margin-left: -38px;
    transition: opacity 0.3s ease-in-out; 
}


.ELandingimg{
    width: 231px;
    height: 345px;
    margin-top: -373px;
    margin-left: -2px;
    transition: opacity 0.3s ease-in-out; /* Smooth transition for fading */
}

.CLandingimg{
    width: 211.08px;
    height: 362px;
    margin-top: -391px;
    margin-left: 0px;
    transition: opacity 0.3s ease-in-out; /* Smooth transition for fading */
    z-index: 1;
    position: relative;
}

.PLandingimg{
    width: 229px;
    height: 359px;
    margin-top: -387px;
    margin-left: -10px;
    transition: opacity 0.3s ease-in-out; /* Smooth transition for fading */
}

.CoLandingimg{
    width: 220px;
    height: 293.33px;
    margin-top: -322px;
    margin-left: -10px;
    transition: opacity 0.3s ease-in-out; /* Smooth transition for fading */
    z-index: 1;
    position: relative;
}



.FLandingBgcontent{
    text-align: left;
    margin-top: 130px;
    margin-left: 25px;
}

.ELandingBgcontent{
    text-align: left;
    margin-top: 120px;
    margin-left: 20px;
}

.CLandingBgcontent{
    text-align: left;
    margin-top: 190px;
    margin-left: 30px;
}

.PLandingBgcontent{
    text-align: left;
    margin-top: 90px;
    margin-left: 25px;
    opacity: 0;
}

.CoLandingBgcontent{
    text-align: left;
    margin-top: 158px;
    margin-left: 25px;
}

.FLandingBgcontentheader{
    color: rgba(255, 255, 255, 1);
    font-size: 25px;
    font-weight: 500;
    line-height: 31px;
    text-align: left;
}

.ELandingBgcontentheader{
    color: rgba(255, 255, 255, 1);
    font-size: 25px;
    font-weight: 500;
    line-height: 31px;
    /* text-align: left; */
}

.CLandingBgcontentheader{
    color: rgba(255, 255, 255, 1);
    font-size: 25px;
    font-weight: 500;
    line-height: 31px;
    text-align: left;
}

.PLandingBgcontentheader{
    color: rgba(255, 255, 255, 1);
    font-size: 25px;
    font-weight: 500;
    line-height: 31px;
    text-align: left;
}

.CoLandingBgcontentheader{
    color: rgba(255, 255, 255, 1);
    font-size: 25px;
    font-weight: 500;
    line-height: 31px;
    text-align: left;
}

.FLandingBgcontentpara{
    width: 132px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.88px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

.ELandingBgcontentpara{
    width: 140px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.88px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

.CLandingBgcontentpara{
    width: 135px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.88px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

.PLandingBgcontentpara{
    width: 132px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.88px;
    text-align: left;
    color: rgb(255, 255, 255, 1);
}

.CoLandingBgcontentpara{
    width: 132px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.88px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

.FresherLandingheader{
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 91, 0, 1);
    margin-left: 30px;
    margin-top: -22px;
    cursor: pointer;
}

.ExperiencedLandingheader{
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 188, 0, 1);
    margin-left: 30px;
    margin-top: -22px;
    cursor: pointer;
}

.CollegeLandingheader{
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(137, 26, 97, 1);
    margin-left: 30px;
    margin-top: -22px;
    cursor: pointer;
}

.PartnerLandingheader{
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(204, 5, 48, 1);
    margin-left: 30px;
    margin-top: -22px;
    cursor: pointer;
}

.CorporateLandingheader{
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(7, 60, 165, 1);
    margin-left: 30px;
    margin-top: -22px;
    cursor: pointer;
}

.FresherLandingpara{
    display: none;
}



.PLandingBgcontent.visible {
    opacity: 1; /* Show the content when visible */
  }




@media (min-width: 1025px) {
    .FLandingimg:hover ,
    .ELandingimg:hover
    ,.CLandingimg:hover
    ,.PLandingimg:hover
    ,.CoLandingimg:hover  {
      opacity: 0;
    }


    .FresherLanding{
        margin-top: -60px;
    }

    .ExperiencedLanding{
        margin-top: 130px;
    }


    .PartnerLanding{
        margin-top: -100px;
    }

    .CorporateLanding{
        margin-top: 60px;
    }
  }

  
  @media (max-width: 1025px) {
    .Landingpgboxes {
      flex-direction: column;
      align-items: center;
    }
  
    .Landingpgbox {
      width: 80%; 
      margin: 10px 0;
      flex-direction: column;
      height: auto;
    }

    .PLandingBgcontent{
        opacity: 0;
    }

    .FresherLandingpara{
        display: block;
        font-size: 24px;
        font-weight: 400;
        line-height: 29.76px;
        text-align: center;
        width: 90%;
        margin-top: 18px;
    }

    .LandingpgContainer{
        margin-bottom: 4em;
    }
    .LandingpgHeadersmain{
        width: 73%;
    }

    .ExperiencedLanding{
        margin-top: 100px;
    }

    .CollegeLanding{
        margin-top: 100px;
    }

    .PartnerLanding{
        margin-top: 100px;
    }

    .CorporateLanding{
        margin-top: 100px;
    }

    .PLandingBg{
        border-top-left-radius: 90%;
        border-top-right-radius: 0%;
    }
  }

  @media  (max-width: 576px){
    .LandingpgHeadersmain{
        font-size: 25px;
        font-weight: 300;
        line-height: 31px;
        text-align: center;
    }


    .LandingpgHeaderspara{
        font-size: 12px;
        font-weight: 400;
        line-height: 14.88px;
        text-align: center;

    }

    .PickProfile{
        width: 167px;
        height: 42px;
        border-radius: 37.5px;
        border: 1px;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.88px;
        text-align: center;
        border: 1px solid rgba(7, 60, 165, 1);

    }

    .FLandingBg{
        width: 151.4px;
        height: 204.66px;
    }

    .FLandingimg{
        width: 202.38px;
        height: 246.5px;
        margin-top: -274px;
        margin-left: -28px;
    }

    .FLandingBgcontent{
        display: none;
    }

    .FresherLandingheader{
        font-size: 20px;
        font-weight: 400;
        line-height: 24.8px;
    }

    .FresherLandingpara{
        font-size: 10px;
        font-weight: 400;
        line-height: 12.4px;
        text-align: center;
        margin-top: 5px;
    }

    .ELandingBg{
        width: 148.36px;
        height: 204.66px;
    }

    .ELandingimg{
        width: 175.75px;
        height: 262.48px;
        margin-top: -290px;
        margin-left: 0px;
    }

    .ELandingBgcontent{
        display: none;
    }

    .ExperiencedLandingheader{
        font-size: 20px;
        font-weight: 400;
        line-height: 24.8px;
    }

    .CollegeBgSection{
        /* margin-left: 0px; */
    }

    .CLandingBg{
        width: 156.73px;
        height: 266.29px;
        margin-left: -30px;
    }

    .CLandingimg{
        width: 160.59px;
        height: 275.42px;
        margin-top: -304px;
        margin-left: -30px;
    }

    .CLandingBgcontent{
        display: none;
    }

    .CollegeLandingheader{
        font-size: 20px;
        font-weight: 400;
        line-height: 24.8px; 
        margin-left: 0px;
        /* end  */
    }

    .PLandingBg{
        width: 165.1px;
        height: 223.68px;
        margin-left: -28px;
    }

    .PLandingimg{
        width: 174.23px;
        height: 273.14px;
        margin-top: -301px;
        margin-left: -33px;
    }

    .PLandingBgcontent{
        display: none;
    }

    .PartnerLandingheader{
        font-size: 20px;
        font-weight: 400;
        line-height: 24.8px;
        margin-left: 0px;
    }

    .CoLandingBg{
        width: 152.16px;
        height: 234.33px;
        margin-left: -28px;
    }

    .CoLandingimg{
        width: 167.38px;
        height: 223.18px;
        margin-top: -253px;
        margin-left: -35px;
    }

    .CoLandingBgcontent{
        display: none;
    }

    .CorporateLandingheader{
        font-size: 20px;
        font-weight: 400;
        line-height: 24.8px;
        margin-left: 0px;
    }

    .ExperiencedLanding{
        margin-top: 70px;
    }

    .CollegeLanding{
        margin-top: 70px;
    }

    .PartnerLanding{
        margin-top: 70px;
    }

    .CorporateLanding{
        margin-top: 70px;
    }

  }
  