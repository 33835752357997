.HeaderImgs{
    width: 92%;
height: 462px;
}

.HeaderImgContainer {
    display: flex;
    flex-direction: column; /* Arrange items vertically */
    align-items: center; /* Center items horizontally */
    height: 100vh; /* Full viewport height to center the content vertically */
    text-align: center; /* Center text inside the span */
    margin-bottom: -280px;
}

.HeaderImgseffect{
    /* background: rgba(217, 217, 217, 1); */
    background: linear-gradient(360deg, #ffffff 6.1%, rgba(255, 255, 255, 0) 88.1%);
    margin-top: -460px;
    width: 92%;
    height: 462px;
}


@media screen and (max-width: 1024px){
    .HeaderImgContainer{
        margin-top: 30px;
        margin-bottom: -280px;
    }
}