.Signupbox{
    width: 95%;
height: 227px;
border-radius: 25px;
background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
margin-top: 70px;
margin-left: auto;
margin-right: auto;
position: relative;
}

.Signupboxhead{
    width: max-content;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 53px;
font-weight: 400;
line-height: 86.8px;
text-align: left;
padding-top: 30px;
padding-left: 30px;
color: rgba(255, 255, 255, 1);
}

.Signupboxpara{
    width: 630px;
height: 62px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
padding-top: 20px;
padding-left: 30px;
color: rgba(255, 255, 255, 1);
}

.Signupboxbutton{
    width: 173px;
height: 60px;
border-radius: 37.5px;
background: rgba(255, 255, 255, 1);
position: absolute;
border: none;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: center;
color: rgba(7, 60, 165, 1);
/* margin-top: -85px;
margin-left: 925px; */
right: 80px;
bottom: 65px;
}


@media screen and (max-width: 1024px){
    .Signupbox{
        width: 78.8%;
        height: 252px;        
    border-radius: 25px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    }

    .Signupboxhead{
        width: 92%;
    height: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 32px;
font-weight: 400;
line-height: 39.68px;
padding-left: 0px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: rgba(255, 255, 255, 1);
    }

    .Signupboxpara{
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding-top: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
text-align: center;
padding-left: 0px;
width: 85%;
    }

    .Signupboxbutton{
        width: 331px;
        height: 61px;
        border-radius: 37.5px;
        /* margin-top: 80px;
        margin-left: 0px; */
        background: rgba(255, 255, 255, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 29.76px;
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 190px;
    }

}

@media screen and (max-width: 578px){
   .Signupbox{
    width: 85%;
    height: 200px;
   }

   .Signupboxhead{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: center;
   }

   .Signupboxpara{
    width: 90%;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: center;
padding-top: 48px;
   }

   .Signupboxbutton{
    width: 137px;
height: 42px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: center;
/* margin-top: 36px; */
position: relative;
top: 30px;
   }
}


@media (min-width:300px) and (max-width:358px) {
    .Signupboxpara{
        width: 100%;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.88px;
    text-align: center;
    padding-top: 48px;
       }

       .Signupboxbutton{
        width: 137px;
    height: 42px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 18.6px;
    text-align: center;
    position: relative;
    top: 40px;
       }
}


@media (min-width:579px) and (max-width:767.5px) {
    .Signupboxhead{
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
        width: 90%;
        position: relative;
        top: -10px;
       }

       .Signupboxbutton{
        top: 205px;
       }
}