@media (min-width: 1400px){

  .bluformbox{
    width: 1233px;
height: 846px;
border-radius: 40px;
background: #1A1A82;
margin-top: -15%;
margin-bottom: 10%;
position: relative;
}

.greyformbox{
    width: 762px;
height: 846px;
position: relative;
margin-left: auto;
border-radius: 40px;   
border: 1px solid #073CA5;
background: linear-gradient(180deg, #FFFFFF 0%, #C5C5C5 100%);
}

.titfrmcol{
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 25px;
  position: relative;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  width: 250px;
  height: 64px;
  border-radius: 32px;
  background-color: rgba(232, 237, 252, 1);
  top: -35px;
  left: -185px;
}

.titfrmfre{
  align-items: center;
      font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 25px;
  position: relative;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  width: 290px;
  height: 64px;
  border-radius: 32px;
  background-color: rgba(232, 237, 252, 1);
  position: relative;
  left: -160px;
}

.bluformboxh1{
    width: 322px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
    margin-left: -55%;
    margin-top: 11%;    
}

.bluformboxp1{
margin-left: -50%;
width: 322px;
height: 217px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: left;
color: #FFFFFF;
margin-top: 2%;
margin-left: -55%;
}

.logcontent {
display: flex;
flex-direction: column;
align-items: center;
position: absolute;
top: 48%; 
left: 50%; 
transform: translate(-50%, -50%);
}

.fresher-cotent {
  display: flow-root;
  position: absolute; 
  height: 100%;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.frestag-style{
  position: relative;
  left: 0px;
}

.logsbutton.fres-style{
  position: relative;
  left: 0px;
}

.logsbutton {
position: relative;
left: -210px;
top: 20px;
}

.formsig{
  width: 173px;
  height: 60px;
  border-radius: 37.5px;
  background-color: #073CA5;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 20px;
  text-align: center;
  line-height: 24.8px;
  position: relative;
}

/* :where(.css-dev-only-do-not-override-1drr2mu).ant-form-item .ant-form-item-label >label {
font-size: 20px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-weight: 600;
line-height: 25px;
text-align: left;
} */

.custom-label {
  font-size: 20px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 600;
  line-height: 25px;
  text-align: left;
}

.namefld {
  display: flex;
  justify-content: space-between;
  }

.contactfld {
display: flex;
justify-content: space-between;
}

.otherfld {
display: flex;
justify-content: space-between;
}

.destinfld{
display: flex;
justify-content: space-between;
}

.startfld{
  display: flex;
  justify-content: space-between;
  }

  .endfld{
    display: flex;
    justify-content: space-between;
    }

}

@media (min-width:1228px) and (max-width:1399px){
  .fromsmalap{
    max-width:1300px;
    transform: scale(0.85);
    transform-origin: top;
    /* margin-bottom: 80%; */
  }

  .fres-blufrmbx{
    position: relative;
    margin-bottom: -130px;
  }

  .fresher-margin {
    margin-bottom: -170px;
  }

  .bluformbox{
      width: 1233px;
  height: 846px;
  border-radius: 40px;
  background: #1A1A82;
  margin-top: -15%;
  position: relative;
  }
  
  .greyformbox{
      width: 762px;
  height: 846px;
  position: relative;
  margin-left: auto;
  border-radius: 40px;   
  border: 1px solid #073CA5;
  background: linear-gradient(180deg, #FFFFFF 0%, #C5C5C5 100%);
  }

  .titfrmcol{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 250px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    top: -35px;
    left: -185px;
  }

  .titfrmfre{
    align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 290px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    position: relative;
    left: -160px;
  }

  .bluformboxh1{
      width: 322px;
      height: 50px;
      font-family: 'Plus Jakarta Sans', sans-serif;
      font-size: 40px;
      font-weight: 400;
      line-height: 50px;
      letter-spacing: 0em;
      text-align: center;
      color: #FFFFFF;
      margin-left: -55%;
      margin-top: 11%;    
}

.bluformboxp1{
  margin-left: -50%;
  width: 322px;
height: 217px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: left;
color: #FFFFFF;
margin-top: 2%;
margin-left: -55%;
}

.logcontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 48%; 
  left: 50%; 
  transform: translate(-50%, -50%);
}

.fresher-cotent {
  display: flow-root;
  position: absolute; 
  height: 100%;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.frestag-style{
  position: relative;
  left: 0px;
}

.logsbutton.fres-style{
  position: relative;
  left: 0px;
}

.logsbutton {
  position: relative;
  left: -210px;
  top: 20px;
  }

.custom-label {
  font-size: 20px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 600;
  line-height: 25px;
  text-align: left;
}

.namefld {
  display: flex;
  justify-content: space-between;
  }

.contactfld {
  display: flex;
  justify-content: space-between;
}

.otherfld {
  display: flex;
  justify-content: space-between;
}

.destinfld {
  display: flex;
  justify-content: space-between;
}

.startfld{
  display: flex;
  justify-content: space-between;
  }

  .endfld{
    display: flex;
    justify-content: space-between;
    }

.formsig{
  width: 173px;
  height: 60px;
  border-radius: 37.5px;
  background-color: #073CA5;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 20px;
  text-align: center;
  line-height: 24.8px;
}
}



@media (min-width:1028px) and (max-width:1226px){
  .fromsmalap{
    max-width:1300px;
    transform: scale(0.83);
    transform-origin: top;
    /* margin-bottom: 0%; */
  }

  .bluformbox{
      width: 1233px;
  height: 846px;
  border-radius: 40px;
  background: #1A1A82;
  margin-top: -15%;
  /* margin-bottom: 10%; */
  position: relative;
  left: -80px;
  }
  
  .greyformbox{
      width: 762px;
  height: 846px;
  position: relative;
  margin-left: auto;
  border-radius: 40px;   
  border: 1px solid #073CA5;
  background: linear-gradient(180deg, #FFFFFF 0%, #C5C5C5 100%);
  }

  .titfrmcol{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 250px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    top: -35px;
    left: -185px;
  }

  .titfrmfre{
    align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 290px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    position: relative;
    left: -160px;
  }

  .bluformboxh1{
      width: 322px;
      height: 50px;
      font-family: 'Plus Jakarta Sans', sans-serif;
      font-size: 40px;
      font-weight: 400;
      line-height: 50px;
      letter-spacing: 0em;
      text-align: center;
      color: #FFFFFF;
      margin-left: -55%;
      margin-top: 11%;    
}

.bluformboxp1{
  margin-left: -50%;
  width: 322px;
height: 217px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: left;
color: #FFFFFF;
margin-top: 2%;
margin-left: -55%;
}

.logcontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 51%; 
  left: 50%; 
  transform: translate(-50%, -50%);
}

.fresher-cotent {
  display: flow-root;
  position: absolute; 
  height: 100%;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.frestag-style{
  position: relative;
  left: 0px;
}

.logsbutton.fres-style{
  position: relative;
  left: 0px;
}

.logsbutton {
  position: relative;
  left: -210px;
  }

.custom-label {
  font-size: 20px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 600;
  line-height: 25px;
  text-align: left;
}

.namefld {
  display: flex;
  justify-content: space-between;
  }

.contactfld {
  display: flex;
  justify-content: space-between;
}

.otherfld {
  display: flex;
  justify-content: space-between;
}

.destinfld {
  display: flex;
  justify-content: space-between;
}

.startfld{
  display: flex;
  justify-content: space-between;
  }

  .endfld{
    display: flex;
    justify-content: space-between;
    }

.formsig{
  width: 173px;
  height: 60px;
  border-radius: 37.5px;
  background-color: #073CA5;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 20px;
  text-align: center;
  line-height: 24.8px;
}


}


@media (min-width:831px) and (max-width:1026px){

  .fres-blufrmbx{
    margin-bottom: -340px !important;
  }

  .fresher-margin{
    margin-bottom: -600px;
  }
  .fromsmalap{
    max-width:1080px;
    transform: scale(0.7);
    transform-origin: top;
    /* margin-bottom: 80%; */
  }

  .regisform{
    margin-left: -165px;
  }


  .bluformbox{
    width: 1233px;
height: 846px;
border-radius: 40px;
background: #1A1A82;
margin-top: -15%;
margin-bottom: 10%;
position: relative;
}

.greyformbox{
    width: 762px;
height: 846px;
position: relative;
margin-left: auto;
border-radius: 40px;   
border: 1px solid #073CA5;
background: linear-gradient(180deg, #FFFFFF 0%, #C5C5C5 100%);
}

.titfrmcol{
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 25px;
  position: relative;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  width: 250px;
  height: 64px;
  border-radius: 32px;
  background-color: rgba(232, 237, 252, 1);
  top: -35px;
  left: -185px;
}

.titfrmfre{
  align-items: center;
      font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 25px;
  position: relative;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  width: 290px;
  height: 64px;
  border-radius: 32px;
  background-color: rgba(232, 237, 252, 1);
  position: relative;
  left: -160px;
}

.bluformboxh1{
    width: 322px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
    margin-left: -55%;
    margin-top: 11%;    
}

.bluformboxp1{
margin-left: -50%;
width: 322px;
height: 217px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: left;
color: #FFFFFF;
margin-top: 2%;
margin-left: -55%;
}

.logcontent {
display: flex;
flex-direction: column;
align-items: center;
position: absolute;
top: 48%; 
left: 50%; 
transform: translate(-50%, -50%);
}

.frestag-style{
  position: relative;
  left: 0px;
}

.logsbutton.fres-style{
  position: relative;
  left: 0px;
}

.fresher-cotent {
  display: flow-root;
  position: absolute; 
  height: 100%;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logsbutton {
position: relative;
left: -210px;
top: 20px;
}

.formsig{
  width: 173px;
  height: 60px;
  border-radius: 37.5px;
  background-color: #073CA5;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 20px;
  text-align: center;
  line-height: 24.8px;
}

/* :where(.css-dev-only-do-not-override-1drr2mu).ant-form-item .ant-form-item-label >label {
font-size: 20px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-weight: 600;
line-height: 25px;
text-align: left;
} */

.custom-label {
  font-size: 20px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 600;
  line-height: 25px;
  text-align: left;
}

.namefld {
  display: flex;
  justify-content: space-between;
  }

.contactfld {
display: flex;
justify-content: space-between;
}

.otherfld {
display: flex;
justify-content: space-between;
}

.destinfld{
display: flex;
justify-content: space-between;
}

.startfld{
  display: flex;
  justify-content: space-between;
  }

  .endfld{
    display: flex;
    justify-content: space-between;
    }

}

@media (min-width:577px) and (max-width:830px){
 
  .fromsmalap{
    /* transform: scale(1.3);
    transform-origin: top; */
    margin-bottom: 30%;
  }

  .bluformbox{
    width: 98%;
height: 1150px;
border-radius: 25px;
background: #1A1A82;
margin-top: 5%;
margin-left: auto;
margin-right: auto;
position: relative;
}

.greyformbox{
  width: 100%;
height: 88%;
position: relative;
top: 205px;
border-radius: 25px;   
border: 1px solid #073CA5;
background: linear-gradient(180deg, #FFFFFF 0%, #C5C5C5 100%);
}

.fres-blufrmbx{
  position: relative;
  margin-bottom: 2200px;
}

.titfrmfre{
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 15px;
  position: relative;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  width: 170px;
  height: 40px;
  border-radius: 37.5px;
  background-color: rgba(232, 237, 252, 1);
  left: -215px;
  top: 10px;
}



.titfrmcol{
  /* display: flex; */
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 12px;
  position: relative;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  width: 105px;
  height: 34px;
  border-radius: 37.5px;
  width: 130px;
  height: 35px;
  background-color: rgba(232, 237, 252, 1);
  top: -20px;
  left: -240px;
}

.bluformboxh1{
  width: 202px;
  height: 31px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 25px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  position: relative;
  top: -160px;
  left: 50px;
}

.bluformboxp1{
  width: 282px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: left;
color: #FFFFFF;
position: relative;
top: -155px;
left: 50px;
}

.formsig{
  width: 137px;
  height: 42px;
  border-radius: 37.5px;
  background-color: #073CA5;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 15px;
  text-align: center;
  line-height: 18.6px;
  font-weight: 400;
  left: -110px;
  top: 90px;
}

.statefld{
  position: relative;
  margin-bottom: 5%;
}

.srtmthfld{
  position: relative;
  margin-bottom: 5%;
}

.endmthfld{
  position: relative;
  margin-bottom: 5%;
}

.destinfld{
  flex-direction: column;
  position: relative;
}

.startfld{
  flex-direction: column;
  position: relative;
}

.endfld{
  flex-direction: column;
  position: relative; 
}

.contactfld {
  flex-direction: column;
  position: relative;
  margin-bottom: -10%;
}

.otherfld {
  flex-direction: column;
  position: relative;
  margin-bottom: -6%;
}

.destinfld {
  flex-direction: column;
  position: relative;
}

.startfld {
  flex-direction: column;
  position: relative;
}

.endfld {
  flex-direction: column;
  position: relative;
}

.lastnamefld{
  position: relative;
  top: -20px;
  margin-bottom: 40px;
}

.locafld{
  position: relative;
  top: -20px;
}

.phonefld{
  position: relative;
  top: -20px;
  margin-bottom: 80px;
}

/* .contact{
  position: relative;
  top: -30px;
} */

.orgafld{
  position: relative;
  top: 0;
}

.namefld{
  position: relative;
  margin-bottom: -5%;
  top: 0px;
}

.profilefld{
  position: relative;
  margin-bottom: 0%;
}

.logcontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 44%; 
  left: 50%; 
  transform: translate(-50%, -50%);
}

.fresher-cotent {
  display: flow-root;
  position: absolute; 
  height: 100%;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.frestag-style{
  position: relative;
  left: 0px;
}

.logsbutton.fres-style{
  position: relative;
  left: 420px;
  top: -100px;
}

.logsbutton {
  display: flex;
  margin-top: 50px;
  position: absolute;
  bottom: 0%;
  left: 30%; 
  transform: translateX(-50%);
}

.custom-label {
  font-size: 15px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
}

}

@media (min-width:501px) and (max-width:576px){ 

  .bluformbox{
    width: 95.4%;
height: 1085px;
border-radius: 25px;
background: #1A1A82;
margin-top: -15%;
margin-bottom: 40%;
margin-left: auto;
margin-right: auto;
position: relative;
}

.greyformbox{
  width: 100%;
height: 880px;
position: relative;
top: 230px;
border-radius: 25px;   
border: 1px solid #073CA5;
background: linear-gradient(180deg, #FFFFFF 0%, #C5C5C5 100%);
}

.titfrmfre{
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 12px;
  position: relative;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  width: 145px;
  height: 34px;
  border-radius: 37.5px;
  background-color: rgba(232, 237, 252, 1);
  left: -70px;
  top: 20px;
}

.titfrmcol{
  /* display: flex; */
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 12px;
  position: relative;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  width: 105px;
  height: 34px;
  border-radius: 37.5px;
  width: 130px;
  height: 35px;
  background-color: rgba(232, 237, 252, 1);
  top: -20px;
  left: -80px;
}

.bluformboxh1{
  width: 202px;
  height: 31px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 25px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  position: relative;
  top: -160px;
  left: 50px;
}

.bluformboxp1{
  width: 282px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: left;
color: #FFFFFF;
position: relative;
top: -155px;
left: 50px;
}

.formsig{
  width: 137px;
  height: 42px;
  border-radius: 37.5px;
  background-color: #073CA5;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 15px;
  text-align: center;
  line-height: 18.6px;
  font-weight: 400;
  left: -30px;
  top: 70px;
}

.statefld{
  position: relative;
  margin-bottom: 10%;
}

.srtmthfld{
  position: relative;
  margin-bottom: 5%;
  margin-top: -5%;
}

.endmthfld{
  position: relative;
  margin-bottom: 5%;
}

.destinfld{
  flex-direction: column;
  position: relative;
}

.startfld{
  flex-direction: column;
  position: relative;
}

.endfld{
  flex-direction: column;
  position: relative; 
}

.contactfld {
  flex-direction: column;
  position: relative;
  margin-bottom: -10%;
}

.otherfld {
  flex-direction: column;
  position: relative;
  margin-bottom: -6%;
}

.destinfld {
  flex-direction: column;
  position: relative;
}

.startfld {
  flex-direction: column;
  position: relative;
}

.endfld {
  flex-direction: column;
  position: relative;
}

.lastnamefld{
  position: relative;
  top: -20px;
}

.locafld{
  position: relative;
  top: -20px;
}

.phonefld{
  position: relative;
  top: -20px;
  margin-bottom: 40px;
}

.orgafld{
  position: relative;
  top: 0;
}

.namefld{
  position: relative;
  margin-bottom: -5%;
  top: 0px;
}

.profilefld{
  position: relative;
  margin-bottom: 0%;
}

.logcontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 46%; 
  left: 50%; 
  transform: translate(-50%, -50%);
}

.fresher-cotent {
  display: flow-root;
  position: absolute; 
  height: 100%;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.frestag-style{
  position: relative;
  left: 0px;
}

.logsbutton.fres-style{
  position: relative;
  left: 180px;
  top: -100px;
}

.fres-blufrmbx{
  position: relative;
  margin-bottom: 100px;
}

.logsbutton {
  display: flex;
  margin-top: 50px;
  position: absolute;
  bottom: 0%;
  left: 34%; 
  transform: translateX(-50%);
}

.custom-label {
  font-size: 15px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
}

}

@media (max-width:500px){
  .bluformbox{
    width: 95.4%;
height: 1085px;
border-radius: 25px;
background: #1A1A82;
margin-top: -15%;
margin-bottom: 10%;
margin-left: auto;
margin-right: auto;
position: relative;
}

.greyformbox{
  width: 100%;
height: 880px;
position: relative;
top: 230px;
border-radius: 25px;   
border: 1px solid #073CA5;
background: linear-gradient(180deg, #FFFFFF 0%, #C5C5C5 100%);
}

.titfrmfre{
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 12px;
  position: relative;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  width: 145px;
  height: 34px;
  border-radius: 37.5px;
  background-color: rgba(232, 237, 252, 1);
  left: -70px;
  top: 20px;
}

.titfrmcol{
  /* display: flex; */
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 12px;
  position: relative;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  width: 105px;
  height: 34px;
  border-radius: 37.5px;
  width: 130px;
  height: 35px;
  background-color: rgba(232, 237, 252, 1);
  top: -20px;
  left: -80px;
}

.bluformboxh1{
  width: 202px;
  height: 31px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 25px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  position: relative;
  top: -160px;
  left: 50px;
}

.bluformboxp1{
  width: 282px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: left;
color: #FFFFFF;
position: relative;
top: -155px;
left: 50px;
}

.formsig{
  width: 137px;
  height: 42px;
  border-radius: 37.5px;
  background-color: #073CA5;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 15px;
  text-align: center;
  line-height: 18.6px;
  font-weight: 400;
  left: -30px;
  top: 70px;
}

.statefld{
  position: relative;
  margin-bottom: 10%;
}

.srtmthfld{
  position: relative;
  margin-bottom: 5%;
  margin-top: -5%;
}

.endmthfld{
  position: relative;
  margin-bottom: 5%;
}

.destinfld{
  flex-direction: column;
  position: relative;
}

.startfld{
  flex-direction: column;
  position: relative;
}

.endfld{
  flex-direction: column;
  position: relative; 
}

.contactfld {
  flex-direction: column;
  position: relative;
  margin-bottom: -10%;
}

.otherfld {
  flex-direction: column;
  position: relative;
  margin-bottom: -6%;
}

.destinfld {
  flex-direction: column;
  position: relative;
}

.startfld {
  flex-direction: column;
  position: relative;
}

.endfld {
  flex-direction: column;
  position: relative;
}

.lastnamefld{
  position: relative;
  top: -20px;
}

.locafld{
  position: relative;
  top: -20px;
}

.phonefld{
  position: relative;
  top: -20px;
  margin-bottom: 40px;
}

.orgafld{
  position: relative;
  top: 0;
}

.namefld{
  position: relative;
  margin-bottom: -5%;
  top: 0px;
}

.profilefld{
  position: relative;
  margin-bottom: 0%;
}

.logcontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 46%; 
  left: 50%; 
  transform: translate(-50%, -50%);
}

.fresher-cotent {
  display: flow-root;
  position: absolute; 
  height: 100%;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.frestag-style{
  position: relative;
  left: 0px;
}

.logsbutton.fres-style{
  position: relative;
  left: 180px;
  top: -100px;
}

.fres-blufrmbx{
  position: relative;
  margin-bottom: 100px;
}

.logsbutton {
  display: flex;
  margin-top: 50px;
  position: absolute;
  bottom: 0%;
  left: 34%; 
  transform: translateX(-50%);
}

.custom-label {
  font-size: 15px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
}

}
