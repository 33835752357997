@media (min-width: 1400px){
    .corpbluformbox{
        width: 1233px;
    height: 846px;
    border-radius: 40px;
    background: #1A1A82;
    margin-top: 10%;
    margin-bottom: 10%;
    }
    
    .corpgreyformbox{
        width: 762px;
    height: 846px;
    position: relative;
    margin-left: auto;
    border-radius: 40px;   
    border: 1px solid #073CA5;
    background: linear-gradient(180deg, #FFFFFF 0%, #C5C5C5 100%);
    }

    .corpformbac{
        width: 234px;
height: 390px;
background: #073CA5;
clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 38%);
margin-top: -66%;
margin-left: 8%;
position: relative;
    }

    .corpformimg{
        width: 294px;
height: 392px;
margin-top: -33%;
margin-left: 5.6%;
position: relative;
    }

    .corformtit{
        width: 199px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    margin-left: 4%;
    margin-top: 1%;
    color: #FFFFFF;
    position: relative;
    }
    
    .corpformpara{
        width: 400px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 4%;
    margin-top: 2%;
    position: relative;
    margin-bottom: 16.5%;
    }

.corplogcontent {
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin-top: -56%;
    margin-left: 40%; 
  }    
  .corplogsbutton {
    display: flex;
    margin-left: 45%;
    margin-top: 40px; 
  }

  :where(.css-dev-only-do-not-override-1drr2mu).ant-form-item .ant-form-item-label >label {
    font-size: 20px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
  }

  /* .titfrmcrp{
    align-items: center;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 250px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    position: relative;
    left: 530px;
    top: -920px;
  } */

}

@media (min-width:1228px) and (max-width:1399px){
    .corpformbac{
        width: 234px;
height: 390px;
background: #073CA5;
clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 38%);
margin-top: -65%;
margin-left: 8%;
position: absolute;
    }

    .corpformimg{
        width: 294px;
height: 392px;
margin-top: -97.7%;
margin-left: 5.6%;
position: relative;
    }

    .corformtit{
        width: 199px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    margin-left: 4%;
    margin-top: 1%;
    color: #FFFFFF;
    position: relative;
    }
    
    .corpformpara{
        width: 337px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 4%;
    margin-top: 2%;
    position: relative;
    /* margin-bottom: 16.5%; */
    }

  .formcorpext{
    position: relative;
    top: -30px;
    left: 0px;
    width: fit-content;
    /* margin-bottom: -50%; */
}

.corpregisform{
    position: relative;
    top: -30px;
    left: -50px;
    margin-bottom: -30%;
}

/* .titfrmcrp{
    align-items: center;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 250px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    position: relative;
    left: 510px;
    top: -665px;
  } */

}

@media (min-width:1028px) and (max-width:1226px){
    .corpformbac{
        width: 234px;
height: 390px;
background: #073CA5;
clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 38%);
margin-top: -65%;
margin-left: 8%;
position: absolute;
    }

    .corpformimg{
        width: 294px;
height: 392px;
margin-top: -97.7%;
margin-left: 5.6%;
position: relative;
    }

    .corformtit{
        width: 199px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    margin-left: 4%;
    margin-top: 1%;
    color: #FFFFFF;
    position: relative;
    }
    
    .corpformpara{
        width: 337px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 4%;
    margin-top: 2%;
    position: relative;
    /* margin-bottom: 16.5%; */
    }

  .formcorpext{
    position: relative;
    top: -30px;
    left: -20px;
    width: fit-content;
    /* margin-bottom: -50%; */
}

.corpregisform{
    position: relative;
    top: -30px;
    left: -50px;
    margin-bottom: -30%;
}

/* .titfrmcrp{
    align-items: center;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 250px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    position: relative;
    left: 510px;
    top: -665px;
  } */

}

@media (min-width:1001px) and (max-width:1027px){
    .corpformbac{
        width: 234px;
height: 390px;
background: #073CA5;
clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 38%);
margin-top: -82%;
margin-left: 8%;
position: absolute;
    }

    .corpformimg{
        width: 294px;
height: 392px;
margin-top: -91.5%;
margin-left: 5.6%;
position: relative;
    }

    .corformtit{
        width: 199px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    margin-left: 4%;
    margin-top: 1%;
    color: #FFFFFF;
    position: relative;
    }
    
    .corpformpara{
        width: 337px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 4%;
    margin-top: 2%;
    position: relative;
    margin-bottom: -35%;
    }

  .formcorpext{
    position: relative;
    width: 500px;
    top: -230px;
    left: -150px;
}

.corpregisform{
    position: relative;
    width: 500px;
    top: -490px;
    left: -160px;
    margin-bottom: -30%;
}

}

@media (min-width:831px) and (max-width:1000px){
    .corpformbac{
        width: 234px;
height: 390px;
background: #073CA5;
clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 38%);
margin-top: -82%;
margin-left: 8%;
position: absolute;
    }

    .corpformimg{
        width: 294px;
height: 392px;
margin-top: -91.5%;
margin-left: 5.6%;
position: relative;
    }

    .corformtit{
        width: 199px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    margin-left: 4%;
    margin-top: 1%;
    color: #FFFFFF;
    position: relative;
    }
    
    .corpformpara{
        width: 337px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 4%;
    margin-top: 2%;
    position: relative;
    margin-bottom: -35%;
    }

  .formcorpext{
    position: relative;
    width: 500px;
    top: -260px;
    left: -190px;
}

.corpregisform{
    position: relative;
    width: 500px;
    top: -460px;
    left: -190px;
    margin-bottom: -30%;
}

/* .titfrmcrp{
    align-items: center;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 250px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    position: relative;
    left: 510px;
    top: -665px;
  } */

}

@media (min-width:577px) and (max-width:830px){
    .corpregisform{
        position: absolute;
        top: 100px;
        left: 160px;
        transform: scale(1.4);
    transform-origin: top;
    width: 200px;
    height: 200px;
        
    }

    .corpformimg{
        width: 177px;
        height: 236px;
        top: -175px;
        left: -20px;
        position: relative;
    }

    .corpformbac{
        width: 142px;
height: 234px;
background: #073CA5;
clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 38%);
position: relative;
top: 60px;
    }

    .corformtit{
        width: 125px;
height: 25px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
color: #FFFFFF
    }

    .corpformpara{
        width: 370px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
color: #FFFFFF;
    }

    .formcorpext{
        position: absolute;
        top: 520px;
        left: 110px;
        margin-bottom: -140%;
    }

    .titfrmcrp{
        display: none !important;
    }

}

@media (min-width:501px) and (max-width:576px){ 
    .corpregisform{
        position: relative;
        top: -1490px;
        left: 40px;
    }

    .corpformimg{
        width: 177px;
        height: 236px;
        top: -175px;
        left: -20px;
        position: relative;
    }

    .corpformbac{
        width: 142px;
height: 234px;
background: #073CA5;
clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 38%);
position: relative;
top: 60px;
    }

    .corformtit{
        width: 125px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
color: #FFFFFF
    }

    .corpformpara{
        width: 282px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
color: #FFFFFF;
    }

    .formcorpext{
        position: relative;
        top: -1650px;
        left: 30px;
        margin-bottom: -130%;
    }

    .titfrmcrp{
        display: none !important;
    }
}

@media (max-width:500px){
    .corpregisform{
        position: relative;
        top: -1320px;
        left: 20px;
    }

    .corpformimg{
        width: 177px;
        height: 236px;
        top: -175px;
        left: -20px;
        position: relative;
    }

    .corpformbac{
        width: 142px;
height: 234px;
background: #073CA5;
clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 38%);
position: relative;
top: 60px;
    }

    .corformtit{
        width: 125px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
color: #FFFFFF
    }

    .corpformpara{
        width: 282px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
color: #FFFFFF;
    }

    .formcorpext{
        position: relative;
        top: -1480px;
        left: 10px;
        margin-bottom: -140%;
    }

    .titfrmcrp{
        display: none !important;
    }

}

