.custom-option .ant-select-item-option-content {
  white-space: normal; /* Allow text to wrap to the next line */
  overflow: hidden; /* Hide any overflowed text */
  text-overflow: ellipsis; /* Add ellipsis (...) for overflowed text if necessary */
}

.statepick {
  width: 295px !important;
  height: 60px !important;
  border-radius: 5px;
}

.citypick {
  width: 295px !important;
  height: 60px !important;
  border-radius: 5px;
}

.collegepick {
  width: 603px !important;
  height: 60px !important;
  border-radius: 5px;
}

.degreepick {
  width: 603px !important;
  height: 60px !important;
  border-radius: 5px;;
}

.edupick{
  width: 603px !important;
  height: 60px !important;
  border-radius: 5px;
}

.educpick{
  width: 603px !important;
  height: 60px !important;
  border-radius: 5px;
}

.departpick {
width: 603px !important;
height: 60px !important;
border-radius: 5px;
}

.depamtpick {
width: 603px !important;
height: 60px !important;
border-radius: 5px;
}

.specpick {
width: 603px !important;
height: 60px !important;
border-radius: 5px;
}

.specipick {
width: 603px !important;
height: 60px !important;
border-radius: 5px;
}

.starmothpick {
width: 295px !important;
height: 60px !important;
border-radius: 5px;
}

.staryerpick {
width: 295px !important;
height: 60px !important;
border-radius: 5px;
}

.endmthpick{
width: 295px !important;
height: 60px !important;
border-radius: 5px;
}

.endyerpick {
width: 295px !important;
height: 60px !important;
border-radius: 5px;
}

.statepick .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin-top: 10px;
}

.citypick .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin-top: 10px;
}

.collegepick .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin-top: 10px;
}

.degreepick .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin-top: 10px;
}

.edupick .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin-top: 10px;
}

.educpick .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin-top: 10px;
}

.departpick .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin-top: 10px;
}

.depamtpick .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin-top: 10px;
}

.specpick .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin-top: 10px;
}

.specipick .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin-top: 10px;
}

.starmothpick .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin-top: 10px;
}

.staryerpick .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin-top: 10px;
}

.endmthpick .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin-top: 10px;
}

.endyerpick .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin-top: 10px;
}


@media (min-width:577px) and (max-width:830px){
.citypick{
  width: 603px !important;
height: 60px !important;
border-radius: 5px;
}

.starmothpick{
width: 603px !important;
height: 60px !important;
border-radius: 5px;
}

.staryerpick{
width: 603px !important;
height: 60px !important;
border-radius: 5px;
}

.endmthpick{
width: 603px !important;
height: 60px !important;
border-radius: 5px;
}

.endyerpick{
width: 603px !important;
height: 60px !important;
border-radius: 5px;
}

.statepick{
width: 603px !important;
height: 60px !important;
border-radius: 5px;
}

}


@media (max-width:576px){
.citypick{
    width: 285px !important;
  height: 44px !important;
  border-radius: 5px;
}

.collegepick{
  width: 285px !important;
height: 44px !important;
border-radius: 5px;
}

.degreepick{
width: 285px !important;
height: 44px !important;
border-radius: 5px;
}

.edupick{
width: 285px !important;
height: 44px !important;
border-radius: 5px;
}

.educpick{
width: 285px !important;
height: 44px !important;
border-radius: 5px;
}

.departpick{
width: 285px !important;
height: 44px !important;
border-radius: 5px;
}

.depamtpick {
width: 285px !important;
height: 44px !important;
border-radius: 5px;
}

.endmthpick{
width: 285px !important;
height: 44px !important;
border-radius: 5px;
}

.endyerpick{
width: 285px !important;
height: 44px !important;
border-radius: 5px;
}

.specpick{
width: 285px !important;
height: 44px !important;
border-radius: 5px;
}

.specipick {
width: 285px !important;
height: 44px !important;
border-radius: 5px;
}  

.starmothpick{
width: 285px !important;
height: 44px !important;
border-radius: 5px;
}

.staryerpick{
width: 285px !important;
height: 44px !important;
border-radius: 5px;
}

.statepick{
width: 285px !important;
height: 44px !important;
border-radius: 5px;

}



}