.footer{
    background: linear-gradient(180deg, #073CA5 0%, #09093F 100%);

}

@media (min-width: 1500px){

    .copybox{
        margin-top: 2.5%;
    }

    .footer{
        /* font-family: 'Plus Jakarta Sans', sans-serif; */
        /* background: linear-gradient(180deg, #073CA5 0%, #09093F 100%); */
    background: linear-gradient(180deg, #073CA5 0%, #09093F 100%);
    border-radius: 50px 50px 0px 0px;
    color: white;
    text-decoration: none;

    }
    body{
        overflow-x: hidden;
    }
    
    .subsribe{
        width: 962px;
        margin-top: 5px;
        margin-left: 55px;
        /* margin-left: 60px; */
        height: 50px;
        border-radius:30px;
        background: linear-gradient(0deg, #043087, #043087);
        color: rgba(255, 255, 255, 1);
        border: 1px solid rgba(255, 255, 255, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
    
    }
    .footercontainer{
        overflow-x: hidden;
        margin-left: auto;
        margin-right: auto;
    }
    
    .subsribe::placeholder{
        color:rgba(255, 255, 255, 1);
    }
    
    .know{
        margin-top: 6%;
        width: 100%;
       /* margin-left: 50px;  */
       font-size: 70px;
       font-family: 'Plus Jakarta Sans', sans-serif;
       margin-left: 70px;
    }

    
    
    .subsbutton{
        height: 50px;
        margin-top: 2.5%;
        width: 175px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgba(255, 255, 255, 1);
    color: rgba(26, 26, 130, 1);
    margin-left: -90px;
    border: solid rgba(255, 255, 255, 1);
    position: relative;
    }
    
    .home{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 125px;
        position: relative;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
        color: rgba(255, 255, 255, 1);
    }
    
    .about{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 125px;
        position: relative;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
        color: rgba(255, 255, 255, 1);
    }
    
    .listing{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 125px;
        position: relative;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .security{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 125px;
        position: relative;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .fresherfooter{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 10px;
        position: relative;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .exper{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 10px;
        position: relative;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .partnerfoot{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 10px;
        position: relative;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .collegefoot{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 10px;
        position: relative;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .corporatee{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 10px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
        /* margin-bottom: 10%; */
        /* margin-top: -100%; */
        /* position: absolute; */
        position: relative;
        color: rgb(255, 255, 255, 1);
    }
    
    .home1{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 60px;
        position: relative;
        color: rgba(255, 255, 255, 1);
    }
    
    .footercontainer{
        margin-left: 100px;
       
    }
    .entermailc{
        /* margin-top: 20px; */
        margin-left: 65px;
    }
    .footlogo{
        margin-top: 3%;
        margin-left: 125px;
    }
    
    .copyright{
        width: 582px;
    height: 25px;
        margin-top: 1%;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    /* margin-left: 43%; */
    justify-content: center;
    align-items: center;
    
    }
    .terms{
        width: 209px;
    height: 30px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: right;
    text-decoration: none;
    color: rgba(255, 255, 255, 1);
    margin-top:10%;
    margin-left: 22%;
    position: relative;
    margin-bottom: -1%;
    }
    
    .privacy{
        margin-left: 15%;
        width: 209px;
    height: 30px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: right;
    text-decoration: none;
    position: absolute;
    margin-top: 5.3%;
    color: rgba(255, 255, 255, 1);
    }
    
    .faq{
        width: 109px;
    height: 30px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: right;
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
    margin-top: 30%;
    margin-left: 50%;
    position: relative;
    display: block;
    margin-bottom: -3%;
    }
    
    .tpc
    {   
        position: relative;
        margin-top: 200px;
        margin-left: -10%;
    }
    
}

@media (min-width:1228px) and (max-width:1499px){
    .footer{
        /* font-family: 'Plus Jakarta Sans', sans-serif; */
        /* background: linear-gradient(180deg, #073CA5 0%, #09093F 100%); */
    margin-top: -80%;
    border-radius: 50px 50px 0px 0px;
    text-decoration: none;
    }
    body{
        overflow-x: hidden;
    }
    
    .subsribe{
        width: 962px;
        margin-top: 5px;
        margin-left: 55px;
        height: 50px;
        border-radius:30px;
        background: linear-gradient(0deg, #043087, #043087);
        color: rgba(255, 255, 255, 1);
        border: 1px solid rgba(255, 255, 255, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
    }

    .footercontainer{
        overflow-x: hidden;
    }
    
    .subsribe::placeholder{
        color:rgba(255, 255, 255, 1);
        
    }
    
    .know{
        margin-top: 6%;
        width: 100%;
       /* margin-left: 50px;  */
       font-size: 70px;
       font-family: 'Plus Jakarta Sans', sans-serif;
       margin-left: 10px;
    }
    
    .subsbutton{
        height: 49px;
        margin-top: 3.6%;
        width: 175px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    position: relative;
    background: rgba(255, 255, 255, 1);
    color: rgba(26, 26, 130, 1);
    margin-left: 10px;
    border: solid rgba(255, 255, 255, 1);
    }
    
    .home{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 60px;
        position: relative;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
        color: rgba(255, 255, 255, 1);
    }
    
    .about{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 60px;
        position: relative;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
        color: rgba(255, 255, 255, 1);
    }
    
    .listing{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 60px;
        position: relative;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .security{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 60px;
        position: relative;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .fresherfooter{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: -60px;
        position: relative;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .exper{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: -60px;
        position: relative;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .partnerfoot{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: -60px;
        position: relative;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .collegefoot{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: -60px;
        position: relative;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .corporatee{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: -60px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
        /* margin-bottom: 10%; */
        /* margin-top: -100%; */
        /* position: absolute; */
        position: relative;
        color: rgb(255, 255, 255, 1);
    }
    
    .home1{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 60px;
        position: relative;
        color: rgba(255, 255, 255, 1);
    }
    
    .footercontainer{
        margin-left: 100px;
       
    }
    .entermailc{
        /* margin-top: 20px; */
        /* margin-left: 40px; */
    }
    .footlogo{
        margin-top: 3%;
        margin-left: 60px;
    }
    
    .copyright{
        width: 582px;
    height: 25px;
        margin-top: 1%;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    /* margin-left: 43%; */
    justify-content: center;
    align-items: center;
    
    }
    .terms{
        width: 209px;
    height: 30px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: right;
    text-decoration: none;
    color: rgba(255, 255, 255, 1);
    margin-top:9%;
    margin-left: 3%;
    position: absolute;
    }
    
    .privacy{
        margin-left: 3%;
        width: 209px;
    height: 30px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: right;
    text-decoration: none;
    position: absolute;
    margin-top: 10.75%;
    color: rgba(255, 255, 255, 1);
    }
    
    .faq{
        width: 109px;
        margin-left: 10.8%;
    height: 30px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: right;
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
    margin-top: 7.5%;
    position: absolute;
    }
    
    .tpc
    {   
        margin-left: -10%;
        margin-top: 100px;
    }
    
}


@media (min-width:1028px) and (max-width:1226px){
    .footer{
        /* font-family: 'Plus Jakarta Sans', sans-serif; */
        /* background: linear-gradient(180deg, #073CA5 0%, #09093F 100%); */
    margin-top: 0%;
    border-radius: 50px 50px 0px 0px;
    text-decoration: none;
    }
    body{
        overflow-x: hidden;
    }
    
    .subsribe{
        width: 962px;
        margin-top: 5px;
        margin-left: 55px;
        height: 50px;
        border-radius:30px;
        background: linear-gradient(0deg, #043087, #043087);
        color: rgba(255, 255, 255, 1);
        border: 1px solid rgba(255, 255, 255, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
    }

    .footercontainer{
        overflow-x: hidden;
    }
    
    .subsribe::placeholder{
        color:rgba(255, 255, 255, 1);
        
    }
    
    .know{
        margin-top: 6%;
        width: 100%;
       /* margin-left: 50px;  */
       font-size: 70px;
       font-family: 'Plus Jakarta Sans', sans-serif;
       margin-left: 10px;
    }
    
    .subsbutton{
        height: 49px;
        margin-top: 3.6%;
        width: 175px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    position: relative;
    background: rgba(255, 255, 255, 1);
    color: rgba(26, 26, 130, 1);
    margin-left: 10px;
    border: solid rgba(255, 255, 255, 1);
    }
    
    .home{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 60px;
        position: relative;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
        color: rgba(255, 255, 255, 1);
    }
    
    .about{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 60px;
        position: relative;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
        color: rgba(255, 255, 255, 1);
    }
    
    .listing{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 60px;
        position: relative;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .security{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 60px;
        position: relative;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .fresherfooter{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: -60px;
        position: relative;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .exper{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: -60px;
        position: relative;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .partnerfoot{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: -60px;
        position: relative;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .collegefoot{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: -60px;
        position: relative;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .corporatee{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: -60px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
        /* margin-bottom: 10%; */
        /* margin-top: -100%; */
        /* position: absolute; */
        position: relative;
        color: rgb(255, 255, 255, 1);
    }
    
    .home1{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 60px;
        position: relative;
        color: rgba(255, 255, 255, 1);
    }
    
    .footercontainer{
        margin-left: 100px;
       
    }
    .entermailc{
        /* margin-top: 20px; */
        /* margin-left: 40px; */
    }
    .footlogo{
        margin-top: 3%;
        margin-left: 60px;
    }
    
    .copyright{
        width: 582px;
    height: 25px;
        margin-top: 1%;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    /* margin-left: 43%; */
    justify-content: center;
    align-items: center;
    
    }
    .terms{
        width: 209px;
    height: 30px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: right;
    text-decoration: none;
    color: rgba(255, 255, 255, 1);
    margin-top:9%;
    margin-left: 3%;
    position: absolute;
    }
    
    .privacy{
        margin-left: 3%;
        width: 209px;
    height: 30px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: right;
    text-decoration: none;
    position: absolute;
    margin-top: 10.75%;
    color: rgba(255, 255, 255, 1);
    }
    
    .faq{
        width: 109px;
        margin-left: 10.8%;
    height: 30px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: right;
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
    margin-top: 7.5%;
    position: absolute;
    }
    
    .tpc
    {   
        margin-left: -10%;
        margin-top: 100px;
    }
    
}


@media (min-width:831px) and (max-width:1026px) {

    .footer{
    border-radius: 50px 50px 0px 0px;
    text-decoration: none;
    }

    .know{
        width: 736px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
margin-left: 3%;

    }

    .subsribe{
        width: 729.53px;
height: 60px;
border-radius: 37.5px;
border: 1px;
border: 1px solid rgba(255, 255, 255, 1);
background: rgba(4, 48, 135, 1);
margin-left: 10%;

    }

    .subsribe::placeholder{
        width: 156px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);
/* margin-left: 20%; */


    }

    .subsbutton{
        width: 186.53px;
height: 60px;
border-radius: 37.5px;
background: rgba(255, 255, 255, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
color: rgba(26, 26, 130, 1);
border: none;
position: relative;
/* margin-left: -120%; */

    }

    .footlogo{
        width: 263px;
height: 47px;
margin-left: 10%;
margin-top: 3%;

    }

    .home{
        width: 148.62px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);
text-decoration: none;
margin-left: 43%;
    }

    .about{
        width: 200px;
        height: 30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        text-decoration: none;
        margin-left: 43%;
    }

    .listing{
        width: 148.62px;
        height: 30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        text-decoration: none;
        margin-left: 43%;
    }

    .security{
        width: 148.62px;
        height: 30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        text-decoration: none;
        margin-left: 43%;
    }

    .fresherfooter{
        width: 148.62px;
        height: 30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        text-decoration: none;
        margin-left: 8%;
    }

    .exper{
        width: 148.62px;
        height: 30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        text-decoration: none;
        margin-left: 8%;
    }

    .collegefoot{
        width: 148.62px;
        height: 30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        text-decoration: none;
        margin-left: 8%;
    }

    .partnerfoot{
        width: 148.62px;
        height: 30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        text-decoration: none;
        margin-left: 8%;
    }

    .corporatee{
        width: 148.62px;
        height: 30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        text-decoration: none;
        margin-left: 8%;
    }

    .faq{
        width: 227px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: right;
color: rgba(255, 255, 255, 1);
text-decoration: none;
display: flex;
justify-content: end;
margin-left: -14%;
margin-top: 9.12%;
position: absolute;

    }

    .terms{
        margin-top: 10%;
        width: 227px;
        height: 30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: right;
        color: rgba(255, 255, 255, 1);
        text-decoration: none;
        display: flex;
        justify-content: end;
        margin-left: -14%;
        margin-top: 11.3%;
        position: absolute;
        
    }

    .privacy{
        width: 227px;
        height: 30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: right;
        color: rgba(255, 255, 255, 1);
        text-decoration: none;
        display: flex;
        justify-content: end;
        margin-left: -14%;
        margin-top: 13.5%;
        position: absolute;
        
    }

}



@media (max-width: 576px) {

    .footer{
        border-radius: 50px 50px 0px 0px;
    }

    .terms{
        width: 209px;
        height: 18px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    text-decoration: none;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 32.5%;
    margin-left: -35%;
    }
    
    .privacy{
        margin-left: 3%;
        width: 209px;
    height: 30px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    text-align: right;
    text-decoration: none;
    position: absolute;
    margin-top: 35%;
    margin-left: -59.5%;
    color: rgba(255, 255, 255, 1);
    }
    
    .faq{
        width: 109px;
        margin-left: 34.5%;
    height: 30px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
    position: absolute;
    margin-top: 30%;
    margin-left: -35%;
    }
    .fresherfooter{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        position:absolute;
        margin-top: -16%;
        margin-left: 7%;
        color: rgba(255, 255, 255, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
    }

    .exper{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        position:absolute;
        margin-top: -11%;
        margin-left: 7%;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
    }

    .collegefoot{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        position:absolute;
        margin-top: -3%;
        margin-left: 7%;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
    }

    .partnerfoot{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        position:absolute;
        margin-top: 5%;
        margin-left: 7%;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
    }

    .corporatee{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        position:absolute;
        margin-top: 14%;
        /* padding-top: -100%;  */
        margin-left: 7%;
        color: rgb(255, 255, 255, 1);
        font-size: 16px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
    }

    .footercontainer{
        width: max-content;
    }

    .footer{
        margin-top: 10%;
    }

    .home{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: -35%;
        position: absolute;
        /* text-align: left;     */
        font-size: 16px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);
    }
    .about{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: -35%;
        margin-top: 5%;
        position: absolute;
        text-decoration: none;
        color: rgba(255, 255, 255, 1);
        /* text-align: left;     */
        font-size: 16px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
    }
    .listing{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: -35%;
        margin-top: 13%;
        position: absolute;
        text-decoration: none;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
        /* text-align: left;     */
    }
    .security{
        text-decoration: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: -35%;
        margin-top: 22%;
        position: absolute;
        text-decoration: none;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
        /* text-align: left;     */
    }


    .footlogo{
        width: 168px;
height: 30px;
margin-left: 110px;
margin-top: 0px;
position: relative;

    }

    .entermailc{
        width: 335px;
height: 42px;
border-radius: 37.5px;
border: 1px;
margin-left: -50px;
    }
    .know{
        width: 280px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 30px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-top: 30px;
margin-left: 0px;
    }
    .fresherfoot{
       padding-top: 7%;
    }

    .subsribe::placeholder{
        color:rgba(255, 255, 255, 1);
        width: 90px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;


    }
    .subsribe{
        width: 335px;
height: 42px;
border-radius: 37.5px;
border: 1px;
margin-left: 18%;
background: rgba(7, 57, 159, 1);
border: 1px solid rgba(255, 255, 255, 1)

    }

    .subsbutton{
        position: relative;
        top: -42px;
        border: none;
        left: 92.8px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
width: 137px;
height: 42px;
color: rgba(7, 60, 165, 1);

border-radius: 37.5px;
background: rgba(255, 255, 255, 1);


    }

    .copybox{
        margin-top: 35%;
    }
.copyright{
    margin-top: 4%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: center;
margin-left: 0%;
}

body{
    overflow-x: hidden;
}

    
}