@media (min-width: 1400px){
    /* .fresbluformbox{
        width: 1233px;
    height: 846px;
    border-radius: 40px;
    background: #1A1A82;
    margin-top: 10%;
    margin-bottom: 10%;
    }
    
    .fresgreyformbox{
        width: 762px;
    height: 846px;
    position: relative;
    margin-left: auto;
    border-radius: 40px;   
    border: 1px solid #073CA5;
    background: linear-gradient(180deg, #FFFFFF 0%, #C5C5C5 100%);
    } */

    .fresbackimg{
        width: 262.28px;
        height: 356px;
        background: #FF5B00;
        top: 438px; 
        left: 250px;
        border-radius: 0px 100% 0px 0px;
        position: relative;
    }

    .formfresimg{
        width: 351.4px;
        height: 428px;
        top: 10px; 
        left: 200px;
        position: relative;
}

/* .freformimgcontainer{
    position:absolute;
    margin-top: -2660px;
    margin-left: -11%;
} */

    .fresformtit{
        width: 142px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    top: 840px;
    left: 50px;
    color: #FFFFFF;
    position: absolute;
    }
    
    .freformpara{
        width: 337px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    top: 910px; 
    left: 50px;
    position: absolute;
    }
  
    .formfrestxt{
        position: absolute;
        top: 0%;
        width: fit-content;
    }

  

    .fresregisform{
        position: absolute;
        top: 0%;
        left: 0%;
    }

/* .freslogcontent {
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin-top: -56%;
    margin-left: 40%; 
  }    
  .freslogsbutton {
    display: flex;
    margin-left: 45%;
    margin-top: 40px; 
  } */

  /* :where(.css-dev-only-do-not-override-1drr2mu).ant-form-item .ant-form-item-label >label {
    font-size: 20px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
  } */

  /* .titfrmfre{
    align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 170px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    position: relative;
    left: -210px;
  } */

}


@media (min-width:1228px) and (max-width:1399px){

    .fresbackimg{
        width: 262.28px;
        height: 356px;
        background: #FF5B00;
        top: 155px; 
        left: 120px;
        border-radius: 0px 100% 0px 0px;
        position: absolute;
    }

    .formfresimg{
        width: 351.4px;
        height: 428px;
        top: 84px; 
        left: 70px;
        position: absolute;
}

    .fresformtit{
        width: 142px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    top: 560px;
    left: 10px;
    color: #FFFFFF;
    position: absolute;
    }
    
    .freformpara{
        width: 337px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    top: 630px; 
    left: 10px;
    position: absolute;
    }
  
    .formfrestxt{
        position: absolute;
        top: 0%;
        width: fit-content;
    }

    .fresregisform{
        position: absolute;
        top: 0%;
        left: 0%;
    }

    /* .titfrmfre{
        align-items: center;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        position: relative;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        width: 170px;
        height: 64px;
        border-radius: 32px;
        background-color: rgba(232, 237, 252, 1);
        position: relative;
        left: 530px;
        top: -835px;
      } */
}

@media (min-width:1028px) and (max-width:1226px){
    .fresbackimg{
        width: 262.28px;
        height: 356px;
        background: #FF5B00;
        top: 155px; 
        left: 120px;
        border-radius: 0px 100% 0px 0px;
        position: absolute;
    }

    .formfresimg{
        width: 351.4px;
        height: 428px;
        top: 84px; 
        left: 70px;
        position: absolute;
}

    .fresformtit{
        width: 142px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    /* top: 67%;  */
    top: 560px;
    left: 10px;
    color: #FFFFFF;
    position: absolute;
    }
    
    .freformpara{
        width: 337px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    top: 630px; 
    left: 10px;
    position: absolute;
    }
  
    .formfrestxt{
        position: absolute;
        top: 0%;
        left: 20px;
        width: fit-content;
    }

    .fresregisform{
        position: absolute;
        top: 0%;
        left: -80px;
    }

    /* .titfrmfre{
        align-items: center;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        position: relative;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        width: 170px;
        height: 64px;
        border-radius: 32px;
        background-color: rgba(232, 237, 252, 1);
        position: relative;
        left: 530px;
        top: -835px;
      } */
}

@media (min-width:831px) and (max-width:1026px){
    .fresbackimg{
        width: 262.28px;
        height: 356px;
        background: #FF5B00;
        top: 155px; 
        left: 120px;
        border-radius: 0px 100% 0px 0px;
        position: absolute;
    }

    .formfresimg{
        width: 351.4px;
        height: 428px;
        top: 84px; 
        left: 70px;
        position: absolute;
}

    .fresformtit{
        width: 142px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    /* top: 67%;  */
    top: 560px;
    left: 10px;
    color: #FFFFFF;
    position: absolute;
    }
    
    .freformpara{
        width: 337px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    top: 630px; 
    left: 10px;
    position: absolute;
    }
  
    .formfrestxt{
        position: absolute;
        top: 0%;
        left: -65px;
        width: fit-content;
    }

    .fresregisform{
        position: absolute;
        top: 0%;
        left: -170px;
    }

}

@media (min-width:577px) and (max-width:830px){
    .fresregisform{
        position: absolute;
        top: 120px;
        left: 170px;
        transform: scale(1.4);
    transform-origin: top;
    width: 200px;
    height: 200px;
    }

    .formfresimg{
        width: 202.79px;
        height: 247px;
        top: -176px;
        left: -28px;
        position: relative;
    }

    .fresbackimg{
        width: 151.71px;
height: 205.08px;
background: #FF5B00;
border-top-right-radius: 100%;
position: relative;
top: 70px;
    }

    .fresformtit{
        width: 89px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
color: #FFFFFF
    }

    .freformpara{
        width: 282px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
color: #FFFFFF;
    }

    .formfrestxt{
        position: absolute;
        top: 515px;
        left: 130px;
    }

    /* .titfrmfre{
        display: none !important;
    } */

}

@media (max-width:576px){
    .fresregisform{
        position: absolute;
        top: 180px;
        left: 45px;
    }

    .formfresimg{
        width: 202.79px;
        height: 247px;
        top: -176px;
        left: -28px;
        position: relative;
    }

    .fresbackimg{
        width: 151.71px;
height: 205.08px;
background: #FF5B00;
border-top-right-radius: 100%;
position: relative;
top: 70px;
    }

    .fresformtit{
        width: 89px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
color: #FFFFFF
    }

    .freformpara{
        width: 282px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
color: #FFFFFF;
    }

    .formfrestxt{
        position: absolute;
        top: 470px;
        left: 40px;
    }

    /* .titfrmfre{
        display: none !important;
    } */

}

