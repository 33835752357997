


 
 @import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");
 .navbarimage{
    width: 170px;
height: 100px;
margin-left: 8%;

  }

  .headerline{
    top: 20%;
    margin: 3rem;
    /* margin-top: 90%; */
    /* position: absolute; */
    width: 80%;
  }

  .pickarrow{
    width: 11px;
height: 7px;
position: absolute;
/* margin-left: 0.3%;
margin-top: 0.4%; */
/* position: absolute;
margin-left: 6%;
margin-top: 3%; */
top: 5px;
right: 15px;
  }

  .active {
    color: rgba(52, 66, 217, 1) !important; /* Change color to highlight the active link */
    /* font-weight: bold;  */
  }


  .dropdown-content {
    display: none;
    border-radius: 8px;
    position: absolute;
    /* margin-top: 14%;
    margin-left: 1.5%; */
    top: 100%; /* Place it just below the navbutton */
    left: 15px;
    background-color: #ffffff;
    width: 150px;
    height: 196px;
    border: 2px;
    /* min-width: 140px; */
    box-shadow: 0 0px 5px 0 rgba(0,0,0,0.2);
    z-index: 1;
  }

  @media (min-width:1024px) and (max-width:1399px){
    .dropdown-content{
        /* margin-top: 17%; */
    }
  }

  .navdropdown{
    list-style-type: none;
    cursor: pointer;
  }
  
  .dropdown-content .navdropcontents{
    list-style-type: none;
    color: black;
    text-decoration: none;
    display: block;
    text-align: center;
    width: 150px;
    margin-left: 0%;
    margin-top: 10%;
    margin-bottom: 2%;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    /* text-align: left; */
  }
  
  .dropdown-content .navdropcontents:hover {
    color: rgba(52, 66, 217, 1);
  }
  
  .navbutton:hover .dropdown-content {
    display: block;
  }

  .navbutton {
    position: relative; /* This will make it the reference point for absolute positioning of dropdown-content */
  }



* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: 'Plus Jakarta Sans', sans-serif;

}


header {
    margin-top: 3%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	padding: 0 2rem;
    color: black;
}

nav a {
	margin: 0 2rem;
    /* margin-left: -20%; */
font-size: 15px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: left;
    color: black;
    	text-decoration: none;
}

.navbarsignintext{
    width: 60px;
height: 16px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 600;
line-height: 16.25px;
text-align: left;
color: rgba(13, 13, 13, 1);
cursor: pointer;

}

.navbarhome{
    margin-left: -25%;
}

/* nav a:hover {
	color: var(--secondaryColor);
} */

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
	display: flex;
	align-items: center;
    background-color: white;
}

.navbarsignin{
    position: absolute;
    margin-left: 40%;
    margin-top: 1%;
}

.lapscreensigninimg{
    position: absolute;
    margin-left: 17%;
    margin-top: -20%;
}

.smallscreensigninimg{
    display: none;
}

@media screen and (max-width :391px){
  header .responsive_nav{
    transform: translateY(105vh) !important;
  }
}


@media screen and (max-width: 1026px) {

    .overlay-menu{
      position: relative;
      width: 100%;
      z-index: 9999;
      background-color: rgba(255, 255, 255, 0.9);
    }


    .pickarrow{
        width: 11px;
    height: 7px;
    position: absolute;
    top: 5px;
right: 85px;
    /* margin-left: 3%;
    margin-top: 1%; */
    /* margin-left: 0.3%;
    margin-top: 0.4%; */
      }

    .dropdown-content {
        display: none;
        position: absolute;
        /* margin-top: 18%; */
        /* margin-left: 10%; */
        left: 75px;
        background-color: #f9f9f9;
        width: 160px;
        min-width: 140px;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        z-index: 1;
      }
      .navbarpick{
        width: 250px;
        height: 30px;
        /* margin-left:36%; */
        /* align-items: center; */
        /* display: flex;
        cursor: pointer; */
        left: 72px;
        /* display: inline-block; */
        position: relative;
      }

    .smallscreensigninimg{
        display:block;
        position: absolute;
        margin-left: 40%;
        margin-top: -1.6%;
    }   

    .lapscreensigninimg{
        display: none;
    }

    .navbarimage{
        width: 170px;
    height: 100px;
    margin-left: 2%;
    
      }

      .navbarhome{
        margin-left: 10%;
    }

    .navbarsignin{
        position: relative;
        margin-left: 0%;
        width: 150%;
        height: 55px;
        background: rgba(7, 60, 165, 1);
        display: flex;
        /* justify-content: center; */
        align-items: center;
        /* margin-left: 45%; */
    }

    .navbarsignintext{
        margin-top: 1%;
        margin-left: 6.5%;
        color: white;
    }
	header .nav-btn {
		visibility: visible;
        color: black;
		opacity: 1;
        position: absolute;
		/* top: 2rem; */
        /* margin-top: -31.95%; */
		right: 2rem;
	}

	header nav {
		position: fixed;
		top: -100vh;
		left: 0;
        margin-top: 10%;
		height: 23%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items:start;
		justify-content: center;
		gap: 1.3rem;
		/* background-color: white; */
		transition: 0.8s;
	}

	header .responsive_nav {
		transform: translateY(101vh);
	}

	/* nav .nav-btn {
		position: absolute;
        margin-top: -31.95%;
		right: 2rem;
        color: black;
	} */

	nav a {
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 10%;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;

	}

    .headerline{
        top: 20%;
        margin: 1rem;
        /* margin-top: 90%; */
        /* position: absolute; */
        width: 80%;
      }
}

@media (min-width:499px) and (max-width: 821px) {
    .navbarpick{
        left: 53px;
    }
}

@media only screen and (max-width: 500px){

    .pickarrow{
        right: 35px;
        top: 7px;
    }

    .overlay-menu{
      width: 100%;
      position: relative;
      z-index: 9999;
      background-color: rgba(255, 255, 255, 0.9);
    }

    .headerline{
        top: 20%;
        margin: 1rem;
        /* margin-top: 90%; */
        /* position: absolute; */
        width: 80%;
      }

    .dropdown-content {
        display: none;
        position: absolute;
        /* margin-top: 40%;
        margin-left: 10%; */
        left: 22px;
        background-color: #f9f9f9;
        width: 160px;
        min-width: 140px;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        z-index: 1;
      }
      .navbarpick{
        width: 191px;
        height: 25px;
        position: relative;
        left: 15px;
      }

    .smallscreensigninimg{
        display:block;
        position: absolute;
        margin-left: 40%;
        margin-top: -3.3%;
        width: 34px;
height: 34px;

    }   

    .navbarsignin{
        position: relative;
        margin-left: -2%;
        width: 150%;
        height: 55px;
        background: rgba(7, 60, 165, 1);
        display: flex;
        /* justify-content: center; */
        align-items: center;
        /* margin-left: 45%; */
    }

    .navbarsignintext
    {
        margin-left: 8%;
    }

    header nav {
		position: fixed;
		top: -100vh;
		left: 5px;
        margin-top: 24%;
		height: 24%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items:start;
		justify-content: center;
		gap: 1rem;
		/* background-color: white; */
		transition: 0.8s;
	}
}






