.phoselc {
    width: 70px;
    background: #ffffff;
    height: 60px;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    /* border-right: none; */
  }
  
  .phonselc input {
    height: 60px !important;
  }
  
  .phonselc {
    width: 295px;
  }

  @media (min-width:577px) and (max-width:830px){
    .phoselc{
      /* width: 603px; */
  height: 60px;
    border-radius: 5px;
  }

  .phonselc input {
    height: 60px !important;
  }
  
  .phonselc {
    width: 603px;
  }
  }
  

@media (max-width:576px){
    .phoselc {
        width: 70px;
        background: #ffffff;
        height: 44px;
        border: 1px solid #d1d1d1;
        border-radius: 5px;
      }
      
      .phonselc input {
        height: 44px !important;
      }
      
      .phonselc {
        width: 285px;
      }
}