.lstnamesize{
    width: 295px;
  height: 60px;
  border-radius: 5px;
  }

  @media (min-width:577px) and (max-width:830px){
    .lstnamesize{
      width: 603px;
  height: 60px;
    border-radius: 5px;
  }
  }
  
  @media (max-width:576px){
    .lstnamesize{
        width: 285px;
      height: 44px;
      border-radius: 5px;
    }
  }