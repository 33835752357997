.Trainbox{
    display: flex;
}

.trainc{
    width: 500px;
    height: 500px;
    border-radius: 100%;
    background-color: rgba(247, 91, 2, 0.1);
    position: relative;
    display: block;
    margin-left: -200px;
}

.Trainboxcontent{
    display: flex;
    flex-direction: column;
}

.trainboxheader{
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
position: absolute;
margin-top: 150px;
margin-left: -280px;
}

.trainboxsubheader{
    width: 80%;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
position: absolute;
margin-top: 250px;
margin-left: -270px;
}

.Assessbox{
    display: flex;
}

.assessc{
    width: 500px;
    height: 500px;
    border-radius: 100%;
    background: rgba(9, 9, 63, 0.05);
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: -200px;
}

.Assessboxcontent{
    /* text-align: right; */
    display: flex;
    flex-direction: column;
}

.assessboxheader{
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
position: absolute;
margin-top: 150px;
margin-left: -730px;
width: 100%;
/* right: 190px; */
}

.assessboxsubheader{
    /* width: 90%; */
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: right;
position: absolute;
margin-top: 250px;
margin-left: -1014px;
}

.asesboxexp {
    width: 805px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 15px;
text-align: right;
position: absolute;
margin-top: 250px;
margin-left: -880px;
  }

.asesboxexp2{
    position: relative;
    width: 1180px;
    left: -370px;
}

.Assessmentpartnercontent{
    display: none;
}

@media (min-width:1227px) and (max-width:1399px){

    .assessboxheader{
        left: 1280px; 
    }

    .assessboxsubheader{
        position: relative;
        left: -40px;
    }

    .asesboxexp{
        left: 1280px;
    }
}

@media screen and (max-width: 1024px){
    .asesboxexp{
        display: none;
    }
    /* .asesboxexp-combined{
        line-height: 31px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 84%;
        text-align: center;
        margin-top: 15px;
    } */


    .Assessmentpartnercontent{
        display: block;
        position: absolute;
        text-align: center;
        width: 80%;
        font-size: 24px;
        font-weight: 400;
        line-height: 29.76px;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 275px;


    }

    .trainboxheader{
        width: max-content;
       margin-left: 0px;
       left: 50%;
        transform: translate(-50%, -50%);
    }

    .trainboxsubheader{
        text-align: center;
        margin-left: 0px;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 260px;
    }

    .Assessboxcontent{
        display: flex;
        align-items: center;
        text-align: center;
    }

    .assessboxheader{
        margin-left: 0px;

        /* width: max-content;
        right: 0px;
        left: 50%;
        transform: translate(-50%, -50%); */
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

    }

    .assessboxsubheader{
        width: 90%;
        text-align: center;
        margin-left: 0px;
        left: 50%;
        transform: translate(-50%, -50%);
        /* width: 90%;
       text-align: center;
       left: 50%;
       transform: translate(-50%, -50%);
       margin-top: 270px; */
    }

}

@media screen and (max-width: 578px){
    .trainc{
        width: 260px;
        height: 260px;
        margin-left: -100px;
    }

    .Assessmentpartnercontent{
        font-size: 12px;
        font-weight: 400;
        line-height: 14.88px;
        text-align: center;
        margin-top: 155px;

    }

    .assessc{
        width: 260px;
        height: 260px;
        margin-right: -100px;
    }

    .trainboxheader{
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
        margin-top: 100px;
    }

    .trainboxsubheader{
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.88px;
        text-align: center;
        margin-top: 145px;

    }

    .assessboxheader{
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
        margin-top: 100px;
    }

    .assessboxsubheader{
       font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.88px;
        text-align: center;
        margin-top: 148px;
    }
}

