@media (min-width: 1400px){
    .formcolgext{
        position: relative;
        top: 0px;
        left: 0px;
        width: fit-content;
        margin-bottom: 18%;
    }

    .colgbluformbox{
        width: 1233px;
    height: 846px;
    border-radius: 40px;
    background: #1A1A82;
    margin-top: 10%;
    margin-bottom: 10%;
    }
    
    .colggreyformbox{
        width: 762px;
    height: 846px;
    position: relative;
    margin-left: auto;
    border-radius: 40px;   
    border: 1px solid #073CA5;
    background: linear-gradient(180deg, #FFFFFF 0%, #C5C5C5 100%);
    }

    .colgbackimg{
        width: 238px;
height: 425px;
background: #891A61;
clip-path: polygon(0 0, 100% 60%, 100% 100%, 0% 100%);
margin-top: -69%;
margin-left: 7%;
position: relative;
    }

    .formcolgimg{
        width: 257px;
height: 440px;
margin-top: -36.7%;
margin-left: 7%;
position: relative;
    }

    .colformtit{
        width: 172px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    margin-left: 6%;
    margin-top: 1%;
    color: #FFFFFF;
    position: relative;
    }
    
    .colgformpara{
        width: 390px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 6%;
    margin-top: 2%;
    position: relative;
    margin-bottom: 17%;
    }

.colglogcontent {
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin-top: -56%;
    margin-left: 40%; 
  }    
  .colglogsbutton {
    display: flex;
    margin-left: 45%;
    margin-top: 40px; 
  }

  :where(.css-dev-only-do-not-override-1drr2mu).ant-form-item .ant-form-item-label >label {
    font-size: 20px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
  }

  /* .titfrmcol{
    align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 250px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    position: relative;
    left: 530px;
    top: -920px;
  } */


}

@media (min-width:1228px) and (max-width:1399px){
    .colgbluformbox{
        width: 1233px;
    height: 846px;
    border-radius: 40px;
    background: #1A1A82;
    margin-top: 10%;
    margin-bottom: 10%;
    }
    
    .colggreyformbox{
        width: 762px;
    height: 846px;
    position: relative;
    margin-left: auto;
    border-radius: 40px;   
    border: 1px solid #073CA5;
    background: linear-gradient(180deg, #FFFFFF 0%, #C5C5C5 100%);
    }

    .colgbackimg{
        width: 238px;
height: 425px;
background: #891A61;
clip-path: polygon(0 0, 100% 60%, 100% 100%, 0% 100%);
margin-top: -67%;
margin-left: 7%;
position: relative;
    }

    .formcolgimg{
        width: 257px;
height: 440px;
margin-top: -39.8%;
margin-left: 7%;
position: relative;
    }

    .colformtit{
        width: 172px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    margin-left: 6%;
    margin-top: 1%;
    color: #FFFFFF;
    position: relative;
    }
    
    .colgformpara{
        width: 390px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 6%;
    margin-top: 2%;
    position: relative;
    margin-bottom: 17%;
    }

.colglogcontent {
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin-top: -56%;
    margin-left: 40%; 
  }    
  .colglogsbutton {
    display: flex;
    margin-left: 45%;
    margin-top: 40px; 
  }

  :where(.css-dev-only-do-not-override-1drr2mu).ant-form-item .ant-form-item-label >label {
    font-size: 20px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
  }

  .formcolgext{
    position: relative;
    top: -10px;
    left: -40px;
    width: fit-content;
    /* margin-bottom: -10%;   */
}

.colgregisform{
    /* position: relative; */
    margin-top: 5%;
    margin-left: -5.5%;
}

/* .titfrmcol{
    align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 250px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    position: relative;
    left: 540px;
    top: -895px;
  } */

}


@media (min-width:1028px) and (max-width:1226px){
    .colgbluformbox{
        width: 1233px;
    height: 846px;
    border-radius: 40px;
    background: #1A1A82;
    margin-top: 10%;
    margin-bottom: 10%;
    }
    
    .colggreyformbox{
        width: 762px;
    height: 846px;
    position: relative;
    margin-left: auto;
    border-radius: 40px;   
    border: 1px solid #073CA5;
    background: linear-gradient(180deg, #FFFFFF 0%, #C5C5C5 100%);
    }

    .colgbackimg{
        width: 238px;
height: 425px;
background: #891A61;
clip-path: polygon(0 0, 100% 60%, 100% 100%, 0% 100%);
margin-top: -67%;
margin-left: 7%;
position: relative;
    }

    .formcolgimg{
        width: 257px;
height: 440px;
margin-top: -39.8%;
margin-left: 7%;
position: relative;
    }

    .colformtit{
        width: 172px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    margin-left: 6%;
    margin-top: 1%;
    color: #FFFFFF;
    position: relative;
    }
    
    .colgformpara{
        width: 337px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 6%;
    margin-top: 2%;
    position: relative;
    margin-bottom: 17%;
    }

.colglogcontent {
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin-top: -56%;
    margin-left: 40%; 
  }    
  .colglogsbutton {
    display: flex;
    margin-left: 45%;
    margin-top: 40px; 
  }

  :where(.css-dev-only-do-not-override-1drr2mu).ant-form-item .ant-form-item-label >label {
    font-size: 20px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
  }

  .formcolgext{
    position: relative;
    top: -10px;
    left: -50px;
    margin-bottom: -10%;
    width: fit-content;
}

.colgregisform{
    /* position: relative; */
    margin-top: 5%;
    margin-left: -5.5%;
}

/* .titfrmcol{
    align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 250px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    position: relative;
    left: 540px;
    top: -895px;
  } */

}

@media (min-width:1001px) and (max-width:1027px){
    
    .colgbackimg{
        width: 238px;
height: 425px;
background: #891A61;
clip-path: polygon(0 0, 100% 60%, 100% 100%, 0% 100%);
margin-top: -85%;
margin-left: 7%;
position: absolute;
    }

    .formcolgimg{
        width: 257px;
height: 440px;
margin-top: -94%;
margin-left: 7%;
position: relative;
    }

    .colformtit{
        width: 172px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    margin-left: 6%;
    margin-top: 1%;
    color: #FFFFFF;
    position: relative;
    }
    
    .colgformpara{
        width: 337px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 6%;
    margin-top: 2%;
    position: relative;
    margin-bottom: 15%;
    }    

  .formcolgext{
    position: relative;
    width: 500px;
    top: -460px;
    left: -160px;
    margin-bottom: -60%;
}

.colgregisform{
    position: relative;
    width: 500px;
    top: -470px;
    left: -150px;
}

}

@media (min-width:831px) and (max-width:1000px){

    .colgbackimg{
        width: 238px;
height: 425px;
background: #891A61;
clip-path: polygon(0 0, 100% 60%, 100% 100%, 0% 100%);
margin-top: -85%;
margin-left: 7%;
position: absolute;
    }

    .formcolgimg{
        width: 257px;
height: 440px;
margin-top: -94%;
margin-left: 7%;
position: relative;
    }

    .colformtit{
        width: 172px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 49.6px;
    text-align: center;
    margin-left: 6%;
    margin-top: 1%;
    color: #FFFFFF;
    position: relative;
    }
    
    .colgformpara{
        width: 337px;
    height: 124px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 6%;
    margin-top: 2%;
    position: relative;
    margin-bottom: 15%;
    }

  .formcolgext{
    position: relative;
    width: 500px;
    top: -460px;
    left: -200px;
    margin-bottom: -60%;
}

.colgregisform{
    position: relative;
    width: 500px;
    top: -450px;
    left: -200px;
}

/* .titfrmcol{
    align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 250px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    position: relative;
    left: 540px;
    top: -895px;
  } */

}

@media (min-width:577px) and (max-width:830px){
    .colgregisform{
        position: absolute;
        top: 140px;
        left: 180px;
        transform: scale(1.4);
    transform-origin: top;
    width: 200px;
    height: 200px;
    }

    .formcolgimg{
        width: 132px;
        height: 226px;
        top: -186px;
        left: 0px;
        position: relative;
    }

    .colgbackimg{
        width: 123px;
height: 219px;
background: #891A61;
clip-path: polygon(0 0, 100% 54%, 100% 100%, 0 100%, 0% 38%);
position: relative;
top: 40px;
    }

    .colformtit{
        width: 108px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
color: #FFFFFF
    }

    .colgformpara{
        width: 430px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
color: #FFFFFF;
    }

    .formcolgext{
        position: absolute;
        top: 510px;
        left: 140px;
        margin-bottom: -140%;
    }
    

    /* .titfrmcol{
        display: none !important;
    } */

}

@media (min-width:501px) and (max-width:576px){
    .colgregisform{
        position: relative;
        top: -1460px;
        left: 30px;
    }

    .formcolgimg{
        width: 132px;
        height: 226px;
        top: -186px;
        left: 0px;
        position: relative;
    }

    .colgbackimg{
        width: 123px;
height: 219px;
background: #891A61;
clip-path: polygon(0 0, 100% 54%, 100% 100%, 0 100%, 0% 38%);
position: relative;
top: 40px;
    }

    .colformtit{
        width: 108px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
color: #FFFFFF
    }

    .colgformpara{
        width: 282px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
color: #FFFFFF;
    }

    .formcolgext{
        position: relative;
        top: -1630px;
        left: 30px;
        margin-bottom: -130%;
    }
}

@media (max-width:500px){
    .colgregisform{
        position: relative;
        top: -1290px;
        left: 20px;
    }

    .formcolgimg{
        width: 132px;
        height: 226px;
        top: -186px;
        left: 0px;
        position: relative;
    }

    .colgbackimg{
        width: 123px;
height: 219px;
background: #891A61;
clip-path: polygon(0 0, 100% 54%, 100% 100%, 0 100%, 0% 38%);
position: relative;
top: 40px;
    }

    .colformtit{
        width: 108px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
color: #FFFFFF
    }

    .colgformpara{
        width: 282px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
color: #FFFFFF;
    }

    .formcolgext{
        position: relative;
        top: -1460px;
        left: 10px;
        margin-bottom: -140%;
    }

    /* .titfrmcol{
        display: none !important;
    } */

}

