@media  (min-width: 1400px){
  
      

    .aboutuscollapsecontainer{
        background-color: white;
        margin-bottom: 20px;
        width: 1114px;
        margin-left: auto;
        margin-right: auto;
    }



    .arrowheadaboutus{
        margin-top: 34px;
    }



    .aboutuscollapseheader{
        width: 788px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 600;
line-height: 24.8px;
text-align: left;
margin-top: 17.5px;

    }

    .Faqblogcardlap{
        margin-top: 20%;
        margin-left: -5%;
    }


    .hideinlapfaq{
        display: none;
    }
 
    .faqheaderforphone{
        display: none;
    }
   
    .aboutstite1{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 234px;
    height: 64px;
    border-radius: 32px;
    background: #E8EDFC;
    align-items: left;
    margin-left: 20%;
    margin-top: 10%;
    }
    
    .aboutparas1{
        width: 761px;
        height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: -11%;
    margin-top: 0%;
    }
    
    .aboutingblog{
        width: 536px;
    height: 371px;
    border-radius: 25;   
    }
    
    .aboutlcblog{
        width: 69px;
    height: 69px;
    border-radius: 100%;
    background: #FFFFFF;
    margin-top: -130px;
    position: absolute;
    }
    
    .aboutauthblog{
        width: 136px;
    height: 30px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: -110px;
    margin-left: 80px;
    }
    
    .aboutlat{
        width: 173px;
    height: 60px;
    border-radius: 0 0 37px 37px;
    background: #252525;
    margin-top: -395px;
    margin-left: 300px;
    position: absolute;
    }
    
    .aboutlate{
        width: 110px;
    height: 19px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
    margin-left: -5%;
    margin-top: -2%;
    }
    
    .aboutmaiblog{
        margin-left: 13%;
        margin-top: 14%;
    }
    
    .aboutbuilblog{
        width: 484px;
    height: 90px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: -28%;
    margin-top: 20%;
    }
    
    .aboutbuildblog{
        width: 484px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: -28%;
    margin-top: -2%;
    }
    
    .aboutreadmore{
        width: 173px;
        height: 60px;
        border-radius: 37.5px;
        border: none;
        background-color: rgba(7, 60, 165, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        margin-top: 8px;
        margin-left: -140px;
    }
   
   
   /* jnaiclaobdciaybci */
    .faqscon{
        margin-top: 5%;
        margin-left: 4%;
    }
    
    .faqsimg{
        width: 1198px;
    height: 462px;
    position: relative;
    }

    .faqsimgmob{
        display: none;
    }
    
    .faqsconimg{
        width: 1198px;
    height: 462px;

    background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
    position: absolute;
    }
    
    .faqstext{
        width: 761px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-left: 20%;
    margin-top: -4%;
    }
    
    .faqstext2{
        width: 1030px;
    height: 62px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 0%;
    margin-left: 10%;
    }
    
    .faqssrch{
        width: 1114px;
    height: 67px;
    border-radius: 15px;
    background: #FFFFFF;
    border: 2px solid #E2E2E2;
    margin-left: 7%;
    margin-top: 3%;
    padding-left: 60px;
    }
    
    .faqsic{
        position: relative;
        width: 18.75px;
    height: 18.75px;
    margin-left: -83%;
    cursor: pointer; 
    }
    
    .faqssrch::placeholder{
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;    
    color: #0D0D0D;
    }
    
    .faqsord{
        width: 761px;
    height: 50px;
    font-family: 'Plus Jakarta Sans' sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 20%;
    }
    
    .faqsfr1-input {
        display: none;
      }
      
      .faqsfr1-label {
        cursor: pointer;
      }
      
      .faqsfr1-input:checked ~ .faqsfre1-label .faqsfre1 {
        border: 2px solid black;
      }
      
      .faqsfr1-input:checked + .faqsfre1-label .faqsfresh1 {
        color: black;
    }
    
    .faqsfr1{
        margin-left: 12%;
        margin-top: 0px;
    }
    
    .faqsfre1{
        width: 157.6px;
    height: 157.6px;
    border-radius: 15px;
    background: #FFF0E8;
    }
    
    .faqsfres1{
        width: 60.73px;
    height: 79.52px;
    margin-left: 50px;
    margin-top: -190px;
    }
    
    .faqsfresh1{
        width: 85.31px;
    height: 24.58px;
    font-family: 'Plus Jakarta Sans' sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: -15px;
    margin-left: 35px;
    color:#FF5B00;
    }
    
    .faqsfr2-input {
        display: none;
      }
      
      .faqsfr2-label {
        cursor: pointer;
      }
      
      .faqsfr2-input:checked ~ .faqsfre2-label .faqsfre2 {
        border: 2px solid black;
      }
      
      .faqsfr2-input:checked + .faqsfre2-label .faqsfresh2 {
        color: black;
    }
    
    .faqsfr2{
        margin-top: 0px;
        margin-left: -18%;
    }
    
    .faqsfre2{
        width: 157.6px;
    height: 157.6px;
    border-radius: 15px;
    background: #FFFAEC;
    }
    
    .faqsfres2{
        width: 57.83px;
    height: 79.52px;
    margin-left: 50px;
    margin-top: -190px;
    }
    
    .faqsfresh2{
        width: 125.79px;
    height: 24.58px;
    font-family: 'Plus Jakarta Sans' sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: -15px;
    margin-left: 15px;
    color: #CCA126;
    }
    
    .faqsfr3-input {
        display: none;
      }
      
      .faqsfr3-label {
        cursor: pointer;
      }
      
      .faqsfr3-input:checked ~ .faqsfre3-label .faqsfre3 {
        border: 2px solid black;
      }
      
      .faqsfr3-input:checked + .faqsfre3-label .faqsfresh3 {
        color: black;
    }
    
    .faqsfr3{
        margin-top: 0px;
        margin-left: -18%;
    }
    
    .faqsfre3{
        width: 157.6px;
    height: 157.6px;
    border-radius: 15px;
    background: #FFEEF9;
    }
    
    .faqsfres3{
        width: 46.27px;
    height: 78.08px;
    margin-left: 50px;
    margin-top: -190px;
    }
    
    .faqsfresh3{
        width: 88.2px;
    height: 24.58px;
    font-family: 'Plus Jakarta Sans' sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: -15px;
    margin-left: 30px;
    color: #891A61;
    }
    
    .faqsfr4-input {
        display: none;
      }
      
      .faqsfr4-label {
        cursor: pointer;
      }
      
      .faqsfr4-input:checked ~ .faqsfre4-label .faqsfre4 {
        border: 2px solid black;
      }
      
      .faqsfr4-input:checked + .faqsfre4-label .faqsfresh4 {
        color: black;
    }
    
    .faqsfr4{
        margin-top: -16%;
        margin-left: 58%;
    }
    
    .faqsfre4{
        width: 157.6px;
    height: 157.6px;
    border-radius: 15px;
    background: #FFE8ED;
    }
    
    .faqsfres4{
        width: 56.39px;
    height: 78.08px;
    margin-left: 50px;
    margin-top: -190px;
    }
    
    .faqsfresh4{
        width: 163.38px;
    height: 24.58px;
    font-family: 'Plus Jakarta Sans' sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: -15px;
    margin-left: 0px;
    color: #CC0530;
    }
    
    .faqsfr5-input {
        display: none;
      }
      
      .faqsfr5-label {
        cursor: pointer;
      }
      
      .faqsfr5-input:checked ~ .faqsfre5-label .faqsfre5 {
        border: 2px solid black;
      }
      
      .faqsfr5-input:checked + .faqsfre5-label .faqsfresh5 {
        color: black;
    }
    
    .faqsfr5{
        margin-top: -16%;
        margin-left: 75%;
    }
    
    .faqsfre5{
        width: 157.6px;
    height: 157.6px;
    border-radius: 15px;
    background: #E2ECFF;
    }
    
    .faqsfres5{
        width: 56.39px;
    height: 86.75px;
    margin-left: 50px;
    margin-top: -190px;
    }
    
    .faqsfresh5{
        width: 104.1px;
    height: 24.58px;
    font-family: 'Plus Jakarta Sans' sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: -15px;
    margin-left: 28px;
    color: #073CA5;
    }
    
    /* .ant-space {
        width: 100%;
      } */
    
    .custom-collapse {
      width: 1114px;
      height: 96px;
      margin-left: 100px;
      margin-top: 20px;
      margin-bottom: 500px;
      position: relative;
    }
    
    /* :where(.css-dev-only-do-not-override-1drr2mu).ant-collapse>.ant-collapse-item >.ant-collapse-header {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        padding: 12px 16px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;    
        cursor: pointer;
        transition: all 0.3s, visibility 0s;
    } */

    /* :where(.css-dev-only-do-not-override-1b0bdye).ant-collapse>.ant-collapse-item >.ant-collapse-header {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        padding: 12px 16px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;    
        cursor: pointer;
        transition: all 0.3s, visibility 0s;
    } */

    .custom-collapse-header{
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        padding: 12px 16px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0em;
        background-color: none;
        text-align: left;    
        cursor: pointer;
        transition: all 0.3s, visibility 0s;
    }
    
    /* :where(.css-dev-only-do-not-override-1b0bdye).ant-collapse {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: #FFFFFF;
        border: 2px solid #E2E2E2;
        border-radius: 10px;
    } */

    .collapsefullcardfaq{
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: #FFFFFF;
        border: 2px solid #E2E2E2;
        border-radius: 10px;
    }
    
    /* :where(.css-dev-only-do-not-override-1b0bdye).ant-collapse .ant-collapse-content {
        color: rgba(0, 0, 0, 0.88);
        background-color: #ffffff;
        border-top: none;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    } */

    .collapsecontentfaq{
        color: rgba(0, 0, 0, 0.88);
        background-color: #ffffff;
    
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    border-bottom: none;
    border-top: none;
    }

    :where(.css-dev-only-do-not-override-1b0bdye).ant-collapse .ant-collapse-content{
        border-top: none;
    }

    .cardarrowfaq{
        margin-top: 205%;
    }
    
    /* :where(.css-dev-only-do-not-override-1drr2mu).ant-collapse>.ant-collapse-item:last-child, :where(.css-dev-only-do-not-override-1drr2mu).ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
        border-radius: 0 0 8px 8px;
        border-bottom: none;
    } */

    .blogpartfaq{
        /* position: relative; */
        /* position: relative; */
        margin-top: -18%;
    }
    
    .horizontal-line {
        position: relative;
        width: 98%;
        border: 1px solid #B1B1B1;
        /* margin-left: 60px; */
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
      }
    
    .faqstite1{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 234px;
    height: 64px;
    border-radius: 32px;
    background: #E8EDFC;
    align-items: left;
    margin-left: 15%;
    
    }
    
    .faqsparas1{
        width: 761px;
        height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: -15%;
    margin-top: 0%;
    }
    
    .faqsingblog{
        width: 536px;
    height: 371px;
    border-radius: 25;   
    }
    
    .faqlcblog{
        width: 69px;
    height: 69px;
    border-radius: 100%;
    background: #FFFFFF;
    margin-top: -130px;
    position: absolute;
    }
    
    .faqsauthblog{
        width: 136px;
    height: 30px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: -110px;
    margin-left: 80px;
    }
    
    .faqslat{
        width: 173px;
    height: 60px;
    border-radius: 0 0 37px 37px;
    background: #252525;
    margin-top: -395px;
    margin-left: 300px;
    position: absolute;
    }
    
    .faqlate{
        width: 110px;
    height: 19px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
    margin-left: -5%;
    margin-top: -2%;
    }
    
    .faqsbuilblog{
        width: 484px;
    height: 90px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: -30%;
    margin-top: 20%;
    }
    
    .faqsbuildblog{
        width: 484px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: -30%;
    margin-top: -2%;
    }
    
    .faqreadmore{
        width: 173px;
        height: 60px;
        border-radius: 37.5px;
        border: none;
        background-color: rgba(7, 60, 165, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        margin-top: 0px;
        margin-left: -140px;
    }
    
    .hr2blogl{
        width: 300px;
    border: 1px solid #000000;
    margin-left: -23px;
    }
    
    .cardbldimensions{
        width: 343px;
        height: 600px;
        border: none;
        margin-left: 33%;
    }
    
    .bltitle{
        width: 343px;
    height: 60px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    color: rgba(17, 17, 17, 1);
    margin-left: -20px;
    }
    
    .bltitlepara{
        width: 343px;
    height: 76px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    margin-left: -20px;
    /* color: rgba(17, 17, 17, 1); */
    }
    
    .bloglc{
        width: 41px;
    height: 41px;
    background: rgba(217, 217, 217, 1);
    border-radius: 100%;
    /* position: relative; */
    margin-top: -5%;
    margin-left: -25px;
    }
    
    .bloglctext{
        width: 105px;
    height: 19px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 20px;
    margin-top: -11%;
    }
    
    .faqstartedbox4{
        margin-top: 50px;
        width: 1207px;
    height: 227px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 100px;
    margin-left: 4%;
    }
    
    .faqsubbutton4{
        margin-left: 950px;
        margin-top: -230px;
        height: 50px;
        width: 175px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }
    
    .faqstarthead4{
        width: 1000px;
    height: 87px;
    margin-left: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    
    }
    
    .faqregis4{
        width: 90px;
    height: 19px;
        margin-top: 12px;
        margin-left: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    
    }
    
    .faqstartpara4{
        width: 613px;
    height: 62px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 85px;
    margin-top: -2%;
    }
    
    .hide-on-desktop {
        display: none;
      }
    
      .faqreadmore2{
        display: none;
      }

      .faqpagemain{
        margin-top: -45px;
      }
    
    }


@media (min-width:1228px) and (max-width:1399px)
{
        .faqpagemain{
            max-width:1300px;
        transform: scale(0.83);
        transform-origin: top;
        margin-bottom: -400px;
        }


        .aboutuscollapsecontainer{
            background-color: white;
            margin-bottom: 20px;
            width: 1114px;
            margin-left: auto;
            margin-right: auto;
        }
    
        /* .ant-collapse-header{
            height: 90px;
        } */
    
        .arrowheadaboutus{
            margin-top: 34px;
        }
    
    
    
        .aboutuscollapseheader{
            width: 788px;
    height: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.8px;
    text-align: left;
    margin-top: 17.5px;
    
        }
    
        .Faqblogcardlap{
            margin-top: 20%;
            margin-left: -5%;
        }
    
    
        .hideinlapfaq{
            display: none;
        }
     
        .faqheaderforphone{
            display: none;
        }
       
        .aboutstite1{
            align-items: center;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        position: relative;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        width: 234px;
        height: 64px;
        border-radius: 32px;
        background: #E8EDFC;
        align-items: left;
        margin-left: 20%;
        margin-top: 10%;
        }
        
        .aboutparas1{
            width: 761px;
            height: 87px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: -11%;
        margin-top: 0%;
        }
        
        .aboutingblog{
            width: 536px;
        height: 371px;
        border-radius: 25;   
        }
        
        .aboutlcblog{
            width: 69px;
        height: 69px;
        border-radius: 100%;
        background: #FFFFFF;
        margin-top: -130px;
        position: absolute;
        }
        
        .aboutauthblog{
            width: 136px;
        height: 30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: -110px;
        margin-left: 80px;
        }
        
        .aboutlat{
            width: 173px;
        height: 60px;
        border-radius: 0 0 37px 37px;
        background: #252525;
        margin-top: -395px;
        margin-left: 300px;
        position: absolute;
        }
        
        .aboutlate{
            width: 110px;
        height: 19px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
        margin-left: -5%;
        margin-top: -2%;
        }
        
        .aboutmaiblog{
            margin-left: 13%;
            margin-top: 14%;
        }
        
        .aboutbuilblog{
            width: 484px;
        height: 90px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 36px;
        font-weight: 500;
        line-height: 45px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: -28%;
        margin-top: 20%;
        }
        
        .aboutbuildblog{
            width: 484px;
        height: 155px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: -28%;
        margin-top: -2%;
        }
        
        .aboutreadmore{
            width: 173px;
            height: 60px;
            border-radius: 37.5px;
            border: none;
            background-color: rgba(7, 60, 165, 1);
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: center;
            color: rgba(255, 255, 255, 1);
            margin-top: 8px;
            margin-left: -140px;
        }
       
       
        .faqscon{
            margin-top: 5%;
            margin-left: 1.5%;
        }
        
        .faqsimg{
            width: 1198px;
        height: 462px;
        position: relative;
        }

        .faqsimgmob{
            display: none;
        }
        
        .faqsconimg{
            width: 1199px;
        height: 462px;
        margin-left: 0%;
        margin-top: 1%;
        background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
        position: absolute;
        }
        
        .faqstext{
            width: 761px;
        height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: center;
        position: relative;
        margin-left: 20%;
        margin-top: -4%;
        }
        
        .faqstext2{
            width: 1030px;
        height: 62px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 0%;
        margin-left: 10%;
        }
        
        .faqssrch{
            width: 1114px;
        height: 67px;
        border-radius: 15px;
        background: #FFFFFF;
        border: 2px solid #E2E2E2;
        margin-left: 7%;
        margin-top: 3%;
        padding-left: 60px;
        }
        
        .faqsic{
            position: relative;
            width: 18.75px;
        height: 18.75px;
        margin-left: -88.5%;
        cursor: pointer; 
        }
        
        .faqssrch::placeholder{
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;    
        color: #0D0D0D;
        }
        
        .faqsord{
            width: 761px;
        height: 50px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 40px;
        font-weight: 400;
        line-height: 50px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: 20%;
        }
        
        .faqsfr1-input {
            display: none;
          }
          
          .faqsfr1-label {
            cursor: pointer;
          }
          
          .faqsfr1-input:checked ~ .faqsfre1-label .faqsfre1 {
            border: 2px solid black;
          }
          
          .faqsfr1-input:checked + .faqsfre1-label .faqsfresh1 {
            color: black;
        }
        
        .faqsfr1{
            margin-left: 12%;
            margin-top: 0px;
        }
        
        .faqsfre1{
            width: 157.6px;
        height: 157.6px;
        border-radius: 15px;
        background: #FFF0E8;
        }
        
        .faqsfres1{
            width: 60.73px;
        height: 79.52px;
        margin-left: 50px;
        margin-top: -190px;
        }
        
        .faqsfresh1{
            width: 85.31px;
        height: 24.58px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 35px;
        color:#FF5B00;
        }
        
        .faqsfr2-input {
            display: none;
          }
          
          .faqsfr2-label {
            cursor: pointer;
          }
          
          .faqsfr2-input:checked ~ .faqsfre2-label .faqsfre2 {
            border: 2px solid black;
          }
          
          .faqsfr2-input:checked + .faqsfre2-label .faqsfresh2 {
            color: black;
        }
        
        .faqsfr2{
            margin-top: 0px;
            margin-left: -18%;
        }
        
        .faqsfre2{
            width: 157.6px;
        height: 157.6px;
        border-radius: 15px;
        background: #FFFAEC;
        }
        
        .faqsfres2{
            width: 57.83px;
        height: 79.52px;
        margin-left: 50px;
        margin-top: -190px;
        }
        
        .faqsfresh2{
            width: 125.79px;
        height: 24.58px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 15px;
        color: #CCA126;
        }
        
        .faqsfr3-input {
            display: none;
          }
          
          .faqsfr3-label {
            cursor: pointer;
          }
          
          .faqsfr3-input:checked ~ .faqsfre3-label .faqsfre3 {
            border: 2px solid black;
          }
          
          .faqsfr3-input:checked + .faqsfre3-label .faqsfresh3 {
            color: black;
        }
        
        .faqsfr3{
            margin-top: 0px;
            margin-left: -18%;
        }
        
        .faqsfre3{
            width: 157.6px;
        height: 157.6px;
        border-radius: 15px;
        background: #FFEEF9;
        }
        
        .faqsfres3{
            width: 46.27px;
        height: 78.08px;
        margin-left: 50px;
        margin-top: -190px;
        }
        
        .faqsfresh3{
            width: 88.2px;
        height: 24.58px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 30px;
        color: #891A61;
        }
        
        .faqsfr4-input {
            display: none;
          }
          
          .faqsfr4-label {
            cursor: pointer;
          }
          
          .faqsfr4-input:checked ~ .faqsfre4-label .faqsfre4 {
            border: 2px solid black;
          }
          
          .faqsfr4-input:checked + .faqsfre4-label .faqsfresh4 {
            color: black;
        }
        
        .faqsfr4{
            margin-top: -16%;
            margin-left: 58%;
        }
        
        .faqsfre4{
            width: 157.6px;
        height: 157.6px;
        border-radius: 15px;
        background: #FFE8ED;
        }
        
        .faqsfres4{
            width: 56.39px;
        height: 78.08px;
        margin-left: 50px;
        margin-top: -190px;
        }
        
        .faqsfresh4{
            width: 163.38px;
        height: 24.58px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 0px;
        color: #CC0530;
        }
        
        .faqsfr5-input {
            display: none;
          }
          
          .faqsfr5-label {
            cursor: pointer;
          }
          
          .faqsfr5-input:checked ~ .faqsfre5-label .faqsfre5 {
            border: 2px solid black;
          }
          
          .faqsfr5-input:checked + .faqsfre5-label .faqsfresh5 {
            color: black;
        }
        
        .faqsfr5{
            margin-top: -16%;
            margin-left: 75%;
        }
        
        .faqsfre5{
            width: 157.6px;
        height: 157.6px;
        border-radius: 15px;
        background: #E2ECFF;
        }
        
        .faqsfres5{
            width: 56.39px;
        height: 86.75px;
        margin-left: 50px;
        margin-top: -190px;
        }
        
        .faqsfresh5{
            width: 104.1px;
        height: 24.58px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 28px;
        color: #073CA5;
        }
        
        /* .ant-space {
            width: 100%;
          } */
        
        .custom-collapse {
          width: 1114px;
          height: 96px;
          margin-left: 100px;
          margin-top: 20px;
          margin-bottom: 500px;
          position: relative;
        }
        
        /* :where(.css-dev-only-do-not-override-1drr2mu).ant-collapse>.ant-collapse-item >.ant-collapse-header {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            padding: 12px 16px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;    
            cursor: pointer;
            transition: all 0.3s, visibility 0s;
        } */
    
        /* :where(.css-dev-only-do-not-override-1b0bdye).ant-collapse>.ant-collapse-item >.ant-collapse-header {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            padding: 12px 16px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;    
            cursor: pointer;
            transition: all 0.3s, visibility 0s;
        } */
    
        .custom-collapse-header{
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            padding: 12px 16px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0em;
            background-color: none;
            text-align: left;    
            cursor: pointer;
            transition: all 0.3s, visibility 0s;
        }
        
        /* :where(.css-dev-only-do-not-override-1b0bdye).ant-collapse {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            list-style: none;
            background-color: #FFFFFF;
            border: 2px solid #E2E2E2;
            border-radius: 10px;
        } */
    
        .collapsefullcardfaq{
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            list-style: none;
            background-color: #FFFFFF;
            border: 2px solid #E2E2E2;
            border-radius: 10px;
        }
        
        /* :where(.css-dev-only-do-not-override-1b0bdye).ant-collapse .ant-collapse-content {
            color: rgba(0, 0, 0, 0.88);
            background-color: #ffffff;
            border-top: none;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        } */
    
        .collapsecontentfaq{
            color: rgba(0, 0, 0, 0.88);
            background-color: #ffffff;
        
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        border-bottom: none;
        border-top: none;
        }
    
        :where(.css-dev-only-do-not-override-1b0bdye).ant-collapse .ant-collapse-content{
            border-top: none;
        }
    
        .cardarrowfaq{
            margin-top: 205%;
        }
        
        /* :where(.css-dev-only-do-not-override-1drr2mu).ant-collapse>.ant-collapse-item:last-child, :where(.css-dev-only-do-not-override-1drr2mu).ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
            border-radius: 0 0 8px 8px;
            border-bottom: none;
        } */
    
        .blogpartfaq{
            /* position: relative; */
            /* position: relative; */
            margin-top: -18%;
        }
        
        .horizontal-line {
            position: relative;
            width: 98%;
            border: 1px solid #B1B1B1;
            /* margin-left: 60px; */
            margin-left: auto;
            margin-right: auto;
            margin-top: 50px;
          }
        
        .faqstite1{
            align-items: center;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        position: relative;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        width: 234px;
        height: 64px;
        border-radius: 32px;
        background: #E8EDFC;
        align-items: left;
        margin-left: 15%;
        
        }
        
        .faqsparas1{
            width: 761px;
            height: 87px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: -15%;
        margin-top: 0%;
        }
        
        .faqsingblog{
            width: 536px;
        height: 371px;
        border-radius: 25;   
        }
        
        .faqlcblog{
            width: 69px;
        height: 69px;
        border-radius: 100%;
        background: #FFFFFF;
        margin-top: -130px;
        position: absolute;
        }
        
        .faqsauthblog{
            width: 136px;
        height: 30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: -110px;
        margin-left: 80px;
        }
        
        .faqslat{
            width: 173px;
        height: 60px;
        border-radius: 0 0 37px 37px;
        background: #252525;
        margin-top: -395px;
        margin-left: 300px;
        position: absolute;
        }
        
        .faqlate{
            width: 110px;
        height: 19px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
        margin-left: -5%;
        margin-top: -2%;
        }
        
        .faqsbuilblog{
            width: 484px;
        height: 90px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 36px;
        font-weight: 500;
        line-height: 45px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: -30%;
        margin-top: 20%;
        }
        
        .faqsbuildblog{
            width: 484px;
        height: 155px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: -30%;
        margin-top: -2%;
        }
        
        .faqreadmore{
            width: 173px;
            height: 60px;
            border-radius: 37.5px;
            border: none;
            background-color: rgba(7, 60, 165, 1);
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: center;
            color: rgba(255, 255, 255, 1);
            margin-top: 0px;
            margin-left: -140px;
        }
        
        .hr2blogl{
            width: 300px;
        border: 1px solid #000000;
        margin-left: -23px;
        }
        
        .cardbldimensions{
            width: 343px;
            height: 600px;
            border: none;
            margin-left: 33%;
        }
        
        .bltitle{
            width: 343px;
        height: 60px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        position: relative;
        color: rgba(17, 17, 17, 1);
        margin-left: -20px;
        }
        
        .bltitlepara{
            width: 343px;
        height: 76px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        position: relative;
        margin-left: -20px;
        /* color: rgba(17, 17, 17, 1); */
        }
        
        .bloglc{
            width: 41px;
        height: 41px;
        background: rgba(217, 217, 217, 1);
        border-radius: 100%;
        /* position: relative; */
        margin-top: -5%;
        margin-left: -25px;
        }
        
        .bloglctext{
            width: 105px;
        height: 19px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 15px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 20px;
        margin-top: -11%;
        }
        
        .faqstartedbox4{
            margin-top: 50px;
            width: 1207px;
        height: 227px;
        position: relative;
        background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
        border-radius: 25px;
        margin-bottom: 550px;
        margin-left: 4%;
        }
        
        .faqsubbutton4{
            position: absolute;
            margin-left: 950px;
            margin-top: -54%;
            height: 50px;
            width: 175px;
            border-radius:30px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        background: rgb(255, 255, 255, 1);
        color: rgba(7, 60, 165, 1);
        border: solid rgba(255, 255, 255, 1);
        }
        
        .faqstarthead4{
            width: 1000px;
        height: 87px;
        margin-left: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 50px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        justify-content: center;
        align-items: center;
        margin-top: 3%;
        
        }
        
        .faqregis4{
            width: 90px;
        height: 19px;
            margin-top: 12px;
            margin-left: 40px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        
        }
        
        .faqstartpara4{
            width: 613px;
        height: 62px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        margin-left: 85px;
        margin-top: -52%;
        position: absolute;
        }
        
        .hide-on-desktop {
            display: none;
          }
        
          .faqreadmore2{
            display: none;
          }
        
}

@media (min-width:1027px) and (max-width:1227px)
{
        .faqpagemain{
            max-width:1300px;
        transform: scale(0.83);
        transform-origin: top;
        margin-bottom: -470px;
        }


        .aboutuscollapsecontainer{
            background-color: white;
            margin-bottom: 20px;
            width: 1114px;
            margin-left: auto;
            margin-right: auto;
        }
    
        /* .ant-collapse-header{
            height: 90px;
        } */
    
        .arrowheadaboutus{
            margin-top: 34px;
        }
    
    
    
        .aboutuscollapseheader{
            width: 788px;
    height: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.8px;
    text-align: left;
    margin-top: 17.5px;
    
        }
    
        .Faqblogcardlap{
            margin-top: 20%;
            margin-left: -5%;
        }
    
    
        .hideinlapfaq{
            display: none;
        }
     
        .faqheaderforphone{
            display: none;
        }
       
        .aboutstite1{
            align-items: center;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        position: relative;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        width: 234px;
        height: 64px;
        border-radius: 32px;
        background: #E8EDFC;
        align-items: left;
        margin-left: 20%;
        margin-top: 10%;
        }
        
        .aboutparas1{
            width: 761px;
            height: 87px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: -11%;
        margin-top: 0%;
        }
        
        .aboutingblog{
            width: 536px;
        height: 371px;
        border-radius: 25;   
        }
        
        .aboutlcblog{
            width: 69px;
        height: 69px;
        border-radius: 100%;
        background: #FFFFFF;
        margin-top: -130px;
        position: absolute;
        }
        
        .aboutauthblog{
            width: 136px;
        height: 30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: -110px;
        margin-left: 80px;
        }
        
        .aboutlat{
            width: 173px;
        height: 60px;
        border-radius: 0 0 37px 37px;
        background: #252525;
        margin-top: -395px;
        margin-left: 300px;
        position: absolute;
        }
        
        .aboutlate{
            width: 110px;
        height: 19px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
        margin-left: -5%;
        margin-top: -2%;
        }
        
        .aboutmaiblog{
            margin-left: 13%;
            margin-top: 14%;
        }
        
        .aboutbuilblog{
            width: 484px;
        height: 90px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 36px;
        font-weight: 500;
        line-height: 45px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: -28%;
        margin-top: 20%;
        }
        
        .aboutbuildblog{
            width: 484px;
        height: 155px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: -28%;
        margin-top: -2%;
        }
        
        .aboutreadmore{
            width: 173px;
            height: 60px;
            border-radius: 37.5px;
            border: none;
            background-color: rgba(7, 60, 165, 1);
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: center;
            color: rgba(255, 255, 255, 1);
            margin-top: 8px;
            margin-left: -140px;
        }
       
       
        .faqscon{
            margin-top: 5%;
            margin-left: 1.5%;
        }
        
        .faqsimg{
            width: 1198px;
        height: 462px;
        position: relative;
        }

        .faqsimgmob{
            display: none;
        }
        
        .faqsconimg{
            width: 1199px;
        height: 462px;
        margin-left: 0%;
        margin-top: 1%;
        background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
        position: absolute;
        }
        
        .faqstext{
            width: 761px;
        height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: center;
        position: relative;
        margin-left: 20%;
        margin-top: -4%;
        }
        
        .faqstext2{
            width: 1030px;
        height: 62px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 0%;
        margin-left: 10%;
        }
        
        .faqssrch{
            width: 1114px;
        height: 67px;
        border-radius: 15px;
        background: #FFFFFF;
        border: 2px solid #E2E2E2;
        margin-left: 7%;
        margin-top: 3%;
        padding-left: 60px;
        }
        
        .faqsic{
            position: relative;
            width: 18.75px;
        height: 18.75px;
        margin-left: -88.5%;
        cursor: pointer; 
        }
        
        .faqssrch::placeholder{
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;    
        color: #0D0D0D;
        }
        
        .faqsord{
            width: 761px;
        height: 50px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 40px;
        font-weight: 400;
        line-height: 50px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: 20%;
        }
        
        .faqsfr1-input {
            display: none;
          }
          
          .faqsfr1-label {
            cursor: pointer;
          }
          
          .faqsfr1-input:checked ~ .faqsfre1-label .faqsfre1 {
            border: 2px solid black;
          }
          
          .faqsfr1-input:checked + .faqsfre1-label .faqsfresh1 {
            color: black;
        }
        
        .faqsfr1{
            margin-left: 12%;
            margin-top: 0px;
        }
        
        .faqsfre1{
            width: 157.6px;
        height: 157.6px;
        border-radius: 15px;
        background: #FFF0E8;
        }
        
        .faqsfres1{
            width: 60.73px;
        height: 79.52px;
        margin-left: 50px;
        margin-top: -190px;
        }
        
        .faqsfresh1{
            width: 85.31px;
        height: 24.58px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 35px;
        color:#FF5B00;
        }
        
        .faqsfr2-input {
            display: none;
          }
          
          .faqsfr2-label {
            cursor: pointer;
          }
          
          .faqsfr2-input:checked ~ .faqsfre2-label .faqsfre2 {
            border: 2px solid black;
          }
          
          .faqsfr2-input:checked + .faqsfre2-label .faqsfresh2 {
            color: black;
        }
        
        .faqsfr2{
            margin-top: 0px;
            margin-left: -18%;
        }
        
        .faqsfre2{
            width: 157.6px;
        height: 157.6px;
        border-radius: 15px;
        background: #FFFAEC;
        }
        
        .faqsfres2{
            width: 57.83px;
        height: 79.52px;
        margin-left: 50px;
        margin-top: -190px;
        }
        
        .faqsfresh2{
            width: 125.79px;
        height: 24.58px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 15px;
        color: #CCA126;
        }
        
        .faqsfr3-input {
            display: none;
          }
          
          .faqsfr3-label {
            cursor: pointer;
          }
          
          .faqsfr3-input:checked ~ .faqsfre3-label .faqsfre3 {
            border: 2px solid black;
          }
          
          .faqsfr3-input:checked + .faqsfre3-label .faqsfresh3 {
            color: black;
        }
        
        .faqsfr3{
            margin-top: 0px;
            margin-left: -18%;
        }
        
        .faqsfre3{
            width: 157.6px;
        height: 157.6px;
        border-radius: 15px;
        background: #FFEEF9;
        }
        
        .faqsfres3{
            width: 46.27px;
        height: 78.08px;
        margin-left: 50px;
        margin-top: -190px;
        }
        
        .faqsfresh3{
            width: 88.2px;
        height: 24.58px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 30px;
        color: #891A61;
        }
        
        .faqsfr4-input {
            display: none;
          }
          
          .faqsfr4-label {
            cursor: pointer;
          }
          
          .faqsfr4-input:checked ~ .faqsfre4-label .faqsfre4 {
            border: 2px solid black;
          }
          
          .faqsfr4-input:checked + .faqsfre4-label .faqsfresh4 {
            color: black;
        }
        
        .faqsfr4{
            margin-top: -18.5%;
            margin-left: 58%;
        }
        
        .faqsfre4{
            width: 157.6px;
        height: 157.6px;
        border-radius: 15px;
        background: #FFE8ED;
        }
        
        .faqsfres4{
            width: 56.39px;
        height: 78.08px;
        margin-left: 50px;
        margin-top: -190px;
        }
        
        .faqsfresh4{
            width: 163.38px;
        height: 24.58px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 0px;
        color: #CC0530;
        }
        
        .faqsfr5-input {
            display: none;
          }
          
          .faqsfr5-label {
            cursor: pointer;
          }
          
          .faqsfr5-input:checked ~ .faqsfre5-label .faqsfre5 {
            border: 2px solid black;
          }
          
          .faqsfr5-input:checked + .faqsfre5-label .faqsfresh5 {
            color: black;
        }
        
        .faqsfr5{
            margin-top: -19%;
            margin-left: 73%;
        }
        
        .faqsfre5{
            width: 157.6px;
        height: 157.6px;
        border-radius: 15px;
        background: #E2ECFF;
        }
        
        .faqsfres5{
            width: 56.39px;
        height: 86.75px;
        margin-left: 50px;
        margin-top: -190px;
        }
        
        .faqsfresh5{
            width: 104.1px;
        height: 24.58px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 28px;
        color: #073CA5;
        }
        
        /* .ant-space {
            width: 100%;
          } */
        
        .custom-collapse {
          width: 1114px;
          height: 96px;
          margin-left: 100px;
          margin-top: 20px;
          margin-bottom: 500px;
          position: relative;
        }
        
        /* :where(.css-dev-only-do-not-override-1drr2mu).ant-collapse>.ant-collapse-item >.ant-collapse-header {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            padding: 12px 16px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;    
            cursor: pointer;
            transition: all 0.3s, visibility 0s;
        } */
    
        /* :where(.css-dev-only-do-not-override-1b0bdye).ant-collapse>.ant-collapse-item >.ant-collapse-header {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            padding: 12px 16px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;    
            cursor: pointer;
            transition: all 0.3s, visibility 0s;
        } */
    
        .custom-collapse-header{
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            padding: 12px 16px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0em;
            background-color: none;
            text-align: left;    
            cursor: pointer;
            transition: all 0.3s, visibility 0s;
        }
        
        /* :where(.css-dev-only-do-not-override-1b0bdye).ant-collapse {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            list-style: none;
            background-color: #FFFFFF;
            border: 2px solid #E2E2E2;
            border-radius: 10px;
        } */
    
        .collapsefullcardfaq{
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            list-style: none;
            background-color: #FFFFFF;
            border: 2px solid #E2E2E2;
            border-radius: 10px;
        }
        
        /* :where(.css-dev-only-do-not-override-1b0bdye).ant-collapse .ant-collapse-content {
            color: rgba(0, 0, 0, 0.88);
            background-color: #ffffff;
            border-top: none;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        } */
    
        .collapsecontentfaq{
            color: rgba(0, 0, 0, 0.88);
            background-color: #ffffff;
        
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        border-bottom: none;
        border-top: none;
        }
    
        :where(.css-dev-only-do-not-override-1b0bdye).ant-collapse .ant-collapse-content{
            border-top: none;
        }
    
        .cardarrowfaq{
            margin-top: 205%;
        }
        
        /* :where(.css-dev-only-do-not-override-1drr2mu).ant-collapse>.ant-collapse-item:last-child, :where(.css-dev-only-do-not-override-1drr2mu).ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
            border-radius: 0 0 8px 8px;
            border-bottom: none;
        } */
    
        .blogpartfaq{
            /* position: relative; */
            /* position: relative; */
            margin-top: -18%;
        }
        
        .horizontal-line {
            position: relative;
            width: 98%;
            border: 1px solid #B1B1B1;
            /* margin-left: 60px; */
            margin-left: auto;
            margin-right: auto;
            margin-top: 50px;
          }
        
        .faqstite1{
            align-items: center;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        position: relative;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        width: 234px;
        height: 64px;
        border-radius: 32px;
        background: #E8EDFC;
        align-items: left;
        margin-left: 15%;
        
        }
        
        .faqsparas1{
            width: 761px;
            height: 87px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: -15%;
        margin-top: 0%;
        }
        
        .faqsingblog{
            width: 536px;
        height: 371px;
        border-radius: 25;   
        }
        
        .faqlcblog{
            width: 69px;
        height: 69px;
        border-radius: 100%;
        background: #FFFFFF;
        margin-top: -130px;
        position: absolute;
        }
        
        .faqsauthblog{
            width: 136px;
        height: 30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: -110px;
        margin-left: 80px;
        }
        
        .faqslat{
            width: 173px;
        height: 60px;
        border-radius: 0 0 37px 37px;
        background: #252525;
        margin-top: -395px;
        margin-left: 300px;
        position: absolute;
        }
        
        .faqlate{
            width: 110px;
        height: 19px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
        margin-left: -5%;
        margin-top: -2%;
        }
        
        .faqsbuilblog{
            width: 484px;
        height: 90px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 36px;
        font-weight: 500;
        line-height: 45px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: -30%;
        margin-top: 20%;
        }
        
        .faqsbuildblog{
            width: 484px;
        height: 155px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: -30%;
        margin-top: -2%;
        }
        
        .faqreadmore{
            width: 173px;
            height: 60px;
            border-radius: 37.5px;
            border: none;
            background-color: rgba(7, 60, 165, 1);
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: center;
            color: rgba(255, 255, 255, 1);
            margin-top: 0px;
            margin-left: -140px;
        }
        
        .hr2blogl{
            width: 300px;
        border: 1px solid #000000;
        margin-left: -23px;
        }
        
        .cardbldimensions{
            width: 343px;
            height: 600px;
            border: none;
            margin-left: 33%;
        }
        
        .bltitle{
            width: 343px;
        height: 60px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        position: relative;
        color: rgba(17, 17, 17, 1);
        margin-left: -20px;
        }
        
        .bltitlepara{
            width: 343px;
        height: 76px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        position: relative;
        margin-left: -20px;
        /* color: rgba(17, 17, 17, 1); */
        }
        
        .bloglc{
            width: 41px;
        height: 41px;
        background: rgba(217, 217, 217, 1);
        border-radius: 100%;
        /* position: relative; */
        margin-top: -5%;
        margin-left: -25px;
        }
        
        .bloglctext{
            width: 105px;
        height: 19px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 15px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 20px;
        margin-top: -11%;
        }
        
        .faqstartedbox4{
            margin-top: 50px;
            width: 1207px;
        height: 227px;
        position: relative;
        background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
        border-radius: 25px;
        margin-bottom: 550px;
        margin-left: 4%;
        }
        
        .faqsubbutton4{
            position: absolute;
            margin-left: 950px;
            margin-top: -54%;
            height: 50px;
            width: 175px;
            border-radius:30px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        background: rgb(255, 255, 255, 1);
        color: rgba(7, 60, 165, 1);
        border: solid rgba(255, 255, 255, 1);
        }
        
        .faqstarthead4{
            width: 1000px;
        height: 87px;
        margin-left: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 50px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        justify-content: center;
        align-items: center;
        margin-top: 3%;
        
        }
        
        .faqregis4{
            width: 90px;
        height: 19px;
            margin-top: 12px;
            margin-left: 40px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        
        }
        
        .faqstartpara4{
            width: 613px;
        height: 62px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        margin-left: 85px;
        margin-top: -52%;
        position: absolute;
        }
        
        .hide-on-desktop {
            display: none;
          }
        
          .faqreadmore2{
            display: none;
          }
        
}
    
@media (min-width:831px) and (max-width:1026px) 
{

    .faqpagemain{
        margin-top: -10px;
    }

    .aboutuscollapseheader{
        width: 780px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 600;
line-height: 24.8px;
text-align: left;


    }

    .aboutuscollapsecontainer{
        background-color: white;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        width: 861px;
    }

        .faqhr2blogl{
            display: none;
        }
        .faqstartedbox4{
            width: 737px;
height: 252px;
border-radius: 25px;
background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
margin-left: 13%;
margin-bottom: 3%;
margin-top: 8%;
        }

        .faqstarthead4{
            width: 505px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 32px;
font-weight: 400;
line-height: 39.68px;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-left: 16%;
margin-top: 4%;
        }

        .faqstartpara4{
            width: 612.49px;
height: 50px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-top: -18%;
margin-left: 20%;
margin-bottom: 12%;

        }

        .faqsubbutton4{
            width: 331px;
height: 61px;
border-radius: 37.5px;
background: rgba(255, 255, 255, 1);
border: none;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 29.76px;
text-align: center;
color: rgba(7, 60, 165, 1);
margin-top: -13%;
margin-left: 38%;        
}

.faqregis4{
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
}

        .hideintabfaq{
            display: none;
        }

        .Faqblogcardlap{
            display: none;
        }

        /* .faqblogheader{
            width: 234px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-top: 55%;
margin-left: 6%;
        } */

        .faqblogheader2{
            width: 761px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: left;
margin-left: 5%;
margin-top: 1%;
        }

        .faqblogheader3{
            width: 761px;
height: 90px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 29.76px;
text-align: left;
margin-left: 6.5%;

        }

        .faqbloglc{
            width: 43px;
    height: 43px;
    background: rgba(217, 217, 217, 1);
    border-radius: 100%;
    margin-top: -54%;
        }

        .faqbloglctext{
            width: 120px;
height: 25px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 700;
line-height: 24.8px;
text-align: left;
margin-left: 8%;
margin-top: -4%;
        }
    
        .faqbltitle{
            width: 438px;
    height: 60px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 29.76px;
    text-align: left;
    color: rgba(17, 17, 17, 1);
    margin-top: -13%;
        }
    
        .faqbltitlepara{
            width: 759px;
    height: 90px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 29.76px;
    text-align: left;
    margin-top: 6%;
    margin-left: -2%;
    
        }
    
        .faqblogheader{
            width: 234px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    margin-top: 8%;
    margin-left: 5%;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: center;
        }

/* jknajca */
        .faqscon{
            margin-top: 5%;
            margin-left: 3.5%;
        }
    
        .faqsimg{
            width: 886px;
        height: 462px;
        position: relative;
        }

        .faqsimgmob{
            display: none;
        }
    
        .faqsconimg{
            width: 886px;
        height: 462px;
        margin-top: 2%;
        background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
        position: absolute;
        }
    
        .faqstext{
            width: 761px;
        height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400px;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: center;
        position: relative;
        margin-left: 8%;
        margin-top: -2%;
        }
    
        .faqstext2{
            width: 832px;
        height: 93px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400px;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: 5%;
        }
    
        .faqssrch{
            width: 886px;
        height: 67px;
        border-radius: 15px;
        background: #FFFFFF;
        border: 2px solid #E2E2E2;
        margin-left: 3%;
        margin-top: 3%;
        padding-left: 60px;
        }
    
        .faqsic{
            position: relative;
            width: 18.75px;
        height: 18.75px;
        margin-left: -90.5%;
        cursor: pointer; 
        }
    
        .faqssrch::placeholder{
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 15px;
        font-weight: 400px;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;    
        color: #0D0D0D;
        }
    
        .faqsord{
            width: 712px;
        height: 47px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 40px;
        font-weight: 400px;
        line-height: 50px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: 11%;
        }
    
        .faqsfr1-input {
            display: none;
          }
          
          .faqsfr1-label {
            cursor: pointer;
          }
          
          .faqsfr1-input:checked ~ .faqsfre1-label .faqsfre1 {
            border: 2px solid black;
          }
          
          .faqsfr1-input:checked + .faqsfre1-label .faqsfresh1 {
            color: black;
        }
        
        .faqsfr1{
            margin-left: 3%;
            margin-top: 0%;
        }
        
        .faqsfre1{
            width: 147.3px;
        height: 147.3px;
        border-radius: 15px;
        background: #FFF0E8;
        }
        
        .faqsfres1{
            width: 56.77px;
        height: 74.34px;
        margin-left: 50px;
        margin-top: -190px;
        }
        
        .faqsfresh1{
            width: 85.31px;
        height: 24.58px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 35px;
        color:#FF5B00;
        }
    
        .faqsfr2-input {
            display: none;
          }
          
          .faqsfr2-label {
            cursor: pointer;
          }
          
          .faqsfr2-input:checked ~ .faqsfre2-label .faqsfre2 {
            border: 2px solid black;
          }
          
          .faqsfr2-input:checked + .faqsfre2-label .faqsfresh2 {
            color: black;
        }
        
        .faqsfr2{
            margin-top: 0px;
            margin-left: -14%;
        }
        
        .faqsfre2{
            width: 147.33px;
        height: 147.33px;
        border-radius: 15px;
        background: #FFFAEC;
        }
        
        .faqsfres2{
            width: 54.07px;
        height: 74.34px;
        margin-left: 50px;
        margin-top: -190px;
        }
        
        .faqsfresh2{
            width: 125.79px;
        height: 24.58px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 15px;
        color: #CCA126;
        }
        
        .faqsfr3-input {
            display: none;
          }
          
          .faqsfr3-label {
            cursor: pointer;
          }
          
          .faqsfr3-input:checked ~ .faqsfre3-label .faqsfre3 {
            border: 2px solid black;
          }
          
          .faqsfr3-input:checked + .faqsfre3-label .faqsfresh3 {
            color: black;
        }
        
        .faqsfr3{
            margin-top: 0px;
            margin-left: -14%;
        }
        
        .faqsfre3{
            width: 147.33px;
        height: 147.33px;
        border-radius: 15px;
        background: #FFEEF9;
        }
        
        .faqsfres3{
            width: 43.25px;
        height: 72.99px;
        margin-left: 50px;
        margin-top: -190px;
        }
        
        .faqsfresh3{
            width: 88.2px;
        height: 24.58px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 30px;
        color: #891A61;
        }
        
        .faqsfr4-input {
            display: none;
          }
          
          .faqsfr4-label {
            cursor: pointer;
          }
          
          .faqsfr4-input:checked ~ .faqsfre4-label .faqsfre4 {
            border: 2px solid black;
          }
          
          .faqsfr4-input:checked + .faqsfre4-label .faqsfresh4 {
            color: black;
        }
        
        .faqsfr4{
            margin-top: -21%;
            margin-left: 61%;
        }
        
        .faqsfre4{
            width: 147.33px;
        height: 147.33px;
        border-radius: 15px;
        background: #FFE8ED;
        }
        
        .faqsfres4{
            width: 52.71px;
        height: 72.99px;
        margin-left: 50px;
        margin-top: -190px;
        }
        
        .faqsfresh4{
            width: 163.38px;
        height: 24.58px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 0px;
        color: #CC0530;
        }
        
        .faqsfr5-input {
            display: none;
          }
          
          .faqsfr5-label {
            cursor: pointer;
          }
          
          .faqsfr5-input:checked ~ .faqsfre5-label .faqsfre5 {
            border: 2px solid black;
          }
          
          .faqsfr5-input:checked + .faqsfre5-label .faqsfresh5 {
            color: black;
        }
        
        .faqsfr5{
            margin-top: -21%;
            margin-left: 80%;
        }
        
        .faqsfre5{
            width: 147.33px;
        height: 147.33px;
        border-radius: 15px;
        background: #E2ECFF;
        }
        
        .faqsfres5{
            width: 52.71px;
        height: 81.1px;
        margin-left: 50px;
        margin-top: -190px;
        }
        
        .faqsfresh5{
            width: 104.1px;
        height: 24.58px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 28px;
        color: #073CA5;
        }
    
        /* .ant-space {
            width: 100%;
          } */
        
        .custom-collapse {
          width: 861px;
          height: 96px;
          margin-left: 40px;
          margin-top: 20px;
          margin-bottom: 500px;
          position: relative;
        }
        
        /* :where(.css-dev-only-do-not-override-1drr2mu).ant-collapse>.ant-collapse-item >.ant-collapse-header {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            padding: 12px 16px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;    
            cursor: pointer;
            transition: all 0.3s, visibility 0s;
        } */

        :where(.css-dev-only-do-not-override-1b0bdye).ant-collapse>.ant-collapse-item >.ant-collapse-header {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            padding: 12px 16px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;    
            cursor: pointer;
            transition: all 0.3s, visibility 0s;
        }
        
        :where(.css-dev-only-do-not-override-1drr2mu).ant-collapse {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            list-style: none;
            background-color: #FFFFFF;
            border: 2px solid #E2E2E2;
            border-radius: 10px;
        }
        
        :where(.css-dev-only-do-not-override-1drr2mu).ant-collapse .ant-collapse-content {
            color: rgba(0, 0, 0, 0.88);
            background-color: #ffffff;
            border-top: none;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        }
        
        :where(.css-dev-only-do-not-override-1drr2mu).ant-collapse>.ant-collapse-item:last-child, :where(.css-dev-only-do-not-override-1drr2mu).ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
            border-radius: 0 0 8px 8px;
            border-bottom: none;
        }
    
        .horizontal-line {
            width: 868px;
            border: 1px solid #B1B1B1;
            margin-top: 8%;
            margin-left: 3%;
        }
    
        .hidemobpad {
            display: none !important;
          }
    
          .faqstite1{
            align-items: center;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        position: relative;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        width: 234px;
        height: 64px;
        border-radius: 32px;
        background: #E8EDFC;
        align-items: left;
        margin-left: 5%;
        margin-top: -2%;
        
        }
        
        .faqsparas1{
            display: none !important;
        }
    
        .faqsparast1{
            width: 761px;
            height: 87px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400px;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: -19%;
        margin-top: 0%;
        }
    
        .faqsparahe2{
            width: 761px;
    height: 90px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    margin-left: 5%;
        }
        
        .faqsingblog{
            width: 761px;
        height: 229px;
        border-radius: 25px;   
        margin-top: 0%;
        }
        
        .faqlcblog{
            width: 43px;
        height: 43px;
        border-radius: 100%;
        background: #D9D9D9;
        margin-top: -310px;
        margin-left: -20px;
        position: absolute;
        }
        
        .faqsauthblog{
            width: 113px;
        height: 25px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: -300px;
        margin-left: 30px;
        }
        
        .faqslat{
            width: 124px;
        height: 38px;
        border-radius: 0 0 15px 15px;
        background: #252525;
        margin-top: -252px;
        margin-left: 570px;
        position: absolute;
        }
        
        .faqlate{
            width: 70px;
        height: 19px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
        margin-left: -3%;
        margin-top: -2%;
        }
        
        .faqspablog{
            width: 438px;
    height: 60px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #111111;
    margin-top: 22%;
        }
        
        .faqsparblog{
            width: 759px;
    height: 90px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    margin-top: 8%;
    margin-left: -2%;
        }
    
        .faqsingblog12{
            width: 761px;
        height: 229px;
        border-radius: 25px;   
        margin-top: 0%;
        }
    
        .faqlcblog12{
            width: 43px;
        height: 43px;
        border-radius: 100%;
        background: #D9D9D9;
        margin-top: -310px;
        margin-left: -20px;
        position: absolute;
        }
    
        .faqsauthblog12{
            width: 113px;
        height: 25px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: -300px;
        margin-left: 30px;
        }
    
        .faqspablog12{
            width: 438px;
    height: 60px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #111111;
    margin-top: 57%;
        }
    
        .faqsingblog13{
            width: 761px;
        height: 229px;
        border-radius: 25px;   
        margin-top: 0%;
        }
    
        .faqlcblog13{
            width: 43px;
        height: 43px;
        border-radius: 100%;
        background: #D9D9D9;
        margin-top: -310px;
        margin-left: -20px;
        position: absolute;
        }
    
        .faqsauthblog13{
            width: 113px;
        height: 25px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: -300px;
        margin-left: 30px;
        }
    
        .faqspablog13{
            width: 438px;
    height: 60px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #111111;
    margin-top: 57%;
        }
    
        .faqreadmore2{
            width: 173px;
            height: 60px;
            border-radius: 37.5px;
            border: none;
            background-color: rgba(7, 60, 165, 1);
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: center;
            color: rgba(255, 255, 255, 1);
            margin-top: 30px;
            margin-left: 30px;
            margin-bottom: 80px;
        }
    
    
}

@media (min-width:577px) and (max-width:830px){

    .faqpagemain{
        margin-top: -10px;
    }

    .aboutuscollapseheader{
        width: 90%;
height: 50px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 600;
line-height: 24.8px;
text-align: left;


    }

    .aboutuscollapsecontainer{
        background-color: white;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }

        .faqhr2blogl{
            display: none;
        }
        .faqstartedbox4{
            width: 737px;
height: 252px;
border-radius: 25px;
background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
margin-left: 13%;
margin-bottom: 3%;
margin-top: 8%;
        }

        .faqstarthead4{
            width: 505px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 32px;
font-weight: 400;
line-height: 39.68px;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-left: 16%;
margin-top: 4%;
        }

        .faqstartpara4{
            width: 612.49px;
height: 50px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-top: -18%;
margin-left: 20%;
margin-bottom: 12%;

        }

        .faqsubbutton4{
            width: 331px;
height: 61px;
border-radius: 37.5px;
background: rgba(255, 255, 255, 1);
border: none;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 29.76px;
text-align: center;
color: rgba(7, 60, 165, 1);
margin-top: -13%;
margin-left: 38%;        
}

.faqregis4{
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
}

        .hideintabfaq{
            display: none;
        }

        .Faqblogcardlap{
            display: none;
        }

        /* .faqblogheader{
            width: 234px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-top: 55%;
margin-left: 6%;
        } */

        .faqblogheader2{
            width: 761px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: left;
margin-left: 5%;
margin-top: 1%;
        }

        .faqblogheader3{
            width: 761px;
height: 90px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 29.76px;
text-align: left;
margin-left: 6.5%;

        }

        .faqbloglc{
            width: 43px;
    height: 43px;
    background: rgba(217, 217, 217, 1);
    border-radius: 100%;
    margin-top: -54%;
        }

        .faqbloglctext{
            width: 120px;
height: 25px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 700;
line-height: 24.8px;
text-align: left;
margin-left: 8%;
margin-top: -4%;
        }
    
        .faqbltitle{
            width: 438px;
    height: 60px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 29.76px;
    text-align: left;
    color: rgba(17, 17, 17, 1);
    margin-top: -13%;
        }
    
        .faqbltitlepara{
            width: 759px;
    height: 90px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 29.76px;
    text-align: left;
    margin-top: 6%;
    margin-left: -2%;
    
        }
    
        .faqblogheader{
            width: 234px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    margin-top: 8%;
    margin-left: 5%;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: center;
        }

/* jknajca */
        .faqscon{
            margin-top: 5%;
            margin-left: 3.5%;
        }
    
        .faqsimg{
            width: 100%;
        height: 430px;
        position: relative;
        }

        .faqsimgmob{
            display: none;
        }
    
        .faqsconimg{
            width: 886px;
        height: 462px;
        margin-top: 2%;
        background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
        position: absolute;
        }
    
        .faqstext{
            width: 761px;
        height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400px;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: center;
        position: relative;
        margin-left: 8%;
        margin-top: -2%;
        }
    
        .faqstext2{
            width: 832px;
        height: 93px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400px;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: 5%;
        }
    
        .faqssrch{
            width: 90%;
        height: 67px;
        border-radius: 15px;
        background: #FFFFFF;
        border: 2px solid #E2E2E2;
        margin-left: 3%;
        margin-top: 3%;
        padding-left: 60px;
        }
    
        .faqsic{
            position: relative;
            width: 18.75px;
        height: 18.75px;
        margin-left: -90.5%;
        cursor: pointer; 
        }
    
        .faqssrch::placeholder{
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 15px;
        font-weight: 400px;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;    
        color: #0D0D0D;
        }
    
        .faqsord{
            width: 712px;
        height: 47px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 40px;
        font-weight: 400px;
        line-height: 50px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: 11%;
        }
    
        .faqsfr1-input {
            display: none;
          }
          
          .faqsfr1-label {
            cursor: pointer;
          }
          
          .faqsfr1-input:checked ~ .faqsfre1-label .faqsfre1 {
            border: 2px solid black;
          }
          
          .faqsfr1-input:checked + .faqsfre1-label .faqsfresh1 {
            color: black;
        }
        
        .faqsfr1{
            margin-left: 3%;
            margin-top: 0%;
        }
        
        .faqsfre1{
            width: 147.3px;
        height: 147.3px;
        border-radius: 15px;
        background: #FFF0E8;
        }
        
        .faqsfres1{
            width: 56.77px;
        height: 74.34px;
        margin-left: 50px;
        margin-top: -190px;
        }
        
        .faqsfresh1{
            width: 85.31px;
        height: 24.58px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 35px;
        color:#FF5B00;
        }
    
        .faqsfr2-input {
            display: none;
          }
          
          .faqsfr2-label {
            cursor: pointer;
          }
          
          .faqsfr2-input:checked ~ .faqsfre2-label .faqsfre2 {
            border: 2px solid black;
          }
          
          .faqsfr2-input:checked + .faqsfre2-label .faqsfresh2 {
            color: black;
        }
        
        .faqsfr2{
            margin-top: 0px;
            margin-left: -14%;
        }
        
        .faqsfre2{
            width: 147.33px;
        height: 147.33px;
        border-radius: 15px;
        background: #FFFAEC;
        }
        
        .faqsfres2{
            width: 54.07px;
        height: 74.34px;
        margin-left: 50px;
        margin-top: -190px;
        }
        
        .faqsfresh2{
            width: 125.79px;
        height: 24.58px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 15px;
        color: #CCA126;
        }
        
        .faqsfr3-input {
            display: none;
          }
          
          .faqsfr3-label {
            cursor: pointer;
          }
          
          .faqsfr3-input:checked ~ .faqsfre3-label .faqsfre3 {
            border: 2px solid black;
          }
          
          .faqsfr3-input:checked + .faqsfre3-label .faqsfresh3 {
            color: black;
        }
        
        .faqsfr3{
            margin-top: 0px;
            margin-left: -14%;
        }
        
        .faqsfre3{
            width: 147.33px;
        height: 147.33px;
        border-radius: 15px;
        background: #FFEEF9;
        }
        
        .faqsfres3{
            width: 43.25px;
        height: 72.99px;
        margin-left: 50px;
        margin-top: -190px;
        }
        
        .faqsfresh3{
            width: 88.2px;
        height: 24.58px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 30px;
        color: #891A61;
        }
        
        .faqsfr4-input {
            display: none;
          }
          
          .faqsfr4-label {
            cursor: pointer;
          }
          
          .faqsfr4-input:checked ~ .faqsfre4-label .faqsfre4 {
            border: 2px solid black;
          }
          
          .faqsfr4-input:checked + .faqsfre4-label .faqsfresh4 {
            color: black;
        }
        
        .faqsfr4{
            margin-top: -21%;
            margin-left: 61%;
        }
        
        .faqsfre4{
            width: 147.33px;
        height: 147.33px;
        border-radius: 15px;
        background: #FFE8ED;
        }
        
        .faqsfres4{
            width: 52.71px;
        height: 72.99px;
        margin-left: 50px;
        margin-top: -190px;
        }
        
        .faqsfresh4{
            width: 163.38px;
        height: 24.58px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 0px;
        color: #CC0530;
        }
        
        .faqsfr5-input {
            display: none;
          }
          
          .faqsfr5-label {
            cursor: pointer;
          }
          
          .faqsfr5-input:checked ~ .faqsfre5-label .faqsfre5 {
            border: 2px solid black;
          }
          
          .faqsfr5-input:checked + .faqsfre5-label .faqsfresh5 {
            color: black;
        }
        
        .faqsfr5{
            margin-top: -21%;
            margin-left: 80%;
        }
        
        .faqsfre5{
            width: 147.33px;
        height: 147.33px;
        border-radius: 15px;
        background: #E2ECFF;
        }
        
        .faqsfres5{
            width: 52.71px;
        height: 81.1px;
        margin-left: 50px;
        margin-top: -190px;
        }
        
        .faqsfresh5{
            width: 104.1px;
        height: 24.58px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 28px;
        color: #073CA5;
        }
    
        /* .ant-space {
            width: 100%;
          } */
        
        .custom-collapse {
          width: 861px;
          height: 96px;
          margin-left: 40px;
          margin-top: 20px;
          margin-bottom: 500px;
          position: relative;
        }
        
        /* :where(.css-dev-only-do-not-override-1drr2mu).ant-collapse>.ant-collapse-item >.ant-collapse-header {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            padding: 12px 16px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;    
            cursor: pointer;
            transition: all 0.3s, visibility 0s;
        } */

        :where(.css-dev-only-do-not-override-1b0bdye).ant-collapse>.ant-collapse-item >.ant-collapse-header {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            padding: 12px 16px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;    
            cursor: pointer;
            transition: all 0.3s, visibility 0s;
        }
        
        :where(.css-dev-only-do-not-override-1drr2mu).ant-collapse {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            list-style: none;
            background-color: #FFFFFF;
            border: 2px solid #E2E2E2;
            border-radius: 10px;
        }
        
        :where(.css-dev-only-do-not-override-1drr2mu).ant-collapse .ant-collapse-content {
            color: rgba(0, 0, 0, 0.88);
            background-color: #ffffff;
            border-top: none;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        }
        
        :where(.css-dev-only-do-not-override-1drr2mu).ant-collapse>.ant-collapse-item:last-child, :where(.css-dev-only-do-not-override-1drr2mu).ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
            border-radius: 0 0 8px 8px;
            border-bottom: none;
        }
    
        .horizontal-line {
            width: 868px;
            border: 1px solid #B1B1B1;
            margin-top: 8%;
            margin-left: 3%;
        }
    
        .hidemobpad {
            display: none !important;
          }
    
          .faqstite1{
            align-items: center;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        position: relative;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        width: 234px;
        height: 64px;
        border-radius: 32px;
        background: #E8EDFC;
        align-items: left;
        margin-left: 5%;
        margin-top: -2%;
        
        }
        
        .faqsparas1{
            display: none !important;
        }
    
        .faqsparast1{
            width: 761px;
            height: 87px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400px;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: -19%;
        margin-top: 0%;
        }
    
        .faqsparahe2{
            width: 761px;
    height: 90px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    margin-left: 5%;
        }
        
        .faqsingblog{
            width: 761px;
        height: 229px;
        border-radius: 25px;   
        margin-top: 0%;
        }
        
        .faqlcblog{
            width: 43px;
        height: 43px;
        border-radius: 100%;
        background: #D9D9D9;
        margin-top: -310px;
        margin-left: -20px;
        position: absolute;
        }
        
        .faqsauthblog{
            width: 113px;
        height: 25px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: -300px;
        margin-left: 30px;
        }
        
        .faqslat{
            width: 124px;
        height: 38px;
        border-radius: 0 0 15px 15px;
        background: #252525;
        margin-top: -252px;
        margin-left: 570px;
        position: absolute;
        }
        
        .faqlate{
            width: 70px;
        height: 19px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
        margin-left: -3%;
        margin-top: -2%;
        }
        
        .faqspablog{
            width: 438px;
    height: 60px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #111111;
    margin-top: 22%;
        }
        
        .faqsparblog{
            width: 759px;
    height: 90px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    margin-top: 8%;
    margin-left: -2%;
        }
    
        .faqsingblog12{
            width: 761px;
        height: 229px;
        border-radius: 25px;   
        margin-top: 0%;
        }
    
        .faqlcblog12{
            width: 43px;
        height: 43px;
        border-radius: 100%;
        background: #D9D9D9;
        margin-top: -310px;
        margin-left: -20px;
        position: absolute;
        }
    
        .faqsauthblog12{
            width: 113px;
        height: 25px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: -300px;
        margin-left: 30px;
        }
    
        .faqspablog12{
            width: 438px;
    height: 60px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #111111;
    margin-top: 57%;
        }
    
        .faqsingblog13{
            width: 761px;
        height: 229px;
        border-radius: 25px;   
        margin-top: 0%;
        }
    
        .faqlcblog13{
            width: 43px;
        height: 43px;
        border-radius: 100%;
        background: #D9D9D9;
        margin-top: -310px;
        margin-left: -20px;
        position: absolute;
        }
    
        .faqsauthblog13{
            width: 113px;
        height: 25px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: -300px;
        margin-left: 30px;
        }
    
        .faqspablog13{
            width: 438px;
    height: 60px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #111111;
    margin-top: 57%;
        }
    
        .faqreadmore2{
            width: 173px;
            height: 60px;
            border-radius: 37.5px;
            border: none;
            background-color: rgba(7, 60, 165, 1);
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: center;
            color: rgba(255, 255, 255, 1);
            margin-top: 30px;
            margin-left: 30px;
            margin-bottom: 80px;
        }
    
    
}
    
@media(max-width: 576px) {
    .faqpagemain{
        margin-top: -12px;
    }
    .aboutuscollapseheader{
        width: 248px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 600;
line-height: 14.88px;
text-align: left;

    }

    .aboutuscollapsecontainer{
        background-color: white;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        width: 330px;
    }


        .faqstartedbox4{
            width: 335px;
height: 199px;
border-radius: 25px;
background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
position: absolute;
margin-top: 115px;
left: 50%;
    transform: translate(-50%, -50%);
}

        .faqstarthead4{
            width: 500px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-left: 0%;
margin-top: 9%;
        }

        .faqsubbutton4{
            width: 137px;
height: 42px;
border-radius: 37.5px;
background: rgba(255, 255, 255, 1);
border: none;
position: relative;
margin-top: 40%;
margin-left:33%;
        }

        .faqregis4{
            width: 90px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 18.6px;
text-align: center;
color: rgba(7, 60, 165, 1);
margin-left: auto;
margin-right: auto;
margin-top: 7%;

        }

        .faqstartpara4{
            width: 253px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: center;
position: relative;
color: rgba(255, 255, 255, 1);
margin-top: -21%;
/* margin-left: 19%; */
margin-left: auto;
margin-right: auto;
        }

        .faqblogheader{
            width: 105px;
height: 34px;
border-radius: 37.5px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: center;
margin-top: 10%;
margin-left: 12%;
        }

        .faqblogheader2{
            width: 135px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
margin-top: 20%;
margin-left: -27%;
        }

        .faqblogheader3{
            width: 330px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
margin-left: 10%;

        }

        .blogpartfaq{
            display: none;
        }

        .hideinlapfaq{
            display: none;
        }

        .hideintabfaq{
            display: none ;
        }

        /* :where(.css-dev-only-do-not-override-1b0bdye).ant-collapse .ant-collapse-content{
            border-top: none;
        } */

        .faqcardbldimensions{
            margin-top: 14%;
            margin-left: -5%;
            border: none;
            margin-bottom: 30%;
        }
    
        .faqbloglctext{
            width: 90px;
    height: 19px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 18.6px;
    text-align: left;
    margin-left: 18%;
    margin-top: -10%;
        }
    
        .faqhr2blogl{
            display: none;
        }
    
        .faqbltitle{
            width: 257px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.8px;
    text-align: left;
    margin-top: -30%;
        }
    
        .faqbltitlepara{
            width: 306px;
    height: 60px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.88px;
    text-align: left;
    margin-top: 10%;
    
    
        }
    
        .faqbloglc{
            width: 43px;
    height: 43px;
    background: rgba(217, 217, 217, 1);
    border-radius: 100%;
    margin-top: -147%;
        }


        /* kcjakjnakiajn */
        .faqscon{
            margin-top: 5%;
            margin-left: 4%;

        }
    
        .faqsimg{
            display: none;
        }

        .faqsimgmob{
            width: 365px;
            height: 293px;
            position: absolute;
            margin-top: 153px;
            left: 50%;
        transform: translate(-50%, -50%);
        }
    
        .faqsconimg{
            width: 385px;
        height: 293px;
        background: linear-gradient(360deg, #FFFFFF 6.1%, rgba(255, 255, 255, 0) 88.1%);
        position: absolute;
        top: 300px;
        left: 50%;
    transform: translate(-50%, -50%);
        }
    
        .faqstext{
            width: 64px;
        height: 31px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400px;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        position: relative;
        /* margin-left: 40%; */
        left: 50%;
    transform: translate(-50%, -50%);
        margin-top: 70%;
        margin-bottom: 1em;
        }
    
        .faqstext2mob{
            width: 358px;
        height: 60px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 12px;
        font-weight: 400px;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
        position: relative;
        left: 50%;
    transform: translate(-50%, -50%);
        /* margin-left: 5%; */
        }
    
        .faqssrch{
            width: 290px;
        height: 37px;
        border-radius: 15px;
        background: #FFFFFF;
        border: 2px solid #E2E2E2;
        margin-left: 13%;
        margin-top: -2%;
        padding-left: 60px;
        }
    
        .faqsic{
            position: relative;
            width: 11.72px;
        height: 11.72px;
        margin-left: -66%;
        cursor: pointer; 
        }
    
        .faqssrch::placeholder{
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 12px;
        font-weight: 400px;
        line-height: 13px;
        letter-spacing: 0em;
        text-align: left;    
        color: #0D0D0D;
        }
    
        .faqsord{
            width: 205px;
        height: 31px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 25px;
        font-weight: 400px;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: 23%;
        margin-top: 0%;
        }
    
        .faqsfr1-input {
            display: none;
          }
          
          .faqsfr1-label {
            cursor: pointer;
          }
          
          .faqsfr1-input:checked ~ .faqsfre1-label .faqsfre1 {
            border: 2px solid black;
          }
          
          .faqsfr1-input:checked + .faqsfre1-label .faqsfresh1 {
            color: black;
        }
        
        .faqsfr1{
            margin-left: 10%;
            margin-top: 0%;
        }
        
        .faqsfre1{
            width: 86px;
        height: 86px;
        border-radius: 15px;
        background: #FFF0E8;
        }
        
        .faqsfres1{
            width: 35px;
        height: 46px;
        margin-left: 25px;
        margin-top: -120px;
        }
        
        .faqsfresh1{
            width: 50px;
        height: 15px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 12px;
        font-weight: 700px;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 20px;
        color:#FF5B00;
        }
    
        .faqsfr2-input {
            display: none;
          }
          
          .faqsfr2-label {
            cursor: pointer;
          }
          
          .faqsfr2-input:checked ~ .faqsfre2-label .faqsfre2 {
            border: 2px solid black;
          }
          
          .faqsfr2-input:checked + .faqsfre2-label .faqsfresh2 {
            color: black;
        }
        
        .faqsfr2{
            margin-top: -31%;
            margin-left: 35%;
        }
        
        .faqsfre2{
            width: 86px;
        height: 86px;
        border-radius: 15px;
        background: #FFFAEC;
        }
        
        .faqsfres2{
            width: 35px;
        height: 49px;
        margin-left: 25px;
        margin-top: -110px;
        }
        
        .faqsfresh2{
            width: 73px;
        height: 15px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 12px;
        font-weight: 700px;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 5px;
        color: #CCA126;
        }
        
        .faqsfr3-input {
            display: none;
          }
          
          .faqsfr3-label {
            cursor: pointer;
          }
          
          .faqsfr3-input:checked ~ .faqsfre3-label .faqsfre3 {
            border: 2px solid black;
          }
          
          .faqsfr3-input:checked + .faqsfre3-label .faqsfresh3 {
            color: black;
        }
        
        .faqsfr3{
            margin-top: -30%;
            margin-left: 60%;
        }
        
        .faqsfre3{
            width: 86px;
        height: 86px;
        border-radius: 15px;
        background: #FFEEF9;
        }
        
        .faqsfres3{
            width: 29px;
        height: 49px;
        margin-left: 30px;
        margin-top: -120px;
        }
        
        .faqsfresh3{
            width: 46px;
        height: 15px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 12px;
        font-weight: 700px;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 20px;
        color: #891A61;
        }
        
        .faqsfr4-input {
            display: none;
          }
          
          .faqsfr4-label {
            cursor: pointer;
          }
          
          .faqsfr4-input:checked ~ .faqsfre4-label .faqsfre4 {
            border: 2px solid black;
          }
          
          .faqsfr4-input:checked + .faqsfre4-label .faqsfresh4 {
            color: black;
        }
        
        .faqsfr4{
            margin-top: 5%;
            margin-left: 23%;
        }
        
        .faqsfre4{
            width: 86px;
        height: 86px;
        border-radius: 15px;
        background: #FFE8ED;
        }
        
        .faqsfres4{
            width: 35px;
        height: 49px;
        margin-left: 25px;
        margin-top: -115px;
        }
        
        .faqsfresh4{
            width: 93px;
        height: 15px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 12px;
        font-weight: 400px;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: -5px;
        color: #CC0530;
        }
        
        .faqsfr5-input {
            display: none;
          }
          
          .faqsfr5-label {
            cursor: pointer;
          }
          
          .faqsfr5-input:checked ~ .faqsfre5-label .faqsfre5 {
            border: 2px solid black;
          }
          
          .faqsfr5-input:checked + .faqsfre5-label .faqsfresh5 {
            color: black;
        }
        
        .faqsfr5{
            margin-top: -30%;
            margin-left: 50%;
        }
        
        .faqsfre5{
            width: 86px;
        height: 86px;
        border-radius: 15px;
        background: #E2ECFF;
        }
        
        .faqsfres5{
            width: 32px;
        height: 49px;
        margin-left: 28px;
        margin-top: -118px;
        }
        
        .faqsfresh5{
            width: 60px;
        height: 15px;
        font-family: 'Plus Jakarta Sans' sans-serif;
        font-size: 12px;
        font-weight: 700px;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: -15px;
        margin-left: 15px;
        color: #073CA5;
        }
    
        /* .ant-space {
            width: 100%;
          } */
        
        .custom-collapse {
          width: 330px;
          height: 57px;
          margin-left: 40px;
          margin-top: -20px;
          margin-bottom: 500px;
          position: relative;
        }
        
        .custom-collapse-header{
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            padding: 12px 16px;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;    
            cursor: pointer;
            transition: all 0.3s, visibility 0s;
            width: 270px;
            margin-top: -5%;
            margin-left: -5%;
height: 30px;

        }
        
        .collapsefullcardfaq {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            list-style: none;
            background-color: #FFFFFF;
            border: 2px solid #E2E2E2;
            border-radius: 10px;
            /* width: 330px;
height: 57px; */
border-radius: 10px;

        }
        
        .collapsecontentfaq {
            color: rgba(0, 0, 0, 0.88);
            background-color: #ffffff;
            border-top: none;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        }
        
        /* :where(.css-dev-only-do-not-override-1drr2mu).ant-collapse>.ant-collapse-item:last-child, :where(.css-dev-only-do-not-override-1drr2mu).ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
            border-radius: 0 0 8px 8px;
            border-bottom: none;
        } */



    
        .horizontal-line {
            width: 85%;
            border: 1px solid #B1B1B1;
            margin-left: auto;
            margin-right: auto;
            margin-top: 5%;
            
        }
    
        .hidemobpad {
            display: none !important;
          }
    
          .faqstite1{
            align-items: center;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 12px;
        position: relative;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
        width: 105px;
        height: 34px;
        border-radius: 32px;
        background: #E8EDFC;
        align-items: left;
        margin-left: 10%;
        margin-top: -12%;
        
        }
    
        .ipadcard{
            width: 309px;
    height: 229px;
    border-radius: 25px 0px 0px 0px;
    opacity: 0px;
    
        }
        
        .faqsparas1{
            width: 105px;
            height: 31px;
            font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400px;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 10%;
        margin-top: 2%;
        }
    
        .faqsparahe2{
            width: 309px;
    height: 60px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.88px;
    text-align: left;
    margin-left: 10%;
        }
        
        .faqsingblog{
            width: 309px;
        height: 229px;
        border-radius: 25;   
        margin-top: 0%;
        margin-left: 50px;
        }
        
        .faqlcblog{
            width: 43px;
        height: 43px;
        border-radius: 100%;
        background: #D9D9D9;
        margin-top: -310px;
        margin-left: -20px;
        position: absolute;
        }
        
        .faqsauthblog{
            width: 85px;
        height: 19px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 15px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: -300px;
        margin-left: 30px;
        }
        
        .faqslat{
            width: 110px;
        height: 38px;
        border-radius: 0 0 15px 15px;
        background: #252525;
        margin-top: -252px;
        margin-left: 150px;
        position: absolute;
        }
        
        .faqlate{
            width: 70px;
        height: 19px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
        margin-left: -3%;
        margin-top: -2%;
        }
        
        .faqspablog{
            width: 257px;
    height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.8px;
    text-align: left;
    color: #111111;
    margin-top: 69%;
        }
        
        .faqsparblog{
            width: 306px;
    height: 60px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.88px;
    text-align: left;
    margin-top: 15%;
    margin-left: -7%;
        }
    
        .faqreadmore2{
            width: 173px;
            height: 60px;
            border-radius: 37.5px;
            border: none;
            background-color: rgba(7, 60, 165, 1);
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: center;
            color: rgba(255, 255, 255, 1);
            margin-top: 10px;
            margin-left: 100px;
        }
        
        .faqsparast1{
            display: none !important;
        }
    
    
    }