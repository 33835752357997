.found{
    margin-top: 10%;
    position: relative;
    margin-bottom: 0%;
    /* margin-left: 0%; */
    left: -350px;
    width: 220px;
height: 15px;
}

.filter{
    margin-top: 5px;
}

.Locationselect{
  top: -95px;
  left: 365px;
    width: 360px;
    height: 67px;
}

.Locationselect .ant-select-selector {
    border: 2px solid rgba(226, 226, 226, 1) !important;
    border-radius:0 15px 15px 0px; 
  }
  
  .Locationselect .ant-select-selection-placeholder {
    font-size: 14px; 
  }
  
  .Locationselect .ant-select-selection-item {
    font-size: 10px; 
    height: 28px;
    align-items: center;
    border-radius: 10px;
  }

.countfound{
    position: absolute;
    color: blue;
    margin-top: -13%;
    margin-left: 45%;
}

.jobsearchinput{
    width: 360px;
  height: 67px;
  border-radius: 0 15px 15px 0;
  border: 2px;
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(226, 226, 226, 1);
  margin-top: -8%;
  margin-left: 29%;
  position:absolute;
    font-size: 16px;
    /* // clip-path: polygon(87% 0%, 0% 0%, 0% 100%, 87% 100%); */
    background-image: url('../Joblisting/images/search.png'); 
    background-position: 15px 24px; 
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
}


@media (min-width: 1600px){
  .Locationselect{
    top: -95px;
    left: 365px;
    width: 340px;
    border-radius: none !important;
   }
}

@media (min-width: 1550px) and (max-width:1600px){
  .Locationselect{
    top: -93px;
    left: 360px;
    width: 340px;
    border-radius: none !important;
   }
}

@media (min-width: 1525px) and (max-width:1550px){
  .Locationselect{
    top: -91px;
    left: 358px;
    width: 340px;
    border-radius: none !important;
   }
}

@media (min-width: 1500px) and (max-width:1525px){
  .Locationselect{
    top: -89.5px;
    left: 353px;
    width: 340px;
    border-radius: none !important;
   }
}

@media (min-width: 1450px) and (max-width:1500px){
  .Locationselect{
    top: -87.5px;
    left: 335px;
    width: 340px;
    border-radius: none !important;
   }
}

@media (min-width: 1420px) and (max-width:1450px){
  .Locationselect{
    top: -85px;
    left: 328px;
    width: 340px;
    border-radius: none !important;
   }
}

@media (min-width: 1400px) and (max-width:1420px){
  .Locationselect{
    top: -84px;
    left: 328px;
    width: 340px;
    border-radius: none !important;
   }
}

@media (min-width:1340px) and (max-width:1400px){
  .Locationselect{
   top: -81px;
   left: 310px;
   width: 340px;
   border-radius: none !important;
  }
}

@media (min-width:1300px) and (max-width:1340px){
  .Locationselect{
   top: -78px;
   left: 300px;
   width: 340px;
   border-radius: none !important;
  }
}

@media (min-width:1250px) and (max-width:1300px){
   .Locationselect{
    top: -75px;
    left: 292px;
    width: 340px;
    border-radius: none !important;
   }
}

@media (min-width:1200px) and (max-width:1250px){
  .Locationselect{
   top: -73px;
   left: 283px;
   width: 340px;
   border-radius: none !important;
  }
}

@media (min-width:1150px) and (max-width:1200px){
  .Locationselect{
    top: -69.5px;
    left: 268px;
    width: 340px;
    border-radius: none !important;
   }
}

@media (min-width:1130px) and (max-width:1150px){
  .Locationselect{
    top: -67.5px;
    left: 258px;
    width: 340px;
    border-radius: none !important;
   }
}

@media (min-width:1100px) and (max-width:1130px){
  .Locationselect{
    top: -66px;
    left: 258px;
    width: 340px;
    border-radius: none !important;
   }
}

@media (min-width:1050px) and (max-width:1100px){
  .Locationselect{
    top: -64px;
    left: 245px;
    width: 340px;
    border-radius: none !important;
   }
}

@media (min-width:1026px) and (max-width:1050px){
  .Locationselect{
    top: -61.5px;
    left: 238px;
    width: 340px;
    border-radius: none !important;
   }
}

@media (min-width:1000px) and (max-width:1026px){
  .Locationselect{
    top: -60.5px;
    left: 232px;
    width: 240px;
    border-radius: none !important;
   }
}

@media (min-width:992px) and (max-width:1000px){
  .Locationselect{
    top: -59px;
    left: 232px;
    width: 240px;
    border-radius: none !important;
   }
}

@media (min-width:960px) and (max-width:991.5px){
  .Locationselect{
    top: -76.5px;
    left: 298px;
    width: 240px;
    border-radius: none !important;
   }
}

@media (min-width:930px) and (max-width:960px){
  .Locationselect{
    top: -74.5px;
    left: 285px;
    width: 240px;
    border-radius: none !important;
   }
}

@media (min-width:900px) and (max-width:930px){
  .Locationselect{
    top: -72.5px;
    left: 280px;
    width: 240px;
    border-radius: none !important;
   }
}

@media (min-width:860px) and (max-width:900px){
  .Locationselect{
    top: -70px;
    left: 266px;
    width: 240px;
    border-radius: none !important;
   }

   .found{
    /* margin-top: 90px; */
    left: -240px;
}
}

@media (min-width:830px) and (max-width:860px){
  .Locationselect{
    top: -66px;
    left: 252px;
    width: 240px;
    border-radius: none !important;
   }
   
   .found{
    /* margin-top: 90px; */
    left: -240px;
}
}

@media (min-width:800px) and (max-width:830px){
  .Locationselect{
    top: -64.5px;
    left: 248px;
    width: 240px;
    border-radius: none !important;
   }
}

@media (min-width:768px) and (max-width:800px){
  .Locationselect{
    top: -61.5px;
    left: 238px;
    width: 240px;
    border-radius: none !important;
   }
}

@media (min-width:800px) and (max-width:1200px){
  .Locationselect .ant-select-selection-item {
    font-size: 8px; 
    height: 20px;
    align-items: center;
    border-radius: 10px;
  }
}

@media (min-width: 768px) and (max-width:991.5px){
    .remaincolm{
      position: relative;
      top: 100px;
    }
}

@media (min-width: 768px) and (max-width:830px){
    .found{
      position: relative;
      left: -240px;
      top: 15px;
    }
}

@media (min-width: 831px) and (max-width:900px){
  .found{
    position: relative;
    top: 15px;
  }
}

@media (min-width: 901px) and (max-width:991.5px){
  .found{
    position: relative;
    left: -240px;
    top: 15px;
  }
}

@media (min-width: 992px) and (max-width:1026.5px){
  .found{
    position: relative;
    left: -240px;
    top: 15px;
  }
}

@media (max-width: 767.5px) {

    .Locationselect{
        top: -20px;
        left: -310px;
        height: 42px;
        width: 290px;
    }

    .Locationselect .ant-select-selector {
        border: 2px solid rgba(226, 226, 226, 1) !important;
        border-radius:15px; /* Adjust selector border radius */
      }

      .Locationselect .ant-select-selection-placeholder {
        font-size: 14px; 
        padding-left: 10px;
      }

      .Locationselect .ant-select-selection-item {
        font-size: 7px; 
        height: 15px;
        align-items: center;
        border-radius: 11px !important;
        left: 7px;
      }



    html,body{
        overflow-x: hidden;
    }
    .filterheading{
        margin-top: 20%;
    }

    .clearheading{
        margin-top: 20%;
    }

    .pagenonavigation{
        margin-top: 0%;
    }
    .found{
        margin-top: 6%;
        position: relative;
        margin-bottom: 0%;
        margin-left: -21%;
        width: 220px;
    height: 15px;
    }

    .filtercolm{
      position: relative;
      top: -90px;
    }
}