.orgsize{
    width: 295px;
    height: 60px;
    border-radius: 5px;
}

@media (min-width:577px) and (max-width:830px){
    .orgsize{
      width: 603px;
  height: 60px;
    border-radius: 5px;
  }
  }

@media (max-width:576px){
    .orgsize{
        width: 285px;
      height: 44px;
      border-radius: 5px;
    }
}