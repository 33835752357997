.chatbox1exp:hover ,
.chatbox2exp:hover,
.extratestimonial:hover,
.chatbox4exp:hover,
.chatbox5exp:hover
{
    transform: scale(1.05); /* Scale up the element on hover */
  }

  .chatbox1exp,
  .chatbox2exp,
  .extratestimonial,
  .chatbox4exp,
  .chatbox5exp
  {
    /* Add initial styles */
    transition: transform 0.3s ease; /* Add transition for smooth effect */
  }
 


@media (min-width:1400px){

    /* box click element start */

    .clickihv1exp{
        width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
    }

    .clickihv2exp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickihv3exp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clickhv1exp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clickhv2exp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickhv3exp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clichv1exp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clichv2exp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clichv3exp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clihv1exp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clihv2exp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clihv3exp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    /* box click elemet end */

    .chatimg4tab{
        display: none;
    }

    .chatimg5tab{
        display: none;
    }

    /* start of circle animation  */
    @keyframes moveUp {
        0% { transform: translateY(30px); }
        100% { transform: translateY(-10px); }
    }
    
    .orgcircle2, .viocircle2, .bluecircle2 {
        
        animation: none; /* Initially no animation */
    }
    
    .scrolling .orgcircle2 {
        animation: moveUp 0.8s forwards; /* Play animation once and stop */
    }
    
    .scrolling .viocircle2 {
        animation: moveUp 1.5s forwards; /* Slightly delay the animation */
    }
    
    .scrolling .bluecircle2 {
        animation: moveUp 2s forwards; /* Slightly delay the animation */
    }
    /* end of circle animation */

    /* start of boxes animation */

    .dancing-divvexp {
        opacity: 0;
        transform: translateY(-400px);
        transition: 1s ;
    }
      
      .dancing-divvexp.visible {
        opacity: 1;
        transform: translateY(-100%);
        animation:  0.5s ease-in-out forwards ;
      }
      
      @keyframes shakeexp {
        0% { transform: translateX(0); }
        25% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
        75% { transform: translateX(-10px); }
        100% { transform: translateX(0); }
      }
      
      /* .shake {
        animation: shake 0.5s;
      } */
      

 
    .dancing-divexp {
        /* width: 100px;
        height: 100px;
        background-color: blue;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer; */
        transition: transform 0.5s ease; /* Smooth transition for the dancing effect */
      }
      
      .dancing-divexp.dancing {
        transform: translateY(-25px); /* Adjust the value as needed */
        animation: 60s ease-out alternate; /* Animation for the dancing effect */
      }
      
      @keyframes bounce {
        from {
          transform: translateY(-25px);
        }
        to {
          transform: translateY(0);
        }
      }

    /* end of boxes animation */

    .exluckcont{
        overflow: hidden;
        height: 60px;
    }

    .exluckcont div div{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  display: inline-block;
                  font-weight: 900;  
                  margin-bottom: 15px;  
    }

    .exluckcont div:nth-child(1){
        animation: animate 6s linear infinite;
        position: relative;
        top: -1px;
    }

    @keyframes animate {
        0%{margin-top: -240px;}
        5%{margin-top: -180px;}
        33%{margin-top: -180px;}
        38%{margin-top: -90px;}
        66%{margin-top: -90px;}
        71%{margin-top: 0px;}
        99.99%{margin-top: 0px;}
        100%{margin-top: 0px;}
    }

   .experiencedpagemain{
    margin-top: 100px;
   } 
   /* start of brand  */
    
         
   .brandhovercontainerexp1{
    position: relative;
}

.accesshovercontainerexp1{
    position: relative;
}

.autohovercontainerexp1{
    position: relative;
}

.hirehovercontainerexp1{
    position: relative;
}


.imagecontainerbrandexp1{
    /* position: relative; */
    transition: opacity 0.3s ease;
    /* cursor: pointer; */
    /* z-index: 1; */
}

.imagecontaineraccessexp1{
    /* position: relative; */
    margin-top: -0.5%;
    margin-left: -0.7%;
    transition: opacity 0.3s ease;
    /* cursor: pointer; */
    /* z-index: 1; */
}

.imagecontainerautoexp1{
    /* position: relative; */
    transition: opacity 0.3s ease;
    margin-left: -0.7%;
    /* cursor: pointer; */
    /* z-index: 1; */
}

.imagecontainerhireexp1{
    /* position: relative; */
    transition: opacity 0.3s ease;
    /* cursor: pointer; */
    /* z-index: 1; */
    margin-left: -0.5%;
    margin-top: -0.9%;
}

.autohoverimagesexp1{
    margin-left: 85%;
    margin-top: -40%;
}

.brandhovercontentexp1{
    position: relative;
        margin-top: -41%;
        left: 80px;
        background-color: #ffffff;
        width: 1140px;
        height: 470px;
        z-index: 1;

}

.accesshovercontentexp1{
    position: relative;
    margin-top: -40.5%;
    left: 80px;
    background-color: #ffffff;
    width: 1140px;
    height: 470px;
    z-index: 1;
}

.autohovercontentexp1{
    position: relative;
    margin-top: -43.3%;
    left: 80px;
    background-color: #ffffff;
    width: 1140px;
    height: 470px;
    z-index: 1;
}

.hirehovercontentexp1{
    position: relative;
    margin-top: -42.2%;
    left: 80px;
    background-color: #ffffff;
    width: 1140px;
    height: 470px;
    z-index: 1;
}

.clickincoreexp1{
    width: 34px;
height: 34px;
position: relative;
top: 145px;
left: -145px;
cursor: pointer;
}

.clickincoreexp2{
    width: 34px;
height: 34px;
position: relative;
margin-top: -6.5%;
left: 64.5%;
cursor: pointer;
}

.clickincoreexp3{
    width: 34px;
height: 34px;
position: relative;
margin-top: -8%;
left: 58%;
cursor: pointer;
}

.clickincoreexp4{
    width: 34px;
height: 34px;
position: relative;
margin-top: -1%;
left: 85.5%;
cursor: pointer;
}

.containerexpcore{
    position: absolute;
}


/* .imagecontainerbrandexp1:hover + .brandhovercontentexp1 {
    opacity: 1; 
  } */

  /* .imagecontaineraccessexp1:hover + .accesshovercontentexp1 {
    opacity: 1; 
  } */
    /* 
    .imagecontainerautoexp1:hover + .autohovercontentexp1 {
        opacity: 1; 
    } */

  /* .imagecontainerhireexp1:hover + .hirehovercontentexp1 {
    opacity: 1; 
  } */



.brandcontentexp1{
    width: 948px;
height: 462px;
border-radius: 50px;
background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
}

.brandheaderexp1{
    width: 760px;
height: 54px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 45px;
font-weight: 900;
line-height: 54.46px;
text-align: left;
color: rgba(255, 255, 255, 1);
margin-left: 5%;
position: absolute;
margin-top: 2.5%;
}

.brandparaexp1{
    width: 854px;
height: 217px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 6%;
margin-left: 2%;
padding: 3%;
}

.brandhoverimagesexp{
    margin-left: 85%;
    margin-top: -40.5%;
}

.hoverimg1brandexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
margin-bottom: 2%;
position: absolute;
}

.brandhovimg1bg{
    width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
opacity: 45%;
margin-bottom: -15%;
}

.hoverimg2brandexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
margin-top: 0.5%;
margin-bottom: 2%;
position: absolute;
}

.brandhovimg2bg{
    width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-bottom: -10%;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    position: relative;
    top: 6px;
}

.hoverimg3brandexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
margin-bottom: 2%;
position: absolute;
}

.brandhoverimg3bg{
    width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-bottom: 2%;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    opacity: 45%;
}




.accesscontentexp1{
    width: 948px;
height: 462px;
border-radius: 50px;
background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
}

.accessheaderexp1{
    width: 900px;
height: 54px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 45px;
font-weight: 900;
line-height: 54.46px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 2.5%;
margin-left: 5%;
}

.accessparaexp1{
    width: 854px;
height: 217px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 7%;
margin-left: 2%;
padding: 3%;
}

.accesshoverimagesexp1{
    margin-left: 85%;
    margin-top: -40%;
}

.hoverimg1accessexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
    margin-top: 0%;
    /* margin-bottom: 2%; */
    position: absolute;
}

.accesshoverimg1bgexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
opacity: 45%;
margin-bottom: -13%;
}


.hoverimg2accessexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
    margin-top: 0%;
    margin-bottom: 2%;
    position: absolute;
}

.accesshoverimg2bgexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
opacity: 45%;
margin-bottom: -13%;
}

.accesshoveimg2bgexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
opacity: 45%;
margin-bottom: -13%;
}

.hoverimg3accessexp1{
    position: absolute;
    width: 141px;
height: 145px;
border-radius: 20px;
    margin-top: 0%;
    margin-bottom: 2%;
}

.accesshoverimg3bgexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
opacity: 45%;
margin-bottom: 2%;
}

.accesshoveimg3bgexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
opacity: 45%;
margin-bottom: 2%;
}

.hoverimg1autoexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
    margin-top: 0%;
    /* margin-bottom: 2%; */
    position: absolute;
}

.autohoverimg1bgexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
opacity: 45%;
margin-bottom: -13%;
}


.hoverimg2autoexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
    margin-top: 0%;
    margin-bottom: 2%;
    position: absolute;
}

.autohoverimg2bgexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
opacity: 45%;
margin-bottom: -13%;
}

.hoverimg3autoexp1{
    position: absolute;
    width: 141px;
height: 145px;
border-radius: 20px;
    margin-top: 0%;
    margin-bottom: 2%;
}

.autohoverimg3bgexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
opacity: 45%;
margin-bottom: 2%;
}   

/* .accessheaderexp1{
    width: 726px;
    height: 54px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 45px;
font-weight: 900;
line-height: 54.46px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 2.5%;
margin-left: 5%;
}
 */
/* .accessparaexp1{
    width: 854px;
height: 217px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 6%;
margin-left: 2%;
padding: 3%;
} */

.autoheaderexp1{
    width: 900px;
height: 54px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 45px;
font-weight: 900;
line-height: 54.46px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 2.5%;
margin-left: 5%;
}

.autoparaexp1{
    width: 854px;
height: 217px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 6%;
margin-left: 2%;
padding: 3%;
}

.hireheaderexp1{
    width: 700px;
height: 54px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 45px;
font-weight: 900;
line-height: 54.46px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 2.5%;
margin-left: 5%;
}

.hireparaexp1{
    width: 854px;
height: 217px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 6%;
margin-left: 2%;
padding: 3%;
}

.hirecontentexp1{
    width: 948px;
height: 462px;
border-radius: 50px;
background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);

}


.autocontentexp1{
    width: 948px;
height: 462px;
border-radius: 50px;
background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%);

}

   /* end of brand */
   
    .bimg1xt{
        position: absolute;
        top: 1400px;
    }

    .instlogoexp{
        /* width: 150px; */
        /* height: 100px; */
    }
    
    
    .logos{
        /* padding: 20px 0 0 0; */
        /* padding-right: 0%; */
        /* margin-left: 0%; */
        white-space: nowrap;
    
    }

    .registertab{
        display: none;
    }

    .assesshead1tab{
        display: none;
    }

    .assesshead2tabexp{
        display: none;
    }
    
    
    
 
    
    .logo-slide{
        display: inline-block;
        animation: 120s slide infinite linear;
    }
    
    .ttextexp{
        width: 320px;
    height: 31px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    }
    
    
    
    .logo-slide img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logoss{
        /* overflow: hidden; */
        /* padding: 20px 0; */
        white-space: nowrap;
    
    }
    
  
    
    .logos-slide{
        display: inline-block;
        animation: 80s slides infinite linear;
    }
    
    .logos-slide img{
        margin: 0 40px;
        height: 100px;
        /* height: 75px; */
    }
    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }
    
    
    body{
        overflow-x: hidden;
    }
    
    .register1texp{
        padding-top: 10%;
    }
    
    
    .content11{
        display: flex;
        justify-content: center;
font-size: 48px;
font-weight: 300;
line-height: 59.52px;
    text-align: left;
    width: max-content;
    font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 60px;
        margin-top: 26%;
    }
    
    .content11 br {
        content: '';
        display: block;
        margin-bottom: 10px; 
    }
    
    .watchs1{
        padding-top: 6%;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    
    }
    
    .fresher1tag{
        /* align-items: center; */
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    /* text-indent: 10px; */
    margin-left: 7%;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 234px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    
    }
    .luck1{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  display: inline-block;
                  font-weight: 900;
    
    }
    .ftext{
        /* margin-top: 10px; */
        top: 15px;
        position: absolute;
        left: 70px;
    }
    
    .imag-container1 {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
        margin-left: 10%;
    }

    .corevaluestab{
        display: none;
    }

    .imag-container1 img{
        position: relative;
    }
    
    .background-container1 {
        position: absolute;
        bottom: 0;
        left: 60%;
        transform: translateX(-50%);
        width: 284px;
        height: 432px;
        background-color: #FFBC00;
    }
    
    .orgvector1 {
        z-index: 1; 
        margin-left: 28%;
    }
    
    .orgvector1 img {
        display: block;
    }
    
    .violetbox1{
        background: rgba(130, 27, 97, 1);
        width: 203px;
    height: 203px;
    display: flex;
    position: relative;
    margin-left: 21%;
    margin-top: -28%;
    display: inline-block;
    text-align: start;
    }
    
    .vtexp1{
        width: 91px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    }
    .imag-container4{
        position: relative;
        margin-left: 21%;
        margin-top: -9%;
        z-index: 1;
    }
    
    .vtexp2{
        width: 83px;
    height: 19px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    margin-top: 65px;
    margin-left: -85px;
    }
    
    .yellowbox1{
        background:#F75B02;    ;
        width: 118px;
    height: 118px;
    display: flex;
    margin-left: 14.5%;
    /* margin-right: 190px; */
    display: inline-block;
    position: relative;
    margin-top: -9%;
    text-align: start;
    }
    
    .ytexp1{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    }
    
    .ytexp2{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 0px;
    }
    
    .imag-container2, .imag-container3, .imag-container5 {
        position: relative;
        z-index: 2; 
    }

    .sechead1{
        margin-top: 5%;
    }
    
    /* .sechead1{
        margin-top: 10%;
    } */
    
    /* .firstsignup1{
        margin-top: -18%;
    } */
    .siginsexp{
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 2%;
    color: rgba(255, 255, 255, 1);
    
    
    }
    
    .signinexp
    {
        width: 173px;
    height: 60px;
    position: relative;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 5%;
    margin-left: 10%;
    background: rgba(7, 60, 165, 1);
    border: none;
    
    }
    
    .watchexp{
        width: 200px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    position: relative;
    margin-top: 5%;
    margin-left: -60%;
    text-align: center;
    background-color: white;
    border: 1px solid rgba(7, 60, 165, 1);
    color: rgba(7, 60, 165, 1);
    }
    
    .linetextexp{
        width: 1051px;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center ;
    margin-left: auto;
    margin-right: auto;
    /* margin-left: 140px; */
    margin-top: 10%;
    margin-bottom: 8em;
    }
    
    .linebox1{
    
        width: 160px;
    height: 160px;
    display: flex;
    /* margin-right: 100px; */
    display: inline-block;
    /* top: 0px; */
    position: relative;
    margin-top: -72%;
    text-align: start;
    border: 2px solid rgba(0, 0, 0, 1);
    margin-left: 24%;
    
    }
    
    .hlinebox1{
        width: 102px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 16%;
    position: absolute;
    opacity: 30%;
    
    }
    .hlinebox2{
        width: 40px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 30%;
    opacity: 30%;
    position: absolute;
    
    }
    
    
    .rlinebox1{
        width: 159px;
    height: 102px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 10%;
    position: absolute;
    }
    
    .rlinebox2{
        width: 159px;
    height: 40px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 50%;
    }
    
    .lineboxtextexp{
        width: 64px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    color: rgba(0, 0, 0, 1);
    
    }
    .lineboxtext2exp{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .title1tagexp{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    margin-top: 6%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 390px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }

    .title2tagexp2g{
        width: 1050px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: auto;
margin-right: auto;
    }

    .imagecontainerbrand{
        position: relative;
    }

    
    .mid3contentexp .img1{
        width: 312px;
    height: 462px;
    margin-left: 100px;
    margin-top: -3px;
    border-radius: 50px;
    }
    .mid3contentexp .bimg1{
        width: 312px;
    height: 462px;
    position: absolute;
    margin-top: -0.2%;
    margin-left: -24%;
    border-radius: 50px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    }
    
    .mid3contentexp .bimg1text{
        width: 146px;
    height: 84px;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    opacity: 100%;
    position: absolute;
    /* display: block; */
    margin-top: -150px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-left: 120px;
    
    }
    
    
    .imagecontaineraccess{
        position: relative;
    }
    
    .mid3contentexp .img2{
        width: 513px;
    height: 231px;
    margin-top: -35%;
    margin-left: 34%;
    margin-bottom: 40%;
    border-radius: 50px;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    .mid3contentexp .bimg2{
        width: 513px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-top: -35.2%;
    margin-left: -39.5%;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    
    }
    
    .bimg2textexp1{
        width: 355px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -45%;
    margin-left: 480px;
    opacity: 100%;
    position: relative;
    display: block;
    }
    
    .imagecontainerauto{
        position: relative;
    }
    
    .title2tagexp{
        margin-top: 8px;
        width: 699px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 310px;
    margin-left: auto;
    margin-right: auto;
    }

    .title2tagexp2{
        margin-top: 8px;
        width: 1050px;
        height: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
    margin-left: auto;
    margin-right: auto;
    }
    
    .mid3contentexp .img3{
        width: 768px;
    height: 212px;
    border-radius: 50px;
    margin-top: -18%;
    margin-left: 34%;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    
    .mid3contentexp .bimg3{
        width: 768px;
    height: 212px;
    margin-top: -16.1%;
    margin-left: -59.2%;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    }
    
    .mid3contentexp .bimg3text{
        width: 430px;
    height: 84px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    margin-left: 36%;
    display: block;
    margin-top: -9%;
    color:rgba(255, 255, 255, 1);
    
    }
    
    .imagecontainerhire{
        position: relative;
    }
    
    
    .mid3contentexp .img4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    margin-top: -58%;
    margin-left: 75%;
    /* opacity: 45%px; */
    
    
    }
    
    .mid3contentexp .bimg4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-left: -17.7%;
    margin-top: -36.9%;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    }
    
    .bimg4textexp1{
        width: 72px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    position: relative;
    display: block;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -32%;
    margin-left: 77%;
    
    }
    
    
    
    
    .phonebox2{
        margin: 14%;
    }
    
    .phonetitle1tag2exp{
        margin-top: 100px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 420px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }
    
    .phoneheadtitle2{
        /* margin-left: 120px; */
        width: 381px;
        height: 174px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;    
    }
    
    .phonebody2lap{
        width: 405px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 5%;
    margin-left: 0px;
    position: absolute;
    }
    
    .phone2{
        width: 351px;
    height: 644px;
    margin-top: -490px;
    margin-left: 80px;
    position: relative;
    }
    
    .orgcircle2{
        width: 240.93px;
    height: 240.93px;
    margin-top: -170px;
    border-radius: 100%;
    margin-left: 50px;
    /* background: rgba(247, 91, 2, 1); */
    position: relative;
    /* opacity: 90%; */
    }
    
    .viocircle2{
        width: 240.93px;
    height: 240.93px;
    border-radius: 100%;
    /* background: rgba(130, 27, 97, 1); */
    margin-top: -120px;
    position: relative;
    opacity: 90%;
    }
    
    .viocircle2::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      margin-left: -15%;
      width: 240.93px;
    height: 240.93px;
      border-radius: 50%;
      opacity: 100%;
    }
    
    .orgcircle2::before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 240.93px;
    height: 240.93px;
      border-radius: 50%;
      opacity: 90%;
    }
    
    .orgcircle2::before {
        background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
      }
      
      .viocircle2::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }
    
    .bluecircle2{
        width: 364.7px;
    height: 364.7px;
    border-radius: 100%;
    background: rgba(135, 189, 255, 1);
    margin-top: -390px;
    margin-left: 150px;
    }
    
    .arrow2{
        width: 256.46px;
    height: 189.91px;
    margin-left: -250px;
    margin-top: -150px;
    }
    .image-phone-container2{
        margin-left: 500px;
        margin-top: -200px;
    }
    
    .phonetitles2{
        margin-left: -130px;
    }
    
    .phonebody2mob{
        display: none;
    }
    
    .trainingtitles{
        margin-top: -590px;
    }
    
    .traintitle1tagexp{
        width: 225px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    }
    
    .trainheadtitleexp{
        width: 542px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    top: 130px;
    }
    
    .traincircleep{
        width: 500px;
    height: 500px;
    background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -250px;
    position: relative;
    top: -100px;
    }
    
    .head12exp{
        width: 1075px;
    height: 105px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    top: 130px;
    }
    
    .head13{
        margin-top: -80px;
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }

    .head121{
        display: none;
    }

    .head131{
        display: none;
    }
    
    .registerexp{
        width: 173px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: -100px;
    background: rgba(7, 60, 165, 1);
    border: none;
    margin-bottom: 0%;
    
    
    }
    
    .assesscircleep{
        width: 500px;
    height: 500px;
    background: rgba(9, 9, 63, 0.05);
    border-radius: 100%;
    margin-left: 1050px;
    margin-top: 15%; 
    }
    
    .assesstitle1tagexp{
        margin-top: -570px;
        margin-left: 990px;
        width: 225px;
        height: 64px;
        border-radius: 37.5px;
        background: rgba(232, 237, 252, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;    
    }
    
    .assesheadtitleexp{
        width: 693px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: right;
    margin-left: 590px;
    position: relative;
    top: -340px;
    }
    
    .assesshead1exp{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 210px;
    text-align: right;
    position: relative;
    top: -340px;
    margin-bottom: -120px;
    }
    
    .assesshead2exp{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 150px;
    text-align: right;
    margin-top: -50px;
    }
    
    .register2exp{
        width: 173px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 1050px;
    margin-top: -100px;
    border: none;
    background: rgba(7, 60, 165, 1);
    }

    .gifexp{
        position: absolute;
        top: -290px;
        left: 160px;
    }
    
    .chatexp{
        position: relative;
        top: -400px;
        margin-bottom: -13em;
    }
    
    .chatitle1tag{
        width: 370px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 170px;
    }
    
    .ctextexp{
        margin-left: auto;
        margin-right: auto;
        margin-bottom: auto;
        margin-top: auto;
    }
    
    .chatheadtitleexp{
        width: 651px;
    height: 174px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .chatimg1exp{
        width: 87px;
    height: 87px;
    }
    .chatbox111exp{
        clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
        width: 84.16px;
    height: 64px;
    background: rgb(231, 242, 255, 1);
    margin-left: -117%;
    margin-top: 0%;
    position: relative;
    }
    
    .chatbox11exp{
        width: 378px;
    height: 615px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-left: 150px;
    margin-top: -80px;
    }
    
    .chatboxtext1{
        width: 370px;
    height: 529.92px;
    padding: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    .chatbox121exp{
        width: 81px;
    height: 64px;
    margin-top: -45.6%;
    margin-left: 63%;
    position: relative;
    background: rgba(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    }
    
    
    .chatbox21exp{
        margin-left: -30px;
        width: 435px;
        height: 284px;
        border-radius: 50px 0px 50px 50px;
        background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext21{
        width: 365px;
    height: 150px;
        padding: 30px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg2exp{
        width: 87px;
    height: 87px;
    margin-left: 450px;
    margin-top: -40px;
    }
    
    .chatbox1331exp{
        width: 81px;
    height: 64px;
    background: rgba(207, 240, 230, 1);
    position: relative;
    margin-top: -32.2%;
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    margin-left: 65%;
    }
    
    .chatbox331{
        padding: 10px;
        margin-top: 10%;
        width: 358px;
       margin-left: 10%;
    height: 201px;
    border-radius: 50px 0px 50px 50px;
    background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext31{
        padding: 20px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }

    
    
    .chatimg33exp{
        
        margin-top: -35px;
        margin-left: 72%;
    }
    
    .chatimg4exp{
        width: 87px;
    height: 87px;
    margin-top: -480px;
    margin-left: -40px;
    }
    .chatbox151mobexp{
        display: none;
    }
    .chatbox141exp{
        width: 81px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    position: relative;
    margin-top: -2.2%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: -105%;
    }
    
    .chatbox41exp{
        padding: 10px;
        width: 475px;
    height: 220px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: -300px;
    margin-left: 100px;
    }
    .chatboxtext41{
        width: 388px;
    height: 90px;
        padding: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    
    }
    
    
    .chatimg5exp{
        width: 87px;
    height: 87px;
        margin-top: -150px;
        margin-left: -40px;
    }
    
    .chatbox51exp{
        padding: 10px;
        width: 455px;
    height: 200px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: -150px;
    margin-left: 95px;
    }
    
    .chatbox151exp{
        width: 81px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    position: relative;
    margin-top: -2.3%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: -110%;
    }
    
    .chatboxtext51{
        padding: 20px;
        width: 399px;
    height: 194px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .startedexp{
        padding: 0 50px 0 50px;
        position: relative;
        margin-top: -15%;
    }

    .startedboxexp{
        margin-top: 280px;
        width: 1207px;
    height: 227px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 100px;
    margin-left: 3%;
    }
    
    .subbuttonexp{
        margin-left: 930px;
        margin-top: -230px;
        height: 50px;
        width: 175px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }
    
    .startheadexp{
        width: 1000px;
    height: 87px;
    margin-left: 60px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    }
    
    .regisexp{
        width: 90px;
    height: 19px;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .startparatab{
        width: 613px;
    height: 62px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 95px;
    margin-top: -2%;
    margin-bottom: 13%;
    }
    
    .blueca1{
        width: 54px;
    height: 54px;
    position: absolute;
    background: rgba(7, 60, 165, 1);
    margin-left: 380px;
    margin-top: 42%;
    border-radius: 100%;
    }

    .blueca1 img{
        margin-top: 20%;
        margin-left: 30%;
    }

    
    .whitea{
        width: 30px;
    height: 60px;
    }
    
    .namesm{
        width: 250px;
    height: 30px;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .desgsm{
        width: 134px;
    height: 30px;
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-top: -30px;
    margin-left: 28%;
    }

    .register2tabexp{
        display: none;
    }

    
}


@media (min-width:1228px) and (max-width:1399px){

     /* box click element start */

     .clickihv1exp{
        width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
    }

    .clickihv2exp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickihv3exp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clickhv1exp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clickhv2exp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickhv3exp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clichv1exp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clichv2exp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clichv3exp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clihv1exp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clihv2exp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clihv3exp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    /* box click elemet end */

    .chatimg4tab{
        display: none;
    }

    .chatimg5tab{
        display: none;
    }
    .experiencedpagemain{
        max-width:1300px;
        transform: scale(0.83);
        transform-origin: top;
        margin-bottom: -800px;
    }
    .gifexp{
        position: absolute;
        top: -290px;
        left: 160px;
    }
 
    /* start of circle animation  */
    @keyframes moveUp {
        0% { transform: translateY(30px); }
        100% { transform: translateY(-10px); }
    }
    
    .orgcircle2, .viocircle2, .bluecircle2 {
        
        animation: none; /* Initially no animation */
    }
    
    .scrolling .orgcircle2 {
        animation: moveUp 0.8s forwards; /* Play animation once and stop */
    }
    
    .scrolling .viocircle2 {
        animation: moveUp 1.5s forwards; /* Slightly delay the animation */
    }
    
    .scrolling .bluecircle2 {
        animation: moveUp 2s forwards; /* Slightly delay the animation */
    }
    /* end of circle animation */

    /* start of boxes animation */

    .dancing-divvexp {
        opacity: 0;
        transform: translateY(-400px);
        transition: 1s ;
    }
      
      .dancing-divvexp.visible {
        opacity: 1;
        transform: translateY(-100%);
        animation:  0.5s ease-in-out forwards ;
      }
      
      @keyframes shakeexp {
        0% { transform: translateX(0); }
        25% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
        75% { transform: translateX(-10px); }
        100% { transform: translateX(0); }
      }
      
      /* .shake {
        animation: shake 0.5s;
      } */
      

 
    .dancing-divexp {
        /* width: 100px;
        height: 100px;
        background-color: blue;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer; */
        transition: transform 0.5s ease; /* Smooth transition for the dancing effect */
      }
      
      .dancing-divexp.dancing {
        transform: translateY(-45px); /* Adjust the value as needed */
        animation: 60s ease-out alternate; /* Animation for the dancing effect */
      }
      
      @keyframes bounce {
        from {
          transform: translateY(-25px);
        }
        to {
          transform: translateY(0);
        }
      }

    /* end of boxes animation */

    .exluckcont{
        overflow: hidden;
        height: 60px;
    }

    .exluckcont div div{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  display: inline-block;
                  font-weight: 900;  
                  margin-bottom: 15px;  
    }

    .exluckcont div:nth-child(1){
        animation: animate 6s linear infinite;
        position: relative;
        top: -1px;
    }

    @keyframes animate {
        0%{margin-top: -240px;}
        5%{margin-top: -180px;}
        33%{margin-top: -180px;}
        38%{margin-top: -90px;}
        66%{margin-top: -90px;}
        71%{margin-top: 0px;}
        99.99%{margin-top: 0px;}
        100%{margin-top: 0px;}
    }

   .experiencedpagemain{
    margin-top: -4%;
   } 
   /* start of brand  */
    
         
   .brandhovercontainerexp1{
    position: relative;
}

.accesshovercontainerexp1{
    position: relative;
}

.autohovercontainerexp1{
    position: relative;
}

.hirehovercontainerexp1{
    position: relative;
}


.imagecontainerbrandexp1{
    /* position: relative; */
    transition: opacity 0.3s ease;
    /* cursor: pointer; */
    /* z-index: 1; */
}

.imagecontaineraccessexp1{
    /* position: relative; */
    margin-top: -0.5%;
    margin-left: -0.7%;
    transition: opacity 0.3s ease;
    /* cursor: pointer; */
    /* z-index: 1; */
}

.imagecontainerautoexp1{
    /* position: relative; */
    transition: opacity 0.3s ease;
    margin-left: -0.7%;
    /* cursor: pointer; */
    /* z-index: 1; */
}

.imagecontainerhireexp1{
    /* position: relative; */
    transition: opacity 0.3s ease;
    /* cursor: pointer; */
    /* z-index: 1; */
    margin-left: -0.5%;
    margin-top: -0.9%;
}

.autohoverimagesexp1{
    margin-left: 85%;
    margin-top: -40%;
}

.brandhovercontentexp1{
    position: relative;
        margin-top: -41%;
        left: 80px;
        background-color: #ffffff;
        width: 1140px;
        height: 470px;
        z-index: 1;

}

.accesshovercontentexp1{
    position: relative;
    margin-top: -40.5%;
    left: 80px;
    background-color: #ffffff;
    width: 1140px;
    height: 470px;
    z-index: 1;
}

.autohovercontentexp1{
    position: relative;
    margin-top: -43.5%;
    left: 80px;
    background-color: #ffffff;
    width: 1140px;
    height: 470px;
    z-index: 1;
}

.hirehovercontentexp1{
    position: relative;
    margin-top: -42.5%;
    left: 80px;
    background-color: #ffffff;
    width: 1140px;
    height: 470px;
    z-index: 1;
}

.clickincoreexp1{
    width: 34px;
height: 34px;
position: relative;
top: 150px;
left: -130px;
cursor: pointer;
}

.clickincoreexp2{
    width: 34px;
height: 34px;
position: relative;
margin-top: -6.5%;
left: 65.5%;
cursor: pointer;
}

.clickincoreexp3{
    width: 34px;
height: 34px;
position: relative;
margin-top: -8%;
left: 59.5%;
cursor: pointer;
}

.clickincoreexp4{
    width: 34px;
height: 34px;
position: relative;
margin-top: -1%;
left: 88%;
cursor: pointer;
}

.containerexpcore{
    position: absolute;
}


/* .imagecontainerbrandexp1:hover + .brandhovercontentexp1 {
    opacity: 1; 
  } */

  /* .imagecontaineraccessexp1:hover + .accesshovercontentexp1 {
    opacity: 1; 
  } */
    /* 
    .imagecontainerautoexp1:hover + .autohovercontentexp1 {
        opacity: 1; 
    } */

  /* .imagecontainerhireexp1:hover + .hirehovercontentexp1 {
    opacity: 1; 
  } */



.brandcontentexp1{
    width: 948px;
height: 462px;
border-radius: 50px;
background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
}

.brandheaderexp1{
    width: 760px;
height: 54px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 45px;
font-weight: 900;
line-height: 54.46px;
text-align: left;
color: rgba(255, 255, 255, 1);
margin-left: 5%;
position: absolute;
margin-top: 2.5%;
}

.brandparaexp1{
    width: 854px;
height: 217px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 6%;
margin-left: 2%;
padding: 3%;
}

.brandhoverimagesexp{
    margin-left: 85%;
    margin-top: -40.5%;
}

.hoverimg1brandexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
margin-bottom: 2%;
position: absolute;
}

.brandhovimg1bg{
    width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
opacity: 45%;
margin-bottom: -15%;
}

.hoverimg2brandexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
margin-top: 0.5%;
margin-bottom: 2%;
position: absolute;
}

.brandhovimg2bg{
    width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-bottom: -10%;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    position: relative;
    top: 6px;
}

.hoverimg3brandexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
margin-bottom: 2%;
position: absolute;
}

.brandhoverimg3bg{
    width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-bottom: 2%;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    opacity: 45%;
}




.accesscontentexp1{
    width: 948px;
height: 462px;
border-radius: 50px;
background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
}

.accessheaderexp1{
    width: 900px;
height: 54px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 45px;
font-weight: 900;
line-height: 54.46px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 2.5%;
margin-left: 5%;
}

.accessparaexp1{
    width: 854px;
height: 217px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 7%;
margin-left: 2%;
padding: 3%;
}

.accesshoverimagesexp1{
    margin-left: 85%;
    margin-top: -40%;
}

.hoverimg1accessexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
    margin-top: 0%;
    /* margin-bottom: 2%; */
    position: absolute;
}

.accesshoverimg1bgexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
opacity: 45%;
margin-bottom: -13%;
}


.hoverimg2accessexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
    margin-top: 0%;
    margin-bottom: 2%;
    position: absolute;
}

.accesshoverimg2bgexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
opacity: 45%;
margin-bottom: -13%;
}

.accesshoveimg2bgexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
opacity: 45%;
margin-bottom: -13%;
}

.hoverimg3accessexp1{
    position: absolute;
    width: 141px;
height: 145px;
border-radius: 20px;
    margin-top: 0%;
    margin-bottom: 2%;
}

.accesshoverimg3bgexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
opacity: 45%;
margin-bottom: 2%;
}

.accesshoveimg3bgexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
opacity: 45%;
margin-bottom: 2%;
}

.hoverimg1autoexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
    margin-top: 0%;
    /* margin-bottom: 2%; */
    position: absolute;
}

.autohoverimg1bgexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
opacity: 45%;
margin-bottom: -13%;
}


.hoverimg2autoexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
    margin-top: 0%;
    margin-bottom: 2%;
    position: absolute;
}

.autohoverimg2bgexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
opacity: 45%;
margin-bottom: -13%;
}

.hoverimg3autoexp1{
    position: absolute;
    width: 141px;
height: 145px;
border-radius: 20px;
    margin-top: 0%;
    margin-bottom: 2%;
}

.autohoverimg3bgexp1{
    width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
opacity: 45%;
margin-bottom: 2%;
}   

/* .accessheaderexp1{
    width: 726px;
    height: 54px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 45px;
font-weight: 900;
line-height: 54.46px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 2.5%;
margin-left: 5%;
}
 */
/* .accessparaexp1{
    width: 854px;
height: 217px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 6%;
margin-left: 2%;
padding: 3%;
} */

.autoheaderexp1{
    width: 900px;
height: 54px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 45px;
font-weight: 900;
line-height: 54.46px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 2.5%;
margin-left: 5%;
}

.autoparaexp1{
    width: 854px;
height: 217px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 6%;
margin-left: 2%;
padding: 3%;
}

.hireheaderexp1{
    width: 700px;
height: 54px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 45px;
font-weight: 900;
line-height: 54.46px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 2.5%;
margin-left: 5%;
}

.hireparaexp1{
    width: 854px;
height: 217px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 6%;
margin-left: 2%;
padding: 3%;
}

.hirecontentexp1{
    width: 948px;
height: 462px;
border-radius: 50px;
background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);

}


.autocontentexp1{
    width: 948px;
height: 462px;
border-radius: 50px;
background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%);

}

   /* end of brand */
   
    .bimg1xt{
        position: absolute;
        top: 1400px;
    }

    .instlogoexp{
        /* width: 150px; */
        /* height: 100px; */
    }
    
    
    .logos{
        /* padding: 20px 0 0 0; */
        /* padding-right: 0%; */
        /* margin-left: 0%; */
        white-space: nowrap;
    
    }

    .registertab{
        display: none;
    }

    .assesshead1tab{
        display: none;
    }

    .assesshead2tabexp{
        display: none;
    }
    
    
    
 
    
    .logo-slide{
        display: inline-block;
        animation: 120s slide infinite linear;
    }
    
    .ttextexp{
        width: 320px;
    height: 31px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    
    
    }
    
    
    
    .logo-slide img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logoss{
        /* overflow: hidden; */
        /* padding: 20px 0; */
        white-space: nowrap;
    
    }
    
  
    
    .logos-slide{
        display: inline-block;
        animation: 80s slides infinite linear;
    }
    
    .logos-slide img{
        margin: 0 40px;
        height: 100px;
        /* height: 75px; */
    }
    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }
    
    
    body{
        overflow-x: hidden;
    }
    
    .register1texp{
        padding-top: 10%;
    }
    
    
    .content11{
        display: flex;
        justify-content: center;
    font-size: 55px;
    font-weight: 300;
    line-height: 68px;
    letter-spacing: 0em;
    text-align: left;
    width: max-content;
    font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: 60px;
        margin-top: 26%;
    }
    
    .content11 br {
        content: '';
        display: block;
        margin-bottom: 10px; 
    }
    
    .watchs1{
        padding-top: 6%;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    
    }
    
    .fresher1tag{
        /* align-items: center; */
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    /* text-indent: 10px; */
    margin-left: 7%;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 234px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    
    }
    .luck1{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  display: inline-block;
                  font-weight: 900;
    
    }
    .ftext{
        /* margin-top: 10px; */
        top: 15px;
        position: absolute;
        left: 70px;
    }
    
    .imag-container1 {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
        margin-left: 10%;
    }

    .corevaluestab{
        display: none;
    }

    .imag-container1 img{
        position: relative;
    }
    
    .background-container1 {
        position: absolute;
        bottom: 0;
        left: 60%;
        transform: translateX(-50%);
        width: 284px;
        height: 432px;
        background-color: #FFBC00;
    }
    
    .orgvector1 {
        z-index: 1; 
        margin-left: 28%;
    }
    
    .orgvector1 img {
        display: block;
    }
    
    .violetbox1{
        background: rgba(130, 27, 97, 1);
        width: 203px;
    height: 203px;
    display: flex;
    position: relative;
    margin-left: 21%;
    margin-top: -28%;
    display: inline-block;
    text-align: start;
    }
    
    .vtexp1{
        width: 91px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    }
    .imag-container4{
        position: relative;
        margin-left: 21%;
        margin-top: -9%;
        z-index: 1;
    }
    
    .vtexp2{
        width: 83px;
    height: 19px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    margin-top: 65px;
    margin-left: -85px;
    }
    
    .yellowbox1{
        background:#F75B02;    ;
        width: 118px;
    height: 118px;
    display: flex;
    margin-left: 14.5%;
    /* margin-right: 190px; */
    display: inline-block;
    position: relative;
    margin-top: -2%;
    text-align: start;
    }
    
    .ytexp1{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    }
    
    .ytexp2{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 0px;
    }
    
    .imag-container2, .imag-container3, .imag-container5 {
        position: relative;
        margin-top: -10%;        
        z-index: 2; 
    }
    
    .sechead1{
        margin-top: 5%;
    }
    
    .firstsignup1{
        margin-top: -18%;
    }
    .siginsexp{
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 2%;
    color: rgba(255, 255, 255, 1);
    
    
    }
    
    .signinexp
    {
        width: 173px;
    height: 60px;
    position: relative;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 18%;
    margin-left: 10%;
    background: rgba(7, 60, 165, 1);
    border: none;
    
    }
    
    .watchexp{
        width: 200px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 18%;
    margin-left: -60%;
    text-align: center;
    background-color: white;
    border: 1px solid rgba(7, 60, 165, 1);
    color: rgba(7, 60, 165, 1);
    }
    
    .linetextexp{
        width: 1051px;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center ;
    margin-left: auto;
    margin-right: auto;
    /* margin-left: 140px; */
    margin-top: 8%;
    margin-bottom: 8em;
    }
    
    .linebox1{
    
        width: 160px;
    height: 160px;
    display: flex;
    /* margin-right: 100px; */
    display: inline-block;
    /* top: 0px; */
    position: relative;
    margin-top: -78%;
    text-align: start;
    border: 2px solid rgba(0, 0, 0, 1);
    margin-left: 24%;
    
    }
    
    .hlinebox1{
        width: 102px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 16%;
    position: absolute;
    opacity: 30%;
    
    }
    .hlinebox2{
        width: 40px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 30%;
    opacity: 30%;
    position: absolute;
    
    }
    
    
    .rlinebox1{
        width: 159px;
    height: 102px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 10%;
    position: absolute;
    }
    
    .rlinebox2{
        width: 159px;
    height: 40px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 50%;
    }
    
    .lineboxtextexp{
        width: 64px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    color: rgba(0, 0, 0, 1);
    
    }
    .lineboxtext2exp{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .title1tagexp{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    margin-top: 0%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 390px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }

    .title2tagexp2g{
        width: 1050px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: auto;
margin-right: auto;
    }

    .imagecontainerbrand{
        position: relative;
    }
    
    .mid3contentexp .img1{
        width: 312px;
    height: 462px;
    margin-left: 90px;
    margin-top: -3px;
    border-radius: 50px;
    }
    .mid3contentexp .bimg1{
        width: 312px;
    height: 462px;
    position: absolute;
    margin-top: -0.2%;
    margin-left: -25.3%;
    border-radius: 50px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    }
    
    .mid3contentexp .bimg1text{
        width: 146px;
    height: 84px;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    opacity: 100%;
    position: absolute;
    /* display: block; */
    margin-top: -150px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-left: 120px;
    
    }
    
    
    .imagecontaineraccess{
        position: relative;
    }
    
    .mid3contentexp .img2{
        width: 513px;
    height: 231px;
    margin-top: -36.8%;
    margin-left: 34.5%;
    margin-bottom: 40%;
    border-radius: 50px;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    .mid3contentexp .bimg2{
        width: 513px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-top: -37%;
    margin-left: -41.6%;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    
    }
    
    .bimg2textexp1{
        width: 355px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -45%;
    margin-left: 450px;
    opacity: 100%;
    position: relative;
    display: block;
    }
    
    .imagecontainerauto{
        position: relative;
    }
    
    
    
      
    
    .title2tagexp{
        margin-top: 8px;
        width: 699px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 310px;
    margin-left: auto;
    margin-right: auto;
    }

    .title2tagexp2{
        margin-top: 8px;
        width: 1050px;
        height: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
    margin-left: auto;
    margin-right: auto;
    }
    
    .mid3contentexp .img3{
        width: 768px;
    height: 212px;
    border-radius: 50px;
    margin-top: -18.5%;
    margin-left: 34.5%;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    
    .mid3contentexp .bimg3{
        width: 768px;
    height: 212px;
    margin-top: -16.8%;
    margin-left: -62.3%;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    }
    
    .mid3contentexp .bimg3text{
        width: 430px;
    height: 84px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    margin-left: 36%;
    display: block;
    margin-top: -9%;
    color:rgba(255, 255, 255, 1);
    
    }
    
    .imagecontainerhire{
        position: relative;
    }
    
    
    .mid3contentexp .img4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    margin-top: -61.5%;
    margin-left: 77.5%;
    /* opacity: 45%px; */
    
    
    }

    .mid3contentexp{
        /* margin-left: -10%; */
    }
    
    .mid3contentexp .bimg4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-left: -18.8%;
    margin-top: -39.2%;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    }
    
    .bimg4textexp1{
        width: 72px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    position: relative;
    display: block;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -34%;
    margin-left: 79%;
    
    }
    
    
    
    
    .phonebox2{
        margin: 14%;
    }
    
    .phonetitle1tag2exp{
        margin-top: 100px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 420px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }
    
    .phoneheadtitle2{
        /* margin-left: 120px; */
        width: 381px;
        height: 174px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;    
    }
    
    .phonebody2lap{
        width: 405px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 6%;
    margin-left: 0px;
    position: absolute;
    }
    
    .phone2{
        width: 351px;
    height: 644px;
    margin-top: -490px;
    margin-left: 80px;
    position: relative;
    }
    
    .orgcircle2{
        width: 240.93px;
    height: 240.93px;
    margin-top: -170px;
    border-radius: 100%;
    margin-left: 50px;
    /* background: rgba(247, 91, 2, 1); */
    position: relative;
    /* opacity: 90%; */
    }
    
    .viocircle2{
        width: 240.93px;
    height: 240.93px;
    border-radius: 100%;
    /* background: rgba(130, 27, 97, 1); */
    margin-top: -120px;
    position: relative;
    opacity: 90%;
    }
    
    .viocircle2::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      margin-left: -15%;
      width: 240.93px;
    height: 240.93px;
      border-radius: 50%;
      opacity: 100%;
    }
    
    .orgcircle2::before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 240.93px;
    height: 240.93px;
      border-radius: 50%;
      opacity: 90%;
    }
    
    .orgcircle2::before {
        background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
      }
      
      .viocircle2::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }
    
    .bluecircle2{
        width: 364.7px;
    height: 364.7px;
    border-radius: 100%;
    background: rgba(135, 189, 255, 1);
    margin-top: -390px;
    margin-left: 150px;
    }
    
    .arrow2{
        width: 256.46px;
    height: 189.91px;
    margin-left: -230px;
    margin-top: -150px;
    }
    .image-phone-container2{
        margin-left: 500px;
        margin-top: -200px;
    }
    
    .phonetitles2{
        margin-left: -130px;
    }
    
    .phonebody2mob{
        display: none;
    }
    
    .trainingtitles{
        margin-top: -590px;
    }
    
    .traintitle1tagexp{
        width: 225px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    }
    
    .trainheadtitleexp{
        width: 542px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    top: 130px;
    }

    .traincircleep{
        width: 500px;
    height: 500px;
    background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -250px;
    position: relative;
    top: -100px;
    }
    
    .head12exp{
        width: 1075px;
    height: 105px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    top: 130px;
    }
    
    .head13{
        margin-top: -80px;
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }

    .head121{
        display: none;
    }

    .head131{
        display: none;
    }
    
    .registerexp{
        width: 173px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: -100px;
    background: rgba(7, 60, 165, 1);
    border: none;
    margin-bottom: 0%;
    
    
    }
    
    .assesscircleep{
        width: 500px;
    height: 500px;
    background: rgba(9, 9, 63, 0.05);
    border-radius: 100%;
    margin-left: 1000px;
    margin-top: 15%;
    
    }
    
    .assesstitle1tagexp{
        margin-top: -570px;
        margin-left: 990px;
        width: 225px;
        height: 64px;
        border-radius: 37.5px;
        background: rgba(232, 237, 252, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;    
    }
    
    .assesheadtitleexp{
        width: 693px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: right;
    margin-left: 530px;
    position: relative;
    top: -340px;
    }
    
    .assesshead1exp{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 150px;
    text-align: right;
    position: relative;
    top: -340px;
    margin-bottom: -120px;
    }
    
    .assesshead2exp{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 150px;
    text-align: right;
    margin-top: -50px;
    }
    
    .register2exp{
        width: 173px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 1050px;
    margin-top: -100px;
    border: none;
    background: rgba(7, 60, 165, 1);
    }
    
    .chatexp{
        margin-top: 200px;
        position: relative;
        margin-top: -18%;
    }
    
    .chatitle1tag{
        width: 370px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    
    }
    
    .ctextexp{
        margin-left: auto;
        margin-right: auto;
        margin-bottom: auto;
        margin-top: auto;
    }
    
    .chatheadtitleexp{
        width: 651px;
    height: 174px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .chatimg1exp{
        width: 87px;
    height: 87px;
    }
    .chatbox111exp{
        clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
        width: 84.16px;
    height: 64px;
    background: rgb(231, 242, 255, 1);
    margin-left: -117%;
    margin-top: 0%;
    position: relative;
    }
    
    .chatbox11exp{
        width: 378px;
    height: 615px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-left: 150px;
    margin-top: -80px;
    }
    
    .chatboxtext1{
        width: 370px;
    height: 529.92px;
    padding: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    .chatbox121exp{
        width: 81px;
    height: 64px;
    margin-top: -48%;
    margin-left: 63%;
    position: relative;
    background: rgba(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    }
    
    
    .chatbox21exp{
        margin-left: -30px;
        width: 435px;
        height: 284px;
        border-radius: 50px 0px 50px 50px;
        background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext21{
        width: 365px;
    height: 150px;
        padding: 30px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg2exp{
        width: 87px;
    height: 87px;
    margin-left: 450px;
    margin-top: -40px;
    }
    
    .chatbox1331exp{
        width: 81px;
    height: 64px;
    background: rgba(207, 240, 230, 1);
    position: relative;
    margin-top: -34%;
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    margin-left: 65%;
    }
    
    .chatbox331{
        padding: 10px;
        margin-top: 10%;
        width: 358px;
       margin-left: 10%;
    height: 201px;
    border-radius: 50px 0px 50px 50px;
    background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext31{
        padding: 20px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }

    
    
    .chatimg33exp{
        
        margin-top: -25px;
        margin-left: 75%;
    }
    
    .chatimg4exp{
        width: 87px;
    height: 87px;
    margin-top: -480px;
    margin-left: -40px;
    }
    .chatbox151mobexp{
        display: none;
    }
    .chatbox141exp{
        width: 81px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    position: relative;
    margin-top: -2.2%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: -105%;
    }
    
    .chatbox41exp{
        padding: 10px;
        width: 475px;
    height: 220px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: -300px;
    margin-left: 100px;
    }
    .chatboxtext41{
        width: 388px;
    height: 90px;
        padding: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    
    }
    
    
    .chatimg5exp{
        width: 87px;
    height: 87px;
        margin-top: -150px;
        margin-left: -40px;
    }
    
    .chatbox51exp{
        padding: 10px;
        width: 455px;
    height: 200px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: -150px;
    margin-left: 95px;
    }
    
    .chatbox151exp{
        width: 81px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    position: relative;
    margin-top: -2.3%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: -110%;
    }
    
    .chatboxtext51{
        padding: 20px;
        width: 399px;
    height: 194px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .startedexp{
        padding: 0 50px 0 0px;
    }
    .startedboxexp{
        margin-top: 180px;
        width: 1207px;
    height: 227px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 100px;
    margin-left: 3%;
    }
    
    .subbuttonexp{
        margin-left: 930px;
        margin-top: -230px;
        height: 50px;
        width: 175px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }
    
    .startheadexp{
        width: 1000px;
    height: 87px;
    margin-left: 60px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    
    }
    
    .regisexp{
        width: 90px;
    height: 19px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    
    }
    
    .startparatab{
        width: 613px;
    height: 62px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 95px;
    margin-top: -2%;
    margin-bottom: 13%;
    }
    
    .blueca1{
        width: 54px;
    height: 54px;
    background: rgba(7, 60, 165, 1);
    margin-left: 380px;
    margin-top: 42%;
    border-radius: 100%;
    }

    .blueca1 img{
        margin-top: 20%;
        margin-left: 30%;
    }

    
    .whitea{
        width: 30px;
    height: 60px;
    }
    
    .namesm{
        width: 250px;
    height: 30px;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .desgsm{
        width: 134px;
    height: 30px;
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-top: -30px;
    margin-left: 28%;
    }

    .register2tabexp{
        display: none;
    }

    
}

@media (min-width:1028px) and (max-width:1226px){



    /* box click element start */

    .clickihv1exp{
       width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
   }

   .clickihv2exp{
       width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
   }

   .clickihv3exp{
       width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
   }

   .clickhv1exp{
       width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
   }

   .clickhv2exp{
       width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
   }

   .clickhv3exp{
       width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
   }

   .clichv1exp{
       width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
   }

   .clichv2exp{
       width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
   }

   .clichv3exp{
       width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
   }

   .clihv1exp{
       width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
   }

   .clihv2exp{
       width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
   }

   .clihv3exp{
       width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
   }

   /* box click elemet end */

   .chatimg4tab{
       display: none;
   }

   .chatimg5tab{
       display: none;
   }
   .experiencedpagemain{
       max-width:1300px;
       transform: scale(0.83);
       transform-origin: top;
   }
   .gifexp{
       position: absolute;
       top: -290px;
       left: 160px;
   }

   /* start of circle animation  */
   @keyframes moveUp {
       0% { transform: translateY(30px); }
       100% { transform: translateY(-10px); }
   }
   
   .orgcircle2, .viocircle2, .bluecircle2 {
       
       animation: none; /* Initially no animation */
   }
   
   .scrolling .orgcircle2 {
       animation: moveUp 0.8s forwards; /* Play animation once and stop */
   }
   
   .scrolling .viocircle2 {
       animation: moveUp 1.5s forwards; /* Slightly delay the animation */
   }
   
   .scrolling .bluecircle2 {
       animation: moveUp 2s forwards; /* Slightly delay the animation */
   }
   /* end of circle animation */

   /* start of boxes animation */

   .dancing-divvexp {
       opacity: 0;
       transform: translateY(-400px);
       transition: 1s ;
   }
     
     .dancing-divvexp.visible {
       opacity: 1;
       transform: translateY(-100%);
       animation:  0.5s ease-in-out forwards ;
     }
     
     @keyframes shakeexp {
       0% { transform: translateX(0); }
       25% { transform: translateX(-10px); }
       50% { transform: translateX(10px); }
       75% { transform: translateX(-10px); }
       100% { transform: translateX(0); }
     }
     
     /* .shake {
       animation: shake 0.5s;
     } */
     


   .dancing-divexp {
       /* width: 100px;
       height: 100px;
       background-color: blue;
       display: flex;
       justify-content: center;
       align-items: center;
       cursor: pointer; */
       transition: transform 0.5s ease; /* Smooth transition for the dancing effect */
     }
     
     .dancing-divexp.dancing {
       transform: translateY(-45px); /* Adjust the value as needed */
       animation: 60s ease-out alternate; /* Animation for the dancing effect */
     }
     
     @keyframes bounce {
       from {
         transform: translateY(-25px);
       }
       to {
         transform: translateY(0);
       }
     }

   /* end of boxes animation */

   .exluckcont{
       overflow: hidden;
       height: 60px;
   }

   .exluckcont div div{
       font-family: 'Plus Jakarta Sans', sans-serif;
       background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                 color: transparent; 
                 -webkit-background-clip: text; 
                 background-clip: text;
                 display: inline-block;
                 font-weight: 900;  
                 margin-bottom: 15px;  
   }

   .exluckcont div:nth-child(1){
       animation: animate 6s linear infinite;
       position: relative;
       top: -1px;
   }

   @keyframes animate {
       0%{margin-top: -240px;}
       5%{margin-top: -180px;}
       33%{margin-top: -180px;}
       38%{margin-top: -90px;}
       66%{margin-top: -90px;}
       71%{margin-top: 0px;}
       99.99%{margin-top: 0px;}
       100%{margin-top: 0px;}
   }

  .experiencedpagemain{
   margin-top: -4%;
   margin-bottom: -900px;
  } 
  /* start of brand  */
   
        
  .brandhovercontainerexp1{
   position: relative;
}

.accesshovercontainerexp1{
   position: relative;
}

.autohovercontainerexp1{
   position: relative;
}

.hirehovercontainerexp1{
   position: relative;
}


.imagecontainerbrandexp1{
   /* position: relative; */
   transition: opacity 0.3s ease;
   /* cursor: pointer; */
   /* z-index: 1; */
}

.imagecontaineraccessexp1{
   /* position: relative; */
   margin-top: -0.5%;
   margin-left: -0.7%;
   transition: opacity 0.3s ease;
   /* cursor: pointer; */
   /* z-index: 1; */
}

.imagecontainerautoexp1{
   /* position: relative; */
   transition: opacity 0.3s ease;
   margin-left: -0.7%;
   /* cursor: pointer; */
   /* z-index: 1; */
}

.imagecontainerhireexp1{
   /* position: relative; */
   transition: opacity 0.3s ease;
   /* cursor: pointer; */
   /* z-index: 1; */
   margin-left: -0.5%;
   margin-top: -0.9%;
}

.autohoverimagesexp1{
   margin-left: 85%;
   margin-top: -40%;
}

.brandhovercontentexp1{
   position: relative;
       margin-top: -41%;
       left: 55px;
       background-color: #ffffff;
       width: 1140px;
       height: 470px;
       z-index: 1;

}

.accesshovercontentexp1{
   position: relative;
   margin-top: -40.5%;
   left: 55px;
   background-color: #ffffff;
   width: 1140px;
   height: 470px;
   z-index: 1;
}

.autohovercontentexp1{
   position: relative;
   margin-top: -42.5%;
   left: 55px;
   background-color: #ffffff;
   width: 1140px;
   height: 470px;
   z-index: 1;
}

.hirehovercontentexp1{
   position: relative;
   margin-top: -41.5%;
   left: 55px;
   background-color: #ffffff;
   width: 1140px;
   height: 470px;
   z-index: 1;
}

.clickincoreexp1{
   width: 34px;
height: 34px;
position: relative;
top: 150px;
left: -130px;
cursor: pointer;
}

.clickincoreexp2{
   width: 34px;
height: 34px;
position: relative;
margin-top: -6.5%;
left: 69.5%;
cursor: pointer;
}

.clickincoreexp3{
   width: 34px;
height: 34px;
position: relative;
margin-top: -9%;
left: 64%;
cursor: pointer;
}

.clickincoreexp4{
   width: 34px;
height: 34px;
position: relative;
margin-top: -1%;
left: 94%;
cursor: pointer;
}

.containerexpcore{
   position: absolute;
}


/* .imagecontainerbrandexp1:hover + .brandhovercontentexp1 {
   opacity: 1; 
 } */

 /* .imagecontaineraccessexp1:hover + .accesshovercontentexp1 {
   opacity: 1; 
 } */
   /* 
   .imagecontainerautoexp1:hover + .autohovercontentexp1 {
       opacity: 1; 
   } */

 /* .imagecontainerhireexp1:hover + .hirehovercontentexp1 {
   opacity: 1; 
 } */



.brandcontentexp1{
   width: 948px;
height: 462px;
border-radius: 50px;
background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
}

.brandheaderexp1{
   width: 760px;
height: 54px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 45px;
font-weight: 900;
line-height: 54.46px;
text-align: left;
color: rgba(255, 255, 255, 1);
margin-left: 5%;
position: absolute;
margin-top: 2.5%;
}

.brandparaexp1{
   width: 854px;
height: 217px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 6%;
margin-left: 2%;
padding: 3%;
}

.brandhoverimagesexp{
   margin-left: 85%;
   margin-top: -40.5%;
}

.hoverimg1brandexp1{
   width: 141px;
height: 145px;
border-radius: 20px;
margin-bottom: 2%;
position: absolute;
}

.brandhovimg1bg{
   width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
opacity: 45%;
margin-bottom: -15%;
}

.hoverimg2brandexp1{
   width: 141px;
height: 145px;
border-radius: 20px;
margin-top: 0.5%;
margin-bottom: 2%;
position: absolute;
}

.brandhovimg2bg{
   width: 141px;
   height: 145px;
   border-radius: 20px;
   margin-bottom: -10%;
   background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
   opacity: 45%;
   position: relative;
   top: 6px;
}

.hoverimg3brandexp1{
   width: 141px;
height: 145px;
border-radius: 20px;
margin-bottom: 2%;
position: absolute;
}

.brandhoverimg3bg{
   width: 141px;
   height: 145px;
   border-radius: 20px;
   margin-bottom: 2%;
   background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
   opacity: 45%;
}




.accesscontentexp1{
   width: 948px;
height: 462px;
border-radius: 50px;
background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
}

.accessheaderexp1{
   width: 900px;
height: 54px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 45px;
font-weight: 900;
line-height: 54.46px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 2.5%;
margin-left: 5%;
}

.accessparaexp1{
   width: 854px;
height: 217px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 7%;
margin-left: 2%;
padding: 3%;
}

.accesshoverimagesexp1{
   margin-left: 85%;
   margin-top: -40%;
}

.hoverimg1accessexp1{
   width: 141px;
height: 145px;
border-radius: 20px;
   margin-top: 0%;
   /* margin-bottom: 2%; */
   position: absolute;
}

.accesshoverimg1bgexp1{
   width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
opacity: 45%;
margin-bottom: -13%;
}


.hoverimg2accessexp1{
   width: 141px;
height: 145px;
border-radius: 20px;
   margin-top: 0%;
   margin-bottom: 2%;
   position: absolute;
}

.accesshoverimg2bgexp1{
   width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
opacity: 45%;
margin-bottom: -13%;
}

.accesshoveimg2bgexp1{
   width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
opacity: 45%;
margin-bottom: -13%;
}

.hoverimg3accessexp1{
   position: absolute;
   width: 141px;
height: 145px;
border-radius: 20px;
   margin-top: 0%;
   margin-bottom: 2%;
}

.accesshoverimg3bgexp1{
   width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
opacity: 45%;
margin-bottom: 2%;
}

.accesshoveimg3bgexp1{
   width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
opacity: 45%;
margin-bottom: 2%;
}

.hoverimg1autoexp1{
   width: 141px;
height: 145px;
border-radius: 20px;
   margin-top: 0%;
   /* margin-bottom: 2%; */
   position: absolute;
}

.autohoverimg1bgexp1{
   width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
opacity: 45%;
margin-bottom: -13%;
}


.hoverimg2autoexp1{
   width: 141px;
height: 145px;
border-radius: 20px;
   margin-top: 0%;
   margin-bottom: 2%;
   position: absolute;
}

.autohoverimg2bgexp1{
   width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
opacity: 45%;
margin-bottom: -13%;
}

.hoverimg3autoexp1{
   position: absolute;
   width: 141px;
height: 145px;
border-radius: 20px;
   margin-top: 0%;
   margin-bottom: 2%;
}

.autohoverimg3bgexp1{
   width: 141px;
height: 145px;
border-radius: 20px;
background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
opacity: 45%;
margin-bottom: 2%;
}   

/* .accessheaderexp1{
   width: 726px;
   height: 54px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 45px;
font-weight: 900;
line-height: 54.46px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 2.5%;
margin-left: 5%;
}
*/
/* .accessparaexp1{
   width: 854px;
height: 217px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 6%;
margin-left: 2%;
padding: 3%;
} */

.autoheaderexp1{
   width: 900px;
height: 54px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 45px;
font-weight: 900;
line-height: 54.46px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 2.5%;
margin-left: 5%;
}

.autoparaexp1{
   width: 854px;
height: 217px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 6%;
margin-left: 2%;
padding: 3%;
}

.hireheaderexp1{
   width: 700px;
height: 54px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 45px;
font-weight: 900;
line-height: 54.46px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 2.5%;
margin-left: 5%;
}

.hireparaexp1{
   width: 854px;
height: 217px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
color: rgba(255, 255, 255, 1);
position: absolute;
margin-top: 6%;
margin-left: 2%;
padding: 3%;
}

.hirecontentexp1{
   width: 948px;
height: 462px;
border-radius: 50px;
background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);

}


.autocontentexp1{
   width: 948px;
height: 462px;
border-radius: 50px;
background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%);

}

  /* end of brand */
  
   .bimg1xt{
       position: absolute;
       top: 1400px;
   }

   .instlogoexp{
       /* width: 150px; */
       /* height: 100px; */
   }
   
   
   .logos{
       /* padding: 20px 0 0 0; */
       /* padding-right: 0%; */
       /* margin-left: 0%; */
       white-space: nowrap;
   
   }

   .registertab{
       display: none;
   }

   .assesshead1tab{
       display: none;
   }

   .assesshead2tabexp{
       display: none;
   }
   
   
   

   
   .logo-slide{
       display: inline-block;
       animation: 120s slide infinite linear;
   }
   
   .ttextexp{
       width: 320px;
   height: 31px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: center;
   
   
   }
   
   
   
   .logo-slide img{
       margin: 0 40px;
       height: 100px;
   }
   
   @keyframes slide{
       from{ 
           transform: translateX(0);
       }
       to{
           transform: translateX(-100%);
       }
   }
   
   .logoss{
       /* overflow: hidden; */
       /* padding: 20px 0; */
       white-space: nowrap;
   
   }
   
 
   
   .logos-slide{
       display: inline-block;
       animation: 80s slides infinite linear;
   }
   
   .logos-slide img{
       margin: 0 40px;
       height: 100px;
       /* height: 75px; */
   }
   
   @keyframes slides{
       to{ 
           transform: translateX(0);
       }
       from{
           transform: translateX(-100%);
       }
   }
   
   
   body{
       overflow-x: hidden;
   }
   
   .register1texp{
       padding-top: 10%;
   }
   
   
   .content11{
       display: flex;
       justify-content: center;
   font-size: 55px;
   font-weight: 300;
   line-height: 68px;
   letter-spacing: 0em;
   text-align: left;
   width: max-content;
   font-family: 'Plus Jakarta Sans', sans-serif;
       margin-left: 60px;
       margin-top: 26%;
   }
   
   .content11 br {
       content: '';
       display: block;
       margin-bottom: 10px; 
   }
   
   .watchs1{
       padding-top: 6%;
       font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 20px;
   font-weight: 400;
   line-height: 25px;
   letter-spacing: 0em;
   text-align: center;
   
   }
   
   .fresher1tag{
       /* align-items: center; */
       font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   position: relative;
   /* text-indent: 10px; */
   margin-left: 7%;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: center;
   width: 234px;
   height: 64px;
   border-radius: 32px;
   background-color: rgba(232, 237, 252, 1);
   
   }
   .luck1{
       font-family: 'Plus Jakarta Sans', sans-serif;
       background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                 color: transparent; 
                 -webkit-background-clip: text; 
                 background-clip: text;
                 display: inline-block;
                 font-weight: 900;
   
   }
   .ftext{
       /* margin-top: 10px; */
       top: 15px;
       position: absolute;
       left: 70px;
   }
   
   .imag-container1 {
       position: relative;
       display: flex;
       justify-content: center;
       align-items: center;
       margin-top: 10%;
       margin-left: 10%;
   }

   .corevaluestab{
       display: none;
   }

   .imag-container1 img{
       position: relative;
   }
   
   .background-container1 {
       position: absolute;
       bottom: 0;
       left: 60%;
       transform: translateX(-50%);
       width: 284px;
       height: 432px;
       background-color: #FFBC00;
   }
   
   .orgvector1 {
       z-index: 1; 
       margin-left: 28%;
   }
   
   .orgvector1 img {
       display: block;
   }
   
   .violetbox1{
       background: rgba(130, 27, 97, 1);
       width: 203px;
   height: 203px;
   display: flex;
   position: relative;
   margin-left: 21%;
   margin-top: -28%;
   display: inline-block;
   text-align: start;
   }
   
   .vtexp1{
       width: 91px;
   height: 45px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 36px;
   font-weight: 400;
   line-height: 45px;
   letter-spacing: 0em;
   text-align: left;
   display: flex;
   justify-content: center;
   color: rgba(255, 255, 255, 1);
   }
   .imag-container4{
       position: relative;
       margin-left: 21%;
       margin-top: -9%;
       z-index: 1;
   }
   
   .vtexp2{
       width: 83px;
   height: 19px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 19px;
   letter-spacing: 0em;
   text-align: left;
   display: flex;
   justify-content: center;
   color: rgba(255, 255, 255, 1);
   margin-top: 65px;
   margin-left: -85px;
   }
   
   .yellowbox1{
       background:#F75B02;    ;
       width: 118px;
   height: 118px;
   display: flex;
   margin-left: 14.5%;
   /* margin-right: 190px; */
   display: inline-block;
   position: relative;
   margin-top: -2%;
   text-align: start;
   }
   
   .ytexp1{
       width: 66px;
   height: 44px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 20px;
   font-weight: 400;
   line-height: 25px;
   letter-spacing: 0em;
   text-align: left;
   margin-top: 20px;
   margin-left: 10px;
   }
   
   .ytexp2{
       width: 66px;
   height: 44px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 19px;
   letter-spacing: 0em;
   text-align: left;
   margin-top: 0px;
   }
   
   .imag-container2, .imag-container3, .imag-container5 {
       position: relative;
       margin-top: -10%;        
       z-index: 2; 
   }
   
   .sechead1{
       margin-top: 5%;
   }
   
   .firstsignup1{
       margin-top: -18%;
   }
   .siginsexp{
       font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 20px;
   font-weight: 400;
   line-height: 25px;
   letter-spacing: 0em;
   text-align: center;
   padding-top: 2%;
   color: rgba(255, 255, 255, 1);
   
   
   }
   
   .signinexp
   {
       width: 173px;
   height: 60px;
   position: relative;
   border-radius: 37.5px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 20px;
   font-weight: 400;
   line-height: 25px;
   letter-spacing: 0em;
   margin-top: 18%;
   margin-left: 10%;
   background: rgba(7, 60, 165, 1);
   border: none;
   
   }
   
   .watchexp{
       width: 200px;
   height: 60px;
   border-radius: 37.5px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 20px;
   font-weight: 400;
   line-height: 25px;
   letter-spacing: 0em;
   margin-top: 18%;
   margin-left: -60%;
   text-align: center;
   background-color: white;
   border: 1px solid rgba(7, 60, 165, 1);
   color: rgba(7, 60, 165, 1);
   }
   
   .linetextexp{
       width: 1051px;
   height: 93px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: center;
   display: flex;
   justify-content: center;
   align-content: center ;
   margin-left: auto;
   margin-right: auto;
   /* margin-left: 140px; */
   margin-top: 8%;
   margin-bottom: 8em;
   }
   
   .linebox1{
   
       width: 160px;
   height: 160px;
   display: flex;
   /* margin-right: 100px; */
   display: inline-block;
   /* top: 0px; */
   position: relative;
   margin-top: -78%;
   text-align: start;
   border: 2px solid rgba(0, 0, 0, 1);
   margin-left: 24%;
   
   }
   
   .hlinebox1{
       width: 102px;
   height: 159px;
   padding: 0px, 0px, 0px, 0px;
   border: 1px;
   border: 1px solid rgba(0, 0, 0, 1);
   margin-left: 16%;
   position: absolute;
   opacity: 30%;
   
   }
   .hlinebox2{
       width: 40px;
   height: 159px;
   padding: 0px, 0px, 0px, 0px;
   border: 1px;
   border: 1px solid rgba(0, 0, 0, 1);
   margin-left: 30%;
   opacity: 30%;
   position: absolute;
   
   }
   
   
   .rlinebox1{
       width: 159px;
   height: 102px;
   padding: 0px, 0px, 0px, 0px;
   border: 1px solid rgba(0, 0, 0, 1);
   margin-top: 30px;
   opacity: 10%;
   position: absolute;
   }
   
   .rlinebox2{
       width: 159px;
   height: 40px;
   padding: 0px, 0px, 0px, 0px;
   border: 1px solid rgba(0, 0, 0, 1);
   margin-top: 30px;
   opacity: 50%;
   }
   
   .lineboxtextexp{
       width: 64px;
   height: 45px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 36px;
   font-weight: 400;
   line-height: 45px;
   letter-spacing: 0em;
   text-align: left;
   margin-top: 20px;
   margin-left: 10px;
   color: rgba(0, 0, 0, 1);
   
   }
   .lineboxtext2exp{
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 12px;
   letter-spacing: 0em;
   text-align: left;
   }
   
   .title1tagexp{
       align-items: center;
       font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   position: relative;
   margin-top: 0%;
   margin-left: auto;
   margin-right: auto;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: center;
   width: 390px;
   height: 64px;
   border-radius: 32px;
   background-color: rgba(232, 237, 252, 1);
   }

   .title2tagexp2g{
       width: 1050px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: auto;
margin-right: auto;
   }

   .imagecontainerbrand{
       position: relative;
   }
   
   .mid3contentexp .img1{
       width: 312px;
   height: 462px;
   margin-left: 60px;
   margin-top: -3px;
   border-radius: 50px;
   }
   .mid3contentexp .bimg1{
       width: 312px;
   height: 462px;
   position: absolute;
   margin-top: -0.2%;
   margin-left: -27.8%;
   border-radius: 50px;
   background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
   opacity: 45%;
   }
   
   .mid3contentexp .bimg1text{
       width: 146px;
   height: 84px;
   font-size: 30px;
   font-weight: 900;
   line-height: 42px;
   letter-spacing: 0em;
   text-align: left;
   color:rgba(255, 255, 255, 1);
   opacity: 100%;
   position: absolute;
   /* display: block; */
   margin-top: -150px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   margin-left: 85px;
   
   }
   
   
   .imagecontaineraccess{
       position: relative;
   }
   
   .mid3contentexp .img2{
       width: 513px;
   height: 231px;
   margin-top: -40.5%;
   margin-left: 35%;
   margin-bottom: 40%;
   border-radius: 50px;
   /* opacity: 0.45px; */
   /* position: absolute; */
   
   }
   .mid3contentexp .bimg2{
       width: 513px;
   height: 231px;
   border-radius: 50px;
   opacity: 45%;
   position: absolute;
   margin-top: -40.8%;
   margin-left: -45.9%;
   background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
   
   }
   
   .bimg2textexp1{
       width: 355px;
   height: 42px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 30px;
   font-weight: 900;
   line-height: 42px;
   letter-spacing: 0em;
   text-align: left;
   color:rgba(255, 255, 255, 1);
   margin-top: -46%;
   margin-left: 420px;
   opacity: 100%;
   position: relative;
   display: block;
   }
   
   .imagecontainerauto{
       position: relative;
   }
   
   
   
     
   
   .title2tagexp{
       margin-top: 8px;
       width: 699px;
   height: 87px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 70px;
   font-weight: 400;
   line-height: 87px;
   letter-spacing: 0em;
   text-align: center;
   margin-left: 310px;
   margin-left: auto;
   margin-right: auto;
   }

   .title2tagexp2{
       margin-top: 8px;
       width: 1050px;
       height: 40px;
   font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
   margin-left: auto;
   margin-right: auto;
   }
   
   .mid3contentexp .img3{
       width: 768px;
   height: 212px;
   border-radius: 50px;
   margin-top: -20.5%;
   margin-left: 35%;
   /* opacity: 0.45px; */
   /* position: absolute; */
   
   }
   
   .mid3contentexp .bimg3{
       width: 768px;
   height: 212px;
   margin-top: -20.7%;
   margin-left: 35.3%;
   border-radius: 50px;
   opacity: 45%;
   position: absolute;
   background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
   }
   
   .mid3contentexp .bimg3text{
       width: 430px;
   height: 84px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 30px;
   font-weight: 900;
   line-height: 42px;
   letter-spacing: 0em;
   text-align: left;
   position: relative;
   margin-left: 38%;
   display: block;
   margin-top: -11%;
   color:rgba(255, 255, 255, 1);
   
   }
   
   .imagecontainerhire{
       position: relative;
   }
   
   
   .mid3contentexp .img4{
       width: 231px;
   height: 231px;
   border-radius: 50px;
   margin-top: -66%;
   margin-left: 82%;
   /* opacity: 45%px; */
   
   
   }

   .mid3contentexp{
       /* margin-left: -10%; */
   }
   
   .mid3contentexp .bimg4{
       width: 231px;
   height: 231px;
   border-radius: 50px;
   opacity: 45%;
   position: absolute;
   margin-left: 82.5%;
   margin-top: -44.4%;
   background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
   }
   
   .bimg4textexp1{
       width: 72px;
   height: 42px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 30px;
   font-weight: 900;
   line-height: 42px;
   letter-spacing: 0em;
   position: relative;
   display: block;
   text-align: left;
   color:rgba(255, 255, 255, 1);
   margin-top: -36%;
   margin-left: 84%;
   
   }
   
   
   
   
   .phonebox2{
       margin: 14%;
   }
   
   .phonetitle1tag2exp{
       margin-top: 100px;
       font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: center;
   width: 420px;
   height: 64px;
   border-radius: 32px;
   background-color: rgba(232, 237, 252, 1);
   }
   
   .phoneheadtitle2{
       /* margin-left: 120px; */
       width: 381px;
       height: 174px;
       font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 70px;
   font-weight: 400;
   line-height: 87px;
   letter-spacing: 0em;
   text-align: left;    
   }
   
   .phonebody2lap{
       width: 405px;
   height: 155px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: left;
   margin-top: 6%;
   margin-left: 0px;
   position: absolute;
   }
   
   .phone2{
       width: 351px;
   height: 644px;
   margin-top: -490px;
   margin-left: 80px;
   position: relative;
   }
   
   .orgcircle2{
       width: 240.93px;
   height: 240.93px;
   margin-top: -170px;
   border-radius: 100%;
   margin-left: 50px;
   /* background: rgba(247, 91, 2, 1); */
   position: relative;
   /* opacity: 90%; */
   }
   
   .viocircle2{
       width: 240.93px;
   height: 240.93px;
   border-radius: 100%;
   /* background: rgba(130, 27, 97, 1); */
   margin-top: -120px;
   position: relative;
   opacity: 90%;
   }
   
   .viocircle2::before {
     content: '';
     position: absolute;
     top: 0;
     left: 0;
     margin-left: -15%;
     width: 240.93px;
   height: 240.93px;
     border-radius: 50%;
     opacity: 100%;
   }
   
   .orgcircle2::before{
     content: '';
     position: absolute;
     top: 0;
     left: 0;
     width: 240.93px;
   height: 240.93px;
     border-radius: 50%;
     opacity: 90%;
   }
   
   .orgcircle2::before {
       background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
     }
     
     .viocircle2::before {
       background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
     }
   
   .bluecircle2{
       width: 364.7px;
   height: 364.7px;
   border-radius: 100%;
   background: rgba(135, 189, 255, 1);
   margin-top: -390px;
   margin-left: 150px;
   }
   
   .arrow2{
       width: 256.46px;
   height: 189.91px;
   margin-left: -230px;
   margin-top: -150px;
   }
   .image-phone-container2{
       margin-left: 500px;
       margin-top: -200px;
   }
   
   .phonetitles2{
       margin-left: -130px;
   }
   
   .phonebody2mob{
       display: none;
   }
   
   .trainingtitles{
       margin-top: -590px;
   }
   
   .traintitle1tagexp{
       width: 225px;
   height: 64px;
   border-radius: 32px;
   background: rgba(232, 237, 252, 1);
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: center;
   }
   
   .trainheadtitleexp{
       width: 542px;
   height: 87px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 70px;
   font-weight: 400;
   line-height: 87px;
   letter-spacing: 0em;
   text-align: left;
   position: relative;
   top: 130px;
   }

   .traincircleep{
       width: 500px;
   height: 500px;
   background: rgba(247, 91, 2, 0.1);
   border-radius: 100%;
   margin-left: -250px;
   position: relative;
   top: -100px;
   }
   
   .head12exp{
       width: 1075px;
   height: 105px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: left;
   position: relative;
   top: 130px;
   }
   
   .head13{
       margin-top: -80px;
       width: 1075px;
   height: 155px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: left;
   }

   .head121{
       display: none;
   }

   .head131{
       display: none;
   }
   
   .registerexp{
       width: 173px;
   height: 60px;
   border-radius: 37.5px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 20px;
   font-weight: 400;
   line-height: 25px;
   letter-spacing: 0em;
   text-align: center;
   margin-top: -100px;
   background: rgba(7, 60, 165, 1);
   border: none;
   margin-bottom: 0%;
   
   
   }
   
   .assesscircleep{
       width: 500px;
   height: 500px;
   background: rgba(9, 9, 63, 0.05);
   border-radius: 100%;
   margin-left: 1000px;
   margin-top: 15%;
   
   }
   
   .assesstitle1tagexp{
       margin-top: -570px;
       margin-left: 990px;
       width: 225px;
       height: 64px;
       border-radius: 37.5px;
       background: rgba(232, 237, 252, 1);
       font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: center;    
   }
   
   .assesheadtitleexp{
       width: 693px;
   height: 87px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 70px;
   font-weight: 400;
   line-height: 87px;
   letter-spacing: 0em;
   text-align: right;
   margin-left: 530px;
   position: relative;
   top: -340px;
   }
   
   .assesshead1exp{
       width: 1075px;
   height: 155px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   margin-left: 150px;
   text-align: right;
   position: relative;
   top: -340px;
   }
   
   .assesshead2exp{
       width: 1075px;
   height: 155px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   margin-left: 150px;
   text-align: right;
   margin-top: -50px;
   }
   
   .register2exp{
       width: 173px;
   height: 60px;
   border-radius: 37.5px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 20px;
   font-weight: 400;
   line-height: 25px;
   letter-spacing: 0em;
   text-align: center;
   margin-left: 1050px;
   margin-top: -100px;
   border: none;
   background: rgba(7, 60, 165, 1);
   }
   
   .chatexp{
       margin-top: 200px;
       position: relative;
       margin-top: -18%;
   }
   
   .chatitle1tag{
       width: 370px;
   height: 64px;
   border-radius: 32px;
   background: rgba(232, 237, 252, 1);
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: center;
   
   }
   
   .ctextexp{
       margin-left: auto;
       margin-right: auto;
       margin-bottom: auto;
       margin-top: auto;
   }
   
   .chatheadtitleexp{
       width: 651px;
   height: 174px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 70px;
   font-weight: 400;
   line-height: 87px;
   letter-spacing: 0em;
   text-align: left;
   }
   
   .chatimg1exp{
       width: 87px;
   height: 87px;
   }
   .chatbox111exp{
       clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
       width: 84.16px;
   height: 64px;
   background: rgb(231, 242, 255, 1);
   margin-left: -117%;
   margin-top: 0%;
   position: relative;
   }
   
   .chatbox11exp{
       width: 378px;
   height: 615px;
   border-radius: 0px 50px 50px 50px;
   background: rgba(231, 242, 255, 1);
   margin-left: 150px;
   margin-top: -80px;
   }
   
   .chatboxtext1{
       width: 370px;
   height: 529.92px;
   padding: 40px;
   font-size: 20px;
   font-weight: 400;
   line-height: 30px;
   letter-spacing: 0em;
   text-align: left;
   font-family: 'Plus Jakarta Sans', sans-serif;
   }
   .chatbox121exp{
       width: 81px;
   height: 64px;
   margin-top: -48%;
   margin-left: 63%;
   position: relative;
   background: rgba(207, 240, 230, 1);
   clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
   }
   
   
   .chatbox21exp{
       margin-left: -30px;
       width: 435px;
       height: 284px;
       border-radius: 50px 0px 50px 50px;
       background: rgba(207, 240, 230, 1);
   }
   
   .chatboxtext21{
       width: 365px;
   height: 150px;
       padding: 30px;
       font-size: 20px;
   font-weight: 400;
   line-height: 30px;
   letter-spacing: 0em;
   text-align: left;
   font-family: 'Plus Jakarta Sans', sans-serif;
   }
   
   .chatimg2exp{
       width: 87px;
   height: 87px;
   margin-left: 450px;
   margin-top: -40px;
   }
   
   .chatbox1331exp{
       width: 81px;
   height: 64px;
   background: rgba(207, 240, 230, 1);
   position: relative;
   margin-top: -34%;
   clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
   margin-left: 65%;
   }
   
   .chatbox331{
       padding: 10px;
       margin-top: 10%;
       width: 358px;
      margin-left: 10%;
   height: 201px;
   border-radius: 50px 0px 50px 50px;
   background: rgba(207, 240, 230, 1);
   }
   
   .chatboxtext31{
       padding: 20px;
       font-size: 20px;
   font-weight: 400;
   line-height: 30px;
   letter-spacing: 0em;
   text-align: left;
   font-family: 'Plus Jakarta Sans', sans-serif;
   }

   
   
   .chatimg33exp{
       
       margin-top: -25px;
       margin-left: 75%;
   }
   
   .chatimg4exp{
       width: 87px;
   height: 87px;
   margin-top: -480px;
   margin-left: -40px;
   }
   .chatbox151mobexp{
       display: none;
   }
   .chatbox141exp{
       width: 81px;
   height: 64px;
   background: rgba(231, 242, 255, 1);
   position: relative;
   margin-top: -2.2%;
   clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
   margin-left: -105%;
   }
   
   .chatbox41exp{
       padding: 10px;
       width: 475px;
   height: 220px;
   border-radius: 0px 50px 50px 50px;
   background: rgba(231, 242, 255, 1);
   margin-top: -300px;
   margin-left: 100px;
   }
   .chatboxtext41{
       width: 388px;
   height: 90px;
       padding: 20px;
   font-size: 20px;
   font-weight: 400;
   line-height: 30px;
   letter-spacing: 0em;
   text-align: left;
   font-family: 'Plus Jakarta Sans', sans-serif;
   
   }
   
   
   .chatimg5exp{
       width: 87px;
   height: 87px;
       margin-top: -150px;
       margin-left: -40px;
   }
   
   .chatbox51exp{
       padding: 10px;
       width: 455px;
   height: 200px;
   border-radius: 0px 50px 50px 50px;
   background: rgba(231, 242, 255, 1);
   margin-top: -150px;
   margin-left: 95px;
   }
   
   .chatbox151exp{
       width: 81px;
   height: 64px;
   background: rgba(231, 242, 255, 1);
   position: relative;
   margin-top: -2.3%;
   clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
   margin-left: -110%;
   }
   
   .chatboxtext51{
       padding: 20px;
       width: 399px;
   height: 194px;
   font-size: 20px;
   font-weight: 400;
   line-height: 30px;
   letter-spacing: 0em;
   text-align: left;
   font-family: 'Plus Jakarta Sans', sans-serif;
   }
   
   .startedexp{
       padding: 0 50px 0 0px;
   }
   .startedboxexp{
       margin-top: 180px;
       width: 1207px;
   height: 227px;
   background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
   border-radius: 25px;
   margin-bottom: 100px;
   margin-left: 3%;
   }
   
   .subbuttonexp{
       margin-left: 930px;
       margin-top: -230px;
       height: 50px;
       width: 175px;
       border-radius:30px;
       font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 20px;
   font-weight: 400;
   line-height: 19px;
   letter-spacing: 0em;
   background: rgb(255, 255, 255, 1);
   color: rgba(7, 60, 165, 1);
   border: solid rgba(255, 255, 255, 1);
   }
   
   .startheadexp{
       width: 1000px;
   height: 87px;
   margin-left: 60px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 50px;
   font-weight: 400;
   line-height: 87px;
   letter-spacing: 0em;
   text-align: left;
   color: rgba(255, 255, 255, 1);
   justify-content: center;
   align-items: center;
   margin-top: 3%;
   
   }
   
   .regisexp{
       width: 90px;
   height: 19px;
   margin-top: auto;
   margin-bottom: auto;
   margin-left: auto;
   margin-right: auto;
       font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 19px;
   letter-spacing: 0em;
   text-align: left;
   
   }
   
   .startparatab{
       width: 613px;
   height: 62px;
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-size: 25px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: left;
   color: rgba(255, 255, 255, 1);
   margin-left: 95px;
   margin-top: -2%;
   margin-bottom: 13%;
   }
   
   .blueca1{
       width: 54px;
   height: 54px;
   background: rgba(7, 60, 165, 1);
   margin-left: 380px;
   margin-top: 42%;
   border-radius: 100%;
   }

   .blueca1 img{
       margin-top: 20%;
       margin-left: 30%;
   }

   
   .whitea{
       width: 30px;
   height: 60px;
   }
   
   .namesm{
       width: 250px;
   height: 30px;
   font-size: 22px;
   font-weight: 600;
   line-height: 30px;
   letter-spacing: 0em;
   text-align: left;
   font-family: 'Plus Jakarta Sans', sans-serif;
   }
   
   .desgsm{
       width: 134px;
   height: 30px;
   font-size: 22px;
   font-style: italic;
   font-weight: 400;
   line-height: 30px;
   letter-spacing: 0em;
   text-align: left;
   font-family: 'Plus Jakarta Sans', sans-serif;
   margin-top: -30px;
   margin-left: 28%;
   }

   .register2tabexp{
       display: none;
   }

   
}


@media (min-width:1024.5px) and (max-width:1028px){
    .corevaluestab{
        display: none !important;
    }

    .title2tagexp{
        margin-top: 8px;
        width: 699px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 310px;
    margin-left: auto;
    margin-right: auto;
    }

    .title2tagexp2{
        margin-top: 8px;
        position: relative;
        left: -60px;
        width: 1050px;
        height: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 25px;
 font-weight: 400;
 line-height: 31px;
 text-align: center;
    margin-left: auto;
    margin-right: auto;
    }    

    .sechead1{
        position: relative;
        top: 0px;
    }
}


@media (min-width:831px) and (max-width:991.5px){
    .title2tagexp{
        width: 700px;
height: 87px;
position: relative;
top: -50px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 60px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
    }

    .title2tagexp2{
        position: relative;
        top: -40px;
        width: fit-content;
        height: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
font-weight: 400;
line-height: 40px;
text-align: center;
    margin-left: auto;
    margin-right: auto;
    }
    
    .brandppexp{
        position: relative;
        top: 40px;
        margin-left: auto;
        margin-right: auto;
        width: 533px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
     }

     .hireppexp{
        position: relative;
        top: 50px;
               margin-left: auto;
               margin-right: auto;
               width: 484px;
               height: 175px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        opacity: 80%;
        color: rgba(255, 255, 255, 1);
            }

     .digitalppexp{
        position: relative;
        top: 60px;
        margin-left: auto;
        margin-right: auto;
        width: 507px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
     }

     .digitalhexp{
        top: 20px;
position: relative;
        left: -47px;
        width: 500px;
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    }

    .trainppexp{
    position: relative;
    top: 60px;
        margin-left: auto;
        margin-right: auto;
        width: 522px;
        height: 175px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    opacity: 80%;
    color: rgba(255, 255, 255, 1);
     }

     .sechead1{
        position: relative;
        top: 120px;
    }
}

@media (min-width:992px) and (max-width:1024px){
    .title2tagexp{
        width: fit-content;
height: 87px;
margin-top: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
    }

    .title2tagexp2{
        margin-top: 25px;
        width: fit-content;
        height: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
font-weight: 400;
line-height: 31px;
text-align: center;
    margin-left: auto;
    margin-right: auto;
    }

    .brandppexp{
        margin-top: 8%;
        margin-left: auto;
        margin-right: auto;
        width: 706px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
     }

     .hireppexp{
        margin-top: 10%;
               margin-left: auto;
               margin-right: auto;
               width: 706px;
               height: 175px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        opacity: 80%;
        color: rgba(255, 255, 255, 1);
            }

         .digitalppexp{
 margin-top: 8.5%;
        margin-left: auto;
        margin-right: auto;
        width: 706px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
     }

     .digitalhexp{
        top: 15%;
position: relative;
        margin-left: 2.3%;
        width: 694px;
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    }

    .trainppexp{
        margin-top: 10%;
        margin-left: auto;
        margin-right: auto;
        width: 706px;
        height: 175px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    opacity: 80%;
    color: rgba(255, 255, 255, 1);
    }

    .sechead1{
        position: relative;
        top: 120px;
    }
}

@media (min-width:831px) and (max-width:1027px){

    .chatbox1exp:hover ,
.chatbox2exp:hover,
.extratestimonial:hover,
.chatbox4exp:hover,
.chatbox5exp:hover
{
    transform: none; /* Scale up the element on hover */
  }


     /* box click element start */

     .clickihv1exp{
        width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
    }

    .clickihv2exp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickihv3exp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clickhv1exp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clickhv2exp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickhv3exp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clichv1exp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clichv2exp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clichv3exp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clihv1exp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clihv2exp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clihv3exp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    /* box click elemet end */

    /* start of circle animation  */
    @keyframes moveUp {
        0% { transform: translateY(30px); }
        100% { transform: translateY(-10px); }
    }
    
    .orgcircle2, .viocircle2, .bluecircle2 {
        
        animation: none; /* Initially no animation */
    }
    
    .scrolling .orgcircle2 {
        animation: moveUp 0.8s forwards; /* Play animation once and stop */
    }
    
    .scrolling .viocircle2 {
        animation: moveUp 1.5s forwards; /* Slightly delay the animation */
    }
    
    .scrolling .bluecircle2 {
        animation: moveUp 2s forwards; /* Slightly delay the animation */
    }
    /* end of circle animation */

    /* start of boxes animation */

    .dancing-divvexp {
        opacity: 0;
        transform: translateY(-600px);
        transition: 1s ;
    }
      
      .dancing-divvexp.visible {
        opacity: 1;
        transform: translateY(0%);
        animation:  0.5s ease-in-out forwards ;
      }
      
      @keyframes shakeexp {
        0% { transform: translateX(0); }
        25% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
        75% { transform: translateX(-10px); }
        100% { transform: translateX(0); }
      }
      
      /* .shake {
        animation: shake 0.5s;
      } */
      

 
    .dancing-divexp {
        /* width: 100px;
        height: 100px;
        background-color: blue;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer; */
        transition: transform 0.5s ease; /* Smooth transition for the dancing effect */
      }
      
      .dancing-divexp.dancing {
        transform: translateY(-25px); /* Adjust the value as needed */
        animation: 60s ease-out alternate; /* Animation for the dancing effect */
      }
      
      @keyframes bounce {
        from {
          transform: translateY(-25px);
        }
        to {
          transform: translateY(0);
        }
      }

    /* end of boxes animation */

    .gifexp{
        position: absolute;
        top: -325px;
        left: 373px;

    }
    /* .title2tagexp2{
        margin-top: 25px;
        width: fit-content;
        height: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
font-weight: 400;
line-height: 31px;
text-align: center;
    margin-left: auto;
    margin-right: auto;
    } */
    
    
    .exluckcont{
        overflow: hidden;
        height: 60px;
    }

    .exluckcont div div{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  display: inline-block;
                  font-weight: 900;  
                  margin-bottom: 15px;  
    }

    .exluckcont div:nth-child(1){
        animation: animate 6s linear infinite;
        position: relative;
        top: -1px;
    }

    @keyframes animate {
        0%{margin-top: -240px;}
        5%{margin-top: -180px;}
        33%{margin-top: -180px;}
        38%{margin-top: -90px;}
        66%{margin-top: -90px;}
        71%{margin-top: 0px;}
        99.99%{margin-top: 0px;}
        100%{margin-top: 0px;}
    }

    .assesheadtitleexp{
        display: none;
    }

    /* .brandppexp{
        margin-top: 8%;
        margin-left: auto;
        margin-right: auto;
        width: 706px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
 
     } */

     .assesshead2tabexp{
        margin-top: -15%;
        width: 676px;
        height: 110px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-left: 28%;
    }
 
     /* .hireppexp{
     
 margin-top: 10%;
        margin-left: auto;
        margin-right: auto;
        width: 706px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
 
     }
 
     .digitalppexp{
 
 margin-top: 8.5%;
        margin-left: auto;
        margin-right: auto;
        width: 706px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
     }
 
     .trainppexp{
 margin-top: 10%;
        margin-left: auto;
        margin-right: auto;
        width: 706px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
 
     } */
 
     .brandhexp{
         position: relative;
         top: 15%;
         margin-left: 5%;
         font-family: 'Plus Jakarta Sans', sans-serif;
         font-size: 35px;
         font-weight: 900;
         line-height: 42px;
         letter-spacing: 0em;
         text-align: center;
     width: 536px;
 height: 42px;
     }
 
     .hirehexp{
         width: 516px;
 height: 42px;
 top: 19%;
 position: relative;
         margin-left: -1%;
         font-family: 'Plus Jakarta Sans', sans-serif;
         font-size: 35px;
         font-weight: 900;
         line-height: 42px;
         letter-spacing: 0em;
         text-align: center;
     }
 
     /* .digitalhexp{
         top: 15%;
 position: relative;
         margin-left: 2.3%;
         width: 694px;
 height: 42px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 35px;
         font-weight: 900;
         line-height: 42px;
         letter-spacing: 0em;
         text-align: center;
     } */
 
     .trainhexp{
         top: 15%;
 position: relative;
         margin-left: 4%;
 width: 426px;
 height: 24px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 35px;
         font-weight: 900;
         line-height: 42px;
         letter-spacing: 0em;
         text-align: center;
     }
 
 
 
     .brandexp{
         width: 84.2%;
         height: 306px;
         background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
     border-radius: 50px;
     margin-top: 15%;
     margin-bottom: 32px;
     margin-left: auto;
     margin-right: auto;
     }
     
     .hireexp{
        width: 84.2%;
 height: 307px;
 border-radius: 50px;
         background: linear-gradient(3600deg, #000000 26.68%, #073C9D 72.57%);
     border-radius: 50px;
     margin-bottom: 32px;
     /* margin-left: 8.5%; */
     margin-left: auto;
     margin-right: auto;
     }
     
     .digitalexp{
        width: 84.2%;
 height: 307px;
         border-radius: 50px;
         background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%); font-size: 20px;
         margin-bottom: 32px;
         font-family: 'Plus Jakarta Sans', sans-serif;
         /* margin-left: 8.5%; */
         margin-left: auto;
     margin-right: auto;
     }
     
     .trainexp{
        width: 84.2%;
 height: 307px;
         border-radius: 50px;
         background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
         margin-bottom: 40px;
         font-family: 'Plus Jakarta Sans', sans-serif;
         margin-left: auto;
     margin-right: auto;
     }

    

    .orgvector1{
        width: 284px;
height: 432px;
background: rgba(255, 188, 0, 1);
margin-left: 20%;
margin-top: 10%;

    }
    .luck1{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  display: inline-block;
                  font-weight: 900;
    
    }
    .arrow2{
        display: none;
    }
    .content11{
        width: 600px;
height: 259.3px;
position: absolute;
left: 50%;
transform: translate(-50%, -50%);
    margin-top: 160px;
      order: 2;
      font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 55px;
font-weight: 300;
line-height: 68px;
letter-spacing: 0em;
text-align: center;
      margin-bottom: 50px;
    
    }

    .imag-container1{
        position: relative;
        margin-top: 80%;
        margin-left: -140%;
    }

    .imag-container1 img{
        width: 354px;
height: 529px;
margin-top: 1.1%;
margin-left: -26%;
position: relative;

    }

    .background-container1{
        width: 284px;
height: 432px;
        margin-left: 0%;
    }
    
    .violetbox1{
        background: rgba(130, 27, 97, 1);
        width: 203px;
height: 203px;
        margin-top: -35%;
        margin-bottom: 100%;
        margin-left: -55%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;     
font-size: 10px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;

    }

    .vtexp1 {
        width: 91px;
height: 45px;
font-family: 'Plus Jakarta Sans', sans-serif; 
font-size: 36px;
font-weight: 400;
line-height: 45px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);
    }

    .vtexp2{
        width: 83px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif; 
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
margin-top: 33%;
margin-left: -43%;
color: rgba(255, 255, 255, 1);


    }
    

    .linebox1{
        width: 164px;
height: 164px;
border: 1px;
opacity: 0.1px;
margin-left: -50%;
 position: relative;
margin-top: -205%;
margin-bottom: 100%;
border: 1px solid rgba(0, 0, 0, 1);

    }

    .yellowbox1{
        background: #F75B02;
        width: 118px;
        height: 118px;
        margin-left: -60%;
        margin-top: -109%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;           
    }

    .ytexp1{
        width: 66px;
height: 44px;
margin-top: 16%;
margin-left: 15%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
    }

    .ytexp2{
    
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;

    }

    .blueca1{
        width: 54px;
height: 54px;
margin-top: -48%;
margin-left: 28%;
background: rgba(7, 60, 165, 1);
position: relative;
border-radius: 100%;
    }

    .blueca1 img{
        width: 24px;
height: 30px;
margin-top: 18%;
margin-left: 23%;

    }

    .signinexp{
        width: 173px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
display: flex;
justify-content: center;
margin-top: 30%;
margin-left: 50%;
text-align: center;
align-content: center;
background: rgba(7, 60, 165, 1);
border-radius: 37.5px;
border: none;
/* text-align: center; */

    }

    .siginsexp{
        padding-top:4%;
        padding-left: 0%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: right;
        color: rgba(255, 255, 255, 1);

    
    }

    .watchexp{
        width: 173px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
/* display: flex; */
/* justify-content: center; */
margin-left: 10%;
border-radius: 37.5px;
margin-top: 30%;
text-align: center;
background: rgba(255, 255, 255, 1);
border: 1px solid rgba(7, 60, 165, 1);
/* text-align: left; */
    }

    .watchs1{
        padding-top: 6%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        color:  rgba(7, 60, 165, 1);
    }

    .imgplaybutton{
        position: relative;
        margin-top: 0%;
        margin-left: -2%;
    }

    .linetextexp{
        width: 850px;
height: 75px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 15%; */
margin-left: auto;
margin-right: auto;
margin-top: 2%;
    }

    .mid3contentexp{
        display: none;
    }

    .title1tagexp{
        margin-top: 15%;
        width: 380px;
height: 64px;
        margin-left: auto;
        margin-right: auto;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1);
    }

    .ttextexp{
        width: 320px;
height: 31px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
    }

    .title2tagexp2g{
        height: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        margin-bottom: 0em;
    }

    /* .sechead1{
        position: relative;
        top: 120px;
    } */

    /* .title2tagexp{
        width: fit-content;
height: 87px;
margin-top: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
    } */


    .orgcircle2{
        width: 240.93px;
    height: 240.93px;
    margin-top: -170px;
    border-radius: 100%;
    margin-left: 50px;
    /* background: rgba(247, 91, 2, 1); */
    position: relative;
    /* opacity: 90%; */
    }
    
    .viocircle2{
        width: 240.93px;
    height: 240.93px;
    border-radius: 100%;
    /* background: rgba(130, 27, 97, 1); */
    margin-top: -120px;
    position: relative;
    opacity: 90%;
    }
    
    .viocircle2::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      margin-top: -160%;
      margin-left: 75%;
      width: 240.93px;
    height: 240.93px;
      border-radius: 50%;
      opacity: 100%;
    }
    
    .orgcircle2::before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      margin-top: -170%;
      margin-left: 100%;
      width: 240.93px;
    height: 240.93px;
      border-radius: 50%;
      opacity: 90%;
    }
    
    .orgcircle2::before {
        background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
      }
      
      .viocircle2::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }
    
    .bluecircle2{
        width: 364.7px;
    height: 364.7px;
    border-radius: 100%;
    background: rgba(135, 189, 255, 1);
    margin-top: -180%;
    margin-left: 80%;
    }

    .phone2{
        width: 314px;
height: 576px;
position: relative;
margin-top: -110%;
margin-left: 70%;

    }

    .phonetitle1tag2exp{
        margin-top: 30%;
        width: 430px;
height: 64px;
border-radius: 32px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
display: flex;
justify-content: center;
align-items: center;
margin-left: 50%;
background: rgba(232, 237, 252, 1);
    }

    .phoneheadtitle2{
        width: 600px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 75px;
letter-spacing: 0em;
text-align: center;
position: absolute;
left: 50%;
transform: translate(-50%, -50%);
color: rgba(0, 0, 0, 1);
margin-top: 5%;

    }

    .phonebody2lap{
        position: relative;
        width: 676px;
height: 75px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
top: 750px;
    margin-left: 25%;

    }

    .mid3contentexp{
        display: none;
    }

    .phonebody2mob{
        display: none;
    }

    .registertab{
        width: 173px;
height: 60px;
border-radius: 37.5px;
background: rgba(7, 60, 165, 1);
border: none;
margin-left: 84%;
        margin-top: 0%;
    }

    .register2exp{
        display: none;
    }



    .register2tabexp{
        margin-top: -14%;
        margin-left: 71%;
        width: 173px;
height: 60px;
border-radius: 37.5px;
border: none;
background: rgba(7, 60, 165, 1);



    }

    .traintitle1tagexp{
        width: 234px;
height: 64px;
border-radius: 32px;
margin-top: -105%;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    
margin-left: 78%;
position: relative;
    }

    .trainheadtitleexp{
        width: 564px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
margin-left: 43%;
margin-top: -86%;
    }

    .traincircle1exp{
        width: 478px;
height: 478px;
background: rgba(247, 91, 2, 0.1);
border-radius: 100%;
margin-left: -40%;
margin-top: 40%;
    }

    .head12exp{
        display: none;
    }

    .head121{
        width: 770px;
height: 170px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-top: 10%;
position: absolute;
        left: 50%;
    transform: translate(-50%, -50%);
/* margin-left: 30%; */

    }

    .assesshead1tab{
        width: 830px;
height: 170px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-top: 4%;
margin-left: 11%;

    }

    .head131{
        margin-top: -60px;
        width: 676px;
height: 90px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-left: 30%;

    }

    .registerexp{
        /* width: 173px;
height: 60px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align:center;
margin-left: 35%;
margin-top: 0%;
top: 3%;
position: relative;
left: 50%; */

display: none;

    }

    .assesscircletabep{
        width: 478px;
        height: 478px;
        margin-left: 2%;
        background: rgba(9, 9, 63, 0.05);
        border-radius: 100%;
        margin-left: 120%;
        margin-top: 43%;
    }

    .assesheadtitletab{
        width: 749px;
        height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: 22%;
        margin-top: -350px;
    }

    .assesstitle1tagexp{
        margin-top: -90%;
        margin-left: 65%;
        width: 234px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        position: relative;
        
    }

    .chatitle1tag{
        width: 400px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
margin-left: auto;
margin-right: auto;
margin-top: 12em;
    }

    .chatheadtitleexp{
        width: 749px;
height: 174px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
margin-left: 90px;
margin-bottom: -40px;

    }

    .chatbox11exp{
        width: 529px;
height: 602px;
border-radius: 0px 20px 20px 20px;
margin-top: 8%;
margin-left: 60%;
background: rgba(231, 242, 255, 1);
    }

    .chatbox21exp{
        background: rgba(207, 240, 230, 1);
        width: 529px;
height: 392px;
border-radius: 20px 20px 0px 20px;
margin-top: 150%;
margin-left: -80%;
position: relative;
    }

    .chatbox41exp{
        width: 529px;
height: 329px;
    border-radius: 0px 20px 20px 20px;
    background: rgba(231, 242, 255, 1);
    margin-top: -18%;
    margin-left: 60%;
    }

    .chatbox51exp{
        width: 529px;
height: 469px;
border-radius: 20px 20px 0px 20px;
margin-top: -418px;
background: rgba(207, 240, 230, 1);
margin-left: 30%;
    }
    .chatbox151tab{
        width: 127px;
    height: 97px;
    background: rgba(207, 240, 230, 1);
    position: relative;
    margin-top: -21%;
    clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100%);
    margin-left:140%;
    }

    .chatbox111tabexp{
        clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
        width: 127.72px;
    height: 97.12px;
    background: rgb(231, 242, 255, 1);
    margin-left: -107%;
    /* margin-top: -59.7%; */
    position: relative;
    }

    .extratestimonial{
        display: none;
    }

    .chatimg1exp{
        width: 83.46px;
height: 83.46px;
position: absolute;
margin-top: 7%;
margin-left: 9%;
    }

    .chatimg2tab{
        width: 83.46px;
height: 83.46px;
margin-top: -27%;
margin-left: 235px;
    }

    .chatimg4tab{
        width: 83.46px;
height: 83.46px;
/* position: absolute; */
margin-top:12%;
margin-left: 12%;

    }.chatimg5tab{
        width: 83.46px;
height: 83.46px;
margin-top: 90%;
margin-left: 155%;
    }

    .instlogoexp{
        /* width: 100px; */
        height: 100px;
    }

    .startedboxexp{
        width: 737px;
height: 252px;
border-radius: 25px;
background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
margin-left: 13%;
margin-top: 15%;
    }

    .startparatab{
        width: 612.49px;
height: 50px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-top: -22%;
margin-bottom: 20%;
margin-left: 21%;

    }

    .startheadexp{
        width: 510px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 32px;
font-weight: 400;
line-height: 40px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-left: auto;
margin-right: auto;
position: relative;
top: 30px;
    }

    .subbuttonexp{
        margin-top: -10%;
        position: absolute;
        margin-left: 300px;
        width: 331px;
height: 61px;
border-radius: 37.5px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    /* margin-bottom: 10%; */
    }

    .regisexp{
        width: 145.25px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 3%;
    }

    .assesshead2exp{
        display: none;
    }

    .ctextexp{
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
        width: 305px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;


    }

    .register1texp{
        padding-top: 4%;
        margin-left: 22%;
        width: 79px;
height: 25px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: right;


    }

    .assesshead1exp{
        display: none;
    }

    .chatimg2exp{
        display: none;
    }

    .chatimg4exp{
        display: none;
    }

    .chatimg5exp{
        display: none;
    }

    .image-phone-container2{
        margin-top: 160%;
    }

    .logo-slide img{
        height: 100px;
    }

    .logos-slide img{
        height: 100px;
    }


    
    .hlinebox1{
        width: 100px;
    height: 165.87px;
    border: 1px solid rgba(0, 0, 0, 1);
    opacity: 15%;
    margin-left: 20%;
    /* left: 10px; */
    position: absolute;
}

.hlinebox2{
    width: 40px;
height: 165.87px;
border: 1px solid rgba(0, 0, 0, 1);
/* opacity: 40%; */
/* left: 10px; */
margin-left: 30%;
}

.rlinebox1{
    margin-top: 22px;
    width: 165.87px;
height: 100px;
opacity: 30%;
top: 10px;
border: 1px solid rgba(0, 0, 0, 1);
position: absolute;
}
.rlinebox2{
    /* margin-top: 20px; */
    width: 165.87px;
height: 40px;
opacity: 30%;
border: 1px solid rgba(0, 0, 0, 1);
/* top: 10px; */
margin-top: 17%;
position: absolute;
}

.lineboxtextexp{
    width: 70.75px;
height: 49.75px;
margin-top: -60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 36px;
font-weight: 400;
line-height: 45px;
letter-spacing: 0em;
text-align: left;
top: 83px;
position: absolute;
left: 10px;
}

.lineboxtext2exp{
    width: 90.65px;
height: 21px;
    margin-top: 2px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}



    

}


@media (min-width:577px) and (max-width:767.5px) {
    .sechead1{
        position: relative;
        top: 70px;
    }

    .brandhexp{
        font-size: 30px;
        font-weight: 900;
        position: relative;
        line-height: 38px;
        top: 20px;
        left: 20px;
    }

    .hirehexp{
        font-size: 30px;
        font-weight: 900;
        position: relative;
        line-height: 38px;
        top: 30px;
        left: 20px;
    }

    .trainhexp{
        font-size: 30px;
        font-weight: 900;
        position: relative;
        line-height: 38px;
        top: 40px;
        left: 20px;
    }

    .digitalhexp{
        font-size: 30px;
        font-weight: 900;
        position: relative;
        line-height: 38px;
        top: 30px;
        left: 20px;
    }

    .digitalppexp{
 
        margin-top: 10%;
               margin-left: auto;
               margin-right: auto;
               width: 90%;
               height: 175px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        opacity: 80%;
        color: rgba(255, 255, 255, 1);
            }
}

@media (min-width:768px) and (max-width:830px) {
    .sechead1{
        position: relative;
        top: 120px;
    }

    .brandhexp{
        position: relative;
        top: 15%;
        margin-left: 5%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    width: 536px;
height: 42px;
    }

    .hirehexp{
        width: 516px;
height: 42px;
top: 19%;
position: relative;
        margin-left: 5%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;
    }

    .trainhexp{
        top: 15%;
position: relative;
        margin-left: 4%;
width: 426px;
height: 24px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    }

    .digitalhexp{
        top: 15%;
position: relative;
        margin-left: 5%;
        width: 56%;
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    }

    .digitalppexp{
 
        margin-top: 16%;
               margin-left: auto;
               margin-right: auto;
               width: 90%;
               height: 175px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        opacity: 80%;
        color: rgba(255, 255, 255, 1);
            }
}


@media (min-width:577px) and (max-width:830px){

    .chatbox1exp:hover ,
.chatbox2exp:hover,
.extratestimonial:hover,
.chatbox4exp:hover,
.chatbox5exp:hover
{
    transform: none; /* Scale up the element on hover */
  }


     /* box click element start */

     .clickihv1exp{
        width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
    }

    .clickihv2exp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickihv3exp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clickhv1exp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clickhv2exp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickhv3exp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clichv1exp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clichv2exp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clichv3exp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clihv1exp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clihv2exp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clihv3exp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    /* box click elemet end */

    /* start of circle animation  */
    @keyframes moveUp {
        0% { transform: translateY(30px); }
        100% { transform: translateY(-10px); }
    }
    
    .orgcircle2, .viocircle2, .bluecircle2 {
        
        animation: none; /* Initially no animation */
    }
    
    .scrolling .orgcircle2 {
        animation: moveUp 0.8s forwards; /* Play animation once and stop */
    }
    
    .scrolling .viocircle2 {
        animation: moveUp 1.5s forwards; /* Slightly delay the animation */
    }
    
    .scrolling .bluecircle2 {
        animation: moveUp 2s forwards; /* Slightly delay the animation */
    }
    /* end of circle animation */

    /* start of boxes animation */

    .dancing-divvexp {
        opacity: 0;
        transform: translateY(-600px);
        transition: 1s ;
    }
      
      .dancing-divvexp.visible {
        opacity: 1;
        transform: translateY(0%);
        animation:  0.5s ease-in-out forwards ;
      }
      
      @keyframes shakeexp {
        0% { transform: translateX(0); }
        25% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
        75% { transform: translateX(-10px); }
        100% { transform: translateX(0); }
      }
      
      /* .shake {
        animation: shake 0.5s;
      } */
      

 
    .dancing-divexp {
        /* width: 100px;
        height: 100px;
        background-color: blue;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer; */
        transition: transform 0.5s ease; /* Smooth transition for the dancing effect */
      }
      
      .dancing-divexp.dancing {
        transform: translateY(-25px); /* Adjust the value as needed */
        animation: 60s ease-out alternate; /* Animation for the dancing effect */
      }
      
      @keyframes bounce {
        from {
          transform: translateY(-25px);
        }
        to {
          transform: translateY(0);
        }
      }

    /* end of boxes animation */

    .gifexp{
        position: absolute;
        top: -300px;
        left: 255px;

    }
    .title2tagexp2{
        margin-top: 10px;
        width: fit-content;
        height: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
    margin-left: auto;
    margin-right: auto;
    }
    
    
    .exluckcont{
        overflow: hidden;
        height: 60px;
    }

    .exluckcont div div{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  display: inline-block;
                  font-weight: 900;  
                  margin-bottom: 15px;  
    }

    .exluckcont div:nth-child(1){
        animation: animate 6s linear infinite;
        position: relative;
        top: -1px;
    }

    @keyframes animate {
        0%{margin-top: -240px;}
        5%{margin-top: -180px;}
        33%{margin-top: -180px;}
        38%{margin-top: -90px;}
        66%{margin-top: -90px;}
        71%{margin-top: 0px;}
        99.99%{margin-top: 0px;}
        100%{margin-top: 0px;}
    }

    .assesheadtitleexp{
        display: none;
    }

    .brandppexp{
        margin-top: 8%;
        /* margin-left: 10%; */
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
 
     }

     .assesshead2tabexp{
        margin-top: -15%;
        width: 676px;
        height: 110px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-left: 28%;
    }
 
     .hireppexp{
     
 margin-top: 10%;
        /* margin-left: 10%; */
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
 
     }
 
     /* .digitalppexp{
 
 margin-top: 16%;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
     } */
 
     .trainppexp{
         /* padding-top: 9%;
         width: 365px;
 height: 165px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 12px;
 font-weight: 400;
 line-height: 22px;
 letter-spacing: 0em;
 text-align: left;
 padding-left: 10px; */
 
 margin-top: 12%;
        /* margin-left: 10%; */
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
 
     }
 
     /* .brandhexp{
         position: relative;
         top: 15%;
         margin-left: 5%;
         font-family: 'Plus Jakarta Sans', sans-serif;
         font-size: 35px;
         font-weight: 900;
         line-height: 42px;
         letter-spacing: 0em;
         text-align: center;
     width: 536px;
 height: 42px;
     }
 
     .hirehexp{
         width: 516px;
 height: 42px;
 top: 19%;
 position: relative;
         margin-left: 5%;
         font-family: 'Plus Jakarta Sans', sans-serif;
         font-size: 35px;
         font-weight: 900;
         line-height: 42px;
         letter-spacing: 0em;
         text-align: left;
     }
 
     .digitalhexp{
         top: 15%;
 position: relative;
         margin-left: 5%;
         width: 56%;
 height: 42px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 35px;
         font-weight: 900;
         line-height: 42px;
         letter-spacing: 0em;
         text-align: center;
     }
 
     .trainhexp{
         top: 15%;
 position: relative;
         margin-left: 4%;
 width: 426px;
 height: 24px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 35px;
         font-weight: 900;
         line-height: 42px;
         letter-spacing: 0em;
         text-align: center;
     } */
 
 
 
     .brandexp{
         width: 84.2%;
         height: 306px;
         background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
     border-radius: 50px;
     margin-top: 17%;
     margin-bottom: 32px;
     margin-left: auto;
     margin-right: auto;
     }
     
     .hireexp{
        width: 84.2%;
 height: 307px;
 border-radius: 50px;
         background: linear-gradient(3600deg, #000000 26.68%, #073C9D 72.57%);
     border-radius: 50px;
     margin-bottom: 32px;
     /* margin-left: 8.5%; */
     margin-left: auto;
     margin-right: auto;
     }
     
     .digitalexp{
        width: 84.2%;
 height: 307px;
         border-radius: 50px;
         background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%); font-size: 20px;
         margin-bottom: 32px;
         font-family: 'Plus Jakarta Sans', sans-serif;
         /* margin-left: 8.5%; */
         margin-left: auto;
     margin-right: auto;
     }
     
     .trainexp{
        width: 84.2%;
 height: 307px;
         border-radius: 50px;
         background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
         margin-bottom: 40px;
         font-family: 'Plus Jakarta Sans', sans-serif;
         margin-left: auto;
     margin-right: auto;
     }

    

    .orgvector1{
        width: 284px;
height: 432px;
background: rgba(255, 188, 0, 1);
margin-left: 17%;
margin-top: 20%;

    }
    .luck1{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  display: inline-block;
                  font-weight: 900;
    
    }
    .arrow2{
        display: none;
    }
    .content11{
        width: 600px;
height: 259.3px;
position: absolute;
left: 50%;
transform: translate(-50%, -50%);
    margin-top: 160px;
      order: 2;
      font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 55px;
font-weight: 300;
line-height: 68px;
letter-spacing: 0em;
text-align: center;
      margin-bottom: 50px;
    
    }

    .imag-container1{
        position: relative;
        margin-top: 80%;
        margin-left: -150%;
    }

    .imag-container1 img{
        width: 354px;
height: 529px;
margin-top: 8.3%;
margin-left: -35%;
position: relative;

    }

    .background-container1{
        width: 284px;
height: 432px;
        margin-left: 0%;
    }
    
    .violetbox1{
        background: rgba(130, 27, 97, 1);
        width: 203px;
height: 203px;
        margin-top: -35%;
        margin-bottom: 100%;
        margin-left: -60%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;     
font-size: 10px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;

    }

    .vtexp1 {
        width: 91px;
height: 45px;
font-family: 'Plus Jakarta Sans', sans-serif; 
font-size: 36px;
font-weight: 400;
line-height: 45px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);
    }

    .vtexp2{
        width: 83px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif; 
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
margin-top: 33%;
margin-left: -43%;
color: rgba(255, 255, 255, 1);


    }
    

    .linebox1{
        width: 164px;
height: 164px;
border: 1px;
opacity: 0.1px;
margin-left: -60%;
 position: relative;
margin-top: -256%;
margin-bottom: 160%;
border: 1px solid rgba(0, 0, 0, 1);

    }

    .yellowbox1{
        background: #F75B02;
        width: 118px;
        height: 118px;
        margin-left: -75%;
        margin-top: -179%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;           
    }

    .ytexp1{
        width: 66px;
height: 44px;
margin-top: 16%;
margin-left: 15%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
    }

    .ytexp2{
    
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;

    }

    .blueca1{
        width: 54px;
height: 54px;
margin-top: -86%;
margin-left: 50%;
background: rgba(7, 60, 165, 1);
position: relative;
border-radius: 100%;
    }

    .blueca1 img{
        width: 24px;
height: 30px;
margin-top: 18%;
margin-left: 23%;

    }

    .signinexp{
        width: 173px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
display: flex;
justify-content: center;
margin-top: 30%;
margin-left: 50%;
text-align: center;
align-content: center;
background: rgba(7, 60, 165, 1);
border-radius: 37.5px;
border: none;
/* text-align: center; */

    }

    .siginsexp{
        padding-top:4%;
        padding-left: 0%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: right;
        color: rgba(255, 255, 255, 1);

    
    }

    .watchexp{
        width: 173px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
/* display: flex; */
/* justify-content: center; */
margin-left: 10%;
border-radius: 37.5px;
margin-top: 30%;
text-align: center;
background: rgba(255, 255, 255, 1);
border: 1px solid rgba(7, 60, 165, 1);
/* text-align: left; */
    }

    .watchs1{
        padding-top: 6%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        color:  rgba(7, 60, 165, 1);
    }

    .imgplaybutton{
        position: relative;
        margin-top: 0%;
        margin-left: -2%;
    }

    .linetextexp{
        width: 87%;
height: 75px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 15%; */
margin-left: auto;
margin-right: auto;
margin-top: 2%;
    }

    .mid3contentexp{
        display: none;
    }

    .title1tagexp{
        margin-top: 15%;
        width: 380px;
height: 64px;
        margin-left: auto;
        margin-right: auto;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1);
    }

    .ttextexp{
        width: 320px;
height: 31px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
    }

    .title2tagexp2g{
        height: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        margin-bottom: 0em;
    }

    /* .sechead1{
        position: relative;
        top: 120px;
    } */

    .title2tagexp{
        width: fit-content;
height: 87px;
margin-top: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 50px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
    }


    .orgcircle2{
        width: 240.93px;
    height: 240.93px;
    margin-top: -170px;
    border-radius: 100%;
    margin-left: 50px;
    /* background: rgba(247, 91, 2, 1); */
    position: relative;
    /* opacity: 90%; */
    }
    
    .viocircle2{
        width: 240.93px;
    height: 240.93px;
    border-radius: 100%;
    /* background: rgba(130, 27, 97, 1); */
    margin-top: -120px;
    position: relative;
    opacity: 90%;
    }
    
    .viocircle2::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      margin-top: -50%;
      margin-left: 30%;
      width: 240.93px;
    height: 240.93px;
      border-radius: 50%;
      opacity: 100%;
    }
    
    .orgcircle2::before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      margin-top: -56%;
      margin-left: 50%;
      width: 240.93px;
    height: 240.93px;
      border-radius: 50%;
      opacity: 90%;
    }
    
    .orgcircle2::before {
        background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
      }
      
      .viocircle2::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }
    
    .bluecircle2{
        width: 364.7px;
    height: 364.7px;
    border-radius: 100%;
    background: rgba(135, 189, 255, 1);
    margin-top: -165%;
    margin-left: 95%;
    }

    .phone2{
        width: 314px;
height: 576px;
position: relative;
margin-top: -139%;
margin-left: 60%;

    }

    .phonetitle1tag2exp{
        margin-top: 30%;
        width: 430px;
height: 64px;
border-radius: 32px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
display: flex;
justify-content: center;
align-items: center;
margin-left: 35%;
background: rgba(232, 237, 252, 1);
    }

    .phoneheadtitle2{
        width: 600px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 75px;
letter-spacing: 0em;
text-align: center;
position: absolute;
left: 50%;
transform: translate(-50%, -50%);
color: rgba(0, 0, 0, 1);
margin-top: 5%;

    }

    .phonebody2lap{
        position: relative;
        width: 676px;
height: 75px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
top: 815px;
    margin-left: 8%;

    }

    .mid3contentexp{
        display: none;
    }

    .phonebody2mob{
        display: none;
    }

    .registertab{
        width: 173px;
height: 60px;
border-radius: 37.5px;
background: rgba(7, 60, 165, 1);
border: none;
margin-left: 84%;
        margin-top: 0%;
    }

    .register2exp{
        display: none;
    }



    .register2tabexp{
        margin-top: -14%;
        margin-left: 71%;
        width: 173px;
height: 60px;
border-radius: 37.5px;
border: none;
background: rgba(7, 60, 165, 1);



    }

    .traintitle1tagexp{
        width: 234px;
height: 64px;
border-radius: 32px;
margin-top: -105%;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    
margin-left: 78%;
position: relative;
    }

    .trainheadtitleexp{
        width: 564px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
margin-left: 28%;
margin-top: -102%;
    }

    .traincircle1exp{
        width: 478px;
height: 478px;
background: rgba(247, 91, 2, 0.1);
border-radius: 100%;
margin-left: -60%;
margin-top: 35%;
    }

    .head12exp{
        display: none;
    }

    .head121{
        width: 81%;
height: 170px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-top: 10%;
position: absolute;
        left: 50%;
    transform: translate(-50%, -50%);
/* margin-left: 30%; */

    }

    .assesshead1tab{
        width: 170%;
height: 170px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-top: 4%;
margin-left: 4%;

    }

    .head131{
        margin-top: -60px;
        width: 676px;
height: 90px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-left: 30%;

    }

    .registerexp{
        /* width: 173px;
height: 60px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align:center;
margin-left: 35%;
margin-top: 0%;
top: 3%;
position: relative;
left: 50%; */

display: none;

    }

    .assesscircletabep{
        width: 478px;
        height: 478px;
        margin-left: 2%;
        background: rgba(9, 9, 63, 0.05);
        border-radius: 100%;
        margin-left: 120%;
        margin-top: 43%;
    }

    .assesheadtitletab{
        width: 749px;
        height: 87px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 70px;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 0em;
        text-align: center;
        /* margin-left: 22%; */
        margin-top: -350px;
    }

    .assesstitle1tagexp{
        margin-top: -90%;
        margin-left: 65%;
        width: 234px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        position: relative;
        
    }

    .chatitle1tag{
        width: 400px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
margin-left: auto;
margin-right: auto;
margin-top: 12em;
    }

    .chatheadtitleexp{
        width: 749px;
height: 174px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 90px; */
margin-bottom: -40px;

    }

    .chatbox11exp{
        width: 529px;
height: 602px;
border-radius: 0px 20px 20px 20px;
margin-top: 8%;
margin-left: 60%;
background: rgba(231, 242, 255, 1);
    }

    .chatbox21exp{
        background: rgba(207, 240, 230, 1);
        width: 529px;
height: 392px;
border-radius: 20px 20px 0px 20px;
margin-top: 150%;
margin-left: -80%;
position: relative;
    }

    .chatbox41exp{
        width: 529px;
height: 329px;
    border-radius: 0px 20px 20px 20px;
    background: rgba(231, 242, 255, 1);
    margin-top: -18%;
    margin-left: 60%;
    }

    .chatbox51exp{
        width: 529px;
height: 469px;
border-radius: 20px 20px 0px 20px;
margin-top: -418px;
background: rgba(207, 240, 230, 1);
margin-left: 30%;
    }
    .chatbox151tab{
        width: 127px;
    height: 97px;
    background: rgba(207, 240, 230, 1);
    position: relative;
    margin-top: -21%;
    clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100%);
    margin-left:140%;
    }

    .chatbox111tabexp{
        clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
        width: 127.72px;
    height: 97.12px;
    background: rgb(231, 242, 255, 1);
    margin-left: -107%;
    /* margin-top: -59.7%; */
    position: relative;
    }

    .extratestimonial{
        display: none;
    }

    .chatimg1exp{
        width: 83.46px;
height: 83.46px;
position: absolute;
margin-top: 7%;
margin-left: 9%;
    }

    .chatimg2tab{
        width: 83.46px;
height: 83.46px;
margin-top: -27%;
margin-left: 235px;
    }

    .chatimg4tab{
        width: 83.46px;
height: 83.46px;
/* position: absolute; */
margin-top:12%;
margin-left: 12%;

    }.chatimg5tab{
        width: 83.46px;
height: 83.46px;
margin-top: 90%;
margin-left: 155%;
    }

    .instlogoexp{
        /* width: 100px; */
        height: 100px;
    }

    .startedboxexp{
        width: 120%;
height: 252px;
border-radius: 25px;
background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
/* margin-left: 13%; */
margin-top: 15%;
    }

    .startparatab{
        width: 612.49px;
height: 50px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-top: -28%;
margin-bottom: 20%;
margin-left: 7%;

    }

    .startheadexp{
        width: 510px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 32px;
font-weight: 400;
line-height: 40px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-left: auto;
margin-right: auto;
position: relative;
top: 30px;
    }

    .subbuttonexp{
        margin-top: -10%;
        position: absolute;
        margin-left: 175px;
        width: 331px;
height: 61px;
border-radius: 37.5px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    /* margin-bottom: 10%; */
    }

    .regisexp{
        width: 145.25px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 3%;
    }

    .assesshead2exp{
        display: none;
    }

    .ctextexp{
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
        width: 305px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;


    }

    .register1texp{
        padding-top: 4%;
        margin-left: 22%;
        width: 79px;
height: 25px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: right;


    }

    .assesshead1exp{
        display: none;
    }

    .chatimg2exp{
        display: none;
    }

    .chatimg4exp{
        display: none;
    }

    .chatimg5exp{
        display: none;
    }

    .image-phone-container2{
        margin-top: 160%;
    }

    .logo-slide img{
        height: 100px;
    }

    .logos-slide img{
        height: 100px;
    }


    
    .hlinebox1{
        width: 100px;
    height: 165.87px;
    border: 1px solid rgba(0, 0, 0, 1);
    opacity: 15%;
    margin-left: 20%;
    /* left: 10px; */
    position: absolute;
}

.hlinebox2{
    width: 40px;
height: 165.87px;
border: 1px solid rgba(0, 0, 0, 1);
/* opacity: 40%; */
/* left: 10px; */
margin-left: 30%;
}

.rlinebox1{
    margin-top: 22px;
    width: 165.87px;
height: 100px;
opacity: 30%;
top: 10px;
border: 1px solid rgba(0, 0, 0, 1);
position: absolute;
}
.rlinebox2{
    /* margin-top: 20px; */
    width: 165.87px;
height: 40px;
opacity: 30%;
border: 1px solid rgba(0, 0, 0, 1);
/* top: 10px; */
margin-top: 17%;
position: absolute;
}

.lineboxtextexp{
    width: 70.75px;
height: 49.75px;
margin-top: -60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 36px;
font-weight: 400;
line-height: 45px;
letter-spacing: 0em;
text-align: left;
top: 83px;
position: absolute;
left: 10px;
}

.lineboxtext2exp{
    width: 90.65px;
height: 21px;
    margin-top: 2px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}



    

}
@media (max-width: 576px) {

    .chatbox1exp:hover ,
    .chatbox2exp:hover,
    .extratestimonial:hover,
    .chatbox4exp:hover,
    .chatbox5exp:hover
    {
        transform: none; /* Scale up the element on hover */
      }
    

    .chatimg4tab{
        display: none;
    }

    .chatimg5tab{
        display: none;
    }
    .breakhideinmob{
        display: none;
    }
    
    /* start of circle animation  */
    @keyframes moveUp {
        0% { transform: translateY(30px); }
        100% { transform: translateY(-10px); }
    }
    
    .orgcircle2, .viocircle2, .bluecircle2 {
        
        animation: none; /* Initially no animation */
    }
    
    .scrolling .orgcircle2 {
        animation: moveUp 0.8s forwards; /* Play animation once and stop */
    }
    
    .scrolling .viocircle2 {
        animation: moveUp 1.5s forwards; /* Slightly delay the animation */
    }
    
    .scrolling .bluecircle2 {
        animation: moveUp 2s forwards; /* Slightly delay the animation */
    }
    /* end of circle animation */

    /* start of boxes animation */

    .dancing-divvexp {
        opacity: 0;
        transform: translateY(-400px);
        transition: 1s ;
    }
      
      .dancing-divvexp.visible {
        opacity: 1;
        transform: translateY(0%);
        animation:  0.5s ease-in-out forwards ;
      }
      
      @keyframes shakeexp {
        0% { transform: translateX(0); }
        25% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
        75% { transform: translateX(-10px); }
        100% { transform: translateX(0); }
      }
      
      /* .shake {
        animation: shake 0.5s;
      } */
      

 
    .dancing-divexp {
        /* width: 100px;
        height: 100px;
        background-color: blue;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer; */
        transition: transform 0.5s ease; /* Smooth transition for the dancing effect */
      }
      
      .dancing-divexp.dancing {
        transform: translateY(-25px); /* Adjust the value as needed */
        animation: 60s ease-out alternate; /* Animation for the dancing effect */
      }
      
      @keyframes bounce {
        from {
          transform: translateY(-25px);
        }
        to {
          transform: translateY(0);
        }
      }

    /* end of boxes animation */


    .gifexp{
        position: absolute;
        height: 100px;
        top: -140px;
        left: 145px;

    }

    .logo-slide img{
        height: 50px;
    }

    .logos-slide img{
        height: 50px;
    }


    .exluckcont{
        overflow: hidden;
        height: 60px;
    }

    .exluckcont div div{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  display: inline-block;
                  font-weight: 900;  
                  margin-bottom: 25px;  
    }

    .exluckcont div:nth-child(1){
        animation: animate 6s linear infinite;
        position: relative;
        top: 1px;
    }

    @keyframes animate {
        0%{margin-top: -240px;}
        5%{margin-top: -180px;}
        33%{margin-top: -180px;}
        38%{margin-top: -90px;}
        66%{margin-top: -90px;}
        71%{margin-top: 0px;}
        99.99%{margin-top: 0px;}
        100%{margin-top: 0px;}
    }

    /* .instlogoexp{
        width: 100px;
        height: 100px;
    } */

    .registertab{
        display: none;
    }

    .assesshead1tab{
        display: none;
    }

    .assesshead2tabexp{
        display: none;
    }


    .register2tabexp{
        display: none;
    }


   
    
    .head121{
        display: none;
    }

    .head131{
        display: none;
    }

    .arrow2{
        display: none;
    }

    html,body{

        overflow-x: hidden;
    }

    .namesm{
        width: 250px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 600;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
    }

    .desgsm{
        width: 134px;
height: 30px;
font-size: 15px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-style: italic;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
margin-top: -30px;
margin-left: 19%;
    }

    .brandppexp{
position: absolute;
margin-left: 35px;
width: 260px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);
    }

    .hireppexp{
        position: absolute;
        margin-left: 35px;
width: 260px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);
    }

    .digitalppexp{
        position: absolute;
        margin-left: 35px;
width: 260px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);
    }

    .trainppexp{
        position: absolute;
        margin-left: 35px;
width: 260px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);

    }

    /* .brandh{
        padding: 2%;
        width: 187px;
height: 24px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;

    }

    .hireh{
        width: 42px;
height: 24px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;
    }

    .digitalh{
        padding: 1%;
        width: 290px;
height: 48px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 900;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
    }

    .trainh{
width: 259px;
height: 24px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 900;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
    } */


    .brandhexp{
        position: relative;
        top: 18%;
        width: 250px;
        height: 24px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 35px;
    margin-bottom: 4.2em;
    }

    .hirehexp{
        width: 248px;
height: 24px;
top: 18%;
position: relative;
text-align: left;
margin-left: 35px;
margin-bottom: 3em;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;
    }

    .digitalhexp{
        top: 18%;
position: relative;
text-align: left;
margin-left: 35px;
margin-bottom: 3em;
        width: 290px;
height: 48px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 900;
line-height: 24px;
letter-spacing: 0em;
    }

    .trainhexp{
        top: 18%;
position: relative;
    margin-left: 35px;
    margin-bottom: 3em;
width: 259px;
height: 24px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 900;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
    }


    .brandexp{
        width: 334px;
    height: 300px;
        background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
    /* text-align: center; */
    border-radius: 50px;
    margin-bottom: 18px;
    margin-top: -15%;
    margin-left: auto;
    margin-right: auto;
    }
    
    .hireexp{
        width: 334px;
    height: 300px;
        background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
    border-radius: 50px;
    margin-bottom: 18px;
    margin-left: auto;
    margin-right: auto;
    }
    
    .digitalexp{
        width: 334px;
    height: 300px;
        border-radius: 50px;
        background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%); font-size: 20px;
        margin-bottom: 18px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: auto;
    margin-right: auto;
    }
    
    .trainexp{
        width: 334px;
    height: 300px;
        border-radius: 50px;
        background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
        margin-bottom: 100px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        /* margin-left: 2.5%; */
        margin-left: auto;
    margin-right: auto;
    }

    .regisexp{
        font-family: 'Plus Jakarta Sans', sans-serif;
        color: rgba(7, 60, 165, 1);
        width: 90px;
height: 19px;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
margin-top: auto;
margin-bottom: auto;
margin-left: auto;
margin-right: auto;
    }

    .blueca1{
        width: 32.91px;
height: 32.91px;
margin-top: -142%;
margin-left: 72%;
position: relative;
background: rgba(7, 60, 165, 1);
border-radius: 100%;

    }

    .blueca1 img {
        width: 14.63px;
height: 18.28px;
margin-top: 20%;
margin-left: 27%;
    }

    .whitea{
        width: 100px;
        height: 100px;
    }

    .startparaexp{
        width: 330px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-top: -50%;
margin-left: 8%;
position: relative;


    }

    .startparatab{
        display: none;
    }

    .startheadexp{
        width: 405px;
height: 31px;
margin-top: 15px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
color: rgb(255, 255, 255, 1);
    }

    .subbuttonexp{
        margin-top: -10px;
        position: relative;
        margin-left: 122px;
        height: 42px;
        width: 137px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }

    .startedboxexp{
        margin-top: 50px;
        /* margin-left: -30px; */
        width: 100%;
    height: 170px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 100px;
    
    }

    .imag-container1 img{
        width: 198px;
height: 297px;
    }

    .chatboxtext51{
        width: 240px;
height: 180px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;



    }

    .chatbox51exp{
        width: 254px;
height: 170px;
border-radius: 20px 20px 0px 20px;
margin-top: 26px;
background: rgba(207, 240, 230, 1);
margin-left: 13%;

    }


    .chatimg5exp{
        width: 40.11px;
height: 40.11px;
margin-left: 337px;
margin-top: 144px;
position: absolute;
    }
    

    .chatboxtext41{
        width: 230px;
height: 108px;
padding: 20px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
    }

    .chatbox41exp{
        width: 250px;
    height: 178px;
    border-radius: 0px 20px 20px 20px;
    background: rgba(231, 242, 255, 1);
    margin-top: 0px;
    margin-left: 30%;
   
    }

    .chatimg4exp{
        width: 40.11px;
        height: 40.11px;
        margin-left: 45px;
        margin-top: 1%; 
       position: absolute;
    }

    .chatboxtext21{
        padding: 30px;
        width: 290px;
height: 144px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;


    }

    .chatbox21exp{
        background: rgba(207, 240, 230, 1);
        width: 290px;
height: 200px;
border-radius: 20px 20px 0px 20px;
margin-top: 10px;
margin-left: 10px;
    }

    .chatimg2exp{
        width: 40px;
height: 40px;
margin-top: -90px;
margin-left: 320px;

    }


    .chatboxtext1{
        padding: 23px;
        width: 250px;
height: 245.15px;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;

    font-family: 'Plus Jakarta Sans', sans-serif;
    }

    .chatbox11exp{
        width: 254px;
height: 325px;
border-radius: 0px 20px 20px 20px;
margin-top: 7%;
margin-left: 35%;
background: rgba(231, 242, 255, 1);


    }
    
    .chatimg1exp{
        width: 40px;
height: 40px;
position: absolute;
margin-top: 7%;
margin-left: 60px;

    }

    .chatexp{
        margin-top: 50px;
    }


    .chatheadtitleexp{
        width: 244px;
height: 62px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: 90px;
margin-bottom: -40px;

    }

    .chatitle1tag{
        width: 190px;
height: 34px;
border-radius: 37.5px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 30px;
    }

    .ctextexp{
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
    }
    

    .register2exp{
        width: 137px;
height: 42px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
position: relative;
/* margin-top: -200px; */
margin-left: 140px;
top: -13px;
margin-bottom: 16%;
background: rgba(7, 60, 165, 1);
border: none;
    }


    .assesshead1exp{
        width: 87%;
height: 85px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;
margin-bottom: 85px;
    }
    .assesshead2exp{
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;
margin-top: -12%;
    }
    .assesheadtitleexp{
        width: 248px;
        height: 31px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: 80px;
margin-top: -190px;
    }

.assesstitle1tagexp{
    margin-top: -240px;
    margin-left: 40%;
    width: 105px;
    height: 34px;
    border-radius: 37.5px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
}
    

    .assesscircleep{
        overflow-x: hidden;
        width: 260px;
height: 260px;
margin-left: 259px;
background: rgba(9, 9, 63, 0.05);
border-radius: 100%;
    }

    .registerexp{
        width: 137px;
height: 42px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
margin-left: 35%;
top: -60px;
position: relative;
left: 0px;
background: rgba(7, 60, 165, 1);
border: none;


    }
    .register1texp{
        padding-top: 8%;
    }

    .head12exp{
        width: 99%;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;

    }

    .head13{
        margin-top: -60px;
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;

    }



    .traintitle1tagexp{
        width: 105px;
height: 34px;
border-radius: 37.5px;
margin-top: -60%;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 40%;

    }

    .trainheadtitleexp{
        width: 194px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: 28%;
margin-top: -240px;
    }

    .traincircleep{
        width: 260px;
        height: 260px;
    background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -120px;
    position: relative;
    top: -50px;
    }

    .phonetitles2{
        margin-left: 10px;
        margin-top: 100px;

    }
    
    .phonebody2{
        /* margin-left: 10px; */
    }
    .orgcircle2{
        width: 121.5px;
height: 121.5px;
margin-top: 0px;
margin-left: 0px;
position: relative;
    }

    .orgcircle2::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 40%;
        margin-left: 75%;
        width: 121.5px;
height: 121.5px;
        border-radius: 50%;
        opacity: 90%;
      }
      
      .orgcircle2::before {
          background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
        }
    .viocircle2{
        margin-top: 0px;
        width: 121.5px;
height: 121.5px;
opacity: 90%;
position: relative;
    }

    .viocircle2::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }

    .viocircle2::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 0%;
        margin-left: 30%;
        width: 121.5px;
height: 121.5px;
        border-radius: 50%;
        opacity: 100%;
      }

    .bluecircle2{
        width: 183.91px;
height: 183.91px;
margin-top: -207px;
margin-left: 39%;
background: rgba(135, 189, 255, 1);
border-radius: 100%;


    }

    .phone2{
        width: 177px;
    height: 324.75px;
    margin-top: -60%;
    margin-left: 27%;
    position: relative;
    /* order: 2; */
    
    }

    .phonetitles2{
        order: 1;
    }

    .phonetitle1tag2exp{
        margin-top: 10%;
        width: 210px;
        height: 34px;
        border-radius: 37.5px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
margin-left: auto;
margin-right: auto;
background: rgba(232, 237, 252, 1);
    }

    .firstsignup1{
        margin-top: -510px;
        text-align: center;
    }

    
    .firstsignup11{
        margin-top: -465px;
    }

    .sechead1{
        margin-top: -220px;
    }

    .phoneheadtitle2{
        width: 270px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
color: rgba(0, 0, 0, 1);
position: relative;
top: -10px;
    }

    .phonebody2lap{
        display: none;
    }

.image-phone-container2{
    margin-top: 35px;
    margin-left: 10px;
    order: 1;
}

.phonebody2mob{
    order: 3;
    width: 358px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 10px;
margin-bottom: 20%;
}

    .adobe{
        width: 89.62px;
height: 23.58px;
    }

    .adidas{
        margin-left: 30px;
        width: 45.28px;
height: 46.23px;
    }
    .burger{
        width: 43.4px;
height: 46.23px;
    }
    .kfc{
        margin-left: 20px;
        width: 43.4px;
height: 46.23px;
    }
    .fedex{
        width: 60.38px;
height: 25.47px;
    }
    .uptime{
        margin-left: 20px;
        width: 66.04px;
height: 23.58px;

    }
    .title2tagexp{
        width: fit-content;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-top: 63%;
margin-left: auto;
margin-right: auto;
/* margin-left: 100px; */

    }

    .title2tagexp2{
        width: 95%;
        height: 31px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 3%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5em;
    }

    .mid3contentexp
    {
        display: none;
    }

    .titlee1tag{
        margin-top: 30px;
        width: 110px;
        margin-left: 35.5%;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1);
    }


    .title1tagexp{        
        width: 190px;
        margin-top: -15px;
        margin-left: auto;
        margin-right: auto;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1);
    }

    .title2tagexp2g{
        width: 250px;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
        top: -10px;
        margin-bottom: -1em;
        position: relative;
    }

    .content11{
        width: 267px;
height: 93px;
        display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    /* margin-left: 20%; */
      order: 2;
      font-family: 'Plus Jakarta Sans', sans-serif;
      font-size: 25px;
      /* font-weight: 300; */
      line-height: 31px;
      letter-spacing: 0em;
      text-align: center;
      margin-bottom: 50px;
    
    }

    .titlee1tag{
        width: 234px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;



        /* margin-top: 30px;
        width: 110px;
        margin-left: 35.5%;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1); */


    }

    .violetbox1{
        background: rgba(130, 27, 97, 1);
        width: 123px;
height: 124px;
        margin-top: -25%;
        margin-bottom: 100%;
        margin-left: 1%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;     
font-size: 10px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;

    }

    .vtexp1{
        width: 55px;
height: 35px;
font-family: 'Plus Jakarta Sans', sans-serif;  
/* margin-left: 100px; */
position: relative;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
left: 1px;
top: -6px;
color: rgba(255, 255, 255, 1);
    }

    .vtexp2{
        margin-top: 40px;
        margin-left: -53px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 10px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);
    }

    .yellowbox1{
        background: #F75B02;
        width: 72px;
        height: 72px;
        margin-top: -189%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif; 
        margin-left: 2%;          
    }
    .ytexp1{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
letter-spacing: 0em;
text-align: left;
margin-left: 5px;
    margin-top: 10px;
    }

    .linebox1{
        width: 100px;
height: 100px;
border: 1px;
opacity: 0.1px;
/* margin-top: 38%; */
margin-left: 10%;
 position: relative;
margin-top: -168%;
margin-bottom: 180%;
border: 1px solid rgba(0, 0, 0, 1);

    }

    .hlinebox1{
        width: 63px;;
    height: 99px;
    opacity: 15%;
    left: 1px;
    position: absolute;
}

.hlinebox2{
    width: 23px;
height: 99px;
opacity: 30%;
left: 10px;
}

.rlinebox1{
    margin-top: 10px;
    width: 99px;
height: 63px;
opacity: 30%;
top: 10px;
position: absolute;
}
.rlinebox2{
    /* margin-top: 20px; */
    width: 99px;
height: 23px;
opacity: 30%;
top: -10px;
position: absolute;
}

.lineboxtextexp{
    width: 55px;
height: 35px;
/* margin-top: -60px; */
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;
left: 9px   ;
top: 21px;
position: absolute;

}

.lineboxtext2exp{
    margin-top: 2px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 10px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;
}

.siginsexp{
    padding-top:2%;
    padding-left: 0%;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);

}

    .signinexp{
        width: 137px;
        
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
display: flex;
justify-content: center;
margin-top: 30px;
margin-left: 50px;
text-align: center;
align-content: center;
background: rgba(7, 60, 165, 1);
border-radius: 37.5px;
border: none;
/* text-align: center; */

    }


    .watchs1{
        /* padding-top: 5px; */
        margin-top: auto;
        margin-bottom: auto;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
/* text-align: left; */

    }

    .watchexp{
        width: 150px;
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
display: flex;
justify-content: center;
margin-left: -8px;
margin-top: 30px;
text-align: center;
border-radius:  30px;
background-color: white;
border: 1px solid rgba(7, 60, 165, 1);
color:  rgba(7, 60, 165, 1)
/* text-align: left; */
    }

    .linetextexp{
        width: 93%;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 13px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 16%;
    }

    .imag-container2{
        margin-left: 20px;
    }

  
    .imag-container1 {
        order: 1;
        margin-top: -5%;
        margin-left: 3%;
    }

    .orgvector1{
        width: 191px;
height: 263px;
margin-top: 15.5%;
        margin-left: 25%;
        position: absolute;
        background: rgba(255, 188, 0, 1);
    }

    .imag-container1 img{
        width: 198px;
height: 297px;
margin-top: 8.2%;
margin-left: -15%;
position: relative;

    }

    .background-container1{
        width: 191px;
        height: 263px;
        margin-left: -3%;
    }

    .chatbox121exp{
        width: 61.38px;
height: 46.67px;
    margin-top: -10.8%;
    margin-left: 64%;
    position: absolute;
    background: rgba(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100.3%);   
 }

 .chatbox141exp{
    width: 61px;
height: 46px;
background: rgba(231, 242, 255, 1);
position: relative;
margin-top: 0%;
clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
margin-left: -110%;
}

.chatbox151mobexp{
    width: 61px;
height: 46px;
background: rgba(207, 240, 230, 1);
position: absolute;
margin-top: -10.7%;
clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100%);
margin-left: 67%;
}

.chatbox111exp{
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    width: 61px;
height: 46px;
background: rgba(231, 242, 255, 1);
margin-left: -10%;
margin-top: 0%;
position: absolute;
}


.ttextexp{
    width: 155px;
height: 15px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
}


    body{
        overflow-x: hidden;
    }
    .luck1{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  display: inline-block;
                  font-weight: 900;
    
    }

    .ytexp2{
        width: 66px;
height: 44px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 10px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
position: relative;
top: -5px;
margin-left: 2px;


    }
    
  }