/* media query

  laptop
  min-width :1400px my laptop
  width  : 1228px - 1399px small lap

*/



@media (min-width: 1400px){

  .patparatab{
    display: none !important;
  }

  .mainlanding{
    margin-top: 70px;
    position: relative;
    margin-bottom: -21%;
    left: 2%;
  }
  .corplandparatab{
    display: none !important;
  }
  .explogo1{
    top: -38px;
  left: 13px;  }

  .nsignin2tab{
    display: none;
  }

  /* my laptop 1528px */

  .logomid{
    position: relative;
    top: 10px;
    bottom: 10px;
    width: 170px;
height: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;

}


.line{
    height: 1px;
    width: 85%;
    background-color: rgba(56, 56, 71, 1);
    display: block;
    margin-left: auto;
    margin-right: auto;
    opacity: 50%; 
} 

.pickprofile{
    margin-top: 2%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 1068px;
height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 36px;
font-weight: 300;
line-height: 44.64px;
text-align: left;

    /* text-align: center; */
}

.Pickbutton{
  width: 229px;
height: 60px;
border-radius: 37.5px;
position: relative;
display: block;
    margin-left: auto;
    margin-right: auto;
border: 1px solid rgba(7, 60, 165, 1);
background: rgba(255, 255, 255, 1);
color: rgba(7, 60, 165, 1);
margin-bottom: 0%;
cursor: none;
margin-top: 1em;
}

.para{
    margin-top: 1%;
    margin-bottom: 0%;
    width: 1171px;
height: 75px;
    display: block;
    margin-left: auto;
    margin-right: auto;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
text-align: center;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.orgbg{
    width: 220px;
    height: 302px;
background: rgba(255, 91, 0, 1);
border-radius: 0px 100% 0px 0px;
}

.fresherlogo{
    width: 266px;
height: 324px;
position: absolute;
}

.freshertitle{
    width: 127px;
height: 22px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 91, 0, 1);
}

.freshers:hover .ftitle{
    opacity: 0.8;
    /* height: 100%; */ 
  }
  .freshers:hover .fresherlogo{
    transition-duration: 0.3s;
    opacity: 0;
  }

.ftitle{
    position: absolute;
    /* margin-top: -40%; */
}
.freshers img{
    width: 266px;
    height: 324px;
    top: -27px;
    position: relative;
    opacity: 1;
  }

  .freshers{
    margin-left: -12%;
  }

  /* .freshers p{
    width: 127px;
    height: 22px;
    position: absolute;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
  } */
  /* .freshers span{
    width: 199px;
    height: 302px;
    margin-left: 0%;
    margin-top: -3%;
    background-color: rgba(255, 91, 0, 1);
    border-top-right-radius: 250px ;
  } */

  .landfreshersbg{
    width: 199px;
    height: 269px;
    background-color: rgba(255, 91, 0, 1);
    border-top-right-radius: 100%;
  }
  .content{
    position: relative;
    width: 10%;
    height: 31px;
    display: flex;
    flex-direction: column;
    transform: translate(-50%, -50%); 
    opacity: 0;
    transition:0.5s;
  }
  .freshers:hover .content{
    opacity: 0.8;
  }
  .freshers:hover img{
    transition-duration: 0.3s;
    opacity: 0;
  }
  .content h1{
  left:-215px;
  top: 13px;
    position: absolute;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left; 
    text-decoration: none;
    color: rgb(255, 255, 255, 1);
    cursor: pointer;
    width: 103px;
height: 31px;
     
  }
  .content p{
    top: 49px;
    left: -212px;
    width: 132px;
height: 105px;
    position: absolute;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
    text-decoration: none;   
    color: rgb(255, 255, 255, 1);
    cursor: pointer;
  }

 

  .fbottom{
    position:relative;
    margin-left: -10%;
    width: 127px;
    height: 22px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 91, 0, 1);
  }

  .ebottom{
    position:relative;
    top: 40px;
    left: 18px;
    width: 127px;
    height: 22px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(233, 172, 1, 1);

  }

  /* .experienced{
position: relative;
top: 40px;
left: 10px;
  } */

  .experienced img{
    width: 231px;
    height: 345px;
    position: relative;
}
.experienced p{
width: 159px;
height: 22px;
position: absolute;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: left;
cursor: pointer;
}
.landexpbg{
width: 195px;
height: 269px;  
margin-left: 10px;
margin-top: 80px;
background-color:rgba(233, 172, 1, 1);
/* position: absolute;   */
}
.content2{
position: absolute;
width: 10%;
height: 31;
display: flex;
flex-direction: column;
transform: translate(-50%, -50%); 
opacity: 0;
transition:0.5s;
background-color: black;
cursor: pointer;

}
.experienced:hover .content2{
opacity: 0.8;
/* height: 100%; */ 
}
.experienced:hover img{
transition-duration: 0.3s;
opacity: 0;
}



.content2 h1{
position: absolute;
top: -39px;
left: -60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 500;
line-height: 31px;
letter-spacing: 0em;
text-align: left; 
color: rgb(255, 255, 255, 1);

 
}
.content2 p{
  width: 132px;
  height: 120px;
left: -60px;
top: 0px;
position: absolute;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
color: rgb(255, 255, 255, 1);
}

.collogolap{
    width: 211.08px;
    height: 362px;
    left: 40px;
    top: -362px;
    position: relative;
    cursor: pointer;
  }
  .colleges p{
    width: 158px;
height: 22px;
position: absolute;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: left;
  }
  .landcolbg{
    width: 206px;
height: 350px;
left: 40px;
position: relative;
background: rgba(137, 26, 97, 1);
clip-path: polygon(0 0, 100% 54%, 100% 100%, 0 100%, 0% 38%);

  }

  /* .college{
    bottom: -700px;
  } */
  .content3{
    position: absolute;
    width: 10%;
    height: 31;
    display: flex;
    flex-direction: column;
    transform: translate(-50%, -50%); 
    opacity: 0;
    transition:0.5s;
    background-color: black;
    cursor:pointer;
  }
  .colleges:hover .content3{
    opacity: 0.8;
    /* height: 100%; */ 
  }
  .colleges:hover img{
    transition-duration: 0.3s;
    opacity: 0;
  }
  .content3 h1{
    position: absolute;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    left: -63px;
    top: -15px;
    cursor: pointer;
    color: rgb(255, 255, 255, 1);
  }
  .content3 p{
    width: 132px;
height: 135px;
    cursor: pointer;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
    left: -60px;
    top: 23px;
    color: rgb(255, 255, 255, 1);
  }

  .cbottom{
    position:relative;
    margin-left: 40px;
    width: 127px;
    height: 22px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgb(137, 26, 97, 1);
  }

  /* .partner{
    margin-left: 10%;
    margin-top: -15%;
  } */
  .partner img{
    width: 229px;
    height: 359px;
    top: -32px;
    position: relative;
  }

  .partner span{
    width: 217px;
    height: 294px;
    top: 20px;
    left: 70px;
    position: relative;
    background: rgba(204, 5, 48, 1);
    border-top-right-radius: 100%;
  }
  .content4{
    position: absolute;
    width: 10%;
    height: 31px;
    display: flex;
    flex-direction: column;
    transform: translate(-50%, -50%); 
    opacity: 0;
    transition:0.5s;
    cursor: pointer;
    /* background-color: black; */

  }
  .partner:hover .content4{
    opacity: 0.8;
    /* height: 100%; */ 
  }
  .partner:hover img{
    transition-duration: 0.3s;
    opacity: 0;
  }
  .content4 h1{
    position: relative;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left; 
    top: -25px;
    left: -30px;
    color: rgb(255, 255, 255, 1);
  }
  .content4 p{
    top: -25px;
    left: -65px;
    width: 132px;
height: 120px;
    position: relative;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
    text-decoration: none; 
    color: rgb(255, 255, 255, 1);
  }

   

  .pbottom{
    position:relative;
    width: 228px;
    height: 23px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    top: 25px;
    left: 79px;
    color: rgba(204, 5, 48, 1);

  }

  /* .corporates{
    margin-top: 30%;
  } */

  .corlogo{
    width: 220px;
    height: 294px;
    top: -264px;
    left: 110px;
    position: relative;
    cursor: pointer;

  }

  .corporatss{
    margin-bottom: -260px;
    margin-left: 10%;
    margin-top: 10%;
  }
  .corporatss p{
    width: 125px;
height: 31px;
position: absolute;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: left;
  }
  /* .corporate span{
    width: 200px;
height: 308px;
background-color: rgb(5, 58, 192);
position: absolute;
margin-left: 100%;
margin-top: -8.5%;
clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 38%);
  } */
  .conttt5{
    /* position: absolute; */
    width: 10%;
    height: 31px;
    display: flex;
    flex-direction: column;
    transform: translate(-50%, -50%); 
    opacity: 0;
    transition:0.5s;
    cursor: pointer;
  }
  .corporatss:hover .conttt5{
    opacity: 0.8;
    /* height: 100%; */ 
  }
  .corporatss:hover img{
    transition-duration: 0.3s;
    opacity: 0;
  }
  .conttt5 h1{
    position: relative;
    width: 103px; 
    height: 31px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left; 
    top: 10px;
    left: -53px;
    color: rgb(255, 255, 255, 1);
     
  }
  .conttt5 p{
    width: 132px;
    height: 120px;
    position: relative;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.88px;
    text-align: left;  
    top: 10px; 
left: -60px;
    color: rgb(255, 255, 255, 1);
  }
  .cobottom{
    position:relative;
    left: 140px;
    width: 127px;
    height: 22px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgb(5, 58, 192);
  }

  .corpbluebg{
    width: 200px;
height: 308px;
background: rgb(5, 58, 192);
left: 120px;
top: 30px;
position: relative;
clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 38%);
  }

  .freshertabcontent{
    display: none;
  }

  .colparatab{
    display: none !important;
  }

  .colleges{
    margin-left:10%;
  }

  .experiencedtitle{
    display: none !important;
  }
}




@media (min-width:1228px) and (max-width:1399px){

  .patparatab{
    display: none !important;
  }

  .mainlandingpage::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Chrome */
  }
  .mainlandingpage{
    max-width:1300px;
    transform: scale(0.83);
    transform-origin: top;
  }

  .mainlanding{
    margin-bottom: -29%;
  }
  .corplandparatab{
    display: none !important;
  }
  .explogo1{
    top: -38px;
  left: 13px;  }

  .nsignin2tab{
    display: none;
  }

  /* my laptop 1528px */

  .logomid{
    position: relative;
    top: 10px;
    bottom: 10px;
    width: 170px;
height: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;

}

.Pickbutton{
  width: 229px;
height: 60px;
border-radius: 37.5px;
position: relative;
display: block;
    margin-left: auto;
    margin-right: auto;
border: 1px solid rgba(7, 60, 165, 1);
background: rgba(255, 255, 255, 1);
color: rgba(7, 60, 165, 1);
margin-bottom: 3em;
cursor: none;
margin-top: 1em;
}


.line{
    height: 1px;
    width: 100%;
    background-color: rgba(56, 56, 71, 1);
    display: block;
    margin-left: auto;
    margin-right: auto;
    opacity: 50%; 
} 

.pickprofile{
    margin-top: 2%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 1068px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 36px;
font-weight: 300;
line-height: 44.64px;
text-align: left;

}



.para{
    margin-top: 1%;
    margin-bottom: 0%;
    width: 1171px;
height: 75px;
    display: block;
    margin-left: auto;
    margin-right: auto;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
text-align: center;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.orgbg{
    width: 220px;
    height: 302px;
background: rgba(255, 91, 0, 1);
border-radius: 0px 100% 0px 0px;
}

.fresherlogo{
    width: 266px;
height: 324px;
position: absolute;
}

.freshertitle{
    width: 127px;
height: 22px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 91, 0, 1);
}

.freshers:hover .ftitle{
    opacity: 0.8;
    /* height: 100%; */ 
  }
  .freshers:hover .fresherlogo{
    transition-duration: 0.3s;
    opacity: 0;
  }

.ftitle{
    position: absolute;
    /* margin-top: -40%; */
}
.freshers img{
    /* width: 266px;
    height: 324px; */
    top: -27px;
    position: relative;
    opacity: 1;
  }

  .freshers{
    margin-left: -50%;
  }

  /* .freshers p{
    width: 127px;
    height: 22px;
    position: absolute;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
  } */
  /* .freshers span{
    width: 199px;
    height: 302px;
    margin-left: 0%;
    margin-top: -3%;
    background-color: rgba(255, 91, 0, 1);
    border-top-right-radius: 250px ;
  } */

  .landfreshersbg{
    width: 199px;
    height: 269px;
    background-color: rgba(255, 91, 0, 1);
    border-top-right-radius: 100% ;
  }
  .content{
    position: relative;
    width: 10%;
    height: 31px;
    display: flex;
    flex-direction: column;
    transform: translate(-50%, -50%); 
    opacity: 0;
    transition:0.5s;

  }
  .freshers:hover .content{
    opacity: 0.8;
  }
  .freshers:hover img{
    transition-duration: 0.3s;
    opacity: 0;
  }
  .content h1{
  left:-210px;
  top: 10px;
    position: absolute;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left; 
    text-decoration: none;
    color: rgb(255, 255, 255, 1);
    cursor: pointer;
     
  }
  .content p{
    top: 45px;
    left: -210px;
    width: 132px;
height: 105px;
    position: absolute;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left;
    text-decoration: none;   
    color: rgb(255, 255, 255, 1);
    cursor: pointer;
  }

 

  .fbottom{
    position:relative;
    margin-left: -50%;
    width: 127px;
    height: 22px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 91, 0, 1);
    /* margin-bottom: 0%; */
  }

  .ebottom{
    position:relative;
    top: 10px;
    left: -10px;
    width: 127px;
    height: 22px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(233, 172, 1, 1);
  
  }

  /* .experienced{
position: relative;
top: 10px;
left: -22px;

  } */

  .experienced img{
    width: 231px;
    height: 345px;
    position: relative;
}
.experienced p{
width: 159px;
height: 22px;
position: absolute;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: left;
cursor: pointer;
}
.landexpbg{
width: 195px;
height: 269px;  
margin-left: 10px;
margin-top: 80px;
background-color:rgba(233, 172, 1, 1);
/* position: absolute;   */
}
.content2{
position: absolute;
width: 10%;
height: 31;
display: flex;
flex-direction: column;
transform: translate(-50%, -50%); 
opacity: 0;
transition:0.5s;
background-color: black;
cursor: pointer;

}
.experienced:hover .content2{
opacity: 0.8;
/* height: 100%; */ 
}
.experienced:hover img{
transition-duration: 0.3s;
opacity: 0;
}

.content2 h1{
position: absolute;
top: -33px;
left: -60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 500;
line-height: 31px;
letter-spacing: 0em;
text-align: left; 
color: rgb(255, 255, 255, 1);

 
}
.content2 p{
width: 132px;
height: 120px;
left: -60px;
top: 0px;
position: absolute;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: left;  
color: rgb(255, 255, 255, 1);
}



.collogolap{
    width: 211.08px;
    height: 362px;
    left: 40px;
    top: -362px;
    position: relative;
    cursor: pointer;
  }
  .colleges p{
    width: 158px;
height: 22px;
position: absolute;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: left;
  }
  .landcolbg{
    width: 206px;
height: 350px;
left: 40px;
position: relative;
background: rgba(137, 26, 97, 1);
clip-path: polygon(0 0, 100% 54%, 100% 100%, 0 100%, 0% 38%);

  }

  /* .college{
    bottom: -700px;
  } */
  .content3{
    position: absolute;
    width: 10%;
    height: 31;
    display: flex;
    flex-direction: column;
    transform: translate(-50%, -50%); 
    opacity: 0;
    transition:0.5s;
    background-color: black;
    cursor:pointer;
  }
  .colleges:hover .content3{
    opacity: 0.8;
    /* height: 100%; */ 
  }
  .colleges:hover img{
    transition-duration: 0.3s;
    opacity: 0;
  }
  .content3 h1{
    position: absolute;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    left: -60px;
    top: -15px;
    cursor: pointer;
    color: rgb(255, 255, 255, 1);
  }
  .content3 p{
    width: 132px;
height: 135px;
    cursor: pointer;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;    
    left: -60px;
    top: 20px;
    color: rgb(255, 255, 255, 1);
  }

  .cbottom{
    position:relative;
    margin-left: 40px;
    width: 127px;
    height: 22px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgb(137, 26, 97, 1);
  }

  /* .partner{
    margin-left: 38%;
  } */
  .partner img{
    width: 229px;
    height: 359px;
    top: -32px;
    position: relative;
  }

  .partner span{
    width: 217px;
    height: 294px;
    top: 20px;
    left: 70px;
    position: relative;
    background: rgba(204, 5, 48, 1);
    border-top-right-radius: 100%;
  }
  .content4{
    position: absolute;
    width: 10%;
    height: 31px;
    display: flex;
    flex-direction: column;
    transform: translate(-50%, -50%); 
    opacity: 0;
    transition:0.5s;
    cursor: pointer;
    /* background-color: black; */

  }
  .partner:hover .content4{
    opacity: 0.8;
    /* height: 100%; */ 
  }
  .partner:hover img{
    transition-duration: 0.3s;
    opacity: 0;
  }
  .content4 h1{
    position: relative;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left; 
    top: -24px;
    color: rgb(255, 255, 255, 1);
    left: -29px;
  }
  .content4 p{
    top: -27px;
    left: -65px;
    width: 132px;
    height: 120px;
    position: relative;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;   
    text-decoration: none; 
    color: rgb(255, 255, 255, 1);
  }

   

  .pbottom{
    position:relative;
    width: 228px;
    height: 23px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    top: 25px;
    left: 119px;
    color: rgba(204, 5, 48, 1);

  }

  /* .corporates{
    margin-top: 30%;
  } */

  .corlogo{
    width: 220px;
    height: 294px;
    top: -264px;
    left: 110px;
    position: relative;
    cursor: pointer;

  }

  .corporatss{
    margin-bottom: -260px;
    margin-left: 60%;
    margin-top: 10%;
  }
  .corporatss p{
    width: 125px;
height: 31px;
position: absolute;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: left;
  }
  /* .corporate span{
    width: 200px;
height: 308px;
background-color: rgb(5, 58, 192);
position: absolute;
margin-left: 100%;
margin-top: -8.5%;
clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 38%);
  } */
  .conttt5{
    /* position: absolute; */
    width: 10%;
    height: 31px;
    display: flex;
    flex-direction: column;
    transform: translate(-50%, -50%); 
    opacity: 0;
    transition:0.5s;
    cursor: pointer;
  }
  .corporatss:hover .conttt5{
    opacity: 0.8;
    /* height: 100%; */ 
  }
  .corporatss:hover img{
    transition-duration: 0.3s;
    opacity: 0;
  }
  .conttt5 h1{
    position: relative;
    width: 103px; 
    height: 31px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left; 
    top: 10px;
    left: -52px;
    color: rgb(255, 255, 255, 1);
     
  }
  .conttt5 p{
    width: 132px;
height: 120px;
    position: relative;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: left; 
    top: 10px; 
    left: -60px;
    color: rgb(255, 255, 255, 1);
  }
  .cobottom{
    position:relative;
    left: 217px;
    width: 127px;
    height: 22px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgb(5, 58, 192);
  }

  .corpbluebg{
    width: 200px;
height: 308px;
background: rgb(5, 58, 192);
left: 120px;
top: 30px;
position: relative;
clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 38%);
  }

  .freshertabcontent{
    display: none;
  }

  .colparatab{
    display: none !important;
  }

  .colleges{
    margin-left:10%;
  }

  .experiencedtitle{
    display: none !important;
  }
}





@media (min-width:831px) and (max-width:1227px)
{

  .patparatab{
    display: none !important;
  }

  .corporatss{
    margin-top: -180%;
  }
    /* @media (min-width:491px) and (max-width:1019px){ */
  .logomid{
    width: 270px;
    height: 140px;
    position: relative;
    /* margin-left:36%; */
    margin-top: 5%;
    display: block;
    margin-left: auto;
    margin-right: auto;

  }

  .nsignin1{
    /* width: 100%;
    background: rgba(7, 60, 165, 1);
    height: 90px; */

    display: none;
  }

  .nsignin2tab{
    width: 100%;
    background: rgba(7, 60, 165, 1);
    height: 90px;
    font-family: 'Plus Jakarta Sans', sans-serif;

  }

  .signintexttab{
    width: 55px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);
margin-left: 13%;
margin-top: 3.5%;
position: absolute;
  }

  .arrowsignintab{
    margin-left: 55%;
    margin-top: 2.5%;
  }



  .line{
    height: 1px;
    width: 80%;
    background-color: rgba(56, 56, 71, 1);
    display: block;
    margin-left: auto;
    margin-right: auto;
    opacity: 50%;
  }
  .pickprofile{
    width: 869px;
    height: 90px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Plus Jakarta Sans', sans-serif;
    text-align: center;
    margin-top: 2em;
    margin-bottom: 1em;
font-size: 36px;
font-weight: 300;
line-height: 44.64px;

  }

  .para{
    width: 827px;
height: 100px;
margin-left: auto;
    margin-right: auto;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.8px;
    text-align: center;
  }

  .Pickbutton{
    width: 229px;
height: 60px;
border-radius: 37.5px;
background: rgba(255, 255, 255, 1);
border: 1px solid rgba(7, 60, 165, 1);
color: rgba(7, 60, 165, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
text-align: center;
display: block;
margin-left: auto;
margin-right: auto;
margin-bottom: 2em;
  }




  .freshers span{
    width: 309.31px;
height: 418.11px;
background: rgba(255, 91, 0, 1);
border-radius: 0px 100% 0px 0px;
position:absolute;
display: block;
margin-left: 25%;
margin-top: 13%;
  }

  .fresherlog{
    width: 413.44px;
height: 503.57px;
position: absolute;
display: block;
margin-left: 10%;
margin-top: -27.5%;
  }

  .content h1{
    display: none !important;
  }

  .content2 h1{
    display: none !important;
  }

  .content3 h1{
    display: none !important;
  }

  .content4 h1{
    display: none !important;
  }

  .conttt5 h1{
    /* display: none; */
    width: 245.15px;
height: 49.03px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 32px;
font-weight: 400;
line-height: 40px;
letter-spacing: 0em;
text-align: left;
color: rgb(5, 58, 192);
margin-left: -260%;
margin-top: -20%;
  }

  .content p{
    width: 697px;
height: 74px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
text-decoration: none;   
position: relative;
color: rgba(0, 0, 0, 1);
display: block;
margin-top: 670px;
margin-left: 4%;
display: flex;
  justify-content: center;
  align-items: center;
  }

  .fbottom{
    position:absolute;
    margin-left: 21%;
    margin-top: 54%;
    width: 197px;
    height: 51px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 91, 0, 1);
    display: flex;
    text-decoration: none;
  justify-content: center;
  align-items: center;
  } 

  /* .experienced{
    margin-top: -40%;
  } */

  .experienced span{
    width: 305px;
height: 418px;
background: rgba(233, 172, 1, 1);
position: absolute;
display: block;
margin-top: 100%;
margin-left: 10%;
  }

  .explogo1{
    width: 358px;
height: 537px;
position: absolute;
display: block;
margin-top: -39%;
margin-left: 19%;

  }

  .content2 p{
    display: block;
    width: 697px;
height: 73px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
text-decoration: none;   
position: relative;
color: rgba(0, 0, 0, 1);
top: 320px;
/* margin-top: 154%; */
margin-left: 5%;
  }

  .ebottom{
    position:absolute;
    margin-left: 10%;
    margin-top: 141%;
    width: 197px;
    height: 51px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(233, 172, 1, 1);
    display: flex;
    text-decoration: none;
  justify-content: center;
  align-items: center;
  } 

  .colleges{
    margin-top: -70%;
  }


  .colleges span{
    width: 321px;
height: 545px;
clip-path: polygon(0 0, 100% 54%, 100% 100%, 0 100%, 0% 38%);
background:rgba(137, 26, 97, 1);
position: absolute;
display: block;
margin-left: -6%;
margin-top: 168%;


  }

  .collogolap{
    width: 328.08px;
height: 562.66px;
position: absolute;
display: block;
margin-top: 166.3%;
margin-left: -6%;
  }

  .content3 p{
    width: 697px;
height: 73px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
text-decoration: none;   
position: absolute;
color: rgba(0, 0, 0, 1);
margin-top: 180%;
margin-left: 50%;
/* display: contents !important; */
  }

.landexpcont{
margin-top: -5%;
}

.colparatab{
  width: 697px;
  height: 73px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  text-decoration: none;   
  position: absolute;
  color: rgba(0, 0, 0, 1);
  margin-top: 229%;
  margin-left: -21%;
}




  .cbottom{
    position:absolute;
    margin-left: -7%;
    margin-top: 222%;
    width: 181px;
    height: 51px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(137, 26, 97, 1);
    display: flex;
    text-decoration: none;
  justify-content: center;
  align-items: center;
  }
  
  /* .partner{
    margin-top: -160%;
  } */

  .partner span{
    width: 337.28px;
    height: 456.96px;
    background:rgba(204, 5, 48, 1);
        border-top-left-radius: 100%;
position: absolute;
display: block;
margin-left: -20%;
margin-top: 270%;
  }

  .patlogolap{
    width: 355.93px;
height: 557.99px;
position: relative;
display: block;
margin-top: -30%;
margin-left:190%;

  }

  .partlandpara{
    width: 697px;
    height: 73px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    text-decoration: none;   
    position: relative;
    color: rgba(0, 0, 0, 1);
    margin-top: 10%;
    margin-left: -72.5%;
  }

  .content4 p{
  
    width: 697px;
height: 73px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
text-decoration: none;   
position: relative;
color: rgba(0, 0, 0, 1);
margin-top: 100%;
margin-left: -72.5%;
  }

.pbottom{
  position:absolute;
    margin-left: -25%;
    margin-top: 316%;
    width: 354.38px;
    height: 35.75px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 32px;
font-weight: 400;
line-height: 40px;
letter-spacing: 0em;
text-align: left;
    color: rgba(204, 5, 48, 1);
    display: flex;
    text-decoration: none;
  justify-content: center;
  align-items: center;
}



.corpbluebg{
  width: 310.86px;
height: 478.72px;
background: rgb(5, 58, 192);
clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 38%);
position: relative;
display: block;
margin-top: 2660%;
margin-left: -270%;
}

.corlogo{
  width: 341.95px;
height: 455.93px;
margin-top: -348%;
margin-left: -280%;
position: relative;
}

.conttt5 p{
  width: 696.63px;
height: 73.54px;
position: relative;
display: block;
margin-top: 50%;
margin-bottom: 150%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
margin-left: -410%;


}

.corplandparatab{
  width: 696.63px;
  height: 73.54px;
  position: relative;
  display: block;
  margin-top: 100%;
  margin-bottom: 150%;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  margin-left: -410%;
}

.cobottom{
  width: 245.15px;
height: 49px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 32px;
font-weight: 400;
line-height: 40px;
letter-spacing: 0em;
text-align: left;
color: rgb(5, 58, 192);
position: relative;
margin-left: -260%;
margin-bottom: -80%;
}


  

  /* .freshertabcontent{
    display: block;
  }

  .ftaborbg{
    width: 309.31px;
    height: 418.11px;
    background: rgba(255, 91, 0, 1);
    border-radius: 0px 100% 0px 0px;
  } */

  .experiencedtitle{
    display: none !important;
  }


}


@media (min-width:577px) and (max-width:830px)
{

  .corporatss{
    margin-top: 440%;
  }
    /* @media (min-width:491px) and (max-width:1019px){ */
  .logomid{
    width: 270px;
    height: 140px;
    position: relative;
    /* margin-left:36%; */
    margin-top: 5%;
    display: block;
    margin-left: auto;
    margin-right: auto;

  }

  .nsignin1{
    /* width: 100%;
    background: rgba(7, 60, 165, 1);
    height: 90px; */

    display: none;
  }

  .nsignin2tab{
    width: 100%;
    background: rgba(7, 60, 165, 1);
    height: 90px;
    font-family: 'Plus Jakarta Sans', sans-serif;

  }

  .signintexttab{
    width: 55px;
height: 19px;
font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);
margin-left: 13%;
margin-top: 3.5%;
position: absolute;
  }

  .arrowsignintab{
    margin-left: 55%;
    margin-top: 2.5%;
  }



  .line{
    height: 1px;
    width: 80%;
    background-color: rgba(56, 56, 71, 1);
    display: block;
    margin-left: auto;
    margin-right: auto;
    opacity: 50%;
  }
  .pickprofile{
    width: 95%;
    height: 90px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Plus Jakarta Sans', sans-serif;
    text-align: center;
    margin-top: 2em;
    margin-bottom: 1em;
font-size: 36px;
font-weight: 300;
line-height: 44.64px;

  }

  .para{
    width: fit-content;
height: 100px;
margin-left: auto;
    margin-right: auto;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.8px;
    text-align: center;
  }

  .Pickbutton{
    width: 229px;
height: 60px;
border-radius: 37.5px;
background: rgba(255, 255, 255, 1);
border: 1px solid rgba(7, 60, 165, 1);
color: rgba(7, 60, 165, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
text-align: center;
display: block;
margin-left: auto;
margin-right: auto;
margin-bottom: 2em;
  }




  .freshers span{
    width: 309.31px;
height: 418.11px;
background: rgba(255, 91, 0, 1);
border-radius: 0px 100% 0px 0px;
position:absolute;
display: block;
left: 50%;
    transform: translate(-50%, -50%);
/* margin-left: 25%; */
margin-top: 35%;
  }

  .fresherlog{
    width: 413.44px;
height: 503.57px;
position: absolute;
display: block;
margin-left: 10%;
margin-top: -27.5%;
  }

  .content h1{
    display: none !important;
  }

  .content2 h1{
    display: none !important;
  }

  .content3 h1{
    display: none !important;
  }

  .content4 h1{
    display: none !important;
  }

  .conttt5 h1{
    /* display: none; */
    width: 245.15px;
height: 49.03px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 32px;
font-weight: 400;
line-height: 40px;
letter-spacing: 0em;
text-align: left;
color: rgb(5, 58, 192);
margin-left: -260%;
margin-top: -20%;
  }

  .content p{
    width: 697px;
height: 74px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
text-decoration: none;   
position: absolute;
color: rgba(0, 0, 0, 1);
display: block;
margin-top: 350px;
left: 50%;
transform: translate(-50%, -50%);
/* margin-left: 4%; */
display: flex;
  justify-content: center;
  align-items: center;
  }

  .fbottom{
    position:relative;
    margin-left: 19%;
    margin-top: 72%;
    width: 197px;
    height: 51px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 91, 0, 1);
    display: flex;
    text-decoration: none;
  justify-content: center;
  align-items: center;
  } 

  /* .experienced{
    margin-top: -10%;
  } */

  .experienced span{
    width: 305px;
height: 418px;
background: rgba(233, 172, 1, 1);
position: absolute;
display: block;
margin-top: 78%;
left: 50%;
    transform: translate(-50%, -50%);
/* margin-left: 10%; */
  }

  .explogo1{
    width: 358px;
height: 537px;
position: absolute;
display: block;
margin-top: -39%;
margin-left: 19%;

  }

  .content2 p{
    display: block;
    width: 697px;
height: 73px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
text-decoration: none;   
position: relative;
color: rgba(0, 0, 0, 1);
top: 360px;
/* margin-top: 154%; */
/* margin-left: 5%; */
left: 50%;
    transform: translate(-50%, -50%);
  }

  .ebottom{
    position:absolute;
    margin-left: 20%;
    /* left: 50%;
    transform: translate(-50%, -50%); */
    margin-top: 104%;
    width: 197px;
    height: 51px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(233, 172, 1, 1);
    display: flex;
    text-decoration: none;
  justify-content: center;
  align-items: center;
  } 

  .colleges{
    margin-top: 0%;
  }


  .colleges span{
    width: 321px;
height: 545px;
clip-path: polygon(0 0, 100% 54%, 100% 100%, 0 100%, 0% 38%);
background:rgba(137, 26, 97, 1);
position: absolute;
display: block;
left: 50%;
    transform: translate(-50%, -50%);
/* margin-left: -6%; */
margin-top: 168%;


  }

  .collogolap{
    width: 328.08px;
height: 562.66px;
position: absolute;
display: block;
margin-top: 167%;
left: 50%;
    transform: translate(-50%, -50%);
/* margin-left: -6%; */
  }

  .content3 p{
    width: 697px;
height: 73px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
text-decoration: none;   
position: absolute;
color: rgba(0, 0, 0, 1);
margin-top: 180%;
margin-left: 50%;
/* display: contents !important; */
  }

.landexpcont{
margin-top: -5%;
}

.colparatab{
  width: 697px;
  height: 73px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  text-decoration: none;   
  position: absolute;
  color: rgba(0, 0, 0, 1);
  margin-top: 218%;
  /* margin-left: 21%; */
  left: 50%;
    transform: translate(-50%, -50%);
}




  .cbottom{
    position:absolute;
    margin-left: 20%;
    /* left: 50%;
    transform: translate(-50%, -50%); */
    margin-top: 202%;
    width: 181px;
    height: 51px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(137, 26, 97, 1);
    display: flex;
    text-decoration: none;
  justify-content: center;
  align-items: center;
  }
  
  /* .partner{
    margin-top: 5%;
  } */

  .partner span{
    width: 337.28px;
    height: 456.96px;
    background:rgba(204, 5, 48, 1);
        border-top-left-radius: 100%;
position: absolute;
display: block;
/* margin-left: -20%; */
left: 50%;
    transform: translate(-50%, -50%);
margin-top: 270%;
  }

  .patlogolap{
    width: 355.93px;
height: 557.99px;
position: absolute;
display: block;
margin-top: -30%;

/* margin-left:190%; */

  }

  .partlandpara{
    width: 697px;
    height: 73px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    text-decoration: none;   
    position: relative;
    color: rgba(0, 0, 0, 1);
    margin-top: 10%;
    margin-left: -72.5%;
  }

  .content4 p{
display: none !important;  
    /* width: 697px;
height: 88px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
text-decoration: none;   
position: relative;
color: rgba(0, 0, 0, 1);
margin-top: 100%;
margin-left: -72.5%; */
  }

  .patparatab{
    width: 697px;
    height: 88px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    text-decoration: none;   
    position: absolute;
    color: rgba(0, 0, 0, 1);
    margin-top: 320%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* margin-left: -72.5%; */
  }

.pbottom{
  position:absolute;
    margin-left: 13%;
    margin-top: 300%;
    width: 354.38px;
    height: 35.75px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 32px;
font-weight: 400;
line-height: 40px;
letter-spacing: 0em;
text-align: left;
    color: rgba(204, 5, 48, 1);
    display: flex;
    text-decoration: none;
  justify-content: center;
  align-items: center;
}



.corpbluebg{
  width: 310.86px;
height: 478.72px;
background: rgb(5, 58, 192);
clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 38%);
position: relative;
display: block;
left: 50%;
    transform: translate(-50%, -50%);
margin-top: 0%;
margin-left: 0%;
}

.corlogo{
  width: 341.95px;
height: 455.93px;
margin-top: -70%;
margin-left: 0%;
position: relative;
left: 50%;
    transform: translate(-50%, -50%);
}

.conttt5 p{
  width: 696.63px;
height: 73.54px;
position: relative;
display: block;
margin-top: 0%;
margin-bottom: 150%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
left: 50%;
    transform: translate(-50%, -50%);


}

.corplandparatab{
  width: 650px;
  height: 73.54px;
  position: relative;
  display: block;
  margin-top: 100%;
  margin-bottom: 40%;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  left: 50%;
    transform: translate(-50%, -50%);
}

.cobottom{
  width: 245px;
height: 49px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 32px;
font-weight: 400;
line-height: 40px;
letter-spacing: 0em;
text-align: left;
color: rgb(5, 58, 192);
position: relative;
margin-left: 26%;
margin-bottom: -90%;
margin-top: -32%;

}


  

  /* .freshertabcontent{
    display: block;
  }

  .ftaborbg{
    width: 309.31px;
    height: 418.11px;
    background: rgba(255, 91, 0, 1);
    border-radius: 0px 100% 0px 0px;
  } */

  .experiencedtitle{
    display: none !important;
  }

  


}



@media  (max-width: 576px){

  .patparatab{
    display: none !important;
  }

  .corplandparatab{
    display: none !important;
  }

  .cobottom{
    display: none;
  }

  .colparatab{
    display: none !important;
  }


  .nsignin2tab{
    display: none;
  }

  .nsignin1{
    /* width: 100%;
    background: rgba(7, 60, 165, 1);
    height: 90px; */

    display: none;
  }

  .content4{
    display: none;
  }  

  .pbottom{
    display: none !important;
  }

  

  .pickprofile{
    display: none;
  }

  .freshertabcontent{
    display: none;
  }

    .bluebg{
        /* width: 220px;
        height: 300px; */
        width: 152.16px;
        height: 234.33px;
        position: absolute;
        margin-top: 10%;
        margin-left: 20%;
        background:rgba(7, 60, 165, 1);
        clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 38%);
        
    }
    .corlogo{
        /* width: 230.59px;
        height: 323.6px; */
        width: 167.38px;
        height: 223.18px;
        margin-top: 14.2%;
        margin-left: 21%;
        position: relative;

    }

    .corporatetitle{
        width: 200px;
        height: 25px;
        margin-top: 0%;
        /* position: absolute; */
        margin-left: 23%;
        color: rgba(7, 60, 165, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
    }

    .bcontent{
        width: 90%;
        height: 36px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: center;
        /* position: relative; */
        margin-top: 14%;
        /* margin-left: -4%; */
        color: black;
        position: absolute;
        left: 50%;
    transform: translate(-50%, -50%);

    }
    
    .corporatessm{
        display: block;
        margin-top: 3%;
        margin-left: 14.6%;
    }

    .pinkbg{
        position: absolute;
        margin-top: 10%;
        margin-left: 6%;
        background:rgba(204, 5, 48, 1);
        border-top-left-radius: 100%;
        width: 165.1px;
        height: 223.68px;

    }

    .patlogolap{
      display: none !important;
    }
    .patlogo{
        width: 200px;
        height: 323.6px;
        margin-top: -1.91%;
        margin-left: 4%;
        position: relative;
        width: 174.23px;
      height: 273.14px;


    }

    .content a{
      display: none;
    }

    .partnertitle{
        width: 200px;
        height: 25px;
        margin-top: 0%;
        /* position: absolute; */
        margin-left: 6%;
        color: rgba(204, 5, 48, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
    }

    .pcontent{
        width: 90%;
        height: 36px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 15%;
        /* margin-left: -20%; */
        color: black;
        position: absolute;
        left: 50%;
    transform: translate(-50%, -50%);

    }

    .corporatesm{
      margin-top: 2.5em;
    }
    
    .partnersm{
        display: block;
        /* margin-top: 3%; */
        margin-top: 4.5em;
        margin-left: 14.6%;
    }

    .content3 h1  {
      display: none !important;
    }

    .content3 p{
      display: none !important;
    }

    .purplebg{
        /* width: 190px;
        height: 300px; */
        width: 156.73px;
height: 266.29px;
        position: absolute;
        margin-top: -30%;
        margin-left: 6%;
        background:rgba(137, 26, 97, 1);
        clip-path: polygon(0 0, 100% 54%, 100% 100%, 0 100%, 0% 38%);


    }

    .colleges img{
      display: none !important;
    }

    .collogo{

        width: 160.59px;
        height: 275.42px;
        margin-top: -44%;
        margin-left: 6%;
        z-index: 1;
        position: relative;
    }

    .collegetitle{
        width: 89px;
        height: 25px;
        margin-top: 0%;
        /* position: absolute; */
        margin-left: 6%;
        color: rgba(137, 26, 97, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
    }

    .ccontent{
        width: 90%;
        height: 36px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 13%;
        /* margin-left: -25%; */
        color: black;
        position: absolute;
        left: 50%;
    transform: translate(-50%, -50%);
    }
    
    .collegesm{
        display: block;
        margin-top: 60%;
        margin-left: 17%;

    }


    .yellowbg{
      width: 148.36px;
height: 204.66px;
        /* position: absolute; */
        margin-top: 40%;
        margin-left: 13%;
        background: rgba(233, 172, 1, 1);

    }
    .explogo{
      /* width: 220px;
      height: 330px; */
      width: 175.75px;
      height: 262.48px;
      margin-top: -39%;

    }

    .experiencedtitle{
        width: 89px;
        height: 25px;
        margin-top: 1%;
        margin-left: 12.5%;
        color: rgba(255, 188, 0, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
    }

    .econtent{
        width: 90%;
        height: 36px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 16%;
        /* margin-left: -75%; */
        position: absolute;
        left: 50%;
    transform: translate(-50%, -50%);

    }
    
    .experiencedsm{
        display: block;
        margin-top: 10%;
        margin-left: 14.6%;
    }

    .orgbg{
      width: 151.4px;
height: 204.66px;
/* margin-left: 12%; */
margin-left: auto;
margin-right: auto;
margin-top: -5%;
background: rgba(255, 91, 0, 1);
border-radius: 0px 100% 0px 0px;


    }

    /* .freshertitle{
      width: 96px;
height: 25px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
margin-left: 10%;
color: rgba(255, 91, 0, 1);
    } */
    
    
    
    .fresherlo{
        margin-top: -27.5%;
        margin-left: -19%;
        width: 202.38px;
height: 246.5px;



    }

    .fcontent{
      position: absolute;
        margin-top: 14%;
        left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 36px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
    }
    
    .fresherssm{
        display: block;
        margin-top: 10%;
        margin-left: -7%;

    }

    .logomid{
        width: 260px;
        height: 130px;
        margin-top: 5%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .line{
        height: 1px;
        width: 80%;
        background-color: rgba(56, 56, 71, 1);
        display: block;
        margin-left: auto;
        margin-right: auto;
        opacity: 50%;
    } 

    .scrollprofile{
        margin-top: 9%;
        width: 280px;
        height: 93px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        text-align: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 300;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        /* margin-bottom: 17%; */
        margin-bottom: 0.5em;
    }

    .para{
        /* margin-top: 6%; */
        width: 337px;
        height: 60px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
        display: block;
        margin-left: auto;
        margin-right: auto;
        /* margin-bottom: 15%; */
        margin-bottom: 2.5em;
        color: rgba(0, 0, 0, 1);
    }

    .Pickbutton{
      width: 167px;
height: 42px;
border-radius: 37.5px ;
background: rgba(255, 255, 255, 1);
border: 1px solid rgba(7, 60, 165, 1);
color: rgba(7, 60, 165, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 14.88px;
text-align: center;
display: block;
margin-left: auto;
margin-right: auto;
margin-bottom: 6em;
    }

    .fresherlogo{
    width: 235px;
height: 300px;
position: relative;
}

.freshertitle{
    width: 127px;
height: 22px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 91, 0, 1);
margin-left: 25%;
margin-top: 1%;
}



.freshers .fresherlog{
  display: none;
}
.content h1{
  display: none;
}

.content p{
  display: none;
}

.fbottom{
  display: none;
}

/* .experienced{
  display: none;
} */

.explogo1{
  display: none;
}

.content2{
  display: none;
}

.ebottom{
  display: none;
}

.colleges img{
  display: none;
}

.content3 h1{
  display: none;
}

.content3 p{
  display: none;
}

.cbottom{
  display: none;
}

.partner img{
  display: none;
}

.corporatss{
  display: none;
}
}