@media (min-width:1228px) and (max-width:1399px){
.viewrolepagemain{
    margin-bottom: 0%;
}
}

@media (max-width: 768px) {

    html,body{
        /* overflow-x: hidden; */
    }

}