.chatboxtext1cor,.chatboxtext21cor,.chatboxtext31cor,.chatboxtext41cor,.chatboxtext51cor{
    color: #000000;
}

.chatbox1cor:hover ,
.chatbox2cor:hover,
.chatbox3cor:hover,
.chatbox4cor:hover,
.chatbox5cor:hover
{
    transform: scale(1.05); 
  }

  .chatbox1cor ,
.chatbox2cor,
.chatbox3cor,
.chatbox4cor,
.chatbox5cor
  {
    transition: transform 0.3s ease; 
  }

@media (min-width: 1400px){

    .corpwidth1{
        width: 550px;
    }

    .corpwidth2{
        width: 550px;
    }

    .corpwidth3{
        width: 650px;
    }

    /* box click element start */

    .clickihv1crp{
        width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
    }

    .clickihv2crp{
        width: 34px;
height: 34px;
position: relative;
top: -5px;
left: 90px;
    }

    .clickihv3crp{
        width: 34px;
height: 34px;
position: relative;
top: -35px;
left: 90px;
    }

    .clickhv1crp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clickhv2crp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickhv3crp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clichv1crp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clichv2crp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clichv3crp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clihv1crp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clihv2crp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clihv3crp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    /* box click elemet end */


    .assesheadtitfretab{
        display: none;
    }

    /* circle animation  */

    .title2tagcorp2g{
        width: 1050px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: auto;
margin-right: auto;
    }

    .patmarimg1 {
        width: 220px;
        height: 220px; /* Adjust height as needed */
        background-size: 120%;
        background-color: white;
        border-radius: 100%;
        margin-top: 80px;
      }

      .patmarimg2 {
        width: 380px;
        height: 250px; /* Adjust height as needed */
        background-size: 120%;
        background-color: white;
        border-radius: 55px;
        margin-top: 40px;
        margin-left: -40px;
      }

      .gifcorp{
        position: absolute;
        top: -200px;
        left: 180px;
    }

    .mainfedex1{
        margin-left: -10%;
        position: relative;
    }

    .mainkfc1{
        margin-left: -15%;
        position: relative;
    }

    .mainkfc2{
        margin-left: 15px;
        position: relative;
    }

    .mainadidas1{
        margin-left: -10px;
        position: relative;
    }

    .mainburger1{
        margin-top: 2%;
        margin-left: -9%;
        position: relative;
    }

        /* start of circle animation  */
        @keyframes moveUp {
            0% { transform: translateY(30px); }
            100% { transform: translateY(-5px); }
        }
        
        .orgcircle4, .viocircle4, .bluecircle4 ,.yellowcircle4 {
            
            animation: none; /* Initially no animation */
        }
        
        .scrolling .orgcircle4 {
            animation: moveUp 0.8s forwards; /* Play animation once and stop */
        }
        
        .scrolling .viocircle4 {
            animation: moveUp 1.5s forwards; /* Slightly delay the animation */
        }
        
        .scrolling .bluecircle4 {
            animation: moveUp 2s forwards; /* Slightly delay the animation */
        }

        .scrolling .yellowcircle4 {
            animation: moveUp 2.5s forwards; /* Slightly delay the animation */
        }
        /* end of circle animation */

        /* start of boxes animation */

        .corpanimationcontainer{
            position: relative;
        }

        .dancing-divvexp {
            opacity: 0;
            transform: translateY(-400px);
            transition: 1s ;
        }
          
          .dancing-divvexp.visible {
            opacity: 1;
            transform: translateY(-100%);
            animation:  0.5s ease-in-out forwards ;
            
          }
          
          @keyframes shakeexp {
            0% { transform: translateX(0); }
            25% { transform: translateX(-10px); }
            50% { transform: translateX(10px); }
            75% { transform: translateX(-10px); }
            100% { transform: translateX(0); }
          }
          
          /* .shake {
            animation: shake 0.5s;
          } */
          
    
     
        .dancing-divexp {
            /* width: 100px;
            height: 100px;
            background-color: blue;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer; */
            transition: transform 0.5s ease; /* Smooth transition for the dancing effect */
          }
          
          .dancing-divexp.dancing {
            transform: translateY(-25px); /* Adjust the value as needed */
            animation: 60s ease-out alternate; /* Animation for the dancing effect */
          }
          
          @keyframes bounce {
            from {
              transform: translateY(-25px);
            }
            to {
              transform: translateY(0);
            }
          }
    
        /* end of boxes animation */

    /* click in core start  */

    .clickincorecorp1{
        width: 34px;
height: 34px;
position: relative;
left: -130px;
top: 150px;
cursor: pointer;
    }
    .clickincorecorp2{
        width: 34px;
height: 34px;
position: relative;
left: 66.5%;
top: -45px;
cursor: pointer;
    }
    .clickincorecorp3{
        width: 34px;
height: 34px;
position: relative;
left: 60%;
top: -53px;
cursor: pointer;
    }
    
    .clickincorecorp4{
        width: 34px;
height: 34px;
position: relative;
left: 88%;
top: -10px;
cursor: pointer;
    }

    /* click in core end */

    .trainingpattitleheader{
        width: 225px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
position: absolute;
margin-left: 6%;
margin-top: 8%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;

    }

    .patboxheaderpara{
        width: 517px;
height: 279px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
margin-top: 20%;
margin-left: 6%;
position: absolute;
    }

    .patboxheaderbutton{
        width: 173px;
height: 60px;
border-radius: 37.5px;
background: rgba(7, 60, 165, 1);
border: none;
position: absolute;
margin-top: 40%;
margin-left: 6%;
color: rgba(255, 255, 255, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
text-align: center;


    }

    .patboxheader{
        width: 542px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: left;
position: absolute;
margin-top: 12%;
margin-left: 6%;
    }

    .patdivcontainer{
        width: 1263px;
height: 701px;
border-radius: 81px;
/* opacity: 0px; */
background: rgba(209, 209, 209, 1);
overflow: hidden;
margin-top: 10%;
position: relative;
    }

    


    .imgscrollup {
        /* animation: scroll-up 10s linear infinite; */
        display: flex;
        flex-direction: column;
        margin-left: 70%;
      }

    .imgscrollup2 {
        /* animation: scroll-up 10s linear  infinite; */
        display: flex;
        flex-direction: column;
        margin-left: 19%;
      }
      
      @keyframes scroll-up {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-100%);
        }
      }

      .assesscorpdivcontainer{
        width: 1263px;
height: 701px;
border-radius: 81px;
/* opacity: 0px; */
background: rgba(255, 255, 255, 1);
overflow: hidden;
margin-top: 10%;
position: relative;
    }

      .assesspattitleheader{
        width: 225px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
position: absolute;
margin-left: 73%;
margin-top: 8%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;

    }

    .assesscorpboxheaderpara{
        width: 517px;
height: 279px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: right;
margin-top: 23%;
margin-left: 50%;
position: absolute;
    }

    .assesscorpboxheaderbutton{
        width: 173px;
height: 60px;
border-radius: 37.5px;
background: rgba(7, 60, 165, 1);
border: none;
position: absolute;
margin-top: 44%;
margin-left: 76%;
color: rgba(255, 255, 255, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
text-align: center;


    }

    .assesscorpboxheader{
        width: 700px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: left;
position: absolute;
margin-top: 15%;
margin-left: 36%;
    }

    .assesscorpdivcontainer{
        width: 1263px;
height: 701px;
border-radius: 81px;
/* opacity: 0px; */
background: rgba(209, 209, 209, 1);
overflow: hidden;
margin-top: 10%;
position: relative;
    }
      
     

    .chatcor{
        margin-top: 4%;
    }

    .corporatepagemain{
        margin-top: -2.5%;
    }

    /* start of brand */

        
    .brandhovercontainercorp{
        position: relative;
    }
    
    .accesshovercontainercorp{
        position: relative;
    }
    
    .autohovercontainercorp{
        position: relative;
    }
    
    .hirehovercontainercorp{
        position: relative;
    }
    
    
    .imagecontainerbrandcorp{
        /* position: relative; */
        transition: opacity 0.3s ease;
        /* cursor: pointer; */
        z-index: 1;
    }
    
    .imagecontaineraccesscorp{
        /* position: relative; */
        margin-top: -0.8%;
        transition: opacity 0.3s ease;
        /* cursor: pointer; */
        z-index: 1;
    }
    
    .imagecontainerautocorp{
        /* position: relative; */
        transition: opacity 0.3s ease;
        /* cursor: pointer; */
        z-index: 1;
    }
    
    .imagecontainerhirecorp{
        /* position: relative; */
        transition: opacity 0.3s ease;
        /* cursor: pointer; */
        z-index: 1;
    }

    .brandhovercontentcorp{
        position: relative;
        margin-top: -41%;
        left: 83px;
        background-color: #ffffff;
        width: 1140px;
        height: 470px;
        z-index: 1;
    
    }
    
    .accesshovercontentcorp{
        position: relative;
        margin-top: -40%;
        left: 83px;
        background-color: #ffffff;
        width: 1140px;
        height: 470px;
        z-index: 1;
    }
    
    .autohovercontentcorp{
        position: relative;
        margin-top: -43.5%;
        left: 83px;
        background-color: #ffffff;
        width: 1140px;
        height: 470px;
        z-index: 1;
    }
    
    .hirehovercontentcorp{
        position: relative;
        margin-top: -43.5%;
        left: 83px;
        background-color: #ffffff;
        width: 1140px;
        height: 470px;
        z-index: 1;
    }

    .containercorpcore{
        position: absolute;
    }
    
    
    
    /* .imagecontainerbrandcorp:hover + .brandhovercontentcorp {
        opacity: 1; 
      } */
    
      /* .imagecontaineraccesscorp:hover + .accesshovercontentcorp {
        opacity: 1; 
      } */
    
      /* .imagecontainerautocorp:hover + .autohovercontentcorp {
        opacity: 1; 
      } */
    
      /* .imagecontainerhirecorp:hover + .hirehovercontentcorp {
        opacity: 1; 
      } */
    
    
    
    .brandcontentcorp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
    }
    
    .brandheadercorp{
        width: 660px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 5%;
    position: absolute;
    margin-top: 2.5%;
    }
    
    .brandparacorp{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .brandhoverimagescorp{
        margin-left: 85%;
        margin-top: -40.3%;
        position: absolute;
    }
    
    .hoverimg1brandcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    /* margin-bottom: 3%; */
    position: absolute;
    }
    
    .brandhoverimg1bgcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -20%;
    position: relative;
    }
    
    .hoverimg2brandcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
   margin-top: 5%;
    position: absolute;
    }
    
    .brandhoverimg2bgcorp{
        width: 141px;
        height: 145px;
        border-radius: 20px;
        top: 7.5px;
        margin-bottom: -20%;
        background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
        opacity: 45%;
        position: relative;
    }
    
    .hoverimg3brandcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    /* margin-bottom: 2%; */
    margin-top: 10%;
    position: absolute;
    }
    
    .brandhoverimg3bgcorp{
        width: 141px;
        height: 145px;
        border-radius: 20px;
        background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
        opacity: 45%;
        position: relative;
        top: 14px;
    }
    
    .accesscontentcorp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
    }
    
    .accessheadercorp{
        width: 900px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 2.5%;
    margin-left: 5%;
    }
    
    .accessparacorp{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 7%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .accesshoverimagescorp{
        margin-left: 85%;
        margin-top: -40.5%;
    }
    
    .hoverimg1accesscorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }
    
    .accesshoverimg1bgcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    margin-bottom: -12%;
    }
    
    
    .hoverimg2accesscorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }
    
    .accesshoverimg2bgcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    margin-bottom: -12%;
    }

    .accesshoveimg2bgcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -12%;
    }

    .accesshovimg2bgcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -12%;
    }
    
    .hoverimg3accesscorp{
        position: absolute;
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
    }
    
    .accesshoverimg3bgcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    opacity: 45%;
    margin-bottom: 2%;
    }

    .accesshovimg3bgcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    margin-bottom: 2%;
    }
    
    .hireheadercorp{
        width: 700px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 2.5%;
    margin-left: 5%;
    }
    
    .hireparacorp{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .hirecontentcorp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
    
    }
    
    
    .autocontentcorp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%);
    
    }

    /* end of brand */

    .partsbox1{
        display: none;
    }

    .partsbox2{
        display: none;
    }
    .bimg1xt{
        position: absolute;
        top: 1400px;
    }
    
    body{
        overflow-x: hidden;
    }
    
    .mainfedex2{
        margin-top: 12%;
        position: absolute;
    }
    .title2tagcorp{
        width: fit-content;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    
    }
    .logos{
        /* padding: 20px 0 0 0; */
        /* padding-right: 0%; */
        /* margin-left: 0%; */
        white-space: nowrap;
    
    }
    
    .logos:hover .logo-slide 
    {
        /* animation-play-state: paused ; */
    }
    
    .logo-slide{
        display: inline-block;
        animation: 120s slide infinite linear;
    }
    
    .corpimgtop{
        position: relative;
    }
    
    .logo-slide img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logoss{
        /* overflow: hidden; */
        /* padding: 20px 0; */
        white-space: nowrap;
    
    }
    
    .logoss:hover .logos-slide 
    {
        /* animation-play-state: paused ; */
    }
    
    .logos-slide{
        display: inline-block;
        animation: 120s slides infinite linear;
    }
    
    .logos-slide img{
        margin: 0 40px;
        height: 100px;
    }


    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }
    
    
    .register1t{
        padding-top: 10%;
    }
    
    
    .contentt4{
        display: flex;
        justify-content: center;
        font-size: 40px;
        font-weight: 300;
        line-height: 48px;
        text-align: left;
        margin-left: 60px;
        margin-top: 170px;
        margin-bottom: 3%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        width: 590px;
        position: relative;
    }
    
    /* .contentt4 br {
        content: '';
        display: block;
        margin-bottom: 10px; 
    } */
    
    .watchs4corp{
        padding-top: 6%;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(7, 60, 165, 1);

    }
    
    .fresher1tag{
        /* align-items: center; */
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    /* text-indent: 10px; */
    margin-left: 7%;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 234px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    
    }
    .luck4{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  display: inline-block;
                  font-weight: 900;
                    padding-left: 10px;
    }
    
    .registert{
        margin-top: 8%;
    }
    .ftext{
        /* margin-top: 10px; */
        top: 15px;
        position: absolute;
        left: 70px;
    }
    
    .im-container1 {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
        margin-left: 10%;
    }
    
    .background-container4 {
        position: absolute;
        bottom: 0;
        left: 60%;
        transform: translateX(-50%);
        width: 282px;
        height: 467.91px;
        clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 38%);
        background-color: #073CA5;
    }
    
    .orgvector4 {
        /* z-index: 1;  */
        margin-left: 10%;
    }
    
    .orgvector4 img {
        display: block;
    }
    
    .violetbox4{
        background: #821B61;
        width: 203px;
    height: 203px;
    display: flex;
    position: relative;
    margin-left: 20%;
    margin-top: -33%;
    display: inline-block;
    text-align: start;
    }
    
    .vt1corp{
        width: 91px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    margin-top: 10%;
    margin-left: 10%;
    color: rgba(255, 255, 255, 1);
    }
    .im-container4{
        position: relative;
        margin-left: 22%;
        margin-top: -5%;
        /* z-index: 1; */
    }
    
    .vt2cor{
        width: 83px;
    height: 19px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    margin-top: 65px;
    margin-left: -85px;
    }
    
    .yellowbox4{
        background:#F7B400;
        width: 118px;
    height: 118px;
    display: flex;
    margin-left: 14.5%;
    /* margin-right: 190px; */
    display: inline-block;
    position: relative;
    margin-top: -9.2%;
    text-align: start;
    }
    
    .yt1{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    }
    
    .yt2{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 10px;
    }
    
    .im-container2, .im-container3, .im-container5 {
        position: relative;
        /* z-index: 2  ;  */
    }
    
    .sechead4{
        margin-top: 10%;
    }
    
    .firstsignup4{
        margin-top: -13%;
    }
    .siginscorp{
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 7%;
    color: rgba(255, 255, 255, 1);
    
    
    }
    
    .signincorp
    {
        width: 173px;
    height: 60px;
    position: relative;
    
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 9%;
    margin-left: 14%;
    background: rgba(7, 60, 165, 1);
    border: none;
    
    }
    
    .watchcorp{
        width: 200px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 9%;
    margin-left: 60px;
    /* margin-left: -390px; */
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(7, 60, 165, 1);
    }
    
    .linetextcorp{
        width: 1151px;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    /* display: flex;
    justify-content: center;
    align-content: center ; */
    margin-left: auto;
    margin-right: auto;
    /* margin-left: 140px; */
    margin-top: 8%;
    margin-bottom: 4em;
    
    }
    
    .linebox4{
    
        width: 160px;
    height: 160px;
    display: flex;
    /* margin-right: 100px; */
    display: inline-block;
    /* top: 0px; */
    position: relative;
    margin-top: -76%;
    text-align: start;
    border: 2px solid rgba(0, 0, 0, 1);
    margin-left: 24%;
    
    }
    
    .hlinebox1corp{
        width: 102px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 16%;
    position: absolute;
    opacity: 30%;
    
    }
    .hlinebox2corp{
        width: 40px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 30%;
    opacity: 30%;
    position: absolute;
    
    }
    
    
    .rlinebox1corp{
        width: 159px;
    height: 102px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 10%;
    position: absolute;
    }
    
    .rlinebox2corp{
        width: 159px;
    height: 40px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 50%;
    }
    
    .lineboxtext{
        width: 64px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    color: rgba(0, 0, 0, 1);
    
    }
    .lineboxtext2{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .title1tagcorp{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    /* text-indent: 10px; */
    margin-top: 0%;
    /* margin-left: 40%; */
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 390px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    
    }
    
    
    
    .imagecontainerbrand{
        position: relative;
    }

    .mid3contentcorp{
        margin-top: 2%;
        margin-bottom: 3em;
    }
    
    .mid3contentcorp .img1{
        width: 312px;
    height: 462px;
    margin-left: 85px;
    margin-top: -3px;
    border-radius: 50px;
    }
    .mid3contentcorp .bimg1{
        width: 312px;
    height: 462px;
    position: absolute;
    margin-top: -0.2%;
    margin-left: -24%;
    border-radius: 50px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    }
    
    .mid3contentcorp .bimg1textcorp{
        width: 146px;
    height: 84px;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    opacity: 100%;
    position: absolute;
    /* display: block; */
    margin-top: -140px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-left: 120px;
    
    }
    
    
    .imagecontaineraccess{
        position: relative;
    }
    
    .mid3contentcorp .img2{
        width: 513px;
    height: 231px;
    margin-top: -34.8%;
    margin-left: 32.5%;
    margin-bottom: 40%;
    border-radius: 50px;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    .mid3contentcorp .bimg2{
        width: 513px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-top: -34.8%;
    margin-left: -39.5%;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    
    }
    
    .bimg2textcorp{
        width: 400px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -45%;
    margin-left: 480px;
    opacity: 100%;
    position: relative;
    display: block;
    }
    
    .imagecontainerauto{
        position: relative;
    }
    
    
    
      
    
    .title2tag2corp{
        width: max-content;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    /* margin-left: 310px; */
    }
    .title2tag2corp2{
        width: 1050px;
height: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
    /* margin-left: 310px; */
    margin-left: auto;
    margin-right: auto;
    }
    
    .mid3contentcorp .img3{
        width: 768px;
    height: 212px;
    border-radius: 50px;
    margin-top: -17%;
    margin-left: 32.5%;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    
    .mid3contentcorp .bimg3{
        width: 768px;
    height: 212px;
    margin-top: -15.6%;
    margin-left: -59.2%;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    }
    
    .mid3contentcorp .bimg3text{
        width: 430px;
    height: 84px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    margin-left: 36%;
    display: block;
    margin-top: -9%;
    color:rgba(255, 255, 255, 1);
    
    }
    
    .imagecontainerhire{
        position: relative;
    }
    
    
    .mid3contentcorp .img4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    margin-top: -60.5%;
    margin-left: 74%;
    /* opacity: 45%px; */
    
    
    }
    
    .mid3contentcorp .bimg4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-left: -17.8%;
    margin-top: -38%;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    }
    
    .bimg4textcorp{
        width: 72px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    position: relative;
    display: block;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -32%;
    margin-left: 77%;
    
    }
    
    
    
    
    .phonebox4{
        margin: 14%;
    }
    
    .phonetitle1tag4corp{
        margin-top: 100px;
        /* align-items: center; */
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    /* position: relative; */
    /* text-indent: 10px; */
    /* margin-left: 17%; */
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 560px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }
    
    .phoneheadtitle4{
        /* margin-left: 120px; */
        width: 420px;
        height: 174px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: left;    
    }
    
    .phonebody4lap{
        width: 435px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: -130px;
    margin-top: 50px;
    }
    
    .phone4{
        width: 485px;
    height: 391px;
    margin-top: -290px;
    margin-left: 30px;
    position: relative;
    }
    
    .orgcircle4{
        width: 240.93px;
    height: 240.93px;
    margin-top: -200px;
    border-radius: 100%;
    margin-left: 100px;
    /* background: rgba(247, 91, 2, 1); */
    position: relative;
    /* opacity: 90%; */
    }
    
    .viocircle4{
        width: 240.93px;
    height: 240.93px;
    border-radius: 100%;
    /* background: rgba(130, 27, 97, 1); */
    margin-top: -120px;
    position: relative;
    opacity: 90%;
    }
    
    .viocircle4::before {
      content: '';
      position: absolute;
      margin-top: 27%;
      margin-left: -20%;
      width: 240.93px;
    height: 240.93px;
      border-radius: 50%;
      opacity: 100%;
    }
    
    .orgcircle4::before{
      content: '';
      position: absolute;
      margin-top: 35%;
      margin-left: -7%;
      width: 240.93px;
      height: 240.93px;
      border-radius: 50%;
      opacity: 90%;
    }
    
    .orgcircle4::before {
        background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
      }
      
      .viocircle4::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }
    
    .bluecircle4{
        width: 364.7px;
    height: 364.7px;
    border-radius: 100%;
    background: rgba(135, 189, 255, 1);
    margin-top: -280px;
    margin-left: 180px;
    position: absolute;
    }
    
    .yellowcircle4{
        width: 171.62px;
        height: 171.62px;
        border-radius: 100%;
        background: #F7B400; 
        position: absolute;
        margin-top: -83px;
        margin-left: 140px;
    }
    .arrow4{
        width: 256.46px;
    height: 189.91px;
    margin-left: -180px;
    margin-top: -170px;
    }
    .image-phone-container4{
        margin-left: 500px;
        margin-top: -400px;
    }
    
    .phonetitles4{
        margin-left: -130px;
    }
    

    
    .trainingtitles{
        margin-top: -590px;
    }
    
    .traintitle1tag{
        width: 225px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    }
    
    .trainheadtitle{
        width: 542px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    }
    .traincircle{
        width: 673px;
    height: 673px;
    background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -250px;
    
    }
    
    .head12{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .head13{
        margin-top: -80px;
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .register{
        width: 173px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: -100px;
    background: rgba(7, 60, 165, 1);
    border: none;
    
    
    }
    
    .assesscircle{
        width: 673px;
    height: 673px;
    background: rgba(9, 9, 63, 0.05);
    border-radius: 100%;
    margin-left: 900px;
    
    }
    
    .assesstitle1tag{
        margin-top: -570px;
        margin-left: 990px;
        width: 225px;
        height: 64px;
        border-radius: 37.5px;
        background: rgba(232, 237, 252, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;    
    }
    
    .assesheadtitle{
        width: 693px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: right;
    margin-left: 530px;
    }
    
    .assesshead1{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 150px;
    text-align: right;
    }
    
    .assesshead2{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 150px;
    text-align: right;
    margin-top: -50px;
    }
    
    .register2{
        width: 173px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 1050px;
    margin-top: -100px;
    border: none;
    background: rgba(7, 60, 165, 1);
    }
    
    .chat{
        margin-top: 200px;
    }
    
    .chatitle1tagcorp{
        width: 365px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 12%;
    
    }
    
    .ctext{
        margin-left: 30px;
        margin-top: 15px;
    }
    
    .chatheadtitlecorp{
        width: 600px;
    height: 174px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .chatimg1cor{
        width: 87px;
    height: 87px;
    margin-left: -2%;
    }
    
    .chatbox111cor{
        clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
        width: 84.16px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    margin-left: 11%;
    margin-top: -98.6%;
    position: relative;
    }
    
    .chatbox11cor{
        width: 378px;
    height: 615px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-left: 150px;
    margin-top: -80px;
    }
    
    .chatboxtext1cor{
        width: 370px;
    height: 529.92px;
    padding: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatbox2cor{
        margin-top: 0.5%;
    }
    
    .chatbox121cor{
        width: 81px;
    height: 64px;
    margin-top: -45.6%;
    margin-left: 63.5%;
    position: relative;
    background: rgba(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    }
    
    
    .chatbox21cor{
        margin-left: -30px;
        width: 435px;
        height: 284px;
        border-radius: 50px 0px 50px 50px;
        background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext21cor{
        width: 365px;
    height: 150px;
        padding: 30px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg2cor{
        width: 87px;
    height: 87px;
    margin-left: 470px;
    margin-top: -60px;
    }
    
    .chatbox3cor{
        margin-top: 15%;
        margin-bottom: 10%;
    }
    
    .chatbox131cor{
        width: 81px;
    height: 64px;
    background: rgba(207, 240, 230, 1);
    position: relative;
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    margin-top: -32.2%;
    margin-left: 60%;
    }
    
    .chatbox31cor{
        padding: 10px;
        margin-top: 60%;
        width: 358px;
       margin-left: 4%;
    height: 201px;
    border-radius: 50px 0px 50px 50px;
    background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext31cor{
        padding: 20px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg3cor{
        margin-top: -60px;
        margin-left: 440px;
    }
    
    .chatbox4cor{
        margin-top: 16%;
    }
    
    .chatimg4cor{
        width: 87px;
    height: 87px;
    margin-top: 50px;
    margin-left: -45px;
    position: absolute;
    }
    
    .chatbox141cor{
        width: 81px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    position: relative;
    margin-top: -35.3%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: 3%;
    }
    
    .chatbox41cor{
        padding: 10px;
        width: 475px;
    height: 220px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: 40px;
    margin-left: 99px;
    }
    .chatboxtext41cor{
        width: 388px;
    height: 90px;
        padding: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    
    }
    
    .chatbox5cor{
        margin-top: -9%;
        margin-bottom: 18%;
    }
    
    .chatimg5cor{
        width: 87px;
    height: 87px;
        margin-top: -120px;
        margin-left: -35px;
        position: absolute;
    }
    
    .chatbox51cor{
        padding: 10px;
        width: 455px;
    height: 200px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: -130px;
    margin-left: 110px;
    }
    
    .chatbox151cor{
        width: 81px;
    height: 64px;
    background: #E7F2FF;
    position: relative;
    margin-top: -32%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: 4.8%;
    }
    
    .chatboxtext51cor{
        padding: 20px;
        width: 399px;
    height: 194px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .mainbox2{
        width: 1263px;
    height: 701px;
    border-radius: 81px;
    opacity:50%;
    background: linear-gradient(180deg, #F7F7F7 0%, rgba(157, 160, 193, 0.5) 100%);
    position: relative;
    margin-top: 0%;
    z-index: 1;
    }
    
    .maintitle2{
        margin-top: -48%;
    margin-left: 5%;
    }
    
    .maintitletag2{
        width: 225px;
    height: 64px;
    border-radius: 32px;
    background: #E8EDFC;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    z-index: 2;
    }
    
    .mainheadtitle2{
        width: 542px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    z-index: 2;
    }
    
    .paras1{
        width: 517px;
    height: 140px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    z-index: 2;
    }
    
    .paras2{
        width: 517px;
        height: 140px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        position: relative;
        z-index: 2;
    }
    
    .registers3{
        width: 173px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    background: rgba(7, 60, 165, 1);
    border: none;
    position: relative;
    z-index: 2;
    margin-top: 8%;
    }
    
    .mainimg2 {
        position: absolute;
        margin-top: -35%;
        margin-left: 40%;
        z-index: 2;
    }
    
    .msfedex2{
        width: 168px;
        height: 168px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: -110px;
        margin-left: 80px;
        z-index: 1;
    }
    
    .mfedex2{
        width: 133px;
    height: 56px;
    margin-top: -80px;
    margin-left: 100px;
    position: relative;
    z-index: 2;
    }
    
    .mskfcs2{
        width: 112px;
    height: 112px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: 2px;
    margin-left: 396px;
    z-index: 1;
    }
    
    .mkfcs2{
        width: 106px;
    height: 111px;
    margin-left: 400px;
    position: relative;
    z-index: 2;
    }
    
    .msadidas2{
        width: 232px;
    height: 232px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: -20px;
    margin-left: 157px;
    z-index: 1;
    }
    
    .madidas2{
        width: 220px;
    height: 223px;
    margin-left: 160px;
    margin-top: -30px;
    position: relative;
    z-index: 2;
    }
    
    .msburger2{
        width: 168px;
    height: 168px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: 0px;
    margin-left: 445px;
    z-index: 1;
    }
    
    .mburger2{
        width: 159px;
    height: 172px;
    margin-left: 450px;
    position: relative;
    z-index: 2;
    }
    
    .msfedex3{
        width: 112px;
    height: 112px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: -150px;
    margin-left: 162px;
    z-index: 1;
    }
    
    .mfedex3{
        width: 79px;
    height: 34px;
    margin-top: -230px;
    margin-left: 180px;
    position: relative;
    z-index: 2;
    }
    
    .mskfc3{
        width: 112px;
    height: 112px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: -19px;
    margin-left: 296px;
    z-index: 1;
    }
    
    .mkfc3{
        width: 106px;
    height: 111px;
    margin-top: -20px;
    margin-left: 300px;
    position: relative;
    z-index: 2;
    }
    
    .main2box{
        width: 1263px;
    height: 701px;
    border-radius: 81px;
    opacity:50%;
    background: linear-gradient(180deg, #F7F7F7 0%, rgba(157, 160, 193, 0.5) 100%);
    position: relative;
    margin-top: 15%;
    z-index: 1;
    }
    
    .main2title{
        margin-left: 40%;
        margin-top: -48%;
    }
    
    .main2titletag{
        width: 225px;
    height: 64px;
    border-radius: 32px;
    background: #E8EDFC;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-left: 60%;
    z-index: 2;
    }
    
    .main2headtitle{
        width: 693px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: right;
    position: relative;
    z-index: 2;
    }
    
    .paras3{
        width: 517px;
    height: 140px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: right;
    position: relative;
    margin-left: 22%;
    z-index: 2;
    }
    
    .paras4{
        width: 517px;
        height: 140px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: right;
        position: relative;
        margin-left: 22%;
        z-index: 2;
    }
    
    .registers4{
        margin-top: 8%;
        width: 173px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    background: rgba(7, 60, 165, 1);
    border: none;
    position: relative;
    margin-left: 65%;
    z-index: 2;
    }
    
    .main2img {
        position: absolute;
        margin-top: -29%;
        margin-left: 10%;
        z-index: 2;
    }
    
    .asfedex{
        width: 168px;
        height: 168px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: -130px;
        margin-left: 245px;
        z-index: 1;
    }
    
    .afedex{
        width: 133px;
    height: 56px;
    margin-top: -100px;
    margin-left: 260px;
    position: relative;
    z-index: 2;
    }
    
    .askfc{
        width: 112px;
        height: 112px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: -19px;
        margin-left: 37px;
        z-index: 1;
    }
    
    .akfc{
        width: 106px;
    height: 111px;
    margin-top: -20px;
    margin-left: 40px;
    position: relative;
    z-index: 2;
    }
    
    .asadidas{
        width: 232px;
        height: 232px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: 10px;
        margin-left: 130px;
        z-index: 1;
    }
    
    .aadidas{
        width: 220px;
    height: 223px;
    margin-left: 130px;
    position: relative;
    z-index: 2;
    }
    
    .asburger{
        width: 168px;
        height: 168px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: -20px;
        margin-left: -65px;
        z-index: 1;
    }
    
    .aburger{
        width: 159px;
    height: 172px;
    margin-top: -20px;
    margin-left: -60px;
    position: relative;
    z-index: 2;
    }
    
    .asfedex2{
        width: 112px;
        height: 112px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: -125px;
        margin-left: 243px;
        z-index: 1;
    }
    
    .afedex2{
        width: 79px;
    height: 34px;
    margin-top: -170px;
    margin-left: 260px;
    position: relative;
    z-index: 2;
    }
    
    .askfc2{
        width: 112px;
        height: 112px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: -40px;
        margin-left: 126px;
        z-index: 1;
    }
    
    .akfc2{
        width: 106px;
        height: 111px;
        margin-top: -40px;
        margin-left: 130px;
        position: relative;
        z-index: 2;
    }
    
    .startedcorp{
        padding: 0 50px 0 50px;
        margin-top: 10%;
        margin-bottom: 4.5em;
    }
    
    .startedboxcorp{
        margin-top: -15%;
        width: 1207px;
    height: 227px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 100px;
    
    }
    
    .subbuttontabcorp{
        margin-left: 880px;
        margin-top: -250px;
        height: 50px;
        width: 175px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }
    
    .startheadcorp{
        width: 1000px;
    height: 87px;
    margin-left: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    
    }
    
    .regis{
        width: fit-content;
    height: 19px;
        margin-top: 12px;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    
    }
    
    .startparalapcorp{
        width: 613px;
    height: 62px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 40px;
    margin-top: 0%;
    }
    
    .bluec4{
        width: 54px;
    height: 54px;
    background: rgba(7, 60, 165, 1);
    margin-left: 380px;
    margin-top: 42%;
    border-radius: 100%;
    }
    
    .whiteacorp{
        width: 24px;
height: 30px;
    position: absolute;
margin-top: 2.3%;
margin-left: 3%;
        /* left: 50%;
    transform: translate(-50%, -50%); */
    }
    
    .namesmcor{
        width: 250px;
    height: 30px;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .desgsmcor{
        width: 134px;
    height: 30px;
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-top: -30px;
    margin-left: 28%;
    }

    .corevalues2corp{
        display: none;
    }
}

@media (min-width:1228px) and (max-width:1399px){
    .corporatepagemain{
        max-width:1300px;
        transform: scale(0.83);
        transform-origin: top;
        margin-bottom: -550px;
    }

    .corpwidth1{
        width: 550px;
    }

    .corpwidth2{
        width: 550px;
    }

    .corpwidth3{
        width: 650px;
    }
    
     /* box click element start */

     .clickihv1crp{
        width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
    }

    .clickihv2crp{
        width: 34px;
height: 34px;
position: relative;
top: -5px;
left: 90px;
    }

    .clickihv3crp{
        width: 34px;
height: 34px;
position: relative;
top: -35px;
left: 90px;
    }

    .clickhv1crp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clickhv2crp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickhv3crp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clichv1crp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clichv2crp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clichv3crp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clihv1crp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clihv2crp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clihv3crp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    /* box click elemet end */

    .title2tagcorp2g{
        width: 1050px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: auto;
margin-right: auto;
    }

    .patmarimg {
        /* width: 110px; */
        height: 110px; /* Adjust height as needed */
        background-size: 120%;
        background-color: white;
        border-radius: 100%;
      }

      .gifcorp{
        position: absolute;
        top: -200px;
        left: 180px;
    }

    .mainfedex1{
        margin-left: -10%;
        position: relative;
    }

    .mainkfc1{
        margin-left: -15%;
        position: relative;
    }

    .mainkfc2{
        margin-left: 15px;
        position: relative;
    }

    .mainadidas1{
        margin-left: -10px;
        position: relative;
    }

    .mainburger1{
        margin-top: 2%;
        margin-left: -9%;
        position: relative;
    }

        /* start of circle animation  */
        @keyframes moveUp {
            0% { transform: translateY(30px); }
            100% { transform: translateY(-5px); }
        }
        
        .orgcircle4, .viocircle4, .bluecircle4 ,.yellowcircle4 {
            
            animation: none; /* Initially no animation */
        }
        
        .scrolling .orgcircle4 {
            animation: moveUp 0.8s forwards; /* Play animation once and stop */
        }
        
        .scrolling .viocircle4 {
            animation: moveUp 1.5s forwards; /* Slightly delay the animation */
        }
        
        .scrolling .bluecircle4 {
            animation: moveUp 2s forwards; /* Slightly delay the animation */
        }

        .scrolling .yellowcircle4 {
            animation: moveUp 2.5s forwards; /* Slightly delay the animation */
        }
        /* end of circle animation */

        /* start of boxes animation */

        .corpanimationcontainer{
            position: relative;
        }

        .dancing-divvexp {
            opacity: 0;
            transform: translateY(-400px);
            transition: 1s ;
        }
          
          .dancing-divvexp.visible {
            opacity: 1;
            transform: translateY(-100%);
            animation:  0.5s ease-in-out forwards ;
            
          }
          
          @keyframes shakeexp {
            0% { transform: translateX(0); }
            25% { transform: translateX(-10px); }
            50% { transform: translateX(10px); }
            75% { transform: translateX(-10px); }
            100% { transform: translateX(0); }
          }
          
          /* .shake {
            animation: shake 0.5s;
          } */
          
    
     
        .dancing-divexp {
            /* width: 100px;
            height: 100px;
            background-color: blue;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer; */
            transition: transform 0.5s ease; /* Smooth transition for the dancing effect */
          }
          
          .dancing-divexp.dancing {
            transform: translateY(-25px); /* Adjust the value as needed */
            animation: 60s ease-out alternate; /* Animation for the dancing effect */
          }
          
          @keyframes bounce {
            from {
              transform: translateY(-25px);
            }
            to {
              transform: translateY(0);
            }
          }
    
        /* end of boxes animation */

    /* click in core start  */

    .clickincorecorp1{
        width: 34px;
height: 34px;
position: relative;
left: -130px;
top: 150px;
cursor: pointer;
    }
    .clickincorecorp2{
        width: 34px;
height: 34px;
position: relative;
left: 66.5%;
top: -45px;
cursor: pointer;
    }
    .clickincorecorp3{
        width: 34px;
height: 34px;
position: relative;
left: 60%;
top: -53px;
cursor: pointer;
    }
    
    .clickincorecorp4{
        width: 34px;
height: 34px;
position: relative;
left: 88%;
top: -10px;
cursor: pointer;
    }

    /* click in core end */

    .trainingpattitleheader{
        width: 225px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
position: absolute;
margin-left: 6%;
margin-top: 8%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;

    }

    .patboxheaderpara{
        width: 517px;
height: 279px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
margin-top: 23%;
margin-left: 6%;
position: absolute;
    }

    .patboxheaderbutton{
        width: 173px;
height: 60px;
border-radius: 37.5px;
background: rgba(7, 60, 165, 1);
border: none;
position: absolute;
margin-top: 44%;
margin-left: 6%;
color: rgba(255, 255, 255, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
text-align: center;


    }

    .patboxheader{
        width: 542px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: left;
position: absolute;
margin-top: 15%;
margin-left: 6%;
    }

    .patdivcontainer{
        width: 1263px;
height: 701px;
border-radius: 81px;
/* opacity: 0px; */
background: rgba(209, 209, 209, 1);
overflow: hidden;
margin-top: 10%;
position: relative;
    }

    


    .imgscrollup {
        animation: scroll-up 10s linear infinite;
        display: flex;
        flex-direction: column;
        margin-left: 70%;
      }

    .imgscrollup2 {
        animation: scroll-up 10s linear  infinite;
        display: flex;
        flex-direction: column;
        margin-left: 19%;
      }
      
      @keyframes scroll-up {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-100%);
        }
      }

      .assesscorpdivcontainer{
        width: 1263px;
height: 701px;
border-radius: 81px;
/* opacity: 0px; */
background: rgba(255, 255, 255, 1);
overflow: hidden;
margin-top: 10%;
position: relative;
    }

      .assesspattitleheader{
        width: 225px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
position: absolute;
margin-left: 73%;
margin-top: 8%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;

    }

    .assesscorpboxheaderpara{
        width: 517px;
height: 279px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: right;
margin-top: 23%;
margin-left: 50%;
position: absolute;
    }

    .assesscorpboxheaderbutton{
        width: 173px;
height: 60px;
border-radius: 37.5px;
background: rgba(7, 60, 165, 1);
border: none;
position: absolute;
margin-top: 44%;
margin-left: 76%;
color: rgba(255, 255, 255, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
text-align: center;


    }

    .assesscorpboxheader{
        width: 700px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: left;
position: absolute;
margin-top: 15%;
margin-left: 36%;
    }

    .assesscorpdivcontainer{
        width: 1263px;
height: 701px;
border-radius: 81px;
/* opacity: 0px; */
background: rgba(209, 209, 209, 1);
overflow: hidden;
margin-top: 10%;
position: relative;
    }
      
     

    .chatcor{
        margin-top: 4%;
    }

    .corporatepagemain{
        margin-top: -2.5%;
    }

    /* start of brand */

        
    .brandhovercontainercorp{
        position: relative;
    }
    
    .accesshovercontainercorp{
        position: relative;
    }
    
    .autohovercontainercorp{
        position: relative;
    }
    
    .hirehovercontainercorp{
        position: relative;
    }
    
    
    .imagecontainerbrandcorp{
        /* position: relative; */
        transition: opacity 0.3s ease;
        /* cursor: pointer; */
        z-index: 1;
    }
    
    .imagecontaineraccesscorp{
        /* position: relative; */
        margin-top: -0.8%;
        transition: opacity 0.3s ease;
        /* cursor: pointer; */
        z-index: 1;
    }
    
    .imagecontainerautocorp{
        /* position: relative; */
        transition: opacity 0.3s ease;
        /* cursor: pointer; */
        z-index: 1;
    }
    
    .imagecontainerhirecorp{
        /* position: relative; */
        transition: opacity 0.3s ease;
        /* cursor: pointer; */
        z-index: 1;
    }

    .brandhovercontentcorp{
        position: relative;
        margin-top: -41%;
        left: 60px;
        background-color: #ffffff;
        width: 1140px;
        height: 470px;
        z-index: 1;
    
    }
    
    .accesshovercontentcorp{
        position: relative;
        margin-top: -40%;
        left: 60px;
        background-color: #ffffff;
        width: 1140px;
        height: 470px;
        z-index: 1;
    }
    
    .autohovercontentcorp{
        position: relative;
        margin-top: -44%;
        left: 60px;
        background-color: #ffffff;
        width: 1140px;
        height: 470px;
        z-index: 1;
    }
    
    .hirehovercontentcorp{
        position: relative;
        margin-top: -44%;
        left: 60px;
        background-color: #ffffff;
        width: 1140px;
        height: 470px;
        z-index: 1;
    }

    .containercorpcore{
        position: absolute;
    }
    
    
    
    /* .imagecontainerbrandcorp:hover + .brandhovercontentcorp {
        opacity: 1; 
      } */
    
      /* .imagecontaineraccesscorp:hover + .accesshovercontentcorp {
        opacity: 1; 
      } */
    
      /* .imagecontainerautocorp:hover + .autohovercontentcorp {
        opacity: 1; 
      } */
    
      /* .imagecontainerhirecorp:hover + .hirehovercontentcorp {
        opacity: 1; 
      } */
    
    
    
    .brandcontentcorp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
    }
    
    .brandheadercorp{
        width: 660px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 5%;
    position: absolute;
    margin-top: 2.5%;
    }
    
    .brandparacorp{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .brandhoverimagescorp{
        margin-left: 85%;
        margin-top: -40.3%;
        position: absolute;
    }
    
    .hoverimg1brandcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    position: absolute;
    }
    
    .brandhoverimg1bgcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -20%; 
    position: relative;
    }
    
    .hoverimg2brandcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
   margin-top: 5%;
    position: absolute;
    }
    
    .brandhoverimg2bgcorp{
        width: 141px;
        height: 145px;
        border-radius: 20px;
        top: 7.5px;
        margin-bottom: -20%;
        background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
        opacity: 45%;
        position: relative;
    }
    
    .hoverimg3brandcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-top: 10%;
    position: absolute;
    }
    
    .brandhoverimg3bgcorp{
        width: 141px;
        height: 145px;
        border-radius: 20px;
        background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
        opacity: 45%;
        position: relative;
        top: 14px;
    }
    
    .accesscontentcorp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
    }
    
    .accessheadercorp{
        width: 900px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 2.5%;
    margin-left: 5%;
    }
    
    .accessparacorp{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 7%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .accesshoverimagescorp{
        margin-left: 85%;
        margin-top: -40.5%;
    }
    
    .hoverimg1accesscorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }
    
    .accesshoverimg1bgcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    margin-bottom: -12%;
    }
    
    
    .hoverimg2accesscorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }
    
    .accesshoverimg2bgcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    margin-bottom: -12%;
    }

    .accesshoveimg2bgcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -12%;
    }

    .accesshovimg2bgcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -12%;
    }
    
    .hoverimg3accesscorp{
        position: absolute;
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
    }
    
    .accesshoverimg3bgcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    opacity: 45%;
    margin-bottom: 2%;
    }
    
    .accesshovimg3bgcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    margin-bottom: 2%;
    }

    .hireheadercorp{
        width: 700px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 2.5%;
    margin-left: 5%;
    }
    
    .hireparacorp{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .hirecontentcorp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
    
    }
    
    
    .autocontentcorp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%);
    
    }

    /* end of brand */

    .partsbox1{
        display: none;
    }

    .partsbox2{
        display: none;
    }
    .bimg1xt{
        position: absolute;
        top: 1400px;
    }
    
    body{
        overflow-x: hidden;
    }
    
    .mainfedex2{
        margin-top: 12%;
        position: absolute;
    }
    .title2tagcorp{
        width: fit-content;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    
    }
    .logos{
        /* padding: 20px 0 0 0; */
        /* padding-right: 0%; */
        /* margin-left: 0%; */
        white-space: nowrap;
    
    }
    
    .logos:hover .logo-slide 
    {
        /* animation-play-state: paused ; */
    }
    
    .logo-slide{
        display: inline-block;
        animation: 120s slide infinite linear;
    }
    
    .corpimgtop{
        position: relative;
    }
    
    .logo-slide img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logoss{
        /* overflow: hidden; */
        /* padding: 20px 0; */
        white-space: nowrap;
    
    }
    
    .logoss:hover .logos-slide 
    {
        /* animation-play-state: paused ; */
    }
    
    .logos-slide{
        display: inline-block;
        animation: 120s slides infinite linear;
    }
    
    .logos-slide img{
        margin: 0 40px;
        height: 100px;
    }


    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }
    
    
    .register1t{
        padding-top: 10%;
    }
    
    
    .contentt4{
        display: flex;
        justify-content: center;
        font-size: 48px;
        font-weight: 300;
        line-height: 59.52px;
        text-align: left;
        margin-left: 60px;
        margin-top: 90px;
        margin-bottom: 3%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        width: 595px;
        position: relative;
    }
    
    /* .contentt4 br {
        content: '';
        display: block;
        margin-bottom: 10px; 
    } */
    
    .watchs4corp{
        padding-top: 6%;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(7, 60, 165, 1);

    }
    
    .fresher1tag{
        /* align-items: center; */
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    /* text-indent: 10px; */
    margin-left: 7%;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 234px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    
    }
    .luck4{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  display: inline-block;
                  font-weight: 900;
                    padding-left: 10px;
    }
    
    .registert{
        margin-top: 8%;
    }
    .ftext{
        /* margin-top: 10px; */
        top: 15px;
        position: absolute;
        left: 70px;
    }
    
    .im-container1 {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
        margin-left: 10%;
    }
    
    .background-container4 {
        position: absolute;
        bottom: 0;
        left: 60%;
        transform: translateX(-50%);
        width: 282px;
        height: 467.91px;
        clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 38%);
        background-color: #073CA5;
    }
    
    .orgvector4 {
        /* z-index: 1;  */
        margin-left: 10%;
    }
    
    .orgvector4 img {
        display: block;
    }
    
    .violetbox4{
        background: #821B61;
        width: 203px;
    height: 203px;
    display: flex;
    position: relative;
    margin-left: 20%;
    margin-top: -33%;
    display: inline-block;
    text-align: start;
    }
    
    .vt1corp{
        width: 91px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    margin-top: 10%;
    margin-left: 10%;
    color: rgba(255, 255, 255, 1);
    }
    .im-container4{
        position: relative;
        margin-left: 22%;
        margin-top: -5%;
        /* z-index: 1; */
    }
    
    .vt2cor{
        width: 83px;
    height: 19px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    margin-top: 65px;
    margin-left: -85px;
    }
    
    .yellowbox4{
        background:#F7B400;
        width: 118px;
    height: 118px;
    display: flex;
    margin-left: 14.5%;
    /* margin-right: 190px; */
    display: inline-block;
    position: relative;
    margin-top: -9.2%;
    text-align: start;
    }
    
    .yt1{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    }
    
    .yt2{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 10px;
    }
    
    .im-container2, .im-container3, .im-container5 {
        position: relative;
        /* z-index: 2  ;  */
    }
    
    .sechead4{
        margin-top: 10%;
    }
    
    .firstsignup4{
        margin-top: -13%;
    }
    .siginscorp{
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 7%;
    color: rgba(255, 255, 255, 1);
    
    
    }
    
    .signincorp
    {
        width: 173px;
    height: 60px;
    position: relative;
    
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 9%;
    margin-left: 14%;
    background: rgba(7, 60, 165, 1);
    border: none;
    
    }
    
    .watchcorp{
        width: 200px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 9%;
    margin-left: 60px;
    /* margin-left: -390px; */
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(7, 60, 165, 1);
    }
    
    .linetextcorp{
        width: 1151px;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    /* display: flex;
    justify-content: center;
    align-content: center ; */
    margin-left: auto;
    margin-right: auto;
    /* margin-left: 140px; */
    margin-top: 8%;
    margin-bottom: 4em;
    
    }
    
    .linebox4{
    
        width: 160px;
    height: 160px;
    display: flex;
    /* margin-right: 100px; */
    display: inline-block;
    /* top: 0px; */
    position: relative;
    margin-top: -83%;
    text-align: start;
    border: 2px solid rgba(0, 0, 0, 1);
    margin-left: 21.5%;
    
    }
    
    .hlinebox1corp{
        width: 102px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 16%;
    position: absolute;
    opacity: 30%;
    
    }
    .hlinebox2corp{
        width: 40px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 30%;
    opacity: 30%;
    position: absolute;
    
    }
    
    
    .rlinebox1corp{
        width: 159px;
    height: 102px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 10%;
    position: absolute;
    }
    
    .rlinebox2corp{
        width: 159px;
    height: 40px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 50%;
    }
    
    .lineboxtext{
        width: 64px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    color: rgba(0, 0, 0, 1);
    
    }
    .lineboxtext2{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .title1tagcorp{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    /* text-indent: 10px; */
    margin-top: 0%;
    /* margin-left: 40%; */
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 390px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    
    }
    
    
    
    .imagecontainerbrand{
        position: relative;
    }

    .mid3contentcorp{
        margin-top: 2%;
        margin-bottom: 3em;
    }
    
    .mid3contentcorp .img1{
        width: 312px;
    height: 462px;
    margin-left: 75px;
    margin-top: -3px;
    border-radius: 50px;
    }
    .mid3contentcorp .bimg1{
        width: 312px;
    height: 462px;
    position: absolute;
    margin-top: -0.2%;
    margin-left: -25.2%;
    border-radius: 50px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    }
    
    .mid3contentcorp .bimg1textcorp{
        width: 146px;
    height: 84px;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    opacity: 100%;
    position: absolute;
    /* display: block; */
    margin-top: -140px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-left: 120px;
    
    }
    
    
    .imagecontaineraccess{
        position: relative;
    }
    
    .mid3contentcorp .img2{
        width: 513px;
    height: 231px;
    margin-top: -36.7%;
    margin-left: 33%;
    margin-bottom: 40%;
    border-radius: 50px;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    .mid3contentcorp .bimg2{
        width: 513px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-top: -36.7%;
    margin-left: -41.5%;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    
    }
    
    .bimg2textcorp{
        width: 400px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -45%;
    margin-left: 430px;
    opacity: 100%;
    position: relative;
    display: block;
    }
    
    .imagecontainerauto{
        position: relative;
    }
    
    
    
      
    
    .title2tag2corp{
        width: max-content;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    /* margin-left: 310px; */
    }
    .title2tag2corp2{
        width: 1050px;
height: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
    /* margin-left: 310px; */
    margin-left: auto;
    margin-right: auto;
    }
    
    .mid3contentcorp .img3{
        width: 768px;
    height: 212px;
    border-radius: 50px;
    margin-top: -18%;
    margin-left: 33%;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    
    .mid3contentcorp .bimg3{
        width: 768px;
    height: 212px;
    margin-top: -16.5%;
    margin-left: -62.25%;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    }
    
    .mid3contentcorp .bimg3text{
        width: 430px;
    height: 84px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    margin-left: 36%;
    display: block;
    margin-top: -9%;
    color:rgba(255, 255, 255, 1);
    
    }
    
    .imagecontainerhire{
        position: relative;
    }
    
    
    .mid3contentcorp .img4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    margin-top: -64.7%;
    margin-left: 76%;
    /* opacity: 45%px; */
    
    
    }
    
    .mid3contentcorp .bimg4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-left: -18.7%;
    margin-top: -40.5%;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    }
    
    .bimg4textcorp{
        width: 72px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    position: relative;
    display: block;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -34.8%;
    margin-left: 77%;
    
    }
    
    
    
    
    .phonebox4{
        margin: 14%;
    }
    
    .phonetitle1tag4corp{
        margin-top: 100px;
        /* align-items: center; */
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    /* position: relative; */
    /* text-indent: 10px; */
    /* margin-left: 17%; */
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 560px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }
    
    .phoneheadtitle4{
        /* margin-left: 120px; */
        width: 420px;
        height: 174px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: left;    
    }
    
    .phonebody4lap{
        width: 435px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: -130px;
    margin-top: 50px;
    }
    
    .phone4{
        width: 485px;
    height: 391px;
    margin-top: -290px;
    margin-left: 30px;
    position: relative;
    }
    
    .orgcircle4{
        width: 240.93px;
    height: 240.93px;
    margin-top: -200px;
    border-radius: 100%;
    margin-left: 100px;
    /* background: rgba(247, 91, 2, 1); */
    position: relative;
    /* opacity: 90%; */
    }
    
    .viocircle4{
        width: 240.93px;
    height: 240.93px;
    border-radius: 100%;
    /* background: rgba(130, 27, 97, 1); */
    margin-top: -120px;
    position: relative;
    opacity: 90%;
    }
    
    .viocircle4::before {
      content: '';
      position: absolute;
      margin-top: 27%;
      margin-left: -20%;
      width: 240.93px;
    height: 240.93px;
      border-radius: 50%;
      opacity: 100%;
    }
    
    .orgcircle4::before{
      content: '';
      position: absolute;
      margin-top: 35%;
      margin-left: -7%;
      width: 240.93px;
      height: 240.93px;
      border-radius: 50%;
      opacity: 90%;
    }
    
    .orgcircle4::before {
        background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
      }
      
      .viocircle4::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }
    
    .bluecircle4{
        width: 364.7px;
    height: 364.7px;
    border-radius: 100%;
    background: rgba(135, 189, 255, 1);
    margin-top: -280px;
    margin-left: 180px;
    position: absolute;
    }
    
    .yellowcircle4{
        width: 171.62px;
        height: 171.62px;
        border-radius: 100%;
        background: #F7B400; 
        position: absolute;
        margin-top: -83px;
        margin-left: 140px;
    }
    .arrow4{
        width: 256.46px;
    height: 189.91px;
    margin-left: -180px;
    margin-top: -170px;
    }
    .image-phone-container4{
        margin-left: 500px;
        margin-top: -400px;
    }
    
    .phonetitles4{
        margin-left: -130px;
    }
    

    
    .trainingtitles{
        margin-top: -590px;
    }
    
    .traintitle1tag{
        width: 225px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    }
    
    .trainheadtitle{
        width: 542px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    }
    .traincircle{
        width: 673px;
    height: 673px;
    background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -250px;
    
    }
    
    .head12{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .head13{
        margin-top: -80px;
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .register{
        width: 173px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: -100px;
    background: rgba(7, 60, 165, 1);
    border: none;
    
    
    }
    
    .assesscircle{
        width: 673px;
    height: 673px;
    background: rgba(9, 9, 63, 0.05);
    border-radius: 100%;
    margin-left: 900px;
    
    }
    
    .assesstitle1tag{
        margin-top: -570px;
        margin-left: 990px;
        width: 225px;
        height: 64px;
        border-radius: 37.5px;
        background: rgba(232, 237, 252, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;    
    }
    
    .assesheadtitle{
        width: 693px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: right;
    margin-left: 530px;
    }
    
    .assesshead1{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 150px;
    text-align: right;
    }
    
    .assesshead2{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 150px;
    text-align: right;
    margin-top: -50px;
    }
    
    .register2{
        width: 173px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 1050px;
    margin-top: -100px;
    border: none;
    background: rgba(7, 60, 165, 1);
    }
    
    .chat{
        margin-top: 200px;
    }
    
    .chatitle1tagcorp{
        width: 365px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 12%;
    
    }
    
    .ctext{
        margin-left: 30px;
        margin-top: 15px;
    }
    
    .chatheadtitlecorp{
        width: 600px;
    height: 174px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .chatimg1cor{
        width: 87px;
    height: 87px;
    margin-left: -2%;
    }
    
    .chatbox111cor{
        clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
        width: 84.16px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    margin-left: 12%;
    margin-top: -103.8%;
    position: relative;
    }
    
    .chatbox11cor{
        width: 378px;
    height: 615px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-left: 150px;
    margin-top: -80px;
    }
    
    .chatboxtext1cor{
        width: 370px;
    height: 529.92px;
    padding: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatbox2cor{
        margin-top: 0.5%;
    }
    
    .chatbox121cor{
        width: 81px;
    height: 64px;
    margin-top: -48%;
    margin-left: 65%;
    position: relative;
    background: rgba(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    }
    
    
    .chatbox21cor{
        margin-left: -30px;
        width: 435px;
        height: 284px;
        border-radius: 50px 0px 50px 50px;
        background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext21cor{
        width: 365px;
    height: 150px;
        padding: 30px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg2cor{
        width: 87px;
    height: 87px;
    margin-left: 470px;
    margin-top: -60px;
    }
    
    .chatbox3cor{
        margin-top: 18%;
        margin-bottom: 10%;
    }
    
    .chatbox131cor{
        width: 81px;
    height: 64px;
    background: rgba(207, 240, 230, 1);
    position: relative;
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    margin-top: -34%;
    margin-left: 60%;
    }
    
    .chatbox31cor{
        padding: 10px;
        margin-top: 60%;
        width: 358px;
       margin-left: 4%;
    height: 201px;
    border-radius: 50px 0px 50px 50px;
    background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext31cor{
        padding: 20px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg3cor{
        margin-top: -60px;
        margin-left: 440px;
    }
    
    .chatbox4cor{
        margin-top: 16%;
    }
    
    .chatimg4cor{
        width: 87px;
    height: 87px;
    margin-top: 50px;
    margin-left: -45px;
    position: absolute;
    }
    
    .chatbox141cor{
        width: 81px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    position: relative;
    margin-top: -37.2%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: 4%;
    }
    
    .chatbox41cor{
        padding: 10px;
        width: 475px;
    height: 220px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: 40px;
    margin-left: 99px;
    }
    .chatboxtext41cor{
        width: 388px;
    height: 90px;
        padding: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    
    }
    
    .chatbox5cor{
        margin-top: -9%;
        margin-bottom: 18%;
    }
    
    .chatimg5cor{
        width: 87px;
    height: 87px;
        margin-top: -120px;
        margin-left: -35px;
        position: absolute;
    }
    
    .chatbox51cor{
        padding: 10px;
        width: 455px;
    height: 200px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: -130px;
    margin-left: 110px;
    }
    
    .chatbox151cor{
        width: 81px;
    height: 64px;
    background: #E7F2FF;
    position: relative;
    margin-top: -33.6%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: 5.4%;
    }
    
    .chatboxtext51cor{
        padding: 20px;
        width: 399px;
    height: 194px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .mainbox2{
        width: 1263px;
    height: 701px;
    border-radius: 81px;
    opacity:50%;
    background: linear-gradient(180deg, #F7F7F7 0%, rgba(157, 160, 193, 0.5) 100%);
    position: relative;
    margin-top: 0%;
    z-index: 1;
    }
    
    .maintitle2{
        margin-top: -48%;
    margin-left: 5%;
    }
    
    .maintitletag2{
        width: 225px;
    height: 64px;
    border-radius: 32px;
    background: #E8EDFC;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    z-index: 2;
    }
    
    .mainheadtitle2{
        width: 542px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    z-index: 2;
    }
    
    .paras1{
        width: 517px;
    height: 140px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    z-index: 2;
    }
    
    .paras2{
        width: 517px;
        height: 140px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        position: relative;
        z-index: 2;
    }
    
    .registers3{
        width: 173px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    background: rgba(7, 60, 165, 1);
    border: none;
    position: relative;
    z-index: 2;
    margin-top: 8%;
    }
    
    .mainimg2 {
        position: absolute;
        margin-top: -35%;
        margin-left: 40%;
        z-index: 2;
    }
    
    .msfedex2{
        width: 168px;
        height: 168px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: -110px;
        margin-left: 80px;
        z-index: 1;
    }
    
    .mfedex2{
        width: 133px;
    height: 56px;
    margin-top: -80px;
    margin-left: 100px;
    position: relative;
    z-index: 2;
    }
    
    .mskfcs2{
        width: 112px;
    height: 112px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: 2px;
    margin-left: 396px;
    z-index: 1;
    }
    
    .mkfcs2{
        width: 106px;
    height: 111px;
    margin-left: 400px;
    position: relative;
    z-index: 2;
    }
    
    .msadidas2{
        width: 232px;
    height: 232px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: -20px;
    margin-left: 157px;
    z-index: 1;
    }
    
    .madidas2{
        width: 220px;
    height: 223px;
    margin-left: 160px;
    margin-top: -30px;
    position: relative;
    z-index: 2;
    }
    
    .msburger2{
        width: 168px;
    height: 168px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: 0px;
    margin-left: 445px;
    z-index: 1;
    }
    
    .mburger2{
        width: 159px;
    height: 172px;
    margin-left: 450px;
    position: relative;
    z-index: 2;
    }
    
    .msfedex3{
        width: 112px;
    height: 112px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: -150px;
    margin-left: 162px;
    z-index: 1;
    }
    
    .mfedex3{
        width: 79px;
    height: 34px;
    margin-top: -230px;
    margin-left: 180px;
    position: relative;
    z-index: 2;
    }
    
    .mskfc3{
        width: 112px;
    height: 112px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: -19px;
    margin-left: 296px;
    z-index: 1;
    }
    
    .mkfc3{
        width: 106px;
    height: 111px;
    margin-top: -20px;
    margin-left: 300px;
    position: relative;
    z-index: 2;
    }
    
    .main2box{
        width: 1263px;
    height: 701px;
    border-radius: 81px;
    opacity:50%;
    background: linear-gradient(180deg, #F7F7F7 0%, rgba(157, 160, 193, 0.5) 100%);
    position: relative;
    margin-top: 15%;
    z-index: 1;
    }
    
    .main2title{
        margin-left: 40%;
        margin-top: -48%;
    }
    
    .main2titletag{
        width: 225px;
    height: 64px;
    border-radius: 32px;
    background: #E8EDFC;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-left: 60%;
    z-index: 2;
    }
    
    .main2headtitle{
        width: 693px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: right;
    position: relative;
    z-index: 2;
    }
    
    .paras3{
        width: 517px;
    height: 140px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: right;
    position: relative;
    margin-left: 22%;
    z-index: 2;
    }
    
    .paras4{
        width: 517px;
        height: 140px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: right;
        position: relative;
        margin-left: 22%;
        z-index: 2;
    }
    
    .registers4{
        margin-top: 8%;
        width: 173px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    background: rgba(7, 60, 165, 1);
    border: none;
    position: relative;
    margin-left: 65%;
    z-index: 2;
    }
    
    .main2img {
        position: absolute;
        margin-top: -29%;
        margin-left: 10%;
        z-index: 2;
    }
    
    .asfedex{
        width: 168px;
        height: 168px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: -130px;
        margin-left: 245px;
        z-index: 1;
    }
    
    .afedex{
        width: 133px;
    height: 56px;
    margin-top: -100px;
    margin-left: 260px;
    position: relative;
    z-index: 2;
    }
    
    .askfc{
        width: 112px;
        height: 112px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: -19px;
        margin-left: 37px;
        z-index: 1;
    }
    
    .akfc{
        width: 106px;
    height: 111px;
    margin-top: -20px;
    margin-left: 40px;
    position: relative;
    z-index: 2;
    }
    
    .asadidas{
        width: 232px;
        height: 232px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: 10px;
        margin-left: 130px;
        z-index: 1;
    }
    
    .aadidas{
        width: 220px;
    height: 223px;
    margin-left: 130px;
    position: relative;
    z-index: 2;
    }
    
    .asburger{
        width: 168px;
        height: 168px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: -20px;
        margin-left: -65px;
        z-index: 1;
    }
    
    .aburger{
        width: 159px;
    height: 172px;
    margin-top: -20px;
    margin-left: -60px;
    position: relative;
    z-index: 2;
    }
    
    .asfedex2{
        width: 112px;
        height: 112px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: -125px;
        margin-left: 243px;
        z-index: 1;
    }
    
    .afedex2{
        width: 79px;
    height: 34px;
    margin-top: -170px;
    margin-left: 260px;
    position: relative;
    z-index: 2;
    }
    
    .askfc2{
        width: 112px;
        height: 112px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: -40px;
        margin-left: 126px;
        z-index: 1;
    }
    
    .akfc2{
        width: 106px;
        height: 111px;
        margin-top: -40px;
        margin-left: 130px;
        position: relative;
        z-index: 2;
    }
    
    .startedcorp{
        padding: 0 50px 0 50px;
        margin-top: 10%;
        /* margin-bottom: 1em; */
    }
    
    .startedboxcorp{
        margin-top: -15%;
        width: 1207px;
    height: 227px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 100px;
    
    }
    
    .subbuttontabcorp{
        margin-left: 880px;
        margin-top: -250px;
        height: 50px;
        width: 175px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }
    
    .startheadcorp{
        width: 1000px;
    height: 87px;
    margin-left: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    
    }
    
    .regis{
        width: fit-content;
    height: 19px;
        margin-top: 12px;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    
    }
    
    .startparalapcorp{
        width: 613px;
    height: 62px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 40px;
    margin-top: 0%;
    }
    
    .bluec4{
        width: 54px;
    height: 54px;
    background: rgba(7, 60, 165, 1);
    margin-left: 380px;
    margin-top: 42%;
    border-radius: 100%;
    }
    
    .whiteacorp{
        width: 24px;
height: 30px;
    position: absolute;
margin-top: 2.3%;
margin-left: 3%;
        /* left: 50%;
    transform: translate(-50%, -50%); */
    }
    
    .namesmcor{
        width: 250px;
    height: 30px;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .desgsmcor{
        width: 134px;
    height: 30px;
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-top: -30px;
    margin-left: 28%;
    }

    .corevalues2corp{
        display: none;
    }
}

@media (min-width:1027px) and (max-width:1227px){
    .corporatepagemain{
        max-width:1300px;
        transform: scale(0.83);
        transform-origin: top;
        margin-bottom: -650px;
    }

    .corpwidth1{
        width: 550px;
    }

    .corpwidth2{
        width: 550px;
    }

    .corpwidth3{
        width: 650px;
    }
    
     /* box click element start */

     .clickihv1crp{
        width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
    }

    .clickihv2crp{
        width: 34px;
height: 34px;
position: relative;
top: -5px;
left: 90px;
    }

    .clickihv3crp{
        width: 34px;
height: 34px;
position: relative;
top: -35px;
left: 90px;
    }

    .clickhv1crp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clickhv2crp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickhv3crp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clichv1crp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clichv2crp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clichv3crp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clihv1crp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clihv2crp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clihv3crp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    /* box click elemet end */

    .title2tagcorp2g{
        width: 1050px;
height: 40px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
margin-left: auto;
margin-right: auto;
    }

    .patmarimg {
        /* width: 110px; */
        height: 110px; /* Adjust height as needed */
        background-size: 120%;
        background-color: white;
        border-radius: 100%;
      }

      .gifcorp{
        position: absolute;
        top: -200px;
        left: 180px;
    }

    .mainfedex1{
        margin-left: -10%;
        position: relative;
    }

    .mainkfc1{
        margin-left: -15%;
        position: relative;
    }

    .mainkfc2{
        margin-left: 15px;
        position: relative;
    }

    .mainadidas1{
        margin-left: -10px;
        position: relative;
    }

    .mainburger1{
        margin-top: 2%;
        margin-left: -9%;
        position: relative;
    }

        /* start of circle animation  */
        @keyframes moveUp {
            0% { transform: translateY(30px); }
            100% { transform: translateY(-5px); }
        }
        
        .orgcircle4, .viocircle4, .bluecircle4 ,.yellowcircle4 {
            
            animation: none; /* Initially no animation */
        }
        
        .scrolling .orgcircle4 {
            animation: moveUp 0.8s forwards; /* Play animation once and stop */
        }
        
        .scrolling .viocircle4 {
            animation: moveUp 1.5s forwards; /* Slightly delay the animation */
        }
        
        .scrolling .bluecircle4 {
            animation: moveUp 2s forwards; /* Slightly delay the animation */
        }

        .scrolling .yellowcircle4 {
            animation: moveUp 2.5s forwards; /* Slightly delay the animation */
        }
        /* end of circle animation */

        /* start of boxes animation */

        .corpanimationcontainer{
            position: relative;
        }

        .dancing-divvexp {
            opacity: 0;
            transform: translateY(-400px);
            transition: 1s ;
        }
          
          .dancing-divvexp.visible {
            opacity: 1;
            transform: translateY(-100%);
            animation:  0.5s ease-in-out forwards ;
            
          }
          
          @keyframes shakeexp {
            0% { transform: translateX(0); }
            25% { transform: translateX(-10px); }
            50% { transform: translateX(10px); }
            75% { transform: translateX(-10px); }
            100% { transform: translateX(0); }
          }
          
          /* .shake {
            animation: shake 0.5s;
          } */
          
    
     
        .dancing-divexp {
            /* width: 100px;
            height: 100px;
            background-color: blue;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer; */
            transition: transform 0.5s ease; /* Smooth transition for the dancing effect */
          }
          
          .dancing-divexp.dancing {
            transform: translateY(-25px); /* Adjust the value as needed */
            animation: 60s ease-out alternate; /* Animation for the dancing effect */
          }
          
          @keyframes bounce {
            from {
              transform: translateY(-25px);
            }
            to {
              transform: translateY(0);
            }
          }
    
        /* end of boxes animation */

    /* click in core start  */

    .clickincorecorp1{
        width: 34px;
height: 34px;
position: relative;
left: -175px;
top: 140px;
cursor: pointer;
    }
    .clickincorecorp2{
        width: 34px;
height: 34px;
position: relative;
left: 66.5%;
top: -45px;
cursor: pointer;
    }
    .clickincorecorp3{
        width: 34px;
height: 34px;
position: relative;
left: 60%;
top: -53px;
cursor: pointer;
    }
    
    .clickincorecorp4{
        width: 34px;
height: 34px;
position: relative;
left: 92%;
top: -10px;
cursor: pointer;
    }

    /* click in core end */

    .trainingpattitleheader{
        width: 225px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
position: absolute;
margin-left: 6%;
margin-top: 8%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;

    }

    .patboxheaderpara{
        width: 517px;
height: 279px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: left;
margin-top: 23%;
margin-left: 6%;
position: absolute;
    }

    .patboxheaderbutton{
        width: 173px;
height: 60px;
border-radius: 37.5px;
background: rgba(7, 60, 165, 1);
border: none;
position: absolute;
margin-top: 44%;
margin-left: 6%;
color: rgba(255, 255, 255, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
text-align: center;


    }

    .patboxheader{
        width: 542px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: left;
position: absolute;
margin-top: 15%;
margin-left: 6%;
    }

    .patdivcontainer{
        width: 1263px;
height: 701px;
border-radius: 81px;
/* opacity: 0px; */
background: rgba(209, 209, 209, 1);
overflow: hidden;
margin-top: 10%;
position: relative;
    }

    


    .imgscrollup {
        animation: scroll-up 10s linear infinite;
        display: flex;
        flex-direction: column;
        margin-left: 70%;
      }

    .imgscrollup2 {
        animation: scroll-up 10s linear  infinite;
        display: flex;
        flex-direction: column;
        margin-left: 19%;
      }
      
      @keyframes scroll-up {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-100%);
        }
      }

      .assesscorpdivcontainer{
        width: 1263px;
height: 701px;
border-radius: 81px;
/* opacity: 0px; */
background: rgba(255, 255, 255, 1);
overflow: hidden;
margin-top: 10%;
position: relative;
    }

      .assesspattitleheader{
        width: 225px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
position: absolute;
margin-left: 73%;
margin-top: 8%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;

    }

    .assesscorpboxheaderpara{
        width: 517px;
height: 279px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: right;
margin-top: 23%;
margin-left: 50%;
position: absolute;
    }

    .assesscorpboxheaderbutton{
        width: 173px;
height: 60px;
border-radius: 37.5px;
background: rgba(7, 60, 165, 1);
border: none;
position: absolute;
margin-top: 44%;
margin-left: 76%;
color: rgba(255, 255, 255, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
text-align: center;


    }

    .assesscorpboxheader{
        width: 700px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 86.8px;
text-align: left;
position: absolute;
margin-top: 15%;
margin-left: 36%;
    }

    .assesscorpdivcontainer{
        width: 1263px;
height: 701px;
border-radius: 81px;
/* opacity: 0px; */
background: rgba(209, 209, 209, 1);
overflow: hidden;
margin-top: 10%;
position: relative;
    }
      
     

    .chatcor{
        margin-top: 4%;
    }

    .corporatepagemain{
        margin-top: -2.5%;
    }

    /* start of brand */

        
    .brandhovercontainercorp{
        position: relative;
    }
    
    .accesshovercontainercorp{
        position: relative;
    }
    
    .autohovercontainercorp{
        position: relative;
    }
    
    .hirehovercontainercorp{
        position: relative;
    }
    
    
    .imagecontainerbrandcorp{
        /* position: relative; */
        transition: opacity 0.3s ease;
        /* cursor: pointer; */
        z-index: 1;
    }
    
    .imagecontaineraccesscorp{
        /* position: relative; */
        margin-top: -0.8%;
        transition: opacity 0.3s ease;
        /* cursor: pointer; */
        z-index: 1;
    }
    
    .imagecontainerautocorp{
        /* position: relative; */
        transition: opacity 0.3s ease;
        /* cursor: pointer; */
        z-index: 1;
    }
    
    .imagecontainerhirecorp{
        /* position: relative; */
        transition: opacity 0.3s ease;
        /* cursor: pointer; */
        z-index: 1;
    }

    .brandhovercontentcorp{
        position: relative;
        margin-top: -41%;
        left: 10px;
        background-color: #ffffff;
        width: 1140px;
        height: 470px;
        z-index: 1;
    
    }
    
    .accesshovercontentcorp{
        position: relative;
        margin-top: -40%;
        left: 10px;
        background-color: #ffffff;
        width: 1140px;
        height: 470px;
        z-index: 1;
    }
    
    .autohovercontentcorp{
        position: relative;
        margin-top: -44%;
        left: 10px;
        background-color: #ffffff;
        width: 1140px;
        height: 470px;
        z-index: 1;
    }
    
    .hirehovercontentcorp{
        position: relative;
        margin-top: -44%;
        left: 10px;
        background-color: #ffffff;
        width: 1140px;
        height: 470px;
        z-index: 1;
    }

    .containercorpcore{
        position: absolute;
    }
    
    
    
    /* .imagecontainerbrandcorp:hover + .brandhovercontentcorp {
        opacity: 1; 
      } */
    
      /* .imagecontaineraccesscorp:hover + .accesshovercontentcorp {
        opacity: 1; 
      } */
    
      /* .imagecontainerautocorp:hover + .autohovercontentcorp {
        opacity: 1; 
      } */
    
      /* .imagecontainerhirecorp:hover + .hirehovercontentcorp {
        opacity: 1; 
      } */
    
    
    
    .brandcontentcorp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
    }
    
    .brandheadercorp{
        width: 660px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 5%;
    position: absolute;
    margin-top: 2.5%;
    }
    
    .brandparacorp{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .brandhoverimagescorp{
        margin-left: 85%;
        margin-top: -40.3%;
        position: absolute;
    }
    
    .hoverimg1brandcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    position: absolute;
    }
    
    .brandhoverimg1bgcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -20%; 
    position: relative;
    }
    
    .hoverimg2brandcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
   margin-top: 5%;
    position: absolute;
    }
    
    .brandhoverimg2bgcorp{
        width: 141px;
        height: 145px;
        border-radius: 20px;
        top: 7.5px;
        margin-bottom: -20%;
        background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
        opacity: 45%;
        position: relative;
    }
    
    .hoverimg3brandcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    margin-top: 10%;
    position: absolute;
    }
    
    .brandhoverimg3bgcorp{
        width: 141px;
        height: 145px;
        border-radius: 20px;
        background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
        opacity: 45%;
        position: relative;
        top: 14px;
    }
    
    .accesscontentcorp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
    }
    
    .accessheadercorp{
        width: 900px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 2.5%;
    margin-left: 5%;
    }
    
    .accessparacorp{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 7%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .accesshoverimagescorp{
        margin-left: 85%;
        margin-top: -40.5%;
    }
    
    .hoverimg1accesscorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }
    
    .accesshoverimg1bgcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    margin-bottom: -12%;
    }
    
    
    .hoverimg2accesscorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
        position: absolute;
    }
    
    .accesshoverimg2bgcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    margin-bottom: -12%;
    }

    .accesshoveimg2bgcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -12%;
    }

    .accesshovimg2bgcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    opacity: 45%;
    margin-bottom: -12%;
    }
    
    .hoverimg3accesscorp{
        position: absolute;
        width: 141px;
    height: 145px;
    border-radius: 20px;
        margin-top: 0%;
        margin-bottom: 2%;
    }
    
    .accesshoverimg3bgcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    opacity: 45%;
    margin-bottom: 2%;
    }
    
    .accesshovimg3bgcorp{
        width: 141px;
    height: 145px;
    border-radius: 20px;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    opacity: 45%;
    margin-bottom: 2%;
    }

    .hireheadercorp{
        width: 700px;
    height: 54px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 45px;
    font-weight: 900;
    line-height: 54.46px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 2.5%;
    margin-left: 5%;
    }
    
    .hireparacorp{
        width: 854px;
    height: 217px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-top: 6%;
    margin-left: 2%;
    padding: 3%;
    }
    
    .hirecontentcorp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
    
    }
    
    
    .autocontentcorp{
        width: 948px;
    height: 462px;
    border-radius: 50px;
    background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%);
    
    }

    /* end of brand */

    .partsbox1{
        display: none;
    }

    .partsbox2{
        display: none;
    }
    .bimg1xt{
        position: absolute;
        top: 1400px;
    }
    
    body{
        overflow-x: hidden;
    }
    
    .mainfedex2{
        margin-top: 12%;
        position: absolute;
    }
    .title2tagcorp{
        width: fit-content;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    
    }
    .logos{
        /* padding: 20px 0 0 0; */
        /* padding-right: 0%; */
        /* margin-left: 0%; */
        white-space: nowrap;
    
    }
    
    .logos:hover .logo-slide 
    {
        /* animation-play-state: paused ; */
    }
    
    .logo-slide{
        display: inline-block;
        animation: 120s slide infinite linear;
    }
    
    .corpimgtop{
        position: relative;
    }
    
    .logo-slide img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logoss{
        /* overflow: hidden; */
        /* padding: 20px 0; */
        white-space: nowrap;
    
    }
    
    .logoss:hover .logos-slide 
    {
        /* animation-play-state: paused ; */
    }
    
    .logos-slide{
        display: inline-block;
        animation: 120s slides infinite linear;
    }
    
    .logos-slide img{
        margin: 0 40px;
        height: 100px;
    }


    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }
    
    
    .register1t{
        padding-top: 10%;
    }
    
    
    .contentt4{
        display: flex;
        justify-content: center;
        font-size: 48px;
        font-weight: 300;
        line-height: 59.52px;
        text-align: left;
        margin-left: 60px;
        margin-top: 90px;
        margin-bottom: 3%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        width: 595px;
        position: relative;
    }
    
    /* .contentt4 br {
        content: '';
        display: block;
        margin-bottom: 10px; 
    } */
    
    .watchs4corp{
        padding-top: 6%;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(7, 60, 165, 1);

    }
    
    .fresher1tag{
        /* align-items: center; */
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    /* text-indent: 10px; */
    margin-left: 7%;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 234px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    
    }
    .luck4{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  display: inline-block;
                  font-weight: 900;
                    padding-left: 10px;
    }
    
    .registert{
        margin-top: 8%;
    }
    .ftext{
        /* margin-top: 10px; */
        top: 15px;
        position: absolute;
        left: 70px;
    }
    
    .im-container1 {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
        margin-left: 10%;
    }
    
    .background-container4 {
        position: absolute;
        bottom: 0;
        left: 60%;
        transform: translateX(-50%);
        width: 282px;
        height: 467.91px;
        clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 38%);
        background-color: #073CA5;
    }
    
    .orgvector4 {
        /* z-index: 1;  */
        margin-left: 10%;
    }
    
    .orgvector4 img {
        display: block;
    }
    
    .violetbox4{
        background: #821B61;
        width: 203px;
    height: 203px;
    display: flex;
    position: relative;
    margin-left: 20%;
    margin-top: -33%;
    display: inline-block;
    text-align: start;
    }
    
    .vt1corp{
        width: 91px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    margin-top: 10%;
    margin-left: 10%;
    color: rgba(255, 255, 255, 1);
    }
    .im-container4{
        position: relative;
        margin-left: 22%;
        margin-top: -5%;
        /* z-index: 1; */
    }
    
    .vt2cor{
        width: 83px;
    height: 19px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    margin-top: 65px;
    margin-left: -85px;
    }
    
    .yellowbox4{
        background:#F7B400;
        width: 118px;
    height: 118px;
    display: flex;
    margin-left: 14.5%;
    /* margin-right: 190px; */
    display: inline-block;
    position: relative;
    margin-top: -9.2%;
    text-align: start;
    }
    
    .yt1{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    }
    
    .yt2{
        width: 66px;
    height: 44px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 10px;
    }
    
    .im-container2, .im-container3, .im-container5 {
        position: relative;
        /* z-index: 2  ;  */
    }
    
    .sechead4{
        margin-top: 10%;
    }
    
    .firstsignup4{
        margin-top: -13%;
    }
    .siginscorp{
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 7%;
    color: rgba(255, 255, 255, 1);
    
    
    }
    
    .signincorp
    {
        width: 173px;
    height: 60px;
    position: relative;
    
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 9%;
    margin-left: 14%;
    background: rgba(7, 60, 165, 1);
    border: none;
    
    }
    
    .watchcorp{
        width: 200px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 9%;
    margin-left: 60px;
    /* margin-left: -390px; */
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(7, 60, 165, 1);
    }
    
    .linetextcorp{
        width: 1151px;
    height: 93px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    /* display: flex;
    justify-content: center;
    align-content: center ; */
    margin-left: auto;
    margin-right: auto;
    /* margin-left: 140px; */
    margin-top: 8%;
    margin-bottom: 4em;
    
    }
    
    .linebox4{
    
        width: 160px;
    height: 160px;
    display: flex;
    /* margin-right: 100px; */
    display: inline-block;
    /* top: 0px; */
    position: relative;
    margin-top: -83%;
    text-align: start;
    border: 2px solid rgba(0, 0, 0, 1);
    margin-left: 21.5%;
    
    }
    
    .hlinebox1corp{
        width: 102px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 16%;
    position: absolute;
    opacity: 30%;
    
    }
    .hlinebox2corp{
        width: 40px;
    height: 159px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-left: 30%;
    opacity: 30%;
    position: absolute;
    
    }
    
    
    .rlinebox1corp{
        width: 159px;
    height: 102px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 10%;
    position: absolute;
    }
    
    .rlinebox2corp{
        width: 159px;
    height: 40px;
    padding: 0px, 0px, 0px, 0px;
    border: 1px solid rgba(0, 0, 0, 1);
    margin-top: 30px;
    opacity: 50%;
    }
    
    .lineboxtext{
        width: 64px;
    height: 45px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
    color: rgba(0, 0, 0, 1);
    
    }
    .lineboxtext2{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .title1tagcorp{
        align-items: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    position: relative;
    /* text-indent: 10px; */
    margin-top: 0%;
    /* margin-left: 40%; */
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 390px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    
    }
    
    
    
    .imagecontainerbrand{
        position: relative;
    }

    .mid3contentcorp{
        margin-top: 2%;
        margin-bottom: 3em;
    }
    
    .mid3contentcorp .img1{
        width: 312px;
    height: 462px;
    margin-left: 10px;
    margin-top: -3px;
    border-radius: 50px;
    }
    .mid3contentcorp .bimg1{
        width: 312px;
    height: 462px;
    position: absolute;
    margin-top: -0.2%;
    margin-left: -27.8%;
    border-radius: 50px;
    background: linear-gradient(180deg, #000000 8.85%, #F75B02 74.48%);
    opacity: 45%;
    }
    
    .mid3contentcorp .bimg1textcorp{
        width: 146px;
    height: 84px;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    opacity: 100%;
    position: absolute;
    /* display: block; */
    margin-top: -155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-left: 40px;
    
    }
    
    
    .imagecontaineraccess{
        position: relative;
    }
    
    .mid3contentcorp .img2{
        width: 513px;
    height: 231px;
    margin-top: -40.5%;
    margin-left: 30.5%;
    margin-bottom: 40%;
    border-radius: 50px;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    .mid3contentcorp .bimg2{
        width: 513px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-top: -40.5%;
    margin-left: -45.8%;
    background: linear-gradient(180deg, #000000 16.94%, #F7B400 75.01%);
    
    }
    
    .bimg2textcorp{
        width: 400px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -45%;
    margin-left: 370px;
    opacity: 100%;
    position: relative;
    display: block;
    }
    
    .imagecontainerauto{
        position: relative;
    }
    
    
    
      
    
    .title2tag2corp{
        width: max-content;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    /* margin-left: 310px; */
    }
    .title2tag2corp2{
        width: 1050px;
height: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
    /* margin-left: 310px; */
    margin-left: auto;
    margin-right: auto;
    }
    
    .mid3contentcorp .img3{
        width: 768px;
    height: 212px;
    border-radius: 50px;
    margin-top: -20%;
    margin-left: 30.5%;
    /* opacity: 0.45px; */
    /* position: absolute; */
    
    }
    
    .mid3contentcorp .bimg3{
        width: 768px;
    height: 212px;
    margin-top: -18.2%;
    margin-left: -68.55%;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    background: linear-gradient(180deg, #000000 16.18%, #821B61 61.27%);
    }
    
    .mid3contentcorp .bimg3text{
        width: 430px;
    height: 84px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    margin-left: 33%;
    display: block;
    margin-top: -10%;
    color:rgba(255, 255, 255, 1);
    
    }
    
    .imagecontainerhire{
        position: relative;
    }
    
    
    .mid3contentcorp .img4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    margin-top: -71%;
    margin-left: 78%;
    /* opacity: 45%px; */
    
    
    }
    
    .mid3contentcorp .bimg4{
        width: 231px;
    height: 231px;
    border-radius: 50px;
    opacity: 45%;
    position: absolute;
    margin-left: -20.7%;
    margin-top: -44.5%;
    background: linear-gradient(180deg, #000000 26.68%, #073C9D 72.57%);
    }
    
    .bimg4textcorp{
        width: 72px;
    height: 42px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0em;
    position: relative;
    display: block;
    text-align: left;
    color:rgba(255, 255, 255, 1);
    margin-top: -37.8%;
    margin-left: 80%;
    
    }
    
    
    
    
    .phonebox4{
        margin: 14%;
    }
    
    .phonetitle1tag4corp{
        margin-top: 100px;
        /* align-items: center; */
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    /* position: relative; */
    /* text-indent: 10px; */
    /* margin-left: 17%; */
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    width: 560px;
    height: 64px;
    border-radius: 32px;
    background-color: rgba(232, 237, 252, 1);
    }
    
    .phoneheadtitle4{
        /* margin-left: 120px; */
        width: 420px;
        height: 174px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: left;    
    }
    
    .phonebody4lap{
        width: 435px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: -130px;
    margin-top: 50px;
    }
    
    .phone4{
        width: 485px;
    height: 391px;
    margin-top: -290px;
    margin-left: 30px;
    position: relative;
    }
    
    .orgcircle4{
        width: 240.93px;
    height: 240.93px;
    margin-top: -200px;
    border-radius: 100%;
    margin-left: 100px;
    /* background: rgba(247, 91, 2, 1); */
    position: relative;
    /* opacity: 90%; */
    }
    
    .viocircle4{
        width: 240.93px;
    height: 240.93px;
    border-radius: 100%;
    /* background: rgba(130, 27, 97, 1); */
    margin-top: -120px;
    position: relative;
    opacity: 90%;
    }
    
    .viocircle4::before {
      content: '';
      position: absolute;
      margin-top: 27%;
      margin-left: -20%;
      width: 240.93px;
    height: 240.93px;
      border-radius: 50%;
      opacity: 100%;
    }
    
    .orgcircle4::before{
      content: '';
      position: absolute;
      margin-top: 35%;
      margin-left: -7%;
      width: 240.93px;
      height: 240.93px;
      border-radius: 50%;
      opacity: 90%;
    }
    
    .orgcircle4::before {
        background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
      }
      
      .viocircle4::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }
    
    .bluecircle4{
        width: 364.7px;
    height: 364.7px;
    border-radius: 100%;
    background: rgba(135, 189, 255, 1);
    margin-top: -280px;
    margin-left: 180px;
    position: absolute;
    }
    
    .yellowcircle4{
        width: 171.62px;
        height: 171.62px;
        border-radius: 100%;
        background: #F7B400; 
        position: absolute;
        margin-top: -83px;
        margin-left: 140px;
    }
    .arrow4{
        width: 256.46px;
    height: 189.91px;
    margin-left: -180px;
    margin-top: -170px;
    }
    .image-phone-container4{
        margin-left: 500px;
        margin-top: -400px;
    }
    
    .phonetitles4{
        margin-left: -130px;
    }
    

    
    .trainingtitles{
        margin-top: -590px;
    }
    
    .traintitle1tag{
        width: 225px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    }
    
    .trainheadtitle{
        width: 542px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    }
    .traincircle{
        width: 673px;
    height: 673px;
    background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -250px;
    
    }
    
    .head12{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .head13{
        margin-top: -80px;
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .register{
        width: 173px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: -100px;
    background: rgba(7, 60, 165, 1);
    border: none;
    
    
    }
    
    .assesscircle{
        width: 673px;
    height: 673px;
    background: rgba(9, 9, 63, 0.05);
    border-radius: 100%;
    margin-left: 900px;
    
    }
    
    .assesstitle1tag{
        margin-top: -570px;
        margin-left: 990px;
        width: 225px;
        height: 64px;
        border-radius: 37.5px;
        background: rgba(232, 237, 252, 1);
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;    
    }
    
    .assesheadtitle{
        width: 693px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: right;
    margin-left: 530px;
    }
    
    .assesshead1{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 150px;
    text-align: right;
    }
    
    .assesshead2{
        width: 1075px;
    height: 155px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-left: 150px;
    text-align: right;
    margin-top: -50px;
    }
    
    .register2{
        width: 173px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 1050px;
    margin-top: -100px;
    border: none;
    background: rgba(7, 60, 165, 1);
    }
    
    .chat{
        margin-top: 200px;
    }
    
    .chatitle1tagcorp{
        width: 365px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 12%;
    
    }
    
    .ctext{
        margin-left: 30px;
        margin-top: 15px;
    }
    
    .chatheadtitlecorp{
        width: 600px;
    height: 174px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    }
    
    .chatimg1cor{
        width: 87px;
    height: 87px;
    margin-left: -2%;
    }
    
    .chatbox111cor{
        clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
        width: 84.16px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    margin-left: 12%;
    margin-top: -103.8%;
    position: relative;
    }
    
    .chatbox11cor{
        width: 378px;
    height: 615px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-left: 150px;
    margin-top: -80px;
    }
    
    .chatboxtext1cor{
        width: 370px;
    height: 529.92px;
    padding: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatbox2cor{
        margin-top: 0.5%;
    }
    
    .chatbox121cor{
        width: 81px;
    height: 64px;
    margin-top: -48%;
    margin-left: 65%;
    position: relative;
    background: rgba(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    }
    
    
    .chatbox21cor{
        margin-left: -30px;
        width: 435px;
        height: 284px;
        border-radius: 50px 0px 50px 50px;
        background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext21cor{
        width: 365px;
    height: 150px;
        padding: 30px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg2cor{
        width: 87px;
    height: 87px;
    margin-left: 470px;
    margin-top: -60px;
    }
    
    .chatbox3cor{
        margin-top: 18%;
        margin-bottom: 10%;
    }
    
    .chatbox131cor{
        width: 81px;
    height: 64px;
    background: rgba(207, 240, 230, 1);
    position: relative;
    clip-path: polygon(0% 0%, 100.2% 0%, 0% 100.3%);
    margin-top: -34%;
    margin-left: 60%;
    }
    
    .chatbox31cor{
        padding: 10px;
        margin-top: 60%;
        width: 358px;
       margin-left: 4%;
    height: 201px;
    border-radius: 50px 0px 50px 50px;
    background: rgba(207, 240, 230, 1);
    }
    
    .chatboxtext31cor{
        padding: 20px;
        font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .chatimg3cor{
        margin-top: -60px;
        margin-left: 440px;
    }
    
    .chatbox4cor{
        margin-top: 16%;
    }
    
    .chatimg4cor{
        width: 87px;
    height: 87px;
    margin-top: 50px;
    margin-left: -45px;
    position: absolute;
    }
    
    .chatbox141cor{
        width: 81px;
    height: 64px;
    background: rgba(231, 242, 255, 1);
    position: relative;
    margin-top: -37.2%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: 4%;
    }
    
    .chatbox41cor{
        padding: 10px;
        width: 475px;
    height: 220px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: 40px;
    margin-left: 99px;
    }
    .chatboxtext41cor{
        width: 388px;
    height: 90px;
        padding: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    
    }
    
    .chatbox5cor{
        margin-top: -9%;
        margin-bottom: 18%;
    }
    
    .chatimg5cor{
        width: 87px;
    height: 87px;
        margin-top: -120px;
        margin-left: -35px;
        position: absolute;
    }
    
    .chatbox51cor{
        padding: 10px;
        width: 455px;
    height: 200px;
    border-radius: 0px 50px 50px 50px;
    background: rgba(231, 242, 255, 1);
    margin-top: -130px;
    margin-left: 110px;
    }
    
    .chatbox151cor{
        width: 81px;
    height: 64px;
    background: #E7F2FF;
    position: relative;
    margin-top: -33.6%;
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    margin-left: 5.4%;
    }
    
    .chatboxtext51cor{
        padding: 20px;
        width: 399px;
    height: 194px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .mainbox2{
        width: 1263px;
    height: 701px;
    border-radius: 81px;
    opacity:50%;
    background: linear-gradient(180deg, #F7F7F7 0%, rgba(157, 160, 193, 0.5) 100%);
    position: relative;
    margin-top: 0%;
    z-index: 1;
    }
    
    .maintitle2{
        margin-top: -48%;
    margin-left: 5%;
    }
    
    .maintitletag2{
        width: 225px;
    height: 64px;
    border-radius: 32px;
    background: #E8EDFC;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    z-index: 2;
    }
    
    .mainheadtitle2{
        width: 542px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    z-index: 2;
    }
    
    .paras1{
        width: 517px;
    height: 140px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    z-index: 2;
    }
    
    .paras2{
        width: 517px;
        height: 140px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        position: relative;
        z-index: 2;
    }
    
    .registers3{
        width: 173px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    background: rgba(7, 60, 165, 1);
    border: none;
    position: relative;
    z-index: 2;
    margin-top: 8%;
    }
    
    .mainimg2 {
        position: absolute;
        margin-top: -35%;
        margin-left: 40%;
        z-index: 2;
    }
    
    .msfedex2{
        width: 168px;
        height: 168px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: -110px;
        margin-left: 80px;
        z-index: 1;
    }
    
    .mfedex2{
        width: 133px;
    height: 56px;
    margin-top: -80px;
    margin-left: 100px;
    position: relative;
    z-index: 2;
    }
    
    .mskfcs2{
        width: 112px;
    height: 112px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: 2px;
    margin-left: 396px;
    z-index: 1;
    }
    
    .mkfcs2{
        width: 106px;
    height: 111px;
    margin-left: 400px;
    position: relative;
    z-index: 2;
    }
    
    .msadidas2{
        width: 232px;
    height: 232px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: -20px;
    margin-left: 157px;
    z-index: 1;
    }
    
    .madidas2{
        width: 220px;
    height: 223px;
    margin-left: 160px;
    margin-top: -30px;
    position: relative;
    z-index: 2;
    }
    
    .msburger2{
        width: 168px;
    height: 168px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: 0px;
    margin-left: 445px;
    z-index: 1;
    }
    
    .mburger2{
        width: 159px;
    height: 172px;
    margin-left: 450px;
    position: relative;
    z-index: 2;
    }
    
    .msfedex3{
        width: 112px;
    height: 112px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: -150px;
    margin-left: 162px;
    z-index: 1;
    }
    
    .mfedex3{
        width: 79px;
    height: 34px;
    margin-top: -230px;
    margin-left: 180px;
    position: relative;
    z-index: 2;
    }
    
    .mskfc3{
        width: 112px;
    height: 112px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 100%;
    margin-top: -19px;
    margin-left: 296px;
    z-index: 1;
    }
    
    .mkfc3{
        width: 106px;
    height: 111px;
    margin-top: -20px;
    margin-left: 300px;
    position: relative;
    z-index: 2;
    }
    
    .main2box{
        width: 1263px;
    height: 701px;
    border-radius: 81px;
    opacity:50%;
    background: linear-gradient(180deg, #F7F7F7 0%, rgba(157, 160, 193, 0.5) 100%);
    position: relative;
    margin-top: 15%;
    z-index: 1;
    }
    
    .main2title{
        margin-left: 40%;
        margin-top: -48%;
    }
    
    .main2titletag{
        width: 225px;
    height: 64px;
    border-radius: 32px;
    background: #E8EDFC;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-left: 60%;
    z-index: 2;
    }
    
    .main2headtitle{
        width: 693px;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: right;
    position: relative;
    z-index: 2;
    }
    
    .paras3{
        width: 517px;
    height: 140px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: right;
    position: relative;
    margin-left: 22%;
    z-index: 2;
    }
    
    .paras4{
        width: 517px;
        height: 140px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: right;
        position: relative;
        margin-left: 22%;
        z-index: 2;
    }
    
    .registers4{
        margin-top: 8%;
        width: 173px;
    height: 60px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    background: rgba(7, 60, 165, 1);
    border: none;
    position: relative;
    margin-left: 65%;
    z-index: 2;
    }
    
    .main2img {
        position: absolute;
        margin-top: -29%;
        margin-left: 10%;
        z-index: 2;
    }
    
    .asfedex{
        width: 168px;
        height: 168px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: -130px;
        margin-left: 245px;
        z-index: 1;
    }
    
    .afedex{
        width: 133px;
    height: 56px;
    margin-top: -100px;
    margin-left: 260px;
    position: relative;
    z-index: 2;
    }
    
    .askfc{
        width: 112px;
        height: 112px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: -19px;
        margin-left: 37px;
        z-index: 1;
    }
    
    .akfc{
        width: 106px;
    height: 111px;
    margin-top: -20px;
    margin-left: 40px;
    position: relative;
    z-index: 2;
    }
    
    .asadidas{
        width: 232px;
        height: 232px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: 10px;
        margin-left: 130px;
        z-index: 1;
    }
    
    .aadidas{
        width: 220px;
    height: 223px;
    margin-left: 130px;
    position: relative;
    z-index: 2;
    }
    
    .asburger{
        width: 168px;
        height: 168px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: -20px;
        margin-left: -65px;
        z-index: 1;
    }
    
    .aburger{
        width: 159px;
    height: 172px;
    margin-top: -20px;
    margin-left: -60px;
    position: relative;
    z-index: 2;
    }
    
    .asfedex2{
        width: 112px;
        height: 112px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: -125px;
        margin-left: 243px;
        z-index: 1;
    }
    
    .afedex2{
        width: 79px;
    height: 34px;
    margin-top: -170px;
    margin-left: 260px;
    position: relative;
    z-index: 2;
    }
    
    .askfc2{
        width: 112px;
        height: 112px;
        background: #FFFFFF;
        position: absolute;
        border-radius: 100%;
        margin-top: -40px;
        margin-left: 126px;
        z-index: 1;
    }
    
    .akfc2{
        width: 106px;
        height: 111px;
        margin-top: -40px;
        margin-left: 130px;
        position: relative;
        z-index: 2;
    }
    
    .startedcorp{
        padding: 0 50px 0 50px;
        margin-top: 10%;
        /* margin-bottom: 1em; */
    }
    
    .startedboxcorp{
        margin-top: -15%;
        width: 1207px;
    height: 227px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 100px;
    
    }
    
    .subbuttontabcorp{
        margin-left: 880px;
        margin-top: -250px;
        height: 50px;
        width: 175px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }
    
    .startheadcorp{
        width: 1000px;
    height: 87px;
    margin-left: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    
    }
    
    .regis{
        width: fit-content;
    height: 19px;
        margin-top: 12px;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    
    }
    
    .startparalapcorp{
        width: 613px;
    height: 62px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-left: 40px;
    margin-top: 0%;
    }
    
    .bluec4{
        width: 54px;
    height: 54px;
    background: rgba(7, 60, 165, 1);
    margin-left: 380px;
    margin-top: 42%;
    border-radius: 100%;
    }
    
    .whiteacorp{
        width: 24px;
height: 30px;
    position: absolute;
margin-top: 2.3%;
margin-left: 3%;
        /* left: 50%;
    transform: translate(-50%, -50%); */
    }
    
    .namesmcor{
        width: 250px;
    height: 30px;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
    
    .desgsmcor{
        width: 134px;
    height: 30px;
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-top: -30px;
    margin-left: 28%;
    }

    .corevalues2corp{
        display: none;
    }
}


@media (min-width:1024.5px) and (max-width:1028px){
    .corevalues2corp{
        display: none !important;
    }

    .title2tag2corp{
        width: max-content;
    height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    /* margin-left: 310px; */
    }
    
    .title2tag2corp2{
        width: 1050px;
height: 40px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
text-align: center;
    /* margin-left: 310px; */
    margin-left: auto;
    margin-right: auto;
    }
}


@media (min-width:831px) and (max-width:991.5px){
    .brandppcorp{
        margin-top: 10%;
        position: relative;
        left: 30px;
        width: 550px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
     }

     .hireppcorp{   
        margin-top: 10%;
               margin-left: auto;
               margin-right: auto;
               width: 530px;
               height: 175px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        opacity: 80%;
        color: rgba(255, 255, 255, 1);
            }
        
            .digitalppcorp{
        margin-top: 10%;
               margin-left: auto;
               margin-right: auto;
               width: 530px;
               height: 175px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        opacity: 80%;
        color: rgba(255, 255, 255, 1);
            }
        
            .trainppcorp{
        margin-top: 12%;
        margin-left: auto;
        margin-right: auto;
               width: 530px;
               height: 175px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        opacity: 80%;
        color: rgba(255, 255, 255, 1);
            }

            .digitalhcorp{
                top: 15%;
        position: relative;
                margin-left: 4%;
                width: 500px;
        height: 42px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 30px;
                font-weight: 900;
                line-height: 42px;
                letter-spacing: 0em;
                text-align: center;
            }

            .brandhcorp{
                position: relative;
                top: 15%;
                margin-left: 5%;
                font-family: 'Plus Jakarta Sans', sans-serif;
                font-size: 30px;
                font-weight: 900;
                line-height: 42px;
                letter-spacing: 0em;
                text-align: center;
            width: fit-content;
        height: 42px;
            }
        
            .hirehcorp{
                width: fit-content;
        height: 42px;
        top: 15%;
        position: relative;
                margin-left: 5%;
                font-family: 'Plus Jakarta Sans', sans-serif;
                font-size: 30px;
                font-weight: 900;
                line-height: 42px;
                letter-spacing: 0em;
                text-align: center;
            }

            .trainhcorp{
                top: 15%;
        position: relative;
                margin-left: 5%;
        width: fit-content;
        height: 24px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 30px;
                font-weight: 900;
                line-height: 42px;
                letter-spacing: 0em;
                text-align: center;
            }
}


@media (min-width:992px) and (max-width:1027px){
    .brandppcorp{
        margin-top: 8%;
        margin-left: auto;
        margin-right: auto;
        width: 706px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
     }

     .hireppcorp{   
        margin-top: 8%;
               margin-left: auto;
               margin-right: auto;
               width: 706px;
               height: 175px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        opacity: 80%;
        color: rgba(255, 255, 255, 1);
            }
        
            .digitalppcorp{
        margin-top: 8%;
               margin-left: auto;
               margin-right: auto;
               width: 706px;
               height: 175px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        opacity: 80%;
        color: rgba(255, 255, 255, 1);
            }
        
            .trainppcorp{
        margin-top: 10%;
        margin-left: auto;
        margin-right: auto;
               width: 706px;
               height: 175px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        opacity: 80%;
        color: rgba(255, 255, 255, 1);
            }

            .digitalhcorp{
                top: 15%;
        position: relative;
                margin-left: 5%;
                width: fit-content;
        height: 42px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
                font-weight: 900;
                line-height: 42px;
                letter-spacing: 0em;
                text-align: center;
            }

            .brandhcorp{
                position: relative;
                top: 15%;
                margin-left: 5%;
                font-family: 'Plus Jakarta Sans', sans-serif;
                font-size: 35px;
                font-weight: 900;
                line-height: 42px;
                letter-spacing: 0em;
                text-align: center;
            width: fit-content;
        height: 42px;
            }
        
            .hirehcorp{
                width: fit-content;
        height: 42px;
        top: 15%;
        position: relative;
                margin-left: 5%;
                font-family: 'Plus Jakarta Sans', sans-serif;
                font-size: 35px;
                font-weight: 900;
                line-height: 42px;
                letter-spacing: 0em;
                text-align: center;
            }

            .trainhcorp{
                top: 15%;
        position: relative;
                margin-left: 5%;
        width: fit-content;
        height: 24px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
                font-weight: 900;
                line-height: 42px;
                letter-spacing: 0em;
                text-align: center;
            }
}


@media (min-width:831px) and (max-width:1027px){

    .chatbox1cor:hover ,
.chatbox2cor:hover,
.chatbox3cor:hover,
.chatbox4cor:hover,
.chatbox5cor:hover
{
    transform: none; /* Scale up the element on hover */
  }

  .title2tag2corp2{
    width: 740px;
        height: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0em;
        position: relative;
        top: -10px;
  }

     /* box click element start */

     .clickihv1crp{
        width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
    }

    .clickihv2crp{
        width: 34px;
height: 34px;
position: relative;
top: -5px;
left: 90px;
    }

    .clickihv3crp{
        width: 34px;
height: 34px;
position: relative;
top: -35px;
left: 90px;
    }

    .clickhv1crp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clickhv2crp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickhv3crp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clichv1crp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clichv2crp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clichv3crp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clihv1crp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clihv2crp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clihv3crp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    /* box click elemet end */

    .title2tagcorp2g{
        height: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 10px;
        margin-bottom: 0em;
    }

    .mainfedex1{
        margin-left: -20%;
        position: relative;
    }

    .mainkfc1{
        margin-left: 15%;
        position: relative;
    }

    .mainadidas1{
        margin-left: -30%;
        position: relative;
    }

    .mainburger1{
        margin-top: 2%;
        margin-left: 30%;
        position: relative;
    }

    .patmarimg {
        width: 100px; /* Adjust width as needed */
        height: 100px; /* Adjust height as needed */
      }

    .imgscrollupmob {
        /* animation: scroll-up 10s linear infinite; */
        display: flex;
        flex-direction: column;
        margin-left: 45%;
      }

    .imgscrollupmob2 {
        animation: scroll-up 10s linear infinite;
        display: flex;
        flex-direction: column;
        margin-left: 45%;
      }
      
      @keyframes scroll-up {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-100%);
        }
      }


    .containercorptrainbox{
        display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    }
    
    .corptrainbox{
        /* width: 674px;
        height: 799px; */
        width: 799px;
        height: 674px;
    background: rgba(209, 209, 209, 1);
    border-radius: 81px;
    /* transform: rotate(90deg); */
    position: relative;
    margin-top: 5%;
    overflow: hidden !important;
    }

    .startparalapcorp{
        width: 612.49px;
height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
    color: rgb(255, 255, 255, 1);
    margin-left: 20%;
    margin-top: -17%;
    margin-bottom: 20%;
    position: relative;
    }

    .registert{
        width: 79px;
height: 25px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
margin-left: 24%;
margin-top: 8%;


    }

    .chatimg3cor{
        display: none;
    }

    .partnerbox2{
        display: none;
    }

    .partner2box{
        display: none;
    }

    .phonebox4{
        display: none;
    }

    /* .brandppcorp{
        margin-top: 8%;
        margin-left: auto;
        margin-right: auto;
        width: 706px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
     } */
 
     /* .hireppcorp{   
 margin-top: 8%;
        margin-left: auto;
        margin-right: auto;
        width: 706px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
     }
 
     .digitalppcorp{
 margin-top: 8%;
        margin-left: auto;
        margin-right: auto;
        width: 706px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
     }
 
     .trainppcorp{
 margin-top: 10%;
 margin-left: auto;
 margin-right: auto;
        width: 706px;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
     } */
 
     /* .brandhcorp{
         position: relative;
         top: 15%;
         margin-left: 5%;
         font-family: 'Plus Jakarta Sans', sans-serif;
         font-size: 35px;
         font-weight: 900;
         line-height: 42px;
         letter-spacing: 0em;
         text-align: center;
     width: fit-content;
 height: 42px;
     }
 
     .hirehcorp{
         width: fit-content;
 height: 42px;
 top: 15%;
 position: relative;
         margin-left: 5%;
         font-family: 'Plus Jakarta Sans', sans-serif;
         font-size: 35px;
         font-weight: 900;
         line-height: 42px;
         letter-spacing: 0em;
         text-align: center;
     } */
 
     /* .digitalhcorp{
         top: 15%;
 position: relative;
         margin-left: 5%;
         width: fit-content;
 height: 42px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 35px;
         font-weight: 900;
         line-height: 42px;
         letter-spacing: 0em;
         text-align: center;
     } */
 
     /* .trainhcorp{
         top: 15%;
 position: relative;
         margin-left: 5%;
 width: fit-content;
 height: 24px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 35px;
         font-weight: 900;
         line-height: 42px;
         letter-spacing: 0em;
         text-align: center;
     } */
 
 
 
     .brandcorp{
         width: 84.2%;
         height: 306px;
         background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
     border-radius: 50px;
     margin-top: 3%;
     margin-bottom: 20px;
     /* margin-left: 8.5%; */
     margin-left: auto;
         margin-right: auto;
     }
     
     .hirecorp{
         width: 84.2%;
 height: 307px;
 border-radius: 50px;
         background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
     border-radius: 50px;
     margin-bottom: 20px;
     /* margin-left: 8.5%; */
     margin-left: auto;
         margin-right: auto;
     }
     
     .digitalcorp{
         width: 84.2%;
 height: 307px;
         border-radius: 50px;
         background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%); font-size: 20px;
         margin-bottom: 20px;
         font-family: 'Plus Jakarta Sans', sans-serif;
         /* margin-left: 8.5%; */
         margin-left: auto;
         margin-right: auto;
     }
     
     .traincorp{
         width: 84.2%;
 height: 307px;
         border-radius: 50px;
         background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
         margin-bottom: 90px;
         font-family: 'Plus Jakarta Sans', sans-serif;
         /* margin-left: 8.5%; */
         margin-left: auto;
         margin-right: auto;
     }

    .luck4{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  display: inline-block;
                  font-weight: 900;
    
    }

    .logos{
        /* padding: 20px 0 0 0; */
        /* padding-right: 0%; */
        /* margin-left: 0%; */
        white-space: nowrap;
    
    }
    
    .logos:hover .logo-slide 
    {
        /* animation-play-state: paused ; */
    }
    
    .logo-slide{
        display: inline-block;
        animation: 80s slide infinite linear;
        margin-top: 30px;
    }
    
    
    
    .logo-slide img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logoss{
        /* overflow: hidden; */
        /* padding: 20px 0; */
        white-space: nowrap;
        margin-bottom: 8em;
    }
    
    .logoss:hover .logos-slide 
    {
        /* animation-play-state: paused ; */
    }
    
    .logos-slide{
        display: inline-block;
        animation: 80s slides infinite linear;
    }
    
    .logos-slide img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }
    

    html,body{

        overflow-x: hidden;
    }

    .namesmcor{
        width: 279px;
height: 63px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 22px;
font-weight: 600;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
    }

    .desgsmcor{
        width: 134px;
height: 30px;
font-size: 22px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-style: italic;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
margin-top: -30px;
margin-left: 25%;
    }

  

   

    .ttextcorp{
        width: 320px;
        height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;

    }


    

    .regis{
        font-family: 'Plus Jakarta Sans', sans-serif;
        color: rgba(7, 60, 165, 1);
        width: 155px;
height: 19px;
font-size: 24px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
margin-top: 5%;
margin-left: 25%;

    }

    .sechead4{
        margin-top: -120px;
    }

    .parttitletag1{
        width: 234px;
height: 64px;
border-radius: 32px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
    text-align: center;
    position: relative;
    background: #E8EDFC;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10em;
    }
    
    .partheadtitle1{
        width: fit-content;
height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
/* text-align: left; */
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    }
    
    .partbox1{
        width: 674px;
height: 799px;
    border-radius: 81px;
    opacity: 0.5px;
    background: linear-gradient(270deg, #F7F7F7 0%, rgba(187, 192, 238, 0.5) 100%);
    position: relative;
    margin-left: 15%;
    }
    
    .pa1{
        width: 700px;
height: 100px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    }
    
    .pa2{
        width: 700px;
height: 80px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
    position: relative;
    margin-left: 15%;
    }
    
    .reg1{
        width: 173px;
height: 60px;
    border-radius: 37.5px;
    background: #073CA5;
    border: none;
    /* margin-left: 43%; */
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
position: absolute;
        left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 45px;
    }
    
    .parttitletag2{
        width: 234px;
        height: 64px;
        border-radius: 32px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    background: #E8EDFC;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30%;
    }
    
    .partheadtitle2{
        width: fit-content;
height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 0%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    }
    
    .partbox2{
        width: 674px;
        height: 799px;
    border-radius: 81px;
    opacity: 0.5px;
    background: linear-gradient(270deg, #F7F7F7 0%, rgba(187, 192, 238, 0.5) 100%);
    position: relative;
    margin-left: 15%;
    margin-top: 3%;
    }
    
    .pa3{
        width: 700px;
height: 90px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    }
    
    .pa4{
        width: 700px;
height: 100px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-left: 13%;
    margin-top: 0%;
    }
    
    .reg2{
        width: 173px;
height: 60px;
    border-radius: 37.5px;
    background: #073CA5;
    border: none;
    position: absolute;
        left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 45px;
    }

    .bluec4{
        width: 54.62px;
height: 54.62px;
margin-top: -540px;
margin-left: 140px;
background: rgba(7, 60, 165, 1);
border-radius: 100%;
position: relative;
    }

    .whiteacorp{
        width: 24.27px;
height: 30.34px;
position: absolute;
margin-top: 50%;
        left: 50%;
    transform: translate(-50%, -50%);


    }

    .startparatabcorp{
        width: 330px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-top: 13%;
margin-left: 10%;
/* top: -215px; */
/* left: 20px; */
position: relative;


    }

    .startheadcorp{
        width: 505px;
height: 31px;
/* margin-left: 97px; */
margin-left: auto;
margin-right: auto;
font-size: 32px;
font-weight: 400;
line-height: 39.68px;
text-align: center;
margin-top: 15px;
font-family: 'Plus Jakarta Sans', sans-serif;
text-align: center;
color: rgb(255, 255, 255, 1);
    }

    .subbuttontabcorp{
        /* top: 40px; */
        margin-top: 28%;
        /* top: 40px; */
        position: relative;
        margin-left: 39%;
        width: 331px;
height: 61px;
border-radius: 37.5px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }

    .yt2{
        

        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;

    }

    .startedboxcorp{
        width: 737px;
height: 252px;
        margin-top: 195px;
        /* margin-left: 88px; */
        position: absolute;
        left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 100px;
    
    }

    .chatboxtext51cor{
        padding: 10%;
        width: 458px;
height: 374px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;


    }

    .startparalap{
        display: none;
    }

    .chatbox51cor{
        width: 529px;
height: 469px;
border-radius: 20px 20px 0px 20px;
margin-top: 50px;
background: rgba(207, 240, 230, 1);
margin-left: -70%;

    }


    .chatimg5cor{
        width: 83px;
height: 83px;
margin-left: 350px;
margin-top: 39%;
margin-left: 28%;
position: absolute;
    }
    

    .chatboxtext41cor{
        width: 458px;
height: 225px;
padding: 10%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
    }

    .chatbox41cor{
        width: 529px;
height: 329px;
    border-radius: 0px 20px 20px 20px;
    background: rgba(231, 242, 255, 1);
    margin-top: 4%;
    margin-left: -31%;
   
    }

    .chatimg4cor{
        width: 83px;
height: 83px;
        margin-left: -30%;
        /* margin-top: 80px; */
        margin-top: 5%; 
       position: absolute;
    }

    .chatboxtext21cor{
        padding: 10%;
        width: 508px;
height: 299px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;



    }

    .chatbox21cor{
        background: rgba(207, 240, 230, 1);
        width: 529px;
height: 392px;
border-radius: 20px 20px 0px 20px;
margin-top: 160%;
margin-left: -60%;
    }

    .chatimg2cor{
        width: 83.46px;
height: 83.46px;
margin-top: -50%;
margin-left: 320px;

    }


    .chatboxtext1cor{
        padding: 10%;
        width: 538.2px;
height: 510.11px;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;


    font-family: 'Plus Jakarta Sans', sans-serif;
    }

    .chatbox11cor{
        width: 529px;
height: 602px;
border-radius: 0px 20px 20px 20px;
margin-top: 10%;
margin-left: 60%;
background: rgba(231, 242, 255, 1);



    }
    
    .chatimg1cor{
        width: 83.46px;
        height: 83.46px;
margin-left: 15%;
margin-top: 11%;
position: absolute;

    }

    .chat{
        margin-top: 50px;
    }


    .chatheadtitlecorp{
        width: 749px;
height: 174px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;

margin-left: 90px;
margin-bottom: -40px;

    }

    .chatitle1tagcorp{
        width: 360px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
justify-content: center;
margin-left: auto;
margin-right: auto;
margin-top: 25%;
    }

    .ctext{
        margin-left: 10px;
        margin-top: 15px;
    }
    

    .register2{
        width: 137px;
height: 42px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
position: relative;
/* margin-top: -200px; */
margin-left: 140px;
top: -60px;
    }


    .assesshead1{
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;
    }
    .assesshead2{
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;
    }
    .assesheadtitle{
        width: 248px;
        height: 31px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: 80px;
margin-top: -10px;
    }

.assesstitle1tag{
    margin-top: -240px;
    margin-left: 40%;
    width: 105px;
    height: 34px;
    border-radius: 37.5px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
}
    

    .assesscircle{
        overflow-x: hidden;
        width: 260px;
height: 260px;
margin-left: 259px;
    }

    .register{
        width: 137px;
height: 42px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
margin-left: 35%;
top: -60px;
position: relative;
left: 0px;
    }
    .register1t{
        padding-top: 8%;
    }

    .head12{
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;

    }

    .head13{
        margin-top: -60px;
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;

    }



    .traintitle1tag{
        width: 105px;
height: 34px;
border-radius: 37.5px;
margin-top: 350px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 40%;

    }

    .trainheadtitle{
        width: 194px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: 28%;
margin-top: -20px;


    }

    .traincircle{
        width: 260px;
        height: 260px;
        
    background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -120px;
    
    }

    .phonetitles4{
        margin-left: 10px;
        margin-top: 100px;

    }
    
    .phonebody4{
        /* margin-left: 10px; */
    }

    .yellowcircle4{
        margin-left: 20px;
     }

    .orgcircle4{
        width: 121.5px;
height: 121.5px;
margin-top: 120px;
/* position: absolute; */
margin-left: 20%;
    }

    .orgcircle4::before{
        content: '';
        position: absolute;
        margin-top: -12%;
        margin-left: 7%;
        width: 121.5px;
height: 121.5px;
        border-radius: 50%;
        opacity: 90%;
      }
      
      .orgcircle4::before {
          background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
        }
    .viocircle4{
        margin-top: -50px;
        width: 121.5px;
height: 121.5px;
opacity: 90%;
    }

    .viocircle4::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }

    .viocircle4::before {
        content: '';
        position: absolute;
        margin-top: -10%;
        left: 0;
        margin-left: 10%;
        width: 121.5px;
height: 121.5px;
        border-radius: 50%;
        opacity: 100%;
      }

    .bluecircle4{
        width: 183.91px;
height: 183.91px;
margin-top: -270px;
margin-left: 180px;
background: rgba(135, 189, 255, 1);
border-radius: 100%;
    }

    .phone4{
        width: 282px;
    height: 228px;
    margin-top: -250px;
    margin-left: 50px;
    position: relative;
    /* order: 2; */
    
    }

    .phonetitles4{
        order: 1;
    }
    .phonetitle1tag4corp{
        margin-top: 10%;
        width: 234px;
height: 64px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
margin-left: 65%;
background: rgba(232, 237, 252, 1);

    }

    .firstsignup4{
        margin-top: -480px;
        text-align: center;
    }

    
    /* .firstsignup14{
        margin-top: -430px;
    } */

    .phoneheadtitle4{
        width: 570px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: left;

margin-left: 34%;
color: rgba(0, 0, 0, 1);
    }

    .arrow4{
        display: none;
    }

.image-phone-container4{
    margin-top: 100px;
    margin-left: 10px;
    order: 1;
    /* display: none; */
}

.phonebody4lap{
    display: none;
}

.phonebody4{
    order: 3;
    width: 358px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 25px;
margin-top: 80px;

}

    .adobe{
        width: 89.62px;
height: 23.58px;
    }

    .adidas{
        margin-left: 30px;
        width: 45.28px;
height: 46.23px;
    }
    .burger{
        width: 43.4px;
height: 46.23px;
    }
    .kfc{
        margin-left: 20px;
        width: 43.4px;
height: 46.23px;
    }
    .fedex{
        width: 60.38px;
height: 25.47px;
    }
    .uptime{
        margin-left: 20px;
        width: 66.04px;
height: 23.58px;

    }
    .title2tag2corp{
        width: fit-content;
        height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
margin-top: 220px;
margin-left: auto;
margin-right: auto;
/* margin-left: 19%; */
margin-bottom: 1em;

    }

    .titlee1tag{
        margin-top: 30px;
        width: 110px;
        margin-left: 35.5%;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1);
    }


    .title1tagcorp{
        margin-top: 18%;
        width: 386px;
        height: 64px;
        border-radius: 32px;
margin-left: auto;
margin-right: auto;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
color: rgba(0, 0, 0, 1);
background: rgba(232, 237, 252, 1);

    }

    .contentt4{
        width: 900px;
height: 259.3px;
        display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right:auto ;
      order: 2;
      font-family: 'Plus Jakarta Sans', sans-serif;
      font-size: 55px;
      font-weight: 300;
      line-height: 68px;
      letter-spacing: 0em;
      text-align: center;
      margin-top: 61px;
      margin-bottom: 50px;
      position: relative;

    }

    .titlee1tag{
        width: 234px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;



        /* margin-top: 30px;
        width: 110px;
        margin-left: 35.5%;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1); */


    }

    .violetbox4{
        background: rgba(130, 27, 97, 1);
        width: 205.32px;
height: 205.32px;
        margin-top: -50%;
        margin-bottom: 100%;
        margin-left: -65%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;     
font-size: 10px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;

    }

    .vt1corp{
        width: 92.04px;
height: 45.51px;
font-family: 'Plus Jakarta Sans', sans-serif;  
position: relative;
margin-left: 10%;
margin-top: 20%;
font-size: 36px;
font-weight: 400;
line-height: 45px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);
    }

    .vt2cor{
        margin-left: -44%;
        margin-top: 40%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif; 
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
color: rgba(255, 255, 255, 1);
width: 83.95px;
height: 19.22px;

    }

    .lapbreak{
        display: none;
    }

    .yellowbox4{
        background: rgba(247, 180, 0, 1);
        width: 119.35px;
height: 119.35px;
        margin-top: -190%;
        margin-left: -70%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;           
    }
    .yt1{
        width: 48px;
        height: 35px;
    
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
letter-spacing: 0em;
text-align: left;
margin-left: 10%;
    margin-top: 20px;

    }

    .linebox4{
        width: 165.87px;
height: 165.87px;
border: 1px;
opacity: 0.1px;
/* margin-top: 38%; */
margin-left: -60%;
 position: relative;
margin-top: -200%;
margin-bottom: 180%;
border: 1px solid rgba(0, 0, 0, 1);

    }

    .hlinebox1corp{
        width: 100px;
    height: 165.87px;
    border: 1px solid rgba(0, 0, 0, 1);
    opacity: 15%;
    margin-left: 20%;
    /* left: 10px; */
    position: absolute;
}

.hlinebox2corp{
    width: 40px;
height: 165.87px;
border: 1px solid rgba(0, 0, 0, 1);
/* opacity: 40%; */
/* left: 10px; */
margin-left: 30%;
}

.rlinebox1corp{
    margin-top: 22px;
    width: 165.87px;
height: 100px;
opacity: 30%;
top: 10px;
border: 1px solid rgba(0, 0, 0, 1);
position: absolute;
}
.rlinebox2corp{
    /* margin-top: 20px; */
    width: 165.87px;
height: 40px;
opacity: 30%;
border: 1px solid rgba(0, 0, 0, 1);
/* top: 10px; */
margin-top: 17%;
position: absolute;
}

.lineboxtext{
    width: 55px;
height: 35px;
margin-top: -60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;
top: 80px;
position: absolute;

}

.lineboxtext2{
    margin-top: 2px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 10px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;
}

.siginscorp{
    padding-top: 0%;
    padding-left: 0%;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 9%;
    

}

    .signincorp{
        width: 173px;
        height: 60px;
        border-radius: 37.5px;
        
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
display: flex;
justify-content: center;
margin-top: 25%;
position: relative;
margin-left: 50%;
text-align: center;
align-content: center;
background: rgba(7, 60, 165, 1);
border: none;
color: rgba(255, 255, 255, 1);

/* text-align: center; */

    }


    .watchs4corp{
        /* margin-top: 5%; */
        margin-top: auto;
        margin-bottom: auto;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        width: 190px;
        height: 25px;



    }

    .watchcorp{
        width: 200px;
        height: 60px;
        border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
display: flex;
justify-content: center;
margin-left: -8px;
margin-top: 10%;
text-align: center;
background: rgba(255, 255, 255, 1);
border: 1px solid rgba(7, 60, 165, 1);
color:rgba(7, 60, 165, 1) ;
position: absolute;
left: 50%;
    transform: translate(-50%, -50%);
    }

    .linetextcorp{
        width: 700px;
height: 150px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
position: relative;
margin-top: 15%;
/* margin-left: 14%; */
margin-left: auto;
margin-right: auto;
    }

    .im-container2{
        margin-left: 20px;
    }

  
    .im-container1 {
      order: 1;
      margin-left: 1%;
      margin-top: 15%;
    }

    .background-container4{
        width: 285.22px;
height: 473.26px;
        clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 25%);
        background-color: #073CA5;
        margin-top: 35%;
        position: absolute;
        margin-left: -15%;
    }

    .orgvector4 img{
        width: 386.11px;
height: 514.82px;

        position: relative;
        margin-left: -100%;
        margin-top: 80.6%;
    }

    .chatbox121cor{
        width: 127.72px;
height: 97.12px;
    margin-top: -9.8%;
    margin-left: 22%;
    position: absolute;
    background: rgb(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100.3%);   
 }

 .chatbox141cor{
    width: 128px;
height: 97px;
background: rgba(231, 242, 255, 1);
position: absolute;
margin-top: -32.5%;
clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
margin-left: -25%;
}

.chatbox151cor{
    width: 127px;
height: 97px;
background: rgba(207, 240, 230, 1);
position: absolute;
margin-top: -9.5%;
clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100%);
margin-left: 20%;
}

.chatbox111cor{
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    width: 127.72px;
    height: 97.12px;
background: rgba(231, 242, 255, 1);
margin-left: 18%;
margin-top: -59.6%;
position: absolute;
}

/* .instlogo{
    height: 100px;
} */

.title2tagcorp{
    /* width: 348px; */
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 20px;
/* margin-left: 31%; */


}


    body{
        overflow-x: hidden;
    }

    .mid3contentcorp{
        display: none;
    }

    .chatboxtext31cor{
        display: none;
    }
    
  }


@media (min-width:577px) and (max-width:767.5px) {

    .sechead4{
        margin-top: -60px;
        margin-bottom: 30px;
    }

    .title2tag2corp{
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 33px;
font-weight: 400;
line-height: 38px;
letter-spacing: 0em;
text-align: center;
margin-top: 20%;
margin-left: auto;
margin-right: auto;
margin-bottom: 1em;
    }

    .brandhcorp{
        position: relative;
        top: 10%;
        margin-left: 5%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 28px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    width: fit-content;
height: 42px;
    }

    .hirehcorp{
        width: 425px;
top: 10%;
position: relative;
        margin-left: 2%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 28px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    }

    .digitalhcorp{
        top: 8%;
position: relative;
        margin-left: 5%;
        width: fit-content;
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 28px;
        font-weight: 900;
        line-height: 35px;
        letter-spacing: 0em;
        text-align: left;
    }

    .trainhcorp{
        top: 8%;
position: relative;
        margin-left: 5%;
width: fit-content;
height: 24px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 28px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    }

}


@media (min-width:768px) and (max-width:830px) {

    .sechead4{
        margin-top: -120px;
    }

    .title2tag2corp{
        width: 77%;
        height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 50px;
font-weight: 400;
line-height: 55px;
letter-spacing: 0em;
text-align: center;
margin-top: 20%;
margin-left: auto;
margin-right: auto;
margin-bottom: 1em;
    }

    .brandhcorp{
        position: relative;
        top: 15%;
        margin-left: 5%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    width: fit-content;
height: 42px;
    }

    .hirehcorp{
        width: fit-content;
height: 42px;
top: 15%;
position: relative;
        margin-left: 5%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    }

    .digitalhcorp{
        top: 15%;
position: relative;
        margin-left: 5%;
        width: fit-content;
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;
    }

    .trainhcorp{
        top: 15%;
position: relative;
        margin-left: 5%;
width: fit-content;
height: 24px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 35px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    }

}


@media (min-width:577px) and (max-width:830px){

    .chatbox1cor:hover ,
.chatbox2cor:hover,
.chatbox3cor:hover,
.chatbox4cor:hover,
.chatbox5cor:hover
{
    transform: none; /* Scale up the element on hover */
  }

  .title2tag2corp2{
    /* width: 740px; */
        height: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: -1em;
        position: relative;
        top: -20px;
  }

     /* box click element start */

     .clickihv1crp{
        width: 34px;
height: 34px;
position: relative;
top: -25px;
left: 90px;
    }

    .clickihv2crp{
        width: 34px;
height: 34px;
position: relative;
top: -5px;
left: 90px;
    }

    .clickihv3crp{
        width: 34px;
height: 34px;
position: relative;
top: -35px;
left: 90px;
    }

    .clickhv1crp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clickhv2crp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clickhv3crp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clichv1crp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clichv2crp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clichv3crp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    .clihv1crp{
        width: 34px;
height: 34px;
position: relative;
top: -30px;
left: 90px;
    }

    .clihv2crp{
        width: 34px;
height: 34px;
position: relative;
top: -20px;
left: 90px;
    }

    .clihv3crp{
        width: 34px;
height: 34px;
position: relative;
top: -55px;
left: 90px;
    }

    /* box click elemet end */

    .title2tagcorp2g{
        height: 40px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 10px;
        margin-bottom: 0em;
    }

    .mainfedex1{
        margin-left: -20%;
        position: relative;
    }

    .mainkfc1{
        margin-left: 15%;
        position: relative;
    }

    .mainadidas1{
        margin-left: -30%;
        position: relative;
    }

    .mainburger1{
        margin-top: 2%;
        margin-left: 30%;
        position: relative;
    }

    .patmarimg {
        width: 100px; /* Adjust width as needed */
        height: 100px; /* Adjust height as needed */
      }

    .imgscrollupmob {
        animation: scroll-up 10s linear infinite;
        display: flex;
        flex-direction: column;
        margin-left: 45%;
      }

    .imgscrollupmob2 {
        animation: scroll-up 10s linear infinite;
        display: flex;
        flex-direction: column;
        margin-left: 45%;
      }
      
      @keyframes scroll-up {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-100%);
        }
      }


    .containercorptrainbox{
        display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    }
    
    .corptrainbox{
        /* width: 674px;
        height: 799px; */
        width: 799px;
        height: 674px;
    background: rgba(209, 209, 209, 1);
    border-radius: 81px;
    /* transform: rotate(90deg); */
    position: relative;
    margin-top: 5%;
    overflow: hidden !important;
    }

    .startparalapcorp{
        width: 612.49px;
height: 50px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
    color: rgb(255, 255, 255, 1);
    margin-left: 8%;
    margin-top: -21%;
    margin-bottom: 20%;
    position: relative;
    }

    .registert{
        width: 79px;
height: 25px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
margin-left: 24%;
margin-top: 8%;


    }

    .chatimg3cor{
        display: none;
    }

    .partnerbox2{
        display: none;
    }

    .partner2box{
        display: none;
    }

    .phonebox4{
        display: none;
    }

    .brandppcorp{
        margin-top: 8%;
        /* margin-left: 10%; */
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
 
     }
 
     .hireppcorp{
     
 margin-top: 8%;
        /* margin-left: 10%; */
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
 
     }
 
     .digitalppcorp{
 
 margin-top: 15%;
        /* margin-left: 10%; */
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
     }
 
     .trainppcorp{
         /* padding-top: 9%;
         width: 365px;
 height: 165px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 12px;
 font-weight: 400;
 line-height: 22px;
 letter-spacing: 0em;
 text-align: left;
 padding-left: 10px; */
 
 margin-top: 11%;
 margin-left: auto;
 margin-right: auto;
        /* margin-left: 10%; */
        width: 90%;
        height: 175px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 25px;
 letter-spacing: 0em;
 text-align: left;
 opacity: 80%;
 color: rgba(255, 255, 255, 1);
 
     }
 
     /* .brandhcorp{
         position: relative;
         top: 15%;
         margin-left: 5%;
         font-family: 'Plus Jakarta Sans', sans-serif;
         font-size: 35px;
         font-weight: 900;
         line-height: 42px;
         letter-spacing: 0em;
         text-align: center;
     width: fit-content;
 height: 42px;
     } */
 
     /* .hirehcorp{
         width: fit-content;
 height: 42px;
 top: 15%;
 position: relative;
         margin-left: 5%;
         font-family: 'Plus Jakarta Sans', sans-serif;
         font-size: 35px;
         font-weight: 900;
         line-height: 42px;
         letter-spacing: 0em;
         text-align: center;
     } */
 
     /* .digitalhcorp{
         top: 15%;
 position: relative;
         margin-left: 5%;
         width: fit-content;
 height: 42px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 35px;
         font-weight: 900;
         line-height: 42px;
         letter-spacing: 0em;
         text-align: left;
     }
 
     .trainhcorp{
         top: 15%;
 position: relative;
         margin-left: 5%;
 width: fit-content;
 height: 24px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 35px;
         font-weight: 900;
         line-height: 42px;
         letter-spacing: 0em;
         text-align: center;
     } */
 
 
 
     .brandcorp{
         width: 84.2%;
         height: 306px;
         background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
     border-radius: 50px;
     margin-top: 4%;
     margin-bottom: 20px;
     /* margin-left: 8.5%; */
     margin-left: auto;
         margin-right: auto;
     }
     
     .hirecorp{
         width: 84.2%;
 height: 307px;
 border-radius: 50px;
         background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
     border-radius: 50px;
     margin-bottom: 20px;
     /* margin-left: 8.5%; */
     margin-left: auto;
         margin-right: auto;
     }
     
     .digitalcorp{
         width: 84.2%;
 height: 307px;
         border-radius: 50px;
         background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%); font-size: 20px;
         margin-bottom: 20px;
         font-family: 'Plus Jakarta Sans', sans-serif;
         /* margin-left: 8.5%; */
         margin-left: auto;
         margin-right: auto;
     }
     
     .traincorp{
         width: 84.2%;
 height: 307px;
         border-radius: 50px;
         background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
         margin-bottom: 90px;
         font-family: 'Plus Jakarta Sans', sans-serif;
         /* margin-left: 8.5%; */
         margin-left: auto;
         margin-right: auto;
     }

    .luck4{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  display: inline-block;
                  font-weight: 900;
    
    }

    .logos{
        /* padding: 20px 0 0 0; */
        /* padding-right: 0%; */
        /* margin-left: 0%; */
        white-space: nowrap;
    
    }
    
    .logos:hover .logo-slide 
    {
        /* animation-play-state: paused ; */
    }
    
    .logo-slide{
        display: inline-block;
        animation: 80s slide infinite linear;
        margin-top: 30px;
    }
    
    
    
    .logo-slide img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logoss{
        /* overflow: hidden; */
        /* padding: 20px 0; */
        white-space: nowrap;
        margin-bottom: 7em;
    }
    
    .logoss:hover .logos-slide 
    {
        /* animation-play-state: paused ; */
    }
    
    .logos-slide{
        display: inline-block;
        animation: 80s slides infinite linear;
    }
    
    .logos-slide img{
        margin: 0 40px;
        height: 100px;
    }
    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }
    

    html,body{

        overflow-x: hidden;
    }

    .namesmcor{
        width: 279px;
height: 63px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 22px;
font-weight: 600;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
    }

    .desgsmcor{
        width: 134px;
height: 30px;
font-size: 22px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-style: italic;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
margin-top: -30px;
margin-left: 25%;
    }

  

   

    .ttextcorp{
        width: 320px;
        height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;

    }


    

    .regis{
        font-family: 'Plus Jakarta Sans', sans-serif;
        color: rgba(7, 60, 165, 1);
        width: 155px;
height: 19px;
font-size: 24px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
margin-top: 5%;
margin-left: 25%;

    }

    /* .sechead4{
        margin-top: -120px;
    } */

    .parttitletag1{
        width: 234px;
height: 64px;
border-radius: 32px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
    text-align: center;
    position: relative;
    background: #E8EDFC;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10em;
    }
    
    .partheadtitle1{
        width: fit-content;
height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
/* text-align: left; */
    position: relative;
    margin-left: auto;
    margin-right: auto;
    }
    
    .partbox1{
        width: 674px;
height: 799px;
    border-radius: 81px;
    opacity: 0.5px;
    background: linear-gradient(270deg, #F7F7F7 0%, rgba(187, 192, 238, 0.5) 100%);
    position: relative;
    margin-left: 15%;
    }
    
    .pa1{
        width: 700px;
height: 100px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    }
    
    .pa2{
        width: 700px;
height: 80px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
    position: relative;
    margin-left: 15%;
    }
    
    .reg1{
        width: 173px;
height: 60px;
    border-radius: 37.5px;
    background: #073CA5;
    border: none;
    /* margin-left: 43%; */
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
position: absolute;
        left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 45px;
    }
    
    .parttitletag2{
        width: 234px;
        height: 64px;
        border-radius: 32px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    background: #E8EDFC;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30%;
    }
    
    .partheadtitle2{
        width: fit-content;
height: 87px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 0%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    }
    
    .partbox2{
        width: 674px;
        height: 799px;
    border-radius: 81px;
    opacity: 0.5px;
    background: linear-gradient(270deg, #F7F7F7 0%, rgba(187, 192, 238, 0.5) 100%);
    position: relative;
    margin-left: 15%;
    margin-top: 3%;
    }
    
    .pa3{
        width: 700px;
height: 90px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    }
    
    .pa4{
        width: 700px;
height: 100px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-left: 13%;
    margin-top: 0%;
    }
    
    .reg2{
        width: 173px;
height: 60px;
    border-radius: 37.5px;
    background: #073CA5;
    border: none;
    position: absolute;
        left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 45px;
    }

    .bluec4{
        width: 54.62px;
height: 54.62px;
margin-top: -540px;
margin-left: 140px;
background: rgba(7, 60, 165, 1);
border-radius: 100%;
position: relative;
    }

    .whiteacorp{
        width: 24.27px;
height: 30.34px;
position: absolute;
margin-top: 50%;
        left: 50%;
    transform: translate(-50%, -50%);


    }

    .startparatabcorp{
        width: 330px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-top: 13%;
margin-left: 10%;
/* top: -215px; */
/* left: 20px; */
position: relative;


    }

    .startheadcorp{
        width: 505px;
height: 31px;
/* margin-left: 97px; */
margin-left: auto;
margin-right: auto;
font-size: 32px;
font-weight: 400;
line-height: 39.68px;
text-align: center;
margin-top: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
text-align: center;
color: rgb(255, 255, 255, 1);
    }

    .subbuttontabcorp{
        /* top: 40px; */
        margin-top: 37%;
        /* top: 40px; */
        position: relative;
        margin-left: 30%;
        width: 331px;
height: 61px;
border-radius: 37.5px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }

    .yt2{
        

        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;

    }

    .startedboxcorp{
        width: 90%;
height: 252px;
        margin-top: 195px;
        /* margin-left: 88px; */
        position: absolute;
        left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 100px;
    
    }

    .chatboxtext51cor{
        padding: 10%;
        width: 458px;
height: 374px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;


    }

    .startparalap{
        display: none;
    }

    .chatbox51cor{
        width: 529px;
height: 469px;
border-radius: 20px 20px 0px 20px;
margin-top: 50px;
background: rgba(207, 240, 230, 1);
margin-left: -70%;

    }


    .chatimg5cor{
        width: 83px;
height: 83px;
margin-left: 350px;
margin-top: 39%;
margin-left: 28%;
position: absolute;
    }
    

    .chatboxtext41cor{
        width: 458px;
height: 225px;
padding: 10%;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
    }

    .chatbox41cor{
        width: 529px;
height: 329px;
    border-radius: 0px 20px 20px 20px;
    background: rgba(231, 242, 255, 1);
    margin-top: 4%;
    margin-left: -31%;
   
    }

    .chatimg4cor{
        width: 83px;
height: 83px;
        margin-left: -30%;
        /* margin-top: 80px; */
        margin-top: 5%; 
       position: absolute;
    }

    .chatboxtext21cor{
        padding: 10%;
        width: 508px;
height: 299px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;



    }

    .chatbox21cor{
        background: rgba(207, 240, 230, 1);
        width: 529px;
height: 392px;
border-radius: 20px 20px 0px 20px;
margin-top: 160%;
margin-left: -60%;
    }

    .chatimg2cor{
        width: 83.46px;
height: 83.46px;
margin-top: -50%;
margin-left: 320px;

    }


    .chatboxtext1cor{
        padding: 10%;
        width: 538.2px;
height: 510.11px;
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;


    font-family: 'Plus Jakarta Sans', sans-serif;
    }

    .chatbox11cor{
        width: 529px;
height: 602px;
border-radius: 0px 20px 20px 20px;
margin-top: 10%;
margin-left: 60%;
background: rgba(231, 242, 255, 1);



    }
    
    .chatimg1cor{
        width: 83.46px;
        height: 83.46px;
margin-left: 15%;
margin-top: 11%;
position: absolute;

    }

    .chat{
        margin-top: 50px;
    }


    .chatheadtitlecorp{
        /* width: 749px; */
height: 174px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
/* margin-left: 90px; */
margin-bottom: -40px;

    }

    .chatitle1tagcorp{
        width: 360px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
justify-content: center;
margin-left: auto;
margin-right: auto;
margin-top: 25%;
    }

    .ctext{
        margin-left: 10px;
        margin-top: 15px;
    }
    

    .register2{
        width: 137px;
height: 42px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
position: relative;
/* margin-top: -200px; */
margin-left: 140px;
top: -60px;
    }


    .assesshead1{
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;
    }
    .assesshead2{
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;
    }
    .assesheadtitle{
        width: 248px;
        height: 31px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: 80px;
margin-top: -10px;
    }

.assesstitle1tag{
    margin-top: -240px;
    margin-left: 40%;
    width: 105px;
    height: 34px;
    border-radius: 37.5px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
}
    

    .assesscircle{
        overflow-x: hidden;
        width: 260px;
height: 260px;
margin-left: 259px;
    }

    .register{
        width: 137px;
height: 42px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
margin-left: 35%;
top: -60px;
position: relative;
left: 0px;
    }
    .register1t{
        padding-top: 8%;
    }

    .head12{
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;

    }

    .head13{
        margin-top: -60px;
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;

    }



    .traintitle1tag{
        width: 105px;
height: 34px;
border-radius: 37.5px;
margin-top: 350px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 40%;

    }

    .trainheadtitle{
        width: 194px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: 28%;
margin-top: -20px;


    }

    .traincircle{
        width: 260px;
        height: 260px;
        
    background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -120px;
    
    }

    .phonetitles4{
        margin-left: 10px;
        margin-top: 100px;

    }
    
    .phonebody4{
        /* margin-left: 10px; */
    }

    .yellowcircle4{
        margin-left: 20px;
     }

    .orgcircle4{
        width: 121.5px;
height: 121.5px;
margin-top: 120px;
/* position: absolute; */
margin-left: 20%;
    }

    .orgcircle4::before{
        content: '';
        position: absolute;
        margin-top: -12%;
        margin-left: 7%;
        width: 121.5px;
height: 121.5px;
        border-radius: 50%;
        opacity: 90%;
      }
      
      .orgcircle4::before {
          background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
        }
    .viocircle4{
        margin-top: -50px;
        width: 121.5px;
height: 121.5px;
opacity: 90%;
    }

    .viocircle4::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }

    .viocircle4::before {
        content: '';
        position: absolute;
        margin-top: -10%;
        left: 0;
        margin-left: 10%;
        width: 121.5px;
height: 121.5px;
        border-radius: 50%;
        opacity: 100%;
      }

    .bluecircle4{
        width: 183.91px;
height: 183.91px;
margin-top: -270px;
margin-left: 180px;
background: rgba(135, 189, 255, 1);
border-radius: 100%;
    }

    .phone4{
        width: 282px;
    height: 228px;
    margin-top: -250px;
    margin-left: 50px;
    position: relative;
    /* order: 2; */
    
    }

    .phonetitles4{
        order: 1;
    }
    .phonetitle1tag4corp{
        margin-top: 10%;
        width: 234px;
height: 64px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
margin-left: 65%;
background: rgba(232, 237, 252, 1);

    }

    .firstsignup4{
        margin-top: -480px;
        text-align: center;
    }

    
    /* .firstsignup14{
        margin-top: -430px;
    } */

    .phoneheadtitle4{
        width: 570px;
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: left;

margin-left: 34%;
color: rgba(0, 0, 0, 1);
    }

    .arrow4{
        display: none;
    }

.image-phone-container4{
    margin-top: 100px;
    margin-left: 10px;
    order: 1;
    /* display: none; */
}

.phonebody4lap{
    display: none;
}

.phonebody4{
    order: 3;
    width: 358px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 25px;
margin-top: 80px;

}

    .adobe{
        width: 89.62px;
height: 23.58px;
    }

    .adidas{
        margin-left: 30px;
        width: 45.28px;
height: 46.23px;
    }
    .burger{
        width: 43.4px;
height: 46.23px;
    }
    .kfc{
        margin-left: 20px;
        width: 43.4px;
height: 46.23px;
    }
    .fedex{
        width: 60.38px;
height: 25.47px;
    }
    .uptime{
        margin-left: 20px;
        width: 66.04px;
height: 23.58px;

    }
    /* .title2tag2corp{
        width: 77%;
        height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 50px;
font-weight: 400;
line-height: 55px;
letter-spacing: 0em;
text-align: center;
margin-top: 20%;
margin-left: auto;
margin-right: auto;
margin-bottom: 1em;
    } */

    .titlee1tag{
        margin-top: 30px;
        width: 110px;
        margin-left: 35.5%;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1);
    }


    .title1tagcorp{
        margin-top: 18%;
        width: 386px;
        height: 64px;
        border-radius: 32px;
margin-left: auto;
margin-right: auto;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
color: rgba(0, 0, 0, 1);
background: rgba(232, 237, 252, 1);

    }

    .contentt4{
        width: 240%;
height: 259.3px;
        display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-left: -15%;
      order: 2;
      font-family: 'Plus Jakarta Sans', sans-serif;
      font-size: 55px;
      font-weight: 300;
      line-height: 68px;
      letter-spacing: 0em;
      text-align: center;
      margin-top: 61px;
      margin-bottom: 50px;
      position: relative;

    }

    .titlee1tag{
        width: 234px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;



        /* margin-top: 30px;
        width: 110px;
        margin-left: 35.5%;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1); */


    }

    .violetbox4{
        background: rgba(130, 27, 97, 1);
        width: 205.32px;
height: 205.32px;
        margin-top: -60%;
        margin-bottom: 100%;
        margin-left: -85%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;     
font-size: 10px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;

    }

    .vt1corp{
        width: 92.04px;
height: 45.51px;
font-family: 'Plus Jakarta Sans', sans-serif;  
position: relative;
margin-left: 10%;
margin-top: 20%;
font-size: 36px;
font-weight: 400;
line-height: 45px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);
    }

    .vt2cor{
        margin-left: -44%;
        margin-top: 40%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif; 
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
color: rgba(255, 255, 255, 1);
width: 83.95px;
height: 19.22px;

    }

    .lapbreak{
        display: none;
    }

    .yellowbox4{
        background: rgba(247, 180, 0, 1);
        width: 119.35px;
height: 119.35px;
        margin-top: -262%;
        margin-left: -86%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;           
    }
    .yt1{
        width: 48px;
        height: 35px;
    
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
letter-spacing: 0em;
text-align: left;
margin-left: 10%;
    margin-top: 20px;

    }

    .linebox4{
        width: 165.87px;
height: 165.87px;
border: 1px;
opacity: 0.1px;
/* margin-top: 38%; */
margin-left: -70%;
 position: relative;
margin-top: -240%;
margin-bottom: 243%;
border: 1px solid rgba(0, 0, 0, 1);

    }

    .hlinebox1corp{
        width: 100px;
    height: 165.87px;
    border: 1px solid rgba(0, 0, 0, 1);
    opacity: 15%;
    margin-left: 20%;
    /* left: 10px; */
    position: absolute;
}

.hlinebox2corp{
    width: 40px;
height: 165.87px;
border: 1px solid rgba(0, 0, 0, 1);
/* opacity: 40%; */
/* left: 10px; */
margin-left: 30%;
}

.rlinebox1corp{
    margin-top: 22px;
    width: 165.87px;
height: 100px;
opacity: 30%;
top: 10px;
border: 1px solid rgba(0, 0, 0, 1);
position: absolute;
}
.rlinebox2corp{
    /* margin-top: 20px; */
    width: 165.87px;
height: 40px;
opacity: 30%;
border: 1px solid rgba(0, 0, 0, 1);
/* top: 10px; */
margin-top: 17%;
position: absolute;
}

.lineboxtext{
    width: 55px;
height: 35px;
margin-top: -60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;
top: 80px;
position: absolute;

}

.lineboxtext2{
    margin-top: 2px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 10px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;
}

.siginscorp{
    padding-top: 0%;
    padding-left: 0%;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 9%;
    

}

    .signincorp{
        width: 173px;
        height: 60px;
        border-radius: 37.5px;
        
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
display: flex;
justify-content: center;
margin-top: 25%;
position: relative;
margin-left: 50%;
text-align: center;
align-content: center;
background: rgba(7, 60, 165, 1);
border: none;
color: rgba(255, 255, 255, 1);

/* text-align: center; */

    }


    .watchs4corp{
        /* margin-top: 5%; */
        margin-top: auto;
        margin-bottom: auto;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        width: 190px;
        height: 25px;



    }

    .watchcorp{
        width: 200px;
        height: 60px;
        border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
display: flex;
justify-content: center;
margin-left: -8px;
margin-top: 10%;
text-align: center;
background: rgba(255, 255, 255, 1);
border: 1px solid rgba(7, 60, 165, 1);
color:rgba(7, 60, 165, 1) ;
position: absolute;
left: 50%;
    transform: translate(-50%, -50%);
    }

    .linetextcorp{
        width: 700px;
height: 150px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
position: relative;
margin-top: 15%;
/* margin-left: 14%; */
margin-left: auto;
margin-right: auto;
    }

    .im-container2{
        margin-left: 20px;
    }

  
    .im-container1 {
      order: 1;
      margin-left: 1%;
      margin-top: 15%;
    }

    .background-container4{
        width: 285.22px;
height: 473.26px;
        clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 25%);
        background-color: #073CA5;
        margin-top: 53%;
        position: absolute;
        margin-left: -15%;
    }

    .orgvector4 img{
        width: 386.11px;
height: 514.82px;

        position: relative;
        margin-left: -100%;
        margin-top: 100%;
    }

    .chatbox121cor{
        width: 127.72px;
height: 97.12px;
    margin-top: -9.8%;
    margin-left: 22%;
    position: absolute;
    background: rgb(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100.3%);   
 }

 .chatbox141cor{
    width: 128px;
height: 97px;
background: rgba(231, 242, 255, 1);
position: absolute;
margin-top: -32.5%;
clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
margin-left: -25%;
}

.chatbox151cor{
    width: 127px;
height: 97px;
background: rgba(207, 240, 230, 1);
position: absolute;
margin-top: -9.5%;
clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100%);
margin-left: 20%;
}

.chatbox111cor{
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    width: 127.72px;
    height: 97.12px;
background: rgba(231, 242, 255, 1);
margin-left: 18%;
margin-top: -59.6%;
position: absolute;
}

/* .instlogo{
    height: 100px;
} */

.title2tagcorp{
    /* width: 348px; */
height: 87px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 70px;
font-weight: 400;
line-height: 87px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 20px;
/* margin-left: 31%; */


}


    body{
        overflow-x: hidden;
    }

    .mid3contentcorp{
        display: none;
    }

    .chatboxtext31cor{
        display: none;
    }
    
  }


@media (max-width: 576px) {

    .chatbox1cor:hover ,
    .chatbox2cor:hover,
    .chatbox3cor:hover,
    .chatbox4cor:hover,
    .chatbox5cor:hover
    {
        transform: none; /* Scale up the element on hover */
      }
    

    .title2tagcorp2g{
        width: 250px;
        margin-left: auto;
        margin-right: auto;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
top: -10px;
margin-bottom: -1em;
position: relative;
    }

    .gifcorp{
        position: absolute;
        top: -180px;
        left: 120px;
        height: 140px;
    }
     /* start of circle animation  */
     @keyframes moveUp {
        0% { transform: translateY(30px); }
        100% { transform: translateY(-10px); }
    }
    
    .orgcircle4, .viocircle4, .bluecircle4, .yellowbox4 {
        
        animation: none; /* Initially no animation */
    }
    
    .scrolling .orgcircle4 {
        animation: moveUp 0.8s forwards; /* Play animation once and stop */
    }
    
    .scrolling .viocircle4 {
        animation: moveUp 1.5s forwards; /* Slightly delay the animation */
    }
    
    .scrolling .bluecircle4 {
        animation: moveUp 2s forwards; /* Slightly delay the animation */
    }

    .scrolling .yellowbox4 {
        animation: moveUp 2.5s forwards; /* Slightly delay the animation */
    }
    /* end of circle animation */
    .containercorptrainbox{
        display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    }
    
    .corptrainbox{
        width: 352px;
    height: 506px;
    background: rgba(209, 209, 209, 1);
    border-radius: 81px;
    /* transform: rotate(90deg); */
    position: relative;
    margin-top: 10%;
    overflow: hidden !important;
    }
    .patmarimg {
        width: 110px; /* Adjust width as needed */
        height: 110px; /* Adjust height as needed */
      }

    .imgscrollupmob {
        animation: scroll-up 10s linear infinite;
        display: flex;
        flex-direction: column;
        margin-left: 35%;
      }

    .imgscrollupmob2 {
        animation: scroll-up 10s linear infinite;
        display: flex;
        flex-direction: column;
        margin-left: 35%;
      }
      
      @keyframes scroll-up {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-100%);
        }
      }

    .startparalapcorp{
        display: none;
    }

    .extrachatcorp{
        display: none;
    }
.partnerbox2{
    display: none;
}
    .partner2box{
        display: none;
    }

    .arrow4{
        display: none;
    }   

    .luck4{
        font-family: 'Plus Jakarta Sans', sans-serif;
        background-image: linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%); 
                  color: transparent; 
                  -webkit-background-clip: text; 
                  background-clip: text;
                  display: inline-block;
                  font-weight: 900;
    
    }

    .logos{
        /* padding: 20px 0 0 0; */
        /* padding-right: 0%; */
        /* margin-left: 0%; */
        white-space: nowrap;
    
    }
    
    .logos:hover .logo-slide 
    {
        /* animation-play-state: paused ; */
    }
    
    .logo-slide{
        display: inline-block;
        animation: 120s slide infinite linear;
    }
    
    
    
    .logo-slide img{
        margin: 0 40px;
        height: 50px;
    }
    
    @keyframes slide{
        from{ 
            transform: translateX(0);
        }
        to{
            transform: translateX(-100%);
        }
    }
    
    .logoss{
        /* overflow: hidden; */
        /* padding: 20px 0; */
        white-space: nowrap;
    
    }
    
    .logoss:hover .logos-slide 
    {
        /* animation-play-state: paused ; */
    }
    
    .logos-slide{
        display: inline-block;
        animation: 80s slides infinite linear;
    }
    
    .logos-slide img{
        margin: 0 40px;
        height: 50px;
    }
    
    @keyframes slides{
        to{ 
            transform: translateX(0);
        }
        from{
            transform: translateX(-100%);
        }
    }
    

    html,body{

        overflow-x: hidden;
    }

    .namesmcor{
        width: 250px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 600;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
    }

    .desgsmcor{
        width: 134px;
height: 30px;
font-size: 15px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-style: italic;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
margin-top: -30px;
margin-left: 19%;
    }

    .brandppcorp{
        /* width: 360px;
height: 175px;
padding: 10px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
padding: 3%; */
/* margin-top: 23%; */
position: absolute;
margin-left: 35px;
width: 260px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);
    }

    .hireppcorp{
        position: absolute;
        margin-left: 35px;
width: 260px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);

    }

    .digitalppcorp{
        /* width: 360px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
padding-left: 10px;
padding: 10px; */
position: absolute;
margin-left: 35px;
width: 260px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);
    }

    .trainppcorp{
        /* padding-top: 9%;
        width: 360px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
padding-left: 10px; */

position: absolute;
        margin-left: 35px;
width: 260px;
height: 165px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
opacity: 80%;
color: rgba(255, 255, 255, 1);

    }

    .brandhcorp{
        position: relative;
        top: 18%;
        margin-left: 35px;
        width: 250px;
        height: 24px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;
    margin-bottom: 4.2em;
    }

    .hirehcorp{
        width: 189px;
        height: 24px;
        top: 20%;
        position: relative;
        margin-left: 35px;
                font-family: 'Plus Jakarta Sans', sans-serif;
            font-size: 20px;
            font-weight: 900;
            line-height: 24px;
            letter-spacing: 0em;
            margin-bottom: 4.4em;
    }

    .digitalhcorp{
        top: 16%;
position: relative;
margin-left: 35px;
        width: 290px;
height: 48px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 900;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
margin-bottom: 2.7em;
    }

    .trainhcorp{
        top: 19%;
position: relative;
margin-left: 35px;
width: 259px;
height: 24px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 900;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
margin-bottom: 3.2em;
    }

    .ttextcorp{
        width: fit-content;
height: 15px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
    }


    .brandcorp{
    width: 334px;
    height: 300px;
    background: linear-gradient(360deg, #000000 8.85%, #F75B02 74.48%);
    border-radius: 50px;
    margin-bottom: 18px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -12%;
    }
    
    .hirecorp{
    width: 334px;
    height: 300px;
    background: linear-gradient(360deg, #000000 26.68%, #073C9D 72.57%);
    border-radius: 50px;
    margin-bottom: 18px;
    margin-left: auto;
    margin-right: auto;
    }
    
    .digitalcorp{
        width: 334px;
    height: 300px;
        border-radius: 50px;
        background: linear-gradient(360deg, #000000 16.18%, #821B61 61.27%); font-size: 20px;
        margin-bottom: 18px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: auto;
         margin-right: auto;
    }
    
    .traincorp{
        width: 334px;
    height: 300px;
        border-radius: 50px;
        background: linear-gradient(360deg, #000000 16.94%, #F7B400 75.01%);
        margin-bottom: 58px;
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin-left: auto;
         margin-right: auto;
         margin-bottom: 65px;
    }

    .regis{
        font-family: 'Plus Jakarta Sans', sans-serif;
        color: rgba(7, 60, 165, 1);
        width: fit-content;
height: 19px;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
margin-top: 6%;
margin-left: auto;
margin-right: auto;
    }

    .sechead4{
        /* margin-top: -120px; */
    }

    .parttitletag1{
        width: 105px;
    height: 34px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    background: #E8EDFC;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8%;
    }
    
    .partheadtitle1{
        width: 194px;
    height: 31px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    /* margin-left: 22%; */
    margin-left: auto;
    margin-right: auto;
    }
    
    .partbox1{
        width: 356px;
    height: 556px;
    border-radius: 81px;
    opacity: 0.5px;
    background: linear-gradient(270deg, #F7F7F7 0%, rgba(187, 192, 238, 0.5) 100%);
    position: relative;
    /* margin-left: 5%; */
    margin-left: auto;
    margin-right: auto;
    }
    
    .pa1{
        width: 358px;
    height: 57px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    /* margin-left: 5%; */
    margin-left: auto;
    margin-right: auto;
    margin-top: 9%;
    margin-bottom: 1em;
    }
    
    .pa2{
        width: 358px;
    height: 57px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    /* margin-left: 5%; */
    margin-left: auto;
    margin-right: auto;
    }
    
    .reg1{
        width: 137px;
    height: 42px;
    border-radius: 37.5px;
    background: #073CA5;
    margin-left: 33%;
    border: none;
    margin-top: 6%;
    }
    
    .parttitletag2{
        width: 105px;
    height: 34px;
    border-radius: 37.5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    background: #E8EDFC;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8%;
    }
    
    .partheadtitle2{
        width: 248px;
    height: 31px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    /* margin-left: 22%; */
    margin-left: auto;
    margin-right: auto;
    }
    
    .partbox2{
        width: 356px;
    height: 556px;
    border-radius: 81px;
    opacity: 0.5px;
    background: linear-gradient(270deg, #F7F7F7 0%, rgba(187, 192, 238, 0.5) 100%);
    position: relative;
    /* margin-left: 5%; */
    margin-left: auto;
    margin-right: auto;
    }
    
    .pa3{
        width: 358px;
    height: 57px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    /* margin-left: 5%; */
    margin-left: auto;
    margin-right: auto;
    margin-top: 9%;
    margin-bottom: -2em;
    }
    
    .pa4{
        width: 358px;
    height: 57px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    /* margin-left: 5%; */
    margin-left: auto;
    margin-right: auto;
    }
    
    .reg2{
        width: 137px;
    height: 42px;
    border-radius: 37.5px;
    background: #073CA5;
    margin-left: 33%;
    border: none;
    }

    .bluec4{
        width: 32.91px;
height: 32.91px;
margin-top: -175px;
margin-left: 280px;
background: rgba(7, 60, 165, 1);
border-radius: 100%;
position: relative;
    }

    .whiteacorp{
        width: 14.63px;
height: 18.28px;
position: absolute;
margin-top: 50%;
        left: 50%;
    transform: translate(-50%, -50%);

    }

    .startparatabcorp{
        width: 330px;
height: 30px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
margin-top: 30%;
margin-left: 10%;
position: relative;


    }

    .startheadcorp{
        width: 405px;
height: 31px;
margin-left: auto;
margin-right: auto;
/* top: 100px;
position: absolute;
left: 100px; */
margin-top: 15px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
color: rgb(255, 255, 255, 1);
    }

    .subbuttontabcorp{
        /* top: 40px; */
        margin-top: -28px;
        top: 30px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        /* margin-left: 30%; */
        height: 42px;
        width: 137px;
        border-radius:30px;
        font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: rgb(255, 255, 255, 1);
    color: rgba(7, 60, 165, 1);
    border: solid rgba(255, 255, 255, 1);
    }

    .yt2{
        

        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 10px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
position: relative;
top: -5px;
left: 2px;

    }

    .startedboxcorp{
        margin-top: 150px;
        width: 400px;
        position: absolute;
        left: 50%;
    transform: translate(-50%, -50%);
    height: 170px;
    background: linear-gradient(91.28deg, #CC0530 -3%, #FF5B00 41.98%, #FFBC00 99.82%);
    border-radius: 25px;
    margin-bottom: 100px;
    
    }

    .chatboxtext51cor{
        padding: 20px;
        width: 240px;
height: 180px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;

    }

    .startparalap{
        display: none;
    }

    .chatbox51cor{
        width: 254px;
height: 170px;
border-radius: 20px 20px 0px 20px;
margin-top: 27px;
background: rgba(207, 240, 230, 1);
margin-left: 13%;

    }


    .chatimg5cor{
        width: 40.11px;
height: 40.11px;
margin-left: 342px;
margin-top: 148px;
position: absolute;
    }
    

    .chatboxtext41cor{
        width: 230px;
height: 108px;
padding: 20px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
    }

    .chatbox41cor{
        width: 250px;
    height: 178px;
    border-radius: 0px 20px 20px 20px;
    background: rgba(231, 242, 255, 1);
    margin-top: 1px;
    margin-left: 29%;
   
    }

    .chatimg4cor{
        width: 40.11px;
        height: 40.11px;
        margin-left: 21px;
        /* margin-top: 80px; */
        margin-top: 1%; 
       position: absolute;
    }

    .chatboxtext21cor{
        padding: 30px;
        width: 290px;
height: 144px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;


    }

    .extrachatcorp{
        display: none;
    }

    .chatbox21cor{
        background: rgba(207, 240, 230, 1);
        width: 290px;
height: 200px;
border-radius: 20px 20px 0px 20px;
margin-top: 10px;
margin-left: 10px;
    }

    .chatimg2cor{
        width: 40px;
height: 40px;
margin-top: -90px;
margin-left: 320px;

    }


    .chatboxtext1cor{
        padding: 23px;
        width: 250px;
height: 245.15px;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;

    font-family: 'Plus Jakarta Sans', sans-serif;
    }

    .chatbox11cor{
        width: 254px;
height: 325px;
border-radius: 0px 20px 20px 20px;
margin-top: 30px;
margin-left: 30%;
background: rgba(231, 242, 255, 1);
margin-bottom: 10px;


    }
    
    .chatimg1cor{
        width: 40.11px;
height: 40.11px;
position: absolute;
margin-left: 35px;
margin-top: 27px;
    }

    .chat{
        margin-top: 50px;
    }


    .chatheadtitlecorp{
        width: 244px;
height: 62px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: 90px;
margin-bottom: -40px;

    }

    .chatitle1tagcorp{
        width: 189px;
height: 34px;
border-radius: 37.5px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-top: 5em;
margin-left: auto;
margin-right: auto;
    }

    .ctext{
        margin-left: 20px;
        margin-top: 10px;
    }
    

    .register2{
        width: 137px;
height: 42px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
position: relative;
/* margin-top: -200px; */
margin-left: 140px;
top: -60px;
    }


    .assesshead1{
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;
    }
    .assesshead2{
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;
    }
    .assesheadtitle{
        width: 248px;
        height: 31px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: 80px;
margin-top: -10px;
    }

.assesstitle1tag{
    margin-top: -240px;
    margin-left: 40%;
    width: 105px;
    height: 34px;
    border-radius: 37.5px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
}
    

    .assesscircle{
        overflow-x: hidden;
        width: 260px;
height: 260px;
margin-left: 259px;
    }

    .register{
        width: 137px;
height: 42px;
border-radius: 37.5px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
margin-left: 35%;
top: -60px;
position: relative;
left: 0px;
    }
    .register1t{
        padding-top: 8%;
    }

    .head12{
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;

    }

    .head13{
        margin-top: -60px;
        width: 358px;
height: 105px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 30px;

    }



    .traintitle1tag{
        width: 105px;
height: 34px;
border-radius: 37.5px;
margin-top: 350px;
    background: rgba(232, 237, 252, 1);
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: 40%;

    }

    .trainheadtitle{
        width: 194px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: 28%;
margin-top: -20px;


    }

    .traincircle{
        width: 260px;
        height: 260px;
        
    background: rgba(247, 91, 2, 0.1);
    border-radius: 100%;
    margin-left: -120px;
    
    }

    .phonetitles4{
        margin-left: 10px;
        margin-top: 100px;

    }
    
    .phonebody4{
        /* margin-left: 10px; */
    }

    .yellowcircle4{
        margin-left: 20px;
     }

    .orgcircle4{
        width: 121.5px;
height: 121.5px;
margin-top: 120px;
/* position: absolute; */
margin-left: 20%;
    }

    .orgcircle4::before{
        content: '';
        position: absolute;
        margin-top: -19%;
        margin-left: 7%;
        width: 121.5px;
height: 121.5px;
        border-radius: 50%;
        opacity: 90%;
      }
      
      .orgcircle4::before {
          background: radial-gradient(circle at 50% 50%, rgba(247, 91, 2, 1) 100%, transparent 60%);
        }
    .viocircle4{
        margin-top: -50px;
        width: 121.5px;
height: 121.5px;
opacity: 90%;
    }

    .viocircle4::before {
        background: radial-gradient(circle at 50% 50%, rgba(130, 27, 97, 1) 100%, transparent 70%);
      }

    .viocircle4::before {
        content: '';
        position: absolute;
        margin-top: -10%;
        left: 0;
        margin-left: 10%;
        width: 121.5px;
height: 121.5px;
        border-radius: 50%;
        opacity: 100%;
      }

    .bluecircle4{
        width: 183.91px;
height: 183.91px;
margin-top: -225px;
margin-left: 180px;
background: rgba(135, 189, 255, 1);
border-radius: 100%;
position: absolute;
    }

    .phone4{
        width: 282px;
    height: 228px;
    margin-top: -250px;
    margin-left: 50px;
    position: relative;
    /* order: 2; */
    
    }

    .phonetitles4{
        order: 1;
    }
    .phonetitle1tag4corp{
        margin-top: 10%;
        width: 290px;
        height: 34px;
        border-radius: 37.5px;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
margin-left: auto;
margin-right: auto;
background: rgba(232, 237, 252, 1);

    }

    .firstsignup4{
        margin-top: -480px;
        text-align: center;
    }

    
    /* .firstsignup14{
        margin-top: -430px;
    } */

    .phoneheadtitle4{
        width: 270px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
color: rgba(0, 0, 0, 1);
    }

.image-phone-container4{
    margin-top: 100px;
    margin-left: 10px;
    order: 1;
}

.phonebody4lap{
    display: none;
}

.phonebody4{
    order: 3;
    width: 379px;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 25px; */
margin-left: auto;
margin-right: auto;
margin-top: 10px;
margin-bottom: 5em;
}

    .adobe{
        width: 89.62px;
height: 23.58px;
    }

    .adidas{
        margin-left: 30px;
        width: 45.28px;
height: 46.23px;
    }
    .burger{
        width: 43.4px;
height: 46.23px;
    }
    .kfc{
        margin-left: 20px;
        width: 43.4px;
height: 46.23px;
    }
    .fedex{
        width: 60.38px;
height: 25.47px;
    }
    .uptime{
        margin-left: 20px;
        width: 66.04px;
height: 23.58px;

    }
    .title2tag2corp{
        margin-top: 30px;
        width: 100%;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 100px; */
margin-left: auto;
margin-right: auto;
margin-bottom: 5px;
    }

    .title2tag2corp2{
        margin-top: -3px;
        width: fit-content;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0em;
text-align: center;
/* margin-left: 100px; */
margin-left: auto;
margin-right: auto;
margin-bottom: 5.5em;
    }

    .mid3contentcorp{
        display: none;
    }

    .titlee1tag{
        margin-top: 30px;
        width: 110px;
        margin-left: 35.5%;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1);
    }


    .title1tagcorp{
        margin-top: -7%;
        width: 190px;
        margin-left: auto;
        margin-right: auto;
        /* margin-left: 36.5%; */
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
color: rgba(0, 0, 0, 1);
background: rgba(232, 237, 252, 1);

    }

    .contentt4{
        width: fit-content;
height: 93px;
        display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    /* margin-left: 25%; */
      order: 2;
      font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 300;
line-height: 31px;
letter-spacing: 0em;
text-align: center;

      margin-bottom: 50px;
      

    }

    .titlee1tag{
        width: 234px;
height: 64px;
border-radius: 32px;
background: rgba(232, 237, 252, 1);
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;



        /* margin-top: 30px;
        width: 110px;
        margin-left: 35.5%;
height: 34px;
border-radius: 32px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
background: rgba(232, 237, 252, 1);
color: rgba(0, 0, 0, 1); */


    }

    .violetbox4{
        background: rgba(130, 27, 97, 1);
        width: 123px;
        height: 124px;
        margin-top: -26%;
        margin-bottom: 10%;
        margin-left: 8%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;     
font-size: 10px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;

    }

    .vt1corp{
        width: 55px;
height: 35px;
font-family: 'Plus Jakarta Sans', sans-serif;  
/* margin-left: 100px; */
position: relative;
/* left: 0px; */
margin-left: 10%;
margin-top: 20%;
/* top: 10px; */
font-size: 20px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);
    }

    .vt2cor{
        /* margin-top: 35px; */
        /* margin-left: -70px; */
        margin-left: -45%;
        margin-top: 36%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif; 
font-size: 10px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);
    }

    .yellowbox4{
        background: rgba(247, 180, 0, 1);
        width: 72px;
        height: 72px;
        margin-top: -93%;
        margin-left: 8%;
        position: relative;
        font-family: 'Plus Jakarta Sans', sans-serif;           
    }
    .yt1{
        width: 48px;
        height: 35px; 
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.8px;
letter-spacing: 0em;
text-align: left;
margin-left: 7%;
    margin-top: 10px;

    }

    .linebox4{
        width: 100px;
height: 100px;
border: 1px;
opacity: 0.1px;
/* margin-top: 38%; */
margin-left: 17%;
 position: relative;
margin-top: -87%;
margin-bottom: 85%;
border: 1px solid rgba(0, 0, 0, 1);

    }

    .hlinebox1corp{
        width: 63px;;
    height: 99px;
    border: 1px solid rgba(0, 0, 0, 1);
    opacity: 8%;
    margin-left: 17%;
    /* left: 10px; */
    position: absolute;
}

.hlinebox2corp{
    width: 23px;
height: 99px;
border: 1px solid rgba(0, 0, 0, 1);
opacity: 30%;
/* left: 10px; */
margin-left: 30%;
}

.rlinebox1corp{
    margin-top: 10px;
    width: 99px;
height: 63px;
opacity: 8%;
top: 10px;
border: 1px solid rgba(0, 0, 0, 1);
position: absolute;
}
.rlinebox2{
    /* margin-top: 20px; */
    width: 99px;
height: 23px;
opacity: 30%;
border: 1px solid rgba(0, 0, 0, 1);
/* top: 10px; */
margin-top: 27%;
position: absolute;
}

.lineboxtext{
    width: 55px;
height: 35px;
margin-top: -60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;
top: 80px;
position: absolute;

}

.lineboxtext2{
    margin-top: 2px;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 10px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;
}

.siginscorp{
    margin-top: 6%;
    font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;

}

    .signincorp{
        width: 137px;
        
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
display: flex;
justify-content: center;
margin-top: 30px;
margin-left: 50px;
text-align: center;
align-content: center;
background: rgba(7, 60, 165, 1);
border: none;
border-radius: 37.5px;
color: rgba(255, 255, 255, 1);

/* text-align: center; */

    }


    .watchs4corp{
        /* padding-top: 1%; */
        margin-top: auto;
        margin-bottom: auto;
        font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: center;

    }

    .watchcorp{
        width: 150px;
height: 42px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
/* margin-left: -8px; */
margin-top: 400px;
border-radius: 30px;
text-align: center;
background: rgba(255, 255, 255, 1);
border: 1px solid rgba(7, 60, 165, 1);
color:rgba(7, 60, 165, 1) ;
/* text-align: left; */
position: absolute;
left: 50%;
    transform: translate(-50%, -50%);
    }

    .linetextcorp{
        width: fit-content;
height: 60px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 13px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: center;
margin-left: auto;
margin-right: auto;
margin-top: 13%;
margin-bottom: 6em;
    }

    .im-container2{
        margin-left: 20px;
    }

  
    .im-container1 {
      order: 1;
      margin-left: 1%;
    }

    .background-container4{
        width: 164px;
        height: 273px;
        clip-path: polygon(67% 0, 100% 0, 100% 100%, 0 100%, 0% 25%);
        background-color: #073CA5;
        margin-top: 10%;
        position: absolute;
        margin-left: 28%;
    }

    .orgvector4 img{
        width: 222.75px;
        height: 297px;
        position: relative;
        margin-left: 80%;
        margin-top: 8.3%;
    }

    .chatbox121cor{
        width: 61.38px;
height: 46.67px;
    margin-top: -10.8%;
    margin-left: 64%;
    position: absolute;
    background: rgba(207, 240, 230, 1);
    clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100.3%);   
 }

 .chatbox141cor{
    width: 61px;
height: 46px;
background: rgba(231, 242, 255, 1);
position: absolute;
margin-top: -41.3%;
clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
margin-left: 14%;
}

.chatbox151cor{
    width: 61px;
height: 46px;
background: rgba(207, 240, 230, 1);
position: absolute;
margin-top: -10.8%;
clip-path: polygon(0% 0%, 100.2% 100.3%, 0% 100%);
margin-left: 67%;
}

.chatbox111cor{
    clip-path: polygon(0% 0%, 100.2% 0%, 100.2% 100.3%);
    width: 61px;
height: 46px;
background: rgba(231, 242, 255, 1);
margin-left: 18%;
margin-top: -77.7%;
position: absolute;
}

.title2tagcorp{
    width: 125px;
height: 31px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 25px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
margin-left: 35%;


}


    body{
        overflow-x: hidden;
    }
    
  }